import React, {useEffect, useState, useRef} from "react";
import {Marker} from "components/shared-components/Marker/Marker";
import {BOOTSTRAP_URL_KEYS} from "configs/AppConfig";
import GoogleMapReact from "google-map-react";
import {darkMapStyle, mapOptions, setLightMapType} from "configs/mapStyle";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {MAP_TYPE} from "services/redux/constants/authConstants";
import {lookupStyleMarker} from "../../shared-components/lookupStyleMarker";

const MapDisplay = ({
                        vehiclesDataToShowOnTheMap,
                        followDriver,
                        selectedMarker,
                        mapOverviewData,
                        defaultCenter,
                        onClickMarker = () => null,
                        setSelectedMarker = () => null
                    }) => {
    const [mapsInstance, setMapsInstance] = useState();
    const [defaultZoom, setDefaultZoom] = useState(5);
    const [markerCluster, setMarkerCluster] = useState();
    const [markersList, setMarkersList] = useState();
    const [previousBounds, setPreviousBounds] = useState(null);
    const [previousDataLength, setPreviousDataLength] = useState(null);
    const zoomingFollowVehicle = 17;

    const createBoundsFromOverviewData = (maps, data) => {
        console.log("Creating bounds for passing to fitBounds");
        const bounds = new maps.LatLngBounds();

        data?.forEach((d) => {
            const lat = d?.position?.latitude;
            const lng = d?.position?.longitude;

            if (lat && lng) {
                // This is valid coordinate
                const newCoordinate = new maps.LatLng(lat, lng);
                bounds.extend(newCoordinate);
            } else {
                // This is an invalid coordinate, don't add it to bounds
            }
        });
        return bounds;
    };
    useEffect(() => {
        const data = vehiclesDataToShowOnTheMap?.filter(item => item.position?.latitude)
        if (data?.length < 1) return
        if (data?.length === previousDataLength) return;


        if (vehiclesDataToShowOnTheMap?.length < 1) return
        if (vehiclesDataToShowOnTheMap?.length === previousDataLength) return;

        if (mapsInstance && data) {
            const bounds = createBoundsFromOverviewData(mapsInstance.maps, data);

            if (previousBounds && bounds.equals(previousBounds)) {

            } else {
                setPreviousBounds(bounds);
                setPreviousDataLength(data?.length)
                mapsInstance.map.fitBounds(bounds);
                if (data?.length === 1) {
                    mapsInstance?.map?.setZoom(17)
                }
            }
        }
    }, [mapsInstance, vehiclesDataToShowOnTheMap, previousBounds]);

    useEffect(() => {
        if (selectedMarker !== null) {
            setDefaultZoom(zoomingFollowVehicle);
        }
    }, [selectedMarker]);
    useEffect(() => {
        if (!mapsInstance) {
            return;
        } else {
            mapsInstance?.maps?.event.trigger(mapsInstance?.map, "resize");
            mapsInstance?.map?.setOptions({
                ...mapOptions,
                styles: localStorage.getItem(MAP_TYPE) === "DARK" ? darkMapStyle : null,
            });
            setLightMapType(mapsInstance?.maps, mapsInstance?.map);
        }
        const data = vehiclesDataToShowOnTheMap?.filter(item => item.position?.latitude)
        if (data) {
            const markers = [];
            const map = mapsInstance?.map;
            const maps = mapsInstance?.maps;
            const toShow = selectedMarker !== null ? mapOverviewData.filter((vehicle) => vehicle?.meta.vehicle_id !== selectedMarker?.meta.vehicle_id) : data;
            toShow?.map((vehicle) => {
                let vehicleTitle = vehicle?.meta?.vehicleDisplayName || vehicle?.meta?.reg || vehicle?.meta.vehicle_id;
                if (vehicle?.meta?.vehicle_id === followDriver && selectedMarker) {
                    vehicleTitle = " ";
                }

                let icon =lookupStyleMarker[vehicle?.meta?.marker]
                if (["androidAlert", "androidCar", "androidContact", "androidDrafts", "androidGlobe", "androidHome"].includes(vehicle?.meta?.marker)) {
                    icon = {
                        path: lookupStyleMarker["androidCar"]?.children[0].children[0].children[0].children[0].attribs.d,
                        scale: 0.07,
                    };
                } else {
                  icon = {
                      path: icon?.children[0].attribs.d,
                      scale: 0.02,
                  };
                }

                const marker = new maps.Marker({
                    key: vehicle?.meta.vehicle_id,
                    position: {lat: vehicle?.position.latitude, lng: vehicle?.position.longitude},
                    map,
                    icon: {
                        path: icon?.path,
                        fillColor: vehicle?.meta.isArchived ? '#b4bed2' : vehicle?.meta.color,
                        fillOpacity: 1,
                        strokeWeight: 0.3,
                        scale: 0.02,
                        className: "icon",
                        ...icon
                    },
                    label: {
                        text: vehicleTitle?.toString(),
                        className: "labels",
                        fontSize: "14px",
                        fontWeight: "bold",
                    },
                });
                marker.addListener("click", () => {
                    onClickMarker(vehicle);
                });
                markers.push(marker);
            });
            if (markerCluster) {
                markerCluster.clearMarkers();
                markerCluster.setMap(null);
            }

            if (markersList)
                markersList.map((m) => {
                    m?.setMap(null);
                });

            const renderer = {
                render: ({ count, position }) =>
                    new maps.Marker({
                        label: { text: String(count), color: "white", fontSize: "10px" },
                        position,
                        icon: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                        zIndex: Number(maps.Marker?.MAX_ZINDEX) + count,
                    }),
            };

           const mc = new MarkerClusterer({
                map,
                markers,
                renderer,
            });

            setMarkerCluster(mc);
            setMarkersList(markers);
        }
    }, [mapsInstance, vehiclesDataToShowOnTheMap, followDriver, selectedMarker]);
    return (
        <>
            <GoogleMapReact
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                center={selectedMarker ? defaultCenter : null}
                defaultCenter={defaultCenter}
                zoom={selectedMarker ? 17 : null}
                defaultZoom={defaultZoom}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => {
                    setMapsInstance({
                        map: map,
                        maps: maps,
                    });
                    setLightMapType(maps, map);
                }}
                options={mapOptions}
            >
                {!selectedMarker ? null : (
                    <Marker
                        key={followDriver}
                        data={followDriver}
                        lat={followDriver?.position?.latitude}
                        lng={followDriver?.position?.longitude}
                        followerDriver={followDriver}
                        selectedMarker={selectedMarker}
                        showInfo={selectedMarker === followDriver?.meta?.vehicle_id}
                        onClick={(e) => onClickMarker(e)}
                    />
                )}
            </GoogleMapReact>
        </>
    );
};

export default MapDisplay;

import {Button, Form, Input, Menu, Modal, Select, Switch, Table, Tabs, Tag, Tooltip, Typography} from "antd";
import {
    OrganizationSimpleContext,
    shouldHideUsersOrganizationsColumn,
    UserListContext,
    VehiclesComplexListContext
} from "../../../services/contexts/contexts";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {useHistory} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {
    ALARM_NAMES,
    ALARM_PRIO,
    ALARM_TYPES,
    alarmShowFields,
    getAlarmNames
} from "../../../services/redux/constants/alarmConstants";
import {ButtonMenuDropdown, tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";
import {TabBadge} from "../Superdevices/reusableComponents";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {dataApi} from "../../../services/api/api";
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import Loading from "../../shared-components/Loading/Loading";
import {ModalWithFullList} from "../shared-components/modalShowFullList";

const {TabPane} = Tabs;

const TableActionBtnType = "link";

const AlarmView = ({alarmType}) => {
    const history = useHistory();

    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)
    const knownVehicles = useContext(VehiclesComplexListContext);
    const knownUsers = useContext(UserListContext);
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);

    const [organizationId, setOrganizationId] = useState([]);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [showModalNewAlarm, setShowModalNewAlarm] = useState(false);
    const [defaultActiveKey, setDefaultActiveKey] = useState();
    const [loading, setLoading] = useState(true);

    const title = ALARM_TYPES[alarmType];

    useEffect(() => {
        if (data && knownVehicles && knownUsers) {
            setLoading(false)
        }
    }, [data, knownVehicles, knownUsers])

    useEffect(() => {
        setDefaultActiveKey(history?.location?.state?.tab || Object.entries(getAlarmNames(alarmType))[0][0]);

        dataApi.getAlarmSettings().then((res) => {
            res = res?.filter((item) => item.alarmType === alarmType);
            setData(res);
            setAllData(res);
        });
    }, []);

    useEffect(() => {
        if (organizationId?.length > 0) {
            setData(allData.filter((item) => organizationId.includes(item.organizationId)));
        } else {
            setData(allData);
        }
    }, [organizationId]);

    const handleDelete = (id) => {
        const newData = data?.filter((item) => item.alarmSettingsId !== id);
        dataApi.deleteAlarmSettings(id).then((_) => {
            setData(newData);
        });
    };

    let hiddenTrackersColumn = !alarmShowFields[defaultActiveKey]?.isTracker
    const columns = [
        {
            title: STATIC_TEXT.ORGANIZATION,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.organizationId,
            hidden: hideUsersOrg,
            render: (text) => {
                const organization = knownOrganizations?.find((i) => i.organizationId === text);
                return organization?.fullName;
            },
        },
        {
            title: "Larmalias",
            dataIndex: "name",
        },
        {
            title: "Intervall",
            dataIndex: "repeatAfterHours",
            render: (text) => {
                if(text === 0){
                    return "Meddelas bara en gång";
                }
                return "Upprepas varje " + text + " h ";
            },
        },
        {
            title: STATIC_TEXT.MAIL_RECIPIENTS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.emails,
            render: (text) => {
                if (text?.length === 1) {
                    return <a>{text[0]}</a>;
                } else if (text?.length === 0) {
                    return "-";
                } else {
                    let title = text?.length + " " + STATIC_TEXT.MAIL_RECIPIENTS;
                    return ModalWithFullList(title, null, text)
                }
            },
        },
        {
            title: "Aktivt",
            dataIndex: "active",
            align: "right",
            render: (value, record) => {
                return <Switch onChange={(v) => patchAlarm({active: v}, record)} defaultChecked={value}/>;
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            fixed: "right",
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => goToCreateView(record.alarmSettingsId)}>Se detaljer/Ändra</Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => handleDelete(record.alarmSettingsId)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }


    function createNewAlarm(values) {
        values.organizationId = 2226;
        values.alarmType = alarmType;
        values.targetVehicleIds = dataApi.postAlarmSettings(values);
        setShowModalNewAlarm(!showModalNewAlarm);
    }

    const patchAlarm = (value, record) => {
        dataApi.patchAlarmSettings(record.alarmSettingsId, value);
    };

    const goToCreateView = (id) => {
        const url = APP_PREFIX_PATH + "/alarmEditor/" + "?alarmType=" + alarmType + "&id=" + (id || -1);
        history.push({
            pathname: url,
            state: {id: id},
        });
    };

    function checkTabColor(length, data) {
        let active = false;
        data.map((item) => {
            if (item.active) {
                active = true;
            }
        });

        if (length > 0 && !active) {
            return SD_BASE_COLORS.YELLOW;
        } else if (length === 0) {
            return SD_BASE_COLORS.RED;
        } else {
            return SD_BASE_COLORS.GREEN;
        }
    }

    return (
        loading ? <Loading/> : (
            <div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, flexWrap: "wrap"}}>
                    <Typography.Title>{title}</Typography.Title>
                    <div style={{display: "flex", gap: 15, alignItems: "center", flexWrap: "wrap"}}>
                        {knownOrganizations?.length < 2 ? null : (
                            <OrganizationsTreeSelect data={knownOrganizations} onChange={(v) => setOrganizationId(v)}
                                                     selectedOrganization={organizationId} isMultiple={true}/>
                        )}

                        <Button
                            type={"primary"}
                            onClick={() => goToCreateView(-1)}
                            //onClick={() => setShowModalNewAlarm(!showModalNewAlarm)}
                            icon={<PlusOutlined/>}
                        >
                            {STATIC_TEXT.ADD}{" "}
                        </Button>
                    </div>
                </div>

                <CollectionCreateForm
                    visible={showModalNewAlarm}
                    onCreate={createNewAlarm}
                    onCancel={() => {
                        setShowModalNewAlarm(false);
                    }}
                    knownVehicles={knownVehicles}
                    knownUsers={knownUsers}
                />

                {defaultActiveKey && (
                    <Tabs defaultActiveKey={defaultActiveKey} onChange={v => setDefaultActiveKey(v)}>
                        {Object.entries(getAlarmNames(alarmType)).map(([key, title]) => {
                            let tabData = data.filter((item) => item.alarmName === key);
                            return (
                                <TabPane tab={<TabBadge text={title} overflowCount={999}
                                                        badgeColor={checkTabColor(tabData?.length, tabData)}
                                                        count={tabData?.length}/>}  key={key}>
                                    <Table dataSource={tabData} scroll={{x: 1000}}
                                           columns={tableColumnsWithTooltip(columns)} {...tableProps}/>
                                </TabPane>
                            );
                        })}
                    </Tabs>
                )}
            </div>
        )
    );
};

const CollectionCreateForm = ({visible, onCreate, onCancel, knownVehicles, knownUsers}) => {
    const [form] = Form.useForm();
    const [alarmName, setAlarmName] = useState(ALARM_NAMES["NO_DRIVER"]);

    return (
        <Modal
            visible={visible}
            title={"Skapa ett nytt larm - " + alarmName}
            okText="Skapa larm"
            cancelText="Avbryt"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: "public",
                }}
            >
                <Form.Item
                    name="alarmName"
                    label="Larm"
                    initialValue={"NO_DRIVER"}
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of collection!",
                        },
                    ]}
                >
                    <Select onChange={(value) => setAlarmName(ALARM_NAMES[value])}>
                        {Object.entries(ALARM_NAMES).map(([key, title]) => {
                            return <Select.Option value={key}>{title}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="alarmPrio"
                    label="Priority"
                    initialValue={"HIGH"}
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of collection!",
                        },
                    ]}
                >
                    <Select onChange={(value) => setAlarmName(ALARM_NAMES[value])}>
                        {Object.entries(ALARM_PRIO).map(([key, title]) => {
                            return <Select.Option value={key}>{title}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Alias"
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of collection!",
                        },
                    ]}
                >
                    <Input type="textarea"/>
                </Form.Item>

                <Form.Item
                    name="targetVehicleIds"
                    label="Fordon"
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of collection!",
                        },
                    ]}
                >
                    <Select mode="multiple" onChange={(value) => setAlarmName(ALARM_NAMES[value])}>
                        {knownVehicles?.map((key) => {
                            return (
                                <Select.Option value={key.id}>
                                    {key.registry} {key.displayName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="emails"
                    label="E-post"
                    rules={[
                        {
                            required: true,
                            message: "Please input the title of collection!",
                        },
                    ]}
                >
                    <Select mode="multiple" onChange={(value) => setAlarmName(ALARM_NAMES[value])}>
                        {knownUsers?.map((key) => {
                            return <Select.Option value={key.displayName}>{key.displayName}</Select.Option>;
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AlarmView;

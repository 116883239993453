export const defaultSchedule = {
    daysList: [
        {
            day: 1,
            start: 800,
            stop: 1700,
            active: true
        },
        {
            day: 2,
            start: 800,
            stop: 1700,
            active: true
        },
        {
            day: 3,
            start: 800,
            stop: 1700,
            active: true
        },
        {
            day: 4,
            start: 800,
            stop: 1700,
            active: true
        },
        {
            day: 5,
            start: 800,
            stop: 1700,
            active: true
        },
        {
            day: 6,
            start: 800,
            stop: 1700,
            active: false
        },
        {
            day: 7,
            start: 800,
            stop: 1700,
            active: false
        },

    ],
    tripType: "BUSINESS"

}
export const defaultScheduleAlarm = [
    {
        dayOfWeek: 1,
        "times": [
            {
                "start": '0000',
                "stop": '0600'
            },
            {
                "start": '1700',
                "stop": '2359'
            }
        ],
        active: true
    },
    {
        dayOfWeek: 2,
        "times": [
            {
                "start": '0000',
                "stop": '0600'
            },
            {
                "start": '1700',
                "stop": '2359'
            }
        ],
        active: true
    },
    {
        dayOfWeek: 3,
        "times": [
            {
                "start": '0000',
                "stop": '0600'
            },
            {
                "start": '1700',
                "stop": '2359'
            }
        ],
        active: true
    },
    {
        dayOfWeek: 4,
        "times": [
            {
                "start": '0000',
                "stop": '0600'
            },
            {
                "start": '1700',
                "stop": '2359'
            }
        ],
        active: true
    },
    {
        dayOfWeek: 5,
        "times": [
            {
                "start": '0000',
                "stop": '0600'
            },
            {
                "start": '1700',
                "stop": '2359'
            }
        ],
        active: true
    },
    {
        dayOfWeek: 6,
        "times": [
            {
                "start": '0000',
                "stop": '2359'
            },
        ],
        active: true
    },
    {
        dayOfWeek: 7,
        "times": [
            {
                "start": '0000',
                "stop": '2359'
            },
        ],
        active: true
    },

]
import {Nest} from "../../utils/utilities";
import {
    AuthorizationProvider,
    DeviceListProvider,
    OrganizationsComplexListProvider,
    shouldHideUsersOrganizationsColumn,
    UserListProvider,
    VehiclesListWithArchivedProvider
} from "../../services/contexts/contexts";
import {SuperTrackersListController} from "../../components/trackson-components/superTrackersList";
import {ServicesContextProvider} from "../../services/contexts/ServicesContext";
import {VehiclesContextProvider} from "../../services/contexts/VehiclesContext";

const SuperTrackersListView = () => {

    return <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, UserListProvider,
            VehiclesListWithArchivedProvider, DeviceListProvider,
            OrganizationsComplexListProvider, ServicesContextProvider, VehiclesContextProvider]}>
        < SuperTrackersListController/>
    </Nest>

}

export default SuperTrackersListView

export const DEVICE_TYPE_NAMES = {
   FMB001: "Plug-n-drive",
   FMT100: "Plug-n-drive",
   FMC001: "Plug-n-drive",
   TAT100: "GPS-tracker",
   FMB920: "Anläggningsmaskin",
};

export const mockDevices = [
   {
      key: 1,
      identity: {
         phone: "0790111222",
         imei: "774785981221450",
         iccid: "89460856047000096301",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "abc123",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "30",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Andre Kalye",
         primaryDriver: "Josh Kalis",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 2,
      identity: {
         phone: "0790111222",
         imei: "352094086429658",
         iccid: "54878543154889335413",
      },
      deviceType: {
         code: "FMB001",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "bkk16a",
      },
      functional: {
         type: "OBD",
         alarm: 5,
      },
      health: {
         battery: "60",
         lastSeen: "40 minuter",
      },
      driver: {
         checkedIn: "Yuta Tyler",
         primaryDriver: "Sean Malto",
      },
      belongTo: {
         companyName: "Facebook.inc",
         since: "2015-07-13",
      },
   },
   {
      key: 3,
      identity: {
         phone: "0790111222",
         imei: "352094086437578",
         iccid: "89460856047005458742",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "rtt268",
      },
      functional: {
         type: "OBD",
         alarm: 2,
      },
      health: {
         battery: "10",
         lastSeen: "16 minuter",
      },
      driver: {
         checkedIn: "Andre Kalye",
         primaryDriver: "Josh Kalis",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 4,
      identity: {
         phone: "0790111222",
         imei: "352094088521908",
         iccid: "25485454554154754585",
      },
      deviceType: {
         code: "FMT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Volvo  XC90",
         category: "Poolbil",
         registry: "SRT118",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "40",
         lastSeen: "30 minuter",
      },
      driver: {
         checkedIn: "Sydney Malkei",
         primaryDriver: "Chris Cole",
      },
      belongTo: {
         companyName: "Apple.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 5,
      identity: {
         phone: "0790111222",
         imei: "352094088772618",
         iccid: "24878762455458787873",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Volvo  XC90",
         category: "Poolbil",
         registry: "CBN58D",
      },
      functional: {
         type: "OBD",
         alarm: 10,
      },
      health: {
         battery: "80",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Yumeno Y.",
         primaryDriver: "J Cole",
      },
      belongTo: {
         companyName: "Microsoft.inc",
         since: "2017-06-08",
      },
   },
   {
      key: 6,
      identity: {
         phone: "0790111222",
         imei: "354017111095051",
         iccid: "54589665545452145465",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Tesla Model S",
         category: "Privat bil",
         registry: "SJT548",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "75",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Elon Musk",
         primaryDriver: "Elon Musk",
      },
      belongTo: {
         companyName: "Tesla.inc",
         since: "2016-01-17",
      },
   },
   {
      key: 7,
      identity: {
         phone: "0790111222",
         imei: "354017111095184",
         iccid: "57547856251212578542",
      },
      deviceType: {
         code: "FMT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Nissan GT-R",
         category: "Privatl bil",
         registry: "SKT856",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "100",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Miyamoto M.",
         primaryDriver: "Miyamoto M.",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 8,
      identity: {
         phone: "0790111222",
         imei: "354017111115230",
         iccid: "54888754523387854525",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Polstart X",
         category: "Privat bil",
         registry: "POP88E ",
      },
      functional: {
         type: "OBD",
         alarm: 2,
      },
      health: {
         battery: "18",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Kunakorn Sarab",
         primaryDriver: "Kunakorn Sarab",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 9,
      identity: {
         phone: "0790111222",
         imei: "354017119827117",
         iccid: "54878742125457422120",
      },
      deviceType: {
         code: "FMT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "KLO97W",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "50",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Micheal Miller ",
         primaryDriver: "Suchar Pichai",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 10,
      identity: {
         phone: "0790111222",
         imei: "354018111667329",
         iccid: "58787843231333541122",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "TKO997 ",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "72",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Leonard L",
         primaryDriver: "Samira Leeb",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
   {
      key: 11,
      identity: {
         phone: "0790111222",
         imei: "354018111667329",
         iccid: "58787843231333541122",
      },
      deviceType: {
         code: "TAT100",
      },
      installedVehicle: {
         id: 45,
         brand: "Audi A6",
         category: "Poolbil",
         registry: "TKO997 ",
      },
      functional: {
         type: "OBD",
         alarm: false,
      },
      health: {
         battery: "72",
         lastSeen: "4 minuter",
      },
      driver: {
         checkedIn: "Leonard L",
         primaryDriver: "Samira Leeb",
      },
      belongTo: {
         companyName: "Alphabet.inc",
         since: "2015-06-29",
      },
   },
];

export const vehicles = [
   {
      id: 45,
      category: "POOLCAR",
      registry: "abc123",
   },
   {
      id: 46,
      category: "POOLCAR",
      registry: "tdr344",
   },
   {
      id: 47,
      category: "POOLCAR",
      registry: "mkl432",
   },
];

//MAIN ITEM
const ERRORS = {
   CUSTOMER_SUPPORT: "Kundsupport",
   BATTERY: "Batteri",
   HEART_BEAT: "Heartbeat",
   POWER_VOLTAGE: "Power Voltage",
};

export const mockPersons = {
   P1: createPerson("Billy", "Bilsson", "billy.bilsson@example.com"),
   P2: createPerson("Bob", "Bilsson", "billy.bilsson@example.com"),
   P3: createPerson("Hedvig", "Hedvigsson", "hevdig.hedvigsson@example.com"),
   P4: createPerson("Henry", "Fordsson", "henryfordsson@example.com"),
};

export const mockVehicle = {
   id: 2232,
   category: "PB",
   registry: "test1133",
   stateTag: null,
   state: {
      imei: "352094085129218",
      iccid: "89460856047000096301",
      vin: null,
      phone: "467190001448919",
      odometer: 104726,
   },
   style: {
      color: "#52d03c",
      marker: "androidAlert",
   },
   displayName: "Salminen Petri ",
   currentDriver: {
      id: 2227,
      displayName: "Petri Salminen",
   },
   defaultDriver: {
      id: 2227,
      displayName: "Petri Salminen",
   },
   permittedDrivers: [
      {
         id: 2243,
         displayName: "Jim Byström",
      },
      {
         id: 2227,
         displayName: "Petri Salminen",
      },
   ],
   odometer: {
      state: "AUTO",
      valueKm: 104726,
   },
   defaultTripType: "BUSINESS",
   benefitType: "PRIVATE",
   lastSeen: "2021-06-22T07:30:14",
   permitAnyDriver: false,
};

function createPerson(firstname, lastname, email) {
   return {
      firstname,
      lastname,
      email,
   };
}

function createVehicle(displayName, alias, imei, deviceType, currentOwner) {
   return {
      displayName,
      alias,
      imei,
      deviceType,
      currentOwner,
   };
}

function createItemTabWarning(
   typeError,
   phoneNumber,
   imei,
   firmware,
   config,
   obd,
   battery,
   vehicle,
   driver,
   heartbeat
) {
   return {
      typeError,
      phoneNumber,
      imei,
      firmware,
      config,
      obd,
      battery,
      vehicle,
      driver,
      heartbeat,
   };
}

function createItemTabOnTheWay(organisation, type, imei, vehicle, driver, connected) {
   return {
      organisation,
      type,
      imei,
      vehicle,
      driver,
      connected,
   };
}

function createItemInAction(
   commissioningDate,
   organisation,
   type,
   imei,
   heartBeat,
   vehicle,
   mileAge,
   driver,
   travels
) {
   return {
      commissioningDate,
      organisation,
      type,
      imei,
      heartBeat,
      vehicle,
      mileAge,
      driver,
      travels,
   };
}

// CREATE FM CFG OBD ETC?
export const mockTabWarningItems = {
   ITEM_1: createItemTabWarning(
      ERRORS.CUSTOMER_SUPPORT,
      createRandomPhoneNumber(),
      createRandomIMEI(),
      "fm",
      "cfg",
      "obd",
      createBatteryPercentage(),
      "vehicle",
      "driver",
      createHeartBeat()
   ),
   ITEM_2: createItemTabWarning(
      ERRORS.BATTERY,
      createRandomPhoneNumber(),
      createRandomIMEI(),
      "fm",
      "cfg",
      "obd",
      createBatteryPercentage(),
      "vehicle",
      "driver",
      createHeartBeat()
   ),
   ITEM_3: createItemTabWarning(
      ERRORS.HEART_BEAT,
      createRandomPhoneNumber(),
      createRandomIMEI(),
      "fm",
      "cfg",
      "obd",
      createBatteryPercentage(),
      "vehicle",
      "driver",
      createHeartBeat()
   ),
   ITEM_4: createItemTabWarning(
      ERRORS.POWER_VOLTAGE,
      createRandomPhoneNumber(),
      createRandomIMEI(),
      "fm",
      "cfg",
      "obd",
      createBatteryPercentage(),
      "vehicle",
      "driver",
      createHeartBeat()
   ),
};

// CREATE ORG TYPE ETC
export const mockTabOnTheWayItems = {
   ITEM_1: createItemTabOnTheWay(
      "org",
      "type",
      createRandomIMEI(),
      "vehicle",
      "driver",
      "connected"
   ),
   ITEM_2: createItemTabOnTheWay(
      "org",
      "type",
      createRandomIMEI(),
      "vehicle",
      "driver",
      "connected"
   ),
};

export const mockTabInActionItems = {
   ITEM_1: createItemInAction(
      createCommisionDate(),
      "org",
      "type",
      createRandomIMEI(),
      createHeartBeat(),
      "vehicle",
      createMilage,
      "driver",
      "travels"
   ),
   ITEM_2: createItemInAction(
      createCommisionDate(),
      "org",
      "type",
      createRandomIMEI(),
      createHeartBeat(),
      "vehicle",
      createMilage,
      "driver",
      "travels"
   ),
};

//TODO: CREATE RANDOM CONFIGS
//TODO: CREATE RANDOM CONFIGS FUNCTION
const CONFIGS = {
   CONFIG1: "CONFIG_OBD_1.cfg",
   CONFIG2: "CONFIG_OBD_2.cfg",
   CONFIG3: "CONFIG_OBD_3.cfg",
};

export const TEMP_LOGG = [
   {
      date: "2021-05-06 08:33",
      from: "2021-01-01",
      to: "2021-03-16",
   },
   {
      date: "2021-03-17 08:34",
      from: "2021-03-17",
      to: "2021-06-17",
   },
   {
      date: "2021-03-17 08:34",
      from: "2021-03-17",
      to: "2021-06-17",
   },
   {
      date: "2021-03-17 08:34",
      from: "2021-03-17",
      to: "2021-06-17",
   },
   {
      date: "2021-03-17 08:34",
      from: "2021-03-17",
      to: "2021-06-17",
   },
   {
      date: "2021-03-17 08:34",
      from: "2021-03-17",
      to: "2021-06-17",
   },
   {
      date: "~",
      from: "~",
      to: "~",
   },
];

export const TEMP_LOG_SMS = [
   {
      date_time: "2021-05-06 08:33",
      new_value: "New value 12345",
   },
   {
      date_time: "2021-05-08 08:33",
      new_value: "New value 46544",
   },
   {
      date_time: "2021-05-06 08:40",
      new_value: "New value 98465",
   },
   {
      date_time: "2021-05-07 09:33",
      new_value: "New value 23654",
   },
   {
      date_time: "2021-05-06 08:33",
      new_value: "New value 12345",
   },
   {
      date_time: "2021-05-08 08:33",
      new_value: "New value 46544",
   },
   {
      date_time: null,
      new_value: "New value 98465",
   },
   {
      date_time: "2021-05-07 09:33",
      new_value: null,
   },
];

export const mockTreeDataOrganisation = [
   {
      title: "Organisation 1",
      value: "Organisation 1",
      children: [
         {
            title: "Organisation Child 1",
            value: "Org. child 1",
         },
         {
            title: "Organisation Child 2",
            value: "Org. child 2",
         },
      ],
   },
   {
      title: "Organisation 2",
      value: "Organisation 2",
   },
];

function createRandomPhoneNumber() {
   return `+46 ${Math.floor(Math.random() * 999999999)}`;
}

function createRandomIMEI() {
   return Math.floor(Math.random() * 999999999999999);
}

function createBatteryPercentage() {
   return Math.floor(Math.random() * 100);
}

function createCommisionDate() {
   const ranMonth = Math.floor(Math.random() * 12);
   const ranDay = Math.floor(Math.random() * 31);
   var d = new Date(`2021-${ranMonth}-${ranDay}`);
   return d;
}

function createHeartBeat() {
   const ranDay = Math.floor(Math.random() * 31);
   var d = new Date(`2021-05-${ranDay}`);
   return d;
}

function createMilage() {
   const milage = Math.floor(Math.random() * 23232);
   return `${milage} km`;
}

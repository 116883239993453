export const KNOWN_LOCATION = {
    medis: [59.315687697358946, 18.070856701525805],
    taby: [59.444221552093595, 18.06690916596459],
    tensta: [59.393962926448346, 17.902708263155645],
    jakobsberg: [59.42354186348747, 17.836801771749744],
    karolinska: [59.2214840656898, 17.936377147304395],
    heron: [59.26682334953732, 17.91156579920684],
    handen: [59.169079419829096, 18.139629097077425],
    tyreso: [59.24434253853164, 18.22917765543008],
    tcentralen: [59.33074233144711, 18.059314762738563],
    kristineberg: [59.332873341662065, 18.00294028011099],
    trangsund: [59.23223536469185, 18.125718602191107],
    drottningholm: [59.32263688881373, 17.887179357404417],
}

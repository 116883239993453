import * as A from 'antd';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line
import 'mapbox-gl/dist/mapbox-gl.css';
import React, {useContext, useEffect, useRef, useState} from "react"
import {env} from "../../../configs/EnvironmentConfig"
import {TaxContext} from "../../../services/contexts/tax-context"
import {makeCompassImage} from "../../../gfx"

import * as Icon from '@ant-design/icons';
import Spinner from "../../../components/trackson-components/shared-components/Spinner"
import {Button} from 'antd';


const {DEBUG_ENDPOINT_URL }= env
const {API_ENDPOINT_URL} = env
const antd = A

mapboxgl.accessToken = 'pk.eyJ1IjoidHJhY2tzb24iLCJhIjoiY2t3YjM2OHF6MGJjcjJ2cWt1bDMwZjViMCJ9.nc3cCXyFi5hVSgwXIt6cZA'


const MapboxMap = ({
                       recenter,
                       payStationProps,
                       payStationRef,
                       mapOptions = {},
                       style = {}
                   }) => {
    const mapContainer = useRef(null)
    const mapRef = useRef(null)
    const [mapReady, setMapReady] = useState(false)

    const zoom = 10

    // lnglat, which *should* be standard
    // 59.35210125748006, 18.057864979840804
    /*
     const [center, setCenter] = useState([
     18.057864979840804,
     59.35210125748006
     ])
     */

    useEffect(() => {
        console.debug("Checking center")
        if(!mapRef?.current)
            return
        const {lat, lng} = mapRef.current.getCenter()
        console.debug("Recenter check: ", lng, lat, recenter)
        if (lat !== recenter[1] || lng !== recenter[0]) {
            const args = {center: recenter}
            args.speed = 10.0
            args.zoom = 20
            args.bearing = 0
            args.pitch = 0
            console.log("Recenter changed; Map flyTo = ", args,
                mapRef.current.flyTo(args))
        }
    }, [recenter])

    const imageSize = {
        width: 8,
        height: 40
    }
    const compassImage = React.useMemo(() => makeCompassImage({...imageSize}), [])


    useEffect(() => {
        if (!mapContainer.current)
            return

        if (mapRef.current)
            return

        mapRef.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            // center: recenter,
            zoom,
            options: mapOptions
        })
        mapRef.current.on('load', () => {
            mapRef.current.addImage('needle', {...imageSize, data: compassImage})
            return setMapReady(true)
        })
    }, [mapRef, mapContainer])

    const [previousSources, setPreviousSources] = useState([])
    const [previousLayers, setPreviousLayers] = useState([])

    useEffect(() => {
        if (!payStationProps || !mapRef?.current || !mapReady)
            return

        const map = mapRef.current

        /*
        Object.values(payStationProps).map((p, idx) => {
            const sourceName = 'paystation-' + idx
            const layerName = sourceName + '-layer'
            const dataSource = mapRef.current.getSource(sourceName)
            if (!dataSource) {
                return
            }
            dataSource.setData({
                type: 'FeatureCollection',
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [p.lng, p.lat]
                        }
                    }
                ]
            })
        })
        */

        const newSources = Object.values(payStationProps).map((p, idx) => {
            const sourceName = 'paystation-' + idx

            const source = {
                type: 'FeatureCollection',
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [p.lng, p.lat]
                        }
                    }
                ]
            }

            if(previousSources.includes(sourceName)) {
                map.getSource(sourceName).setData(source)
            }
            else {
                map.addSource(sourceName, {
                    type: 'geojson',
                    data: source
                })
            }
            return sourceName
        })

        const newLayers = Object.values(payStationProps).map((p, idx) => {
            const sourceName = 'paystation-' + idx
            const layerName = sourceName + '-layer'
            const layer = {
                id: layerName,
                type: 'symbol',
                source: sourceName,
                layout: {
                    'icon-image': 'needle',
                    'icon-rotate': p.angleDeg,
                    'icon-allow-overlap': true,
                    'text-field': '' + p.base.cp_id,
                    'text-allow-overlap': true,
                    'text-ignore-placement': true,
                },
                minzoom: 0,
                maxzoom: 24
            }

            if(previousLayers.includes(layerName)) {
                map.removeLayer(layerName)
            }
            map.addLayer(layer)
            return layerName
        })

        previousSources.forEach(p => {
            if (!newSources.includes(p)) {
                map.removeSource(p)
            }
        })
        previousLayers.forEach(p => {
            if (!newLayers.includes(p)) {
                map.removeLayer(p)
            }
        })
        setPreviousLayers(newLayers)
        setPreviousSources(newSources)
    }, [mapRef.current, payStationProps, mapReady])

    useEffect(() => {
        console.debug("MAP CONTAINER RELOAD?", mapContainer)
    }, [mapContainer])

    return <div style={style} ref={mapContainer}/>
}
const NudgeButton = ({icon, action, value}) => {
    return <Button icon={icon} type="default" onClick={action}/>
}
const OverridesTable = ({payStationProps, onStationClick}) => {
    const tc = useContext(TaxContext)
    const applyOverride = (cp_id, fieldName, e) => {
        const value = e?.target?.value
        tc.putStationOverrides(cp_id, {[fieldName]: value}).then(() => {
            tc.reload()
        })
    }

    const increment = (e, p, k, d) => {
        const value = (p.base[k] || 0) + d * (e.shiftKey ? 5 : 1) * (e.ctrlKey ? 25 : 1)
        const overrideArgument = {[k]: value}
        console.debug("INCREMENT", overrideArgument)
        return tc.putStationOverrides(p.base.cp_id, overrideArgument).then(() => {
            tc.reload()
        })
    }

    const inputProps = {
        bordered: false,
        size: 'small',
        style: {display: 'inline'}
    }

    const aProps = {}

    // Not proud
    const incrementFix = (k, d) => (p => {
        return e => increment(e, p, k, d)
    })

    const nudges = {
        west: incrementFix('sweref99tm_e', -0.1),
        south: incrementFix('sweref99tm_n', -0.1),
        north: incrementFix('sweref99tm_n', 0.1),
        east: incrementFix('sweref99tm_e', 0.1),
        ccw: incrementFix('nudge_angle', 1),
        cw: incrementFix('nudge_angle', -1),
    }
    return <table>
        <tr>
            <th>ID</th>
            <th>Nudge</th>
            <th>info</th>
        </tr>
        {payStationProps.map((p, i) => {
            return <tr key={i}>
                <td><a
                    title={p.base.name}
                    onClick={() => onStationClick(p)}>{p.base.cp_id % 100000}</a>
                </td>
                <td>
                    <NudgeButton icon={<Icon.RotateLeftOutlined/>}
                                 action={nudges.ccw(p)}/>
                    <NudgeButton icon={<Icon.LeftOutlined/>}
                                 action={nudges.west(p)}
                                 value={p.base.sweref99tm_e}/>
                    <NudgeButton icon={<Icon.DownOutlined/>}
                                 action={nudges.south(p)}
                                 value={p.base.sweref99tm_e}/>
                    <NudgeButton icon={<Icon.UpOutlined/>}
                                 action={nudges.north(p)}
                                 value={p.base.sweref99tm_e}/>
                    <NudgeButton icon={<Icon.RightOutlined/>}
                                 action={nudges.east(p)}
                                 value={p.base.sweref99tm_e}/>
                    <NudgeButton icon={<Icon.RotateRightOutlined/>}
                                 action={nudges.cw(p)}/>
                </td>
            </tr>
        })}
    </table>
}
export const ConfigureStations = () => {
    const [recenter, setRecenter] = useState([
        11.96710577918244, 57.706335051165645
    ])

    const [mapOptions, setMapOptions] = useState({
        bearingSnap: 360,
        boxZoom: false,
        antialias: false,
        dragRotate: false,
        dragPan: false,
        fadeDuration: 0,
        maxTileCacheSize: 99999999999,
        optimizeForTerrain: true,
        pitchWithRotate: false
    })

    const tc = useContext(TaxContext)
    console.debug("STATIONS", tc.stations)
    return <>
        <antd.Row>
            <antd.Col span={6}>
                <OverridesTable
                    onOverridePut={tc.reload}
                    payStationProps={tc.stations}
                    onStationClick={(p) => {
                        console.debug("Click station", p);
                        setRecenter([p.lng, p.lat])
                    }
                    }/>
            </antd.Col>
            <antd.Col span={18}>
                <antd.Affix offsetTop={20}>
                    <MapboxMap
                        style={{"height": "75vh"}}
                        recenter={recenter}
                        payStationProps={tc.stations}
                        payStationRef={tc.references}
                        mapOptions={mapOptions}
                    />
                </antd.Affix>
                <Button onClick={() => tc.reload()}>Refresh Meta</Button>
            </antd.Col>
        </antd.Row>
    </>
}

import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, Progress, Row, Space, Typography} from "antd";
import moment from "moment";
import {OrganizationContext} from "services/contexts/OrganizationsContext";
import styles from "./styles";
import {OrganizationsTreeSelect} from "components/trackson-components/shared-components/OrganizationsTreeSelect";
import {co2ColorThresholds, graphColor} from "constants/Modules";
import {ORGANIZATION_ID} from "services/redux/constants/authConstants";
import {EcoContext} from "services/contexts/EcoContext";
import {DownloadOutlined, DownOutlined} from "@ant-design/icons";
import Loading from "components/shared-components/Loading/Loading";
import {SelectDefaultVehicle, VehicleContext} from "services/contexts/contexts";
import {ECOSCORE_STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import {VehiclesSelect} from "../shared-components/VehiclesSelect";

const {Title, Text} = Typography;
const {RangePicker} = DatePicker;
const EcoScore = () => {
    const {organizations} = useContext(OrganizationContext);
    const vehicles = useContext(VehicleContext).raw;
    const {ecoScore, getEcoScore, downloadEcoScoreReport} = useContext(EcoContext);
    const defaultVehicle = useContext(SelectDefaultVehicle.context);

    const defaultPickerValues = [moment().subtract("7", "days"), moment()];
    const [selectedOrganizationIds, setSelectedOrganizationids] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);
    const [vehicleList, setVehicleList] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const defaultOrganizationId = parseInt(localStorage.getItem(ORGANIZATION_ID));
    useEffect(() => {
        //Intialized
        if (defaultVehicle) {
            setSelectedVehicleId(defaultVehicle);
        }
        if (vehicles) {
            setVehicleList(vehicles);
        }
        setSelectedDate([defaultPickerValues[0].unix(), defaultPickerValues[1].unix()]);
    }, [vehicles, defaultOrganizationId, defaultVehicle]);
    useEffect(() => {
        if (selectedVehicleId) {
            getEcoScore(selectedDate[0] * 1000, selectedDate[1] * 1000, selectedVehicleId);
        }
    }, [selectedVehicleId, selectedDate]);
    const onChangeSelectedOrganizations = (value) => {
        setSelectedOrganizationids(value);
        if (value.length > 0) {
            const vehicleList = vehicles.filter((d) => value.includes(d.organizationId));
            console.log(vehicleList);
            setVehicleList(vehicleList);
            setSelectedVehicleId(vehicleList[0]?.id);
        } else {
            const vehicleList = vehicles.filter((d) => d.organizationId === defaultOrganizationId);
            setVehicleList(vehicleList);
            setSelectedVehicleId(vehicleList[0]?.id);
        }
    };
    const onChangeDate = (dates) => {
        setSelectedDate([dates[0].unix(), dates[1].unix()]);
    };
    const handleReportMenuClick = () => {
        let tz = moment.tz.guess();
        const tsForm = selectedDate ? selectedDate[0] : defaultPickerValues[0].unix();
        const tsTo = selectedDate ? selectedDate[1] : defaultPickerValues[1].unix();
        const orgIds = selectedOrganizationIds.length !== 0 ? selectedOrganizationIds : parseInt(localStorage.getItem(ORGANIZATION_ID));
        downloadEcoScoreReport(tsForm, tsTo, tz, orgIds);
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between",  marginBottom: 20, flexWrap: "wrap", alignItems: "start"}}>
                <Typography.Title>Ecoscore</Typography.Title>
                <div style={{display: "flex", gap: 15, alignItems: "center", flexWrap: "wrap"}}>
                    {!organizations ? null : (
                        <OrganizationsTreeSelect data={organizations} onChange={(e) => onChangeSelectedOrganizations(e)}
                                                 selectedOrganization={selectedOrganizationIds} isMultiple={true}/>
                    )}

                    <VehiclesSelect
                        data={vehicleList}
                        onChange={(e) => setSelectedVehicleId(e)}
                        value={selectedVehicleId}/>

                    <RangePicker dropdownClassName={"one-month"} onChange={(dates) => onChangeDate(dates)}
                                 defaultValue={defaultPickerValues}/>
                    <Button icon={<DownloadOutlined/>} onClick={handleReportMenuClick}>
                        Exportera alla
                    </Button>
                </div>
            </div>
            {!ecoScore ? (
                <Loading/>
            ) : (
                <>
                    <Row gutter={[16, 16]} style={{...styles.ecoContent}}>
                            <Card bordered={false} style={{...styles.card}}>
                                <Title level={2}>Total</Title>
                                <Progress type="dashboard" percent={ecoScore.total}
                                          strokeColor={graphColor(ecoScore.total)}/>
                                <br/>
                                <Text>{ECOSCORE_STATIC_TEXT.AVERAGE_AV_ECOSCORE_FOR_PERIOD}</Text>
                                <br/>
                                <Text type="secondary" style={{fontSize: ".7rem"}}>
                                    {ECOSCORE_STATIC_TEXT.TOTAL_AVERAGE}
                                </Text>
                            </Card>
                        <Card bordered={false} style={{...styles.card}}>
                            <Title level={3}>Totalt CO2-utsläpp</Title>
                            <br/>
                            <Text style={{...styles.dataDisplay, color: SD_BASE_COLORS.BLUE}}>
                                {ecoScore.totalCo2Kg !== null && ecoScore.totalCo2Kg > 0 ? ecoScore.totalCo2Kg.toFixed(2) : "-"}
                            </Text>
                            <br/>
                            <Text style={{...styles.dataSubDisplay}}>Kg</Text>
                            <br/>
                            <br/>
                            <Text type="secondary" style={{...styles.dataDescription}}>
                                Totalt utsläpp för perioden
                            </Text>
                        </Card>
                            <Card bordered={false} style={{...styles.card}}>
                                <Title level={3}>Genomsnittligt CO2-utsläpp</Title>
                                <br/>
                                <Text style={{
                                    ...styles.dataDisplay,
                                    color: co2ColorThresholds(ecoScore.vehicleCo2GKm)
                                }}>{ecoScore.vehicleCo2GKm !== null ? ecoScore.vehicleCo2GKm : "-"}</Text>
                                <br/>
                                <Text style={{...styles.dataSubDisplay}}>g/Km</Text>
                                <br/>
                                <br/>
                                <Text type="secondary" style={{...styles.dataDescription}}>
                                    Fordonets genomsnittliga CO2-utsläpp
                                </Text>
                                <br/>
                            </Card>

                            <Card bordered={false} style={{...styles.card}}>
                                <Title level={3}>{ECOSCORE_STATIC_TEXT.CURVE_TAKING}</Title>
                                <Progress type="dashboard" percent={ecoScore.cornering}
                                          strokeColor={graphColor(ecoScore.cornering)}/>
                                <br/>
                                <Text>{ECOSCORE_STATIC_TEXT.BAD_CURVE_TAKING_REDUCES_YOUR_ECOSCORE}</Text>
                                <br/>
                            </Card>
                            <Card bordered={false} style={{...styles.card}}>
                                <Title level={3}>{ECOSCORE_STATIC_TEXT.ACCELERATION}</Title>
                                <Progress type="dashboard" percent={ecoScore.acceleration}
                                          strokeColor={graphColor(ecoScore.acceleration)}/>
                                <br/>
                                <Text>{ECOSCORE_STATIC_TEXT.RAPID_ACCELERATION_REDUCES_YOUR_ECOSCORE}</Text>
                                <br/>
                            </Card>
                            <Card bordered={false} style={{...styles.card}}>
                                <Title level={3}>{ECOSCORE_STATIC_TEXT.BRAKING}</Title>
                                <Progress type="dashboard" percent={ecoScore.breaking}
                                          strokeColor={graphColor(ecoScore.breaking)}/>
                                <br/>
                                <Text>{ECOSCORE_STATIC_TEXT.STARK_BRAKING_REDUCES_YOUR_ECOSCORE}</Text>
                                <br/>
                            </Card>
                    </Row>
                </>
            )}
        </>
    );
};
export default EcoScore;

import {Nest} from "../utils/utilities";
import PurposeController from "../components/trackson-components/Purpose";

const TripPurposePage = () => {
    return (
        <Nest
            wrappers={[
            ]}>
            <PurposeController/>
        </Nest>
    );
};

export default TripPurposePage;

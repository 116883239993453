import {useEffect, useState} from "react";
import {dataApi} from "../../../services/api/api";
import {ORGANIZATION_ID} from "../../../services/redux/constants/authConstants";
import {EmailRecipients, VehiclesTreeStructure} from "../shared-components/ItemsSelectors";
import moment from "moment"
import {Button, Divider, Form, Input, message, Select, Typography} from "antd";
import {styles} from "./styles";
import {reportsTypesLookUp, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {reportsTypes, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";

const {Option} = Select;
const {Title} = Typography;

export const NewScheduleForm = (props) => {
    const [form] = Form.useForm();
    const [showFields, setShowFields] = useState(defaultShowFields);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [checkedKeysDrivers, setCheckedKeysDrivers] = useState([]);
    const [emailData, setEmailData] = useState([]);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [reportType, setReportType] = useState();

    useEffect(() => {
        setCheckedKeys([]);
        setCheckedKeysDrivers([]);
    }, [props.selectedOrganization, reportType]);

    useEffect(() => {
        getFieldsToShow();
    }, [reportType]);

    useEffect(() => {
        if (emailData?.length > 0 && (checkedKeys?.length > 0 || checkedKeysDrivers.length > 0)) {
            setSubmitButtonDisabled(false);
        } else {
            setSubmitButtonDisabled(true);
        }
    }, [emailData, checkedKeys, checkedKeysDrivers]);
    const handleOnScheduleSave = (values) => {
        if (checkedKeys.length > 0) {
            const isOrgSelected = checkedKeys?.some((i) => typeof i === "string");
            if (checkedKeys?.includes("-1")) {
                const orgIds = [];
                const vehicles = checkedKeys?.filter((org) => Number.isInteger(org));
                for (let i of props?.knownVehicles) {
                    for (let x of vehicles) {
                        if (i?.id === x) {
                            orgIds.push(i.organizationId);
                        }
                    }
                }
                values.reportTarget = null;
                values.reportTargetOrganizations = orgIds;
            }

            if (isOrgSelected && !checkedKeys?.includes("-1")) {
                const selectedOrg = [];
                const selectedVehicles = [];
                const reportTarget = [];
                const orgId = checkedKeys.filter((r) => typeof r === "string");
                const str = orgId.map((item) => item.replace("org:", ""));
                str.forEach((i) => selectedOrg.push(Number(i)));
                for (let k of checkedKeys) {
                    if (typeof r !== "string") {
                        for (let v of props.knownVehicles) {
                            if (k === v.id) {
                                selectedVehicles.push(v);
                            }
                        }
                    }
                }
                selectedVehicles.filter((element) => !selectedOrg.includes(element.organizationId)).forEach((i) => reportTarget.push(i.id));
                values.reportTarget = reportTarget;
                values.reportTargetOrganizations = selectedOrg;
            }
        }
        if (checkedKeysDrivers.length > 0) {
            const isOrgSelected = checkedKeysDrivers?.some((i) => typeof i === "string");
            if (checkedKeysDrivers?.includes("-1")) {
                const orgIds = [];
                const drivers = checkedKeysDrivers?.filter((org) => Number.isInteger(org));
                for (let i of props?.knownUsers) {
                    for (let x of drivers) {
                        if (i?.userId === x) {
                            orgIds.push(i.organizationId);
                        }
                    }
                }
                values.reportTargetDrivers = null;
                values.reportTargetDriverOrganizations = orgIds;
            }

            if (isOrgSelected && !checkedKeysDrivers?.includes("-1")) {
                const selectedOrg = [];
                const selectedDrivers = [];
                const reportTargetDrivers = [];
                const orgId = checkedKeysDrivers.filter((r) => typeof r === "string");
                const str = orgId.map((item) => item.replace("org:", ""));
                str.forEach((i) => selectedOrg.push(Number(i)));
                for (let k of checkedKeysDrivers) {
                    if (typeof r !== "string") {
                        for (let v of props.knownUsers) {
                            if (k === v.userId) {
                                selectedDrivers.push(v);
                            }
                        }
                    }
                }
                selectedDrivers.filter((element) => !selectedOrg.includes(element.organizationId)).forEach((i) => reportTargetDrivers.push(i.userId));
                values.reportTargetDrivers = reportTargetDrivers;
                values.reportTargetDriverOrganizations = selectedOrg;
            }
        }
        dataApi.postReport(values).then((i) => {
            props.setKnownReports([...props.knownReports, i]);
            message.success(STATIC_TEXT.REPORT_CREATED);
        });
        setTimeout(() => {
            window.location.reload();
        }, 150);
    };

    function resetFields() {
        form?.resetFields();
        setCheckedKeys([]);
        setCheckedKeysDrivers([]);
        setEmailData([]);
        setReportType(null);
    }

    function getFieldsToShow() {
        let show;

        switch (reportType) {
            case reportsTypes.SUMMARY_VEHICLE:
                show = {
                    vehicles: true,
                    drivers: false,
                };
                break;
            case reportsTypes.SUMMARY_DRIVER:
                show = {
                    vehicles: false,
                    drivers: true,
                };
                break;
            case reportsTypes.SALARY:
                show = {
                    vehicles: false,
                    drivers: true,
                };
                break;
            default:
                show = defaultShowFields;
        }

        setShowFields(show);
    }

    const onFinish = (values) => {
        const emails = [];
        emailData.map((i) => {
            emails.push(i.email);
        });

        const organizationLS = localStorage.getItem(ORGANIZATION_ID);
        const zoneId = moment.tz.guess();

        const newSchedule = {
            schedule: values.schedule,
            name: values.name,
            reportType: values.reportType,
            reportTarget: checkedKeys.filter((i) => Number.isInteger(i)),
            reportTargetDrivers: checkedKeysDrivers.filter((i) => Number.isInteger(i)),
            email: emails,
            organizationId: props.selectedOrganization || organizationLS,
            zoneId: zoneId,
        };
        handleOnScheduleSave(newSchedule);
        resetFields();
    };

    return (
        <>
            <Title level={3}>{STATIC_TEXT.CREATE_NEW_SCHEDULE}</Title>
            <Form form={form} onFinish={onFinish}>
                <div style={styles.scheduleForm}>
                    <div style={styles.column}>
                        <ReportsType setReportType={setReportType}/>

                        {showFields.vehicles && (
                            <VehiclesTreeStructure
                                showTitle={STATIC_TEXT.CHOOSE_VEHICLES}
                                checkedKeys={checkedKeys}
                                setCheckedKeys={setCheckedKeys}
                                knownVehicles={props.knownVehicles}
                                knownOrganizations={props.knownOrganizations}
                                selectedOrganization={props.selectedOrganization}
                                targetOrganizations={true}
                            />
                        )}

                        {showFields.drivers && (
                            <VehiclesTreeStructure
                                showTitle={STATIC_TEXT.CHOOSE_DRIVERS}
                                checkedKeys={checkedKeysDrivers}
                                setCheckedKeys={setCheckedKeysDrivers}
                                knownVehicles={props.knownUsers}
                                drivers={true}
                                knownOrganizations={props.knownOrganizations}
                                selectedOrganization={props.selectedOrganization}
                            />
                        )}
                    </div>

                    <div style={styles.column}>
                        <EmailRecipients emailData={emailData} setEmailData={setEmailData}/>
                        <Interval/>
                        <Divider dashed/>
                        <DisplayReportsName/>
                        <div style={styles.saveButton}>
                            <Button htmlType="submit" type="primary" disabled={submitButtonDisabled}>
                                {STATIC_TEXT.CREATE_NEW_SCHEDULE}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};
let defaultShowFields = {
    vehicles: true,
    drivers: true,
};
const ReportsType = ({setReportType}) => {
    function handleChange(value) {
        setReportType(value);
    }

    return (
        <>
            <Title level={4}>{STATIC_TEXT.REPORTS_TYPE}</Title>
            <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.reportType} initialValue={"JOURNAL"}>
                <Select style={{width: "50%"}} onChange={handleChange}>
                    {Object.entries(reportsTypesLookUp).map(([i, k]) => {
                        return <Option value={i}>{k}</Option>;
                    })}
                </Select>
            </Form.Item>
        </>
    );
};
const DisplayReportsName = () => {
    let defaultDisplayName = STATIC_TEXT.MONTHLY_REPORT;
    return (
        <div>
            <Title level={4}>{STATIC_TEXT.ALIAS}</Title>
            <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.name} initialValue={defaultDisplayName}>
                <Input style={styles.input} placeholder={STATIC_TEXT.SUPPLY_AN_ALIAS}/>
            </Form.Item>
        </div>
    );
};
const Interval = () => {
    function handleChange(value) {
    }

    const children = [];
    for (let i = 1; i < 32; i++) {
        children.push(<Option key={i}>{i}:e</Option>);
    }

    return (
        <div>
            <Title level={4}>{STATIC_TEXT.TIMEPOINT}</Title>
            <div style={styles.interval}>
                <p style={styles.paragraph}>{STATIC_TEXT.THIS}</p>
                <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.schedule} initialValue={"1"} style={styles.selectDay}>
                    <Select onChange={handleChange}>{children}</Select>
                </Form.Item>
                <p style={styles.paragraph}>{STATIC_TEXT.EVERY_MONTH}</p>
            </div>
        </div>
    );
};
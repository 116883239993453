import SettingsPanel from "../trackson-components/SettingsPanel";
import {Nest} from "../../utils/utilities";

const SettingsPanelView = () => {
    return (
        <Nest
            wrappers={[
            ]}>
            <SettingsPanel/>
        </Nest>
    );
};

export default SettingsPanelView;

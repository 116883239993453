import React, { useContext, useState, useRef, useEffect } from "react";
import { Modal, Typography } from "antd";
import { GeoContext } from "services/contexts/GeoContext";
import styles from "../../styles";
import GoogleMapReact from "google-map-react";
import { BOOTSTRAP_URL_KEYS } from "configs/AppConfig";
import { mapOptions, setLightMapType } from "configs/mapStyle";
import {createBoundsFromZonesData} from "../../../Zones/Zones";
const { Text } = Typography;
const GeozoneModal = ({ visible, onClose = () => null }) => {
    const mapRef = useRef(null);
    const { geozone } = useContext(GeoContext);
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [polygons, setPolygons] = useState([]);
    useEffect(() => {
        if (maps && map && geozone) {
            if (polygons?.length > 0) {
                polygons.map((item) => {
                    item.setMap(null);
                });
            }

            const path = [];
            geozone?.map((alarm) => {
                initPolygons(maps, map, alarm);
                alarm?.zoneCoords?.map((item) => {
                    return path.push({
                        lat: item.latitude,
                        lng: item.longitude,
                    });
                });
            });
            const bounds = createBoundsFromZonesData(maps, path);
            map.fitBounds(bounds);
        }
    }, [map, maps, geozone]);

    function initPolygons(maps, map, alarm) {
        const path = alarm?.zoneCoords?.map((item) => {
            return {
                lat: item.latitude,
                lng: item.longitude,
            };
        });

        const newPolygons = new maps.Polygon({
            map,
            paths: path,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            draggable: false,
            geodesic: true,
            editable: false,
        });

        const pushPolygons = polygons;
        pushPolygons.push(newPolygons);
        setPolygons(pushPolygons);
    }
    return (
        <Modal title="Geozoner" visible={visible} onOk={onClose} onCancel={onClose} width={800}>
            {geozone.length <= 0 ? (
                <Text>Inga Geozoner för valt fordon</Text>
            ) : (
                <div style={{ ...styles.mapContent }}>
                    <GoogleMapReact
                        ref={mapRef}
                        bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                        center={{
                            lat: 59.57053037008066,
                            lng: 16.391671447753904,
                        }}
                        zoom={5}
                        options={mapOptions}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => {
                            setLightMapType(maps, map);
                            setMap(map);
                            setMaps(maps);
                        }}
                    ></GoogleMapReact>
                </div>
            )}
        </Modal>
    );
};

export default GeozoneModal;

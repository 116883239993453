import React from "react";
import * as Icons from "@ant-design/icons";
import {BellOutlined, CarOutlined, ScheduleOutlined} from "@ant-design/icons";
import {APP_PREFIX_PATH} from "configs/AppConfig";

import {mapO} from "react-icons-kit/fa/mapO";
import {edit} from "react-icons-kit/fa/edit";

import {env} from "configs/EnvironmentConfig";
import {ORGANIZATION_ID} from "../services/redux/constants/authConstants";

const disabled = true;
const fixRecursive = (e) => {
    if (e.submenu && e.submenu.length === 0) return e;
    if (!e.submenu) return {...e, submenu: []};
    return {
        ...e,
        submenu: e.submenu.map(fixRecursive),
    };
};

let dashBoardNavTreeCollapsed = [
    {
        key: 'homeentries',
        title: 'Trackson',
        submenu: [
            {
                key: 'journey',
                path: `${APP_PREFIX_PATH}/journey`,
                title: 'Körjournal',
                icon: Icons.NodeIndexOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'map',
                path: `${APP_PREFIX_PATH}/map`,
                title: 'Karta',
                icon: Icons.EnvironmentOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'ecoScore',
                icon: Icons.CheckCircleOutlined,
                path: `${APP_PREFIX_PATH}/ecoScore`,
                title: 'Ecoscore',
                breadcrumb: false,
            },
            {
                key: 'vehicle',
                path: `${APP_PREFIX_PATH}/myVehicle`,
                title: 'Mina fordon',
                icon: CarOutlined,
                breadcrumb: false,
                submenu: [],
            },
        ],
    },
    {
        key: 'defaultings',
        title: 'Administration',
        submenu: [
            {
                key: "accounts",
                title: "Min organisation",
                limitVisibility: ["ADMIN", "ROOT", "HEXA"],
                icon: Icons.ApartmentOutlined,
                submenu: [
                    {
                        key: "userroles",
                        title: "Användare",
                        path: `${APP_PREFIX_PATH}/userroles`,
                    },
                    {
                        key: "vehicles",
                        title: "Fordon",
                        path: `${APP_PREFIX_PATH}/vehicles`,
                    },
                    {
                        key: "trackersList",
                        title: "Utrustning",
                        icon: Icons.AimOutlined,
                        path: `${APP_PREFIX_PATH}/trackersList`,
                    },
                    ...((env.SHOW_DEV && [
                            {
                                key: "devices",
                                title: "Enheter",
                                path: `${APP_PREFIX_PATH}/devices`,
                            },
                        ]) ||
                        []),
                    {
                        key: "account",
                        title: "Konto",
                        path: `${APP_PREFIX_PATH}/account`,
                    },
                ],
            },
            {
                key: 'schedule',
                title: 'Förval',
                breadcrumb: false,
                icon: Icons.ControlOutlined,
                submenu: [
                    {
                        key: 'schedule',
                        path: `${APP_PREFIX_PATH}/schedule`,
                        title: 'Arbetstidsscheman',
                        breadcrumb: false,
                    },
                    {
                        key: 'zones',
                        path: `${APP_PREFIX_PATH}/zones`,
                        title: 'Platser',
                        breadcrumb: false,
                    },
                    {
                        key: 'purpose',
                        path: `${APP_PREFIX_PATH}/purpose`,
                        title: 'Resesyften',
                        breadcrumb: false,
                    },
                ]
            },
            {
                key: "alarms",
                title: "Larm",
                limitVisibility: ["ADMIN", "ROOT", "HEXA"],
                icon: Icons.AlertOutlined,
                submenu: [
                    {
                        key: "alarmZones",
                        path: `${APP_PREFIX_PATH}/alarmsZones`,
                        title: "Geozon",
                        breadcrumb: false,
                        disabled: false,
                    },
                    {
                        key: "alarmFunctions",
                        path: `${APP_PREFIX_PATH}/alarmsFunctions`,
                        title: "Funktionslarm",
                        disabled: false,
                        breadcrumb: false,
                    },
                    {
                        key: "alarmSafety",
                        path: `${APP_PREFIX_PATH}/alarmsSafety`,
                        title: "Säkerhetslarm",
                        breadcrumb: false,
                    },
                    // ...(env.SHOW_DEV && [{
                    //     key: 'alarmsQuality',
                    //     icon: Icons.SafetyCertificateOutlined,
                    //     path: `${APP_PREFIX_PATH}/alarmsQuality`,
                    //     title: 'Kvalitet',
                    //     breadcrumb: false
                    // }] || []),
                    {
                        key: "alarmsEvents",
                        path: `${APP_PREFIX_PATH}/alarmsEvents`,
                        title: "Pågående larm",
                        breadcrumb: false,
                    },
                ],
            },
            {
                key: "reports",
                title: "Rapporter",
                limitVisibility: ["ADMIN", "ROOT", "HEXA"],
                icon: Icons.FileExcelOutlined,
                submenu: [
                    {
                        key: "scheduling",
                        path: `${APP_PREFIX_PATH}/scheduling`,
                        title: "Schemaläggning",
                        breadcrumb: false,
                    },
                    {
                        key: "salaryReports",
                        path: `${APP_PREFIX_PATH}/salaryReports`,
                        title: "Lönerapporter",
                        breadcrumb: false,
                    },
                ],
            },
            {
                key: "customer-service",
                title: "Support",
                icon: Icons.CustomerServiceOutlined,
                submenu: [
                    {
                        key: "support",
                        title: "Kundtjänst",
                        path: `${APP_PREFIX_PATH}/support`,
                    },
                    {
                        key: "alarmsReport",
                        path: `${APP_PREFIX_PATH}/alarmsReport`,
                        title: "Felanmälan",
                        breadcrumb: false,
                    },
                    {
                        key: 'guides',
                        title: 'Guider',
                        path: `${APP_PREFIX_PATH}/guides`,
                    },
                ],
            },

        ],
    },
    {
        key: "superadmin",
        title: "Superadmin",
        limitVisibility: ["ROOT", "HEXA"],
        submenu: [
            {
                key: "organizations",
                title: "Organisationer",
                icon: Icons.DatabaseOutlined,
                path: `${APP_PREFIX_PATH}/organizations`,
            },
            {
                key: "superdevices",
                title: "Enheter",
                icon: Icons.AppstoreOutlined,
                path: `${APP_PREFIX_PATH}/superdevices`,
            },
            {
                key: "users",
                title: "Användare",
                icon: Icons.TeamOutlined,
                path: `${APP_PREFIX_PATH}/users`,
            },
            {
                key: "superVehicles",
                title: "Fordon",
                icon: Icons.CarOutlined,
                path: `${APP_PREFIX_PATH}/superVehicles`,
            },
            {
                key: "triptax-analyser",
                title: "Betalstationer",
                iconExternal: mapO,
                path: `${APP_PREFIX_PATH}/triptax-analyzer`,
            },
        ],
    },
].map(fixRecursive);

let dashBoardNavTreeOpen = [
    {
        key: 'homeentries',
        title: 'Trackson',
        submenu: [
            {
                key: 'journey',
                path: `${APP_PREFIX_PATH}/journey`,
                title: 'Körjournal',
                icon: Icons.NodeIndexOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'map',
                path: `${APP_PREFIX_PATH}/map`,
                title: 'Karta',
                icon: Icons.EnvironmentOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: 'ecoScore',
                icon: Icons.CheckCircleOutlined,
                path: `${APP_PREFIX_PATH}/ecoScore`,
                title: 'Ecoscore',
                breadcrumb: false,
            },
            {
                key: 'vehicle',
                path: `${APP_PREFIX_PATH}/myVehicle`,
                title: 'Mina fordon',
                icon: CarOutlined,
                breadcrumb: false,
                submenu: [],
            },
        ],
    },
    {
        key: 'defaultings',
        title: 'Förval',
        submenu: [
            {
                key: 'schedule',
                icon: ScheduleOutlined,
                path: `${APP_PREFIX_PATH}/schedule`,
                title: 'Arbetstidsscheman',
                breadcrumb: false,
            },
            {
                key: 'zones',
                icon: Icons.PushpinOutlined,
                path: `${APP_PREFIX_PATH}/zones`,
                title: 'Platser',
                breadcrumb: false,
            },
            {
                key: 'purpose',
                iconExternal: edit,
                path: `${APP_PREFIX_PATH}/purpose`,
                title: 'Resesyften',
                breadcrumb: false,
            },
        ],
    },
    {
        key: "alarms",
        title: "Larm",
        limitVisibility: ["ADMIN", "ROOT", "HEXA"],
        submenu: [
            {
                key: "alarmZones",
                icon: Icons.RadarChartOutlined,
                path: `${APP_PREFIX_PATH}/alarmsZones`,
                title: "Geozon",
                breadcrumb: false,
                disabled: false,
            },
            {
                key: "alarmFunctions",
                icon: Icons.WarningOutlined,
                path: `${APP_PREFIX_PATH}/alarmsFunctions`,
                title: "Funktionslarm",
                disabled: false,
                breadcrumb: false,
            },
            {
                key: "alarmSafety",
                icon: Icons.SafetyCertificateOutlined,
                path: `${APP_PREFIX_PATH}/alarmsSafety`,
                title: "Säkerhetslarm",
                breadcrumb: false,
            },
            // ...(env.SHOW_DEV && [{
            //     key: 'alarmsQuality',
            //     icon: Icons.SafetyCertificateOutlined,
            //     path: `${APP_PREFIX_PATH}/alarmsQuality`,
            //     title: 'Kvalitet',
            //     breadcrumb: false
            // }] || []),
            {
                key: "alarmsEvents",
                icon: BellOutlined,
                path: `${APP_PREFIX_PATH}/alarmsEvents`,
                title: "Pågående larm",
                breadcrumb: false,
            },
        ],
    },
    {
        key: "reports",
        title: "Rapporter",
        limitVisibility: ["ADMIN", "ROOT", "HEXA"],
        submenu: [
            {
                key: "scheduling",
                icon: Icons.CalendarOutlined,
                path: `${APP_PREFIX_PATH}/scheduling`,
                title: "Schemaläggning",
                breadcrumb: false,
            },
            {
                key: "salaryReports",
                icon: Icons.FileExcelOutlined,
                path: `${APP_PREFIX_PATH}/salaryReports`,
                title: "Lönerapporter",
                breadcrumb: false,
            },
        ],
    },
    {
        key: "customer-service",
        title: "Support",
        submenu: [
            {
                key: "support",
                title: "Kundtjänst",
                icon: Icons.CustomerServiceOutlined,
                path: `${APP_PREFIX_PATH}/support`,
            },
            {
                key: "alarmsReport",
                icon: Icons.ToolOutlined,
                path: `${APP_PREFIX_PATH}/alarmsReport`,
                title: "Felanmälan",
                breadcrumb: false,
            },
            {
                key: 'guides',
                title: 'Guider',
                icon: Icons.QuestionCircleOutlined,
                path: `${APP_PREFIX_PATH}/guides`,
            },
        ],
    },
    {
        key: "accounts",
        title: "Organisation",
        limitVisibility: ["ADMIN", "ROOT", "HEXA"],
        submenu: [
            {
                key: "userroles",
                title: "Användare",
                icon: Icons.TeamOutlined,
                path: `${APP_PREFIX_PATH}/userroles`,
            },
            {
                key: "vehicles",
                title: "Fordon",
                icon: Icons.CarOutlined,
                path: `${APP_PREFIX_PATH}/vehicles`,
            },
            {
                key: "trackersList",
                title: "Utrustning",
                icon: Icons.AimOutlined,
                path: `${APP_PREFIX_PATH}/trackersList`,
            },
            ...((env.SHOW_DEV && [
                    {
                        key: "devices",
                        title: "Enheter",
                        icon: Icons.ApiOutlined,
                        path: `${APP_PREFIX_PATH}/devices`,
                    },
                ]) ||
                []),
            {
                key: "account",
                title: "Konto",
                icon: Icons.ApartmentOutlined,
                path: `${APP_PREFIX_PATH}/account`,
            },
        ],
    },
    {
        key: "superadmin",
        title: "Superadmin",
        limitVisibility: ["ROOT", "HEXA"],
        submenu: [
            {
                key: "users",
                title: "Användare",
                icon: Icons.TeamOutlined,
                path: `${APP_PREFIX_PATH}/users`,
            },
            {
                key: "superVehicles",
                title: "Fordon",
                icon: Icons.CarOutlined,
                path: `${APP_PREFIX_PATH}/superVehicles`,
            },
            {
                key: "superTrackersList",
                title: "Utrustning",
                icon: Icons.AimOutlined,
                path: `${APP_PREFIX_PATH}/superTrackersList`,
            },
            {
                key: "superdevices",
                title: "Enheter",
                icon: Icons.ApiOutlined,
                path: `${APP_PREFIX_PATH}/superdevices`,
            },
            {
                key: "organizations",
                title: "Organisationer",
                icon: Icons.DatabaseOutlined,
                path: `${APP_PREFIX_PATH}/organizations`,
            },
            {
                key: "triptax-analyser",
                title: "Betalstationer",
                iconExternal: mapO,
                path: `${APP_PREFIX_PATH}/triptax-analyzer`,
            },
        ],
    },
].map(fixRecursive);

let dashBoardNavTree = localStorage.getItem("MENU_TYPE") === "true" ? dashBoardNavTreeCollapsed : dashBoardNavTreeOpen

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;

import React, {useEffect, useRef, useState} from "react";
import {dataApi} from "../../../services/api/api";
import {darkMapStyle, mapOptions, setLightMapType} from "../../../configs/mapStyle";
import {MAP_TYPE} from "../../../services/redux/constants/authConstants";
import {BOOTSTRAP_URL_KEYS} from "../../../configs/AppConfig";
import {createBoundsFromZonesData} from "./Zones";
import {Button, Form, Input, Menu, message, Select, Slider, Switch, Table, Tooltip, Typography,} from 'antd';
import {ButtonMenuDropdown} from "../shared-components/sharedComponents";

import GoogleMap from 'google-map-react';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {applicationsNames, STATIC_TEXT, tripTypes, ZONES_STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {CONSTANTS_TEXT, SD_BASE_COLORS, ZONES_CONSTANTS} from "../../../constants/CustomConstant";

export const LocationList = ({locations, setLocations, editKey, setEditKey, userId, ...props}) => {
    const [getRadius, setRadius] = useState()
    const [coordinate, setCoordinate] = useState()
    const [address, setAddress] = useState(null)

    useEffect(() => {
        if (locations) {
            if (locations.length > 0) {
                setLocations(locations)
            }
        }
    }, [])

    useEffect(() => {
        setAddress(null)
    }, [editKey])

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px'}}>
            </div>
            <LocationMap setAddress={setAddress} setCoordinate={setCoordinate} editKey={editKey}
                         showLocations={locations?.length > 0 ? locations : []} setRadius={setRadius}
                         radiusMs={getRadius} showMap={props.showMap}/>
            <br/>
            <LocationTable userId={userId} selectedId={props.selectedId} setAddress={setAddress} address={address}
                           data={locations}
                           getRadius={getRadius} setEditKey={setEditKey} editKey={editKey} setData={setLocations}
                           coordinate={coordinate} setShowMap={props.setShowMap}/>
        </div>
    );
};
const LocationMap = (props) => {
    const [map, setMap] = useState()
    const [maps, setMaps] = useState()
    const editingKey = props.editKey
    const [center, setNewCenter] = useState([59.334591, 18.06324]);
    const [routePolyline, setRoutePolyline] = useState(null);
    let filteredOverviewData = props.showLocations
    let circle;

    useEffect(() => {
        if (!map || !maps) {
            return
        }

        maps?.event.trigger(map, 'resize')
        map?.setOptions({...mapOptions, styles: localStorage.getItem(MAP_TYPE) === 'DARK' ? darkMapStyle : null})
        setLightMapType(maps, map)
    }, [localStorage.getItem(MAP_TYPE), map, maps]);

    const changeRadius = (_) => {
        const radiusMs = routePolyline[0]?.getRadius().toFixed()
        props.setRadius(Number(radiusMs))
    }

    const setCenter = (r) => {
        if (props.coordinate !== ([routePolyline[0].getCenter().lat(), routePolyline[0].getCenter().lng()])) {

            if (!r) {
                r = {lat: routePolyline[0].getCenter().lat(), lng: routePolyline[0].getCenter().lng()}
            }

            const location = {lat: r.lat, lng: r.lng}

            if (props.showMap && routePolyline) {
                props.setCoordinate([r.lat, r.lng])
                const geocoder = new maps.Geocoder();

                setTimeout(() => {
                    geocodeLatLng(geocoder, maps, location);
                }, 1500);
            }
        }
    }

    const onMapClick = (r) => {

        if (props.editKey) {

            const location = {lat: r.latLng.lat(), lng: r.latLng.lng()}
            routePolyline[0]?.setCenter(location)
            setCenter(location)
        }

    }


    if (!props.showMap) {
        props.setCoordinate(null)
    }

    useEffect(() => {

        if (routePolyline?.length > 0) {
            maps?.event.addListener(routePolyline[0], 'radius_changed', changeRadius)
            maps?.event.addListener(routePolyline[0], 'center_changed', setCenter)
            maps?.event.addListener(map, "dblclick", onMapClick);
        }

    }, [routePolyline])


    useEffect(() => {
        if (!(map && maps && filteredOverviewData)) {
            return
        }

        if (!editingKey) {
            setNewCenter(null)
        }

        if (editingKey) {
            filteredOverviewData = filteredOverviewData?.filter(i => i.zoneId === props.editKey)

        }
        updateRoutePolyline({map, maps, filteredOverviewData})
    }, [map, maps, filteredOverviewData, props.editKey]);

    const updateRoutePolyline = ({map, maps, filteredOverviewData}) => {
        if (routePolyline) {
            routePolyline.map(i => {
                i?.setMap(null)
            })
        }
        let newRoutePolyline = []
        {
            filteredOverviewData?.sort((a, b) => b.radiusMs - a.radiusMs)
                .map((l, _) => {

                    const draggable = l.zoneId === editingKey
                    if (draggable) {
                        setNewCenter([l.latitude, l.longitude])
                        props.setRadius(l.radiusMs)
                    }

                    circle = new maps.Circle({
                        strokeColor: l.tripType === CONSTANTS_TEXT.BUSINESS ? '#17bcff' : SD_BASE_COLORS.SWITCH_PRIVATE,
                        strokeOpacity: 0.8,
                        strokeWeight: 0.5,
                        fillColor: l.tripType === CONSTANTS_TEXT.BUSINESS ? SD_BASE_COLORS.SWITCH_BUSINESS : '#0C6D44',
                        fillOpacity: 0.3,
                        map: map,
                        draggable: false,
                        editable: draggable,
                        center: {lat: l.latitude, lng: l.longitude},
                        radius: l.radiusMs,
                        geodesic: true

                    })

                    const address = l.address || "-"
                    const contentString =
                        '<div id="content">' +
                        '<div id="siteNotice">' +
                        l.zoneName +
                        '<div id="bodyContent">' +
                        address
                        +
                        "</div>" +
                        "</div>";
                    const infowindow = new maps.InfoWindow({
                        content: contentString,
                        position: {lat: l.latitude, lng: l.longitude},
                    });

                    if (!draggable) {
                        circle.addListener("mouseover", () => {
                            infowindow.open(map, circle);
                        });

                        circle.addListener("mouseout", () => {
                            infowindow.close(map, circle)
                        });
                    }

                    newRoutePolyline = ([...newRoutePolyline, circle, infowindow,])
                })
        }
        setRoutePolyline(newRoutePolyline);
    }
    useEffect(() => {
        if (editingKey && props.showMap) {
            filteredOverviewData = filteredOverviewData?.filter(i => i.zoneId === editingKey)
        }

        if (!props.showMap && map && maps) {
            const bounds = createBoundsFromZonesData(maps, props.showLocations)
            map.fitBounds(bounds)
        }

    }, [editingKey, props.showMap])

    function geocodeLatLng(geocoder, map, location) {
        geocoder
            .geocode({location: location})
            .then((response) => {
                if (response.results[0]) {
                    // props.setAddress(response.results[0].formatted_address);
                }
            })
            .catch(e => {
                console.log(e)
                setTimeout(3000);
            })
    }

    const searchBox = useRef(null);
    const input = document.getElementById('places-ref')

    const handleOnPlacesChanged = () => {

        if (!searchBox?.current?.getPlaces()) {
            return
        }

        const place = searchBox.current?.getPlaces()[0]

        const bounds = place?.geometry?.viewport
        map.fitBounds(bounds)

        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        props.setCoordinate([lat, lng])
        props.setAddress(place.formatted_address)

        const location = {lat: lat, lng: lng}
        routePolyline[0]?.setCenter(location)
    }

    useEffect(() => {
        if (!searchBox?.current && maps?.places) {
            searchBox.current = new maps.places.SearchBox(input);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (maps) {
                searchBox.current = null;
                maps.event.clearInstanceListeners(searchBox);
            }
        };
    }, [maps, handleOnPlacesChanged]);

    return (
        <div style={{
            height: '50vh',
            width: '100%',
            position: editingKey ? 'sticky' : 'relative',
            top: editingKey ? 80 : null,
            zIndex: 10
        }}>
            <GoogleMap
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                center={center}
                zoom={props.showMap ? 12 : 10}
                options={{...mapOptions, disableDoubleClickZoom: editingKey}}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({map, maps}) => {
                    setLightMapType(maps, map)
                    setMap(map)
                    if (maps) {
                        setMaps(maps)

                        if (props?.showLocations?.length < 1) {
                            const bounds = createBoundsFromZonesData(maps)
                            map.fitBounds(bounds)

                        } else {
                            const bounds = createBoundsFromZonesData(maps, props.showLocations)
                            map.fitBounds(bounds)
                        }

                    }
                }
                }
            >
            </GoogleMap>
        </div>
    );
}
const LocationTable = (props) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(props.data);
    const coordinate = props.coordinate
    const [override, setOveride] = useState()

    useEffect(() => {
        setOveride(props.address)
    }, [props.address])

    useEffect(() => {
        setData(props.data)
    })

    const isEditing = record => record.zoneId === props.editKey;

    const edit = record => {
        form.resetFields()
        form.setFieldsValue({...record});
        props.setEditKey(record.zoneId)
        props.setShowMap(true)
        message.destroy()
        message.info(STATIC_TEXT.DBCLICK_ON_MAP_TO_CHOOSE_LOCATION, 15)
    };

    const cancel = () => {
        form.resetFields()
        props.setEditKey('')
        props.setShowMap(false)
    };

    function handleDelete(row) {
        const newData = data.filter(item => item.zoneId !== row.zoneId);
        setData(newData)
        props.setData(newData)
    }

    const deleteLocation = record => {
        if (data.length !== -1) {
            dataApi.deleteZone(props.selectedId || props.userId, record.zoneId)
                .then(
                    message.success(ZONES_STATIC_TEXT.ZONES_DELETED)
                )
            handleDelete(record)
        }
    }

    const save = async key => {
        const row = await form.validateFields();
        const newData = [...data];
        const index = newData.findIndex(item => key === item.zoneId);
        const item = newData[index];

        console.log(row)
        console.log(props.address)

        row.latitude = coordinate ? coordinate[0] : item.latitude
        row.longitude = coordinate ? coordinate[1] : item.longitude
        row.radiusMs = props.getRadius ? props.getRadius : item.radiusMs
        row.tripType = row.tripType || ' '
        row.address = override

        if (row.application) {
            row.activeOnTripStart = row.application === ZONES_CONSTANTS.EXIT || row.application === ZONES_CONSTANTS.BOTH
            row.activeOnTripEnd = row.application === ZONES_CONSTANTS.ENTRY || row.application === ZONES_CONSTANTS.BOTH
        }

        if (index > -1) {
            newData.splice(index, 1, {...item, ...row});
        } else {
            newData.push(row);
        }

        dataApi.putZone(props?.selectedId || props.userId, key, row)
            .then((res) => {
                props.setData(res)
                setData(res)
                cancel()
            })
    };

    const columns = [
        {
            title: ZONES_STATIC_TEXT.NAME,
            dataIndex: ZONES_CONSTANTS.ZONE_NAME,
            key: ZONES_CONSTANTS.ZONE_NAME,
            editable: true,
            width: '10%',
        },
        {
            title: ZONES_STATIC_TEXT.ADDRESS,
            dataIndex: ZONES_CONSTANTS.ADDRESS,
            key: ZONES_CONSTANTS.ADDRESS,
            editable: true,
            width: '30%',
            render: (value) => {
                return (
                    value ? value : null
                )
            }
        },
        {
            title: ZONES_STATIC_TEXT.RADIUS,
            dataIndex: ZONES_CONSTANTS.RADIUS_MS,
            key: ZONES_CONSTANTS.RADIUS_MS,
            editable: false,
            width: '10%',
            render: (value, record) => {
                let radiusMs = record.radiusMs
                if (props.editKey === record.zoneId) {
                    radiusMs = props.getRadius
                }
                return (
                    <span style={{width: 200}}>{radiusMs} m</span>
                )
            }
        },
        {
            title: ZONES_STATIC_TEXT.TRIP_TYPE,
            dataIndex: ZONES_CONSTANTS.TRIP_TYPE,
            key: ZONES_CONSTANTS.TRIP_TYPE,
            editable: true,
            width: '10%',
            render: (value) => {
                if(!value){
                    return
                }
                value = value === CONSTANTS_TEXT.BUSINESS
                return (
                    <Button style={{color: value ? '#699DFF' : SD_BASE_COLORS.SWITCH_PRIVATE}}>
                        {value ? tripTypes.BUSINESS : tripTypes.PRIVATE}
                    </Button>

                )
            }
        },

        {
            title: ZONES_STATIC_TEXT.ENTRY_EXIT_TRIP,
            dataIndex: ZONES_CONSTANTS.APPLICATION,
            key: ZONES_CONSTANTS.APPLICATION,
            editable: true,
            width: '10%',
            render: (value, record) => {
                let application = record.activeOnTripStart && record.activeOnTripEnd
                application = application ? ZONES_CONSTANTS.BOTH : null
                if (!application) {
                    application = record.activeOnTripStart ? ZONES_CONSTANTS.EXIT : ZONES_CONSTANTS.ENTRY
                }
                return Object.entries(applicationsNames).map(([key, title]) => {
                    if (key === application) {
                        return title
                    }
                })
            }
        },
        {
            title: ZONES_STATIC_TEXT.DESCRIPTION_GOAL,
            dataIndex: ZONES_CONSTANTS.DESCRIPTION,
            key: ZONES_CONSTANTS.DESCRIPTION,
            width: '10%',
            editable: true
        },
        {
            dataIndex: ZONES_CONSTANTS.OPERATION,
            width: '5%',
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <a onClick={() => save(record.zoneId)} style={{marginRight: 8,}}> {STATIC_TEXT.SAVE}</a>
                     <a onClick={() => cancel(record.zoneId)}>{STATIC_TEXT.BTN_ABORT}</a>
          </span>
                ) : (
                    <ButtonMenuDropdown menu={() => menuDropdown(record)}/>                );
            },
        },
    ];

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => edit(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => deleteLocation(record)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }

    const components = {
        body: {
            cell: EditableCell,
        },
    };
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === ZONES_CONSTANTS.ZONE_NAME ? ZONES_CONSTANTS.ZONE_NAME : ZONES_CONSTANTS.COORDINATE,
                dataIndex: col.dataIndex,
                coordinate: coordinate,
                setNewRadius: props.setRadius,
                title: col.title,
                editing: isEditing(record),
                setAddress: props.setAddress,
                address: props.address,
                override: override,
                setOveride: setOveride
            }),
        };
    });
    return (
        <div>
            <Form form={form} component={false}>
                <Form form={form} component={false}>
                    <Table
                        components={components}
                        rowKey={r => r.zoneId}
                        scroll={{x: 1000}}
                        dataSource={ props.editKey ? data?.filter(item => item?.zoneId === props.editKey) : data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Form>
        </div>
    );
};
const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          coordinate,
                          setNewRadius,
                          children,
                          ...restProps
                      }) => {

    const [radiusMs, setRadius] = useState(record?.radiusMs)

    useEffect(() => {

        if (restProps.setOveride) {


            restProps.setOveride(record?.address)
        }

    }, [editing])


    useEffect(() => {
    }, [restProps.address])

    const inputNode = (() => {
        switch (dataIndex) {
            case "latitude":
                return (
                    coordinate ? <Typography.Text>{coordinate[0]}, <br/> {coordinate[1]}</Typography.Text>
                        : <Typography.Text>{record?.latitude}, <br/> {record?.longitude}</Typography.Text>
                )
            case "tripType":
                return (
                    <Select
                    >
                        <Select.Option value={null}>Inget förval</Select.Option>
                        <Select.Option value={CONSTANTS_TEXT.BUSINESS}>{tripTypes.BUSINESS}</Select.Option>
                        <Select.Option value={CONSTANTS_TEXT.PRIVATE}>{tripTypes.PRIVATE}</Select.Option>
                    </Select>
                );
            case "application":
                let application = record.activeOnTripStart && record.activeOnTripEnd ? ZONES_CONSTANTS.BOTH : null
                if (!application) {
                    application = record.activeOnTripStart ? ZONES_CONSTANTS.EXIT : ZONES_CONSTANTS.ENTRY
                }
                return (
                    <Select
                        defaultValue={application ? application : null}
                    >
                        {selectApplication}
                    </Select>
                )
            case "radiusMs":
                return (
                    <div style={{display: 'flex', alignItems: 'bottom', flexDirection: 'column'}}> {radiusMs} m
                        <Slider
                            onChange={(e) => {
                                setNewRadius(e)
                                setRadius(e)
                            }}
                            style={{width: 150}}
                            max={800}
                            value={radiusMs}/>
                    </div>
                )
            case "address":
                return (
                    <Tooltip title={'Ange en adress för att ersätta standardadressen, eller lämna tomt för att använda den faktiska adressen.'}>
                        <Input
                            style={{minWidth: 250}}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_STREET_ADDRESS}
                            id={'places-ref'}
                            value={restProps.override}
                            allowClear={true}
                            onChange={(e) => {

                                if (e.target.value) {
                                    restProps.setOveride(e.target.value)
                                } else {
                                    console.log('tom', e.target.value)
                                    restProps.setOveride(' ')
                                }
                                // restProps.setAddress(null)
                            }}
                        />
                    </Tooltip>
                )
            default :
                return <Input
                    placeholder={STATIC_TEXT.SUPPLY + STATIC_TEXT[dataIndex?.toUpperCase()]}
                    style={{minWidth: 180}}
                />
        }
    })()

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: false,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const selectApplication = Object.entries(applicationsNames).map(([key, title]) => {
    return (
        <Select.Option key={key} value={key}>{title}</Select.Option>
    )
})
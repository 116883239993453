import {Nest} from "../utils/utilities";
import Schedule from "../components/trackson-components/Schedule";

const TripSchedulePage = () => {
    return (
        <Nest
            wrappers={[
            ]}>
            <Schedule/>
        </Nest>
    );
};

export default TripSchedulePage;

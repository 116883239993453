const dev = {
   // API_ENDPOINT_URL: "http://127.0.0.1:8089/api2",
    DEBUG_ENDPOINT_URL: "http://127.0.0.1:8000",
    SHOW_DEV: true,
    API_ENDPOINT_URL: "https://dev.app.trackson.se/api2",
};

const prod = {
    API_ENDPOINT_URL: process.env.TS_API_ENDPOINT_URL,
    DEBUG_ENDPOINT_URL: process.env.TS_API_DEBUG_ENDPOINT_URL,
    SHOW_DEV: false,
};

const getEnv = () => {
    switch (process.env.NODE_ENV) {
        case "development":
            console.log("Using DEV environment:", dev);
            return dev;
        case "production":
            console.log("Using PROD environment:", prod);
            return prod;
        default:
            break;
    }
};

export const env = getEnv();

import AlarmView from "../AlarmView/AlarmView";

const AlarmFunctionsController = () => {
    return (
        <>
            <AlarmView alarmType={"FUNCTION"} />
        </>
    );
};

export default AlarmFunctionsController;
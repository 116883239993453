import React, {useContext, useEffect, useState} from "react";
import {Button, Form, Switch, Menu, Table, Tag, Tooltip, Typography} from "antd";
import {dataApi, userApi} from "../../../services/api/api";
import Loading from "../../shared-components/Loading/Loading";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {
    ButtonMenuDropdown,
    dayOfWeekNames,
    tableColumnsWithTooltip,
    tableProps
} from "../shared-components/sharedComponents";
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import {useHistory} from "react-router-dom";
import Flex from "../../shared-components/Flex/Flex";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {shouldHideUsersOrganizationsColumn, VehiclesComplexListContext} from "../../../services/contexts/contexts";
import {ModalWithFullList} from "../shared-components/modalShowFullList";

const {Title} = Typography;

const ScheduleTags = ({i}) => {
    const time = i?.times[0];
    const time1 = i?.times[1];
    let colorTag = i?.active ? "green" : null;

    time.start = time?.start?.toString().length < 4 ? "0" + time?.start : time?.start;
    time.stop = time?.stop?.toString().length < 4 ? "0" + time?.stop : time?.stop;

    if (time1) {
        time1.start = time1?.start?.toString().length < 4 ? "0" + time1?.start : time1?.start;
        time1.stop = time1?.stop?.toString().length < 4 ? "0" + time1?.stop : time1?.stop;
    }

    const showTime = (time) => time?.toString().slice(0, 2) + ":" + time.toString().slice(2);
    const start = i?.active ? showTime(time?.start?.toString().length < 4 ? "0" + time?.start : time?.start) + " -" : " ";
    let stop = i?.active ? showTime(time?.stop?.toString().length < 4 ? "0" + time?.stop : time?.stop) : " ";

    return (
        <Tag style={{marginRight: 8, marginTop: 8, minWidth: 110}} color={colorTag}>
            {dayOfWeekNames[i?.dayOfWeek]}

            <>
                <br/>
                {start + " " + stop}
                <br/>
                {time1 && colorTag ? showTime(time1?.start?.toString().length < 4 ? "0" + time1?.start : time1?.start) + " - " + showTime(time1?.stop?.toString().length < 4 ? "0" + time1?.stop : time1?.stop) : null}
            </>
        </Tag>
    );

}

function AlarmsTable() {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [user, setUser] = useState();
    const [editingKey, setEditingKey] = useState("");
    const [loading, setLoading] = useState(true);
    const [knownOrganizations, setKnownOrganizations] = useState();
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [filteredUsers, setFilteredData] = useState();
    const [showOrganizationSelector, setShowOrganizationSelector] = useState(false);
    const history = useHistory();
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);
    const knownVehicles = useContext(VehiclesComplexListContext);

    useEffect(() => {
        userApi.getCurrentUser().then((user) => {
            setUser(user);
            if (user.roles?.includes("ROOT")) {
                setShowOrganizationSelector(true);
            }
        });

        dataApi.getOrganizationsSimple().then((r) => {
            setKnownOrganizations(r);
        });

    }, []);

    useEffect(() => {
        if (!user) return;

        dataApi.getGeoAlarm().then((i) => {
            console.log(i);
            setLoading(true);
            setData(i);
            setLoading(false);
        });
    }, [user]);

    useEffect(() => {
        let filteredList = data;
        if (selectedOrganization?.length > 0) {
            filteredList = filteredList?.filter((i) => selectedOrganization?.includes(i.organizationId));
        }
        setFilteredData(filteredList);
    }, [selectedOrganization, data]);

    const edit = (id) => {
        const url = APP_PREFIX_PATH + "/geoLarmDetails/" + id;
        console.log("show item", id, url);
        history.push({
            pathname: url,
            state: {id: id},
        });
    };

    const handleDelete = (id) => {
        const newData = data?.filter((item) => item.alarmSettingsId !== id);
        dataApi.deleteGeoAlarm(id).then((_) => {
            setData(newData);
        });
    };

    const save = async (id) => {
        try {
            let row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => id === item.geoZoneAlarmId);

            if (index > -1) {
                dataApi.patchGeoAlarm(row, id).then((i) => {
                    const item = newData[index];
                    newData.splice(index, 1, {...item, ...row});
                    setData(newData);
                    setEditingKey("");
                });
            } else {
                let defaultGeoLarmData = {
                    name: "Larm",
                    organizationId: user?.organizationId,
                    targetOrganizationIds: [2226, 2, 3, 4, 5],
                };
                dataApi.postGeoAlarm(defaultGeoLarmData).then((i) => {
                    console.log(row);
                    defaultGeoLarmData = {geoZoneAlarmId: i.geoZoneAlarmId, ...defaultGeoLarmData};
                    newData.push(defaultGeoLarmData);
                    setData(newData);
                    setEditingKey("");
                });
            }

            form?.resetFields();
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };


    const columns = [
        {
            title: STATIC_TEXT.ORGANIZATION,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.organizationId,
            hidden: hideUsersOrg,
            render: (text) => {
                const organization = knownOrganizations?.find((i) => i.organizationId === text);
                return organization?.fullName;
            },
        },
        {
            title: STATIC_TEXT.ALIAS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.name,
        },
        {
            title: "Intervall",
            dataIndex: "repeatAfterHours",
            render: (text) => {
                if(text === 0 || text === -1){
                    return "Meddelas bara en gång";
                }
                return "Upprepas varje " + text + " h ";
            },
        },
        {
            title: STATIC_TEXT.MAIL_RECIPIENTS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.emails,
            render: (text) => {
                if (text?.length === 1) {
                    return <a>{text[0]}</a>;
                } else if (text?.length === 0) {
                    return "-";
                } else {
                    let title = text?.length + " " + STATIC_TEXT.MAIL_RECIPIENTS;
                    return ModalWithFullList(title, null, text)
                }
            },
        },
        {
            title: "Aktivt",
            dataIndex: "active",
            align: "right",
            render: (value, record) => {
                return <Switch onChange={(v) => patchAlarm({active: v}, record)} defaultChecked={value}/>;
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            fixed: "right",
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    const patchAlarm = (value, record) => {
        dataApi.patchAlarmSettings(record.alarmSettingsId, value);
    };

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => edit(record?.alarmSettingsId)}>Se detaljer/Ändra </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => handleDelete(record.alarmSettingsId)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }


    return loading ? (
        <Loading/>
    ) : (
        <div>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, flexWrap: "wrap"}}>
                <Title>{STATIC_TEXT.ALARM_PLACES}</Title>
                <Flex gap={15}>
                    {!showOrganizationSelector ? null : (
                        <div style={{display: "flex", marginBottom: 20, gap: 15}}>
                            {knownOrganizations?.length < 2 ? null : (
                                <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                         selectedOrganization={selectedOrganization} isMultiple={true}/>
                            )}
                        </div>
                    )}
                    <Button type={"primary"} onClick={() => edit(-1)} icon={<PlusOutlined/>}>
                        {STATIC_TEXT.ADD}
                    </Button>
                </Flex>
            </div>
            <Form form={form} component={false}>
                <Table
                    dataSource={filteredUsers}
                    columns={tableColumnsWithTooltip(columns)}
                    scroll={{x: 1000}}
                    rowClassName="editable-row"
                    rowKey={(i) => i.alarmSettingsId}
                    expandable={{
                        rowExpandable: record => record.schedule?.length > 0,
                        expandedRowRender: (record) => {
                            return (
                                <div style={{marginLeft: 80}}>
                                    <Typography.Text>Schema</Typography.Text>
                                    <Flex>
                                        {record?.schedule?.map(item => {
                                            return <ScheduleTags i={item}/>
                                        })
                                        }
                                    </Flex>
                                </div>
                            );
                        },
                    }}
                    {...tableProps}
                />
            </Form>
        </div>
    );
}

const AlarmsZonesController = () => {
    return (
        <>
            <AlarmsTable/>
        </>
    );
};

export default AlarmsZonesController;

import {
    Badge,
    Alert,
    Row,
    Select,
    Popconfirm,
    message,
    Tag,
    Popover,
    Tooltip
} from 'antd';
import * as icons from '@ant-design/icons'
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Col from 'antd/es/grid/col';
import React from 'react';
import Button from 'antd/es/button';
import { icon_determine } from './superDeviceHelpers';
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";

export const TitleTop = ({ level, title }) => {
    return (

        <Row gutter={[0, 5]} justify="left">
            <Title level={level}>{title} </Title>
        </Row>

    );
};


export const TitleSpanned = ({ span, level, title }) => {
    return (

        <Col span={span}>
            <Title level={level}>{title} </Title>
        </Col>

    );
};


export const TextSingleSpan = ({ span, text }) => {
    return (

        <Col span={span}>
            <Text>{text} </Text>
        </Col>

    );
};


export const TwoRowsTitleSubTitle = ({ span, title, text }) => {
    return (

        <Col span={span}>
            <Row gutter={[0, 0]} justify="left">
                <Title level={4}>{title} </Title>
            </Row>
            <Row gutter={[0, 0]} justify="left">
                <Text>{text} </Text>
            </Row>
        </Col>

    );
};

export const TwoRowsTitleText = ({ span, title, text, textSize }) => {
    return (

        <Col span={span}>
            <Row gutter={[0, 0]} justify="left">
                <Title level={5}>{title} </Title>
            </Row>
            <Row gutter={[0, 0]} justify="left">
                <Text style={{ fontSize: textSize ? textSize : 12 }}>{text} </Text>
            </Row>
        </Col>

    );
};

export const TwoRowsIcon = ({ span, title, icon }) => {
    return (

        <Col span={span}>
            <Row gutter={[0, 0]} justify="left">
                <Title level={5}>{title} </Title>
            </Row>
            <Row gutter={[0, 0]} justify="left">
                {icon}
            </Row>
        </Col>

    );
};

export const CarNumberPlateIcon = ({ text }) => {
    return (
        <Tag color={"#fff"} style={{ minWidth: 80, width: '100%', height: '100%', minHeight: 28, boxShadow: 'inset 0px 0px 1px #000', textAlign: 'center',  marginBottom: 8 }}>
            <Text strong style={{ color: "#000" }}>{text?.toUpperCase()}</Text>
        </Tag>
    );
};

export const SingleRowIconText = ({ title, icon }) => {
    return (

        <Row>
            {/* <Col span={span}> */}
            <div style={{ marginRight: 5 }}>{icon}</div> <Title level={5}>{title} </Title>
            {/* </Col> */}
        </Row>

    );
};

export const TabBadge = ({ text, count, color, overflowCount, badgeColor }) => {

    return (
        <React.Fragment>
            <Text style={{ color: color, marginRight: 10 }}>{text}</Text>
            <Badge count={count} overflowCount={overflowCount} showZero style={{ backgroundColor: color || badgeColor }} />
        </React.Fragment>
    )
}

export const SingleItemDropDown = ({ array, placeholder, onChangeFunc }) => {

    return (

        <Select
            onChange={onChangeFunc}
            style={{ width: '100%' }}
            placeholder={placeholder}
        >

            {
                array.map((item) => {
                    return (
                        <Select.Option key={`opt_${item}`} value={item}>{item}</Select.Option>
                    )
                })
            }

        </Select>

    );

}

export const ObdDeterIcon = ({ obd }) => {

    return (
        icon_determine.determineObdColorAndIcon(obd)
    )

}

export const PopConfirmDangerButton = ({ title, onConfirmFunc, setOkText, setCancelText, buttonProps }) => {

    const btnType = buttonProps.type
    const btnIcon = buttonProps.icon
    const btnText = buttonProps.text


    function cancel(e) {
        console.log(e);
        message.info('Click on No');
    }

    return (
        <React.Fragment>

            <Popconfirm
                title={title}
                onConfirm={onConfirmFunc}
                onCancel={cancel}
                okText={setOkText}
                icon={<icons.WarningOutlined style={{ color: SD_BASE_COLORS.RED }} />}
                cancelText={setCancelText}>

                <Button
                    danger
                    type={btnType}
                    style={{ width: '100%' }}
                    icon={btnIcon}>

                    {btnText}
                </Button>

            </Popconfirm>

        </React.Fragment>


    );
}

export const PopConfirmWarningButton = ({ title, onConfirmFunc, setOkText, setCancelText, buttonProps }) => {

    const btnType = buttonProps.type
    const btnIcon = buttonProps.icon
    const btnText = buttonProps.text


    function cancel(e) {
        console.log(e);
        message.info('Click on No');
    }

    return (
        <React.Fragment>

            <Popconfirm
                title={title}
                onConfirm={onConfirmFunc}
                onCancel={cancel}
                okText={setOkText}
                icon={<icons.InfoCircleFilled style={{ color: SD_BASE_COLORS.YELLOW }} />}
                cancelText={setCancelText}>

                <Button
                    type={btnType}
                    style={{ width: '100%', color: SD_BASE_COLORS.YELLOW  }}
                    icon={btnIcon}>

                    {btnText}
                </Button>

            </Popconfirm>

        </React.Fragment>


    );
}
import {Button, message, Select, Switch, Typography, Tooltip} from 'antd';
import Loading from 'components/shared-components/Loading/Loading'
import React, {useEffect, useState} from 'react'
import {dataApi, userApi} from "../../../services/api/api";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {defaultSchedule} from "../../../constants/InitialConstant";
import styles from "./styles";
import {ScheduleTable2} from "./ScheduleTable";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import {UsersSelect} from "../shared-components/UsersSelect"; // 21K (gzipped: 5.8K)
import {InfoCircleOutlined} from "@ant-design/icons/";
import Flex from "../../shared-components/Flex/Flex";

function EditScheduleController() {
    const [schedule, setSchedule] = useState([])
    const [loading, setLoading] = useState(true)
    const [ifDefaultSchedule, setIfDefaultSchedule] = useState(false)
    const [isSchemaActive, setIsSchemaActive] = useState(true)
    const [selectedId, setSelectedId] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [selectedTripType, setSelectedTripType] = useState(true);
    const [knownOrganizations, setKnownOrganizations] = useState()
    const [knownUsers, setKnownUsers] = useState()
    const [filteredUsers, setFilteredUsers] = useState()

    useEffect(() => {
        dataApi.getOrganizationsSimple().then(r => {
            setKnownOrganizations(r)
        })
    }, [])

    const changeScheduleParam = (dayOfWeek, fields) => {
        console.log(fields)
        if (!ifDefaultSchedule) {
            dayOfWeek = dayOfWeek - 1;

            // Create a new schedule object
            let newSchedule = {
                ...schedule, // Spread existing schedule
                daysList: schedule.daysList.map((day, index) => {
                    if (index === dayOfWeek) {
                        return {...day, ...fields}; // Update the specific day
                    }
                    return day; // Return other days as-is
                })
            };

            // Set the new schedule and then save
            setSchedule(newSchedule)

        }
    };

    useEffect(() => {
        if (!schedule || !selectedId) return
        handleSaveClick();

    }, [schedule])

    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
    };

    const handleSelectId = (e) => {
        setSelectedId(e)
        setLoading(true)
        fetchFromApi(e)
    }

    const handleSaveClick = () => {
        dataApi.patchWeekdaySelection(selectedId, schedule).then(_ => {
                message.destroy()
            }
        )
    }

    const fetchFromApi = (userId) => {
        dataApi.getWeekdaySelection(userId)
            .then((r) => {
                if (r.daysList.length === 7) {
                    setSchedule(r)
                    setSelectedTripType(r.tripType?.startsWith("BUSINESS"))
                    setIfDefaultSchedule(false)
                } else {
                    setSchedule(defaultSchedule)
                    setSelectedTripType(true)
                    setIfDefaultSchedule(true)
                }
                setLoading(false)
            })
    }

    const getUsers = (user) => {
        dataApi.getUsersSimple()
            .then(res => {
                setKnownUsers(res)
                setFilteredUsers(res)
                fetchFromApi(user.userId)
            })
    }

    const onChangeTripType = (newValue) => {
        const data = {"tripType": newValue ? "BUSINESS" : "PRIVATE"}
        dataApi.patchWeekdaySelection(selectedId, data)
            .then(_ => {
                setSelectedTripType(!selectedTripType)
            })
    }


    useEffect(() => {
        userApi.getCurrentUser().then(user => {
            setSelectedId(user.userId)
            setCurrentUser(user.userId)
            // setSelectedOrganization(user?.organizations[0].organizationId)
            getUsers(user)
        })
    }, [])

    useEffect(() => {
        const isAnyActive = schedule?.daysList?.some(item => item.active);
        setIsSchemaActive(isAnyActive)
    }, [schedule])

    useEffect(() => {
        let filteredList = knownUsers
        if (knownUsers?.length > 0 && selectedOrganization.length > 0) {
            filteredList = filteredList?.filter(
                i => selectedOrganization.includes(i.organizationId) || i.id === selectedId)
        }
        setFilteredUsers(filteredList)
    }, [selectedOrganization])

    const activeNewSchema = () => {
        setIfDefaultSchedule(false)
        dataApi.postWeekdaySelection(selectedId, schedule).then(_ => {
            message.success('Du har skapat ditt första schema')
        })
    }

    return loading ? <Loading/> : <div>
        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20, flexWrap: "wrap"}}>
            <Flex>
                <Typography.Title>Arbetstidsschema</Typography.Title>
            </Flex>
            <div style={{display: 'flex', marginBottom: 20, gap: 15, flexWrap: "wrap"}}>
                {knownOrganizations?.length < 2 ? null :
                    <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                             selectedOrganization={selectedOrganization} isMultiple={true}/>
                }
                <UsersSelect
                    data={filteredUsers}
                    value={selectedId}
                    onChange={(e) => handleSelectId(e)}
                />

            </div>
        </div>

        <ScheduleTable2
            ifDefaultSchedule={ifDefaultSchedule}
            selectedTripType={selectedTripType}
            schedule={schedule.daysList || schedule}
            changeScheduleParam={changeScheduleParam}/>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
            {ifDefaultSchedule ? <Button onClick={activeNewSchema} type="primary" style={{
                backgroundColor: SD_BASE_COLORS.SWITCH_PRIVATE,
                borderColor: SD_BASE_COLORS.GREEN
            }}>Aktivera schema</Button> : null}
        </div>
        {!isSchemaActive || ifDefaultSchedule ?
            <p>
                Alla dagar i arbetstidsschemat är inaktiva, så för nya resor gäller fordonets förval. Aktivera dagarna
                för att arbetstidsschemat ska vara aktivt.
            </p>
            :
            <div>
                <div style={styles.interval}>
                    <p style={styles.paragraph}>
                         Resor som påbörjas under arbetstiden registreras som
                    </p>
                    <div>
                        <Switch
                            style={{
                                minWidth: 80,
                                backgroundColor: !selectedTripType ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS
                            }}
                            checkedChildren="Tjänst"
                            unCheckedChildren="Privat"
                            checked={selectedTripType}
                            onChange={onChangeTripType}
                            disabled={ifDefaultSchedule}
                        />
                    </div>
                </div>

                <p>Alla {selectedId === currentUser ? "dina resor" : " resor som utförs av " + knownUsers?.find(item => item.userId === selectedId)?.displayName} registreras
                    som {!selectedTripType ? "privat" : "tjänst"} under angiven arbetstid och
                    som {selectedTripType ? "privat" : "tjänst"} utanför arbetstid. Om du har inställningar i 'Platser'
                    för 'typ av resa', kommer dessa att ha företräde oavsett arbetstid.</p>

            </div>}
    </div>
}

export default EditScheduleController;

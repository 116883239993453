import React, { createContext, useState, useEffect } from "react";
import { dataApi } from "services/api/api";

const VehiclesContext = createContext({});

const VehiclesContextProvider = ({ children }) => {
    const [loadingVehicles, setLoadingVehicles] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const fetchVehicles = async () => {
        setLoadingVehicles(true);
        dataApi
            .getVehicles()
            .then((res) => {
                setLoadingVehicles(false);
                setVehicles(res);
            })
            .catch((err) => {
                setLoadingVehicles(false);
            });
    };

    const updateVehicle = async (id, data) => {
        return dataApi
            .patchVehicle(id, data)
            .then((res) => {
                const vehicle = res;
                if (vehicle) {
                    let updatedVehicles = [...vehicles];
                    const index = vehicles.findIndex((v) => v.id === vehicle.id);
                    if (index > -1) {
                        updatedVehicles[index] = vehicle;
                        setVehicles(updatedVehicles);
                    }
                }
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    };

    const addVehicle = async (data) => {
        return dataApi
            .postVehicle(data)
            .then((res) => {
                // TODO: add to list
                console.log(res);
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    };

    const deleteVehicle = async (vehicleId) => {
        const vehicleIndex = vehicles.findIndex((i) => i.id === vehicleId);
        const selectedVehicleIndex = selectedVehicles.findIndex((i) => i.key === vehicleId);
        dataApi
            .deleteVehicle(vehicleId)
            .then((res) => {
                console.log(res);
                if (vehicleIndex > -1 && selectedVehicleIndex > -1) {
                    vehicles.splice(vehicleIndex, 1);
                    selectedVehicles.splice(selectedVehicleIndex, 1);
                    return true;
                }
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    };
    const changeVehicleOrg = async (record, organizationId) => {
        await dataApi
            .patchVehicle(record.key, { organizationId: organizationId })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.error(err));
    };
    const updateUserCertificate = async (previousMonth, year, vehicleId) => {
        dataApi
            .patchAttestUser(previousMonth, year, vehicleId)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const updateVehicleFrequencyUpdate = async (vehicleId, frequency) => {
        await dataApi
            .patchTrackerUpdateFrequency(vehicleId, frequency)
            .then((res) => console.log("Update Successfully!"))
            .catch((err) => console.error(err));
    };
    const updateVehicleCurrentPosition = async (vehicleId) => {
        await dataApi
            .postVehicleTracking(vehicleId)
            .then((res) => console.log("Update Successfully!"))
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        fetchVehicles();

        // TODO: refetch vehiclesNew when org ID has changed?
    }, []);

    return (
        <VehiclesContext.Provider
            value={{
                vehicles,
                loadingVehicles,
                selectedVehicles,
                setSelectedVehicles,
                updateVehicle,
                addVehicle,
                deleteVehicle,
                changeVehicleOrg,
                updateUserCertificate,
                updateVehicleFrequencyUpdate,
                updateVehicleCurrentPosition,
            }}
        >
            {children}
        </VehiclesContext.Provider>
    );
};

export { VehiclesContext, VehiclesContextProvider };

import React, {createContext, useMemo, useCallback, useState, useRef} from "react";
import moment from "moment";
import fetch from "../auth/FetchInterceptor"
import {dataApi} from "../services/api/api"
import * as types from "../services/redux/constants/dataConstants"

// TODO unmangle the mangledness
const manglePatchJournal = (journals, action) => {
    return journals.map(jp => {
        return jp.map(j => {
            if (j.journal_id !== action.payload.journal_id) {
                return j
            } else {
                const r = Object.fromEntries(Object.entries(j).map(([k, v]) => {
                    return [k, (
                        k in action.payload.data ?
                            {...v, ...action.payload.data[k]} : v)]
                }))


                if (action.payload.data.object) {
                    console.log(action.payload.data)
                    return action.payload.data.object
                }


                // Can't find better way to do that...
                if (action.payload.data.lightAttested === true || action.payload.data.lightAttested === false) {
                    return {
                        ...r,
                        lightAttested: action.payload.data.lightAttested,
                        status: action.payload.data.lightAttested ? 'CHECKED' : 'NONE'
                    }
                }
                // Can't find better way to do this either...
                if (action.payload.data.correctedByDriver === true || action.payload.data.correctedByDriver === false) {
                    return {
                        ...r,
                        correctedByDriver: action.payload.data.correctedByDriver,
                        status: action.payload.data.correctedByDriver ? 'CORRECTED' : 'BAD'
                    }
                }


                if (action.payload.data.attested === true || action.payload.data.attested === false) {
                    console.log('r', r, action.payload.data.attested)
                    return {
                        ...r,
                        attested: action.payload.data.attested
                    }
                }

                if (action.payload.data.devComment === null) {
                    console.log('r', r, action.payload.data.devComment)
                    return {
                        ...r,
                        devComment: action.payload.data.devComment ? [action.payload.data.devComment] : null
                    }
                }

                console.log('updated obj: ', r)
                return r
            }
        })
    })
}

// yeah
const bijectPutGetFields = (fields, aggregateFields, object) => {
    let r = {
        driver: {...(fields.user_id ? {user_id: fields.user_id} : {})},
        trip: {
            ...(fields.override_start ? {override_start: fields.override_start} : {}),
            ...(fields.override_stop ? {override_stop: fields.override_stop} : {}),
            ...(fields.purpose ? {purpose: fields.purpose} : {}),
            ...(fields.description ? {description: fields.description} : {}),
            ...(fields.timestamp_stop_ms ? {timestamp_stop_ms: fields.timestamp_stop_ms} : {}),
            ...(fields.timestamp_start_ms ? {timestamp_start_ms: fields.timestamp_start_ms} : {}),
            ...(fields.odometer_max ? {odometer_max: fields.odometer_max} : {}),
        },
    };

    if (fields.attested === true || fields.attested === false) {
        r = {
            ...r,
            attested: fields.attested,
        };
    }

    if (fields.lightAttested === true || fields.lightAttested === false) {
        r = {
            ...r,
            lightAttested: fields.lightAttested,
        };
    }

    if (fields.correctedByDriver === true || fields.correctedByDriver === false) {
        r = {
            ...r,
            correctedByDriver: fields.correctedByDriver,
        };
    }

    if (fields.devComment === true || fields.devComment === false || fields.devComment === null) {
        r = {
            ...r,
            devComment: fields.devComment,
        };
    }

    if (aggregateFields) {
        Object.entries(aggregateFields).map(([k, v]) => {
            r[k] = {...r[k], ...v};
        });
    }

    if (object) {
        return object;
    }

    return r;
};


// todo dont do this, its just here for pycharm
// actually figure out how contexts work instead
const initState = {
    requestFetchJournals: null,
    requestResetJournals: null,
    patchJournals: null,
    requestDownloadCSV: null
}

export const TripLogbookContext = createContext(initState)

export const TripLogbookProvider = ({children}) => {
    const [journals, setJournals] = useState([])
    const journalsRef = useRef(journals);
    journalsRef.current = journals;    const [loadedJournals, setLoadedJournals] = useState(false)
    const [loadingJournals, setLoadingJournals] = useState(false)
    const [mapOverviewData, setMapOverviewData] = useState([])

    const requestDownloadCSV = (fromTimestamp, toTimestamp, vehicleId, filename, driverIds, organizationIds, showPrivateTrips) => {
        return dataApi.getJournalsCSV(
            fromTimestamp,
            toTimestamp,
            vehicleId,
            filename,
            driverIds,
            organizationIds,
            showPrivateTrips
        ).then(res => {
            console.log('csv response: ', res)
        })
    }

    const requestFetchJournals = (fromTimestamp, toTimestamp, vehicleId, drivers, fetchCoordinates, journeyId, loadingJournals) => {
        // types.FETCH_JOURNALS_AWAIT,
        setLoadedJournals(false)
        setLoadingJournals(loadingJournals)
        dataApi.getJournals(
            fromTimestamp,
            toTimestamp,
            vehicleId,
            drivers,
            fetchCoordinates,
            journeyId
        ).then(res => {
            setJournals(res.journals)
            setLoadedJournals(true)
            setLoadingJournals(false)
        }).catch(err => {
            setLoadedJournals(false)
            setLoadingJournals(false)
        })
    }

    const patchJournalEntry = (journalId, fields) => {
        document.body.style.pointerEvents = 'none';
        document.body.style.cursor = 'waiting';
        return dataApi
            .putJournal(journalId, fields)
            .then((res) => {
                patchJournalsObject(res[0])
                document.body.style.pointerEvents = 'auto';
                document.body.style.cursor = 'default';
            })
            .catch((err) => {
                document.body.style.pointerEvents = 'auto';
                console.log(err);
            });
    };

    const deleteAdminTrip = (journalId) => {
        document.body.style.pointerEvents = 'none';
        document.body.style.cursor = 'waiting';
        return dataApi
            .deleteAdminTrip(journalId)
            .then((res) => {
                patchJournalsObject(res[0])
                document.body.style.pointerEvents = 'auto';
                document.body.style.cursor = 'default';
            })
            .catch((err) => {
                document.body.style.pointerEvents = 'auto';
                console.log(err);
            });
    };

    const patchJournals = (journalId, fields, aggregateFields, object) => {
        // Does not do any requests, just updates state!
        setJournals(
            manglePatchJournal(journalsRef.current, {payload: {
                data: bijectPutGetFields(fields, aggregateFields, object),
                journal_id: journalId}})
        )

    }

    const patchJournalsObject = (object) => {
        if (!Array.isArray(object)) {
            console.error("Provided object is not an array", object);
            return;
        }

        const updatedJournals = journalsRef.current.map(journalArray =>
            journalArray.map(journal => {
                const update = object.find(updateItem => updateItem.journal_id === journal.journal_id);
                return update ? { ...journal, ...update } : journal;
            })
        );

        setJournals(updatedJournals);
        console.log("Updated journals", updatedJournals);
    };

    const requestResetJournals = () => {
        // TODO this used to reset map overview too, but that property isn't here no more
        setJournals([])
    }

    const getOverrideHistory = (journalId) => {
       return dataApi
           .getOverrideHistory(journalId)
    }

    // Below state is just data mangling...
    // doesn't really belong here, but its sort of selector-ish
    // export const getJournals = (state) => {
    const journalState = useMemo(() => {
        const data = [];
        // const journals = state.data.journals;

        journals?.map((e) => {
            if (e?.length < 2) {
                data.push(e);
            } else {
                let firstTrip = e[0];
                let lastTrip = e[e?.length - 1];
                let odometer_max = 0;
                let congestionTaxSek = 0;
                let congestionTaxCount = 0;
                let congestionTaxSekOverride = null;
                let infraTaxSek = 0;
                let infraTaxCount = 0;
                let infraTaxSekOverride = null;



                e?.map((trip) => {
                    odometer_max += trip.trip?.odometer_max
                    congestionTaxSek = trip?.congestionTaxSek ? parseInt(trip?.congestionTaxSek) + congestionTaxSek : congestionTaxSek;
                    congestionTaxCount = trip?.congestionTaxCount + congestionTaxCount;
                    if (trip?.congestionTaxSekOverride !== null) {
                        congestionTaxSekOverride = parseInt(trip?.congestionTaxSekOverride) + (congestionTaxSekOverride || 0);
                    }
                    infraTaxSek = trip?.infraTaxSek ? parseInt(trip?.infraTaxSek) + infraTaxSek : infraTaxSek;
                    infraTaxCount = trip?.infraTaxCount + infraTaxCount;
                    if (trip?.infraTaxSekOverride !== null) {
                        infraTaxSekOverride = parseInt(trip?.infraTaxSekOverride) + (infraTaxSekOverride || 0);
                    }
                    
                });

                const mergedTrip = {
                    ...e[0],
                    geocode_start: {
                             ...lastTrip?.geocode_start,
                        },
                    trip: {
                        ...e[0]?.trip,
                        odometer_start: lastTrip?.trip?.odometer_start,
                        odometer_max: odometer_max,
                        timestamp_start_ms: lastTrip?.trip?.timestamp_start_ms,
                        purpose: firstTrip?.trip?.purpose,
                    },
                    stopTimeBackwards: lastTrip?.stopTimeBackwards,
                    override_start: lastTrip?.override_start,
                    mergedTripsId: e?.map((trip) => trip.journal_id),
                    congestionTaxSek: congestionTaxSek,
                    congestionTaxCount: congestionTaxCount,
                    congestionTaxSekOverride: congestionTaxSekOverride !== null ? congestionTaxSekOverride : null,
                    infraTaxSek: infraTaxSek,
                    infraTaxCount: infraTaxCount,
                    infraTaxSekOverride: infraTaxSekOverride !== null ? infraTaxSekOverride : null,
                    congestionData: {
                        ...lastTrip?.congestionData,
                    },
                };

                data.push([mergedTrip]);
            }
        });

        console.log("Building journal state", data);

        return data;
    }, [journals])

    // getJournalTableData = createSelector(getJournals, (journals) => {
    const journalTableData = useMemo(() => {
        const data = {
            //'<DATE_KEY>': []
        };

        console.log("Rebuilding journal table data");
        // console.log("GET JOURNAL TABLE DATA", journals)

        journalState.forEach((merged) => {
            merged.forEach((j) => {
                const dateStr = moment(j?.trip?.timestamp_start_ms).format("YYYY-MM-DD");
                if (data[dateStr] === undefined) {
                    data[dateStr] = [];
                }
                data[dateStr].push(j);
            });
        });
        console.log("journalTableData", data)
        return data;
    }, [journalState])

    // export const getJournalTableDataBadTrips = createSelector(getJournals, (journals) => {
    const journalTableDataBadTrips = useMemo(() => {
        const data = {
            //'<DATE_KEY>': []
        };

        console.log("Rebuilding journal table data with just bad trips");
        // console.log("GET JOURNAL TABLE DATA", journals)

        journalState.forEach((merged) => {
            merged.forEach((j) => {
                if (j.isBadTrip) {
                    const dateStr = moment(j?.trip?.timestamp_start_ms).format("YYYY-MM-DD");
                    if (data[dateStr] === undefined) {
                        data[dateStr] = [];
                    }
                    data[dateStr].push(j);
                }
            });
        });
        return data;
    }, [journalState]);

    return <TripLogbookContext.Provider
        value={{
            journals,
            loadedJournals,
            loadingJournals,
            mapOverviewData,
            // controlState,

            // getJournalTableData = createSelector(getJournals, (journals) => {
            journalState,
            journalTableData,
            journalTableDataBadTrips,

            requestDownloadCSV,
            requestFetchJournals,
            requestResetJournals,

            patchJournals,
            patchJournalEntry,
            patchJournalsObject,
            deleteAdminTrip,
            getOverrideHistory
        }}
    >{children}</TripLogbookContext.Provider>
}

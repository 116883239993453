import AlarmView from "../AlarmView/AlarmView";

const AlarmSafetyController = () => {
    return (
        <>
            <AlarmView alarmType={"SECURITY"} />
        </>
    );
};

export default AlarmSafetyController

import {Button, Card, Form, Typography} from "antd";
import {useContext} from "react";
import {ALARM_TEXT, ALARM_TYPES, alarmShowFields} from "../../../services/redux/constants/alarmConstants";
import Flex from "../../shared-components/Flex/Flex";
import Loading from "../../shared-components/Loading/Loading";
import {styles} from "./styles";
import {AlarmsEditorContext} from "../../../services/contexts/AlarmsEditorContext";

const AlarmEditor = () => {
    const [form] = Form.useForm();
    const alarmsEditorContext = useContext(AlarmsEditorContext);

    return !alarmsEditorContext.loaded ? (
        <Loading/>
    ) : (
        <>
            <Typography.Title>Skapa ett nytt {ALARM_TYPES[alarmsEditorContext.alarmType]}</Typography.Title>

            <Form
                onFinish={alarmsEditorContext.onFinish}
                onFinishFailed={(v) => console.log(v)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "0px",
                    justifyContent: "space-between",
                }}
                form={form}
                layout="vertical"
                name="newAlarm"
                initialValues={alarmsEditorContext.alarm || alarmsEditorContext.initialValues}
            >
                <Card style={{minWidth: "100%", padding: "0px"}}>
                    {Object.entries(alarmShowFields[alarmsEditorContext.alarmName]).map(([key, value]) => {
                        if (!value) return;

                        if (alarmsEditorContext.showAsCard.includes(key)) {
                            return (
                                <Form.Item
                                    name={key}
                                    label={ALARM_TEXT[key]}
                                    style={styles[key]}
                                    initialValue={alarmsEditorContext.isTracker}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Detta fält kan inte vara tomt",
                                        },
                                    ]}
                                >
                                    {alarmsEditorContext.fields[key]}
                                </Form.Item>
                            );
                        }
                    })}
                </Card>

                {Object.entries(alarmShowFields[alarmsEditorContext.alarmName]).map(([key, value]) => {
                    if (!value) return;

                    if (!alarmsEditorContext.showAsCard.includes(key)) {
                        const label = alarmsEditorContext.isTracker && key === "targetVehicleIds" ?
                            "Välj utrustning" : ALARM_TEXT[key]
                        return (
                            <Form.Item
                                name={key}
                                label={label}
                                style={styles[key]}
                                rules={[
                                    {
                                        required: false,
                                        message: "Detta fält kan inte vara tomt",
                                    },
                                ]}
                            >
                                {alarmsEditorContext.fields[key]}
                            </Form.Item>
                        );
                    }
                })}

                <Form.Item style={{width: "100%"}}>
                    <Flex gap={20} justifyContent={"end"}>
                        <Button onClick={alarmsEditorContext.onClickCancel}>Avbryt</Button>
                        <Button htmlType="submit" type={"primary"}>
                            Skapa
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </>
    );
};

export default AlarmEditor

import React, {useEffect, useState} from "react";
import {AutoComplete, Button, Card, Form, Input, Popconfirm, Table, Tooltip} from 'antd';
import {dataApi} from "../../../services/api/api";
import {DeleteOutlined} from '@ant-design/icons';
import {FORM_MESSAGES} from "../../../lang/translate/sv-SE/sv-SE";

export const EditableEmailList = ({
                                      data,
                                      displayTitle,
                                      displayPlaceHolder,
                                      selectedMethod,
                                      deleteEmail,
                                      addEmail
                                  }) => {

    const [form] = Form.useForm();
    const [knownUsers, setKnownUsers] = useState([])

    useEffect(() => {
        dataApi.getUsers()
            .then(i => {
                i = i?.filter((i) => !i?.isArchived)
                setKnownUsers(i)
            })

    }, [])

    function validateEmail(email) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    const [newValue, setNewValue] = useState()
    const handleInput = (value) => {
        setNewValue(value)
    };


    const onFinish = () => {
        if (validateEmail(newValue)) {
            addEmail(newValue)
            setNewValue(null)
            form.resetFields()
        }
    };

    const columns = [
        {
            title: selectedMethod + " för " + displayTitle?.toLowerCase(),
            dataIndex: 'email',
            key: 'email',
            width: '90%',
            render: email => <a>{email}</a>,
        },
        {
            key: 'delete',
            width: '10%',
            render: (text, record) => (
                <div>
                    <Tooltip title="Ändra">
                    </Tooltip>
                    <Popconfirm title="Ta bort användare?" okText="Ta bort" onConfirm={() => deleteEmail(record)}>
                        <Tooltip title="Ta bort">
                            <a style={{color: '#de4436'}}>
                                <DeleteOutlined style={{fontSize: '18px'}}/>
                            </a>
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const options = Object.entries(knownUsers).map(([key, title]) => {
        return {value: title.displayEmail, label: title.displayName + " - " + title.displayEmail}
    })

    return (
        <Card bordered={false} style={{height: 350, overflow: "auto"}}>
            <div>
                <Form
                    layout={'inline'}
                    form={form}>
                    <Form.Item
                        style={{width: '70%'}}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: FORM_MESSAGES.PERSON_EMAIL,
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}>
                        <AutoComplete
                            style={{
                                minWidth: 200,
                            }}
                            options={options}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onChange={(e) => handleInput(e)}


                        >
                            <Input
                                placeholder={displayPlaceHolder}
                                value={newValue}
                            />
                        </AutoComplete>


                    </Form.Item>
                    <Form.Item
                        style={{width: '20%'}}

                    >
                        <Button onClick={onFinish} type='primary'>Lägg till</Button>
                    </Form.Item>
                </Form>
            </div>
            <Table columns={columns} dataSource={data} pagination={false} />
        </Card>
    )

}
import { createStore, applyMiddleware, compose } from "redux";
import { combineReducers } from "redux";

// import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage

import Auth from "services/redux/reducers/authReducer";
import Theme from "services/redux/reducers/themeReducer";

// import { all } from 'redux-saga/effects';
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import AuthSagas from "./sagas/authSagas";

const eagerCombinedReducers = combineReducers({
    theme: Theme,
    auth: Auth,
});

const reducers = (state, action) => {
    if (action.type === "RESET") {
        state = undefined;
    }
    return eagerCombinedReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["din1"],
};

function configureStore(preloadedState) {
    const persistedReducer = persistReducer(persistConfig, reducers);

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

    function* rootSaga(getState) {
        yield all([
            AuthSagas(),
        ]);
    }

    sagaMiddleware.run(rootSaga);

    /*
  Why?
  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("./reducers");
      store.replaceReducer(nextRootReducer);
    });
  }
   */

    return store;
}

const store = configureStore();
const persistor = persistStore(store);
export default { store, persistor };

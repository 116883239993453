import {UsersController} from '../../components/trackson-components/users.js'
import {
    AuthorizationProvider,
    OrganizationSimpleProvider,
    shouldHideUsersOrganizationsColumn,
    UserListWithArchivedProvider,
    VehiclesComplexListProvider
} from "../../services/contexts/contexts";
import {UsersContextProvider} from "../../services/contexts/UsersContext";
import {SchedulingViewProvider} from "../../services/contexts/views-context/scheduling-context";
import {Nest} from "../../utils/utilities";

const SuperUserPage = () => {
    return   <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, OrganizationSimpleProvider, UserListWithArchivedProvider, VehiclesComplexListProvider, UsersContextProvider, SchedulingViewProvider]}>
        <UsersController />
    </Nest>
}

export default SuperUserPage

export const customConstant = {
    ADD_NEW_ZONE: "ADD_NEW_ZONE"
}
export const SD_BASE_COLORS = {
    RED: "#FF6B72",
    YELLOW: "#FFC542",
    BLUE: "#699DFF",
    GREEN: "#04D182",
    SWITCH_PRIVATE: "#21B573",
    SWITCH_BUSINESS: "#3e79f7",
    GRAY_LIGHTER: "#ededed",
    NOT_IMPLEMENTED_YET: "#3f3f3f",
};
export const SUPER_DEVICES_MODAL_TYPES = {
    AVL: "AVL",
    LOGG: "LOGG",
    DISPLAY_UNIT: "DISPLAY_UNIT",
    CONNECT: "CONNECT",
    ADD: "ADD",
    VEHICLE: "VEHICLE",
    C12SMS: "C12SMS",
    CHANGE_ORG: "CHANGE_ORG",
};
export const SUPER_DEVICES_KEYS_AND_INDEXES = {
    ADMIN: "ADMIN",
    CSV: "CSV",
    DARK: "DARK",
    DRIVER: "DRIVER",
    LIGHT: "LIGHT",
    PDF: "PDF",
    PURPOSE: "PURPOSE",
    ROOT: "ROOT",
    SALARY: "SALARY",
    SUMMARY: "SUMMARY",
    SUMMARY_ALL_VEHICLES: "SUMMARY_ALL_VEHICLES",
    SUMMARY_DRIVER: "SUMMARY_DRIVER",
    SUMMARY_VEHICLE: "SUMMARY_VEHICLE",
    TRACKER: "TRACKER",
    VEHICLE: "VEHICLE",
    acknowledged: "acknowledged",
    activeOn: "activeOn",
    activeOnEnter: "activeOnEnter",
    activeOnExit: "activeOnExit",
    address: "address",
    adminUserEmail: "adminUserEmail",
    adminUserPassword: "adminUserPassword",
    alarmName: "alarmName",
    alarmTarget: "alarmTarget",
    alarmType: "alarmType",
    archivedTsMs: 'archivedTsMs',
    batery_percentage: "battery_percentage",
    btn_change_org: "openChangeOrg",
    btn_connect_customer: "openCustomerConnect",
    btn_display_unit: "openDisplayUnit",
    btn_display_vehicle: "openDisplayVehicle",
    btn_retire: "openRetire",
    btn_show: "openShow",
    btn_to_storage: "openToStorage",
    centerCoords: "centerCoords",
    city: "city",
    config: "configName",
    connected: "physicalInstallTimestamp",
    country: "country",
    currentDriver: "currentDriver",
    currentVehicle: "currentVehicle",
    deviceType: "deviceType",
    displayEmail: "displayEmail",
    displayName: "displayName",
    distortion: "distortion",
    driver: "driver",
    email: "email",
    emails: "emails",
    error: "error",
    exEmail: "min-epost@example.com",
    firmware: "firmware",
    fullname: "fullName",
    geoZone: "geoZone",
    geoZoneAlarmId: "geoZoneAlarmId",
    geoZoneAlarmName: "geoZoneAlarmName",
    heartbeat: "uplinkLoss",
    iccid: "iccid",
    imei: "imei",
    installedVehicle: "installedVehicle",
    invoiceAddress: "invoiceAddress",
    lastSeenApp: "lastSeenApp",
    lastSeenDriving: "lastSeenDriving",
    lastSeenMs: "last_seen_ms",
    latestBatteryLevel: "latestBatteryLevel",
    latest_response_frequency: "latestResponseFrequency",
    latitude: "latitude",
    longitude: "longitude",
    meta: "meta",
    mileAge: "mileAge",
    name: "name",
    new_purposes: "new_purposes",
    obd: "odb",
    odoRemindDay: "odoRemindDay",
    odometer: "odometer",
    operation: "operation",
    operationalAt: "physicalInstallTimestamp",
    org: "organizationId",
    organizationId: "organizationId",
    organizationNumber: "organizationNumber",
    parentOrganization: "parentOrganization",
    permittedVehicles: 'permittedVehicles',
    phone: "phone",
    physicalInstallTimestamp: "physicalInstallTimestamp",
    place: "place",
    postadress: "Postadress",
    postalAddress: "postalAddress",
    purposes: "purposes",
    reg: "reg",
    registry: "registry",
    repeatAfterHours: "repeatAfterHours",
    reportTarget: "reportTarget",
    reportType: "reportType",
    retired: "retired",
    schedule: "schedule",
    selected: "choose",
    sendInvite: "sendInvite",
    show_on_the_map: "showOnTheMAp",
    speed: "speed_kph",
    state: "state",
    status: "status",
    streetAndNum: "streetAndNum",
    success: "success",
    targetOrganizationIds: "targetOrganizationIds",
    targetVehicleIds: "targetVehicleIds",
    timestampStartOfUse: "timestampStartOfUse",
    travels: "travels",
    type: "type",
    typeError: "typeError",
    update: "update",
    validating: "validating",
    vehicle: "vehicle",
    vehicleId: "vehicleId",
    warning: "warning",
    zip: "zip",
};
export const SUPER_DEVICES_ADD_UNIT_TYPE = ["FMM001", "FMM230", "FMB001", "FMB010", "FMC100", "FMT100", "TAT100", "FMB920", "FMM800", "GL530MG" ];
export const SD_DEVICE_TYPES = [
    {
        text: "FMB001",
        value: "FMB001",
    },
    {
        text: "FMC100",
        value: "FMC100",
    },
    {
        text: "FMT100",
        value: "FMT100",
    },
    {
        text: "TAT100",
        value: "TAT100",
    },
    {
        text: "FMB920",
        value: "FMB920",
    },
    {
        text: "FMM001",
        value: "FMM001",
    },
    {
        text: "GL530MG",
        value: "GL530MG",
    },
];
export const SD_BENEFIT_TYPES = [
    {
        TYPE: "PRIVATE",
        TYPE_DISPLAY_TEXT: "Privat",
    },
    {
        TYPE: "BENEFIT",
        TYPE_DISPLAY_TEXT: "Förmånsbil",
    },
    {
        TYPE: "WORK",
        TYPE_DISPLAY_TEXT: "Arbetsfordon",
    },
    {
        TYPE: "TRACKER",
        TYPE_DISPLAY_TEXT: "Tracker",
    },
    {
        TYPE: "POOL",
        TYPE_DISPLAY_TEXT: "Poolbil",
    },
];
export const SD_DEFAULT_TRIP_TYPES = [
    {
        TYPE: "PRIVATE",
        TYPE_DISPLAY_TEXT: "Privat",
    },
    {
        TYPE: "BUSINESS",
        TYPE_DISPLAY_TEXT: "Tjänst",
    },
];
export const SD_MODAL_SMS_FIELD_COMMANDS = ["cpureset/", "getrecord/", "setparam", "#:#/getparam", "#:#/getinfo/getstatus"];
export const SD_MODAL_AVL_LOG_KEYS_AND_INDEXES = {
    DATE: "date",
    DATE_FROM: "tsFromMs",
    DATE_TO: "tsToMs",
    FIELD_ID: "fileId",
};
export const SD_MODAL_SMS_LOG_KEYS_AND_INDEXES = {
    DATE_TIME: "date_time",
    NEW_VALUE: "new_value",
};
export const SD_TAB_COLORS = {
    WARNINGS: SD_BASE_COLORS.RED,
    STOCK: SD_BASE_COLORS.YELLOW,
    ON_THE_WAY: SD_BASE_COLORS.BLUE,
    IN_DRIVE: SD_BASE_COLORS.GREEN,
    RETIRED: SD_BASE_COLORS.GRAY_LIGHTER,
};
export const ZONES_CONSTANTS = {
    DATE: "Datum",
    ENTRY: "ENTRY",
    EXIT: "EXIT",
    BOTH: "BOTH",
    LATITUDE: "latitude",
    LONGITUDE: "longitude",
    RADIUS_MS: "radiusMs",
    COORDINATE: "coordinate",
    TRIP_TYPE: "tripType",
    APPLICATION: "application",
    DESCRIPTION: "description",
    OPERATION: "operation",
    ZONE_NAME: "zoneName",
    ADDRESS: "address",
};
export const CONSTANTS_TEXT = {
    BUSINESS: "BUSINESS",
    PRIVATE: "PRIVATE",
    BENEFIT: "BENEFIT",
    POOL: "POOL",
};
export const reportsTypes = {
    JOURNAL: "Körjournalrapport",
    SUMMARY_VEHICLE: "SUMMARY_VEHICLE",
    SUMMARY_DRIVER: "SUMMARY_DRIVER",
    SALARY: "SALARY",
    DISCREPANCY: "DISCREPANCY",
    PURPOSE: "PURPOSE",
};

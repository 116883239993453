import Support from "components/trackson-components/Support";
import { ServicesContextProvider } from "services/contexts/ServicesContext";
import React from "react";
const SupportPage = () => {
    return (
        <ServicesContextProvider>
            <Support />
        </ServicesContextProvider>
    );
};

export default SupportPage;

import React, {useEffect, useState} from "react";
import {OrganizationsTreeSelect} from "./OrganizationsTreeSelect";
import ColorPicker from "../../shared-components/ColorPicker/ColorPicker";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Slider,
    Switch,
    Typography,
} from "antd";
import {Icon} from "react-icons-kit";
import {DatePicker} from 'antd-v5';
import dayjs from "dayjs";
import moment from "moment";
import {dataApi} from "../../../services/api/api";
import {httpErrorMessages} from "../../../constants/httpErrorMessages";
import {handleUpdateOdometer} from "./odometer";
import {generateRandomColor} from "./sharedComponents";
import {CONSTANTS_TEXT} from "../../../constants/CustomConstant";
import {lookupStyleMarker} from "./lookupStyleMarker";

const {Text} = Typography;
const {Option, OptGroup} = Select;

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

const selectIcon = [];

Object.entries(lookupStyleMarker).map(([i, k]) => {
    selectIcon.push(
        <Option value={i}>
            <Icon icon={k}/>
        </Option>
    );
});

const categories = {
    BENEFIT: "Förmånsbil",
    PRIVATE: "Privat",
    WORK: "Arbetsfordon",
    POOL: "Poolbil",
    TRACKER: "Tracker",
};

const tripTypes = {
    PRIVATE: "Privat",
    BUSINESS: "Tjänst",
};

const countryNamesList = countries.getNames("sv", {select: "alias"});
const countryNamesAsArray = Object.entries(countryNamesList).map(([key, label]) => ({
    key,
    label,
}));
const countryNamesAsArraySorted = countryNamesAsArray.sort((a, b) => a.label.localeCompare(b.label));
const nordicCountries = countryNamesAsArraySorted.filter((country) => country.key === "SE" || country.key === "FI" || country.key === "NO" || country.key === "DK");
const sweden = countryNamesAsArraySorted.find((sweden) => sweden.key === "SE");
const findSweden = nordicCountries.findIndex((sweden) => sweden.key === "SE");
nordicCountries.splice(findSweden, 1);
nordicCountries.unshift(sweden);

const ODOMETER_IS_TOO_BIG = 900000; //km
const ODOMETER_MAX_VALUE = 1500000; //km
const ODOMETER_MIN_VALUE = 0; //km


export const NewVehicleForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingVehicleInfo, setLoadingVehicleInfo] = useState(false);
    const [useOrganization, setUseOrganization] = useState(props.record?.organizationId);
    const [errorMessageVehicle, setErrorMessageVehicle] = useState(null);
    const [selectedPermittedDrivers, setSelectedPermittedDrivers] = useState();
    const [odometerApplyRetroactiveDisabled, setOdometerApplyRetroactiveDisabled] = useState(true);
    const [odometerFormDisabled, setOdometerFormDisabled] = useState(true);
    const [previousDevice, setPreviousDevice] = useState();
    const [ifBenefitTypeIsPool, setIfBenefitTypeIsPool] = useState(false);
    const [handleAddForm] = Form.useForm();
    const [color, setColor] = useState();
    const [defaultIsoCountryCode, setDefaultIsoCountryCode] = useState(null);
    const permittedDrivers = props.permittedDrivers;
    const [regNo, setRegNo] = useState(null);

    const initialVehicleInfo = {
        co2Default: null,
        co2Override: null,
        consumptionDefault: null,
        consumptionOverride: null
    };
    const [vehicleInfo, setVehicleInfo] = useState(initialVehicleInfo);
    const [vehicleInfoEx, setVehicleInfoEx] = useState({});
    const [sliderProportion1, setSliderProportion1] = useState(null);

    useEffect(() => {
        clearValues()
    }, [props.isModalVisible])

    useEffect(() => {
        clearValues()
    }, [useOrganization])


    useEffect(() => {
        if(selectedPermittedDrivers?.length === 0){
            handleAddForm.setFieldsValue({["defaultDriver"]: null})
            handleAddForm.setFieldsValue({["currentDriver"]: null})

        }
    }, [selectedPermittedDrivers])

    useEffect(() => {
       if(selectedPermittedDrivers?.length === 0){
           handleAddForm.setFieldsValue({["defaultDriver"]: null})
           handleAddForm.setFieldsValue({["currentDriver"]: null})

       }
    }, [selectedPermittedDrivers])

    useEffect(() => {
        if(!props.record?.state?.imei && props.record && !props.moveDevice){
            const vehicleId = props.record?.id;
            dataApi.getVehicleDevice(vehicleId)
                .then(res => {
                    const deviceId = Array.from(new Set(res?.map(item => item.deviceId)));
                    deviceId.reverse();
                    setPreviousDevice(deviceId);
                })
        }
    }, [props.record, props.moveDevice])
    const onFinish = (e) => {
        if (e.registry && !props.record?.registry) {
            showConfirm(e);
        } else if (!e.state.imei && !props.record?.state?.imei) {
            showConfirmEmptyDevice(e);
        } else {
            handleAdd(e);
        }
    };

    const onFinishFailed = () => {
        setVehicleInfoEx({});
    };



    useEffect(() => {
        if (!props.isModalVisible) return
        if (props?.knownOrganizations?.length < 2 && !props.record) {
            setUseOrganization(props?.knownOrganizations[0]?.organizationId)
        } else if (props.moveDevice) {
            setUseOrganization(props.moveDevice?.organizationId)
        }
        else {
            setUseOrganization(props.record?.organizationId)
        }

    }, [props.knownOrganizations, props.record, props.isModalVisible, props.moveDevice])

    useEffect(() => {
        if (props.isModalVisible === true)
            setLoading(false)

    }, [props.isModalVisible])

    const onModalCancelNewForm = () => {
        setUseOrganization(null)
        props.onModalCancel()
    };

    const clearValues = () => {
        handleAddForm.resetFields()
        handleAddForm.setFieldsValue({["organizationId"]: useOrganization})
        setColor(props.record?.style?.color || generateRandomColor())
        setLoading(false)
        setDefaultIsoCountryCode(null);
        setRegNo(null);
        setVehicleInfo(initialVehicleInfo);
        setVehicleInfoEx({...props.record})
        setSliderProportion1(props.record?.proportion1)
        setOdometerApplyRetroactiveDisabled(true)
        setOdometerFormDisabled(!props.record?.state?.imei)
        setSelectedPermittedDrivers(props.record?.permittedDrivers)
        setIfBenefitTypeIsPool(props.record?.benefitType === CONSTANTS_TEXT.POOL)
        setErrorMessageVehicle(null)
    }

    const showConfirm = (values) => {
        Modal.confirm({
            title: "Bekräfta registreringsnummer  ",
            content: `Du har angivit ${values.registry}. Är detta korrekt? En gång tilldelat registreringsnummer kan inte ändras.`,
            okText: "Spara regnr",
            onOk() {
                handleAdd(values);
            },
            onCancel() {
                setLoading(false);
            },
        });
    };

    const showConfirmEmptyDevice = (values) => {
        Modal.confirm({
            title: "Ingen enhet kopplad till fordon",
            content: "Du har inte valt någon enhet. Är detta korrekt? Fordon kommer inte att registrera resor.",
            okText: "Ingen enhet",
            onOk() {
                handleAdd(values);
            },
            onCancel() {
                setLoading(false);
            },
        });
    };

    const onColorChange = (value) => {
        setColor(value.hex);
    };

    function showOdometerIsTooBigModal(valueKM, values, vehicleId) {
        const formatter = new Intl.NumberFormat("sv-SE", {
            style: "decimal",
            currency: "SEK",
        });

        Modal.confirm({
            title: "Ovanligt hög mätarställning",
            content: "Hoppsan, du verkar ha angett en ovanligt hög mätarställning.\nÄr du säker att du vill kalibrera till " + formatter.format(valueKM) + " kilometer?",
            onOk: () => handleUpdateOdometer(vehicleId, values),
            cancelText: "Avbryt",
            okText: "Kalibrera",
        });
    }

    async function handlePostVehicle(values) {
        try {
            const res = await dataApi.postSuperVehicle(values, useOrganization);
            const vehicleId = res.id;
            let vehicle = res;

            if (values.currentDriver?.userId && values.currentDriver?.userId !== props.record?.currentDriver?.userId) {
                const data = {userId: values.currentDriver?.userId};
                await dataApi.putCheckCurrentDriver(vehicleId, data);
                vehicle.currentDriver = {
                    id: values.currentDriver?.userId,
                    displayName: values.currentDriver?.displayName
                };
                await props.updateRecord(vehicle);
            }

            if (props.moveDevice) {
                await props.handleMoveDeviceToOtherVehicle(props.moveDevice, vehicleId);
                vehicle.state = props.moveDevice?.state;
            } else if (values.state?.imei && !props.record?.state?.imei ) {
                const devicesId = values.state.imei;
                const device = await dataApi.patchDevices(devicesId, vehicleId);
                vehicle.state = device;
                const findDevice = props.knownDevices?.find(item => item?.id === devicesId);
                if (findDevice) {
                    findDevice.installedVehicle = vehicleId;
                }
            }

            if (values.odometer?.applyRetroactive || values.odometer?.valueKm !== props.record?.odometer?.valueKm) {
                try {
                    if (values.odometer.valueKm > ODOMETER_IS_TOO_BIG) {
                        showOdometerIsTooBigModal(values.odometer.valueKm, values.odometer, vehicleId);
                    } else {
                        await handleUpdateOdometer(vehicleId, values.odometer);
                    }
                } catch (e) {
                    console.log(e);
                }
            }

            if (props.moveDevice) {
                props.setData([vehicle, { ...props.moveDevice, state: null }]);
            } else {
                props.setData(vehicle);
            }

        } catch (e) {
            let translatedMessage = httpErrorMessages[e?.response?.data];
            setErrorMessageVehicle(translatedMessage);
            setLoading(false);
        }
    }

    function handlePatchVehicle(values) {
        const vehicleId = props.record?.id;
        let vehicle;

        return dataApi.patchVehicle(vehicleId, values)
            .then((res) => {
                vehicle = res;
                if (values.currentDriver?.userId && (values.currentDriver?.userId !== props.record?.currentDriver?.id)) {
                    const data = {userId: values.currentDriver?.userId};
                    return dataApi.putCheckCurrentDriver(vehicleId, data)
                        .then(() => {
                            vehicle.currentDriver = {
                                id: values.currentDriver?.userId,
                                displayName: values.currentDriver?.displayName
                            };
                        });
                } else if (!values.currentDriver){
                    const data = {userId: null};
                    return dataApi.putCheckCurrentDriver(vehicleId, data)
                        .then(() => {
                            vehicle.currentDriver = null;
                        });
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (props.moveDevice) {
                    return props.handleMoveDeviceToOtherVehicle(props.moveDevice, vehicleId, values);
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (values.state?.imei && !props.record?.state?.imei) {
                    const devicesId = values.state.imei;
                    return dataApi.patchDevices(devicesId, vehicleId)
                        .then((device) => {
                            vehicle.state = device;
                            const findDevice = props.knownDevices?.find(item => item?.id === devicesId);
                            if (findDevice) {
                                findDevice.installedVehicle = vehicleId;
                            }
                        });
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (values.odometer?.applyRetroactive || values.odometer.valueKm !== props.record?.odometer.valueKm) {
                    try {
                        if (values.odometer.valueKm > ODOMETER_IS_TOO_BIG) {
                            showOdometerIsTooBigModal(values.odometer.valueKm, values.odometer, vehicleId);
                        } else {
                            return handleUpdateOdometer(vehicleId, values.odometer);
                        }
                    } catch (e) {
                        console.log(e);
                        return Promise.resolve();
                    }
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                props.setData(vehicle);
            })
            .catch((e) => {
                let translatedMessage = httpErrorMessages[e?.response?.data];
                setErrorMessageVehicle(translatedMessage);
                setLoading(false);
            });
    }

    const handleAdd = (values) => {
        const defDriver = permittedDrivers.find((u) => u.userId === values.defaultDriver?.id);
        const currentDriver = permittedDrivers.find((u) => u.userId === values.currentDriver?.id);
        const ifPoolCar = values.benefitType === "POOL";

        let permDrivers = values.permittedDrivers ? values.permittedDrivers.map((d) => {
                const index = permittedDrivers.findIndex((u) => u.userId === d);
                return {id: permittedDrivers[index]?.userId, displayName: permittedDrivers[index]?.displayName};
            })
            : [];

        values = {
            ...values,
            permittedDrivers: ifPoolCar ? [] : permDrivers,
            defaultDriver: defDriver ? {id: defDriver?.userId, ...defDriver} : (props.record ? {defaultDriver: null} : null),
            currentDriver: currentDriver ? {id: currentDriver?.userId, ...currentDriver} : null,
            permitAnyDriver: ifPoolCar,
            defaultTripType: values.defaultTripType ? CONSTANTS_TEXT.PRIVATE : CONSTANTS_TEXT.BUSINESS,
            style: {...values.style, color: color},
            fuel1: vehicleInfoEx?.fuel1,
            fuel2: vehicleInfoEx?.fuel2,
            consumption1: vehicleInfoEx?.consumption1,
            consumption2: vehicleInfoEx?.consumption2,
            proportion1: sliderProportion1,
        }

        if (vehicleInfo.co2Override !== vehicleInfo.co2Default) {
            values.co2GKmOverride = vehicleInfo.co2Override;
        }
        if (vehicleInfo.consumptionOverride !== vehicleInfo.consumptionDefault) {
            values.fuelLKmOverride = vehicleInfo.consumptionOverride;
        }

        if (props.record) {
            handlePatchVehicle(values)
        } else {
            handlePostVehicle(values)
        }
    };

    const handleChangeRegNo = (e) => {
        e.preventDefault();
        setRegNo(e.target.value);
    };

    const getVehicleInfo = () => {
        setLoadingVehicleInfo(true);
        dataApi
            .getVehicleInfo(regNo)
            .then((res) => {
                if (res && res.hexaData && res.hexaData[regNo.toUpperCase()]) {
                    setVehicleInfoEx(res.hexaData[regNo.toUpperCase()]);
                    setSliderProportion1(res.hexaData[regNo.toUpperCase()].proportion1);
                } else {
                    setVehicleInfoEx({});
                    setSliderProportion1(null);
                }
                setLoadingVehicleInfo(false);
            })
            .catch((e) => {
                setLoadingVehicleInfo(false);
                console.error(e);
            });
    };

    const resetFuelAndCO2 = () => {
        dataApi
            .postVehiclesResetFuelAndCO2(props.record?.id)
            .then((res) => {
                if (res) {
                    setVehicleInfoEx(res)
                }
            })
            .catch((err) => console.log(err));
    };

    const defaultPermittedUsers = props.permittedDrivers?.filter(user =>
        props.record?.permittedDrivers?.some(permission => permission.id === user.id)
    )?.map(item => item.id);

    const permittedDriversForOrg = props.permittedDrivers?.filter((i) => (i.organizationId === useOrganization));
    let devicesOptions = props.imeiList?.filter(item => item.organizationId === useOrganization && !item.installedVehicle && item.retired !== true);
    devicesOptions = devicesOptions?.filter(item => !item.isTracker)
    return (
        <Modal centered title={"Fordon"} onCancel={onModalCancelNewForm}
               onOk={() => handleAddForm.submit()}
               okText={props.record ? "Spara" : "Lägg till nytt fordon"}
               visible={props.isModalVisible}
               bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
        >
                    <Form form={handleAddForm} name="basic" layout="vertical"
                          style={{margin: "auto", justifyContent: "center"}} onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete={"off"}
                          initialValues={{
                              ...props.record,
                              defaultTripType: props.record?.defaultTripType === CONSTANTS_TEXT.PRIVATE,
                              benefitType: props.record?.benefitType || "BENEFIT",
                              permittedDrivers: defaultPermittedUsers,
                              odometer: {
                                  ...props.record?.odometer,
                                  recordedTimeMs: dayjs(),

                              },
                              style: props.record ? {...props.record.style} : {marker: "androidCar", color: color},
                              state: {imei: props.moveDevice?.state?.imei || props.record?.state?.imei}

                          }}
                    >

                        {errorMessageVehicle &&
                            <>
                                <Alert type={"error"} message={errorMessageVehicle} showIcon/>
                                <br/>
                            </>
                        }

                        <Form.Item
                            label="Organisation"
                            name="organizationId"
                            rules={[{required: true, message: "Välj organisation!"}]}
                        >
                            <OrganizationsTreeSelect disabled={props.record || props.moveDevice} placeholder={"Välj organisation"}
                                                     data={props.knownOrganizations}
                                                     selectedOrganization={useOrganization}
                                                     onChange={(v) => setUseOrganization(v)}/>
                        </Form.Item>

                        <Form.Item
                            label="Enhet"
                            name={["state", "imei"]}
                            rules={[{ required: false, message: "Välj organisation!" }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                onChange={(value) => setOdometerFormDisabled(!value)}
                                disabled={props.record?.state?.imei || props.moveDevice}
                                placeholder={"Välj enhet"}
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {devicesOptions?.filter((item) => previousDevice?.includes(item.id)).length > 0 && (
                                    <OptGroup label="Tidigare installerade enheter">
                                        {previousDevice?.map((deviceId, index) => {
                                            const item = devicesOptions.find((item) => item.id === deviceId);
                                            if (item) {
                                                return (
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {index === previousDevice.length - 1 ? `${item.imei} (senaste)` : item.imei}
                                                    </Select.Option>
                                                );
                                            }
                                            return null;
                                        })?.reverse()}
                                    </OptGroup>
                                )}

                                {devicesOptions?.filter((item) => !previousDevice?.includes(item.id)).length > 0 && (
                                    <OptGroup label="Alla lediga enheter">
                                        {devicesOptions
                                            ?.filter((item) => !previousDevice?.includes(item.id))
                                            .map((item) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.imei}
                                                </Select.Option>
                                            ))}
                                    </OptGroup>
                                )}
                            </Select>
                        </Form.Item>


                        <Form.Item label="Regnr">
                            <Input.Group compact>
                                <Form.Item name={"registry"} noStyle
                                           rules={[{required: false, message: "Registration number is required"}]}>
                                    <Input disabled={props.record?.registry && !props.isRoot}
                                           onInput={e => e.target.value = e.target.value.toUpperCase()}
                                           style={{width: "50%"}} placeholder="Ange regnr"
                                           onChange={handleChangeRegNo}/>
                                </Form.Item>
                                <Form.Item name={"displayName"} noStyle
                                           rules={[{required: false, message: "Street is required"}]}>
                                    <Input style={{width: "50%"}} placeholder="Ange alias"/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                        <Form.Item label="Landskod">
                            <Form.Item name={["isoCountryCode"]} noStyle>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                                    name={"isoCountryCode"}
                                    // onChange={ ifEdit}
                                    onChange={(val) => {
                                        setDefaultIsoCountryCode(val);
                                    }}
                                    // onSelect = { (value) => modalReminderToCheckReg(value)}
                                    style={{minWidth: 150}}
                                    // defaultValue={record?.isoCountryCode ? record?.isoCountryCode : null}
                                    placeholder="Ange landskod"
                                >
                                    <OptGroup label="Skandinavien">
                                        {nordicCountries.map((country) => (
                                            <Option value={country.key}>
                                                {country.label} ({country.key})
                                            </Option>
                                        ))}
                                    </OptGroup>
                                    <OptGroup label="Övriga länder">
                                        {countryNamesAsArraySorted.map((country) => (
                                            <Option value={country.key}>
                                                {country.label} ({country.key})
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </Form.Item>

                        </Form.Item>
                        <Form.Item label="Biluppgifter">
                            {defaultIsoCountryCode !== "SE" || !handleAddForm.getFieldValue("registry") ? (
                                <p style={{marginTop: "0.5em", fontStyle: "italic"}}>Kontrollera att registreringsnummer
                                    är
                                    angivet ovan och följer Trafikverkets regler.</p>
                            ) : null}

                            {handleAddForm.getFieldValue("isoCountryCode") === "SE"
                            && handleAddForm.getFieldValue("registry")
                            && !vehicleInfoEx?.consumption1 ? (
                                <Form.Item>
                                    <Button
                                        loading={loadingVehicleInfo} type="secondary" onClick={getVehicleInfo}>
                                        Hämta info från biluppgifter
                                    </Button>
                                </Form.Item>
                            ) : null}

                            {handleAddForm.getFieldValue("isoCountryCode") === "SE"
                            && vehicleInfoEx?.consumption1 ? (
                                <Form.Item>
                                    <Button loading={loadingVehicleInfo} type="secondary" onClick={resetFuelAndCO2}>
                                        Återställ data från biluppgifter
                                    </Button>
                                </Form.Item>
                            ) : null}
                        </Form.Item>

                        {(() => {
                            if (Object.keys(vehicleInfoEx)?.length > 0) {
                                <Divider/>;
                                if (vehicleInfoEx?.fuel2 === null) {
                                    return (
                                        <>
                                            <Row gutter={16} >
                                                <Col span={8}>
                                                    <h5>Bränsle</h5>
                                                    <Divider style={{margin: "4px 0"}}/>
                                                    <span style={{marginTop: 8}}>
                                                <span>{vehicleInfoEx?.fuel1?.svName}</span>
                                            </span>
                                                </Col>
                                                <Col span={8}>
                                                    <h5>CO2 Utsläpp</h5>
                                                    <Divider style={{margin: "4px 0"}}/>
                                                    {vehicleInfoEx?.co2GKm1 !== null && (
                                                        <span style={{marginTop: 8}}>
                                                    {vehicleInfoEx?.co2GKm1 !== null ? vehicleInfoEx?.co2GKm1 : ""}
                                                            {"g/km"}
                                                </span>
                                                    )}
                                                </Col>
                                                <Col span={8}>
                                                    <h5>Förbrukning</h5>
                                                    <Divider style={{margin: "4px 0 12px 0"}}/>
                                                    <Row justify="flex-start">
                                                        <Form.Item noStyle>
                                                            <InputNumber style={{width: "70px"}}
                                                                         value={vehicleInfoEx?.consumption1}
                                                                         onChange={(e) => setVehicleInfoEx({
                                                                             ...vehicleInfoEx,
                                                                             consumption1: e
                                                                         })}/>
                                                        </Form.Item>
                                                        <Text style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            padding: "3px",
                                                            paddingLeft: "8px"
                                                        }}>
                                                            {(() => {
                                                                return vehicleInfoEx.fuel1 ? vehicleInfoEx.fuel1.consumptionUnit : "";
                                                            })()}
                                                        </Text>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </>
                                    );
                                }
                                if (vehicleInfoEx.fuel2 !== null) {
                                    const marks = {
                                        0: vehicleInfoEx.fuel1?.svName,
                                        100: vehicleInfoEx.fuel2?.svName,
                                    };
                                    return (
                                        <>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <h5>Bränsle</h5>
                                                    <Divider style={{margin: "4px 0"}}/>
                                                    <Row>
                                                <span style={{marginTop: 8}}>
                                                    <span>{vehicleInfoEx.fuel1?.svName}</span>
                                                </span>
                                                    </Row>
                                                    <Row>
                                                <span style={{marginTop: 16}}>
                                                    <span>{vehicleInfoEx.fuel2?.svName}</span>
                                                </span>
                                                    </Row>
                                                </Col>
                                                <Col span={8}>
                                                    <h5>CO2 Utsläpp</h5>
                                                    <Divider style={{margin: "4px 0"}}/>
                                                    <Row>
                                                        {vehicleInfoEx.co2GKm1 !== null && (
                                                            <span style={{marginTop: 8}}>
                                                        {vehicleInfoEx.co2GKm1 !== null ? vehicleInfoEx.co2GKm1 : ""}
                                                                {"g/km"}
                                                    </span>
                                                        )}
                                                    </Row>
                                                    <Row>
                                                        {vehicleInfoEx.co2GKm2 !== null && (
                                                            <span style={{marginTop: 16}}>
                                                        {vehicleInfoEx.co2GKm2 !== null ? vehicleInfoEx.co2GKm2 : ""}
                                                                {"g/km"}
                                                    </span>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col span={8}>
                                                    <h5>Förbrukning</h5>
                                                    <Divider style={{margin: "4px 0 12px 0"}}/>
                                                    <Row justify="flex-start">
                                                        <Form.Item noStyle>
                                                            <InputNumber style={{width: "70px"}}
                                                                         value={vehicleInfoEx?.consumption1}
                                                                         onChange={(e) => setVehicleInfoEx({
                                                                             ...vehicleInfoEx,
                                                                             consumption1: e
                                                                         })}/>
                                                        </Form.Item>
                                                        <Text style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            padding: "3px",
                                                            paddingLeft: "8px"
                                                        }}>
                                                            {(() => {
                                                                return vehicleInfoEx.fuel1 ? vehicleInfoEx.fuel1?.consumptionUnit : "";
                                                            })()}
                                                        </Text>
                                                    </Row>
                                                    <Row justify="flex-start">
                                                        <Form.Item noStyle>
                                                            <InputNumber style={{width: "70px"}}
                                                                         value={vehicleInfoEx?.consumption2}
                                                                         onChange={(e) => setVehicleInfoEx({
                                                                             ...vehicleInfoEx,
                                                                             consumption2: e
                                                                         })}/>
                                                            <Text style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center",
                                                                padding: "3px",
                                                                paddingLeft: "8px"
                                                            }}>
                                                                {(() => {
                                                                    return vehicleInfoEx.fuel2 ? vehicleInfoEx.fuel2.consumptionUnit : "";
                                                                })()}
                                                            </Text>
                                                        </Form.Item>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <div style={{padding: "8px 24px"}}>
                                                <Slider value={sliderProportion1}
                                                        onChange={(v) => setSliderProportion1(v)}
                                                        marks={marks} tooltipVisible={false}
                                                        trackStyle={{background: "#3B4962"}}/>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <span>{sliderProportion1}%</span>
                                                    </Col>
                                                    <Col>
                                                        <span>{100 - sliderProportion1}%</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <div style={{ padding: "8px 24px" }}>
                                        <Slider value={sliderProportion1} onChange={(v) => setSliderProportion1(v)} marks={marks} tooltipVisible={false} trackStyle={{ background: "#3B4962" }} />
                                        <p>El</p>
                                        <p>Fossil</p>
                                    </div> */}
                                        </>
                                    );
                                }
                                <Divider/>;
                            }
                        })()}

                        <Form.Item
                            name={["odometer", "valueKm"]}
                            label="Mätarställning"
                            rules={[
                                {
                                    required: false,
                                    message: "Värdet måste vara ett tal",
                                    pattern: new RegExp(/^[0-9]+$/)
                                },
                            ]}
                        >
                            <Input
                                disabled={odometerFormDisabled}
                                min={ODOMETER_MIN_VALUE}
                                max={ODOMETER_MAX_VALUE}
                                suffix="km"
                            />
                        </Form.Item>

                        <Form.Item
                            name={["odometer", "applyRetroactive"]}
                            valuePropName="checked"
                            style={{marginBottom: 5}}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Checkbox
                                disabled={odometerFormDisabled}
                                checked={!odometerApplyRetroactiveDisabled}
                                onChange={() => setOdometerApplyRetroactiveDisabled(!odometerApplyRetroactiveDisabled)}>
                                Kalibrera fr.o.m. tidpunkt
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            name={["odometer", "recordedTimeMs"]}
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                changeOnBlur
                                disabled={odometerFormDisabled || odometerApplyRetroactiveDisabled}
                                showTime={{format: "HH:mm"}}
                                format="YYYY-MM-DD HH:mm"
                                defaultValue={dayjs()}
                                placeholder="Välj tidpunkt"
                                style={{width: "50%"}}
                                allowClear={false}
                            />
                        </Form.Item>


                        <Form.Item label="Typ av fordon" name="benefitType"
                                   rules={[{required: true, message: "Välj fordonstyp!"}]}>
                            <Radio.Group size="small"
                                         defaultValue={props.record?.benefitType || "BENEFIT"}
                                         onChange={(v) => setIfBenefitTypeIsPool(v.target.value === CONSTANTS_TEXT.POOL)}>
                                {Object.entries(categories).map(([key, title]) => {
                                    return <Radio.Button value={key}>{title}</Radio.Button>;
                                })}
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item label="Förval" name="defaultTripType"
                                   valuePropName={props.record?.defaultTripType === CONSTANTS_TEXT.PRIVATE ? "checked" : null}
                                   rules={[{required: true, message: "Välj förval!"}]}>
                            <Switch
                                className="tripType"
                                checkedChildren="Privat"
                                unCheckedChildren="Tjänst"
                            />
                        </Form.Item>

                        <Form.Item label="Behöriga förare" name="permittedDrivers"
                                   rules={[{required: false, message: "Välj fordon!"}]}>
                            {ifBenefitTypeIsPool ?
                                <p style={{marginTop: "0.5em", fontStyle: "italic"}}>Poolbilar är tillåtna för alla
                                    förare.</p>
                                :
                                <Select
                                    optionFilterProp="children"

                                    allowClear
                                    showSearch
                                    autoComplete={false}
                                    filterOption={(input, option) => JSON.stringify(option?.object)?.toLowerCase().includes(input.toLowerCase())}
                                    onSearch={() => null}

                                    onChange={(value, key) => setSelectedPermittedDrivers(key)}
                                    mode="multiple"
                                    style={{width: "100%"}}
                                    placeholder="Välj förare"
                                >

                                    <Option disabled={true} value={"xxx"}>
                                        {selectedPermittedDrivers?.length > 0 ? (
                                            <span
                                                onClick={() => {
                                                    handleAddForm.setFieldsValue({["permittedDrivers"]: []})
                                                    setSelectedPermittedDrivers([])
                                                }}
                                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Avmarkera alla
                            </span>
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    const m = permittedDriversForOrg?.map(({userId}) => userId)
                                                    handleAddForm.setFieldsValue({["permittedDrivers"]: m})
                                                    setSelectedPermittedDrivers(permittedDriversForOrg)
                                                }}
                                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Välj alla
                            </span>
                                        )}
                                    </Option>

                                    {permittedDriversForOrg?.map((v, i) => {
                                        return (
                                            <Option value={v.userId} object={v}>{v.displayName}</Option>
                                        );
                                    })}
                                </Select>}
                        </Form.Item>


                        <Form.Item label="Förvald förare" name={["defaultDriver", ["id"]]}
                                   rules={[{required: false, message: "Välj förvald förare!"}]}>

                            {ifBenefitTypeIsPool ?
                                <p style={{marginTop: "0.5em", fontStyle: "italic"}}>Poolbilar är tillåtna för alla
                                    förare.</p>
                                : <Select allowClear showSearch autoComplete={false}
                                          filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
                                          placeholder="Välj förare">
                                    {selectedPermittedDrivers?.map((i) => <Option
                                        value={i.userId || i.id || i.value}>{i.displayName || i.children}</Option>)}
                                </Select>}
                        </Form.Item>

                        <Form.Item label="Incheckad förare" name={["currentDriver", "id"]}
                                   rules={[{required: false, message: "Välj incheckad förare!"}]}>
                            <Select allowClear={true} showSearch autoComplete={false}
                                    filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
                                    placeholder="Välj förare">

                                {ifBenefitTypeIsPool ? permittedDriversForOrg?.map((v, i) =>
                                        <Option value={v.userId} object={v}>{v.displayName}</Option>)
                                    :
                                    selectedPermittedDrivers?.map((i) => <Option
                                        value={i.userId || i.id || i.value}>{i.displayName || i.children}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item label="Ikon">
                            <Input.Group compact>
                                <Form.Item name={["style", "marker"]} noStyle
                                           rules={[{required: false, message: "Registration number is required"}]}>
                                    <Select placeholder="Välj ikon">
                                        {selectIcon}
                                    </Select>
                                </Form.Item>
                                <Form.Item name={["style", "color"]} style={{margin: "auto"}}
                                           rules={[{required: false, message: "Street is required"}]}>
                                    <ColorPicker color={props.record?.style?.color || color}
                                                 colorChange={onColorChange}/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Form>

        </Modal>
    );
};

import {
    androidAlert,
    androidCar,
    androidContact,
    androidDrafts,
    androidGlobe,
    androidHome
} from "react-icons-kit/ionicons";
import {
    ambulance,
    bank,
    bolt,
    briefcase,
    bus,
    cutlery,
    glass,
    home,
    lightbulbO,
    paintBrush,
    plug,
    road,
    shoppingCart,
    truck,
    wrench
} from "react-icons-kit/fa";
import React from "react";

export const lookupStyleMarker = {
    androidCar: androidCar,
    androidContact: androidContact,
    bus: bus,
    home: home,
    road: road,
    glass: glass,
    briefcase: briefcase,
    wrench: wrench,
    shoppingCart: shoppingCart,
    truck: truck,
    bolt: bolt,
    cutlery: cutlery,
    lightbulbO: lightbulbO,
    ambulance: ambulance,
    bank: bank,
    plug: plug,
    paintBrush: paintBrush,
    androidDrafts: androidDrafts,
    androidHome: androidHome,
    androidGlobe: androidGlobe,
    androidAlert: androidAlert,
};
import {Nest} from "../../utils/utilities";
import SuperDevices from "../../components/trackson-components/Superdevices";

const SuperDevicePage = () => {
    return (
        <Nest
            wrappers={[
            ]}>
            <SuperDevices/>
        </Nest>
    );
};

export default SuperDevicePage;

import enLang from './entries/en_US';
import svLang from './entries/sv_SE';
import antdEnUS from 'antd/es/locale/en_US';
import antdEnSE from 'antd/es/locale/sv_SE';
import en from './locales/en_US.json'
import sv from './locales/sv_SE.json'
import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import {THEME_CONFIG} from 'configs/AppConfig';

import {STATIC_TEXT} from "./translate/sv-SE/sv-SE";

export const AppLocale = {
    en: enLang,
    sv: svLang,
};


export const resources = {
    en: {
        translation: en,
        antd: antdEnUS
    },
    sv: {
        translation: {...sv,  },
        antd: antdEnSE
    },

}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: THEME_CONFIG.locale,
    lng: THEME_CONFIG.locale,
    interpolation: {
        escapeValue: false
    }
})

export default i18n;
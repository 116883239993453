import {Tabs, Alert} from 'antd';
import {InfoCircleOutlined, SettingOutlined} from '@ant-design/icons';
import React, {useState} from "react"

import {SD_BASE_COLORS} from "../../../constants/CustomConstant";

export const TabsArchived = ({content, showAlertArchived, setShowAlertArchived}) => {
    const [activeKey, setActiveKey] = useState('1');
    const callbackTabClicked = (key) => {
        if (key === '3') {
            setShowAlertArchived(!showAlertArchived)
        } else {
            setActiveKey(key)
        }
    };



    return (
        <Tabs onTabClick={callbackTabClicked} activeKey={activeKey} defaultActiveKey={'1'}>
            {content}
            <Tabs.TabPane
                key={'3'}
                tab={<InfoCircleOutlined
                    style={{fontSize: 18, color: SD_BASE_COLORS.SWITCH_BUSINESS}}/>}
            >
            </Tabs.TabPane>

        </Tabs>

    )

}

export const AlertArchived = ({message}) => {
    const messageArchivedAlert = (
        <>
            Arkiverade fordon och användare kommer inte att vara synliga i övriga vyer. Du kan ändra ditt val under Mitt konto <SettingOutlined /> högst upp till höger.
        </>
    );

    return <>
        <Alert
            message={messageArchivedAlert}
            type="info" showIcon/>
        <br/>
    </>;
}
import React from "react"
import {
    Typography,
    Tooltip
} from 'antd';
import moment from 'moment-timezone';

const {Text} = Typography;
const redColor = "#de4436"
const orangeColor = "#fa8c16"
const greenColor = "#21B573"

export const LastSeenLink = ({value, callback, typ, isTracker, defaultText, formatTimestampToFullDate, customTooltip}) => {
    let dateAsMomentObj = getRefactoredDateText(value, isTracker, defaultText, formatTimestampToFullDate, customTooltip)

    return (
        <span>
            {dateAsMomentObj}
        </span>
    )
}

function getRefactoredDateText(date, isTracker = false, defaultText, formatTimestampToFullDate, customTooltip) {
    let time = moment.utc(date);
    let duration = moment.duration(moment().diff(time));
    let mins = duration.asMinutes();
    mins = Math.ceil(mins)
    let hours;
    let minutes;
    let tz = moment.tz.guess();

    const formats = {
        redDate: "D MMM -YY",
        tooltipTitle: "YYYY-MM-DD HH:mm"
    }

    if (mins > 59) {
        let time = mins
        hours = Math.floor(time / 60)
        minutes = time % 60
    }

    if (!moment.isMoment(date)) {
        date = moment.utc(date) // ok for js date, milliseconds or string in ISO format
    }

    let tooltipTitle = customTooltip || date.tz(tz).format(formats.tooltipTitle);

    if (defaultText) {
        return <Tooltip title={tooltipTitle}>
            <Text>{date.tz(tz).format(formats.redDate)}</Text>
        </Tooltip>
    }

    if(formatTimestampToFullDate){
        return date.tz(tz).format(formats.tooltipTitle)

    }

    if (isTracker) {

        if (date.isSameOrAfter(moment().subtract(60, 'm'), 'minute')) {
            return <Tooltip title={tooltipTitle}>
                <Text style={{color: greenColor}}>{mins ? mins + ' min sen' : null}</Text>
            </Tooltip>
        } else if (date.isSameOrAfter(moment().subtract(60 * 24, 'm'), 'minute')) {
            return <Tooltip title={tooltipTitle}>
                <Text style={{color: greenColor}}>{hours ? hours + ' h' : null} {minutes} min sen</Text>
            </Tooltip>
        } else if (date.isSameOrAfter(moment().subtract(60 * 25, 'm'), 'minute')) {
            return <Tooltip title={tooltipTitle}>
                <Text style={{color: orangeColor}}>{hours ? hours + ' h' : null} {minutes} min sen</Text>
            </Tooltip>
        } else if (date.isSame(moment(), 'year')) {
            return <Tooltip title={tooltipTitle}>
                <Text style={{color: redColor}}>{date.tz(tz).format("D MMM")}</Text>
            </Tooltip>
        }
        return <Tooltip title={tooltipTitle}>
            <Text style={{color: redColor}}>{date.tz(tz).format(formats.redDate)}</Text>
        </Tooltip>
    } else {
        if ((date.isSame(moment(), 'day')) && (date.isSame(moment(), 'month')) && (date.isSame(moment(), 'year'))) {
            if (date.isSameOrAfter(moment().subtract(60, 'm'), 'minute')) {
                return <Tooltip title={tooltipTitle}>
                    <Text style={{color: greenColor}}> {mins} min sen</Text>
                </Tooltip>
            } else if (date.isSameOrAfter(moment().subtract(240, 'm'), 'minute')) {
                return <Tooltip title={tooltipTitle}>
                    <Text style={{color: orangeColor}}> {hours} h {minutes} min sen</Text>
                </Tooltip>
            }
        } else if (date.isSame(moment(), 'year')) {
            return <Tooltip title={tooltipTitle}>
                <Text style={{color: redColor}}>{date.tz(tz).format("D MMM")}</Text>
            </Tooltip>
        }

        return <Tooltip title={tooltipTitle}>
            <Text style={{color: redColor}}>{date.tz(tz).format(formats.redDate)}</Text>
        </Tooltip>
    }
}
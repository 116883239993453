import React, {useContext, useEffect, useState} from "react";
import {Col, Divider, Input, Row, Typography} from "antd";
import OverViewList from "./OverviewList/OverviewList";
import {MapContext} from "services/contexts/MapContext";
import {ORGANIZATION_ID} from "services/redux/constants/authConstants";
import {InfoWindow} from "components/shared-components/Marker/Marker";
import {OrganizationContext} from "services/contexts/OrganizationsContext";
import {OrganizationsTreeSelect} from "components/trackson-components/shared-components/OrganizationsTreeSelect";
import MapDisplay from "./MapDisplay";
import styles from "./styles";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {CardSelectOrganizationForRoot, SelectBenefitType} from "../shared-components/sharedComponents";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {RestrictedMapOrganizationsContext} from "../../../services/contexts/contexts";

const {Title} = Typography;
const {Search} = Input;
const Map = ({openExportTrackerReportModal = () => null, openGeozone = () => null}) => {
    const refreshFrequency = 10000;
    const excludedOrgs = useContext(RestrictedMapOrganizationsContext)
    const hideMap = excludedOrgs?.includes(parseInt(localStorage.getItem(ORGANIZATION_ID)));
    const {mapOverviewData, updateMapOverviewData, updateSelectedVehicleOverviewData} = useContext(MapContext);
    const {organizations} = useContext(OrganizationContext);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [followDriver, setFollowDriver] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [defaultCenter, setDefaultCenter] = useState([59.334591, 18.06324]);
    const [vehiclesDataToShowOnTheMap, setVehiclesDataToShowOnTheMap] = useState();
    const [vehiclesDataToShowOnTheTable, setVehiclesDataToShowOnTheTable] = useState([]);
    const [benefitType, setBenefitType] = useState();
    const [organizationIds, setOrganizationIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTermVehicleID, setSearchTermVehicleId] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const vehicleId = urlParams.get('vehicleId');
    useEffect(() => {
        if (vehicleId && mapOverviewData) {
            const v = mapOverviewData?.find(item => item?.meta?.vehicle_id === parseInt(vehicleId))
            let term = v?.meta?.vehicleDisplayName?.toLowerCase() || v?.meta?.reg?.toLowerCase()
                || vehicleId
            setSearchTermVehicleId(term)
        }

    }, [mapOverviewData, vehicleId])

    useEffect(() => {
        if(searchTermVehicleID){
            setSearchTerm(searchTermVehicleID)
        }
    }, [searchTermVehicleID])

    useEffect(() => {
        updateMapOverviewData()
    }, [])

    useEffect(() => {
        if (mapOverviewData) {
            let sortedList = mapOverviewData?.sort((a, b) => a?.meta?.reg?.toLowerCase()?.localeCompare(b?.meta?.reg?.toLowerCase()));
            const vehicleIds = sortedList?.map((v) => v.meta.vehicle_id)
            setSelectedVehicleIds(vehicleIds);
            setVehiclesDataToShowOnTheTable(sortedList);
        }
    }, [mapOverviewData]);

    useEffect(() => {
        let toShow =  mapOverviewData;
        if (searchTerm) {
            toShow = toShow?.filter((d) => JSON.stringify(d).toLowerCase().includes(searchTerm));
        }
        if (organizationIds.length > 0) {
            toShow = toShow?.filter((d) => organizationIds.includes(d?.meta?.organizationId));
        }

        if (benefitType === SUPER_DEVICES_KEYS_AND_INDEXES.TRACKER) {
            toShow = toShow?.filter((d) => d?.isTracker);
        } else if (benefitType === SUPER_DEVICES_KEYS_AND_INDEXES.VEHICLE) {
            toShow = toShow?.filter((d) => !d?.isTracker);
        }

        setVehiclesDataToShowOnTheMap(toShow);
        setVehiclesDataToShowOnTheTable(toShow);
    }, [selectedVehicleIds, organizationIds, searchTerm, benefitType]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateMapOverviewData();
            if (selectedMarker !== null) {
                updateSelectedVehicleOverviewData(selectedMarker?.meta.vehicle_id).then((res) => {
                    if (res === false) {
                        setSelectedMarker(null);
                    } else {
                        const latitude = res?.position.latitude;
                        const longitude = res?.position.longitude;
                        const coordinates = [latitude, longitude];
                        setFollowDriver(res);
                        setDefaultCenter(coordinates);
                    }
                });
            }
        }, refreshFrequency);
        return () => clearInterval(interval);
    }, [selectedMarker]);
    const onClickMarker = (marker) => {

        if (selectedMarker !== null && marker?.meta.vehicle_id === selectedMarker?.meta.vehicle_id) {
            setSelectedMarker(null);
            updateMapOverviewData();
        } else {
            const toShow = vehiclesDataToShowOnTheMap?.filter((vehicle) => vehicle?.meta.vehicle_id !== marker?.meta.vehicle_id);
            setDefaultCenter([marker.position.latitude, marker.position.longitude]);
            setSelectedMarker(marker);
            setFollowDriver(marker);
        }
    };
    const selectedOrganizations = (organizations) =>
    {
        setOrganizationIds(organizations);
    };

    const onHandleBenefitTypeChange = (value) => {
        setBenefitType(value);
    };

    const showAlert = !localStorage.getItem(ORGANIZATION_ID);

    return (
        showAlert ?
            <CardSelectOrganizationForRoot
                selectedOrganization={organizationIds}
                organizationSelectOnChange={(value) => setOrganizationIds([value])}
                knownOrganizations={organizations}
            />
            :
        <>
            <div style={{...styles.appContent}}>
                <Title>Karta</Title>
                <div style={{...styles.contentHeader}}>

                    {organizations?.length < 2 ? null :
                        <OrganizationsTreeSelect data={organizations} onChange={selectedOrganizations}
                                                 selectedOrganization={organizationIds} isMultiple={true}/>}
                    <SelectBenefitType onChange={onHandleBenefitTypeChange}/>
                    <Search
                        type="text"
                        placeholder={STATIC_TEXT.SEARCH_VEHICLE}
                        value={searchTerm}
                        enterButton
                        onChange={(v) => setSearchTerm(v.target.value.toLowerCase())}
                        style={{width: 300}}
                    />
                </div>
            </div>
            <div style={{...styles.mapContent}}>
                {!hideMap ? (
                    <>
                        <Row style={{...styles.mapRow}} gutter={16}>
                            <Col span={selectedMarker !== null ? 18 : 24}>
                                <MapDisplay
                                    vehiclesDataToShowOnTheMap={vehiclesDataToShowOnTheMap}
                                    followDriver={followDriver}
                                    selectedMarker={selectedMarker}
                                    mapOverviewData={mapOverviewData}
                                    defaultCenter={defaultCenter}
                                    onClickMarker={(e) => onClickMarker(e)}
                                    setSelectedMarker={(e) => setSelectedMarker(e)}
                                />
                            </Col>
                            {selectedMarker !== null ? (
                                <Col span={6}>
                                    <InfoWindow isTracker={selectedMarker.isTracker}
                                                showInfo={followDriver} onClick={(e) => onClickMarker(e)}/>
                                </Col>

                            ) : (
                                <></>
                            )}
                        </Row>
                    </>
                ) : (
                    <span>Kartan är ej tillgänglig.</span>
                )}
            </div>
            <Divider/>
            <OverViewList
                mapOverviewData={vehiclesDataToShowOnTheTable}
                selectedVehicleIds={selectedVehicleIds}
                followDriver={followDriver}
                selectedMarker={selectedMarker}
                onClickMarker={onClickMarker}
                openGeozone={openGeozone}
                updateMapOverviewData={updateMapOverviewData}
                benefitType={benefitType}
            />
        </>
    );
};
export default Map;

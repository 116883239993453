import React, {useState} from 'react'
import {
    Form,
    Modal,
    TreeSelect,
    Row, Divider
} from 'antd';
import * as icons from '@ant-design/icons'
import Col from 'antd/es/grid/col';
import { TwoRowsTitleText } from "./reusableComponents";
import { getOrgTreeDataWithChildren } from "./superDeviceHelpers";
import {SUPER_DEVICES_MODAL_TITLES, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";

const FORM_FIELDS = {
    EXISTING_ORG: 'selected_org_id',
};
const initialState = {
    selected_org_id: null,
};

//TODO: SET DEFAULT AS SELECTED OR ONLY ALLOW OTHER ORG IN DROPDOWN-SELECTIONS?
const ModalFormDeviceChangeOrganization = ({ visible, handleClose, handleOrg, selectedDevice, orgData  }) => {

    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(initialState);
    const orgTreeData = getOrgTreeDataWithChildren(orgData);
    const sdImei = selectedDevice.device ? selectedDevice.device.imei : '-'
    const device = selectedDevice.device
    const sDeviceOrgId = device.organizationId.organizationId
    // TODO there is no way the line below was functional
    // const cancel = handleClose()
    const formItemLayout = {
        layout: 'vertical',
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    console.log('FORMVALUES::::', formValues)
    console.log('FORMVALUES::ORGANIZATION_ID::', formValues.selected_org_id)

    return (

        <Modal
            title={SUPER_DEVICES_MODAL_TITLES.CHANGE_ORG}
            visible={visible}
            onOk={() => handleOrg(device, formValues.selected_org_id)}
            onCancel={handleClose()}
            >
                <Form form={form} {...formItemLayout} name='device_change_org_form'>

                    <TwoRowsTitleText span={24} title={'Avser IMEI'} text={sdImei}/>

                    <Divider dashed />

                    <Row gutter={[0,0]}>

                        <Col span={24}>
                            <ExistingOrgTreeDataDropDown
                                formValues={formValues}
                                setFormValues={setFormValues}
                                orgTreeData={orgTreeData}
                                org={sDeviceOrgId}
                            />
                        </Col>

                    </Row>
            </Form>

        </Modal>
    );
};

const ExistingOrgTreeDataDropDown = ({ formValues, setFormValues, org, orgTreeData }) => {

    return (
        <React.Fragment>
            <Row gutter={[0, 0]} justify='left'>
                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.EXISTING_ORG]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SELECT_AN_EXISTING_ORGANISATION,
                            },
                        ]}
                    >
                        <TreeSelect
                            suffixIcon={<icons.UserSwitchOutlined style={{ marginRight: 3 }} />}
                            style={{ width: '100%' }}
                            defaultValue={org}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={orgTreeData}
                            filterOption={(input, option) =>
                                option?.title?.toLowerCase().includes(input.toLowerCase())
                            }
                            placeholder={STATIC_TEXT.CHOOSE_AN_ORG}
                            treeDefaultExpandAll
                            onChange={(value) => {
                                setFormValues({ ...formValues, selected_org_id: value });
                            }}
                            showSearch={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ModalFormDeviceChangeOrganization;

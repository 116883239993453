import React, {useContext, useEffect, useState} from "react";
import {dataApi} from "../api/api";
import {ALARM_TYPES} from "../redux/constants/alarmConstants";
import {UserListSimpleContext, VehicleContext, VehiclesComplexListContext} from "./contexts";
import moment from 'moment'

export const AlarmsEventsListContext = React.createContext(null)
export const AlarmsEventsListProvider = (props) => {
    const [alarmData, setAlarmData] = useState()
    const [unacknowledgedAlarm, setUnacknowledgedAlarm] = useState()
    const [acknowledgedAlarm, setAcknowledgedAlarm] = useState()
    const [loading, setLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState()
    const [selectedVehicles, setSelectedVehicles] = useState()
    const [selectedDrivers, setSelectedDrivers] = useState()
    const [selectedAlarmTypes, setSelectedAlarmTypes] = useState([])
    const [selectedAlarmSubTypes, setSelectedAlarmSubTypes] = useState([])
    const [selectedAlarmTypesAndSubTypes, setSelectedAlarmTypesAndSubTypes] = useState([])
    const knownVehicles = useContext(VehiclesComplexListContext)
    const knownUsers = useContext(UserListSimpleContext)
    const [defaultPickerValue, setDefaultPickerValue] = useState()

    const knownAlarmTypes = [
        {
            value: -1,
            key: -1,
            label: '--Välj alla--',
            children: []
        },
        {
            value: 'SECURITY',
            key: 'SECURITY',
            label: 'Säkerhetslarm',
            children: [
                {
                    value: 'CRASH',
                    key: 'CRASH',
                    label: '- Incident',
                },
                {
                    value: 'JAMMING',
                    key: 'JAMMING',
                    label: '- Störd signal (jamming)',
                },
            ]
        },
        {
            value: 'FUNCTION',
            key: 'FUNCTION',
            label: 'Funktionslarm',
            children: [
                {
                    value: 'NO_DRIVER',
                    key: 'NO_DRIVER',
                    label: '- Förarlös resa',
                },
                {
                    value: 'UNPLUG',
                    key: 'UNPLUG',
                    label: '- Enhet urkopplad',
                },
                {
                    value: 'MISSING_TRIPS',
                    key: 'MISSING_TRIPS',
                    label: '- Resor ej rapporterade på X dagar',
                },
                {
                    value: 'INTERMITTENCE',
                    key: 'INTERMITTENCE',
                    label: '- Tappad kontakt',
                },
                {
                    value: 'BATTERY_SUB_20',
                    key: 'BATTERY_SUB_20',
                    label: '- Låg batterispänning',
                },

            ]
        },
        {
            value: 'GEO',
            key: 'GEO',
            label: 'Geo-larm',
            children: [
                {
                    value: 'GEO_ZONE',
                    key: 'GEO_ZONE',
                    label: '- Geofence',
                },
            ]
        }
    ]

    let pickerValue = [
        moment().add(-30, 'days'),
        moment().endOf('day')
    ]

    useEffect(() => {
        setDefaultPickerValue(pickerValue)
    }, [])

    useEffect(() => {
        fetchAlarms()
    }, [knownVehicles])


    function fetchAlarms() {
        let fromTimestamp = defaultPickerValue ? defaultPickerValue[0]?.unix() : pickerValue[0]?.unix()
        let toTimestamp = defaultPickerValue ? defaultPickerValue[1]?.unix() : pickerValue[1]?.unix()
        dataApi.getAlarmPeriod(fromTimestamp, toTimestamp)

            .then(e => {

                const data = e.map(alarm => {
                        const v = knownVehicles?.find(v => v.id === alarm.vehicleId)
                        return {...alarm, vehicle: v}
                    }
                )
                setAlarmData(data)
                // setLoading(false)
            })
    }


    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
    };

    const vehiclesSelectOnChange = value => {
        if (value.includes(-1)) {
            if(selectedVehicles?.length > 0) {
                setSelectedVehicles([])
                return;
            }
            const permissions = value ? knownVehicles
                .map(d => {
                    return d?.id
                }) : []
            setSelectedVehicles(permissions)
            return
        }

        const vehicles = value.filter(v => v !== null)
        setSelectedVehicles(vehicles)
    };

    const driversSelectOnChange = value => {
        if (value.includes(-1)) {
            if(selectedDrivers?.length > 0) {
                setSelectedDrivers([])
                return;
            }
            const permissions = value ? knownUsers
                .map(d => {
                    return d?.userId
                }) : []
            setSelectedDrivers(permissions)
            return
        }

        const drivers = value.filter(v => v !== null)
        setSelectedDrivers(drivers)
    };

    const alarmTypesAndSubTypesSelectOnChange = value => {
        let alarmTypes = []
        let subAlarmTypes = []
        setSelectedAlarmTypesAndSubTypes(value)
        console.log('selected types and subtypes', value)
        // console.log(ALARM_NAMES_SECURITY)
        console.log(Object.keys(ALARM_TYPES))

        value.map(value => {
            if (Object.keys(ALARM_TYPES).includes(value)) {
                alarmTypes.push(value)
            } else {
                subAlarmTypes.push(value)
            }
        })
        setSelectedAlarmTypes(alarmTypes)
        setSelectedAlarmSubTypes(subAlarmTypes)
    };

    const onChangeDate = (dates) => {
        if (dates && dates.length > 1) {
            const start = dates[0].startOf('day')
            const end = dates[1].endOf('day')
            const pickerValue = [
                start,
                end
            ]
            setDefaultPickerValue(pickerValue)
        }
    }

    return <AlarmsEventsListContext.Provider value={{
        alarmData,
        setAlarmData,
        unacknowledgedAlarm,
        acknowledgedAlarm,
        selectedOrganization,
        selectedVehicles,
        setSelectedVehicles,
        selectedDrivers,
        selectedAlarmTypes,
        selectedAlarmSubTypes,
        selectedAlarmTypesAndSubTypes,
        setSelectedAlarmTypesAndSubTypes,
        knownVehicles,
        knownAlarmTypes,
        organizationSelectOnChange,
        vehiclesSelectOnChange,
        driversSelectOnChange,
        alarmTypesAndSubTypesSelectOnChange,
        onChangeDate,
        pickerValue,
        defaultPickerValue,
        setDefaultPickerValue,
        loading,
        setLoading,
        fetchAlarms
    }}>{props.children}</AlarmsEventsListContext.Provider>

}
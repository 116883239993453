import {Switch, Table, Input, } from 'antd';
import {TimePicker} from 'antd-v5';
import moment from 'moment';
import React, {useState} from 'react'
import {dayOfWeekNames} from "../shared-components/sharedComponents";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import "./timeInputStyles.css"
import dayjs from "dayjs";

export const ScheduleTable2 = ({schedule, changeScheduleParam, ...props}) => {

    const columns = Object.entries(dayOfWeekNames).map(([key, title]) => {
        return {
            title: title,
            key,
            dataIndex: key - 1,
            render: (value, row) => {
                if (row.map(i => i.day === key ? row = row[key] : null))
                    return <CellView day={key} row={value}/>
            }
        }
    })

    const CellView = ({day, value, row}) => {
        const [disabled, setDisable] = useState(row?.active)

        return (props.alarmSchedule ?
                <div style={{minWidth: 150}}>
                    {!props.isTracker && <>
                        <TimePickerSetAlarm day={day} value={value} row={row} disabled={disabled} index={0}/> <br/>
                        <br/>
                        <TimePickerSetAlarm day={day} value={value} row={row} disabled={disabled} index={1}/> <br/>
                    </>
                    }
                    <AktivCheckBox day={day} value={value} row={row} disabled={disabled} setDisable={setDisable}/>
                </div> :
                <div style={{minWidth: 150}}>
                    <TimePickerSet day={day} value={value} row={row}/>
                    <AktivCheckBox day={day} value={value} row={row} disabled={disabled} setDisable={setDisable}/>
                </div>
        )
    }

    const AktivCheckBox = ({day, row, disabled, setDisable}) => {
        return <div>
            <Switch
                defaultChecked={row?.active}
                unCheckedChildren="Inaktiv"
                checkedChildren="Aktiv"
                disabled={props.ifDefaultSchedule}
                onChange={e => {
                    setDisable(!disabled)
                    changeScheduleParam(day, {active: e})
                }}
                style={{
                    marginTop: props.isTracker ? 0 : 16,
                    minWidth: 85,
                    backgroundColor: disabled ? (!props.selectedTripType ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS) : null
                }}
            />
        </div>
    }

    const TimePickerSetAlarm = ({day, row, disabled, index}) => {
        const format = 'HH:mm';

        let defaultValue = row?.times[index] ? [dayjs(formatTimeHHMM(row?.times[index].start?.toString()), format), dayjs(formatTimeHHMM(row?.times[index].stop?.toString()), format)] : null;
        return <TimePicker.RangePicker
            showNow={false}
            defaultValue={defaultValue}
            format={format}
            allowClear={index !== 0}
            disabled={props.ifDefaultSchedule ? true : !disabled}
            changeOnBlur={true}
            needConfirm={false}
            onChange={(time, timeString) => {

                if (!time) {
                    row.times = row.times.filter(((k, i) => i !== index))
                }
                if (time) {
                    const updatedTime = {
                        start: time[0].format("HHmm"),
                        stop: time[1].format("HHmm")
                    };

                    if (row.times[index]) {
                        row.times[index] = updatedTime;
                    } else {
                        row.times.push(updatedTime);
                    }
                }
                let target = {times: [...row.times]};
                console.log("target", target)
                console.log("time", time)
                console.log("timeString", timeString)
                changeScheduleParam(day, target)
            }}
        />
    }


    const TimePickerSetAlarm2 = ({day, row, disabled, index}) => {
        let timeRow = row?.times[index];
        let startTime = timeRow?.start?.toString().length < 4 ? "0" + timeRow?.start : timeRow?.start;
        let stopTime = timeRow?.stop?.toString().length < 4 ? "0" + timeRow?.stop : timeRow?.stop;

        const handleTimeChange = (timeValue, timeType) => {
            if (!timeValue.isValid()) {
                row.times = row.times.filter(((k, i) => i !== index))
            }else {
                const timeString = timeValue.format("HHmm");

                const updatedTime = {...row.times[index], [timeType]: timeString};

                if (row.times[index]) {
                    row.times[index] = updatedTime;
                } else {
                    row.times.push(updatedTime);
                }
            }

            let target = {times: [...row.times]};
            changeScheduleParam(day, target);
        };

        return (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                <Input
                    type="time"
                    allowClear={index !== 0}
                    defaultValue={formatTimeHHMM(startTime?.toString())}
                    disabled={props.ifDefaultSchedule ? true : !disabled}
                    onBlur={(e) => handleTimeChange(moment(e.target.value, "HH:mm"), 'start')}
                    style={{marginRight: '5px'}} // Space between inputs
                />-
                <Input
                    type="time"
                    allowClear={index !== 0}
                    defaultValue={formatTimeHHMM(stopTime?.toString())}
                    disabled={props.ifDefaultSchedule ? true : !disabled}
                    onBlur={(e) => handleTimeChange(moment(e.target.value, "HH:mm"), 'stop')}
                    style={{marginLeft: '5px'}} // Space between inputs
                />
            </div>
        );
    };

    function formatTimeHHMM(time) {
        if (time === 0 || time === "0") {
            return "00:00";
        }
        if (time) {
            while (time.length < 4) {
                time = '0' + time;
            }
            return time.substring(0, 2) + ":" + time.substring(2);
        }
        return null;
    }

    const TimePickerSet = ({day, row}) => {
        const format = 'HH:mm';

        console.log(row.start)
        return <TimePicker.RangePicker
            defaultValue={[dayjs(formatTimeHHMM(row.start?.toString()), format), dayjs(formatTimeHHMM(row.stop?.toString()), format)]}
            format={format}
            allowClear={false}
            minuteStep={5}
            disabled={props.ifDefaultSchedule ? true : !row.active}
            changeOnBlur={true}
            needConfirm={false}
            onChange={(time, timeString) => {
                changeScheduleParam(day, {
                    start: dayjs(timeString[0], "HH:mm").format("HHmm"),
                    stop: dayjs(timeString[1], "HH:mm").format("HHmm"),
                })
            }}
        />
    }
    const TimePickerSet3 = ({day, row}) => {
        let startTime = row?.start ;
        let stopTime = row?.stop ;

        return (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                <Input
                    type="time"
                    defaultValue={formatTimeHHMM(startTime.toString())}
                    disabled={props.ifDefaultSchedule ? true : !row.active}
                    onBlur={(e) => {
                        const timeString = moment(e.target.value, "HH:mm");
                        if (!timeString.isValid()) return
                        changeScheduleParam(day, {
                            start: timeString.format("HHmm"),
                        });
                    }}
                    style={{marginRight: '5px'}} // Space between inputs
                    placeholder="HHmm"
                    required="true"
                />-
                <Input
                    type="time"
                    defaultValue={formatTimeHHMM(stopTime.toString())}
                    disabled={props.ifDefaultSchedule ? true : !row.active}
                    onBlur={(e) => {
                        const timeString = moment(e.target.value, "HH:mm");
                        console.log(timeString)
                        if (!timeString.isValid()) return
                        changeScheduleParam(day, {
                            stop: timeString.format("HHmm"),
                        });
                    }}
                    style={{marginLeft: '5px'}} // Space between inputs
                    placeholder="HHmm"
                />
            </div>
        );
    };


    return <Table
        columns={columns}
        dataSource={[schedule]}
        pagination={false}
        scroll={{x: 1000}}
        style={props.ifDefaultSchedule ? {opacity: 0.6} : {}}
        {...props} />
}
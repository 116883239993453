import {OrganizationsTreeSelect} from "./OrganizationsTreeSelect";
import {
    Alert,
    Avatar,
    Button,
    Card,
    Checkbox,
    Divider,
    Dropdown,
    Form,
    InputNumber,
    Menu,
    Modal,
    Radio,
    Select,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {DatePicker} from 'antd-v5';
import dayjs from "dayjs";
import locale from 'antd/es/date-picker/locale/sv_SE';

import {ORGANIZATION_ID} from "../../../services/redux/constants/authConstants";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {EllipsisOutlined, ReloadOutlined} from '@ant-design/icons';
import React, {useEffect, useRef, useState} from "react";
import {handleUpdateOdometer} from "./odometer";
import moment from "moment";
import Flex from "../../shared-components/Flex";
import {Icon} from "react-icons-kit";
import {CarNumberPlateIcon} from "../Superdevices/reusableComponents";
import {SD_BASE_COLORS, SD_DEFAULT_TRIP_TYPES} from "../../../constants/CustomConstant";
import {lookupStyleMarker} from "./lookupStyleMarker";
import {VehicleMarker} from "../vehicles";
import {androidCar} from "react-icons-kit/ionicons";
import {dataApi} from "../../../services/api/api";

export const CardSelectOrganizationForRoot = ({
                                                  knownOrganizations,
                                                  organizationSelectOnChange,
                                                  selectedOrganization
                                              }) => {
    const handleSelectOrganizationAndSetHeader = (e) => {
        localStorage.setItem(ORGANIZATION_ID, e);
        window.location.reload(false);
    };

    return (
        <Card style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "40%",
            minHeight: 300
        }}>
            <Alert message={STATIC_TEXT.CHOOSE_ORGS}
                   description={STATIC_TEXT.TOO_LARGE_DATA_LIMIT_PLEASE_SELECT_ORGANIZATION} type="error"/>
            <br/>
            <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange} width={"100%"}
                                     selectedOrganization={selectedOrganization} isMultiple={false}/>
            <div style={{display: "flex", justifyContent: "flex-end", marginTop: 20}}>
                <Button type={"primary"} onClick={() => handleSelectOrganizationAndSetHeader(selectedOrganization)}>
                    Spara
                </Button>
            </div>
        </Card>
    );
};

export const dayOfWeekNames = {
    1: "Mån",
    2: "Tis",
    3: "Ons",
    4: "Tor",
    5: "Fre",
    6: "Lör",
    7: "Sön",
};


export const SelectBenefitType = ({onChange}) => {
    return (
        <div>
            <Select style={{width: 200}} placeholder={STATIC_TEXT.FILTER_BY_BENEFIT_TYPE} allowClear={true}
                    onChange={onChange}>
                <Select.Option value={""}>Alla</Select.Option>
                <Select.Option value={"TRACKER"}>Utrustning</Select.Option>
                <Select.Option value={"VEHICLE"}>Fordon</Select.Option>
            </Select>
        </div>
    );
};

export const generateRandomColor = () => "#" + (Math.random().toString(16).substr(-6))

export const ButtonMenuDropdown = ({menu}) => {
    return (
        <Dropdown
            trigger={['click']}
            overlay={menu}
        >
            <Button style={{float: "right"}} icon={<EllipsisOutlined/>}/>


        </Dropdown>
    )
}

export const tableProps = {
    sticky: {offsetHeader: 70},
    pagination: {showTotal: (total, range) => `${range[0]}-${range[1]} av ${total} objekt`, showSizeChanger: true}
}

export const tableColumnsWithTooltip = (columns) => {
    return columns?.map(item => ({
        ...item,
        ellipsis: true,

    }))
};
export const OdometerModal = ({visible, onCancel, updateOdometer, record}) => {
    const [form] = Form.useForm();
    const prevVisibleRef = useRef();
    const now = dayjs();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);

    const prevVisible = prevVisibleRef.current;

    const ODOMETER_IS_TOO_BIG = 900000; //km
    const ODOMETER_MAX_VALUE = 1500000; //km
    const ODOMETER_MIN_VALUE = 0; //km

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    }, [visible]);

    const onOk = () => {
        form.submit();
    };

    function showOdometerIsTooBigModal(valueKM, values) {
        const formatter = new Intl.NumberFormat("sv-SE", {
            style: "decimal",
            currency: "SEK",
        });

        Modal.confirm({
            title: "Ovanligt hög mätarställning",
            content: "Hoppsan, du verkar ha angett en ovanligt hög mätarställning.\nÄr du säker att du vill kalibrera till " + formatter.format(valueKM) + " kilometer?",
            onOk: () => handleOdometerChange(values),
            cancelText: "Avbryt",
            okText: "Kalibrera",
        });
    }

    const handleOdometerChange = async (values) => {
        values.valueKm = defaultOdometerValue;
        values.applyRetroactive = applyRecorded;
        handleUpdateOdometer(record?.id, values, updateOdometer);

        form.resetFields();
        onCancel();
    };

    const onFinish = (values) => {
        if (defaultOdometerValue > ODOMETER_IS_TOO_BIG) {
            showOdometerIsTooBigModal(defaultOdometerValue, values);
        } else {
            handleOdometerChange(values);
        }
    };

    const [defaultOdometerValue, setDefaultOdometerValue] = useState(record?.odometer?.valueKm);
    const [applyRecorded, setApplyRecorded] = useState(false);

    const onChangeApplyRecorded = (v) => {
        setApplyRecorded(v.target.checked);
    };

    const showHistoricalDate = true;

    return (
        <Modal title="Uppdatera mätarställning" visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form form={form} layout="vertical" onFinish={onFinish} name="userForm">
                <Form.Item
                    name="valueKm"
                    label="Mätarställning"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <InputNumber
                        min={ODOMETER_MIN_VALUE}
                        max={ODOMETER_MAX_VALUE}
                        onChange={(e) => {
                            setDefaultOdometerValue(e);
                        }}
                        defaultValue={defaultOdometerValue}
                        style={{width: "50%"}}
                        suffix="km"
                    />{" "}
                    km
                </Form.Item>

                {!showHistoricalDate ? null : (
                    <>
                        <Checkbox onChange={onChangeApplyRecorded} checked={applyRecorded} style={{marginLeft: "5px"}}>
                            Kalibrera fr.o.m. tidpunkt
                        </Checkbox>

                        <Form.Item
                            name="fromTimeMs"
                            label="Avläsningstidpunkt"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                locale={locale}
                                changeOnBlur
                                disabled={!applyRecorded}
                                showTime={{format: "HH:mm"}}
                                format="YYYY-MM-DD HH:mm"
                                defaultValue={now}
                                placeholder="Välj tidpunkt"
                                style={{width: "50%"}}
                                onChange={() => setApplyRecorded(true)}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export function CheckInDriver({record, updateRecord, setRecord}) {
    const [currentDriver, setCurrentDriver] = useState()

    useEffect(() => {
        setCurrentDriver(record?.currentDriver?.id || null)
    }, [record])
    const onOk = () => {
        let vehicleId = record?.id;
        if ((!record?.currentDriver?.id) && !currentDriver) {
            updateRecord(record)
            return
        }
        dataApi.putCheckCurrentDriver(vehicleId, {userId: currentDriver})
            .then((_) => {
                const driver = record?.permittedDrivers?.find(item => item?.id === currentDriver)
                const res = {...record, currentDriver: driver}
                updateRecord(res)
            })

    };

    return (
        <Modal onOk={onOk} onCancel={() => setRecord(null)}
               title={`Välj incheckad förare för fordon  ${record?.registry}`} visible={!!record}>
            <Select style={{width: "100%"}} value={currentDriver}
                    onChange={(v) => setCurrentDriver(v)}
                    showSearch
                    allowClear={true}
                    autoComplete={false}
                    filterOption={(input, option) => option.children?.toLowerCase().includes(input.toLowerCase())}
            >
                <Select.Option value={null}>
                    Ingen förare
                </Select.Option>

                {record?.permittedDrivers?.map((i) => {
                    return (
                        <Select.Option value={i.id}>
                            {i.displayName}
                        </Select.Option>
                    );
                })}
            </Select>

        </Modal>
    );
}


export const showModalForMoveDevice = (record, vehicles, handleMoveDeviceToOtherVehicle, handleAddNewVehicle, afterArchive) => {
    const imei = record.state?.imei;
    const recordId = record.id;
    let vehicleId;
    const matchedVehicle = vehicles.find(vehicle => vehicle.id === recordId && !vehicle?.isArchived);

    const defaultValue = matchedVehicle ? matchedVehicle.id : null;
    const title = `Flytta enhet ${imei} till ett annat fordon inom samma organisation`
    const titleArchive = `Vill du koppla enhet ${imei} till ett annat fordon? `
    const onOk = "Ok"
    const onOkArchive = "Koppla"
    const onCancelArchive = "Hoppa över"
    const onCancel = "Avbryt"

    Modal.confirm({
        title: afterArchive ? titleArchive : title,
        okText: afterArchive ? onOkArchive : onOk,
        cancelText: afterArchive ? onCancelArchive : onCancel,
        content: (
            <div>
                <Select style={{width: "300px"}} defaultValue={defaultValue} onChange={(v) => (vehicleId = v)}>
                    {vehicles
                        ?.filter((i) => i.organizationId === record.organizationId)
                        ?.filter((i) => !i.isArchived)
                        .map((i) => {
                            if (!i?.state?.imei || i.id === recordId)
                                return (
                                    <Select.Option value={i.id}>
                                        {i.registry} {i.displayName}
                                    </Select.Option>
                                );
                        })}
                </Select>
                <Divider/>

                <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                    <Typography.Text>... eller skapa ett nytt fordon</Typography.Text>
                    <br/>
                    <Button
                        onClick={() => {
                            handleAddNewVehicle(record);
                            Modal.destroyAll();
                        }}
                    >
                        Skapa nytt
                    </Button>
                </Flex>
            </div>
        ),
        onOk() {
            handleMoveDeviceToOtherVehicle(record, vehicleId, vehicles);
        },
        onCancel() {
            console.log("Cancel");
        },
    });
};
export const ChangeVehiclesOrganization = (record, organizations, handleSelectChangeVehicleOrganization) => {
    let id;
    const displayName = record?.displayName ? record?.displayName + " " : "";
    const registry = record?.registry ? record?.registry : "";

    let vehicle = (displayName + registry) || record?.id;
    Modal.confirm({
        title: `Flytta ${vehicle} till en annan organisation`,
        style: {minWidth: 500},
        content: (
            <OrganizationsTreeSelect width={"100%"} data={organizations} defaultValue={record.organizationId}
                                     onChange={(v) => (id = v)}/>

        ),
        onOk() {
            handleSelectChangeVehicleOrganization(record, id);
        },
        onCancel() {
        },
    });
};

export const registryPlateWithAlias = (vehicle) => {
    return <div style={{maxWidth: 200}}>
        <CarNumberPlateIcon text={vehicle?.registry}/>
        <br/>
        <Typography.Text style={{textAlign: "left", margin: 0, backgroundColor: ""}}>
            {vehicle?.displayName}
        </Typography.Text>
    </div>
};

export function showDriversCell(record, value) {
    const ifPoolCar = record?.benefitType === "POOL";
    let text;
    if (ifPoolCar) {
        text = "Tillåts för alla"
    } else {
        text = value?.displayName || "Förare ej vald";
    }
    return text

}

export function vehiclesIcon(value) {
    const color = value?.color;
    let icon = value?.marker;
    if (icon?.constructor === String) {
        icon = lookupStyleMarker[icon];
    }
    return (
        <div>
            <Avatar style={{backgroundColor: color,}}>
                {icon ? <VehicleMarker marker={icon}/> : <Icon icon={androidCar}/>}
            </Avatar>
        </div>
    );
}

export const vehicleTag = (vehicle) => {
    const title = ((vehicle?.registry || "") + " " + (vehicle?.displayName || ''))
    return <Tooltip title={title}>
        <Tag color={SD_BASE_COLORS.SWITCH_BUSINESS} style={{margin: 5}}>
            {vehicle.registry || vehicle.displayName}
        </Tag>
    </Tooltip>
};

export const MenuItemInterval2 = (record, handleUpdateFreq) => {
    let updateIntervalText = `Intervall (${record?.latestResponseFrequency || "24h"})`;
    let selectedIntervalStyle = {color: SD_BASE_COLORS.SWITCH_BUSINESS, fontWeight: "bold"};

    const getConfirmModal = (value) => {
        Modal.confirm({
            title: "Ändra intervall",
            content: "Denna ändring kommer att påverkar batteritid. Mindre intervall betyder kortare livslängd på enheten.",
            okText: "Bekräfta",
            onOk() {
                handleUpdateFreq(record?.id || record?.meta?.vehicle_id, value?.key, record)
            },
        });
    };
    return <Menu.SubMenu icon={<ReloadOutlined/>} title={updateIntervalText}
                         onClick={(value) => getConfirmModal(value)}>
        <Menu.Item key={"24-h"} style={record?.latestResponseFrequency?.includes("24") ? selectedIntervalStyle : null}>24
            h (standard)</Menu.Item>
        <Menu.Item key={"12-h"} style={record?.latestResponseFrequency?.includes("12") ? selectedIntervalStyle : null}>12
            h</Menu.Item>
        <Menu.Item key={"6-h"} style={record?.latestResponseFrequency?.includes("6") ? selectedIntervalStyle : null}>6
            h</Menu.Item>
        <Menu.Item key={"3-h"} style={record?.latestResponseFrequency?.includes("3") ? selectedIntervalStyle : null}>3
            h</Menu.Item>
        <Menu.Item key={"1-h"} style={record?.latestResponseFrequency?.includes("1h") ? selectedIntervalStyle : null}>1
            h</Menu.Item>
    </Menu.SubMenu>

}


export const MenuItemInterval = ({record, handleUpdateFreq, setRecordFrequencyInterval}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [showValidation, setShowValidation] = useState(false);
    const [checkboxValue, setCheckboxValue] = useState(false);
    const [selectedValueSelector, setSelectedValueSelector] = useState('12-h');

    useEffect(() => {
        if (record) {
            setModalVisible(true)
            setSelectedValue(record?.latestResponseFrequency === "24h" ? 1 : 2)
            setSelectedValueSelector(record?.latestResponseFrequency !== "24h" ? record?.latestResponseFrequency?.replace('h', '-h') : "12-h")
            setShowValidation(false)
            setCheckboxValue(false)
        }
    }, [record])

    const radioStyle = {
        display: 'block',
        lineHeight: '22px',
        maxWidth: "100%",
        whiteSpace: "wrap",
        marginBottom: 8
    };

    const showModal = () => {
        setModalVisible(true);
    };

    function showSuccessModal(value) {
        value = value.replace('-h', 'h')

        let vehicleName = record?.meta?.vehicleDisplayName || record?.displayName
        const text1 = `Rapporteringsintervallet för ${vehicleName} ändrades till ${value}.<br /><br />Denna ändring slår igenom vid nästa rapporteringstillfälle.`;
        const text2 = `Rapporteringsintervallet för ${vehicleName} ändrades till ${value}.<br /><b>Observera att detta intervall förkortar enhetens batteritid drastiskt.</b><br /><br />Denna ändring slår igenom vid nästa rapporteringstillfälle.`;
        let content;

        switch (value) {
            case "1h":
            case "3h":
                content = text2;
                break;
            case "6h":
            case "12h":
                content = text1;
                break;
            default:
                break;
        }

        Modal.success({
            title: "Ändring registrerad",
            content: (
                <div dangerouslySetInnerHTML={{__html: content}}/>
            ),
        });
    }

    const handleOk = () => {
        if (!checkboxValue && selectedValue === 2) {
            setShowValidation(true)
            return
        }

        const value = selectedValue === 1 ? "24-h" : selectedValueSelector;
        handleUpdateFreq(record?.id || record?.meta?.vehicle_id, value, record)
            .then(() => {
                showSuccessModal(value)
                setModalVisible(false);
                setRecordFrequencyInterval(null)
            })
            .catch((error) => {
                console.error("Error", error);
            });
    };

    const handleCancel = () => {
        setModalVisible(false);
        setRecordFrequencyInterval(null)
    };

    const onChange = (e) => {
        setCheckboxValue(e.target.checked)
    };

    return (
        <div>

            <Modal
                title="Ändra uppdateringsfrekvens"
                visible={modalVisible}
                onOk={handleOk}
                okText={"Uppdatera"}
                onCancel={handleCancel}
                // footer={modalFooter}
            >
                <Flex flexDirection={"column"} gap={12}>
                    {record?.selectedResponseFrequency !== record?.latestResponseFrequency &&
                        <Typography style={{color: SD_BASE_COLORS.YELLOW}}>Väntande
                            ändring: {record.selectedResponseFrequency}</Typography>
                    } <Typography>Välj hur ofta enheten ska skicka en uppdatering om sin position.
                    Observera att ett kortare intervall än det rekommenderade kraftigt förkortar batteriets livslängd.</Typography>
                    <Radio.Group onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                        <Radio style={radioStyle} value={1}>
                            <b>Var 24:e timme (rekommenderas)</b>
                        </Radio>
                        <Radio style={radioStyle} value={2}>
                            <b>Anpassat intervall</b>
                        </Radio>
                        <Select value={selectedValueSelector} style={{width: "100%"}} onChange={(v) => {
                            setSelectedValue(2)
                            setSelectedValueSelector(v)
                        }}>
                            <Select.Option value="12-h">12 h</Select.Option>
                            <Select.Option value="6-h">6 h</Select.Option>
                            <Select.Option value="3-h">3 h</Select.Option>
                            <Select.Option value="1-h">1 h</Select.Option>
                        </Select>

                    </Radio.Group>

                    {selectedValue === 2 &&
                        <Form.Item
                            validateStatus={showValidation ? "error" : null}
                        >

                            <Checkbox
                                checked={checkboxValue}
                                onChange={onChange}
                                style={{color: showValidation ? SD_BASE_COLORS.RED : null}}>
                                * Jag är införstådd med att detta val kraftigt förkortar enhetens batteritid.
                            </Checkbox>

                        </Form.Item>
                    }

                </Flex>
            </Modal>
        </div>
    );
};

export function renderTripTypeTag(value) {
    const privateTrip = value?.startsWith("PRIVATE");
    const text = Object.entries(SD_DEFAULT_TRIP_TYPES)
        .filter(([key, title]) => title?.TYPE === value)
        .map(([key, title]) => title?.TYPE_DISPLAY_TEXT);
    const color = privateTrip ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS;
    return <Tag style={{color: color}}>{text}</Tag>;
}
import React, {useContext, useEffect, useState} from "react";
import {
    Avatar,
    Button,
    ConfigProvider,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Spin,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import {
    ArrowsAltOutlined,
    CarOutlined,
    ClearOutlined,
    DeleteOutlined,
    EditOutlined,
    FileExcelOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    RetweetOutlined,
    UpSquareOutlined,
    ExclamationCircleOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {Icon} from "react-icons-kit";
import {androidCar} from "react-icons-kit/ionicons";
import {LastSeenLink} from "./shared-components/DatumLink";
import {dataApi} from "../../services/api/api";
import Loading from "../shared-components/Loading/Loading";
import {SEARCH_TERM} from "../../services/redux/constants/authConstants";
import {OrganizationsTreeSelect} from "./shared-components/OrganizationsTreeSelect";
import {TabBadge} from "./Superdevices/reusableComponents";
import {STATIC_TEXT} from "../../lang/translate/sv-SE/sv-SE";
import {
    SD_BASE_COLORS,
    SD_BENEFIT_TYPES,
    SD_DEFAULT_TRIP_TYPES,
    SUPER_DEVICES_KEYS_AND_INDEXES
} from "../../constants/CustomConstant";
import {
    ButtonMenuDropdown,
    ChangeVehiclesOrganization,
    CheckInDriver,
    registryPlateWithAlias,
    showDriversCell,
    showModalForMoveDevice,
    tableColumnsWithTooltip,
    tableProps
} from "./shared-components/sharedComponents";
import {AlertArchived, TabsArchived} from "./shared-components/TabsArchived";
import {customizeRenderEmpty} from "./shared-components/customizeRenderEmpty";
import {TableActionBtnType} from "./Superdevices/Superdevices";
import {
    DeviceListContext,
    OrganizationsComplexListContext,
    shouldHideUsersOrganizationsColumn,
    UserListContext,
    VehiclesListWithArchivedContext
} from "../../services/contexts/contexts";
import {NewVehicleForm} from "./shared-components/newVehicleForm";
import {archiveVehicle, activateVehiclesModals} from "./shared-components/archiveVehiclesModals";
import {getOdometerValue} from "./shared-components/odometer";
import {lookupStyleMarker} from "./shared-components/lookupStyleMarker";
import {ModalWithFullList} from "./shared-components/modalShowFullList";

const {Text} = Typography;
const {TabPane} = Tabs;

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
const countryNamesList = countries.getNames("sv", {select: "alias"});
const countryNamesAsArray = Object.entries(countryNamesList).map(([key, label]) => ({
    key,
    label,
}));
const countryNamesAsArraySorted = countryNamesAsArray.sort((a, b) => a.label.localeCompare(b.label));
const nordicCountries = countryNamesAsArraySorted.filter((country) => country.key === "SE" || country.key === "FI" || country.key === "NO" || country.key === "DK");
const sweden = countryNamesAsArraySorted.find((sweden) => sweden.key === "SE");
const findSweden = nordicCountries.findIndex((sweden) => sweden.key === "SE");
nordicCountries.splice(findSweden, 1);
nordicCountries.unshift(sweden);

export const SuperVehicleController = () => {
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const knownOrganizations = useContext(OrganizationsComplexListContext)
    const vehicles = useContext(VehiclesListWithArchivedContext)
    const knownDevices = useContext(DeviceListContext)

    useEffect(() => {
        if (vehicles)
            setLoading(false)
    }, [vehicles])

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };

    function handleOnClickAddVehicle() {
        setIsModalVisible(true);
    }

    const handleChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                <Typography.Title>Superadmin - Fordon</Typography.Title>
                <div style={{display: "flex", gap: 15, flexWrap: "wrap"}}>
                    <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                             selectedOrganization={selectedOrganization} isMultiple={true}/>
                    <Input.Search
                        type="text"
                        placeholder="Sök efter regnr, IMEI eller förare"
                        label="Registreringsnummer"
                        defaultValue={searchTerm}
                        enterButton
                        onChange={handleChange}
                        style={{width: "400px"}}
                    />

                    <Button type={"primary"} onClick={() => handleOnClickAddVehicle()} icon={<CarOutlined/>}>
                        Lägg till
                    </Button>
                </div>
            </div>
            <VehicleTable
                organizationId={selectedOrganization}
                knownOrganizations={knownOrganizations}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                vehicles={vehicles}
                knownDevices={knownDevices}
                knownVehicles={vehicles}
                handleOnClickAddVehicle={handleOnClickAddVehicle}
            />
        </>
    );
};

const VehicleMarker = ({marker}) => {
    return <Icon icon={marker}/>;
};

const IsoCountyCodeEditor = ({vehicle}) => {
    return vehicle?.isoCountryCode ? (
        <span>
            {vehicle.isoCountryCode}
        </span>
    ) : null;
};

const VehicleTable = ({
                          vehicles,
                          changeOdometerValue,
                          changeDefaultTripType,
                          changeBenefitType,
                          setIsModalVisible,
                          knownDevices,
                          knownVehicles,
                          ...props
                      }) => {
    const [form] = Form.useForm();
    const [filteredVehiclesData, setFilteredVehiclesData] = useState();
    const [loading, setLoading] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [allData, setAllData] = useState();
    const [showAlertArchived, setShowAlertArchived] = useState(false);
    const [archivedData, setArchivedData] = useState();
    const [activeData, setActiveData] = useState();
    const [editingVehicle, setEditingVehicle] = useState();
    const [selectedRecord, setSelectedRecord] = useState();
    const [record, setRecord] = useState(null);
    const users = useContext(UserListContext);
    const knownUsers = users?.filter(v => !v.isArchived)

    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);
    useEffect(() => {
    }, [knownUsers])

    useEffect(() => {
        const sortData = filteredVehiclesData?.sort((a, b) => {
            // Sort by registry (if exists), put items without registry at the end
            if (a?.registry && b?.registry) {
                if (a.registry === b.registry) {
                    // If registry is the same, sort by alias
                    return a.displayName.localeCompare(b.displayName);
                } else {
                    return a.registry.localeCompare(b.registry);
                }
            } else if (!a?.registry && !b?.registry) {
                // If both items have no registry, sort by alias
                return a?.displayName?.localeCompare(b.displayName);
            } else {
                // One of the items has registry, put the one without registry at the end
                return a?.registry ? -1 : 1;
            }
        });
        setActiveData(sortData?.filter((item) => !item.isArchived))
        setArchivedData(sortData?.filter((item) => item.isArchived))
    }, [filteredVehiclesData])

    useEffect(() => {
        const search = localStorage.getItem(SEARCH_TERM);
        if (search) {
            props.setSearchTerm(search.toLowerCase());
            localStorage.removeItem(SEARCH_TERM);
        }

        setAllData(knownVehicles);
        setFilteredVehiclesData(knownVehicles);
    }, [knownVehicles]);

    useEffect(() => {
        let filteredList = allData;

        if (filteredList) {
            setLoading(false);
        }

        if (props.searchTerm.length > 1) filteredList = filteredList?.filter((item) => JSON.stringify(item).toLowerCase().includes(props.searchTerm));


        if (props?.organizationId?.length > 0) {
            filteredList = filteredList?.filter((i) => props.organizationId.includes(i.organizationId));
        }
        filteredList = filteredList?.filter((i) => !i.isTracker);

        setFilteredVehiclesData(filteredList);
    }, [props.searchTerm, allData, props.organizationId]);

    function handleAdd(rows) {
        const newData = [...allData];

        if (Array.isArray(rows)) {
            rows.forEach(row => {
                const index = newData.findIndex(item => row.id === item.id);
                if (index > -1) {
                    newData.splice(index, 1, {...newData[index], ...row});
                } else {
                    newData.push(row);
                }
            });
        } else {
            const index = newData.findIndex(item => rows.id === item.id);
            if (index > -1) {
                newData.splice(index, 1, {...newData[index], ...rows});
            } else {
                newData.push(rows);
            }
        }

        setFilteredVehiclesData(newData);
        setAllData(newData);
        setIsModalVisible(false);
        setEditingVehicle(null);
        setSelectedRecord(null);
    }


    const deleteVehicle = (record) => {
        Modal.confirm({
            title: `Bekräfta borttagning av ${record?.registry || record.displayName}`,
            icon: <ExclamationCircleOutlined/>,
            okType: 'danger',
            content: 'Är du säker på att du vill ta bort detta fordon?',
            onOk() {
                let newData;
                dataApi.deleteVehicle(record.id).then((_) => {
                    message.success(record?.registry || record.displayName + " har tagits bort");
                    handleDelete(record)
                });
            },
            onCancel() {
            },
            okText: "Ta bort"
        })


    };

    const handleSelectChangeVehicleOrganization = (record, selectedOrgId) => {
        setShowSpinner(true);
        const req = {organizationId: selectedOrgId}

        dataApi.patchChangeOrgVehicle(record.id, req)
            .then(_ => {
                message.success("Fordon flyttades till en annan organisation.")

                const newData = [...allData];
                const index = newData.findIndex(item => record.id === item.id);
                const item = newData[index];
                let newRow
                newRow = {
                    ...item,
                    organizationId: selectedOrgId,
                    permittedDrivers: [],
                    defaultDriver: null,
                    currentDriver: null,
                }
                newData.splice(index, 1, {...item, ...newRow});
                setFilteredVehiclesData(newData)
                setAllData(newData)

            })
            .catch((e) => {
                message.error("Något gick fel. Försök igen.");
                console.error(e);
            });
    };

    function handleMoveDeviceToOtherVehicle(record, vehicleId, knownVehicles) {
        setShowSpinner(true);
        knownVehicles = knownVehicles || allData

        const imei = record.state?.imei;
        const deviceIndex = knownDevices.findIndex((device) => device.imei === imei);

        if (deviceIndex === -1) {
            setShowSpinner(false);
            message.error("Kunde inte hitta enheten.");
            return;
        }

        const device = knownDevices[deviceIndex];

        if (record.id === vehicleId || !vehicleId) {
            setShowSpinner(false);
            return;
        }

        try {
            dataApi.patchDevices(device.id, vehicleId);

            const newData = [...knownVehicles];
            const recordIndex = newData.findIndex((item) => item.id === record.id);
            const vehicleIndex = newData.findIndex((item) => item.id === vehicleId);

            let newVehicle;
            if (vehicleIndex !== -1) {
                newVehicle = {...newData[vehicleIndex]};
                newVehicle.state = {...device};
                newVehicle.odometer = record.odometer;
                newData.splice(vehicleIndex, 1, newVehicle);
            }

            const newRecord = {...newData[recordIndex]};
            newRecord.state = null;
            newRecord.odometer = null;
            newData.splice(recordIndex, 1, newRecord);

            setFilteredVehiclesData(newData);
            setAllData(newData);
            Modal.success({
                title: "Enheten flyttades",
                content: (
                    <div>
                        <p>Enhet {device.imei} flyttades
                            till <strong>{newVehicle?.registry || newVehicle?.displayName}</strong>.</p>
                    </div>
                ),
            });
        } catch (error) {
            console.error(error);
            message.error("Något gick fel. Försök igen.");
        } finally {
            setShowSpinner(false);
        }
    }

    const handleRetiredDevice = (record) => {
        const imei = record.state?.imei;
        const device = knownDevices.find((i) => i.imei === imei);

        Modal.confirm({
            title: `Bekräfta pensionering för ${record?.registry || record.displayName}`,
            icon: <ExclamationCircleOutlined/>,
            okType: 'danger',
            content: 'Är du säker på att du vill pensionera enhet?',
            onOk() {
                dataApi.patchAdminDevices(device?.id, {retired: true}).then((_) => {
                    message.success("Enheten är pensionerad");
                    const newData = [...allData];
                    device.retired = true;
                    device.retired = true;

                    const recordIndex = newData.findIndex((item) => item.id === record.id);
                    const newRecord = {...newData[recordIndex]};
                    newRecord.state = null;
                    newRecord.odometer = null;
                    newData.splice(recordIndex, 1, newRecord);
                    setAllData(newData);
                });

            },
            onCancel() {
            },
            okText: "Pensionera"
        })

    };

    const handleDeleteInstalledDevice = (record) => {
        const imei = record.state?.imei;
        const device = knownDevices.find((i) => i.imei === imei);
        const itemIndex = allData.findIndex((i) => i.id === record.id);


        if (device) {
            Modal.confirm({
                title: `Bekräfta avinstallering för ${record?.registry || record.displayName}`,
                icon: <ExclamationCircleOutlined/>,
                okType: 'danger',
                content: 'Är du säker på att du vill avinstallera enhet?',
                onOk() {
                    let newData;
                    dataApi.deleteAdminDevicesInstalled(device.id).then((_) => {
                        message.success("Enheten har avinstallerats");
                        setShowSpinner(false);
                        device.installedVehicle = null;
                        device.retired = false;
                        if (itemIndex !== -1) {
                            const updatedItems = [...allData];
                            updatedItems[itemIndex].state = null;
                            setAllData(updatedItems);
                        }
                    });
                },
                onCancel() {
                },
                okText: "Avinstallera"
            })

        }
    };

    function handleDelete(row) {
        const newData = allData?.filter((item) => item?.id !== row?.id);
        setFilteredVehiclesData(newData);
        setAllData(newData);
    }

    const deleteVehicleHistory = (vehicle) => {
        Modal.confirm({
            title: "Radera Biluppgiftershistorik",
            content: "Vill du radera Biluppgiftershistorik?",
            onOk() {
                dataApi.deleteVehicleHistory(vehicle.id).then((res) => {
                    const newData = [...allData];
                    const index = newData?.findIndex((item) => vehicle.id === item.id);
                    const item = newData[index];
                    newData.splice(index, 1, {...item, ...res});
                    setAllData(newData);
                });
            },
        });
    };

    const columnsArchived = [
        {
            key: "registry",
            dataIndex: "registry",
            title: "Regnr",
            fixed: 'left',
            width: 150,
            render: (value, record) => registryPlateWithAlias(record)
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            width: 200,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: "Arkiverad",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.archivedTsMs,
            key: "archived",
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker} defaultText={true}/> : "-"),
        },
        {
            title: "",
            dataIndex: "operation",
            render: (text, record) => (
                <div style={{float: "right"}}>
                    <Button
                        icon={<UpSquareOutlined/>}
                        onClick={() => activateVehiclesModals(record, allData, setAllData, editVehicle)}>
                        {STATIC_TEXT.ACTIVE}
                    </Button>
                </div>
            )
        },
    ].filter(item => !item.hidden);

    function editVehicle(record) {
        setEditingVehicle(record);
        setIsModalVisible(true)
    }

    function handleAddNewVehicle(record) {
        setSelectedRecord(record);
        setIsModalVisible(true);
    }

    const menuDropdown = (record) => {
        const hasDevice = record?.state?.imei;
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => editVehicle(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item icon={<UserOutlined/>}
                       onClick={() => setRecord(record)}>{STATIC_TEXT.CHECK_IN_DRIVER} </Menu.Item>
            <Menu.Item disabled={!hasDevice} icon={<RetweetOutlined/>}
                       onClick={() => showModalForMoveDevice(record, allData, handleMoveDeviceToOtherVehicle, handleAddNewVehicle)}>{STATIC_TEXT.MOVE_TO_ANOTHER_VEHICLE} </Menu.Item>
            <Menu.Item disabled={!hasDevice} icon={<ArrowsAltOutlined/>}
                       onClick={() => handleDeleteInstalledDevice(record)}>{STATIC_TEXT.UNINSTALL_DEVICE} </Menu.Item>
            <Menu.Item disabled={!hasDevice} icon={<FileExcelOutlined/>}
                       onClick={() => handleRetiredDevice(record)}>{STATIC_TEXT.RETIRE_DEVICE} </Menu.Item>
            <Menu.Item icon={<HomeOutlined/>}
                       onClick={() => ChangeVehiclesOrganization(record, props.knownOrganizations, handleSelectChangeVehicleOrganization)}>{STATIC_TEXT.MOVE_VEHICLE_TO_OTHER_ORGANIZATION} </Menu.Item>
            <Menu.Item icon={<ClearOutlined/>}
                       onClick={() => deleteVehicleHistory(record)}>{STATIC_TEXT.REMOVE_VEHICLES_INFO} </Menu.Item>
            <Menu.Item icon={<FolderOpenOutlined/>}
                       onClick={() =>
                           archiveVehicle(record, allData, setAllData, knownDevices,
                               showModalForMoveDevice, handleMoveDeviceToOtherVehicle, handleAddNewVehicle)}>{STATIC_TEXT.ARCHIVE} </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => deleteVehicle(record)}>{STATIC_TEXT.REMOVE_VEHICLE} </Menu.Item>

        </Menu>
    };

    const showModalWithTooltip = (showModal, title) => {
        return <Tooltip title={"hej"}>
            <a onClick={showModal}>{title}</a>
        </Tooltip>
    };

    const columns = [
        {
            key: "registry",
            dataIndex: "registry",
            title: "Regnr",
            fixed: 'left',
            width: 150,
            render: (value, record) => registryPlateWithAlias(record),
        },
        {
            key: "style",
            dataIndex: "style",
            title: "Ikon",
            width: 80,
            render: (value) => {
                const color = value?.color;
                let icon = value?.marker;
                if (icon?.constructor === String) {
                    icon = lookupStyleMarker[icon];
                }
                return (
                    <div>
                        <Avatar style={{backgroundColor: color,}}>
                            {icon ? <VehicleMarker marker={icon}/> : <Icon icon={androidCar}/>}
                        </Avatar>
                    </div>
                );
            },
        },
        {
            key: "benefitType",
            dataIndex: "benefitType",
            title: "Typ",
            width: 150,
            render: (value, record) => {
                const benefitType = Object.entries(SD_BENEFIT_TYPES)
                    .find(([key, title]) => title?.TYPE === value)
                return benefitType[1]?.TYPE_DISPLAY_TEXT
            },
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            editable: false,
            width: 150,
            hidden: hideUsersOrg,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            key: "defDriver",
            dataIndex: "defaultDriver",
            title: "Förvald förare",
            editable: true,
            width: 150,
            render: (value, record) => {
                return showDriversCell(record, value)
            },
        },
        {
            key: "curDriver",
            dataIndex: "currentDriver",
            title: "Incheckad förare",
            editable: true,
            width: 150,
            render: (value) => (value?.displayName || "Förare ej vald"),
        },
        {
            title: "Behöriga förare",
            key: "dPermitted",
            dataIndex: "permittedDrivers",
            width: 150,
            render: (value, record) => {
                value = value?.filter(item => knownUsers?.find(user => user.id === item.id))

                const ifPoolCar = record?.benefitType === "POOL";
                let text;
                if (ifPoolCar) {
                    text = "Tillåts för alla"
                }
                if (value?.length === 0) {
                    text = "Förare ej vald";
                }
                if (value?.length === 1) {
                    const driver = knownUsers?.find((v) => v.userId === value[0].id);
                    text = driver?.displayName

                }
                if (value?.length > 1) {
                    const recordUsers = value?.map(item => item.id)
                    let title = value?.length + " förare";
                    return ModalWithFullList(title, null, null, recordUsers, null, knownUsers, props.knownOrganizations)
                }
                return text
            },
        },
        {
            title: "Sågs senast",
            dataIndex: "lastSeen",
            key: "lastSeen",
            width: 150,
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker}/> : "-"),
        },
        {
            key: "brand",
            dataIndex: "brand",
            title: "Bilmärke",
            width: 150,
            render: (text, record) => {
                const brand = record.brand || "";
                const model = record.model || "";
                const modelYear = record.modelYear || "";
                return `${brand} ${model} ${modelYear}`;
            },
        },
        {
            key: "vehicleType",
            dataIndex: "hybrid",
            title: "Bränsletyp",
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {record.fuel1 ? <Text>{record.fuel1?.svName}</Text> : null}
                        {record.fuel2 ? <Text>{record.fuel2?.svName}</Text> : null}
                    </div>
                );
            },
        },
        {
            key: "odom",
            dataIndex: "odometer",
            title: "Mätarställning",
            width: 150,
            render: (value, record) => getOdometerValue(record)
        },
        {
            key: "co2_fuel",
            dataIndex: "co2_fuel",
            title: "CO2",
            width: 150,
            render: (value, record) => {
                const co21 = record?.co2GKm1 !== null ? `${record?.co2GKm1} g/km` : null;
                const co22 = record?.co2GKm2 !== null ? `${record?.co2GKm2} g/km` : null;

                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Text>{co21}</Text>
                        <Text>{co22}</Text>
                    </div>
                );
            },
        },
        {
            key: "consumption",
            dataIndex: "consumption",
            title: "Förbrukning",
            width: 120,
            render: (value, record) => {
                const fuel1 = record?.fuel1 ? record?.consumption1 + " " + record?.fuel1.consumptionUnit : null;
                const fuel2 = record?.fuel2 ? record?.consumption2 + " " + record?.fuel2.consumptionUnit : null;

                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Text>{fuel1}</Text>
                        <Text>{fuel2}</Text>
                    </div>
                );
            },
        },

        {
            key: "isoCountryCode",
            dataIndex: "isoCountryCode",
            title: "Landskod",
            width: 100,
        },
        {
            key: "defaultTripType",
            dataIndex: "defaultTripType",
            title: "Förval",
            width: 80,
            render: (value) => {
                const privateTrip = value?.startsWith("PRIVATE");
                const text = Object.entries(SD_DEFAULT_TRIP_TYPES)
                    .filter(([key, title]) => title?.TYPE === value)
                    .map(([key, title]) => title?.TYPE_DISPLAY_TEXT);
                const color = privateTrip ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS;
                return <Tag style={{color: color}}>{text}</Tag>;
            },
        },
        {
            key: "imei",
            dataIndex: "state",
            title: "Enhet",
            width: 150,
            render: (value, record) => {
                if (!value?.imei) return

                let imei = value?.imei?.toString();
                return <Tooltip title={imei}>
                    {imei}
                </Tooltip>
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    const onBulkUpdateBU = () => {
        Modal.confirm({
            title: "Uppdatera BU-uppgifter",
            content:
                "Nya uppgifter kommer att läsas in baserat på data ifrån Transportstyrelsen. Detta bör endast göras i samband med stor förändring eller förnyelse av fordonsflottan. Vill du fortsätta?",
            okText: "Ja",
            onOk() {
                const vIds = filteredVehiclesData.map((v) => v.id);
                dataApi
                    .postVehicleSetFlag(vIds, "SE")
                    .then((res) => {
                        setAllData(res);
                        setFilteredVehiclesData(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            onCancel() {
            },
        });
    };

    const onModalCancel = () => {
        setEditingVehicle(null)
        setSelectedRecord(null)
        setIsModalVisible(false)
    };

    function updateRecord(record) {
        const newData = [...allData];
        const index = newData?.findIndex((item) => record?.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...record});
        setAllData(newData);
        setRecord(null)
    }

    return (
        <Form form={form} component={false}>

            <NewVehicleForm
                knownOrganizations={props.knownOrganizations}
                organizationId={props.organizationId}
                permittedDrivers={knownUsers}
                imeiList={knownDevices}
                knownDevices={knownDevices}
                record={editingVehicle}
                setRecord={setEditingVehicle}
                setData={handleAdd}
                onModalCancel={onModalCancel}
                moveDevice={selectedRecord}
                isModalVisible={props.isModalVisible}
                updateRecord={updateRecord}
                handleMoveDeviceToOtherVehicle={handleMoveDeviceToOtherVehicle}
                isRoot={true}
            />

            <CheckInDriver record={record} updateRecord={updateRecord} setRecord={setRecord}/>

            {!activeData ? (
                <Loading/>
            ) : (
                <Spin spinning={showSpinner}>

                    <TabsArchived
                        setShowAlertArchived={setShowAlertArchived}
                        showAlertArchived={showAlertArchived}
                        content={
                            <>
                                <TabPane
                                    key={'1'}
                                    tab={<TabBadge
                                        text={'Aktiva fordon'}
                                        overflowCount={9999999}
                                        badgeColor={'#3f3f3f'}
                                        count={activeData?.length}
                                    />}
                                >
                                    {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                    <Table
                                        dataSource={activeData}
                                        columns={tableColumnsWithTooltip(columns)}
                                        ellipsis={true}
                                        scroll={{x: 1000}}
                                        {...tableProps}
                                        tableLayout={"fixed"}
                                    />
                                </TabPane>
                                <TabPane
                                    key={'2'}
                                    tab={<TabBadge
                                        text={'Arkiverade fordon'}
                                        overflowCount={9999999}
                                        badgeColor={'#3f3f3f'}
                                        showTooltip={false}
                                        count={archivedData?.length}
                                    />}
                                >
                                    {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                    <ConfigProvider
                                        renderEmpty={() => customizeRenderEmpty("Du har inga arkiverade fordon")}>
                                        <Table
                                            dataSource={archivedData}
                                            columns={tableColumnsWithTooltip(columnsArchived)}
                                            scroll={{x: 1000}}
                                            rowClassName="editable-row"
                                            {...tableProps}
                                        />
                                    </ConfigProvider>
                                </TabPane>

                            </>
                        }
                    />

                    <div style={{width: "100%", textAlign: "right"}}>
                        <Button type={"primary"} onClick={() => onBulkUpdateBU()} icon={<CarOutlined/>}>
                            Hämta Biluppgifter för fordon med SE flagga utan historik
                        </Button>
                    </div>
                </Spin>
            )}
        </Form>
    );

};

import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { modulesByLimitedVisibility } from "../../constants/Modules";
import { Alert } from "antd";


import AlarmEditorPage from "../../pages/alarms/AlarmEditorPage"
import AlarmReportPage from "../../pages/alarms/AlarmReportPage"
import AlarmSafetyPage from "../../pages/alarms/AlarmSafetyPage"
import AlarmsEventsPage from "../../pages/alarms/AlarmsEventsPage"
import AlarmsFunctionsPage from "../../pages/alarms/AlarmsFunctionsPage"
import AlarmsQualityPage from "../../pages/alarms/AlarmsQualityPage"
import AlarmsZonesPage from "../../pages/alarms/AlarmsZonesPage"
import EcoScorePage from "../../pages/EcoScorePage"
import GeoAlarmDetailsPage from "../../pages/GeoAlarmDetailsPage"
import GuidesPage from "../../pages/GuidesPage"
import JourneyPage from "../../pages/JourneyPage"
import MapPage from "../../pages/MapPage"
import MyVehiclePage from "../../pages/MyVehiclePage"
import OrganizationDevicesPage from "../../pages/OrganizationDevicesPage"
import ReportSchedulePage from "../../pages/ReportSchedulePage"
import SalaryReportPage from "../../pages/SalaryReportPage"

import SuperAccountPage from "../../pages/super-pages/SuperAccountPage"
import SuperDevicePage from "../../pages/super-pages/SuperDevicePage"
import SuperOrganizationPage
    from "../../pages/super-pages/SuperOrganizationPage"
import SuperUserPage from "../../pages/super-pages/SuperUserPage"
import SuperVehiclesPage from "../../pages/super-pages/SuperVehiclesPage"
import SuperTrackersListPage from "../../pages/super-pages/SuperTrackersListPage"
import SuperTriptaxMainPage
    from "../../pages/super-pages/triptax-analyzer/SuperTriptaxMainPage"
import SupportPage from "../../pages/SupportPage"
import TripPurposePage from "../../pages/TripPurposePage"
import TripSchedulePage from "../../pages/TripSchedulePage"
import UserListPage from "../../pages/UserListPage"
import VehicleListPage from "../../pages/VehicleListPage"
import ZonesPage from "../../pages/ZonesPage"
import TrackersListPage from "../../pages/TrackersListPage";


export const modules = {
    //journey: "Körjournal",
    //map: "Karta",
};

const RouteComponents = {
    journey: JourneyPage,
    map: MapPage,
    // alarm views
    alarmEditor: AlarmEditorPage,
    alarmsEvents: AlarmsEventsPage,
    alarmsFunctions: AlarmsFunctionsPage,
    alarmsQuality: AlarmsQualityPage,
    alarmsReport: AlarmReportPage,
    alarmsSafety: AlarmSafetyPage,
    alarmsZones: AlarmsZonesPage,
    // normal views
    devices: OrganizationDevicesPage,
    ecoScore: EcoScorePage,
    geoLarmDetails: GeoAlarmDetailsPage,
    guides: GuidesPage,
    myVehicle: MyVehiclePage,
    purpose: TripPurposePage,
    salaryReports: SalaryReportPage,
    schedule: TripSchedulePage,
    scheduling: ReportSchedulePage,
    support: SupportPage,
    userroles: UserListPage,
    vehicles: VehicleListPage,
    trackersList: TrackersListPage,
    zones: ZonesPage,
    // super admin pages
    organizations: SuperOrganizationPage,
    superdevices: SuperDevicePage,
    superVehicles: SuperVehiclesPage,
    superTrackersList: SuperTrackersListPage,
    "triptax-analyzer": SuperTriptaxMainPage,
    account: SuperAccountPage,
    users: SuperUserPage,
}


const autoPath = [];

Object.entries(modules).map(([l, v]) => autoPath.push(l));
// Object.entries(modulesByLimitedVisibility).map(([l, v]) => autoPath.push(l));
Object.entries(modulesByLimitedVisibility).map(([l, v]) => {});

export const AppViews = () => {
    //noinspection ConstantConditionalExpressionJS
    return (
        <Suspense fallback={<Loading cover="content" />}>
            {false ? (
                <Alert
                    message="Vi håller just nu på att uppgradera våra system.
            Våra tekniker arbetar på att lösa situationen så snabbt som möjligt, men under tiden kan det bli fördröjningar i körjournalen."
                    type="error"
                    closable
                    banner
                    showIcon={false}
                />
            ) : null}
            <Switch>
                {autoPath.map((p) => (
                    <Route path={`${APP_PREFIX_PATH}/${p}`} component={lazy(() => import(`./${p}`))} key={p} />
                ))}

                {Object.entries(RouteComponents).map( ([p, c]) => (
                    <Route path={`${APP_PREFIX_PATH}/${p}`} component={c} key={p} />
                ))}


                <Route path={`${APP_PREFIX_PATH}/error-2`} component={lazy(() => import(`../auth-views/errors/error-page-2`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/journey`} />
            </Switch>
        </Suspense>
    );
};

export default React.memo(AppViews);

import React, {useEffect, createContext, useCallback, useState} from "react";
import {dataApi} from "services/api/api";
import fetch from "../auth/FetchInterceptor"
import {API_BASE_URL} from "../configs/AppConfig"
import {getErrorMessage} from "../constants/httpErrorMessages"


function createNewDevice(payload) {
    console.log("SuperAdminSagas.js ", "createNewDevice ", JSON.stringify(payload));
    let qs = payload?.specifiedTimeOfDay ? "?specifiedTimeOfDay=" + payload?.specifiedTimeOfDay : "";
    return fetch({
        url: `${API_BASE_URL}/admin/devices` + qs,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        data: payload,
    });
}
function fetchOrgs() {
    return fetch({
        url: `${API_BASE_URL}/admin/organization`,
        method: "GET",
    });
}
function sdFetchVehiclesByOrg(orgId) {
    return fetch({
        url: `${API_BASE_URL}/vehicles`,
        method: "GET",
        headers: {
            "X-Trackson-Organization": orgId,
        },
    });
}

function sdFetchUsersByOrg(orgId) {
    return fetch({
        url: `${API_BASE_URL}/user`,
        method: "GET",
        headers: {
            "X-Trackson-Organization": orgId,
        },
    });
}

/*
function vehicleCpuReset(orgId, vehicleId) {
    return fetch({
        url: `${API_BASE_URL}/vehicles/${vehicleId}/cpureset`,
        method: "POST",
        headers: {
            "X-Trackson-Organization": orgId,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        // data: payload,
    });
}
*/



async function asyncRequestConnectDevice({
                                                orgNew,
                                                vehicleNew,
                                                driverNew,
                                                dataOrg,
                                                dataVehicle,
                                                dataDriver,
                                                userEmailInvite,
                                                selectedDeviceId,
                                            }) {
    const isNewOrg = orgNew;
    const isNewVehicle = vehicleNew;
    const isNewUser = driverNew;

    const orgData = dataOrg;
    const vehicleData = dataVehicle;
    const userData = dataDriver;

    const isInviteUserByMail = userEmailInvite;

    let useOrg;
    let useVehicle = null;
    let useUser = null;

    if (isNewOrg) {
        /*  NEW ORG POST */
        useOrg = await dataApi.postOrganization(orgData);
    } else {
        /* EXISTING ORG  */
        useOrg = {
            organizationId: orgData.organizationId,
        };
    }

    if (vehicleData !== null) {
        if (isNewVehicle) {
            /* NEW VEHICLE  */
            vehicleData.organizationId = useOrg?.organizationId;
            const newVehicle = await dataApi.postSuperVehicle(vehicleData, useOrg.organizationId);

            useVehicle = {
                vehicleId: newVehicle.id,
            };
        } else {
            /* EXISTING VEHICLE  */
            useVehicle = {
                vehicleId: vehicleData.vehicleId,
            };
        }

        if (userData !== null) {
            if (isNewUser) {
                /*  NEW USER/DRIVER POST */
                Object.assign(userData, {
                    permittedVehicles: [{ id: useVehicle.vehicleId }],
                    defaultVehicles: [useVehicle.vehicleId],
                })

                userData.organizationId = useOrg?.organizationId
                userData.roles = ["DRIVER"]
                useUser = await dataApi.postNewUser(userData, useOrg.organizationId)
            } else {
                /*  EXISTING USER/DRIVER */
                useUser = userData

                const vehicleUpdateData = {
                    permittedDrivers: [
                        {
                            id: useUser.userId,
                            displayName: useUser.displayName ? useUser.displayName : "",
                        },
                    ],
                }

                await dataApi.patchVehicle(useVehicle.vehicleId, vehicleUpdateData)
            }

            /*  Put new user(userid) to vehicle */
            const newDriverData = { userId: useUser.userId }
            await dataApi.putCheckCurrentDriver(useVehicle.vehicleId, newDriverData)
        }
    }

    let deviceUpdateData

    if (useVehicle !== null) {
        deviceUpdateData = {
            organizationId: useOrg.organizationId,
            installedVehicle: useVehicle.vehicleId,
            physicalLocation: "Somewhere",
        }
    } else {
        deviceUpdateData = {
            organizationId: useOrg.organizationId,
            physicalLocation: "Somewhere",
        }
    }

    await dataApi.patchAdminDevices(selectedDeviceId, deviceUpdateData)

    return dataApi.getDeviceById(selectedDeviceId)
}


const SuperAdminDevicesContext = createContext({
    requestUpdateDeviceById: null,
    requestFetchVehicles: null
})

const initDevices = {
    LOADING: false,
    LOADED: false,
    RESULT: [],
    RESULT_VEHICLES_BY_ORG: null,
    RESULT_USERS_BY_ORG: null,
    MESSAGE: 'SUPERDEVICE_REQ_DEFAULT',
    MESSAGE_NEW_DEVICE: null,
    MESSAGE_VEHICLES_BY_ORG: '',
    MESSAGE_USERS_BY_ORG: '',
    // MESSAGE_DEVICE_CONNECT: null,
    MESSAGE_DEVICE_CONNECT: '',
    MESSAGE_DEVICE_UPDATE: null,

    ERROR: undefined
}

const initOrgs = {
    LOADING: false,
    LOADED: false,
    RESULT: [],
    MESSAGE_NEW_ORG: '',
    MESSAGE: ''
}

const initVehicles = {
    LOADING: false,
    LOADED: false,
    RESULT: null,
    RESULT_BY_ID: null,
    MESSAGE: null,
    MESSAGE_POST: 'VEHICLE_REQUEST_REQ_POST_DEFAULT',
    RESULT_POST: null,
}


const SuperAdminDevicesContextProvider = ({ children }) => {

    const [devices, setDevices] = useState(initDevices)
    const [orgs, setOrgs] = useState(initOrgs)
    const [vehicles, setVehicles] = useState(initVehicles)

    console.log("devices", devices)
    const resetState = useCallback(() => {
        setDevices(initDevices)
        setOrgs(initOrgs)
        setVehicles(initVehicles)
    }, [])

    const requestFetchVehicles = () => {
        // VEHICLES_REQUEST_FETCH
        setVehicles({
            ...vehicles,
            LOADING: true,
            // this LOADED statement wasnt present in previous redux-code but seems appropriate
            LOADED: false,
            MESSAGE: 'VEHICLE_REQ_LOADING'
        })
        return fetch({
            url: `${API_BASE_URL}/vehicles`,
            method: 'get'
        }).then((res) => {
            setVehicles({
                ...vehicles,
                RESULT: res,
                LOADING: false,
                LOADED: true,
                MESSAGE: 'VEHICLE_REQ_SUCCESS'
            })
        }).catch((err) => {
            // err was ignored in previous version too
            setVehicles({
                ...vehicles,
                RESULT: null,
                LOADING: false,
                // this LOADED statement wasnt present in previous redux-code but seems appropriate
                LOADED: false,
                MESSAGE: 'VEHICLE_REQ_FAILED'
            })
        })
    }

    const requestFetchDevices = () => {
        // SUPERDEVICE_REQUEST_FETCH
        // TODO this is definitely wrong because its the action and not the reducer
        // that has been ported
        // TODO implement it anew in a reasonable manner
        setDevices({
            ...devices,
            LOADING: true,
            LOADED: false,
        })

        return fetch({
            url: `${API_BASE_URL}/admin/devices`,
            method: "GET",
        }).then((device) => {
            setDevices({
                ...devices,
                MESSAGE: 'Alla enheter hämtade',
                LOADING: false,
                LOADED: true,
                RESULT: device
            })
        }).catch((err) => {
            setDevices({
                ...devices,
                MESSAGE: 'SUPERDEVICE_REQ_FAILED',
                LOADING: false,
                RESULT: null
            })
        })
    }


    const requestConnectDevice = (args) => {
        // SUPERDEVICE_REQ_DEVICE_CONNECT
        const p = asyncRequestConnectDevice(args)
        console.debug("MODAL CONNECT PROMISE", p)
        return p.then((newDeviceData) => {
            console.log("requestConnectDevice", newDeviceData)
            setDevices({
                ...devices,
                RESULT: devices.RESULT.map((device) => {
                    if(device.deviceId === newDeviceData.deviceId)
                        return {
                            ...device,
                            ...newDeviceData
                        }
                    return device
                }),
                MESSAGE_DEVICE_CONNECT: {
                    message: 'Koppling lyckades!',
                    type: 'SUCCESS'
                },
            })
        }).catch((error) => {
            let typeMessage = getErrorMessage(error.response.data)
            console.log(typeMessage)
            //noinspection JSCheckFunctionSignatures
            setDevices({
                ...devices,
                ERROR: 'Koppling misslyckades!'
            })
        })
    }

    const requestVehicleCpuReset = ({
                                                    organizationId,
                                                    selectedDeviceId
                                                }) => {
        // SUPERDEVICE_DEVICE_VEHICLE_CPU_RESET
        return dataApi.vehicleCpuReset(organizationId, selectedDeviceId).then((data) => {
            console.log('cpu reset data onsuccess:', data)
            //noinspection JSCheckFunctionSignatures
            setDevices({
                ...devices,
                MESSAGE_DEVICE_UPDATE: {
                    message: 'Lyckad omstart av enheten',
                    type: 'SUCCESS'
                }
            })
        }).catch((err) => {
            let typeMessage = getErrorMessage(err.response.data);
            console.log(typeMessage);
            //noinspection JSCheckFunctionSignatures
            setDevices({
                ...devices,
                MESSAGE_DEVICE_UPDATE: {
                    message: typeMessage,
                    type: 'ERROR'
                }
            })
        })
    }

    const requestUpdateDeviceById = ({
                                                     selectedDeviceId,
                                                     deviceUpdateData
                                                 }) => {
        // SUPERDEVICE_UPDATE_DEVICE
        return dataApi.patchAdminDevices(selectedDeviceId, deviceUpdateData).then(() => {
            // TODO make sure this works instead of the previous extra fetch
            const pre = devices
            const post = {
                ...devices,
                RESULT: devices.RESULT.map((device) => {
                    if(device.deviceId === selectedDeviceId)
                        return {
                            ...device,
                            ...deviceUpdateData
                        }
                    return device
                })
            }
            setDevices(post)
        })
        /* TODO print this error when its appropriate */
        .catch((err) => {
            let typeMessage = getErrorMessage(err.response.data);
            console.log(typeMessage);
            //noinspection JSCheckFunctionSignatures
            setDevices({
                ...devices,
                MESSAGE_DEVICE_UPDATE: {
                    message: typeMessage,
                    type: 'ERROR'
                }
            })
        })
    }

    const requestVehiclesAndUsersByOrgId = ({organizationId}) => {
        // SUPERDEVICE_REQUEST_VEHICLES_USER_BY_ORG_ID
        const vehiclesP = sdFetchVehiclesByOrg(organizationId)
        const usersP = sdFetchUsersByOrg(organizationId)
        return Promise.all([vehiclesP, usersP]).then(([vehicles, users]) => {
            setDevices({
                ...devices,
                RESULT_VEHICLES_BY_ORG: vehicles,
                RESULT_USERS_BY_ORG: users
            })
        }).catch((err) => {
            setDevices({
                ...devices,
                MESSAGE: "FAILED MESSAGE"
            })
        })
    }

    const requestCreateNewDevice = (payload) => {
        // SUPERDEVICE_CREATE_NEW_DEVICE
        return createNewDevice(payload).then((deviceId) => {
            dataApi.getDeviceById(deviceId).then((newDeviceData) => {
                //noinspection JSCheckFunctionSignatures
                setDevices({
                    ...devices,
                    LOADING: false,
                    LOADED: true,
                    MESSAGE_NEW_DEVICE: {
                        message: 'Enhet tillagd!',
                        type: 'SUCCESS'
                    },
                    RESULT: [...devices.RESULT, newDeviceData]
                })
            })
        }).catch((err) => {
            //noinspection JSCheckFunctionSignatures
            setDevices({
                // ...devices,
                LOADING: false,
                LOADED: false,
                ERROR: getErrorMessage(err.response.data)
            })
        })
    }

    const requestFetchOrganizations = () => {
        // SUPERDEVICE_REQUEST_ORG_FETCH
        setDevices({
            ...devices,
            LOADING: true,
            LOADED: false
        })

        return fetchOrgs().then((newOrgs) => {
            setOrgs({...orgs,
                LOADING: false,
                LOADED: true,
                RESULT: newOrgs
            })
        }).catch((err) => {
            let typeMessage = getErrorMessage(err.response.data);
            setDevices({...devices,
                LOADING: false,
                LOADED: true,
            })
        })
    }

    // Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
    // TODO you get this error when you forget to export a state variable!
    // VERY misleading!

    //noinspection JSXUnresolvedComponent
    return <SuperAdminDevicesContext.Provider
        value={{
            devices,
            orgs,
            vehicles,
            resetState,
            // devices
            requestConnectDevice,
            requestCreateNewDevice,
            requestFetchDevices,
            requestUpdateDeviceById,
            requestVehicleCpuReset,
            requestVehiclesAndUsersByOrgId,
            requestFetchOrganizations,
            // vehicles
            requestFetchVehicles
        }}>
        {children}
    </SuperAdminDevicesContext.Provider>
}

export {SuperAdminDevicesContextProvider, SuperAdminDevicesContext}

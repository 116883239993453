import { Zones } from "components/trackson-components/Zones";
import {Nest} from "../utils/utilities";
const ZonesPage = () => {
    return (
        <Nest wrappers={[]}>
            <Zones />
        </Nest>
    );
};

export default ZonesPage;

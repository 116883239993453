import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined } from "@ant-design/icons";
import * as icons from "@ant-design/icons";
import { Button, Col, Popconfirm, Form, Input, InputNumber, message, Row, Select, Tooltip, Typography } from "antd";
import {DatePicker} from 'antd-v5';

import {
    SuperAdminDevicesContext
} from "../../../contexts/SuperAdminDevicesContext"
import { SingleRowIconText } from "./reusableComponents";
import { dataApi } from "services/api/api";
import { inputValidators } from "./superDeviceHelpers";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_ADD_UNIT_TYPE, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import moment from 'moment-timezone';

const initialStateAdd = {
    hardware: SUPER_DEVICES_ADD_UNIT_TYPE[0],
    imei: null,
    phone: null,
    iccid: null,
    err_iccid: "",
    err_hardware: null,
    err_imei: "Min 14 max 16 tecken",
    err_phone: "",
    timestampStartOfUse: null,
    specifiedTimeOfDay: null,
};

const LOCAL_STATIC_TEXT = {
    CHOOSE_HARDWARRE: "Välj hårdvara",
    INPUT_ICCID: "Ange ICCID",
    INPUT_IMEI: "Ange IMEI",
    INPUT_PHONE_NUMBER: "Ange telefonnr.",
    PLACEHOLDER_IMEI: "Ex. 123456789145",
    PLACEHOLDER_PHONE_NUMBER: "Ex. 46736940",
};

const AddUnit = () => {
    const [form] = Form.useForm();
    const [formValues, setFormData] = useState(initialStateAdd);
    const [timer, setTimer] = useState(500);
    const focusIccid = useRef(null);
    const focusImei = useRef(null);
    const [phoneFill, setPhoneFill] = useState();
    const [iccidValidate, setIccidValidate] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(null);

    const suContext = useContext(SuperAdminDevicesContext)

    const devicePostMessage = suContext.devices.MESSAGE_NEW_DEVICE;
    const deviceConnectMessage = suContext.devices.MESSAGE_DEVICE_CONNECT;

    useEffect(() => {
        if (focusIccid.current) {
            focusIccid.current.iccid.focus();
        }
    }, [focusIccid]);

    useEffect(() => {
        if (formValues.isTracker) {
            setFormData({
                ...formValues,
                iccid: initialStateAdd.iccid,
            });
            focusImei.current.focus();
        }
    }, [formValues.isTracker]);

    useEffect(() => {
        if (!devicePostMessage) return;

        if (devicePostMessage.type === "SUCCESS") {
            message.success(devicePostMessage.message);
        } else if (devicePostMessage.type === "ERROR") {
            message.error(devicePostMessage.message);
        }
    }, [devicePostMessage]);

    const onHardWareClickChange = (value) => {
        setFormData({
            ...formValues,
            hardware: value,
            isTracker: value.includes(["GL530MG"])
        });
    };

    useEffect(() => {
        if (!deviceConnectMessage) return;

        if (deviceConnectMessage.type === "SUCCESS") {
            message.success(deviceConnectMessage.message);
            //TODO: update tabtable on connection success
            // window.location.reload();
        } else if (deviceConnectMessage.type === "ERROR") {
            message.error(deviceConnectMessage.message);
        }
    }, [deviceConnectMessage]);

    const onHandleImeiChange = (value) => {
        /* IMEI: 14-16 SIFFROR,  måste vara unikt i db */
        const val = value.target.value;
        const validate = inputValidators.isValidImei(val); // maxLength 16 via form-input
        const aboveLength = val.length > 7;
        setFormData({
            ...formValues,
            imei: val,
            err_imei: "",
        });

        // if (validate.valid) {
        // setFormData({
        //     ...formValues,
        //     imei: val,
        //     err_imei: "",
        // });
        // } else if (!validate.valid && aboveLength) {
        //     setFormData({
        //         ...formValues,
        //         imei: null,
        //         err_imei: validate.messages,
        //     });
        // } else {
        //     setFormData({
        //         ...formValues,
        //         imei: null,
        //         err_imei: "",
        //     });
        // }
    };

    const onHandleIccidChange = (value) => {
        const val = value.target.value;
        console.log("value: ", val);

        // {/* ICCID: 18-22 SIFFROR,  måste vara unikt i db */}
        //TODO: ADD ICCID VALIDATOR??
        const validate = inputValidators.isValidIccid(val);
        const aboveLength = val.length > 15; //CHECK at 14
        setIccidValidate(true);
        setFormData({
            ...formValues,
            iccid: val,
            err_iccid: "",
        });

        // if (validate.valid) {
        //     setFormData({
        //         ...formValues,
        //         iccid: val,
        //         err_iccid: "",
        //     });
        // } else if (!validate.valid && aboveLength) {
        //     setIccidValidate(false);
        //     setFormData({
        //         ...formValues,
        //         iccid: null,
        //         err_iccid: validate.messages,
        //         phone: null,
        //     });
        // } else {
        //     setIccidValidate(false);
        //     setFormData({
        //         ...formValues,
        //         iccid: null,
        //         err_iccid: "",
        //         phone: null,
        //     });
        // }
    };

    //---------------------------------------------
    /*const iccidValid = () => {
    if (formValues.iccid !== null) {
      const ifIccid = (formValues.iccid.length >= 18)
      const ifIccidNumber = isNaN(formValues.iccid)
    console.log(ifIccid);
    console.log(!ifIccidNumber);

    const checkValues = [ifIccid, !ifIccidNumber];
    return checkValues.every(v => v === true);
    }
  }*/
    useEffect(() => {
        if ((formValues.iccid === null && !formValues.isTracker) || formValues.phone === null || formValues.imei === null) {
            setShowSuccessMessage(false);
        } else {
            setShowSuccessMessage(true);
        }
    }, [formValues]);
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (iccidValidate && formValues.iccid) {
                dataApi
                    .getPhoneFromJasper(formValues.iccid)
                    .then((phone) => {
                        console.log(formValues.iccid);
                        focusImei.current.focus();
                        if (phone) {
                            setShowSuccessMessage(true);
                        }
                        setPhoneFill(phone);
                        forcePhoneChange(phone);
                    })
                    .catch((e) => {
                        console.log(e);
                        // setShowSuccessMessage(false);
                        // setIccidValidate(false);
                    });
            }
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [formValues.iccid, iccidValidate]);

    const forcePhoneChange = (value) => {
        setFormData({
            ...formValues,
            phone: value,
            err_phone: "",
        });
    };

    const onHandlePhoneRequest = () => {
        if (iccidValidate) {
            try {
                dataApi.getPhoneFromJasper(formValues.iccid).then((phoneFill) => {
                    console.log(formValues.iccid);
                    console.log("phone: ", phoneFill);
                    setPhoneFill(phoneFill);
                    if (phoneFill) {
                        setShowSuccessMessage(true);
                    }
                });
                setFormData({
                    ...formValues,
                    phone: phoneFill,
                });
            } catch (error) {
                setShowSuccessMessage(false);
                if (error.response && error.response.status === 404) {
                    message.error("MISSING_ICCID");
                } else {
                    message.error("UNEXPECTED ERROR");
                }
            }
        } else {
            message.error("ICCID INTE KORREKT!");
            setFormData({
                ...formValues,
                phone: null,
            });
        }

        //console.log("form values: ",formValues);
        //console.log("phone fill: ",phoneFill);
    };

    const onHandlePhoneChange = (value) => {
        const val = value.target.value;

        /* Telnr: MINST 14 SIFFROR, måste vara unikt i db */ // minLength 14 siffror via input ... maxLength: 0? måste kunna vara max något?
        const validate = inputValidators.isValidPhone(val);

        const aboveLength = val.length > 7; //CHECK AT 6
        setFormData({
            ...formValues,
            phone: val,
            err_phone: "",
        });

        // if (validate.valid) {
        //     setFormData({
        //         ...formValues,
        //         phone: val,
        //         err_phone: "",
        //     });
        // } else if (!validate.valid && aboveLength) {
        //     setFormData({
        //         ...formValues,
        //         phone: val,
        //         err_phone: validate.messages,
        //     });
        // } else {
        //     setFormData({
        //         ...formValues,
        //         phone: val,
        //         err_phone: "",
        //     });
        // }
    };

    const onHandleTimestampStartOfUseChange = (value) => {
        setFormData({
            ...formValues,
            timestampStartOfUse: value,
        });
    };

    const onHandleSpecifiedTimeOfDay = (value, timeString) => {
        const utcTime = value?.toISOString()
        const getDate = new Date(utcTime);
        const hours = getDate.getUTCHours().toString().padStart(2, '0');
        const minutes = getDate.getUTCMinutes().toString().padStart(2, '0');
        const timeHHMM = hours + minutes;

        setFormData({
            ...formValues,
            specifiedTimeOfDay: timeHHMM,
        });
    };

    const onFinish = (values) => {
        let checkValues = [values.hardware !== null, values.imei != null, values.phone != null];
        let checkAllTrue = checkValues.every((v) => v === true);

        console.log(formValues, values);
        if (checkAllTrue) {
            suContext.requestCreateNewDevice({
                iccid: formValues.iccid,
                imei: formValues.imei,
                phone: formValues.phone,
                deviceType: `${formValues.hardware}`,
                organizationId: null,
                timestampStartOfUse: formValues.timestampStartOfUse,
                specifiedTimeOfDay: formValues.specifiedTimeOfDay,
            })
            form.resetFields();
            setShowSuccessMessage(null);
            setFormData(initialStateAdd);
        } else {
            message.info("Ett eller flera fält saknar korrekt värde!");
            console.log("Formvals", formValues);
        }

        //form.submit();
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <React.Fragment>
            <Form form={form} onFinish={onFinish} onFinishFailed={(v) => console.log(v)} style={{ width: "100%", margin: "auto" }} layout={{ ...formLayout }} name="add_unit_form">
                <Row gutter={[15, 20]} align={"top"}>
                    <Col span={[3]}>
                        <Typography.Text>{STATIC_TEXT.HARDWARE}</Typography.Text>
                        <HardwareFormSelector onHardWareClickChange={onHardWareClickChange} />
                    </Col>

                    <Col span={[4]}>
                        <Typography.Text>{STATIC_TEXT.ICCID}</Typography.Text>
                        <InputIccidValidator formValues={formValues} onHandleIccidChange={onHandleIccidChange} hasFocus={focusIccid} iccidValidate={showSuccessMessage} />
                    </Col>

                    <Col span={[4]}>
                        <Typography.Text>{STATIC_TEXT.IMEI}</Typography.Text>
                        <InputImeiValidator formValues={formValues} onHandleImeiChange={onHandleImeiChange} hasFocus={focusImei} />
                    </Col>

                    <Col span={[4]}>
                        <Typography.Text>{formValues.isTracker ? "SIM-ID" : STATIC_TEXT.PHONE_NUMBER}</Typography.Text>
                        <InputPhoneValidator phoneFill={phoneFill} formValues={formValues} onHandlePhoneChange={onHandlePhoneChange} onHandlePhoneRequest={onHandlePhoneRequest} />
                    </Col>

                    <Col span={[3]}>
                        <Typography.Text>{STATIC_TEXT.START_OF_USE}</Typography.Text>
                        <InputTimestampStartOfUse formValues={formValues} onHandleTimestampStartOfUseChange={onHandleTimestampStartOfUseChange} />
                    </Col>

                    <Col span={[3]}>
                        <Typography.Text>Tidpunkt för uppdatering</Typography.Text>
                        <InputSpecifiedTimeOfDay formValues={formValues} onHandleSpecifiedTimeOfDay={onHandleSpecifiedTimeOfDay} />
                    </Col>

                    <Col span={[3]}>
                        <Typography.Text style={{ opacity: 0 }}>{"REMOVE THIS"}</Typography.Text>
                        <Form.Item>
                            {formValues.isTracker ? (
                                <Popconfirm
                                    title="Slå på enheten innan du trycker på OK"
                                    onConfirm={() => {
                                        onFinish(formValues);
                                    }}
                                    okText="OK"
                                    cancelText="Avbryt"
                                    disabled={!showSuccessMessage}
                                >
                                    <Button
                                        style={{ width: 150 }}
                                        type="primary"
                                        icon={<icons.AppstoreAddOutlined />}
                                        disabled={!showSuccessMessage}
                                    >
                                        {STATIC_TEXT.BTN_ADD_UNIT}
                                    </Button>
                                </Popconfirm>
                            ) : (
                                <Button
                                    style={{ width: 150 }}
                                    type="primary"
                                    icon={<icons.AppstoreAddOutlined />}
                                    onClick={() => {
                                        onFinish(formValues);
                                    }}
                                    disabled={!showSuccessMessage}
                                >
                                    {STATIC_TEXT.BTN_ADD_UNIT}
                                </Button>
                            )}
                        </Form.Item>                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

const HardwareFormSelector = ({ onHardWareClickChange }) => {
    return (
        <Form.Item
            //name={SUPER_DEVICES_STATIC_TEXT.HARDWARE}
            rules={[
                {
                    required: true,
                    message: LOCAL_STATIC_TEXT.CHOOSE_HARDWARRE,
                },
            ]}
        >
            <Select onChange={onHardWareClickChange} style={{ width: "100%" }} defaultValue={SUPER_DEVICES_ADD_UNIT_TYPE[0]} placeholder={LOCAL_STATIC_TEXT.CHOOSE_HARDWARRE}>
                {SUPER_DEVICES_ADD_UNIT_TYPE.map((type) => {
                    return (
                        <Select.Option key={`opt_${type}`} value={type}>
                            {type}
                        </Select.Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
};

const InputIccidValidator = ({ formValues, onHandleIccidChange, hasFocus, iccidValidate }) => {
    const disabled = formValues.isTracker
    return (
        <Form.Item
            disabled={disabled}
            name={STATIC_TEXT.ICCID}
            // validateStatus={formValues.iccid !== null && iccidValidate !== null ? (iccidValidate ? "success" : "error") : null}
            validateStatus={null}
            valuePropName={formValues.iccid}
            // hasFeedback={formValues.iccid !== null && iccidValidate !== null}
            help={formValues.err_iccid}
            //TODO use a state if tab has been swapped? or keep only on first entry
            rules={[
                {
                    required: formValues.isTracker,
                    message: LOCAL_STATIC_TEXT.INPUT_ICCID,
                    type: "number",
                    //min: 18
                },
            ]}
        >
            <Input
                disabled={disabled}
                allowClear
                style={{ width: "100%" }}
                autoFocus={hasFocus}
                onPressEnter={() => {
                    return null;
                }}
                // minLength={18}
                // maxLength={22}
                onChange={(value) => onHandleIccidChange(value)}
                placeholder={LOCAL_STATIC_TEXT.INPUT_ICCID}
                value={formValues.iccid}
            />
        </Form.Item>
    );
};

const InputImeiValidator = ({ formValues, onHandleImeiChange, hasFocus }) => {
    return (
        <Form.Item
            name={STATIC_TEXT.IMEI}
            rules={[
                {
                    required: true,
                    message: LOCAL_STATIC_TEXT.INPUT_IMEI,
                },
                { min: 14, message: 'Min 14 tecken' },
                { max: 16, message: 'Max 16 tecken' }]}
        >
            <Input
                allowClear
                inputMode={"tel"}
                ref={hasFocus}
                //autoFocus={hasFocus}
                onPressEnter={(e) => {
                    return null;
                }}
                onChange={(value) => onHandleImeiChange(value)}
                placeholder={LOCAL_STATIC_TEXT.INPUT_IMEI}
            />
        </Form.Item>    );
};

//const InputPhoneValidator = ({ formValues, onHandlePhoneChange }) => {

const InputPhoneValidator = ({ formValues, onHandlePhoneChange, onHandlePhoneRequest }) => {
    const checkStatus = formValues.phone?.length === 9 ? "success" : "warning"
    return (
        <Form.Item
            name={STATIC_TEXT.PHONE_NUMBER}
            inputMode={"numeric"}
            valuePropName={formValues.phone}
            validateStatus={formValues?.isTracker ? checkStatus : ""}
            help={formValues?.isTracker && "9 tecken"}
        >
            <Input
                allowClear
                minLength={14}
                inputMode={"tel"}
                onPressEnter={(e) => {
                    return null;
                }}
                suffix={
                    <Tooltip title="Hämta Telefonnummer">
                        <a style={{ width: "100%", height: "100%" }} onClick={onHandlePhoneRequest}>
                            <DownloadOutlined style={{ fontSize: "18px" }} />
                        </a>
                    </Tooltip>
                }
                //  maxLength={25}
                style={{ width: "100%" }}
                onChange={(value) => onHandlePhoneChange(value)}
                placeholder={LOCAL_STATIC_TEXT.INPUT_PHONE_NUMBER}
                value={formValues.phone}
            />
        </Form.Item>
    );
};

function InputTimestampStartOfUse({ onHandleTimestampStartOfUseChange }) {
    return (
        <Form.Item
            name={SUPER_DEVICES_KEYS_AND_INDEXES.timestampStartOfUse}
            rules={[
                {
                    required: false,
                },
            ]}
        >
            <DatePicker
                style={{width: "100%"}}
                onChange={(date) => onHandleTimestampStartOfUseChange(date?.unix())} />
        </Form.Item>
    );
}


function InputSpecifiedTimeOfDay({ onHandleSpecifiedTimeOfDay, formValues }) {
    const disabled = !formValues.isTracker
    return (
        <Form.Item
            disabled={disabled}
            name="specifiedTimeOfDay"
            rules={[
                {
                    required: false,
                },
            ]}
        >
            <DatePicker.TimePicker
                changeOnBlur
                disabled={disabled}
                style={{width: "100%"}}
                format={'HH:mm'}
                onChange={(value, timeString) => onHandleSpecifiedTimeOfDay(value, timeString)}/>
        </Form.Item>
    );
}

export default AddUnit;

import * as icons from "@ant-design/icons";
import { ORGANIZATION_ID, USER_ORG_ID } from "../../../services/redux/constants/authConstants";
import { AuthorizationContext } from "../../../services/contexts/contexts";
import { useContext } from "react";
import {SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";

export function LOG_INDEX_JS(text) {
    const ACTIVITY = "SuperDevices, triptax-analyzer: ";
}

export function LOGG_ADD_UNIT_JSX(text) {
    const ACTIVITY = "SuperDevices, AddUnit.jsx: ";
}

export function applySearchFilter(preFilteredData, searchedText, selectedOrganizations) {
    const searchLower = searchedText?.toLowerCase();
    let filteredList = preFilteredData;

    if (selectedOrganizations?.length > 0) {
        filteredList = filteredList?.filter((i) => selectedOrganizations.includes(i?.organizationId?.organizationId));
    }

    if (searchLower.length > 1) {
        filteredList = filteredList?.filter((device) => JSON.stringify(device).toLowerCase().includes(searchLower));
    }

    return filteredList;
}

export function findOrgNameById(id, listOrgs) {
    let filteredOrg = listOrgs?.find((org) => org.organizationId === id);
    return filteredOrg ? filteredOrg : null;
}
export function findVehicleById(vehicleId, listVehicles) {
    let filteredVehicleData = listVehicles?.find((vehicle) => vehicle.id === vehicleId);
    return filteredVehicleData ? filteredVehicleData : null;
}

export function filterWarnings(deviceData) {
    //device.organizationId === null
    let filteredDeviceData = deviceData ? deviceData : [];
    return filteredDeviceData ? filteredDeviceData : null;
}

export function filterInStock(deviceData) {
    //device.organizationId === null
    let filteredDeviceData = deviceData?.filter((device) => device.organizationId === null);
    return filteredDeviceData ? filteredDeviceData : null;
}

export function filterOnTheWay(deviceData) {
    //device.organizationId  !== null && device.status === null
    let filteredDeviceData = deviceData?.filter((device) => device.organizationId !== null && device.status === null && !device.retired);
    return filteredDeviceData ? filteredDeviceData : null;
}

export function filterInDrive(deviceData) {
    //device.organizationId  !== null && device.status !== null
    let filteredDeviceData = deviceData?.filter((device) => device.organizationId !== null && device.status !== null && !device.retired);
    return filteredDeviceData ? filteredDeviceData : null;
}

export function filterRetired(deviceData) {
    //device.organizationId  !== null && device.status !== null
    let filteredDeviceData = deviceData?.filter((device) => device.retired);
    return filteredDeviceData ? filteredDeviceData : null;
}

export function filterNonConnectedVehicles(vehicles, organizationId) {
    return vehicles.filter((vehicle) => !vehicle.state?.imei && vehicle.organizationId === organizationId);
}

export function filterNonConnectedUsers(users) {
    return users.filter((user) => user.currentVehicle?.length === 0);
}

export const getOrgTreeData = (orgData) => {
    const arr = [];

    orgData.forEach((element) => {
        //console.log("org", element);

        const children = element.children ? { children: { title: element.children.title, value: element.children.organizationId } } : null;

        const item = {
            title: element.fullName,
            value: element.organizationId,
            children,
        };

        arr.push(item);
    });

    return arr;
};

export const getOrgTreeDataWithChildren = (orgData, organizationId, thisIsSettingPanel) => {
    const arr = [];

    const userOrganizationId = localStorage.getItem(USER_ORG_ID);

    const getParentOrganization = orgData?.find((org) => org?.organizationId === parseInt(userOrganizationId));

    orgData = orgData?.map((i) => {
        console.log(i)
        return {
            ...i,
            title: i.fullName,
            key: i.organizationId,
            value: i.organizationId,
            organizationNumber: i.organizationNumber,
        };
    });

    for (let i = 0; i < 15; i++) {
        orgData = orgData?.map((i) => {
            return {
                ...i,
                key: i.organizationId,
                children: orgData.filter((children) => children.parentOrganization === i.organizationId),
            };
        });
    }

    let parentOrganizationz = orgData?.filter((org) => org?.parentOrganization === null);
    const childOrganizationz = orgData?.filter((org) => org?.parentOrganization !== null);

    let localStorageGetOrganizationId = parseInt(localStorage.getItem(ORGANIZATION_ID));

    if (localStorageGetOrganizationId && !thisIsSettingPanel) {
        parentOrganizationz = orgData?.filter((org) => org.organizationId === localStorageGetOrganizationId);
    }

    if (parentOrganizationz?.length < 1) {
        parentOrganizationz = orgData?.filter((org) => org.organizationId === parseInt(userOrganizationId));
    }

    // console.log("PARENTS:::::", parentOrganizationz);
    // console.log("CHILDREN:::::", childOrganizationz);

    parentOrganizationz?.forEach((element) => {
        const filteredChildItems = orgData?.filter((children) => children.parentOrganization === element.organizationId);

        // console.log("PARENT:::::", element.organizationId);
        // console.log("PARENT CHILDREN:::::", filteredChildItems);

        const children = filteredChildItems.map((childItem) => ({
            title: childItem.fullName,
            value: childItem.organizationId,
            key: childItem.organizationId,
            children: childOrganizationz.filter((o) => o.parentOrganization === childItem.organizationId),
            organizationNumber: element.organizationNumber
        }));

        // console.log('CHILDREN OF ELEMENT::MAPPED::', children)

        const item = {
            title: element.fullName,
            value: element.organizationId,
            key: element.organizationId,
            organizationNumber: element.organizationNumber,
            children,
        };

        arr.push(item);
    });

    return arr;
};

function millisToLocalDateString(millis) {
    let dateStr = "";

    try {
        const date = new Date(millis);
        dateStr = date.toISOString("se-SE").split("T")[0];
    } catch (error) {
        console.log("Conversion error millis, ", error);
    }

    return dateStr;
}

function phoneNumber(number) {
    //TODO: FORMAT PHONE NUMBER?
    return `${number}`;
}

function determineBatteryColor(batteryLevel) {
    var level = batteryLevel;

    if (level < 20) {
        return SD_BASE_COLORS.RED;
    } else if (level >= 20 && level < 60) {
        return SD_BASE_COLORS.YELLOW;
    } else if (level >= 60 && level < 85) {
        return SD_BASE_COLORS.BLUE;
    } else if (level >= 85) {
        return SD_BASE_COLORS.GREEN;
    } else {
        return null;
    }
}

function determineObdColorAndIcon(obd) {
    switch (obd) {
        case true:
            return (
                <div>
                    <icons.CheckSquareFilled style={{ color: SD_BASE_COLORS.GREEN }} />
                </div>
            );
        default:
            return (
                <div>
                    <icons.CloseSquareFilled style={{ color: SD_BASE_COLORS.RED }} />
                </div>
            );
    }
}

function isValidIccid(iccid) {
    const minLength = iccid.length >= 18; // Required min length not reached
    const messageLength = minLength ? "" : "Minst 18 siffror krävs!";

    const checkValues = [minLength];

    let checkAllTrue = checkValues.every((v) => v === true);
    return {
        valid: checkAllTrue,
        messages: [messageLength],
    };
}

function isValidImei(imei) {
    const minLength = imei === Number;
    //  const isNumbersOnly = Number.isInteger(imei)

    const messageLength = minLength ? "" : "Endast siffror";

    const checkValues = [minLength];

    let checkAllTrue = checkValues.every((v) => v === true);

    return {
        valid: checkAllTrue,
        messages: [messageLength],
    };
}

function isValidPhone(phone) {
    const minLength = phone.length >= 13; // Required min length not reached
    //const isNumbersOnly = Number.isInteger(imei)

    const messageLength = minLength ? "" : "Minst 14 siffror krävs!";

    const checkValues = [minLength];

    let checkAllTrue = checkValues.every((v) => v === true);
    return {
        valid: checkAllTrue,
        messages: [messageLength],
    };
}

function generateUniqueIccid(arrayIccids) {
    let isInArray = true;
    let finalIccid = "";
    do {
        let newIccid = generateIccid();
        isInArray = [arrayIccids].filter((i) => i === newIccid);
        finalIccid = newIccid;
    } while (isInArray === true);

    return finalIccid;
}

function generateIccid() {
    const minLength = 18;
    const maxLength = 22;

    let rndLength = Math.floor(Math.random() * (maxLength - minLength + 1) + minLength);
    let text = "";
    let num = 0;

    do {
        let numberMinMax = Math.floor(Math.random() * 9);
        text += `${numberMinMax}`;
        num += 1;
    } while (num !== rndLength);

    return text;
}

function createNewOrgObject(fullName, adminUserEmail, adminUserPassword, organizationNumber, email, phone, invoiceAddress, postalAddress) {
    return {
        fullName,
        adminUserEmail,
        adminUserPassword,
        organizationNumber,
        email,
        phone,
        invoiceAddress,
        postalAddress,
    };
}

function createExistingOrgObject(organizationId) {
    return {
        organizationId,
    };
}

function createNewVehicleObject(category, registry, displayName, style, permittedDrivers, defaultDriver, defaultTripType, benefitType, permitAnyDriver, organizationId, isoCountryCode) {
    return {
        category,
        registry,
        displayName,
        style,
        permittedDrivers,
        defaultDriver,
        defaultTripType,
        benefitType,
        permitAnyDriver,
        organizationId,
        isoCountryCode,
    };
}

function createExistingVehicleObject(vehicleId) {
    return {
        vehicleId,
    };
}

function createNewDriverObject(displayName, displayEmail, roles, sendInvite, organizationId) {
    return {
        displayName,
        displayEmail,
        roles,
        sendInvite,
        organizationId,
    };
}

function createExistingDriverObject(userId) {
    return {
        userId,
    };
}

export const deviceConnectObjectFunctions = { createNewOrgObject, createExistingOrgObject, createNewVehicleObject, createExistingVehicleObject, createNewDriverObject, createExistingDriverObject };
export const formatters = { phoneNumber, millisToDate: millisToLocalDateString };
export const color_calculations = { determineBatteryColor };
export const icon_determine = { determineObdColorAndIcon };
export const inputValidators = { isValidImei, isValidPhone, isValidIccid };
export const iccid_generator = { generateUniqueIccid };

import React, {useState} from 'react';
import {dataApi} from "../../../services/api/api";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {message, Modal, Select} from "antd";
import {MESSAGE_NOTIFICATION, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {showModalForMoveDevice} from "./sharedComponents";

export async function activateVehiclesModals(record, data, setData, editVehicle) {
    try {
        const res = await dataApi.patchActivateVehicle(record?.id);
        const newData = [...data];
        const index = newData?.findIndex(item => record?.id === item.id);
        newData.splice(index, 1, { ...newData[index], ...res });
        setData(newData);

        Modal.success({
            title: 'Fordonet är aktiverat',
            content: `Du har aktiverat fordon ${record?.registry || record?.displayName}. Ändra inställningar i nästa steg.`,
            okText: 'OK',
            cancelText: 'Ångra',
            onOk: () => {
                editVehicle(record);
            },
        });
    } catch (error) {
        console.error(error);
    }
}


export const archiveVehicle = (record, data, setData, knownDevices, showModalForMoveDevice, handleMoveDeviceToOtherVehicle, handleAddNewVehicle) => {
    Modal.confirm({
        title: 'Arkivera fordon',
        icon: <ExclamationCircleOutlined/>,
        content: (
            <div>
                <p>
                    Du kommer att arkivera fordon {record.registry || record.displayName}.
                </p>
                <p><b>
                    Kopplingen mellan fordonet och dess Trackson-enhet kommer att brytas.
                    Enheten kan därefter installeras i ett annat fordon.
                </b></p>
                <p>All data som har registrerats fram tills nu finns kvar.</p>
                <p>Vill du arkivera fordonet?</p>
            </div>
        ),
        onOk() {
            let newData;
            dataApi.patchArchiveVehicle(record?.id)
                .then((res) => {
                    newData = [...data];
                    const index = newData?.findIndex((item) => record?.id === item.id);
                    const item = newData[index];
                    newData.splice(index, 1, {...item, ...res});
                    setData(newData);

                    if (record?.state?.imei) {
                        const device = knownDevices?.find(item => item?.imei === record?.state?.imei);
                        device.installedVehicle = null
                    }

                })
                .then(_ => {
                    if (record?.state?.imei) {
                        let afterArchive = true;
                        showModalForMoveDevice(record, newData, handleMoveDeviceToOtherVehicle, handleAddNewVehicle, afterArchive)
                    }
                });

        },
        onCancel() {
        },
        okText: "Arkivera"
    })
}
import React, {useContext, useEffect, useState} from "react";
import {MapContext} from "services/contexts/MapContext";
import GoogleMapReact from "google-map-react";
import {Alert, Avatar, Button, Col, Descriptions, Divider, Row, Select, Space, Switch, Typography} from "antd";
import * as icons from "@ant-design/icons";
import {darkMapStyle, mapOptions, setLightMapType} from "configs/mapStyle";
import Loading from "components/shared-components/Loading/Loading";
import {OrganizationContext} from "services/contexts/OrganizationsContext";
import {OrganizationsTreeSelect} from "components/trackson-components/shared-components/OrganizationsTreeSelect";
import styles from "./styles";
import {countryFlagApi, getCo2, getFuelsAndConsumptions, lookupStyleMarker, VehicleMarker} from "constants/Modules";
import {LastSeenLink} from "components/trackson-components/shared-components/DatumLink";
import {MAP_TYPE, ORGANIZATION_ID} from "services/redux/constants/authConstants";
import {BOOTSTRAP_URL_KEYS} from "configs/AppConfig";
import {Marker} from "components/shared-components/Marker/Marker";
import {benefitType, STATIC_TEXT, tripTypes} from "../../../lang/translate/sv-SE/sv-SE";
import {VehiclesSelect} from "../shared-components/VehiclesSelect";
import {
    AuthorizationContext,
    RestrictedMapOrganizationsContext,
    SelectDefaultVehicle,
    UserListContext,
    VehiclesComplexListContext
} from "../../../services/contexts/contexts";
import {OdometerModal} from "../shared-components/sharedComponents";
import {getOdometerValue} from "../shared-components/odometer";
import {ModalWithFullList} from "../shared-components/modalShowFullList";
import {dataApi} from "../../../services/api/api";
import {CONSTANTS_TEXT} from "../../../constants/CustomConstant";

const {Title, Text} = Typography;
const {Option} = Select;
const MyVehicle = () => {
    const {updateSelectedVehicleOverviewData} = useContext(MapContext);
    const {organizations} = useContext(OrganizationContext);
    const knownVehicles = useContext(VehiclesComplexListContext);
    const defaultVehicle = useContext(SelectDefaultVehicle.context);
    const users = useContext(UserListContext);
    const currentUser = useContext(AuthorizationContext);

    const [selectedVehicle, setSelectedVehicle] = useState();
    const [vehicles, setVehicles] = useState(knownVehicles);
    const [selectedVehicleOverviewData, setSelectedVehicleOverviewData] = useState();
    const [isPrivateTrip, setIsPrivateTrip] = useState(false);
    const [defaultCenter, setDefaultCenter] = useState([59.334591, 18.06324]);
    const [permittedDrivers, setPermittedDrivers] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [isOdometerEdited, setOdometerEdited] = useState(false);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    const [vehiclesByOrg, setVehiclesByOrg] = useState([]);
    const [mapsInstance, setMapsInstance] = useState();
    const [didFitBoundsTargets, setDidFitBoundsTargets] = useState("");
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [filteredOverviewData, setFilteredOverviewData] = useState();
    const [allowChangeDefaultTripType, setAllowChangeDefaultTripType] = useState(false);
    const refitWheneverVehiclesChange = true;
    const excludedOrgs = useContext(RestrictedMapOrganizationsContext)
    const hideMap = excludedOrgs?.includes(parseInt(localStorage.getItem(ORGANIZATION_ID)));

    useEffect(() => {
        if (knownVehicles)
            setVehicles(knownVehicles)
    }, [knownVehicles])

    useEffect(() => {
        setVehiclesByOrg(vehicles);
        if (!selectedVehicle) {
            const vehicle = vehicles?.find(item => item.id === defaultVehicle)
            setSelectedVehicle(vehicle)
        } else {
            const vehicle = vehicles?.find(item => item.id === selectedVehicle.id)
            setSelectedVehicle(vehicle)
        }
    }, [defaultVehicle, vehicles])

    useEffect(() => {
        if (!selectedVehicle?.id) return
        updateSelectedVehicleOverviewData(selectedVehicle?.id).then(overviewData => setSelectedVehicleOverviewData(overviewData))
        checkPermittedDrivers(selectedVehicle);
        let changeTypeAllowed = false;
        if (selectedVehicle?.permittedDrivers?.length < 2 || currentUser?.roles?.includes("ADMIN")
            || currentUser?.roles?.includes("ROOT")) {
            changeTypeAllowed = true
        }
        setAllowChangeDefaultTripType(changeTypeAllowed)
    }, [selectedVehicle])


    useEffect(() => {
        setIsPrivateTrip(!selectedVehicleOverviewData?.position?.latitude);
    }, [selectedVehicleOverviewData])

    useEffect(() => {
        if (!mapsInstance) {
            return;
        }

        mapsInstance?.maps?.event.trigger(mapsInstance?.map, "resize");
        mapsInstance?.map?.setOptions({
            ...mapOptions,
            styles: localStorage.getItem(MAP_TYPE) === "DARK" ? darkMapStyle : null
        });
        setLightMapType(mapsInstance?.maps, mapsInstance?.map);
    }, [localStorage.getItem(MAP_TYPE), mapsInstance]);
    const createBoundsFromOverviewData = (maps, data) => {
        // This function returns an instance of google.maps.LatLngBounds
        // That instance can then be passed to maps.fitBounds
        // To create this instance, use the positions in data to extend
        // the bounds via new coordinate points.
        console.log("Creating bounds for passing to fitBounds");
        const bounds = new maps.LatLngBounds();
        data.forEach((d) => {
            const lat = d?.position?.latitude;
            const lng = d?.position?.longitude;

            if (lat && lng) {
                // This is valid coordinate
                const newCoordinate = new maps.LatLng(lat, lng);
                bounds.extend(newCoordinate);
            } else {
                // This is an invalid coordinate, don't add it to bounds
            }
        });
        return bounds;
    };
    useEffect(() => {
        let currentFitBoundsTargets;
        if (!mapsInstance) {
            console.error("Cannot set bounds yet: don't have maps instance");
            return;
        }
        if (!filteredOverviewData || filteredOverviewData.length === 0) {
            console.error("Cannot set bounds yet: don't have overviewData, or overviewData is empty");
            return;
        }
        if (refitWheneverVehiclesChange) {
            const currentVehicleIds = [...selectedVehicleIds];
            currentVehicleIds.sort();
            currentFitBoundsTargets = currentVehicleIds.map((x) => "" + x).join(",");
        } else {
            currentFitBoundsTargets = "everything";
        }
        if (currentFitBoundsTargets === didFitBoundsTargets) {
            return;
        }
        const bounds = createBoundsFromOverviewData(mapsInstance.maps, filteredOverviewData);
        mapsInstance.map.fitBounds(bounds);
        setDidFitBoundsTargets(currentFitBoundsTargets);
    }, [mapsInstance, filteredOverviewData, selectedVehicleIds]);

    const checkIfVehicleAvailableOnTheMap = (e) => {
        if (selectedVehicle) {
            setDefaultCenter([selectedVehicle?.position?.latitude, selectedVehicle?.position?.longitude]);
        } else {
            setDefaultCenter([59.334591, 18.06324]);
            setShowMap(false);
            setIsPrivateTrip(false);
        }
    };
    const checkPermittedDrivers = (vehicle) => {
        const drivers = [];
        setPermittedDrivers(vehicle?.permittedDrivers);
    };

    const handleChangedVehicle = (e) => {
        const vehicle = vehiclesByOrg.find((res) => res.id === e);
        if (vehicle) {
            setSelectedVehicle(vehicle);
            checkIfVehicleAvailableOnTheMap(vehicle?.id);
            checkPermittedDrivers(vehicle);
        }
    };
    const handleChangedOrganization = (e) => {
        if (e?.length !== 0) {
            setSelectedOrgs(e);
            const vehiclesByOrgId = vehicles.filter((vehicle) => e.includes(vehicle.organizationId));
            setVehiclesByOrg(vehiclesByOrgId);
        } else {
            setSelectedOrgs([]);
            setVehiclesByOrg(vehicles);
        }
    };
    const vehicleIcon = (marker) => {
        let icon = marker;
        if (marker.constructor === String) {
            icon = lookupStyleMarker[icon];
        }
        return icon;
    };
    const editOdometer = () => {
        setOdometerEdited(true);
    };
    const getFuels = (fue1, fuel2) => {
        const fuels = {fuel1: fue1, fuel2: fuel2};
        return fuels;
    };
    const getConsumtions = (consumption1, consumption2) => {
        const consumptions = {consumption1: consumption1, consumption2: consumption2};
        return consumptions;
    };
    const getCo2s = (co1, co2) => {
        const co2s = {co2GKm1: co1, co2GKm2: co2};
        return co2s;
    };

    const PermittedDriversModal = ({value, record}) => {
        const ifPoolCar = record?.benefitType === "POOL";
        let text = "Förare ej vald";
        if (ifPoolCar) {
            text = "Tillåts för alla"
        }
        if (value?.length === 1) {
            const driver = permittedDrivers?.find((v) => v.id === value[0].id);
            console.log(value, driver, permittedDrivers)
            text = driver ? driver?.displayName : "-"
        }
        if (value?.length > 1) {
            const recordUsers = value?.map(item => item.id)
            let title = value?.length + " förare";
            return ModalWithFullList(title, null, null, recordUsers, null, users, organizations)
        }
        return text
    }

    const onChangeDefaultTripType = (value) => {
        const data = {"defaultTripType": value ? CONSTANTS_TEXT.PRIVATE : CONSTANTS_TEXT.BUSINESS}
        dataApi.patchVehicle(selectedVehicle?.id, data).then(item => {
            const newData = [...vehicles];
            const index = newData.findIndex(item => selectedVehicle.id === item.id);
            newData.splice(index, 1, {...newData[index], ...item});
            setVehicles(newData);
        })
    }

    return (
        <>
            {!selectedVehicle ? (
                <Loading/>
            ) : (
                <>
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Title style={styles.Title}>Mina fordon</Title>
                        </Col>
                        <Col>
                            <Space align="baseline">
                                <OrganizationsTreeSelect data={organizations} onChange={handleChangedOrganization}
                                                         selectedOrganization={selectedOrgs} isMultiple={true}/>

                                <VehiclesSelect
                                    data={vehiclesByOrg}
                                    onChange={handleChangedVehicle}
                                    value={selectedVehicle?.id}
                                />

                            </Space>
                        </Col>
                    </Row>
                    <Divider/>
                    {!hideMap ? (
                        <div style={{height: "40vh"}}>
                            <GoogleMapReact
                                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                                center={defaultCenter}
                                zoom={showMap ? 13 : 5}
                                yesIWantToUseGoogleMapApiInternals={true}
                                options={mapOptions}
                                // onClick={onClickMap}
                                onGoogleApiLoaded={({map, maps}) => {
                                    console.log("Google API is loaded");
                                    setLightMapType(maps, map);
                                    setMapsInstance({
                                        map: map,
                                        maps: maps,
                                    });
                                }}
                            >

                                {selectedVehicleOverviewData?.position && <Marker
                                    data={selectedVehicleOverviewData}
                                    lat={selectedVehicleOverviewData?.position?.latitude}
                                    lng={selectedVehicleOverviewData?.position?.longitude}
                                />
                                }
                            </GoogleMapReact>
                        </div>
                    ) : (
                        <Text>Kartan är ej tillgänglig.</Text>
                    )}
                    <Divider/>

                    {selectedVehicle && selectedVehicle?.isArchived && (
                        <div>
                            <Descriptions bordered column={{xs: 1, sm: 1, md: 2}}>
                                <Descriptions.Item label={STATIC_TEXT.VEHICLE} style={{}}>
                                    <b>{selectedVehicle?.registry}</b> {selectedVehicle?.displayName || "-"}
                                </Descriptions.Item>
                                <Descriptions.Item label={STATIC_TEXT.ARCHIVED} style={{minWidth: 0}}>
                                    {selectedVehicle?.archived !== null ?
                                        <LastSeenLink value={selectedVehicle?.archivedTsMs} typ={"map"}
                                                      isTracker={selectedVehicle?.isTracker} defaultText={true}/> : "-"}
                                </Descriptions.Item>
                            </Descriptions>


                            <br/>
                            <Alert
                                message="Detta fordon är arkiverat"
                                description="Arkiverade fordon registrerar ingen kördata. Aktivering av fordon kan göras av företagsadmin under fliken Fordon."
                                type="info"
                                showIcon
                            />
                        </div>


                    )}


                    {selectedVehicle && !selectedVehicle?.isArchived && (
                        <Descriptions bordered column={{xs: 1, sm: 1, md: 2}}>
                            <Descriptions.Item label={STATIC_TEXT.VEHICLE} style={{}}>
                                <b>{selectedVehicle?.registry}</b> {selectedVehicle?.displayName || "-"}
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.IMEI} style={{}}>
                                {selectedVehicle?.state?.imei || "-"}
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.BENEFIT_TYPE} style={{}}>
                                {selectedVehicle?.isTracker ? "Utrustning" : benefitType[selectedVehicle?.benefitType]}
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.CURRENT_DRIVER} style={{}}>
                                {selectedVehicle?.currentDriver?.displayName || STATIC_TEXT.NO_DRIVER}
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.DEFAULT} style={{width: "25%"}}>
                                {allowChangeDefaultTripType ?
                                    <Switch
                                        className="tripType"
                                        checked={selectedVehicle?.defaultTripType === CONSTANTS_TEXT.PRIVATE ? "checked" : null}
                                        checkedChildren="Privat"
                                        unCheckedChildren="Tjänst"
                                        onChange={onChangeDefaultTripType}
                                    />
                                    : tripTypes[selectedVehicle?.defaultTripType || "-"]
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.PRIMARY_DRIVER} style={{width: "25%"}}>
                                {selectedVehicle?.defaultDriver?.displayName || STATIC_TEXT.NO_DRIVER}
                            </Descriptions.Item>
                            <Descriptions.Item label={STATIC_TEXT.ICON} style={{}}>
                                {
                                    <Space align="baseline">
                                        <Avatar
                                            style={{
                                                backgroundColor: selectedVehicle?.style?.color,
                                            }}
                                        >
                                            {selectedVehicle?.style?.marker ? <VehicleMarker
                                                marker={vehicleIcon(selectedVehicle?.style?.marker)}/> : "-"}
                                        </Avatar>

                                        {isPrivateTrip && "Privat resa - visas ej på karta"}
                                    </Space>
                                }
                            </Descriptions.Item>
                            {!selectedVehicle?.isTracker ? (
                                <Descriptions.Item label={STATIC_TEXT.PERMITTED_DRIVERS} style={{minWidth: 0}}>
                                    <PermittedDriversModal value={permittedDrivers} record={selectedVehicle}/>
                                </Descriptions.Item>
                            ) : null}

                            <Descriptions.Item label={STATIC_TEXT.LAST_SEEN} style={{minWidth: 0}}>
                                {selectedVehicle?.lastSeen !== null ?
                                    <LastSeenLink value={selectedVehicle?.lastSeen} typ={"map"}
                                                  isTracker={selectedVehicle?.isTracker}/> : "-"}
                            </Descriptions.Item>

                            {!selectedVehicle?.isTracker ? (
                                <Descriptions.Item label="Respons frekvens" style={{minWidth: 0}}>
                                    {selectedVehicle?.latestResponseFrequency || "-"}
                                </Descriptions.Item>
                            ) : null}

                            {!selectedVehicle?.isTracker ? (
                                <Descriptions.Item label="Mätarställning" style={{minWidth: 0}}>
                                    {isOdometerEdited ? (
                                        <OdometerModal visible={isOdometerEdited}
                                                       onCancel={() => setOdometerEdited(false)}
                                                       record={selectedVehicle}
                                                       />
                                    ) : (
                                        <div>
                                            {selectedVehicle?.state?.odometer ? (
                                                <Space>
                                                    <Text>{getOdometerValue(selectedVehicle)}</Text>

                                                    <Button type="link" icon={<icons.EditOutlined/>}
                                                            onClick={editOdometer}></Button>
                                                </Space>
                                            ) : (
                                                "-"
                                            )}
                                        </div>
                                    )}
                                </Descriptions.Item>
                            ) : null}
                            <Descriptions.Item label="Landskod" style={{minWidth: 0}}>
                                {selectedVehicle?.isoCountryCode !== null ? (
                                    <Space size={8}>
                                        <Avatar
                                            src={`${countryFlagApi}${(selectedVehicle?.isoCountryCode)?.toLowerCase()}.svg`}/>
                                        <Text>{selectedVehicle?.isoCountryCode}</Text>
                                    </Space>
                                ) : (
                                    "-"
                                )}
                            </Descriptions.Item>
                            {!selectedVehicle?.isTracker ? (
                                <Descriptions.Item label="Bränsletyp / Bränsleförbrukning" style={{minWidth: 0}}>
                                    {selectedVehicle?.fuel1 !== null
                                        ? getFuelsAndConsumptions(
                                            selectedVehicle?.hybrid,
                                            getFuels(selectedVehicle?.fuel1, selectedVehicle?.fuel2),
                                            getConsumtions(selectedVehicle?.consumption1, selectedVehicle?.consumption2)
                                        )
                                        : "-"}
                                </Descriptions.Item>
                            ) : null}
                            {!selectedVehicle.isTracker ? (
                                <Descriptions.Item label="CO2 utsläpp" style={{minWidth: 0}}>
                                    {selectedVehicle?.co2GKm1 !== null ? getCo2(selectedVehicle?.hybrid, getCo2s(selectedVehicle?.co2GKm1, selectedVehicle?.co2GKm2)) : "-"}
                                </Descriptions.Item>
                            ) : null}
                        </Descriptions>
                    )}
                </>
            )}
        </>
    );
};
export default MyVehicle;

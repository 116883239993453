import React, {createContext, useContext, useEffect, useState} from "react";
import {OrganizationContext} from "../../OrganizationsContext";
import {VehicleContext} from "../../contexts";
import {UsersContext} from "../../UsersContext";
import {DevicesContext} from "../../DevicesContext";
import {dataApi} from "../../../api/api";
import {message} from 'antd';

const RootOrganisationsContext = createContext({});
const RootOrganisationsProvider = ({ children }) => {
    const [allOrganizationsForRoot, setAllOrganizationsForRoot] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    // const deviceLoaded = useSelector(store => store?.superDevices?.LOADED)
    const deviceContext = useContext(DevicesContext)
    const deviceLoaded = deviceContext.devicesLoaded;
    const knownVehicles = useContext(VehicleContext).raw;
    const knownUsers = useContext(UsersContext).users;
    const knownDevices = deviceContext.devices;
    const allOrganisationsCo = useContext(OrganizationContext)

    useEffect(() => {
        allOrganisationsCo.getAllOrganizationsForRoot()
            .then((res) => {
                setAllOrganizationsForRoot(res)
            })
    }, [])

    useEffect(() => {
        setData(allOrganizationsForRoot)
        setLoading(false)
    }, [allOrganizationsForRoot])

    function fixOrgsDevices(record) {
        dataApi.postAdminOrgFix2024(record.organizationId).then(res => {
            message.success("Fix 2024 OK")
        })
    }


    return <RootOrganisationsContext.Provider value={{
        allOrganizationsForRoot,
        deviceLoaded,
        knownDevices,
        knownVehicles,
        knownUsers,
        data,
        setData,
        loading


    }}>{children}</RootOrganisationsContext.Provider>;
};
export { RootOrganisationsContext, RootOrganisationsProvider };

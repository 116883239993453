import React, {useContext, useEffect, useState} from 'react';
import {  useDispatch } from "react-redux";
import {Button, Tooltip, Col, Divider, Form, Modal, Row, Input, Table, Select} from 'antd';
import {DatePicker} from 'antd-v5';

import { DownloadOutlined,  EditOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import {
    SuperAdminDevicesContext
} from "../../../contexts/SuperAdminDevicesContext"
import {dataApi} from "../../../services/api/api";
import {LastSeenLink} from "../shared-components/DatumLink";
import AddUnit, {HardwareFormSelector, InputIccidValidator, InputPhoneValidator} from "./AddUnit";
import {TableActionBtnType} from "./Superdevices";
import {
    benefitType,
    SD_MODAL_AVL_LOG_TITLES,
    SUPER_DEVICES_MODAL_TITLES,
    STATIC_TEXT
} from "../../../lang/translate/sv-SE/sv-SE";
import {
    SD_MODAL_AVL_LOG_KEYS_AND_INDEXES,
    SUPER_DEVICES_ADD_UNIT_TYPE,
    SUPER_DEVICES_KEYS_AND_INDEXES, SUPER_DEVICES_MODAL_TYPES
} from "../../../constants/CustomConstant";

const { RangePicker } = DatePicker;
import locale from 'antd/es/date-picker/locale/sv_SE';

const MODAL_PRINT_NAME = 'ModalFormDeviceAVL: ';

const ModalFormDeviceAVL = ({
                                visible,
                                handleAdd,
                                handleClose,
                                selectedDevice,
                                setSelectedDevice,
                                requestFetchDevices,
                            }) => {
  // const prevVisibleRef = useRef();

  const [, forceUpdate] = useState({});
  const [datePickerAVL, setDatePickerAVL] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(null);
  const [logg, setLogg] = useState([]);
  const [form] = Form.useForm();

  const suContext = useContext(SuperAdminDevicesContext)


  const imei = selectedDevice.device.imei ? selectedDevice.device.imei : '-';
  const organizationId = selectedDevice?.device?.organizationId?.fullName
    ? selectedDevice.device.organizationId.fullName
    : '-';

  //VEHICLE -> change to device.installedVehicle

  const carType = selectedDevice.device.installedVehicle
    ?  benefitType[selectedDevice.device.installedVehicle.benefitType]
    : '-';
  const mileAge = selectedDevice.device.mileAge ? selectedDevice.device.mileAge : '-';
  const carRegnr = selectedDevice.device.installedVehicle?.registry ? selectedDevice.device.installedVehicle?.registry : '-';
  const displayName = selectedDevice.device.installedVehicle?.displayName ? selectedDevice.device.installedVehicle?.displayName : '-';
  const config = selectedDevice.device.configName ? selectedDevice.device.configName : '-';
  const iccid = selectedDevice.device.iccid ? selectedDevice.device.iccid : '-';
  const hardware = selectedDevice.device.deviceType ? selectedDevice.device.deviceType : '-';
  const phone = selectedDevice.device.phone ? selectedDevice.device.phone : '-';
  const firmware = selectedDevice.device.firmware ? selectedDevice.device.firmware : '-';
  const obd = selectedDevice.device.obd ? selectedDevice.device.obd : '-';
  const vehicle = selectedDevice.device.vehicle ? selectedDevice.device.vehicle : '-';
  const selectedDeviceId = selectedDevice.device?.deviceId ? selectedDevice.device?.deviceId : '-';
  const driver = selectedDevice.device.installedVehicle
    ? selectedDevice.device.installedVehicle.displayName
    : 'Driver'; //CHANGE ? !ownerName!
  const dataBlock = selectedDevice.device.dataBlock ? selectedDevice.device.dataBlock : '-';
  const ownerName = selectedDevice.device.installedVehicle
    ? selectedDevice.device.installedVehicle.defaultDriver?.displayName
    : '-'; //CHANGE ? !driver!
  const dateTimeStart = selectedDevice.device.dateTime
    ? selectedDevice.device.dateTime
    : 'DateTimeStart';
  const dateTimeStop = selectedDevice.device.dateTime
    ? selectedDevice.device.dateTime
    : 'DateTimeStop';

  console.log('MODAL AVL', selectedDevice, selectedDevice.device.deviceId, selectedDeviceId)

  useEffect(() => {
    forceUpdate({});

  }, []);

  const formItemLayout = {
    layout: 'vertical',
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const onOk = () => {};

  const onFinish = (values) => {
    console.log(values);
    handleClose();
  };

  const onGetClick = () => {

    if(datePickerAVL?.length > 0){
    console.log("AVL date for ", imei, datePickerAVL[0].unix() * 1000, datePickerAVL[1].valueOf())

    const data = {
      imei: imei,
      start:  datePickerAVL[0].valueOf(),
      stop:  datePickerAVL[1].valueOf(),
    }
    const fileName = "AVL " + imei
    dataApi.getAVLLogFromTo(data, fileName)
        .then(i =>{

            console.log("get logg")
        }
        )
    }

  };

  function onChange(value, dateString) {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
  }

  function onOkDataPicker(value) {
    console.log('onOk: ', value);
    setDatePickerAVL(value)

  }

  function showEditModal(deviceId) {
    console.log(selectedDevice.device)
    setEditModalVisible(true)
    return undefined;
  }

  const onSaveDevice = (device) => {
      console.log('MODAL SAVE DEVICE', selectedDeviceId, device)
      suContext.requestUpdateDeviceById({selectedDeviceId, deviceUpdateData: device}).then(() => {
          // TODO not sure if this state update is the same as previous
          setEditModalVisible(false)
      })
      // dataApi.patchAdminDevices(selectedDeviceId, device )
      //     .then(i => {
      //
      //       requestFetchDevices()
      //       const devices = {
      //       device:
      //       {
      //         ...selectedDevice.device,
      //         ...device
      //       }
      //       }
      //       setSelectedDevice(devices)
      //       setEditModalVisible(false)
      //       console.log(devices)
      //     })
  };

  return (
      <>
        <Modal
      title={SUPER_DEVICES_MODAL_TITLES.AVL}
      visible={visible}
      onOk={onOk}
      onCancel={handleClose()}
      footer={[
        <Button key='submit' type='primary' onClick={handleClose()}>
          {STATIC_TEXT.BTN_CLOSE}
        </Button>,
      ]}
    >
      <Form form={form} {...formItemLayout} onFinish={onFinish} name='device_info_form'>

        <Row gutter={[0, 10]} justify='left'>
          <TwoRowsTitleSubTitle span={10} title={STATIC_TEXT.IMEI} text={imei} />
          <TwoRowsTitleSubTitle span={8} title={STATIC_TEXT.HARDWARE} text={hardware} />
          <Tooltip title={STATIC_TEXT.BTN_CHANGE}>
               <Button
                    type={TableActionBtnType}
                    icon={<EditOutlined />}
                    onClick={() => showEditModal(selectedDeviceId)}
               >
                   {STATIC_TEXT.BTN_CHANGE}
               </Button>
          </Tooltip>
        </Row>

        <Row gutter={[0, 10]} justify='left'>
          <TwoRowsTitleSubTitle span={10} title={STATIC_TEXT.ICCID} text={iccid} />
          <TwoRowsTitleSubTitle span={10} title={STATIC_TEXT.PHONE_NUMBER} text={phone} />
        </Row>

        <Divider dashed />


        <Row gutter={[0, 10]} justify='left'>
          <TwoRowsTitleText span={8} title={STATIC_TEXT.ORG} text={organizationId} />

          <TwoRowsTitleText span={8} title={STATIC_TEXT.PRIMARY_DRIVER} text={ownerName} />

          <TwoRowsTitleText span={8} title={STATIC_TEXT.BENEFIT_TYPE} text={carType} />
        </Row>

        <Row gutter={[0, 10]} justify='left'>
          <TwoRowsTitleText span={8} title={STATIC_TEXT.REG_NUM} text={carRegnr} />

          <TwoRowsTitleText span={8} title={STATIC_TEXT.ALIAS} text={displayName} />

          <TwoRowsTitleText span={8} title={STATIC_TEXT.FIRMWARE_SHORT} text={firmware}/>
        </Row>

        <Title level={5}>{STATIC_TEXT.CONFIG_SHORT} </Title>
        <Text style={{  overflowWrap: 'break-word', wordWrap: 'break-word'}}>{config} </Text>

        <Divider dashed />

        <Row gutter={[0, 0]} justify="space-between">
          <Col span={12}>
          <Title
            span={6}
            level={4}
          >Skapa AVL logg</Title>
          </Col>

            <br/>
            <br/>
          <Row gutter={[0, 0]} justify='left'>
          <RangePicker
              locale={locale}
              changeOnBlur
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              onChange={onOkDataPicker}
              onOk={onOkDataPicker}
          />
          </Row>

          <Row gutter={[0, 0]} align='middle'>
            <Col span={4}>
              <Button type='primary' onClick={() => onGetClick()}>
                Hämta{' '}
              </Button>
            </Col>
          </Row>
        </Row>

      </Form>
    </Modal>
        <ModalEditDevice
            visible={editModalVisible}
            onSaveDevice={onSaveDevice}
            device={selectedDevice.device}
            onCancel={() => {
              setEditModalVisible(false);
            }}
        />
        </>
  );
};

const TwoRowsTitleSubTitle = ({ span, title, text }) => {
  return (
    <Col span={span}>
      <Row gutter={[0, 0]} justify='left'>
        <Title level={4}>{title} </Title>
      </Row>
      <Row gutter={[0, 0]} justify='left'>
        <Text>{text} </Text>
      </Row>
    </Col>
  );
};

const TwoRowsTitleText = ({ span, title, text }) => {
  return (
    <Col span={span}>
      <Row gutter={[0, 0]} justify='left'>
        <Title level={5}>{title} </Title>
      </Row>
      <Row style={{  overflowWrap: 'break-word', wordWrap: 'break-word'}} gutter={[0, 0]} justify='left' >
        <Text style={{  overflowWrap: 'break-word', wordWrap: 'break-word'}}>{text} </Text>
      </Row>
    </Col>
  );
};

const ThreeRowsTitleTextText = ({ span, title, text_one, text_two }) => {
  return (
    <Col span={span}>
      <Row gutter={[0, 0]} justify='left'>
        <Title level={5}>{title} </Title>
      </Row>
      <Row gutter={[0, 0]} justify='left'>
        <Text>{text_one} </Text>
      </Row>
      <Row gutter={[0, 0]} justify='left'>
        <Text>{text_two} </Text>
      </Row>
    </Col>
  );
};

const TableLogg = ({ logg }) => {
  const title = () => 'Logg';
  const pagination = { position: 'topRight' };
  const pageSizeValue = [3];

  const tableState = useState({
    bordered: false,
    loading: false,
    pagination,
    size: 'small',
    title: title,
    showHeader: true,
    rowSelection: false,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: 'none',
    bottom: 'bottomRight',
  });

  let row = {};

  const dataSource = logg.map((item) => {
    row = {
      fileId: item.fileId ? item.fileId : 'ID',
      imei: item.imei ? item.imei : 'IMEI',
      tsFromMs: item.tsFromMs ? item.tsFromMs : 'From YYYY-MM-DD',
      tsToMs: item.tsToMs ? item.tsToMs : 'To YYYY-MM-DD',
      date: item.date ? item.date : 'YYYY-MM-DD',
    };

    return row;
  });

  console.log(dataSource)

  const columns = [
    //FIELD_ID
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.FIELD_ID,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.FIELD_ID,
      title: SD_MODAL_AVL_LOG_TITLES.FIELD_ID,
      //sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ?
            <Tooltip title={STATIC_TEXT.BTN_DOWNLOAD_SEL}>
            <a  >{value}</a>
            </Tooltip>

            : <Text> {'-'} </Text>;
      },
    },
    //DATE_FROM
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_FROM,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_FROM,
      title: SD_MODAL_AVL_LOG_TITLES.DATE_FROM,
      // sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <LastSeenLink value={value} typ={"map"}/> : <Text> {'-'} </Text>;
      },
    },
    //DATE_TO
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_TO,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_TO,
      title: SD_MODAL_AVL_LOG_TITLES.DATE_TO,
      //sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <LastSeenLink value={value} typ={"map"}/> : <Text> {'-'} </Text>;
      },
    },
    //DOWNLOAD_BTN
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.FIELD_ID,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.FIELD_ID,
      //sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ?
            <Tooltip title={STATIC_TEXT.BTN_DOWNLOAD_SEL}>
              <a >
                <DownloadOutlined style={{fontSize: '18px'}} />
              </a>
            </Tooltip>

            : <Text> {'-'} </Text>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Row gutter={[0, 0]} justify='left'>
        <Col span={24}>
          <Table
            {...tableState[0]}
            dataSource={dataSource}
            columns={columns}
            pagination={{ position: ['topRight'], pageSize: pageSizeValue }}
            bordered={false}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const ModalEditDevice = ({ visible, onSaveDevice, onCancel, device }) => {
  const [form] = Form.useForm();

  const LOCAL_STATIC_TEXT = {
    CHOOSE_HARDWARRE: 'Välj hårdvara',
    INPUT_ICCID: 'Ange ICCID',
    INPUT_IMEI: 'Ange IMEI',
    INPUT_PHONE_NUMBER: 'Ange telefonnr.',
    PLACEHOLDER_IMEI: 'Ex. 123456789145',
    PLACEHOLDER_PHONE_NUMBER: 'Ex. 46736940',
  };


  return (
      <Modal
          visible={visible}
          title={STATIC_TEXT.EDIT_DEVICE + " IMEI " + device.imei}
          okText={STATIC_TEXT.BTN_SAVE}
          cancelText={STATIC_TEXT.BTN_ABORT}
          onCancel={onCancel}
          onOk={() => {
            form
                .validateFields()
                .then(values => {
                  form.resetFields();
                  onSaveDevice(values);
                  console.log(values)
                })
                .catch(info => {
                  console.log('Validate Failed:', info);
                });
          }}
      >
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={device}
        >
          <Form.Item
              name={SUPER_DEVICES_KEYS_AND_INDEXES.deviceType}
              label={STATIC_TEXT.HARDWARE}
              rules={[
                {
                  required: false,
                  message: LOCAL_STATIC_TEXT.CHOOSE_HARDWARRE,
                },
              ]}
          >
            <Select
                style={{ width: '100%' }}
                defaultValue={SUPER_DEVICES_ADD_UNIT_TYPE[0]}
                placeholder={LOCAL_STATIC_TEXT.CHOOSE_HARDWARRE}
            >
              {SUPER_DEVICES_ADD_UNIT_TYPE.map((type) => {
                return (
                    <Select.Option key={`opt_${type}`} value={type}>
                      {type}
                    </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
              name={SUPER_DEVICES_KEYS_AND_INDEXES.iccid}
              label={STATIC_TEXT.ICCID}
              rules={[
                {
                  required: false,
                  message: LOCAL_STATIC_TEXT.INPUT_ICCID,
                  //min: 18
                },
              ]}
          >
            <Input
                allowClear
                style={{ width: '100%' }}
                onPressEnter={() => {
                  return null;
                }}
                minLength={18}
                maxLength={22}
                placeholder={LOCAL_STATIC_TEXT.INPUT_ICCID}
            />
          </Form.Item>

          <Form.Item
              name={SUPER_DEVICES_KEYS_AND_INDEXES.phone}
              label={STATIC_TEXT.PHONE_NUMBER}
              inputMode={'numeric'}
              rules={[
                {
                  required: false,
                  message: LOCAL_STATIC_TEXT.INPUT_PHONE_NUMBER,
                },
              ]}
          >
            <Input
                allowClear
                minLength={14}
                inputMode={'tel'}
                onPressEnter={(e) => {
                  return null;
                }}
                style={{ width: '100%' }}
                placeholder={LOCAL_STATIC_TEXT.INPUT_PHONE_NUMBER}
            />

          </Form.Item>



        </Form>
      </Modal>
  );
};


export default ModalFormDeviceAVL;

import {
    OrganizationSimpleProvider,
    UserListSimpleProvider,
    VehiclesComplexListProvider
} from "../../services/contexts/contexts";
import AlarmEditor from "../../components/trackson-components/AlarmEditor";
import {AlarmsEditorContextProvider} from "../../services/contexts/AlarmsEditorContext";
import {Nest} from "../../utils/utilities";

const AlarmEditorPage = () => (
    <Nest wrappers={[VehiclesComplexListProvider, UserListSimpleProvider, OrganizationSimpleProvider, AlarmsEditorContextProvider]}>
        <AlarmEditor />
    </Nest>
);

export default AlarmEditorPage;

import {SalaryReports} from "components/trackson-components/SalaryReports";
import {
    TripLogbookContext,
    TripLogbookProvider
} from "../contexts/TripLogbookContext"
import {
    AuthorizationProvider,
    InitialDateAttest,
    OrganizationsComplexListProvider,
    OrganizationSimpleProvider,
} from "../services/contexts/contexts";
import {Nest} from "../utils/utilities";
import {EconomyReportProvider} from "../services/contexts/SalaryReportsContext";

const SalaryReportPage = () => {
    return (
        <Nest
            wrappers={[
                OrganizationsComplexListProvider,
                InitialDateAttest.provider,
                OrganizationSimpleProvider,
                AuthorizationProvider,
                EconomyReportProvider,
                TripLogbookProvider,
            ]}>
            <SalaryReports/>
        </Nest>
    );
};

export default SalaryReportPage;

import React, {createContext, useContext, useEffect, useState} from "react";
import {ALARM_PRIO, getAlarmNames} from "../redux/constants/alarmConstants";
import {Radio, Form, Input, InputNumber, Card, Select, Slider, Switch} from "antd";
import {
    EmailRecipients,
    VehiclesTreeStructure
} from "../../components/trackson-components/shared-components/ItemsSelectors";
import {OrganizationsTreeSelect} from "../../components/trackson-components/shared-components/OrganizationsTreeSelect";
import {SchemaView} from "../../components/trackson-components/GeoLarmDetails/GeoAlarmDetailsController";
import {useHistory} from "react-router-dom";
import {UserListSimpleContext, VehicleContext, VehiclesComplexListContext} from "./contexts";
import {dataApi} from "../api/api";
import {styles} from "../../components/trackson-components/AlarmEditor/styles";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {defaultScheduleAlarm} from "../../constants/InitialConstant";
import {STATIC_TEXT} from "../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../constants/CustomConstant";
import map from "../../components/trackson-components/Map";

const AlarmsEditorContext = createContext({});
const AlarmsEditorContextProvider = ({children}) => {
    const knownVehicles = useContext(VehiclesComplexListContext);
    const knownUsers = useContext(UserListSimpleContext);
    const [knownOrganizations, setKnownOrganizations] = useState();
    const [alarmName, setAlarmName] = useState();
    const [alarm, setAlarm] = useState();
    const [loaded, setLoaded] = useState(false);
    const [newAlarm, setNewAlarm] = useState();
    const [emailData, setEmailData] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [schedule, setSchedule] = useState(defaultScheduleAlarm);
    const [isTracker, setIsTracker] = useState(false);
    const search = window.location.search; // returns the URL query String
    const params = new URLSearchParams(search);
    const alarmId = params.get("id");
    const alarmType = params.get("alarmType");
    const history = useHistory();

    const initialValues = {
        alarmName: alarmName,
        name: "",
        repeatAfterHours: 24,
        repeatAfterHoursSelect: 0,
        daysMissingTrips: 3,
        notifyWhenAcknowledged: true,
        lowBatteryLevel: 30,
        hoursMissingContact: 24,
        odoRemindDay: "1",
        isTracker: false,
        organizationId: null
    };
    useEffect(() => {
        if (!alarmType) return;

        const name = alarmType === "FUNCTION" ? "NO_DRIVER" : alarmType === "SECURITY" ? "JAMMING" : "ODO_DIFF";
        setAlarmName(name);
    }, [alarmType]);
    useEffect(() => {
        dataApi
            .getOrganizationsSimple()
            .then((r) => {
                setKnownOrganizations(r);
            })
            .catch((err) => console.error(err));
    }, []);
    useEffect(() => {
        if (alarmId === "-1") {
            setNewAlarm(true);
            setSchedule(defaultScheduleAlarm);
            return;
        }

        dataApi.getAlarmSettingsById(alarmId).then((res) => {
            res.repeatAfterHoursSelect = res.repeatAfterHours === 0 ? 0 : 1
            res.repeatAfterHours = res.repeatAfterHoursSelect === 0 ? 24 : res.repeatAfterHours
            setAlarm(res);
            setNewAlarm(false);
            setAlarmName(res.alarmName);

            res.emails = res.emails.map((item) => {
                return {email: item};
            });
            setEmailData(res.emails);

            const targetVehicleIds = res?.targetVehicleIds || [];
            let targetOrganizationIds = res?.targetOrganizationIds || [];
            let targetTrackerOrganizationIds = res?.targetTrackerOrganizationIds || [];
            const targetTrackerIds = res?.targetTrackerIds || [];
            targetOrganizationIds = targetOrganizationIds?.map((org) => "org:" + org);
            targetTrackerOrganizationIds = targetTrackerOrganizationIds?.map((org) => "org:" + org);
            setIsTracker(targetTrackerIds?.length > 0 || targetTrackerOrganizationIds?.length > 0)
            setCheckedKeys([...targetVehicleIds, ...targetOrganizationIds,
                ...targetTrackerOrganizationIds, ...targetTrackerIds]);


            setSelectedOrganization(res.organizationId);
            setSchedule(res.schedule?.length > 0 ? res.schedule : defaultScheduleAlarm);
        });
    }, [alarmId]);

    useEffect(() => {
        if (knownOrganizations && knownOrganizations && knownUsers && (newAlarm === true || newAlarm === false)) {
            setLoaded(true);
        }
    }, [knownOrganizations, knownUsers, knownVehicles, newAlarm]);

    useEffect(() => {
        setIsTracker(false)
    }, [alarmName])

    const AlarmName = ({setAlarmName, initialValues, alarmNames}) => {
        return (
            <Select onChange={(value) => setAlarmName(value)}>
                {Object.entries(alarmNames).map(([key, title]) => {
                    return <Select.Option value={key}>{title}</Select.Option>;
                })}
            </Select>
        );
    };

    const changeScheduleParam = (dayOfWeek, fields) => {
        const r = {};
        dayOfWeek = dayOfWeek - 1;
        r[dayOfWeek] = fields;
        let targetSchedule = schedule;

        targetSchedule[dayOfWeek] = {
            ...targetSchedule[dayOfWeek],
            ...fields,
        };

        setSchedule(targetSchedule);
    };
    const fields = {
        alarmPrio: <AlarmPrio initialValues={initialValues}/>,
        alarmName: (
            <Select disabled={!newAlarm} onChange={(value) => setAlarmName(value)}>
                {Object.entries(getAlarmNames(alarmType)).map(([key, title]) => {
                    return <Select.Option value={key}>{title}</Select.Option>;
                })}
            </Select>
        ),
        emails: <EmailRecipients emailData={emailData} setEmailData={setEmailData}/>,
        targetVehicleIds: (
            <VehiclesTreeStructure
                showTitle={false}
                selectedOrganization={selectedOrganization}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
                knownVehicles={knownVehicles || []}
                knownOrganizations={knownOrganizations || []}
                isTracker={isTracker}
                setIsTracker={setIsTracker}
            />
        ),
        organizationId: <OrganizationsTreeSelect selectedOrganization={selectedOrganization}
                                                 onChange={(value) => setSelectedOrganization(value)}
                                                 data={knownOrganizations || []} isMultiple={false}/>,
        repeatAfterHours: <RepeatAlarm/>,
        schema: <SchemaView schemaView={{minWidth: "100%"}} changeScheduleParam={changeScheduleParam}
                            schedule={schedule}/>,
        name: <Input placeholder={"Ange alias"}/>,
        notifyWhenAcknowledged: <NotifyWhenAcknowledged checked={alarm?.notifyWhenAcknowledged}/>,
        daysMissingTrips: <DaysMissingTrips/>,
        lowBatteryLevel: <LowBatteryLevel/>,
        hoursMissingContact: <HoursMissingContact/>,
        odoRemindDay: <OdoRemindDay/>,
        isTracker: <IsTrackerSelector isTracker={isTracker} setIsTracker={setIsTracker}/>,
    };

    function onClickCancel() {
        const url = APP_PREFIX_PATH + (alarmType === "FUNCTION" ? "/alarmsFunctions" : alarmType === "SECURITY" ? "/alarmsSafety" : "/alarmsQuality");
        history.push(url, {tab: alarmName});
    }

    function onFinish(values) {
        const emails = [];
        emailData.map((i) => {
            emails.push(i.email);
        });
        let targetOrganizationIds = checkedKeys?.filter((org) => org.toString().startsWith("org"))
            ?.map((org) => parseInt(org.replace("org:", "")));

        let selectedTarget = checkedKeys.map(keys => knownVehicles?.find(vehicle => vehicle?.id === keys))
        selectedTarget = selectedTarget.filter(item => !targetOrganizationIds.includes(item?.organizationId) && item)?.map(item => item?.id)
        values.emails = emails;
        values.alarmType = alarmType;
        values.repeatAfterHours = values.repeatAfterHoursSelect === 0 ? 0 : values.repeatAfterHours
        values.targetVehicleIds = !isTracker ? selectedTarget : [],
            values.targetOrganizationIds = !isTracker ? targetOrganizationIds : [],
            values.targetTrackerIds = isTracker ? selectedTarget : [],
            values.targetTrackerOrganizationIds = isTracker ? targetOrganizationIds : [],
            values.schema = schedule;

        if (alarm) {
            values = {
                ...alarm,
                ...values,
            };
        }

        if (newAlarm === true) {
            dataApi.postAlarmSettings(values).then((_) => {
                onClickCancel();
            });
        } else {
            dataApi.patchAlarmSettings(alarm?.alarmSettingsId, values).then((_) => {
                onClickCancel();
            });
        }
    }

    const showAsCard = [
        "alarmName",
        "alarmPrio",
        "alarmName",
        "organizationId",
        "isTracker",
        "name",
        "repeatAfterHours",
        "daysMissingTrips",
        "notifyWhenAcknowledged",
        "lowBatteryLevel",
        "hoursMissingContact",
        "odoRemindDay",
    ];

    function RepeatAlarm() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <Form.Item noStyle name={"repeatAfterHoursSelect"}>
                <Radio.Group>
                    <Radio value={0} style={radioStyle}>Meddela bara en gång</Radio>
                    <Radio value={1} style={radioStyle}>
                        <span className="ant-form-text">Upprepa larmet varje </span>
                        <Form.Item noStyle name={SUPER_DEVICES_KEYS_AND_INDEXES.repeatAfterHours}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <span
                            className="ant-form-text">h tills larmperioden avslutats eller att larmet kvitterats</span>
                    </Radio>
                </Radio.Group>
            </Form.Item>
        );
    }

    function OdoRemindDay() {
        const children = [];
        for (let i = 1; i < 32; i++) {
            children.push(<Select.Option key={i}>{i}:e</Select.Option>);
        }

        return (
            <div>
                <div style={styles.interval}>
                    <p style={styles.paragraph}>{STATIC_TEXT.THIS}</p>
                    <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.odoRemindDay} initialValue={"1"}
                               style={styles.selectDay}>
                        <Select>{children}</Select>
                    </Form.Item>
                    <p style={styles.paragraph}>{STATIC_TEXT.EVERY_MONTH}</p>
                </div>
            </div>
        );
    }

    function DaysMissingTrips() {
        return (
            <div>
                <span className="ant-form-text">Meddela efter </span>
                <Form.Item noStyle name={"daysMissingTrips"}>
                    <InputNumber defaultValue={3} min={1}/>
                </Form.Item>
                <span className="ant-form-text"> dagar utan resor</span>
            </div>
        );
    }

    function IsTrackerSelector(props) {
        return (
            <div>
                <Select
                    style={{width: "100%",}}
                    onChange={(value) => props.setIsTracker(value)}
                    value={props.isTracker}
                >
                    <Select.Option value={false}>Fordon</Select.Option>
                    <Select.Option value={true}>Utrustning</Select.Option>
                </Select>
            </div>
        );
    }

    function LowBatteryLevel() {
        const [batteryLevel, setBatteryLevel] = useState(30);
        const handleSliderChange = (value) => {
            setBatteryLevel(value);
        };

        return (
            <div style={{minWidth: 600}}>
                <span className="ant-form-text">Meddela när batterinivån är mindre än <b>{batteryLevel}%</b></span>
                <Form.Item noStyle name={"lowBatteryLevel"}>
                    <Slider
                        defaultValue={30}
                        min={10}
                        tooltipVisible={false}
                        onChange={handleSliderChange}
                        marks={{
                            10: '10%',
                            20: '20%',
                            30: '30%',
                            40: '40%',
                            50: '50%',
                            60: '60%',
                            70: '70%',
                            80: '80%',
                            90: '90%',
                            100: '100%'
                        }}
                        step={10}
                    />
                </Form.Item>
            </div>
        );
    }

    function HoursMissingContact() {
        return (
            <div>
                <span className="ant-form-text">Meddela när det gått </span>
                <Form.Item noStyle name={"hoursMissingContact"}>
                    <InputNumber defaultValue={3} min={1}/>
                </Form.Item>
                <span className="ant-form-text"> h sedan enheten förväntades rapportera</span>
            </div>
        );
    }

    function AlarmPrio({initialValues}) {
        return (
            <Select defaultValue={initialValues.alarmPrio} onChange={(v) => console.log(v)}>
                {Object.entries(ALARM_PRIO).map(([key, title]) => {
                    return <Select.Option value={key}>{title}</Select.Option>;
                })}
            </Select>
        );
    }

    function NotifyWhenAcknowledged({checked}) {
        return (
            <div>
                <span className="ant-form-text">Meddela när larmet kvitterats </span>
                <Form.Item noStyle name={"notifyWhenAcknowledged"} valuePropName={checked ? "checked" : null}>
                    <Switch/>
                </Form.Item>
            </div>
        );
    }

    return <AlarmsEditorContext.Provider value={{
        loaded,
        alarmName,
        showAsCard,
        fields,
        alarmType,
        onFinish,
        alarm,
        initialValues,
        onClickCancel,
        isTracker,
    }}>{children}</AlarmsEditorContext.Provider>;
};
export {AlarmsEditorContext, AlarmsEditorContextProvider};

import React, { useContext, useState, useEffect } from "react";
import { Button, Checkbox, Form, Input, Modal, Switch, TreeSelect, Row, Col, Divider, message, Select } from "antd";
import * as icons from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Text from "antd/es/typography/Text";
import {
    SuperAdminDevicesContext
} from "../../../contexts/SuperAdminDevicesContext"
import {
    deviceConnectObjectFunctions,
    filterNonConnectedVehicles,
    getOrgTreeData,
    getOrgTreeDataWithChildren
} from "./superDeviceHelpers";
import { useDispatch, useSelector } from "react-redux";
import {
    benefitType,
    FORM_MESSAGES,
    SUPER_DEVICES_MODAL_TITLES,
    STATIC_TEXT, tripTypes
} from "../../../lang/translate/sv-SE/sv-SE";
import {
    CONSTANTS_TEXT,
    SD_BASE_COLORS,
    SD_BENEFIT_TYPES,
    SD_DEFAULT_TRIP_TYPES, SUPER_DEVICES_KEYS_AND_INDEXES
} from "../../../constants/CustomConstant";
import {generateRandomColor} from "../shared-components/sharedComponents";

const FORM_FIELDS = {
    EXISTING_DRIVER: "existing_driver_id",
    EXISTING_ORG: "existing_organization_organizationId",
    EXISTING_VEHICLE: "existing_vehicle_id",
    NEW_DRIVER_DISPLAY_NAME: "new_driver_display_name",
    NEW_DRIVER_EMAIL: "new_driver_email",
    NEW_DRIVER_ROLL: "new_driver_roll",
    NEW_ORG_ORG_NUMBER: "new_organization_number",
    NEW_ORG_BUSINESS_NAME: "new_org_bussiness_name",
    NEW_ORG_COUNTRY: "new_org_country",
    NEW_ORG_COUNTY: "new_org_county",
    NEW_ORG_EMAIL: "new_org_email",
    NEW_ORG_NUMBER: "new_org_num",
    NEW_ORG_PHONE: "new_org_phone",
    NEW_ORG_POSTAL_CODE: "new_org_postal_code",
    NEW_ORG_STREET_ADDRESS: "new_org_street_address",
    NEW_ORG_PASSWORD: "new_org_password",
    NEW_VEHICLE_ALIAS: "new_vehicle_alias",
    NEW_VEHICLE_REGNUM: "new_vehicle_regnum",
    NEW_VEHICLE_DEFAULT_TRIP_TYPE: "new_vehicle_default_trip_type",
    NEW_VEHICLE_BENEFIT_TYPE: "new_vehicle_benefit_type",
    NEW_DRIVER_SEND_INVITE: "sendInvite",
    COUNTRY_CODE: "country_code",
};

const FORM_MIN_MAX = {
    NEW_ORG_MIN: 1,
    NEW_ORG_MAX: 12,
};

const initialState = {
    existing_vehicle_id: null,
    existing_driver_id: null,
    new_vehicle_regnum: null,
    new_vehicle_benefitType: SD_BENEFIT_TYPES[2].TYPE,
    new_vehicle_defaultTripType: SD_DEFAULT_TRIP_TYPES[1].TYPE,
    dataVehicle: null,
    dataDriver: null,
    existing_organization_organizationId: null,
    country_code: null,
    new_driver_roll: "DRIVER"
};

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
const countryNamesList = countries.getNames("sv", { select: "alias" });
const countryNamesAsArray = Object.entries(countryNamesList).map(([key, label]) => ({
    key,
    label,
}));
const countryNamesAsArraySorted = countryNamesAsArray.sort((a, b) => a.label.localeCompare(b.label));
// KOPPLA DEVICE/ ENHET TILL ORG / KUND

const ModalFormDeviceConnect = ({ visible, handleAdd, handleClose, selectedDevice, orgData }) => {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [formValues, setFormValues] = useState(initialState);

    // Vehicles and users belonging to selected organization
    // const selOrgVehicles = useSelector((store) => store.superDevices.RESULT_VEHICLES_BY_ORG);
    // const selOrgUsers = useSelector((store) => store.superDevices.RESULT_USERS_BY_ORG);
    const suContext = useContext(SuperAdminDevicesContext)
    // TODO maybe move this to a different context, a subcontext of superadmin?
    const selOrgVehicles = suContext.devices.RESULT_VEHICLES_BY_ORG
    const selOrgUsers = suContext.devices.RESULT_USERS_BY_ORG

    const [vehicleKnown, setOnVehicleKnown] = useState(false);
    const [vehicleKnownNew, setOnVehicleKnownNew] = useState(false);
    const [driverKnown, setOnDriverKnown] = useState(false);
    const [driverKnownNew, setOnDriverKnownNew] = useState(false);
    const [useNewOrganization, setUseNewOrganization] = useState(false);
    const resetOnOrgChanges = (orgId) => {
        setOnVehicleKnown(selectedDevice?.device?.isTracker);
        setOnVehicleKnownNew(false);
        setOnDriverKnown(false);
        setOnDriverKnownNew(false);
        //  form.resetFields();
        formValues.existing_vehicle_id = null;
        formValues.existing_driver_id = null;
        formValues.existing_organization_organizationId = orgId || null;
        formValues.dataDriver = null;
        formValues.dataVehicle = null;
        formValues.country_code = null;
        setFormValues({ existing_organization_organizationId: orgId });
    };
    const onOrgSwitchChange = () => {
        setUseNewOrganization(!useNewOrganization);
        form.resetFields();
        formValues.existing_vehicle_id = null;
        formValues.existing_driver_id = null;
        formValues.existing_organization_organizationId = null;
        formValues.dataDriver = null;
        formValues.dataVehicle = null;
        formValues.country_code = null;
        resetOnOrgChanges();
    };

    console.log("selectedDevice", selectedDevice)
    const orgTreeData = getOrgTreeDataWithChildren(orgData);

    // TODO these should be filtered already when populated in the context?
    const filteredVehicles = filterNonConnectedVehicles(
        selOrgVehicles ? selOrgVehicles : [],
        formValues.existing_organization_organizationId
    );
    const filteredUsers = selOrgUsers ?
        selOrgUsers.filter(
            (i) => i.organizationId === formValues.existing_organization_organizationId
        ) : [];

    const imei = selectedDevice.device.imei ? selectedDevice.device.imei : "-";
    const deviceType = selectedDevice.device.deviceType ? selectedDevice.device.deviceType : "-";

    useEffect(() => {
        // TODO this probably does nothing, we should get rid of it
        // it makes the component update when mounted but components already
        // update when mounted
        forceUpdate({});
    }, []);

    const formItemLayout = {
        layout: "vertical",
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    const validateFieldsAndSubmit = () => {
        console.log(form.validateFields());
        form.validateFields()
            .then(() => {
                console.log("No error - Run onFinish");
                onFinish();
                handleClose();
                return;
            })
            .catch((error) => {
                console.log(" ~ file: ModalFormDeviceConnect.jsx ~ line 161 ~ validateFieldsAndSubmit ~ error", error);

                console.log("Error: ", error);
                return;
            });
    };

    const onFinish = () => {
        let useOrg = null;
        let useVehicle = null;
        let useDriver = null;
        let newOrg = false;
        let newOrgId = null;
        let newVehicle = false;
        let newDriver = false;
        let existingVehicle = false;
        let existingDriver = false;

        //CHECK FOR ORG EXISTING OR NEW ORG
        if (useNewOrganization) {
            useOrg = deviceConnectObjectFunctions.createNewOrgObject(
                formValues.new_org_business_name ? formValues.new_org_business_name : "",
                formValues.new_org_email ? formValues.new_org_email : null,
                formValues.new_org_password ? formValues.new_org_password : null,
                formValues.new_organization_number ? formValues.new_organization_number : "",
                formValues.new_org_email ? formValues.new_org_email : null,
                formValues.new_org_phone ? formValues.new_org_phone : "",
                {
                    streetAndNum: formValues.new_org_street_address ? formValues.new_org_street_address : "",
                    zip: formValues.new_org_postal_code ? parseInt(formValues.new_org_postal_code) : 11111,
                    city: "",
                    state: formValues.new_org_county ? formValues.new_org_county : "",
                    country: formValues.new_org_country ? formValues.new_org_country : "",
                },
                {
                    streetAndNum: "",
                    zip: 11111,
                    city: "",
                    state: "",
                    country: "",
                }
            );
            newOrg = true;
            newOrgId = 1;
        } else {
            useOrg = deviceConnectObjectFunctions.createExistingOrgObject(formValues.existing_organization_organizationId);
            newOrg = false;
        }

        //FORDON    OKÄNT / (KÄNT & NY / (KÄNT & EXISTERANDE) /
        //CHECK FOR -VEHICLE- UNKNOWN / (KNOWN AND NEW) / (KNOWN AND EXISTING)
        if (!vehicleKnown) {
            newVehicle = false;
            existingVehicle = false;
        } else if (vehicleKnown && !vehicleKnownNew) {
            useVehicle = deviceConnectObjectFunctions.createExistingVehicleObject(formValues.existing_vehicle_id ? formValues.existing_vehicle_id : null);
            newVehicle = false;
            existingVehicle = true;
        } else {
            useVehicle = deviceConnectObjectFunctions.createNewVehicleObject(
                "CAR",
                formValues.new_vehicle_regnum ? formValues.new_vehicle_regnum : null,
                formValues.new_vehicle_displayName ? formValues.new_vehicle_displayName : "",
                { color: generateRandomColor(), marker: selectedDevice?.device?.isTracker ? "wrench" : "androidCar" },
                [],
                null,
                formValues.new_vehicle_defaultTripType ? formValues.new_vehicle_defaultTripType : CONSTANTS_TEXT.BUSINESS,
                formValues.new_vehicle_benefitType ?
                    formValues.new_vehicle_benefitType : (selectedDevice?.device?.isTracker ? SD_BENEFIT_TYPES[3].TYPE :  SD_BENEFIT_TYPES[2].TYPE),
                false,
                newOrgId ? newOrgId : null,
                formValues.country_code
            );
            newVehicle = true;
            existingVehicle = false;
        }

        //FÖRARE    OKÄNT / (KÄNT & NY / (KÄNT & EXISTERANDE) /
        //CHECK FOR -USER- UNKNOWN / (KNOWN AND NEW) / (KNOWN AND EXISTING)
        if (!driverKnown) {
            newDriver = false;
            existingDriver = false;
        } else if (driverKnown && !driverKnownNew) {
            useDriver = deviceConnectObjectFunctions.createExistingDriverObject(formValues.existing_driver_id ? formValues.existing_driver_id : null);
            newDriver = false;
            existingDriver = true;
        } else {
            useDriver = deviceConnectObjectFunctions.createNewDriverObject(
                formValues.new_driver_display_name ? formValues.new_driver_display_name : "",
                formValues.new_driver_email ? formValues.new_driver_email : "",
                formValues.new_driver_roll ? formValues.new_driver_roll : ["DRIVER"],
                formValues.new_driver_send_invite || false,
                newOrgId ? newOrgId : null
            );

            newDriver = true;
            existingDriver = false;
        }

        suContext.requestConnectDevice({
            orgNew: newOrg,
            orgNewId: newOrgId,
            vehicleNew: newVehicle,
            driverNew: newDriver,
            vehicleExisting: existingVehicle,
            driverExisting: existingDriver,
            dataOrg: useOrg,
            dataVehicle: useVehicle,
            dataDriver: useDriver,
            userEmailInvite: formValues.new_driver_send_invite,
            selectedDeviceId: selectedDevice.device.deviceId,
        })
    };

    // console.log('FORMVALUES', formValues)

    const onVehicleChange = () => {
        setOnVehicleKnown(!vehicleKnown);
        setOnDriverKnown(false);
        setOnDriverKnownNew(false);
        if (useNewOrganization) {
            setOnVehicleKnownNew(true);
        }
    };

    const onVehicleChangeNew = () => {
        setOnVehicleKnownNew(!vehicleKnownNew);
    };

    const onDriverChange = () => {
        setOnDriverKnown(!driverKnown);
        if (useNewOrganization) {
            setOnDriverKnownNew(true);
        }
    };

    const onDriverChangeNew = () => {
        setOnDriverKnownNew(!driverKnownNew);
    };

    return (
        <Modal
            title={SUPER_DEVICES_MODAL_TITLES.CONNECT_TO_ORG}
            visible={visible}
            onCancel={() => handleClose()}
            footer={[
                <Button key="back" onClick={() => handleClose()}>
                    {STATIC_TEXT.BTN_ABORT}
                </Button>,

                <Button key="submit" type="primary" onClick={validateFieldsAndSubmit}>
                    {STATIC_TEXT.BTN_CONNECT}
                </Button>,
            ]}
            width={640}
        >
            <Form form={form} {...formItemLayout} name="device_connect_form">
                <Row gutter={[0, 0]} justify="left">
                    <Col>
                        <Title level={4}>{deviceType} </Title>
                        <Text level={5}>{imei} </Text>
                    </Col>
                </Row>

                <Divider dashed />

                <Row gutter={[0, 5]}>
                    <Col span={24}>
                        <Title level={4}>{STATIC_TEXT.ORG}</Title>
                    </Col>
                </Row>

                <Row gutter={[0, 5]} justify="left">
                    <NewExistingOrgSwitch newOrExistingOrg={useNewOrganization}
                                          onOrgSwitchChange={onOrgSwitchChange}/>
                </Row>

                {useNewOrganization ? (
                    <CreateNewOrgSection formValues={formValues}
                                         setFormValues={setFormValues}/>
                ) : (
                    <UseExistingOrg formValues={formValues}
                                    initialState={initialState}
                                    setFormValues={setFormValues}
                                    resetOnOrgChanges={resetOnOrgChanges}
                                    orgTreeData={orgTreeData} />
                )}

                <Divider dashed />

                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Row gutter={[0, 5]}>
                            <Col span={24}>{selectedDevice?.device?.isTracker ?
                                 <Title level={4}>{"Utrustning"}</Title>
                                : <Title level={4}>{STATIC_TEXT.VEHICLE}</Title>}
                            </Col>
                        </Row>

                        <Row gutter={[0, 0]}>
                            <Col span={6}>
                                <VehicleKnownCheck vehicleKnown={vehicleKnown}
                                                   onVehicleChange={!selectedDevice?.device?.isTracker && onVehicleChange}
                                                   useNewOrganization={useNewOrganization}
                                                   formValues={formValues}/>
                            </Col>

                            {vehicleKnown ? (
                                <Col span={6}>
                                    <VehicleKnownCheckNew
                                        vehicleKnownNew={vehicleKnownNew}
                                        onVehicleChangeNew={onVehicleChangeNew}
                                        orgNotKnown={useNewOrganization}/>
                                </Col>
                            ) : null}
                        </Row>

                        {
                            //---
                            vehicleKnown && !vehicleKnownNew ? (
                                <UseExistingVehicle
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    selectData={filteredVehicles}
                                    placeholder={STATIC_TEXT.SELECT_AN_EXISTING_VEHICLE}
                                />
                            ) : vehicleKnown && vehicleKnownNew ? (
                                selectedDevice?.device?.isTracker ?
                                    <CreateNewTracker formValues={formValues} setFormValues={setFormValues}/>
                                : <CreateNewVehicle formValues={formValues} setFormValues={setFormValues}/>
                            ) : null
                        }
                    </Col>
                </Row>

                <Divider dashed />

                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Row gutter={[0, 5]}>
                            <Col span={6}>
                                {selectedDevice?.device?.isTracker ?
                                    <Title level={4}>{"Användare"}</Title>
                                    : <Title level={4}>{STATIC_TEXT.DRIVER}</Title>}
                            </Col>
                        </Row>

                        <Row gutter={[0, 0]}>
                            <Col span={6}>
                                <DriverKnownCheck driverKnown={driverKnown}
                                                  onDriverChange={onDriverChange}
                                                  vehicleKnown={vehicleKnown}/>
                            </Col>

                            {
                                //---
                                driverKnown ? (
                                    <Col span={6}>
                                        <DriverKnownCheckNew
                                            driverKnownNew={driverKnownNew}
                                            onDriverChangeNew={onDriverChangeNew}
                                            useNewOrganization={useNewOrganization}/>
                                    </Col>
                                ) : null
                            }
                        </Row>

                        {driverKnown && !driverKnownNew ? (
                            <UseExistingDriver formValues={formValues}
                                               setFormValues={setFormValues}
                                               selectData={filteredUsers}
                                               placeholder={STATIC_TEXT.SELECT_AN_EXISTING_DRIVER}/>
                        ) : driverKnown && driverKnownNew ? (
                            <CreateNewDriver formValues={formValues}
                                             setFormValues={setFormValues}/>
                        ) : null}
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

const VehicleKnownCheckNew = ({ vehicleKnownNew, onVehicleChangeNew, orgNotKnown }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 0]}>
                <Col span={12}>
                    <Switch
                        style={{
                            backgroundColor: vehicleKnownNew ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
                            minWidth: 100,
                        }}
                        checkedChildren={STATIC_TEXT.NEW_VEHICLE}
                        unCheckedChildren={STATIC_TEXT.LIST_VEHICLES}
                        onChange={!orgNotKnown ? onVehicleChangeNew : null}
                        // onChange={onVehicleChangeNew}
                        checked={vehicleKnownNew}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const DriverKnownCheckNew = ({ driverKnownNew, onDriverChangeNew, useNewOrganization }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 0]}>
                <Col span={12}>
                    <Switch
                        style={{
                            backgroundColor: driverKnownNew ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
                            minWidth: 100,
                        }}
                        checkedChildren={STATIC_TEXT.NEW_DRIVER}
                        unCheckedChildren={STATIC_TEXT.LIST_DRIVERS}
                        onChange={!useNewOrganization ? onDriverChangeNew : null}
                        checked={driverKnownNew}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const VehicleKnownCheck = ({ vehicleKnown, onVehicleChange, formValues, useNewOrganization }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 15]}>
                <Col span={12}>
                    <Switch
                        style={{
                            backgroundColor: vehicleKnown ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
                            minWidth: 100,
                        }}
                        disabled={
                            !useNewOrganization && !formValues.existing_organization_organizationId
                            /* Do not use automatic refactoring because it gets confused about order of operations */
                            /* Be very certain of what is being evaluated first */
                            /*
                            !useNewOrganization || !formValues.existing_organization_organizationId
                                ? formValues.existing_organization_organizationId === null
                                : false
                                    ? false
                                    : true || (useNewOrganization && !formValues.existing_organization_organizationId)
                                        ? false
                                        : true
                             */
                        }
                        //disabled={!newOrExistingOrg ? false : true}
                        checkedChildren={STATIC_TEXT.KNOWN}
                        unCheckedChildren={STATIC_TEXT.UNKNOWN}
                        onChange={onVehicleChange}
                        checked={vehicleKnown}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const DriverKnownCheck = ({ driverKnown, onDriverChange, vehicleKnown }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 15]}>
                <Col span={8}>
                    <Switch
                        style={{
                            backgroundColor: driverKnown ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
                            minWidth: 100,
                        }}
                        disabled={!vehicleKnown}
                        checkedChildren={STATIC_TEXT.KNOWN}
                        unCheckedChildren={STATIC_TEXT.UNKNOWN}
                        onChange={onDriverChange}
                        checked={driverKnown}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const NewExistingOrgSwitch = ({ newOrExistingOrg, onOrgSwitchChange }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 15]}>
                <Col span={12}>
                    <Switch
                        style={{
                            backgroundColor: newOrExistingOrg ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
                            minWidth: 100,
                        }}
                        checkedChildren={STATIC_TEXT.NEW_ORG_SHORT}
                        unCheckedChildren={STATIC_TEXT.EXISTING}
                        onChange={onOrgSwitchChange}
                        checked={newOrExistingOrg}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

const UseExistingOrg = ({ resetOnOrgChanges, setFormValues, formValues, org, orgTreeData }) => {
    const suContext = useContext(SuperAdminDevicesContext)
    return (
        <React.Fragment>
            <Row gutter={[0, 0]} justify="left">
                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.EXISTING_ORG]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SELECT_AN_EXISTING_ORGANISATION,
                            },
                        ]}
                    >
                        <TreeSelect
                            suffixIcon={<icons.UserSwitchOutlined style={{ marginRight: 3 }} />}
                            style={{ width: "100%" }}
                            value={org}
                            showSearch
                            autoComplete={false}
                            filterOption={(input, option) => option?.title?.toLowerCase().includes(input.toLowerCase())}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            treeData={orgTreeData}
                            placeholder={STATIC_TEXT.CHOOSE_AN_ORG}
                            treeDefaultExpandAll
                            onChange={(value) => {
                                resetOnOrgChanges(value);
                                // setFormValues({ ...initialState, existing_organization_organizationId: value });
                                suContext.requestVehiclesAndUsersByOrgId({organizationId: value})
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const CreateNewOrgSection = ({ formValues, setFormValues }) => {
    return (
        <React.Fragment>
            <Row gutter={[5, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_NUMBER]}
                        rules={[
                            {
                                required: false,
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG, //1
                            },
                        ]}
                    >
                        <Input
                            minLength={FORM_MIN_MAX.NEW_ORG_MIN}
                            maxLength={FORM_MIN_MAX.NEW_ORG_MAX}
                            type={"number"}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG}
                            prefix={<icons.NumberOutlined style={{ marginRight: 3 }} />}
                            onChange={(e) => setFormValues({ ...formValues, new_organization_number: e.target.value })}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_BUSINESS_NAME]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SUPPLY_A_NEW_BUSINESS_NAME, //2
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"text"}
                            prefix={<icons.TeamOutlined style={{ marginRight: 3 }} />}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_BUSINESS_NAME}
                            onChange={(e) => setFormValues({ ...formValues, new_org_business_name: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[5, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_PHONE]}
                        rules={[
                            {
                                required: false,
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_PHONE, //3
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"tel"}
                            prefix={<icons.PhoneFilled style={{ marginRight: 3 }} />}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_PHONE}
                            onChange={(e) => setFormValues({ ...formValues, new_org_phone: e.target.value })}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_EMAIL]}
                        rules={[
                            {
                                required: false,
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_EMAIL, //4
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"email"}
                            autoComplete="new-password"
                            prefix={<icons.MailFilled style={{ marginRight: 3 }} />}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_EMAIL}
                            onChange={(e) => setFormValues({ ...formValues, new_org_email: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {/* PASSWORD FIELD */}
            <Row gutter={[5, 0]} justify="right">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_PASSWORD]}
                        rules={[
                            {
                                required: false,
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_PASSWORD, //5
                            },
                        ]}
                    >
                        <Input.Password
                            style={{ width: "100%" }}
                            minLength={1}
                            autoComplete="new-password"
                            prefix={<icons.LockOutlined style={{ marginRight: 3 }} />}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_PASSWORD}
                            onChange={(e) => setFormValues({ ...formValues, new_org_password: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[5, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_STREET_ADDRESS]}
                        rules={[
                            {
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_STREET_ADDRESS, //5
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"text"}
                            prefix={<icons.EnvironmentFilled style={{ marginRight: 3 }} />}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_STREET_ADDRESS}
                            onChange={(e) => setFormValues({ ...formValues, new_org_street_address: e.target.value })}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_POSTAL_CODE]}
                        rules={[
                            {
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_POSTAL_CODE, //6
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"number"}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_POSTAL_CODE}
                            onChange={(e) => setFormValues({ ...formValues, new_org_postal_code: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[5, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_COUNTY]}
                        rules={[
                            {
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTY, //7
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            type={"text"}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTY}
                            onChange={(e) => setFormValues({ ...formValues, new_org_county: e.target.value })}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_ORG_COUNTRY]}
                        rules={[
                            {
                                message: STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTRY, //8
                            },
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTRY}
                            onChange={(e) => setFormValues({ ...formValues, new_org_country: e.target.value })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const CreateNewVehicle = ({ formValues, setFormValues, selectedDevice }) => {
    // const [defaultIsoCountryCode, setDefaultIsoCountryCode] = useState(null);
    return (
        <React.Fragment>
            <Row gutter={[16, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_VEHICLE_REGNUM]}
                        rules={[
                            {
                                // required: true,
                                message: STATIC_TEXT.SUPPLY_A_REG_NUM,
                            },
                        ]}
                    >
                        <Input
                            prefix={<icons.CarOutlined style={{ marginRight: 3 }} />}
                            onChange={(e) => setFormValues({ ...formValues, new_vehicle_regnum: e.target.value })}
                            placeholder={STATIC_TEXT.SUPPLY_A_REG_NUM}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_VEHICLE_ALIAS]}
                        rules={[
                            {
                                required: !formValues?.new_vehicle_regnum,
                                message: STATIC_TEXT.SUPPLY_AN_ALIAS,
                            },
                        ]}
                    >
                        <Input
                            prefix={<icons.UserOutlined style={{ marginRight: 3 }} />}
                            onChange={(e) => setFormValues({ ...formValues, new_vehicle_displayName: e.target.value })}
                            placeholder={STATIC_TEXT.SUPPLY_AN_ALIAS}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[16, 0]} justify="left">
                <Col span={8}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_VEHICLE_BENEFIT_TYPE]}
                        initialValue={SD_BENEFIT_TYPES[2].TYPE}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SUPPLY_A_BENEFIT_TYPE,
                            },
                        ]}
                    >
                        <Select
                            suffixIcon={<icons.AuditOutlined style={{ marginRight: 3 }} />}
                            onChange={(value) => setFormValues({ ...formValues, new_vehicle_benefitType: value })}
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={STATIC_TEXT.SUPPLY_A_BENEFIT_TYPE}
                            defaultValue={SD_BENEFIT_TYPES[2].TYPE}
                        >
                            {SD_BENEFIT_TYPES.map((item) => {
                                return (
                                    <Select.Option key={`opt_${item.TYPE}`} value={item.TYPE}>
                                        {`${item.TYPE_DISPLAY_TEXT}`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_VEHICLE_DEFAULT_TRIP_TYPE]}
                        initialValue={SD_DEFAULT_TRIP_TYPES[1].TYPE}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SUPPLY_A_DEFAULT_TRIP_TYPE,
                            },
                        ]}
                    >
                        <Select
                            suffixIcon={<icons.NodeIndexOutlined style={{ marginRight: 3 }} />}
                            onChange={(value) => setFormValues({ ...formValues, new_vehicle_defaultTripType: value })}
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={STATIC_TEXT.SUPPLY_A_DEFAULT_TRIP_TYPE}
                            defaultValue={SD_DEFAULT_TRIP_TYPES[1].TYPE}
                        >
                            {SD_DEFAULT_TRIP_TYPES.map((item) => {
                                return (
                                    <Select.Option key={`opt_${item.TYPE}`} value={item.TYPE}>
                                        {`${item.TYPE_DISPLAY_TEXT}`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={[FORM_FIELDS.COUNTRY_CODE]} noStyle>
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                            name={"isoCountryCode"}
                            onChange={(value) => setFormValues({ ...formValues, country_code: value })}
                            // onChange={ ifEdit}
                            // onChange={(val) => {
                            //     setDefaultIsoCountryCode(val);
                            // }}
                            // onSelect = { (value) => modalReminderToCheckReg(value)}

                            // defaultValue={record?.isoCountryCode ? record?.isoCountryCode : null}
                            placeholder="Ange landskod"
                            style={{ width: "100%" }}
                        >
                            {countryNamesAsArraySorted.map((country) => (
                                <Select.Option value={country.key}>
                                    {country.label} ({country.key})
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const CreateNewTracker = ({ formValues, setFormValues }) => {
    // const [defaultIsoCountryCode, setDefaultIsoCountryCode] = useState(null);
    return (
        <React.Fragment>
            <Row gutter={[16, 0]} justify="left">

                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_VEHICLE_ALIAS]}
                        rules={[
                            {
                                required: !formValues?.new_vehicle_regnum,
                                message: STATIC_TEXT.SUPPLY_AN_ALIAS,
                            },
                        ]}
                    >
                        <Input
                            prefix={<icons.UserOutlined style={{ marginRight: 3 }} />}
                            onChange={(e) => setFormValues({ ...formValues, new_vehicle_displayName: e.target.value })}
                            placeholder={STATIC_TEXT.SUPPLY_AN_ALIAS}
                        />
                    </Form.Item>
                </Col>
            </Row>

        </React.Fragment>
    );
};

const UseExistingVehicle = ({ formValues, setFormValues, selectData, placeholder }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 0]} justify="left">
                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.EXISTING_VEHICLE]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SELECT_AN_EXISTING_VEHICLE,
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                            suffixIcon={<icons.CarOutlined style={{ marginRight: 3 }} />}
                            onChange={(value) => setFormValues({ ...formValues, existing_vehicle_id: value })}
                            value={formValues.existing_vehicle_id}
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={placeholder}
                        >
                            {selectData.map((item) => {
                                return (
                                    <Select.Option key={`opt_${item}`} value={item.id}>
                                        {`${item.registry} - ${item.displayName} `}{" "}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const CreateNewDriver = ({ formValues, setFormValues }) => {
    const [emailInvite, setEmailInvite] = useState(false);

    const onSendInviteChange = () => {
        setEmailInvite(!emailInvite);
        setFormValues({ ...formValues, new_driver_send_invite: !emailInvite });
    };

    return (
        <React.Fragment>
            <Row gutter={[15, 0]} justify="left">
                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_DRIVER_DISPLAY_NAME]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SUPPLY_A_FIRST_LAST_NAME,
                            },
                        ]}
                    >
                        <Input
                            prefix={<icons.UserAddOutlined style={{ marginRight: 3 }} />}
                            onChange={(e) => setFormValues({ ...formValues, new_driver_display_name: e.target.value })}
                            placeholder={STATIC_TEXT.SUPPLY_A_FIRST_LAST_NAME}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_DRIVER_EMAIL]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SUPPLY_AN_EMAIL_ADDRESS,
                            },
                        ]}
                    >
                        <Input
                            prefix={<icons.MailOutlined style={{ marginRight: 3 }} />}
                            type={"email"}
                            onChange={(e) => setFormValues({ ...formValues, new_driver_email: e.target.value })}
                            placeholder={STATIC_TEXT.SUPPLY_AN_EMAIL_ADDRESS}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[15, 0]} justify="right">
                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.NEW_DRIVER_ROLL]}

                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                            mode="multiple"
                            placeholder="Välj roll"
                            onChange={(e) => setFormValues({ ...formValues, new_driver_roll: e })}
                        >
                            <Option value={SUPER_DEVICES_KEYS_AND_INDEXES.ADMIN}>Företagsadmin</Option>,
                            <Option value={SUPER_DEVICES_KEYS_AND_INDEXES.DRIVER}>Förare</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        initialValue={false}
                        name={[FORM_FIELDS.NEW_DRIVER_SEND_INVITE]}
                        rules={[
                            {
                                type: "boolean",
                            },
                        ]}
                    >
                        <Checkbox onChange={onSendInviteChange}>{STATIC_TEXT.SEND_INVITE}</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

const UseExistingDriver = ({ formValues, setFormValues, selectData, placeholder }) => {
    return (
        <React.Fragment>
            <Row gutter={[0, 20]} justify="left">
                <Col span={24}>
                    <Form.Item
                        name={[FORM_FIELDS.EXISTING_DRIVER]}
                        rules={[
                            {
                                required: true,
                                message: STATIC_TEXT.SELECT_AN_EXISTING_DRIVER,
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                            suffixIcon={<icons.UserSwitchOutlined style={{ marginRight: 3 }} />}
                            onChange={(value) => setFormValues({ ...formValues, existing_driver_id: value })}
                            style={{ width: "100%" }}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder={placeholder}
                        >
                            {selectData?.map((item) => {
                                return (
                                    <Select.Option key={`opt_${item}`} value={item.userId}>
                                        {`${item.displayName} - ${item.displayEmail}`}{" "}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ModalFormDeviceConnect;

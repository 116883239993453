// P as in Promise; not Predicate. Sorry about that.
import {AUTH_TOKEN} from "./services/redux/constants/authConstants"

export const jwt_xhrp = (method, url, data = undefined) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    console.debug("Dispatching request", method, url, " with JWT = ", jwtToken)
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr)
        }
        xhr.onerror = () => {
            reject()
        }
        console.debug("Calling XHR open")
        xhr.open(method, url)
        xhr.setRequestHeader("Authorization", "JWT " + jwtToken)
        console.debug("Calling XHR send")
        xhr.send(data)
    })
}


export const xhr_dejson = (xhr) => {
    return JSON.parse(xhr.response)
}

export const jwt_xhrp2 = (method, url, authorizeOptions = false, data = undefined) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    console.debug("Dispatching request", method, url, " with JWT = ", jwtToken)
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr)
        }
        xhr.onerror = () => {
            reject()
        }
        console.debug("Calling XHR open")
        xhr.open(method, url)
        if (authorizeOptions || (method !== 'OPTIONS')) {
            console.debug("Adding request header")
            xhr.setRequestHeader("Authorization", "JWT " + jwtToken)
        } else {
            console.debug("Ignoring request header")
        }
        console.debug("Calling XHR send")
        xhr.send(data)
    })
}

 export const getAlarmNames = (alarmType) => {
    switch (alarmType) {
        case 'FUNCTION':
            return ALARM_NAMES_FUNCTION;
        case 'SECURITY':
            return ALARM_NAMES_SECURITY;
        case 'QUALITY':
            return ALARM_NAMES_QUALITY;
        case 'GEO':
            return ALARM_NAMES_GEO;
        default:
            return null;
    }
}

export const ALARM_TYPES = {
    FUNCTION: 'Funktionslarm',
    SECURITY: 'Säkerhetslarm',
    QUALITY: 'Kvalitetslarm',
    GEO: "Geolarm"
}

export const ALARM_NAMES = {
    NO_DRIVER: 'Förarlös resa',
    UNPLUG: 'Enhet urkopplad',
    BATTERY_SUB_20: 'Låg batterispänning',
    DRIVING_SCHEDULE: 'Får ej köras utanför schema',
    MISSING_TRIPS: 'Resor ej rapporterade på X dagar',
    INTERMITTENCE: 'Tappad kontakt',
    JAMMING: 'Störd signal (jamming)',
    CRASH: 'Incident',
    TOWING: 'Bärgning',
    ODO_DIFF: 'Odometer-diff skillnad mellan stopp och start',
    ODO_REMIND: 'Påminnelse Odo',
    ON_WAY: '"På väg" under lång tid',
    GEO_ZONE: 'Geofence',
}

// Här kan du ta bort larm som inte ska visas genom att skriva "//"
//  tex " // CRASH: 'Incident', "

export const ALARM_NAMES_SECURITY = {
    JAMMING: 'Störd signal (jamming)',
    CRASH: 'Incident',
    // TOWING: 'Bärgning',
}

export const ALARM_NAMES_QUALITY = {
//     ODO_DIFF: 'Odometer-diff skillnad mellan stopp och start',
//     ODO_REMIND: 'Påminnelse Odo',
    ON_WAY: '"På väg" under lång tid',
}

export const ALARM_NAMES_FUNCTION = {
    NO_DRIVER: 'Förarlös resa',
    UNPLUG: 'Enhet urkopplad',
    BATTERY_SUB_20: 'Låg batterispänning',
    // ODO_REMIND: 'Påminnelse att uppdatera odometer',
    // DRIVING_SCHEDULE: 'Får ej köras utanför schema',
    MISSING_TRIPS: 'Resor ej rapporterade på X dagar',
    INTERMITTENCE: 'Tappad kontakt',
    // FERRY_TRIP: 'Resa med färja',
}


export const ALARM_NAMES_GEO = {
    GEO_ZONE: 'Geofence',
}

export const ALARM_PRIO = {
    CRITICAL: 'KRITISK',
    HIGH: 'HÖG',
    MEDIUM: 'MEDEL',
    LOW: 'LÅG',
}

export const ALARM_TEXT = {
    alarmName: 'Kategori',
    organizationId: 'Organisation',
    alarmPrio: 'Prio',
    name: 'Alias',
    emails: 'Välj mottagare',
    targetVehicleIds: 'Välj fordon',
    schema: 'Schema',
    repeatAfterHours: 'Upprepa',
    daysMissingTrips: 'Notifikation',
    notifyWhenAcknowledged: 'Kvittering av larm',
    hoursMissingContact: 'Notifikation',
    odoRemindDay: 'Upprepa',
    isTracker: 'Fordon/utrustning',


}

export const alarmShowFields = {
    NO_DRIVER:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    ODO_DIFF:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    ON_WAY:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    TOWING:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    BATTERY_SUB_20:
        {
            alarmName: true,
            organizationId: true,
            isTracker: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            lowBatteryLevel: true,

        },
    UNPLUG:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    ODO_REMIND:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            odoRemindDay: true,
            isTracker: false

        },
    DRIVING_SCHEDULE:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: true,
            isTracker: false

        },
    MISSING_TRIPS:
        {
            alarmName: true,
            organizationId: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: true,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,
            isTracker: false

        },
    INTERMITTENCE:
        {
            alarmName: true,
            organizationId: true,
            isTracker: true,
            alarmPrio: false,
            name: true,
            hoursMissingContact: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,

        },
    CRASH:
        {
            alarmName: true,
            organizationId: true,
            isTracker: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            notifyWhenAcknowledged: true,
            targetVehicleIds: true,
            emails: true,
            schema: false,

        },
    JAMMING:
        {
            alarmName: true,
            organizationId: true,
            isTracker: true,
            alarmPrio: false,
            name: true,
            daysMissingTrips: false,
            repeatAfterHours: true,
            targetVehicleIds: true,
            notifyWhenAcknowledged: true,
            emails: true,
            schema: false,

        }
}

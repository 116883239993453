import React from 'react'
import {connect} from 'react-redux';
import {NAV_TYPE_TOP} from 'constants/ThemeConstant';
import utils from 'utils'
import MenuContent from './MenuContent'
import {RestrictedMapOrganizationsProvider} from "../../services/contexts/contexts";

export const TopNav = ({topNavColor, localization = true}) => {
    const props = {topNavColor, localization}
    return (
        <div className={`top-nav ${utils.getColorContrast(topNavColor)}`} style={{backgroundColor: topNavColor}}>
            <div className="top-nav-wrapper">
                <RestrictedMapOrganizationsProvider>
                    <MenuContent
                        type={NAV_TYPE_TOP}
                        {...props}
                    />
                </RestrictedMapOrganizationsProvider>
            </div>
        </div>
    )
}

const mapStateToProps = ({theme}) => {
    const {topNavColor} = theme;
    return {topNavColor}
};

export default connect(mapStateToProps)(TopNav);

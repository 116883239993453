import React, { useState, useEffect, useRef } from 'react';
import { Button, Checkbox, Form, Modal, Table, Row, Divider } from 'antd';
import * as icons from '@ant-design/icons';
import { TEMP_LOGG } from './mock-device-data';
import Text from 'antd/lib/typography/Text';
import Col from 'antd/es/grid/col';
import { TwoRowsTitleSubTitle } from './reusableComponents';
import {
  SD_MODAL_AVL_LOG_TITLES,
  SUPER_DEVICES_MODAL_TITLES,
  STATIC_TEXT
} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_MODAL_AVL_LOG_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";

// LOGG ? NOT STARTED_JUST_TEMPLATE
const ModalFormDeviceLogg = ({ visible, handleClose, selectedDevice }) => {
  // const prevVisibleRef = useRef();
  const [, forceUpdate] = useState({});
  const [form] = Form.useForm();

  const imei = selectedDevice.device.imei ? selectedDevice.device.imei : '-';

  useEffect(() => {
    forceUpdate({});
  }, []);

  const formItemLayout = {
    layout: 'vertical',
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const onOk = () => {};

  return (
    <Modal
      title={SUPER_DEVICES_MODAL_TITLES.LOGG}
      visible={visible}
      onOk={onOk}
      onCancel={handleClose()}
      footer={[
        <Button key='submit' type='primary' onClick={handleClose()}>
          {STATIC_TEXT.BTN_CLOSE}
        </Button>,
      ]}
    >
      <Form form={form} {...formItemLayout} onFinish={() => {}} name='device_info_form'>
        <Row gutter={[0, 0]}>
          <Col span={24}>
            <TwoRowsTitleSubTitle title={STATIC_TEXT.IMEI} text={imei} />
          </Col>
        </Row>

        <Divider dashed />

        <Row gutter={[0, 20]}>
          <TableLogg logg={TEMP_LOGG} />
        </Row>

        <Row gutter={[0, 20]} justify='end'>
          <ButtonDownload handleDownloadClick={() => {}} />
        </Row>
      </Form>
    </Modal>
  );
};

const ButtonDownload = ({ handleDownloadClick }) => {
  return (
    <Col span={8}>
      <Button type='secondary' onClick={handleDownloadClick}>
        {STATIC_TEXT.BTN_DOWNLOAD_SEL}
      </Button>
    </Col>
  );
};

const TableLogg = ({ logg }) => {
  const title = () => 'Logg';
  const pagination = { position: 'top' };
  const pageSizeValue = [8];

  const tableState = useState({
    bordered: false,
    loading: false,
    pagination,
    size: 'small',
    title: false,
    showHeader: true,
    rowSelection: false,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: 'none',
    bottom: 'bottomRight',
  });
  let row = {};

  const dataSource = logg.map((item) => {
    row = {
      date: item.date ? item.date : 'YYYY-MM-DD',
      tsFromMs: item.tsFromMs ? item.tsFromMs : 'From YYYY-MM-DD',
      tsToMs: item.tsToMs ? item.tsToMs : 'To YYYY-MM-DD',
    };

    return row;
  });

  const columns = [
    //LOCAL_KEY =>
    {
      key: 'download',
      dataIndex: 'download',
      title: <icons.DownloadOutlined style={{ padding: 5 }} />,
      //sorter: (a, b) => a.value - b.value,
      render: () => {
        return <Checkbox></Checkbox>;
      },
    },
    //DATE
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE,
      title: SD_MODAL_AVL_LOG_TITLES.DATE,
      sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <Text>{value}</Text> : <Text> {'-'} </Text>;
      },
    },
    //DATE_FROM
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_FROM,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_FROM,
      title: SD_MODAL_AVL_LOG_TITLES.DATE_FROM,
      // sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <Text>{value}</Text> : <Text> {'-'} </Text>;
      },
    },
    //DATE_TO
    {
      key: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_TO,
      dataIndex: SD_MODAL_AVL_LOG_KEYS_AND_INDEXES.DATE_TO,
      title: SD_MODAL_AVL_LOG_TITLES.DATE_TO,
      //sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <Text>{value}</Text> : <Text> {'-'} </Text>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Col span={24}>
        <Table
          {...tableState[0]}
          dataSource={dataSource}
          columns={columns}
          pagination={{ position: ['topRight'], pageSize: pageSizeValue }}
        />
      </Col>
    </React.Fragment>
  );
};

export default ModalFormDeviceLogg;

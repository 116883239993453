import React, { useState } from "react";
import { Account } from "components/trackson-components/Account";
import { OrganizationContextProvider } from "services/contexts/OrganizationsContext";
import { UsersContextProvider } from "services/contexts/UsersContext";
import { AccountEditModal } from "components/trackson-components/Account/AccountEditModal";
const SuperAccountPage = () => {
    const initialAccountSettings = { show: false, account: null };
    const [changeAccountInfo, setChangeAccountInfo] = useState(initialAccountSettings);
    const [org, setOrg] = useState();
    const showAccountSettings = (account, currentUserOrg) => {
        setChangeAccountInfo({ show: true, account: account });
        setOrg(currentUserOrg);
    };
    const onCancel = () => {
        setChangeAccountInfo(initialAccountSettings);
    };
    return (
        <UsersContextProvider>
            <OrganizationContextProvider>
                <Account onEditAccount={(account, currentUserOrg) => showAccountSettings(account, currentUserOrg)} />
                <AccountEditModal show={changeAccountInfo.show} org={org} onCancel={onCancel} />
            </OrganizationContextProvider>
        </UsersContextProvider>
    );
};

export default SuperAccountPage;

import fetch from "auth/FetchInterceptor";
import React, {useContext, useEffect, useState} from "react";

import moment from "moment";
import {VehiclesContext} from "./VehiclesContext";
import {monthsLookUp} from "../../constants/MonthsLookUp";
import Userroles from "../../pages/UserListPage";
import {STATIC_TEXT, userRoles} from "../../lang/translate/sv-SE/sv-SE";
import {dataApi} from "../api/api";
import {EXCLUDED_ORG_IDS} from "../../configs/AppConfig";

const makeProvider = (initialState, makeComponent) => {
    const context = React.createContext(initialState);
    return [context, (props) => makeComponent({ context, ...props })];
};

const makeProviderClass = (initialState, makeComponent) => {
    const r = makeProvider(initialState, makeComponent);
    return {
        context: r[0],
        provider: r[1],
    };
};

const makeTrivialProvider = (endpoint, postprocess = null, showArchived ) => {
    return makeProvider({}, (props) => {
        const [data, setData] = useState(null);
        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
             showArchived = showArchived || urlParams.get('showArchived');
            const url = showArchived ? `${endpoint}?showArchived=true` : endpoint;

            // Use the updated URL with fetch.get
            let p = fetch.get(url).then((e) => {
                setData(e);
            });

            if (postprocess) p.then(postprocess);
        }, []);

        return <props.context.Provider value={data}>{props.children}</props.context.Provider>;
    });
};

export const OrganizationSimpleContext = React.createContext({
    organizationList: [],
});
export const OrganizationSimpleProvider = ({ ...props }) => {
    const [organizationList, setOrganizationList] = useState([]);

    function fetchSimplyOrganizations() {
        fetch
            .get("/organization-simple")
            .then((r) => {
                if (!r) return [];
                return r.map((k) => {
                    return {
                        // Legacy convert some old keys
                        ...k,
                        organization_id: k.organizationId,
                        full_name: k.fullName,
                    };
                });
            })
            .then(setOrganizationList);

    }

    useEffect(() => {
        fetchSimplyOrganizations();
    }, []);

    return (
        <OrganizationSimpleContext.Provider value={{organizationList, fetchSimplyOrganizations}}>
            {props.children}
        </OrganizationSimpleContext.Provider>
    );
};


export const [RestrictedMapOrganizationsContext, RestrictedMapOrganizationsProvider] = makeProvider({}, (props) => {
    const [excludedOrganizationList, setExcludedOrganizationList] = useState(EXCLUDED_ORG_IDS);
    useEffect(() => {
        fetch
            .get("/organization/no-map-view")
            .then((res) => {
                console.log(res)
                const orgIds = res?.orgIds ? res?.orgIds : []
                setExcludedOrganizationList(orgIds)
            })
    }, []);
    return <props.context.Provider value={excludedOrganizationList}>{props.children}</props.context.Provider>;
});

export const [UserListSimpleContext, UserListSimpleProvider] = makeTrivialProvider("/user/simple");
export const [UserListContext, UserListProvider] = makeTrivialProvider("/user", );
export const [UserListWithArchivedContext, UserListWithArchivedProvider] = makeTrivialProvider("/user", null, true );
export const [VehiclesListSimpleContext, VehiclesListSimpleProvider] = makeTrivialProvider("/vehicles/simple");
export const [VehiclesComplexListContext, VehiclesComplexListProvider] = makeTrivialProvider("/vehicles");
export const [VehiclesListWithArchivedContext, VehiclesListWithArchivedProvider] = makeTrivialProvider("/vehicles", null, true );
export const [DeviceListContext, DeviceListProvider] = makeTrivialProvider("/devices");
export const [OrganizationsComplexListContext, OrganizationsComplexListProvider] = makeTrivialProvider("/organization");
export const [AuthorizationContext, AuthorizationProvider] = makeTrivialProvider("/auth");

export const VehicleContext = React.createContext({
    idByReg: null,
});

export const VehicleProvider = ({ ...props }) => {
    const [raw, setRaw] = useState(null);
    const [index, setIndex] = useState({});
    const [idByReg, setIdByReg] = useState({});

    const getVehiclesSimple = async (excludeTrackers = true ) => {
        try {
            const devicesResponse = await fetch.get('/devices');
            let excludeDeviceIds = [];

                excludeDeviceIds = devicesResponse
                    .filter((d) => d.isTracker)
                    .map((d) => d.installedVehicle);

            const urlParams = new URLSearchParams(window.location.search);
            const showArchived = urlParams.get('showArchived');

            const vehiclesResponse = await fetch.get("/vehicles/simple"  + (showArchived ? "?showArchived=true" : ""));
            const filteredVehicles = vehiclesResponse.filter(
                (v) => excludeTrackers ? !excludeDeviceIds.includes(v.id) : excludeDeviceIds.includes(v.id)
            );

            return filteredVehicles;
        } catch (error) {
            console.error('Error ', error);
            return [];
        }
    };

    const getTrackers = () => {
        getVehiclesSimple(false).then(trackers => {
           return trackers
        })
    }

    useEffect(() => {
        getVehiclesSimple().then((vehicles) => {
            setRaw(vehicles);
            setIndex(
                Object.fromEntries(
                    vehicles.map((e) => {
                        return [e.id, e];
                    })
                )
            );
            setIdByReg(
                Object.fromEntries(
                    vehicles.map((e) => {
                        return [e.registry, e.id];
                    })
                )
            );
        });
    }, []);

    return (
        <VehicleContext.Provider value={{ idByReg, index, raw, getTrackers, getVehiclesSimple }}>
            {props.children}
        </VehicleContext.Provider>
    );
};

export const SelectDefaultVehicle = makeProviderClass(null, (props) => {
    const currentUser = useContext(AuthorizationContext);
    const knownVehicles = useContext(VehicleContext).raw;
    const [data, setData] = useState(null);

    // Assign initial selected vehicle
    useEffect(() => {

        if (!currentUser) return;
        if (!knownVehicles) return;
        let primaryCandidates = knownVehicles?.filter((v) => currentUser?.defaultVehicles[0] === v?.id);
        let checkedInCandidates = knownVehicles?.filter((v) => currentUser?.currentVehicle[0] === v?.id);
        let validCandidates = knownVehicles?.filter((v) => (currentUser?.permittedVehicles?.length > 0 ? currentUser?.permittedVehicles[0]?.id === v?.id : null));

        const bestCandidate = primaryCandidates.concat(checkedInCandidates).concat(validCandidates)[0];

        if (bestCandidate === undefined && knownVehicles?.length > 0) {
            setData(knownVehicles[0]?.id);
        } else {
            setData(bestCandidate?.id || undefined);
        }

    }, [currentUser, knownVehicles]);

    return <props.context.Provider value={data}>{props.children}</props.context.Provider>;
});

export const shouldHideUsersOrganizationsColumn = makeProviderClass(null, (props) => {
    const currentUser = useContext(AuthorizationContext);
    const [hideUsersOrganizations, setHideUsersOrganizations] = useState();

    useEffect(() => {
    setHideUsersOrganizations((currentUser?.organizations?.length < 2) && (currentUser?.roles?.includes(STATIC_TEXT.ROOT)));
    }, [currentUser]);

    return <props.context.Provider value={hideUsersOrganizations}>{props.children}</props.context.Provider>;
});

export const InitialDateAttest = makeProviderClass(null, (props) => {
    const [selectedPeriod, setSelectedPeriod] = useState();

    useEffect(() => {
        findInitialPeriod();
    }, []);

    const findInitialPeriod = () => {
        // Getting the current date and subtracting one month
        const previousMonthDate = moment().subtract(1, "month");

        // Extracting the month and year from the modified date
        const previousMonth = previousMonthDate.format("M");
        const year = previousMonthDate.format("YYYY");

        const stringPeriod = year + "/" + previousMonth;
        setSelectedPeriod(stringPeriod);
    };

    return <props.context.Provider value={selectedPeriod}>{props.children}</props.context.Provider>;
});

export const TripContext = React.createContext({
    state: null,
    dispatch: null,
});

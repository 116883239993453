export const styles = {
    emails: {
        width: "50%",
        paddingLeft: "10px",
    },
    targetVehicleIds: {
        paddingRight: "10px",
        width: "50%",
    },
    schema: {
        width: "100%",
    },
    daysMissingTrips: {},
    notifyWhenAcknowledged: {},
    lowBatteryLevel: {
        width: "30%",
    },
    selectDay: {
        width: 80,
        margin: 0,
    },
    interval: {
        display: "flex",
        gap: 15,
        alignItems: "center",
    },
};
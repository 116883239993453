import React, {useEffect, useState} from 'react';
import {Table, Input, Button, Menu, Typography, Form, Divider, message, Select} from 'antd';
import {dataApi, userApi} from "../../../services/api/api";
import {TableActionBtnType} from "../Superdevices/Superdevices";
import {PlusOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import Loading from "../../shared-components/Loading/Loading";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {FORM_MESSAGES, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {UsersSelect} from "../shared-components/UsersSelect";
import {ButtonMenuDropdown, tableProps} from "../shared-components/sharedComponents";
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import Flex from "../../shared-components/Flex";

const {Title} = Typography;

const styles = {
    addItem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20',
    },
}


const PurposeController = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [user, setUser] = useState();
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(true)
    const [knownOrganizations, setKnownOrganizations] = useState()
    const [knownUsers, setKnowUsers] = useState()
    const [selectedOrganization, setSelectedOrganization] = useState()
    const [filteredUsers, setFilteredUsers] = useState()
    const [showOrganizationSelector, setShowOrganizationSelector] = useState(false)

    useEffect(() => {

        userApi.getCurrentUser()
            .then(user => {
                console.log("userId", user)
                setUser(user.userId)
                if (user.roles?.includes("ROOT")) {
                    setShowOrganizationSelector(true)
                }

            })

        dataApi.getOrganizationsSimple()
            .then(r => {
                setKnownOrganizations(r)
            })

        dataApi.getUsersSimple()
            .then(i => {
                setKnowUsers(i)
                setFilteredUsers(i)
            })

    }, [])

    useEffect(() => {
        if (!user)
            return

        setLoading(true)
        dataApi.getUserById(user)
            .then(r => {
                let i = r[0]
                let userPurposes = i.purposes
                let nr = 0
                i = i[nr]
                const dataPurpose = userPurposes?.map(i => {
                    return {
                        key: nr++,
                        purposes: i
                    }
                })
                setData(dataPurpose)
                console.log('daaaata', dataPurpose)
                setLoading(false)
            })

    }, [user])

    useEffect(() => {
        let filteredList = knownUsers
        if (knownUsers?.length > 0 && selectedOrganization?.length > 0) {
            filteredList = filteredList?.filter(
                i => selectedOrganization?.includes(i.organizationId) || i.id === user)
        }
        setFilteredUsers(filteredList)
    }, [selectedOrganization])

    const isEditing = record => record.key === editingKey;

    const edit = record => {
        form.setFieldsValue({...record});
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const handleDelete = key => {
        const newData = data?.filter(item => item.key !== key);
        setData(newData)
        console.log(key)

        handleApiCall(newData)
    };

    function handleApiCall(newData) {

        const purposes = []
        newData?.map(i => {
            purposes.push(i.purposes)
        })

        dataApi.patchUser(user, {purposes: purposes})
            .then(_ => {
                message.success(STATIC_TEXT.UPDATE_PURPOSE)
            })
    }

    const save = async key => {
        try {
            let row = await form.validateFields();

            const newData = [...data];
            console.log(newData)
            const index = newData.findIndex(item => key === item.key);

            row = {purposes: row.purposes || row.new_purposes}

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                setEditingKey('');
            } else {
                console.log(row)
                row = {key: newData.length, ...row}
                newData.push(row);
                setData(newData.sort((a, b) => a.purposes.localeCompare(b.purposes)));
                setEditingKey('');
            }

            form?.resetFields()
            handleApiCall(newData)

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
    };

    const columns = [
        {
            title: STATIC_TEXT.DESCRIPTION,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.purposes,
            editable: true,
        },
        {
            dataIndex: 'operation',
            align: "right",
            render: (text, record) => {
                return <Button
                    danger={true}
                    icon={<DeleteOutlined/>}
                    onClick={() => handleDelete(record.key)}
                    type={TableActionBtnType}
                    >
                    {STATIC_TEXT.DELETE}
                </Button>

            },
        },
    ];

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => edit(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => handleDelete(record.key)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }

    const components = {
        body: {
            cell: EditableCell,
        },
    };
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        loading ? <Loading/> :
            <div>
                <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20, flexWrap: "wrap"}}>
                    <Title>{STATIC_TEXT.DESCRIPTIONS}</Title>
                    {!showOrganizationSelector ? null :
                        <div style={{display: 'flex', marginBottom: 20, gap: 15, flexWrap: "wrap"}}>
                            {knownOrganizations?.length < 2 ? null :
                                <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                         selectedOrganization={selectedOrganization} isMultiple={true}/>
                            }

                            <UsersSelect
                                data={filteredUsers}
                                value={user}
                                onChange={(e) => setUser(e)}
                            />

                        </div>}
                </div>


                <Divider/>
                <Form form={form} component={false}>
                    <AddNewPurpose save={save}/>
                    <Title level={4}>{STATIC_TEXT.SAVED_PURPOSE}</Title>
                    <Table
                        components={components}
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                        {...tableProps}
                    />
                </Form>
            </div>
    );
};

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {

    const inputNode = (() => {
        switch (dataIndex) {
            case "s":
                return <Input
                />
                break
            default :
                return <Input/>
        }
    })()

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


const AddNewPurpose = ({save}) => {

    return <>
        <Title level={4}>{STATIC_TEXT.ADD_NEW_PURPOSE}</Title>
        <div style={styles.addItem}>
            <Form.Item
                name={SUPER_DEVICES_KEYS_AND_INDEXES.new_purposes}
                style={{
                    marginRight: 20,
                    width: '70%'
                }}
                rules={[
                    {
                        required: false,
                        message: FORM_MESSAGES.PURPOSE,
                    },
                ]}
            >
                <Input
                    placeholder={STATIC_TEXT.SUPPLY_PURPOSE}
                />
            </Form.Item>
            <Button
                type={'primary'}
                onClick={() => save(-1)}
                icon={<PlusOutlined/>}>
                {STATIC_TEXT.ADD}
            </Button>
        </div>
    </>

}

export default PurposeController;

import Sweden_road_sign from "../../assets/svg/Sweden_road_sign_E25.svg";
import {TripLogbookContext} from "../../contexts/TripLogbookContext"
import Flex from "../shared-components/Flex/Flex";
import {MarkerStartLocation, MarkerStopLocation} from "../shared-components/Marker/Marker";
import {GOOGLE_MAPS_API} from "../../configs/AppConfig";
import {darkMapStyle, mapOptions, setLightMapType} from "../../configs/mapStyle";
import {MAP_TYPE} from "../../services/redux/constants/authConstants";
import {dataApi} from "../../services/api/api";
import {convertTimestampToDateStr, extractedDate} from "../../utilities";
import {RollbackOutlined} from "@ant-design/icons";

// import "./JourneyPageStyles.css";
import {useDispatch} from "react-redux";

import GoogleMapReact from "google-map-react";
import React, {useContext, useRef, useState, useEffect} from "react";
import {
    Tooltip,
    Modal,
    Typography,
    Form,
    ConfigProvider,
    Table,
    Input,
    Button,
    Card,
    Spin,
    Drawer,
    Collapse
} from "antd";
import {bijectPutGetFields} from "../../pages/JourneyPage";
import Spinner from "./shared-components/Spinner";
import {STATIC_TEXT, tripTypes} from "../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../constants/CustomConstant";
import {colorTripType} from "../../utils/utilities";
import {customizeRenderEmpty} from "./shared-components/customizeRenderEmpty";

const EditableCell = ({editing, dataIndex, title, inputType, record, index, children, ...restProps}) => {
    const inputNode = <Input/>;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const EditableTableRutter = ({data, setData, selectedJourney, taxType, infra}) => {
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record) => record.passageId === editingKey;

    const edit = (record) => {
        form.setFieldsValue({...record});
        setEditingKey(record.passageId);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const editPassage = async (id) => {
        const overridePrice = await form.validateFields();
        const req = {overridePrice: overridePrice.overridePrice};
        const reqInfra = {overridePriceInfra: overridePrice.overridePrice};
        const request = infra ? reqInfra : req;
        save(id, request);
    };

    const deleteStation = (id) => {
        const req = {isActive: false};

        save(id, req);
    };

    const save = async (key, req) => {
        try {
            const row = req || (await form.validateFields());
            let coords = [];
            let passages = [];
            let congestionTaxSek = 0;
            let congestionTaxCount = 0;
            let congestionTaxSekOverride = 0;
            let infraTaxSek = 0;
            let infraTaxCount = 0;
            let infraTaxSekOverride = 0;
            const index = data?.congestionData?.passages?.findIndex((item) => key === item.passageId);

            if (index > -1) {
                dataApi.patchJournalPassage(key, req).then((res) => {
                    res[0]?.map((trip) => {
                        if (trip?.trip?.coords) {
                            coords = [...coords, ...trip?.trip?.coords];
                        }
                        if (trip?.congestionData?.passages) {
                            passages = [...passages, ...trip?.congestionData?.passages];
                        }
                        congestionTaxSek = trip?.congestionTaxSek ? parseInt(trip?.congestionTaxSek) + congestionTaxSek : congestionTaxSek;
                        congestionTaxCount = trip?.congestionTaxCount + congestionTaxCount;
                        if (trip?.congestionTaxSekOverride !== null) congestionTaxSekOverride = parseInt(trip?.congestionTaxSekOverride) + (congestionTaxSekOverride || 0);
                        infraTaxSek = trip?.infraTaxSek ? parseInt(trip?.infraTaxSek) + infraTaxSek : infraTaxSek;
                        infraTaxCount = trip?.infraTaxCount + infraTaxCount;
                        if (trip?.infraTaxSekOverride !== null) infraTaxSekOverride = parseInt(trip?.infraTaxSekOverride) + (infraTaxSekOverride || 0);
                    });

                    const newData = {
                        ...data,
                        trip: {
                            ...data?.trip,
                            coords: coords,
                        },
                        congestionTaxSek: congestionTaxSek,
                        congestionTaxCount: congestionTaxCount,
                        congestionTaxSekOverride: congestionTaxSekOverride !== null ? congestionTaxSekOverride : null,
                        infraTaxSek: infraTaxSek,
                        infraTaxCount: infraTaxCount,
                        infraTaxSekOverride: infraTaxSekOverride !== null ? infraTaxSekOverride : null,
                        congestionData: {
                            passages: passages,
                        },
                    };

                    setData(newData);
                    setEditingKey("");
                });
            } else {
            }
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columns = [
        {
            title: "Station",
            dataIndex: "station",
            key: "station",
            render: (text) => {
                return text?.name;
            },
        },
        {
            title: "Tidpunkt",
            dataIndex: "isotime",
            key: "isotime",
            render: (text) => {
                return convertTimestampToDateStr(text);
            },
        },
        {
            title: "Kostnad",
            dataIndex: "overridePrice",
            key: "overridePrice",
            editable: true,
            render: (text, record) => {
                let override = "overridePrice"
                if (infra) {
                    override = "overridePriceInfra"
                }
                const price = record[override] !== null ? record[override] : record[taxType] || 0;
                return price + " kr";
            },
        },
        {
            title: "Typ",
            dataIndex: "address",
            key: "address",
            render: (text, record) => {
                const type = tripTypes[selectedJourney?.data?.trip?.purpose];
                const color = colorTripType(selectedJourney?.data?.trip?.purpose);
                return (
                    <Typography.Text strong style={color}>
                        {type}
                    </Typography.Text>
                );
            },
        },
        {
            dataIndex: "operation",
            render: (text, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            href="javascript:;"
                            onClick={() => editPassage(record.passageId)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            {STATIC_TEXT.SAVE}
                        </a>
                        <a onClick={() => cancel(record.passageId)}>{STATIC_TEXT.BTN_ABORT}</a>
                    </span>
                ) : (
                    <span>
                        <a disabled={editingKey !== ""} onClick={() => edit(record)}>
                            {STATIC_TEXT.BTN_CHANGE}
                        </a>
                    </span>
                );
            },
        },
    ];
    const components = {
        body: {
            cell: EditableCell,
        },
    };
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "age" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    let tableData = data?.congestionData?.passages?.filter(item => taxType === "priceInfra" ? item[taxType] : item?.priceInfra <= 0);
    return (
        <Form form={form} component={false}>
            <ConfigProvider
                renderEmpty={() => customizeRenderEmpty("Inga registrerade passager")}>
                <Table
                    rowKey={(i) => i.passageId}
                    components={components}
                    dataSource={tableData}
                    columns={mergedColumns}
                    rowClassName={(record) => record?.isActive === false && "is-not-active-row"}
                    pagination={false}
                />
            </ConfigProvider>
        </Form>
    );
};

function TableTax({dataTax, selectedJourney, data, setData, setHeaderCongestionTax}) {
    let [totalTax, setTotalTax] = useState(0);
    let [passages, setPassages] = useState(0);
    let isOverride = false

    useEffect(() => {
        if (!data) return
        const summa = data?.congestionTaxSekOverride !== null ? data?.congestionTaxSekOverride : data?.congestionTaxSek || 0;
        setTotalTax(summa?.toString());
        const passages = data?.congestionData?.passages?.map(item => item).filter(item => item?.priceInfra <= 0).length || 0
        setPassages(passages)
        isOverride = data?.congestionTaxSekOverride !== null
    }, [data, selectedJourney]);

    useEffect(() => {
        setHeaderCongestionTax(`Trängselskatt - ${totalTax} kr (${passages} ${passages === 1 ? 'passage' : 'passager'})`);
    }, [totalTax, passages])

    const onChange = (tax) => {
        tax = parseInt(tax)
        dataApi
            .putJournal(selectedJourney.data.journal_id, {congestionTaxSekOverride: tax})
            .then((res) => {
                const newData = {
                    ...data,
                    congestionTaxSekOverride: tax,
                };

                setData(newData);
                setTotalTax(tax);

            })
            .catch((e) => {
            });
    };
    const restorePassages = () => {
        let passages = [];
        let congestionTaxSek = 0;
        let congestionTaxCount = 0;
        let congestionTaxSekOverride = null;
        dataApi.putJournalRestorePassages(selectedJourney.data.journal_id, false, true).then((res) => {
            res[0]?.map((trip) => {
                if (trip?.congestionData?.passages) {
                    passages = [...passages, ...trip?.congestionData?.passages];
                }
                congestionTaxSek = trip?.congestionTaxSek ? parseInt(trip?.congestionTaxSek) + congestionTaxSek : congestionTaxSek;
                congestionTaxCount = trip?.congestionTaxCount + congestionTaxCount;
                if (trip?.congestionTaxSekOverride !== null) congestionTaxSekOverride = parseInt(trip?.congestionTaxSekOverride) + (congestionTaxSekOverride || 0);
            });

            const newData = {
                ...data,
                congestionTaxSek: congestionTaxSek,
                congestionTaxCount: congestionTaxCount,
                congestionTaxSekOverride: congestionTaxSekOverride !== null ? congestionTaxSekOverride : null,
                congestionData: {
                    passages: passages,
                },
            };

            console.log(newData)
            setData(newData);
        });
    };

    let isDisabled = data?.status === "DONE" || data?.status === "LOCKED" || data?.status === "CHECKED"
    let showRestoreButton = data && (data?.congestionTaxSekOverride !== null) || data?.congestionData?.passages?.some(p => p.overridePrice)

    return (
        <div style={{minHeight: 200, pointerEvents: isDisabled ? "none" : "auto"}}>
            <br/>

            <Flex alignItems={"center"} justifyContent={"end"}>
                {showRestoreButton &&
                    <Button style={{marginBottom: 10, float: "right"}} icon={<RollbackOutlined/>}
                            onClick={restorePassages}>
                        Återställ
                    </Button>
                }
            </Flex>

            <EditableTableRutter data={data} setData={setData} selectedJourney={selectedJourney} taxType={"price"}/>

            <br/>
            <Flex alignItems={"baseline"} justifyContent={"end"}>
                <Typography.Title type="danger" level={2}>
                    Total kr:{" "}
                </Typography.Title>
                {data?.congestionTaxSekOverride !== null &&
                    <Typography.Paragraph
                        style={{
                            textDecoration: "line-through",
                            color: SD_BASE_COLORS.RED,
                            fontSize: 26,
                            marginLeft: 10
                        }}
                    >
                        {(data?.congestionTaxSek || "0") + " "}
                    </Typography.Paragraph>
                }
                <Typography.Paragraph style={{color: SD_BASE_COLORS.RED, fontSize: 26, marginLeft: 10}}
                                      editable={{onChange: onChange}}>
                    {totalTax}
                </Typography.Paragraph>
            </Flex>
        </div>
    );
}

function TableInfraTax({dataTax, selectedJourney, data, setData, setHeaderInfraTax}) {
    let [totalTax, setTotalTax] = useState(0);
    let [passages, setPassages] = useState(0);
    let isOverride = false;

    useEffect(() => {
        if (!data) return
        const summa = data?.infraTaxSekOverride !== null ? data?.infraTaxSekOverride : data?.infraTaxSek || 0;
        setTotalTax(summa?.toString());
        const passages = data?.congestionData?.passages?.map(item => item).filter(item => item?.priceInfra > 0).length || 0
        setPassages(passages)
        isOverride = data?.infraTaxSekOverride !== null
    }, [data, selectedJourney]);

    useEffect(() => {
        setHeaderInfraTax(`Infrastrukturavgift - ${totalTax} kr (${passages} ${passages === 1 ? 'passage' : 'passager'})`)
    }, [totalTax, passages])

    const onChange = (tax) => {
        tax = parseInt(tax)
        setTotalTax(tax);
        dataApi
            .putJournal(selectedJourney.data.journal_id, {infraTaxSekOverride: tax})
            .then((res) => {
                const newData = {
                    ...data,
                    infraTaxSekOverride: tax,
                };

                setData(newData);
            })
            .catch((e) => {
            });
    };
    const restorePassages = () => {
        let passages = [];
        let infraTaxSek = 0;
        let infraTaxCount = 0;
        let infraTaxSekOverride = null;
        dataApi.putJournalRestorePassages(selectedJourney.data.journal_id, true, false).then((res) => {
            res[0]?.map((trip) => {
                if (trip?.congestionData?.passages) {
                    passages = [...passages, ...trip?.congestionData?.passages];
                }
                infraTaxSek = trip?.infraTaxSek ? parseInt(trip?.infraTaxSek) + infraTaxSek : infraTaxSek;
                infraTaxCount = trip?.infraTaxCount + infraTaxCount;
                if (trip?.infraTaxSekOverride !== null) infraTaxSekOverride = parseInt(trip?.infraTaxSekOverride) + (infraTaxSekOverride || 0);
            });

            const newData = {
                ...data,
                infraTaxSek: infraTaxSek,
                infraTaxCount: infraTaxCount,
                infraTaxSekOverride: infraTaxSekOverride !== null ? infraTaxSekOverride : null,
                congestionData: {
                    passages: passages,
                },
            };

            console.log(newData)
            setData(newData);
        });
    };

    let isDisabled = data?.status === "DONE" || data?.status === "LOCKED" || data?.status === "CHECKED"
    // show button when tax is override
    let showRestoreButton = data && (data?.infraTaxSekOverride !== null) || data?.congestionData?.passages?.some(p => p.overridePriceInfra)
    return (
        <div style={{pointerEvents: isDisabled ? "none" : "auto"}}>
            <br/>

            <Flex alignItems={"center"} justifyContent={"end"}>
                {showRestoreButton &&
                    <Button style={{marginBottom: 10, float: "right"}} icon={<RollbackOutlined/>}
                            onClick={restorePassages}>
                        Återställ
                    </Button>}
            </Flex>

            <EditableTableRutter data={data} setData={setData} selectedJourney={selectedJourney}
                                 taxType={"priceInfra"}
                                 infra={true}/>

            <br/>
            <Flex alignItems={"baseline"} justifyContent={"end"}>
                <Typography.Title type="danger" level={2}>
                    Total kr:{" "}
                </Typography.Title>

                {data?.infraTaxSekOverride !== null &&
                    <Typography.Paragraph
                        style={{
                            textDecoration: "line-through",
                            color: SD_BASE_COLORS.RED,
                            fontSize: 26,
                            marginLeft: 10
                        }}
                    >
                        {(data?.infraTaxSek || 0) + " "}
                    </Typography.Paragraph>
                }

                <Typography.Paragraph style={{color: SD_BASE_COLORS.RED, fontSize: 26, marginLeft: 10}}
                                      editable={{onChange: onChange}}>
                    {totalTax}
                </Typography.Paragraph>
            </Flex>
        </div>
    );
}

// TODO is this a good idea?
// can we make it warn (pref. at build time) if its not Provided during the hierarchy?

/*
const ShowRouteModal = injectContext([TripLogbookContext],
 ([logbook], { selectedJourney, visible, onOk, onCancel, dataTax, clickedButton }) => {
})
 */
export const ShowRouteModal = ({selectedJourney, visible, onOk, onCancel, dataTax, clickedButton, data, setData, ...props}) => {
    const mapRef = useRef(null);
    const [center, setCenter] = useState([0, 0]);
    const [zoom, setZoom] = useState();
    const [map, setMap] = useState();
    const [maps, setMaps] = useState();
    const [randomKey, setRandomKey] = useState();
    // const dispatch = useDispatch();
    const logbook = useContext(TripLogbookContext)

    const [routePolyline, setRoutePolyline] = useState(null);
    const [bounds, setBounds] = useState(null);

    useEffect(() => {
        setRandomKey(Math.random())
    }, [visible]);

    useEffect(() => {
        setData(selectedJourney?.data);
    }, [selectedJourney]);

    // When user map style preference changes, update map
    useEffect(() => {
        if (!map || !maps) {
            return;
        }

        maps?.event.trigger(map, "resize");
        //noinspection JSUnresolvedFunction
        map?.setOptions({...mapOptions, styles: localStorage.getItem(MAP_TYPE) === "DARK" ? darkMapStyle : null});
        setLightMapType(maps, map);
    }, [localStorage.getItem(MAP_TYPE), map, maps]);

    const updateRoutePolyline = ({map, maps, coords, selectedJourney}) => {
        let newCoords = [];
        selectedJourney?.data.trip.coords.map((i) => {
            newCoords = [
                ...newCoords,
                {
                    lat: i.latitude,
                    lng: i.longitude,
                },
            ];
        });
        if (routePolyline) {
            //noinspection JSUnresolvedFunction
            routePolyline.setMap(null);
        }

        const newRoutePolyline = new maps.Polyline({
            path: newCoords,
            geodesic: true,
            strokeColor: selectedJourney?.data?.trip?.purpose === "PRIVATE" ? SD_BASE_COLORS.GREEN : SD_BASE_COLORS.SWITCH_BUSINESS,
            strokeOpacity: 1.0,
            strokeWeight: 5,
        });

        let boundsCoords = selectedJourney?.data.trip.coords;

        //noinspection JSUnresolvedFunction
        const getBounds = new maps.LatLngBounds();
        boundsCoords.forEach((child) => {
            //noinspection JSUnresolvedVariable,JSUnresolvedFunction
            getBounds.extend(new window.google.maps.LatLng(child.latitude, child.longitude));
        });

        // map.fitBounds(getBounds);

        //noinspection JSUnresolvedFunction
        newRoutePolyline.setMap(map);
        setBounds(getBounds);
        setRoutePolyline(newRoutePolyline);
    };

    const createBoundsFromTripData = (maps, data) => {
        //noinspection JSUnresolvedFunction
        const newBounds = new maps.LatLngBounds();

        data.forEach((d) => {
            const lat = d?.latitude;
            const lng = d?.longitude;

            if (lat && lng) {
                // This is valid coordinate
                //noinspection JSUnresolvedFunction
                const newCoordinate = new maps.LatLng(lat, lng);
                newBounds.extend(newCoordinate);
            } else {
                // This is an invalid coordinate, don't add it to bounds
            }
        });
        setBounds(newBounds);
        return newBounds;
    };

    // When selected journeyNew changes, reset zoom and update bounds
    useEffect(() => {
        setZoom(12);

        if (map && maps && selectedJourney) {
            const bound = createBoundsFromTripData(maps, selectedJourney?.data?.trip?.coords);
            setBounds(bound);
        }

        if (!(map && maps && selectedJourney)) return;

        updateRoutePolyline({map, maps, selectedJourney});
    }, [map, maps, selectedJourney]);

    // Fit bounds to map when state changes
    useEffect(() => {
        //noinspection JSUnresolvedFunction
        map?.fitBounds(bounds, {top: 80, right: 80, left: 80, bottom: 80});
    }, [bounds]);

    const handlePanelChange = (key) => {
        setPanelVisibility(prevState => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const zoomOnMarker = (value) => {
        const index = value ? 0 : selectedJourney?.data?.trip?.coords?.length - 1;
        const newCenter = {
            lat: selectedJourney?.data.trip.coords[index].latitude,
            lng: selectedJourney?.data.trip.coords[index].longitude,
        };
        setCenter([newCenter.lat, newCenter.lng]);
        setZoom(value ? 20 : 19);
    };

    const onCancelModal = () => {
        onCancel();
        return
    };

    const [headerInfraTax, setHeaderInfraTax] = useState("");
    const [headerCongestionTax, setHeaderCongestionTax] = useState("");
    let tripsInfoStop = convertTimestampToDateStr(selectedJourney?.data?.trip?.timestamp_stop_ms, "HH:mm", false)
        + " " + (selectedJourney?.data?.override_stop || selectedJourney?.data?.geocode_stop?.format);
    let tripsInfoStart = convertTimestampToDateStr(selectedJourney?.data?.trip?.timestamp_start_ms, "HH:mm", false)
        + " " + (selectedJourney?.data?.override_start || selectedJourney?.data?.geocode_start?.format);
    return (
        <Card
            title="Rutt"
            placement="right"
            closable={true}
            onClose={onCancelModal}
            visible={visible}
            width={800}
            style={{
                width: visible ? props.width : 0,
                margin: 0,
                overflow: 'hidden',
            }}
            mask={false}
            extra={<Button onClick={onCancelModal}>Stäng</Button>}
            loading={props.loading}
        >
            <Spin spinning={props.loading}>
                <div style={{
                    maxHeight: `calc(100vh - ${props.divHeight + 200}px)`,
                    overflow: 'auto'
                }}>

                    <span><b>{extractedDate(selectedJourney?.data?.trip?.timestamp_start_ms)}</b></span>
                    <p>{tripsInfoStop}<br/>{tripsInfoStart}</p>

                    <div style={{position: 'relative', width: '100%', height: '400px',}}>

                        <GoogleMapReact
                            ref={mapRef}
                            bounds={selectedJourney?.data.trip.coords}
                            style={{height: 400, transform: "translate(0, 0px)"}}
                            bootstrapURLKeys={{key: GOOGLE_MAPS_API}}
                            center={center}
                            zoom={zoom}
                            options={mapOptions}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({map, maps}) => {
                                setLightMapType(maps, map);
                                setMap(map);
                                setMaps(maps);
                            }}
                        >
                            <MarkerStartLocation
                                lat={selectedJourney?.data.trip.coords[0].latitude}
                                lng={selectedJourney?.data.trip.coords[0].longitude}
                                text={selectedJourney?.data?.geocode_start?.format}
                                onClick={(e) => zoomOnMarker("start")}
                            />
                            <MarkerStopLocation
                                lat={selectedJourney?.data.trip.coords[selectedJourney?.data.trip.coords.length - 1].latitude}
                                lng={selectedJourney?.data.trip.coords[selectedJourney?.data.trip.coords.length - 1].longitude}
                                text={selectedJourney?.data?.geocode_stop?.format}
                                onClick={(e) => zoomOnMarker()}
                            />

                            {selectedJourney?.data?.congestionData?.passages?.map((pay) => {
                                return (
                                    <div
                                        style={{
                                            width: 8,
                                            height: 8,
                                            position: "absolute",
                                            borderRadius: 8 / 2,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        lat={pay?.station?.geometry?.coordinates[1]}
                                        lng={pay?.station?.geometry?.coordinates[0]}
                                    >
                                        <Tooltip title={pay?.station?.name}>
                                            <img alt="Sweden road sign E25" width={28} src={Sweden_road_sign}/>
                                        </Tooltip>
                                    </div>
                                );
                            })}
                        </GoogleMapReact>

                        {data?.overrideTsMs && <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '20px',
                            zIndex: 2,
                            padding: 30
                        }}>
                            Kartan är inte tillgänglig på grund av manuell ändring av resan
                        </div>}
                    </div>

                    <Collapse style={{marginTop: 20}}>
                        <Collapse.Panel header={headerCongestionTax} key={data?.journal_id + "1" + randomKey}
                                        forceRender={true}
                        >
                            <TableTax data={data} setData={setData} selectedJourney={selectedJourney} dataTax={dataTax}
                                      setHeaderCongestionTax={setHeaderCongestionTax}/>
                        </Collapse.Panel>
                        <Collapse.Panel header={headerInfraTax} key={data?.journal_id + "2" + randomKey}
                                        forceRender={true}
                        >

                            <TableInfraTax data={data} setData={setData} selectedJourney={selectedJourney}
                                           dataTax={dataTax} setHeaderInfraTax={setHeaderInfraTax}/>
                        </Collapse.Panel>
                    </Collapse>

                </div>
            </Spin>
        </Card>
    );
};

export default ShowRouteModal;

import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Modal, Table, Row, Col, message, Divider } from 'antd';
import {
  SingleItemDropDown as SingleItemSelector,
  TwoRowsTitleSubTitle,
  TwoRowsTitleText,
} from './reusableComponents';
import { TEMP_LOG_SMS } from './mock-device-data';
import Text from 'antd/lib/typography/Text';
import {
  SD_MODAL_SMS_LOG_TITLES,
  SUPER_DEVICES_MODAL_TITLES,
  STATIC_TEXT
} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_MODAL_SMS_FIELD_COMMANDS, SD_MODAL_SMS_LOG_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";

const MODAL_PRINT_NAME = 'ModalFormDeviceSMS: ';

const MODAL_STATIC_TEXT = {
  LOGG: 'Logg',
  ORG: 'TEMPLATE_ORG',
  BTN_SEND: 'SKICKA',
};

// KOPPLA TILL KUND
const ModalFormDeviceSMS = ({ visible, handleAdd, handleClose, selectedDevice }) => {
  // const prevVisibleRef = useRef();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [textFromDrop, setTextFromDrop] = useState('');

  //DEVICE
  const imei = selectedDevice.device.imei ? selectedDevice.device.imei : '-';
  const organizationId = selectedDevice?.device?.organizationId?.fullName
    ? selectedDevice.device.organizationId.fullName
    : '-';

  //VEHICLE
  const carType = selectedDevice?.device?.installedVehicle?.defaultTripType
    ? selectedDevice.device.installedVehicle.defaultTripType
    : '-';

  const ownerName = selectedDevice?.device?.installedVehicle?.defaultDriver?.displayName
    ? selectedDevice.device.installedVehicle.defaultDriver.displayName
    : '-';

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    forceUpdate({});
  }, []);

  const formItemLayout = {
    layout: 'vertical',
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const onSendClick = () => {
    message.info(MODAL_PRINT_NAME, 'SKICKA onClick!');
  };

  const onOk = () => {};

  const onCommandDropDownChange = (value) => {
    const val = value;

    setTextFromDrop(val);

    form.setFieldsValue({
      getinfo: val,
    });
  };

  const onFinish = (values) => {
    console.log(values);
    handleClose();
  };

  return (
    <Modal
      title={SUPER_DEVICES_MODAL_TITLES.C12SMS}
      visible={visible}
      onOk={onOk}
      onCancel={handleClose()}
    >
      <Form form={form} {...formItemLayout} onFinish={() => {}} name='device_sms_form'>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <TwoRowsTitleSubTitle title={STATIC_TEXT.IMEI} text={imei} />
          </Col>
        </Row>

        <Row gutter={[0, 0]}>
          <TwoRowsTitleText span={8} title={STATIC_TEXT.ORG} text={organizationId} />
          <TwoRowsTitleText
            span={8}
            title={STATIC_TEXT.PRIMARY_DRIVER}
            text={ownerName}
          />
          <TwoRowsTitleText span={8} title={STATIC_TEXT.CAR_TYPE} text={carType} />
        </Row>

        <Divider dashed />

        <Row gutter={[15, 0]}>
          <Form.Item style={{ width: '100%' }} name={['command-dropdown']}>
            <SingleItemSelector
              array={SD_MODAL_SMS_FIELD_COMMANDS}
              placeholder={STATIC_TEXT.CHOOSE_QUICK_COMMAND}
              onChangeFunc={onCommandDropDownChange}
            />
          </Form.Item>
        </Row>

        <Row gutter={[15, 0]}>
          <Col span={16}>
            <Form.Item style={{ width: '100%' }} name={['getinfo']} rules={[{}]}>
              {/* {console.log(textFromDrop)} */}
              <Input
                type={'text'}
                //allowClear
                placeholder={STATIC_TEXT.GET_INFO}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Button
              type='secondary'
              style={{ width: '100%' }}
              onClick={() => {
                onSendClick();
              }}
            >
              {MODAL_STATIC_TEXT.BTN_SEND}
            </Button>
          </Col>
        </Row>

        <Row gutter={[0, 20]}>
          <TableLogg logg={TEMP_LOG_SMS} />
        </Row>
      </Form>
    </Modal>
  );
};

const TableLogg = ({ logg }) => {
  const title = () => STATIC_TEXT.LOG;
  const pagination = { position: 'top' };
  const pageSizeValue = [5];

  const tableState = useState({
    bordered: false,
    loading: false,
    pagination,
    size: 'small',
    title: title,
    showHeader: false,
    rowSelection: false,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    top: 'none',
    bottom: 'bottomRight',
  });
  let row = {};

  const dataSource = logg.map((item) => {
    row = {
      date_time: item.date_time ? item.date_time : 'Missing date-time',
      new_value: item.new_value ? item.new_value : 'Missing new value',
    };

    return row;
  });

  const columns = [
    //DATE_TIME
    {
      key: SD_MODAL_SMS_LOG_KEYS_AND_INDEXES.DATE_TIME,
      dataIndex: SD_MODAL_SMS_LOG_KEYS_AND_INDEXES.DATE_TIME,
      title: SD_MODAL_SMS_LOG_TITLES.DATE_TIME,
      sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <Text>{value}</Text> : <Text> {'-'} </Text>;
      },
    },
    //NEW_VALUE
    {
      key: SD_MODAL_SMS_LOG_KEYS_AND_INDEXES.NEW_VALUE,
      dataIndex: SD_MODAL_SMS_LOG_KEYS_AND_INDEXES.NEW_VALUE,
      title: SD_MODAL_SMS_LOG_TITLES.NEW_VALUE,
      // sorter: (a, b) => a.value - b.value,
      render: (value) => {
        return value ? <Text>{value}</Text> : <Text> {'-'} </Text>;
      },
    },
  ];

  return (
    <React.Fragment>
      <Col span={24}>
        <Table
          {...tableState[0]}
          dataSource={dataSource}
          columns={columns}
          pagination={{ position: ['topRight'], pageSize: pageSizeValue }}
        />
      </Col>
    </React.Fragment>
  );
};

export default ModalFormDeviceSMS;

import {useContext} from "react";
import {SchedulingViewContext} from "../../../services/contexts/views-context/scheduling-context";
import Loading from "../../shared-components/Loading/Loading";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";

import {Divider, Typography} from "antd";
import {NewScheduleForm} from "./NewScheduleForm";
import {CurrentSchedules} from "./CurrentSchedules";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";

const {Title} = Typography;

const SchedulingController = () => {
    const {
        knownUsers,
        knownVehicles,
        knownOrganizations,
        knownReports,
        loading,
        selectedOrganization,
        organizationSelectOnChange,
        setKnownReports,
    } = useContext(SchedulingViewContext)

    return loading ? (
        <Loading/>
    ) : (
        <div>
            <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                <Title>{STATIC_TEXT.SCHEDULING}</Title>
                <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                         selectedOrganization={selectedOrganization} isMultiple={false}/>
            </div>
            <Divider/>
            <NewScheduleForm
                knownVehicles={knownVehicles}
                knownUsers={knownUsers}
                setKnownReports={setKnownReports}
                knownReports={knownReports}
                knownOrganizations={knownOrganizations}
                selectedOrganization={selectedOrganization}
            />
            <Divider/>
            <CurrentSchedules
                knownReports={knownReports}
                knownVehicles={knownVehicles}
                knownUsers={knownUsers}
                setKnownReports={setKnownReports}
                selectedOrganization={selectedOrganization}
                knownOrganizations={knownOrganizations}
            />
        </div>
    );
};
export default SchedulingController;

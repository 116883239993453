import {
    androidAlert,
    androidCar,
    androidContact,
    androidDrafts,
    androidGlobe,
    androidHome
} from "react-icons-kit/ionicons";
import {
    ambulance,
    bank,
    bolt,
    briefcase,
    bus,
    cutlery,
    glass,
    home,
    lightbulbO,
    paintBrush,
    plug,
    road,
    shoppingCart,
    truck,
    wrench
} from "react-icons-kit/fa";
import {Icon} from "react-icons-kit";
import {Select, Space, Tag} from "antd";
import {LastSeenLink} from "components/trackson-components/shared-components/DatumLink";
import {SD_BASE_COLORS} from "./CustomConstant";

const { Option } = Select;
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));
const countryNamesList = countries.getNames("sv", { select: "alias" });
export const countryNamesAsArray = Object.entries(countryNamesList)
    .map(([key, label]) => ({
        key,
        label,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
export const nordicCountries = countryNamesAsArray.filter((country) => country.key === "SE" || country.key === "FI" || country.key === "NO" || country.key === "DK");
const sweden = countryNamesAsArray.find((sweden) => sweden.key === "SE");
const findSweden = nordicCountries.findIndex((sweden) => sweden.key === "SE");
nordicCountries.splice(findSweden, 1);
nordicCountries.unshift(sweden);

const DEVICE_TYPE_NAMES = {
    FMB001: "Plug-n-drive",
    FMT100: "Plug-n-drive",
    FMC001: "Plug-n-drive",
    TAT100: "GPS-tracker",
    FMB920: "Anläggningsmaskin",
    FMM001: "Bert Åkes Test FMM",
};
export const modulesByLimitedVisibility = {
    superVehicles: "Fordon",
    // superadminVehicles: "Fordon",
    account: "Konto",
    organizations: "Organisationer",
    users: "Användare",
    superdevices: "Enheter",
    "triptax-analyzer": "Trängselskatt",
};

export const benefitCategories = {
    POOL: "Poolbil",
    PRIVATE: "Privat",
    BENEFIT: "Förmånsbil",
    WORK: "Arbetsfordon",
    TRACKER: "Tracker",
};

export const tripTypes = {
    PRIVATE: "Privat",
    BUSINESS: "Tjänst",
};

const defaultRoles = ["ADMIN", "DRIVER", "ROOT"];

export const lookupStyleMarker = {
    androidCar: androidCar,
    androidContact: androidContact,
    bus: bus,
    home: home,
    road: road,
    glass: glass,
    briefcase: briefcase,
    wrench: wrench,
    shoppingCart: shoppingCart,
    truck: truck,
    bolt: bolt,
    cutlery: cutlery,
    lightbulbO: lightbulbO,
    ambulance: ambulance,
    bank: bank,
    plug: plug,
    paintBrush: paintBrush,
    androidDrafts: androidDrafts,
    androidHome: androidHome,
    androidGlobe: androidGlobe,
    androidAlert: androidAlert,
    PB: androidGlobe,
};
export const countryFlagApi = "https://flagcdn.com/";

//Vehicle Icon
export const VehicleMarker = ({ marker }) => {
    return <Icon icon={marker} size={24} />;
};
//Select icons
export const selectVehicleIcon = () => {
    const selectIcon = [];
    Object.entries(lookupStyleMarker).map(([i, k]) => {
        selectIcon.push(
            <Option value={i}>
                <Icon icon={k} />
            </Option>
        );
    });
    return selectIcon;
};
//Countries Code
export const isoCountryCode = () => {
    const countryCode = [];
    countryNamesAsArray.map((country) => {
        countryCode.push(
            <Option value={country.key}>
                {country.label} ({country.key})
            </Option>
        );
    });
    return countryCode;
};
//Nordic countries code
export const nordicCountryCode = () => {
    const countryCode = [];
    nordicCountries.map((country) => {
        countryCode.push(
            <Option value={country.key}>
                {country.label} ({country.key})
            </Option>
        );
    });
    return countryCode;
};
//BenefitType
export const getBenefitType = (value) => {
    const title = benefitCategories[value] !== undefined ? benefitCategories[value] : "-";
    return <span>{title}</span>;
};

//Default trip type
export const getDefaultTripType = (value) => {
    const trip = value?.startsWith("PRIVATE");
    const text = tripTypes[value];
    const color = trip ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS;
    return <span style={{ color: color }}>{text}</span>;
};
//GET organization name by organization id
export const getOrgName = (organizations, orgId) => {
    const org = organizations.find((org) => org.organizationId === orgId);
    if (org !== undefined) return org.fullName;
};
//Get user organization
export const getUserOrg = (organizations, orgId) => {
    const org = organizations.find((org) => org.organizationId === orgId);
    if (org !== undefined) return org;
};
//Get driver info
export const getDriver = (value, permittedDrivers, defaultDriver) => {
    const isPoolCar = value?.benefitType === "POOL";
    const totalPermittedDrivers = permittedDrivers.length !== 0 ? `${permittedDrivers.length} st förare` : "-";
    const defaultDriverName = defaultDriver !== null ? defaultDriver.displayName : "-";
    const currentDriver = value !== null ? value.displayName : "Förare ej vald";
    if (isPoolCar) {
        return <span>Tillåts för alla</span>;
    }
    return (
        <Space direction="vertical">
            <Space align="center">
                <h5 style={{ marginBottom: 0 }}>Primär:</h5>
                <p style={{ marginBottom: 0 }}>{defaultDriverName}</p>
            </Space>
            <Space align="center">
                <h5 style={{ marginBottom: 0 }}>Incheckad:</h5>
                <p style={{ marginBottom: 0 }}>{currentDriver}</p>
            </Space>
            <Space align="center">
                <h5 style={{ marginBottom: 0 }}>Behöriga:</h5>
                <p style={{ marginBottom: 0 }}>{totalPermittedDrivers}</p>
            </Space>
        </Space>
    );
};
//Fuels and consumptions
export const getFuelsAndConsumptions = (hybrid, fuels, consumptions) => {
    const fuel1 = fuels.fuel1 !== null ? `${fuels?.fuel1?.svName},` : "";
    const fuel2 = fuels.fuel2 !== null ? `${fuels?.fuel2?.svName},` : "";
    const consumption1 = consumptions?.consumption1 !== null ? `${consumptions.consumption1}` : "";
    const consumption2 = consumptions?.consumption2 !== null ? `${consumptions.consumption2}` : "";
    const consumptionUnit1 = fuels?.fuel1 !== null ? `${fuels.fuel1?.consumptionUnit}` : "";
    const consumptionUnit2 = fuels?.fuel2 !== null ? `${fuels.fuel2?.consumptionUnit}` : "";
    if (hybrid && fuels?.fuel1 !== null && fuels?.fuel2 !== null) {
        return (
            <Space direction="vertical">
                <span>{`${fuel1} ${consumption1} ${consumptionUnit1}`}</span>
                <span>{`${fuel2} ${consumption2} ${consumptionUnit2}`}</span>
            </Space>
        );
    }
    return <span>{`${fuel1} ${consumption1} ${consumptionUnit1}`}</span>;
};
//Co2
export const getCo2 = (hybrid, co2) => {
    const co2GKm1 = co2.co2GKm1 !== null && co2.co2GKm1 !== 0 ? `${co2.co2GKm1}g / km` : "";
    const co2GKm2 = co2.co2GKm2 !== null && co2.co2GKm2 !== 0 ? `${co2.co2GKm2}g / km` : "";
    if (hybrid && co2.co2GKm1 !== null && co2.co2GKm2 !== null) {
        return (
            <Space direction="vertical">
                <span>{co2GKm1}</span>
                <span>{co2GKm2}</span>
            </Space>
        );
    }
    return <span>{co2GKm1}</span>;
};

//Phone number
export const getPhoneNumber = (value) => {
    const number = value !== "" && value !== null ? value : "-";
    return number;
};
//Roles
export const getRoles = (value) => {
    if (value !== null && value.length !== 0) {
        const roles = [];
        value.map((role) => {
            roles.push(<Tag key={role}>{role}</Tag>);
        });
        return roles;
    }
};
//Option roles
export const getOptionRoles = () => {
    const roles = [];
    defaultRoles.map((item) => {
        roles.push(<Option key={item}>{item}</Option>);
    });
    return roles;
};
//Option vehiclesNew
export const getOptionVehicle = (vehicles) => {
    const vehiclesOption = [];
    vehicles?.map((vehicle) => {
        vehicle.registry !== "" && vehicle.registry !== null && vehiclesOption.push(<Option key={vehicle.id}>{vehicle.registry}</Option>);
    });
    return vehiclesOption;
};
//Registry permittedVehicle
export const defaultPermittedVehicle = (vehicle) => {
    const registry = vehicle !== "" ? vehicle : "Untitled";
    return registry;
};
//Last seen
export const lastSeenActive = (value) => {
    const active = value !== null ? <LastSeenLink value={value} typ={"map"} /> : "-";
    return active;
};
//Invite
export const invite = (value) => {
    const inviteInfo = value !== null ? <LastSeenLink value={value} typ={"map"} /> : "-";
    return inviteInfo;
};
//Permitted vehicle tag
export const permittedVehicles = (value) => {
    if (value !== null && value.length !== 0) {
        const vehicles = [];
        value.map((vehicle) => {
            const registry = vehicle.registry !== "" ? vehicle.registry : "untitled";
            vehicles.push(
                <Tag key={vehicle.id} style={{ marginBottom: 8 }}>
                    {registry}
                </Tag>
            );
        });
        return vehicles;
    }
};
//Organization name
export const getOrg = (organizations, organizationId) => {
    const org = organizations?.filter((i) => i.organizationId === organizationId);
    return org ? org[0].fullName : "";
};
//Device name
export const getDeviceName = (value) => {
    if (value !== null) {
        const r = Object.entries(DEVICE_TYPE_NAMES).find(([key]) => {
            if (key === value) {
                return key;
            }
        });
        return r[1];
    }
};
//Battery Color
export const batteryColor = (value) => {
    let strokeColor = "";
    if (value <= 20) {
        strokeColor = "#ff6b72";
    } else if (value >= 20 && value <= 50) {
        strokeColor = "#ffc542";
    } else if (value >= 50 && value < 80) {
        strokeColor = "#3e82f7";
    } else {
        strokeColor = "#04d182";
    }
    return strokeColor;
};

//Installed Vehicle
export const installedVehicle = (vehicles, vehicleId) => {
    const r = vehicles.find((id) => id.id === vehicleId);
    if (r !== undefined) {
        const vehicle = {
            registry: r.registry,
            driver: r.displayName,
        };
        return vehicle;
    }
};

//Adress format
export const formatAnyAddress = (address) => {
    let components = [address?.streetAndNum, address?.zip, address?.city, address?.state, address?.country];

    components = components.filter((x) => {
        return x !== undefined && x !== null && x !== "";
    });

    return <>{components.join(", ")}</>;
};

//Co2 color thresholds
export const co2ColorThresholds = (co2) => {
    if (co2 >= 120) return SD_BASE_COLORS.RED;
    else if (co2 < 120 && co2 > 80) return SD_BASE_COLORS.YELLOW;
    else return SD_BASE_COLORS.GREEN;
};

//Graph color
export const graphColor = (ecoScore) => {
    let color = SD_BASE_COLORS.RED; // Start out as red/bad
    color = ecoScore >= 30 ? SD_BASE_COLORS.YELLOW : color;
    color = ecoScore >= 55 ? SD_BASE_COLORS.BLUE : color;
    color = ecoScore >= 80 ? SD_BASE_COLORS.GREEN : color;
    return color;
};

import React from "react"
import {
    Button,
    Card,
    Input,
    Popconfirm,
    Select,
    Table,
    Tooltip,
    Typography,
    Form,
    message
} from 'antd';
// import { Table, Tooltip, Popconfirm, Form, Col, Input, Card, Modal, Typography, Descriptions, Button, Select, message } from 'antd';
import { EditOutlined, UserAddOutlined, DeleteOutlined } from '@ant-design/icons';
import {userApi} from "../../../services/api/api";

const ErrorReportController = (props) => {
    const [form] = Form.useForm();

    const onFinish = values => {
        userApi.postContactTrackson(values)
            .then(r => {
                message.success('Din felanmälan har skickats!');
                form.resetFields()

            })
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const tailLayout = {
        wrapperCol: {span: 16},
    };


    return (
        <>
            <Typography.Title>Felanmälan</Typography.Title>

            <Card title='Felanmälan' bordered={true}
                  style={{width: '49%', minWidth: 350, margin: 'auto'}}>


                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Titel"
                        name="subject"
                        rules={[{required: true, message: 'Ange titel!'}]}
                    >
                        <Input
                            placeholder="t.ex. ABC123 har låg batterinivå"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Beskrivning"
                        name="body"
                        rules={[{
                            required: false,
                            message: 'Please input description!'
                        }]}
                    >
                        <Input.TextArea
                            rows={8}
                            placeholder="t.ex. Batterinivå var låg på morgonen"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Din E-post"
                        name="addrFrom"
                        rules={[{required: true, message: 'Ange E-post'}]}
                    >
                        <Input
                            placeholder="min-epost@example.com"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Ditt Telefonnummer"
                        name="phone"
                        rules={[{
                            required: true,
                            message: 'Ange telefonnummer'
                        }]}
                    >
                        <Input
                            placeholder="0707..."
                        />
                    </Form.Item>
                    { /*
                <Form.Item
                    label="Larma/Meddela kollegor"
                    name="usersToAlarm"
                    rules={[{ required: false }]}
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Välj larmmottagare"
                    >
                        {selectPermittedDrivers}
                    </Select>
                </Form.Item>
                  */}

                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Rapportera
                        </Button>
                    </Form.Item>
                </Form>

            </Card>
        </>

    )

}

export default ErrorReportController
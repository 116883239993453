import React from "react";
import {CarOutlined, UserOutlined, AimOutlined, HistoryOutlined, ReloadOutlined} from "@ant-design/icons";
import {Card, Button, Descriptions, Tooltip, Typography} from "antd";
import Icon from "react-icons-kit";
import {mapMarker} from "react-icons-kit/fa/mapMarker";
import {battery} from "react-icons-kit/fa/battery";
import {signal} from "react-icons-kit/fa/signal";

import {ic_flag} from "react-icons-kit/md/ic_flag";

import {flagO} from "react-icons-kit/fa/flagO";

import {flagCheckered} from "react-icons-kit/fa/flagCheckered";

import {flag} from "react-icons-kit/entypo/flag";

import {convertTimestampToDateStr} from "../../../utilities.js";
import {LastSeenLink} from "../../trackson-components/shared-components/DatumLink";
import {
    androidAlert,
    androidCar,
    androidContact,
    androidDrafts,
    androidGlobe,
    androidHome,
    androidSync
} from "react-icons-kit/ionicons";
import {
    ambulance,
    bank,
    bolt,
    briefcase,
    bus,
    cutlery,
    glass,
    home,
    lightbulbO,
    paintBrush,
    plug,
    road,
    shoppingCart,
    truck,
    wrench
} from "react-icons-kit/fa";

import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import {BatteryIcon} from "../../trackson-components/Superdevices/superDeviceIcons";

const DEFAULT_COLOR = "#3e79f7";
const {Title} = Typography;
const styles = {
    marker: {
        backgroundColor: DEFAULT_COLOR,
        width: 48,
        height: 48,
        borderRadius: 48 / 2,
        transition: ".3s",
        transform: "translate(-50%, -50%)",
        outlineStyle: "circle",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    markerRegistryPlate: {
        backgroundColor: DEFAULT_COLOR,
        width: "max-content",
        padding: "5px 30px",
        transition: ".3s",
        transform: "translate(-50%, -50%)",
        marginTop: -10,
        display: "flex",
        justifyContent: "center",
        color: "#fff",
    },
    markerDisplayNamePlate: {
        backgroundColor: "#ffffff",
        height: 32,
        width: "max-content",
        padding: "4px 8px",
        transition: ".3s",
        transform: "translate(-50%, -50%)",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        color: "#000000",
        border: "1px solid #000000",
        fontWeight: "bold",
        fontSize: 14,
    },
    markerStart: {
        backgroundColor: "#D6D7DC",
        width: 42,
        height: 42,
        position: "relative",
        transform: "translate(-50%, -50%)",
        borderRadius: 48 / 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    markerStop: {
        backgroundColor: "#6B7D92",
        width: 42,
        height: 42,
        borderRadius: 48 / 2,
        transform: "translate(-50%, -50%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    markerIcon: {
        fontSize: 28,
        color: "#fff",
    },
    markerIconFollowed: {
        backgroundColor: DEFAULT_COLOR,
        width: 80,
        height: 80,
        borderRadius: 80 / 2,
        transform: "translate(-50%, -50%)",
        zIndex: 3,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    markerIconStart: {
        fontSize: 28,
        color: "#6B7D92",
    },
    plate: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: 48 + 4,
        top: (48 - 36) / 2,
        height: 36,
        width: 100,
        backgroundColor: "#2B3855",
        borderRadius: 4,
        border: "1px solid #D6D7DC",
    },
    plateText: {
        position: "relative",
        fontSize: 18,
        fontWeight: "bold",
        color: "#D6D7DC",
        textAlign: "center",
    },
    cardHead: {
        textAlign: "center",
        borderBottom: "1px solid rgb(77, 91, 117)",
        paddingBottom: 16,
        fontSize: 24,
    },
    infoWindow: {
        backgroundColor: "#283142",
        boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
        position: "relative",
    },
};

export const InfoWindow = (props) => {
    const marker = props.showInfo;
    const device = marker?.meta?.vehicleDisplayName || marker?.meta.reg || "ID " + marker?.meta?.vehicle_id;
    console.log("marker", marker)
    const typ = "map";
    const isTracker = marker?.isTracker || false;
    const position = marker?.meta.status === "MOVING" ?
        <span style={{color: SD_BASE_COLORS.GREEN}}>I rörelse</span> : "Parkerad";
    const speed = marker?.meta.status === "MOVING" ? marker?.speed_kph + " km/h" : "Parkerad";
    let signalGPS = marker?.meta.gps_quality ? (marker?.meta.gps_quality * 10) / 2 : 0;
    signalGPS = Math.round(signalGPS);
    let batteryColor;
    let gpsColor;

    if (signalGPS > 3) {
        gpsColor = "#21B573";
    } else if (signalGPS > 2) {
        gpsColor = "#fa8c16";
    } else {
        gpsColor = "#de4436";
    }

    console.log(marker)
    if (marker?.battery_percentage < 30) {
        batteryColor = "#de4436";
    } else if (marker?.battery_percentage >= 70) {
        batteryColor = "#21B573";
    } else {
        batteryColor = "#fa8c16";
    }
    const stopFollowing = () => {
        props.onClick(props.showInfo);
    };
    let getTitle = marker?.meta?.reg || marker?.meta?.vehicleDisplayName || "ID " + marker?.meta?.vehicle_id;
    return (
        <Card
            style={styles.infoWindow}
            title={marker?.isTracker ? device : getTitle}
            headStyle={styles.cardHead}
            actions={[
                <Button onClick={stopFollowing} danger>
                    {"Stäng"}
                </Button>,
            ]}
            className="markerBox"
        >

            <Descriptions column={1}>
                {!isTracker && (
                    <Descriptions.Item>
                        <div style={{width: 25, height: 24}}>
                            <CarOutlined/>
                        </div>
                        {device}
                    </Descriptions.Item>
                )}

                {!isTracker && (
                    <Descriptions.Item>
                        <div style={{width: 25, height: 24}}>
                            <UserOutlined/>
                        </div>
                        {marker?.driver?.displayName || marker?.driver?.id || "Ingen incheckad förare"}
                    </Descriptions.Item>
                )}

                {isTracker && (
                    <Descriptions.Item>
                        <div style={{width: 25, height: 24}}>
                            <ReloadOutlined/>
                        </div>
                        {marker?.latestResponseFrequency || "-"} uppdateringsfrekvens
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Descriptions column={2}>

                {!isTracker && (
                    <Descriptions.Item>
                        <div style={{width: 25, height: 24}}>
                            <AimOutlined/>
                        </div>
                        {position}
                    </Descriptions.Item>
                )}

                {isTracker && (
                    <Descriptions.Item>
                        <div style={{width: 25, height: 24}}>
                            <Icon icon={battery}/>
                        </div>
                        {marker?.battery_percentage || "-"}%
                    </Descriptions.Item>
                )}

                <Descriptions.Item>
                    <div style={{width: 25, height: 24}}>
                        <HistoryOutlined/>
                    </div>
                    <LastSeenLink value={marker?.last_seen_ms} typ={typ} isTracker={isTracker}/>
                </Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

const ICON_TYPE_CAR = 1;
const ICON_TYPE_TRUCK = 2;

const lookupStyleMarker = {
    androidCar: androidCar,
    androidContact: androidContact,
    bus: bus,
    home: home,
    road: road,
    glass: glass,
    briefcase: briefcase,
    wrench: wrench,
    shoppingCart: shoppingCart,
    truck: truck,
    bolt: bolt,
    cutlery: cutlery,
    lightbulbO: lightbulbO,
    ambulance: ambulance,
    bank: bank,
    plug: plug,
    paintBrush: paintBrush,
    androidDrafts: androidDrafts,
    androidHome: androidHome,
    androidGlobe: androidGlobe,
    PB: androidGlobe,
    androidAlert: androidAlert,
};

export const Marker = (props) => {
    const {data} = props;
    const {lat, lng, IMEI, meta, followerDriver} = data !== null ? data : props?.selectedMarker;
    let iconType = ICON_TYPE_CAR;
    if (meta.type !== "PB") {
        // mock
        iconType = ICON_TYPE_TRUCK;
    }

    const isFollowed = props.selectedMarker;
    const icon = lookupStyleMarker[meta?.marker];
    let COLOR_VEHICLE_FOLLOWED = "0 0 0 20px rgba(255,0,0,0.35)";
    const registry = meta.vehicleDisplayName || meta.reg || meta.vehicle_id;

    return (
        <>
            <div style={{
                ...styles.marker,
                backgroundColor: meta.color || DEFAULT_COLOR,
                boxShadow: isFollowed ? COLOR_VEHICLE_FOLLOWED : null
            }} onClick={(e) => props.onClick(props.selectedMarker)}>
                {icon ? <Icon icon={icon} size={28} style={styles.markerIcon}/> :
                    <CarOutlined style={styles.markerIcon}/>}
            </div>
            {!props.selectedMarker ? (
                <div style={{...styles.markerDisplayNamePlate, backgroundColor: "#ffffff"}}>{registry}</div>
            ) : (
                <div style={{
                    ...styles.markerRegistryPlate,
                    backgroundColor: props.selectedMarker ? meta.color : DEFAULT_COLOR
                }}>{registry}</div>
            )}
            {props.showPlate && (
                <div style={styles.plate}>
                    <span style={styles.plateText}>{meta.reg || meta.vehicleDisplayName || meta.vehicle_id}</span>
                </div>
            )}
            {/* {props.showInfo && (
				<InfoWindow marker={data} />
			)} */}
        </>
    );
};

export const MarkerStopLocation = (props) => (
    <div style={styles.markerStop} onClick={props.onClick}>
        <Tooltip title={props.text}>
            <Icon icon={ic_flag} style={styles.markerIcon}/>
        </Tooltip>
    </div>
);
export const MarkerStartLocation = (props) => (
    <div style={styles.markerStart} onClick={props.onClick}>
        <Tooltip title={props.text}>
            <Icon icon={mapMarker} style={styles.markerIconStart}/>
        </Tooltip>
    </div>
);

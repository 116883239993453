import { MAP_TYPE } from "../services/redux/constants/authConstants";
import {STATIC_TEXT} from "../lang/translate/sv-SE/sv-SE";

export const darkMapStyle = [
    {
        featureType: "all",
        elementType: "geometry",
        stylers: [
            {
                color: "#202c3e",
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.text.fill",
        stylers: [
            {
                gamma: 0.01,
            },
            {
                lightness: 20,
            },
            {
                weight: "1.39",
            },
            {
                color: "#ffffff",
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
            {
                weight: "0.96",
            },
            {
                saturation: "9",
            },
            {
                visibility: "on",
            },
            {
                color: "#000000",
            },
        ],
    },
    {
        featureType: "all",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
            {
                lightness: 30,
            },
            {
                saturation: "9",
            },
            {
                color: "#29446b",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
            {
                saturation: 20,
            },
        ],
    },
    {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
            {
                lightness: 20,
            },
            {
                saturation: -20,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [
            {
                lightness: 10,
            },
            {
                saturation: -30,
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
            {
                color: "#193a55",
            },
        ],
    },
    {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
            {
                saturation: 25,
            },
            {
                lightness: 25,
            },
            {
                weight: "0.01",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "all",
        stylers: [
            {
                lightness: -20,
            },
        ],
    },
];

export const setLightMapType = (maps, map) => {
    const dark = localStorage.getItem(MAP_TYPE) === "DARK";
    const nameDark = "Mörk karta";
    const nameLight = "Ljus karta";
    let mapStyle = dark ? [] : darkMapStyle;
    let name = dark ? nameLight : nameDark;
    const styledMapType = new maps.StyledMapType(mapStyle, { name: name });
    map.mapTypes.set("dark", styledMapType);
};

export const mapOptions = {
    styles: localStorage.getItem(MAP_TYPE) === "DARK" ? darkMapStyle : null,
    streetViewControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "dark", "satellite", "hybrid", "terrain"],
    },
};

import React, { createContext } from "react";
import { dataApi, userApi } from "services/api/api";
const ServicesContext = createContext({});

const ServicesContextProvider = ({ children }) => {
    const postContact = async (v) => {
        const res = await userApi
            .postContactTrackson(v)
            .then((res) => {
                console.log(res);
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
        return res;
    };
    const getTrackersReport = async (trackers, fileNameDescription) => {
        const res = dataApi
            .getTrackersReport(trackers, fileNameDescription)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
        return res;
    };
    const getVehicleCertificate = (previousMonth, year, vehicleId) => {
        const res = dataApi
            .getAttestCkeckLite(previousMonth, year, vehicleId)
            .then((resLite) => {
                const res = dataApi
                    .getAttestCheck(previousMonth, year, vehicleId, resLite)
                    .then((res) => {
                        return { resCheck: res, checkLite: resLite };
                    })
                    .catch((err) => {
                        console.error(err);
                        return false;
                    });
                return res;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
        return res;
    };
    const getJournalsPDF = async (tsFr, tsTo, vehicleIds, fileName, zoneId, driverIds, organizationIds) => {
        await dataApi
            .getJournalsPDF(tsFr, tsTo, vehicleIds, fileName, zoneId, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const getJournalsCSV = async (tsFr, tsTo, vehicleIds, fileName, driverIds, organizationIds) => {
        await dataApi
            .getJournalsCSV(tsFr, tsTo, vehicleIds, fileName, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const getSummaryDriver = async (tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds) => {
        await dataApi
            .getSummaryDriverReports(tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const getSummaryVehicle = async (tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds) => {
        await dataApi
            .getSummaryVehicleReports(tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const getSummarySalary = async (tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds) => {
        await dataApi
            .getSalaryReports(tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    const getSummaryPurpose = async (tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds) => {
        await dataApi
            .getPurposeReports(tsFr, tsTo, vehicleIds, fileName, zoneId, allVehicles, driverIds, organizationIds)
            .then((res) => console.log(res))
            .catch((err) => console.error(err));
    };
    return (
        <ServicesContext.Provider
            value={{ postContact, getTrackersReport, getVehicleCertificate, getJournalsPDF, getJournalsCSV, getSummaryDriver, getSummaryVehicle, getSummarySalary, getSummaryPurpose }}
        >
            {children}
        </ServicesContext.Provider>
    );
};

export { ServicesContext, ServicesContextProvider };

import React from "react";
import {Breadcrumb, Button, Table} from "antd";
import {tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";
import Flex from "../../shared-components/Flex/Flex";

export const TableForOrganization = ({
                                         columns,
                                         filteredData,
                                         knownOrganizations,
                                         organization,
                                         getDriverReport,
                                         showButton
                                     }) => {
    let organizationName = knownOrganizations?.find((e) => e.organizationId === organization.organizationId)?.fullName;
    let parent1 = knownOrganizations?.find((e) => e.organizationId === organization.parentOrganization);
    let parent2 = knownOrganizations?.find((e) => e.organizationId === parent1?.parentOrganization);
    let parent3 = knownOrganizations?.find((e) => e.organizationId === parent2?.parentOrganization);

    filteredData = filteredData?.filter((item) => item.organizationId === organization.organizationId);
    console.log(organization);

    const TableTitle = () => {
        return (
            <Flex style={{marginBottom: 16}} justifyContent={"between"} alignItems={"center"}>
                <Breadcrumb style={{fontSize: 22, }}>
                    <Breadcrumb.Item>{parent3?.fullName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{parent2?.fullName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{parent1?.fullName}</Breadcrumb.Item>
                    <Breadcrumb.Item>{organizationName}</Breadcrumb.Item>
                </Breadcrumb>
                {showButton && (
                    <Button disabled={filteredData?.length < 1} onClick={() => getDriverReport(null, filteredData)}
                            style={{}}>
                        Exportera samlad lönerapport för {organizationName}
                    </Button>
                )}
            </Flex>
        );
    };
    return (
        <div style={{marginBottom: "4em"}}>
            <TableTitle/>

            <Table
                dataSource={filteredData}
                pagination={false}
                columns={tableColumnsWithTooltip(columns)}
                scroll={{x: 1500}}
                {...tableProps}
            />

        </div>
    );
};

import React, {createContext, useContext, useEffect, useState} from "react";
import {OrganizationContext} from "../../OrganizationsContext";
import {useSelector} from "react-redux";
import {OrganizationSimpleContext, UserListSimpleContext, VehicleContext, VehiclesComplexListContext} from "../../contexts";
import {UsersContext} from "../../UsersContext";
import {DevicesContext} from "../../DevicesContext";
import {dataApi} from "../../../api/api";

const SchedulingViewContext = createContext({});
const SchedulingViewProvider = ({ children }) => {
    const knownUsers = useContext(UserListSimpleContext)
    const knownVehicles = useContext(VehiclesComplexListContext)
    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)
    const [knownReports, setKnownReports] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState();



    useEffect(() => {
        dataApi.getReports().then((res) => {
            setKnownReports(res);
        });

    }, []);

    useEffect(() => {
        if (knownVehicles && knownReports && knownOrganizations) {
            setLoading(false);
        }
    }, [knownOrganizations, knownReports, knownVehicles]);

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };

    return <SchedulingViewContext.Provider value={{
        knownUsers,
        knownVehicles,
        knownOrganizations,
        knownReports,
        loading,
        selectedOrganization,
        organizationSelectOnChange,
        setKnownReports,





    }}>{children}</SchedulingViewContext.Provider>;
};
export { SchedulingViewContext, SchedulingViewProvider };


import React from "react";
import { Typography, Form, Button, Input, Card, Collapse, message } from "antd";
import moment from "moment";
import { PhoneOutlined, StarFilled, StarTwoTone } from "@ant-design/icons";
import {userApi} from "../../../services/api/api";
import {STATIC_TEXT as SUPER_DEVICES_STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";


const { Title } = Typography;
const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const SupportController = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: "wrap"}}>
            <Title>Kundtjänst</Title>
            <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%", justifyContent: "center", alignItems: "top", gap: "3em", flexWrap: "wrap" }}>
                <Card bordered={true} style={{ minWidth: 300, whiteSpace: "normal", flexWrap: "wrap", display: "flex", gap: 30, justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    <Title level={2}>Kontakta oss</Title>
                    <Text style={{ whiteSpace: "normal" }}>
                        Vill du veta mer eller har du frågor?
                        <br />
                        Tveka inte att höra av dig till oss!
                    </Text>
                    <br />
                    <br />
                    <br />
                    <a href="tel: +460104240940">
                        <Button size="large" type="primary" style={{  margin: "auto" }}>
                            <PhoneOutlined style={{ marginBottom: "4.5em" }} />
                            010 - 424 09 40
                        </Button>
                    </a>
                    <div style={{marginTop: 40}}>
                        <Text style={{ whiteSpace: "normal" }}>Vi erbjuder också fjärrsupport med</Text>
                    </div>
                    <div style={{ position: "relative", minWidth: 500, height: "37.5px", margin: "15px auto" }}>
                        <a href="https://get.teamviewer.com/trackson" style={{ textDecoration: "none" }}>
                            <img
                                src="https://static.teamviewer.com/resources/badges/teamviewer_badge_flat4.png"
                                alt="Fjärråtkomst och support med TeamViewer"
                                title="Fjärråtkomst och support med TeamViewer"
                                style={{ border: "0", width: "146.25px", height: "37.5px" }}
                            />
                        </a>
                    </div>
                </Card>
                <ContactForm />
            </div>
            <div>
                {/*TODO FAQ hidden as long as we don't have answers*/}
                {/*<FaqComponent />*/}
            </div>
        </div>
    );
};

const ContactForm = () => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        userApi.postContactTrackson(values).then((r) => {
            message.success("Ditt meddelande har skickats!");
            form.resetFields();
        });

        console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Card title="Har du några frågor eller funderingar?" bordered={true} style={{ minWidth: 500, }}>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                onFinish={onFinish}
                initialValues={{
                    modifier: "public",
                }}
            >
                <Form.Item
                    label={SUPER_DEVICES_STATIC_TEXT.TITLE}
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: "Fältet är obligatoriskt",
                        },
                    ]}
                >
                    <Input placeholder={SUPER_DEVICES_STATIC_TEXT.SUPPLY_TITLE} />
                </Form.Item>
                <Form.Item
                    label="E-post"
                    name="addrFrom"
                    rules={[
                        {
                            required: true,
                            message: "Fältet är obligatoriskt",
                        },
                    ]}
                >
                    <Input placeholder={SUPER_DEVICES_KEYS_AND_INDEXES.exEmail} />
                </Form.Item>
                <Form.Item
                    label={SUPER_DEVICES_STATIC_TEXT.PHONE_NUMBER}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: "Fältet är obligatoriskt",
                        },
                    ]}
                >
                    <Input placeholder={SUPER_DEVICES_STATIC_TEXT.SUPPLY_A_NEW_ORG_PHONE} />
                </Form.Item>
                <Form.Item
                    label="Meddelande"
                    name="body"
                    rules={[
                        {
                            required: true,
                            message: "Fältet är obligatoriskt",
                        },
                    ]}
                >
                    <TextArea placeholder={SUPER_DEVICES_STATIC_TEXT.DESCRIBE_CASE} rows={13} />
                </Form.Item>

                <Button htmlType="submit" type="primary">
                    Skicka
                </Button>
            </Form>
        </Card>
    );
};

const FaqComponent = () => {
    const text = (
        <p style={{ paddingLeft: 24 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu tellus sed justo dignissim aliquet sit amet quis massa. Class aptent taciti sociosqu ad litora torquent per conubia
            nostra, per inceptos himenaeos. In mauris nisi, tincidunt et suscipit sed, posuere quis orci. Nulla cursus est felis, quis congue sem tristique eu. Sed vitae dolor volutpat, pharetra sem
            sed, fringilla nulla. Nunc feugiat congue lacus vitae convallis. Duis vestibulum, libero aliquet consequat tincidunt, ante augue placerat tellus, sed eleifend nibh turpis non leo. Donec a
            arcu ornare, bibendum quam sed, lobortis lacus. Proin sed mi scelerisque, vestibulum sem commodo, porta est. Quisque nec ligula nec nibh maximus cursus. Fusce aliquet, turpis ac auctor
            imperdiet, justo nunc auctor lectus, nec rhoncus ligula felis vitae metus. Mauris fermentum iaculis nibh, vitae blandit urna tincidunt vel.
        </p>
    );

    return (
        /*

                <>
                <br/>
            <br/>
            <Title>Vanliga frågor</Title>
            <Collapse style={{padding: 30}} bordered={false} >
                <Panel header="Jag har glömt mitt lösenord/anv.namn." key="1">
                    <p style={{ paddingLeft: 24 }}>
                        Om du glömt ditt lösenord/anv.namn gör du så här………………………………………………………………

                    </p>
                </Panel>
                <Panel header="Jag ska byta bil hur gör jag med min körjournal? " key="2">
                    <p style={{ paddingLeft: 24 }}>
                        Om du vill flytta din körjournal till annat fordon gör du så här…………………………………………….

                    </p>
                </Panel>
                <Panel header="Om du i huvudsak kör i tjänst vissa tider/dagar och vill schemalägga din körjournal gör du så här. " key="3">
                    <p style={{ paddingLeft: 24 }}>
                        Ex. om du i huvudsak kör i tjänst Måndag till Fredag mellan 08.00 och 17.00 kan du schemalägga din körjournal så att alla de resorna blir tjänsteresor då gör du så här ………………………………………
                    </p>
                </Panel>
                <Panel header="Jag vill ändra mina resor från tjänst till privat hur gör jag?" key="4">
                    <p style={{ paddingLeft: 24 }}>
                        Du kan enkelt ändra dina resor i appen eller på din dator från tjänst till privat eller vice versa om du gör så här ………………………………..

                    </p>
                </Panel>
                <Panel header="Jag ska skicka in min körjournal, hur gör jag." key="5">
                    <p style={{ paddingLeft: 24 }}>
                        Du ska granska din körjournal och korrigera den innan du skickar in den,
                        när du anser att din körjournal är Ok klickar du på ………………………………
                    </p>
                </Panel>
                <Panel header="Kan min chef se mina resor? " key="6">
                    <p style={{ paddingLeft: 24 }}>
                        Administratören av Trackson på ditt företag kan se dina tjänsteresor men inte dina privata resor.
                        Administratörerna (Företagsadmin) har mer specifika frågor om systemet speciellt i lite större företag ex.
                    </p>
                </Panel>
                <Panel header="Jag vill flytta ett fordon från den här avdelningen till annan avdelning, hur gör jag det?" key="7">
                    <p style={{ paddingLeft: 24 }}>
                        I Trackson administratörs programmet gör du det enkelt genom att du markerar fordonet du vill flytta och …………………………….

                    </p>
                </Panel>
                <Panel header="Vi har flera poolbilar hur vet jag vem som kör vilken bil? " key="8">
                    <p style={{ paddingLeft: 24 }}>
                        Här vet inte jag hur Trackson systemet fungerar//bå

                    </p>
                </Panel>
                <Panel header="Vi har köpt ett nytt företag och jag vill lägga till den i gruppen hur gör jag det? " key="9">
                    <p style={{ paddingLeft: 24 }}>
                        I Trackson administratörs programmet kan du enkelt skapa underavdelningar genom att …………………………………..

                    </p>
                </Panel>
                <Panel header="Jag vill lägga till en administratör, kan jag göra det? " key="10">
                    <p style={{ paddingLeft: 24 }}>
                        Här vet inte jag hur Trackson systemet fungerar//bå
                    </p>
                </Panel>
            </Collapse>
        </>
        */

        <>
            <br />
            <br />
            <Title>Vanliga frågor</Title>
            <Collapse style={{ padding: 30 }} bordered={false}>
                <Panel header="Jag har glömt mitt lösenord/anv.namn." key="1">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Jag ska byta bil hur gör jag med min körjournal? " key="2">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Om du i huvudsak kör i tjänst vissa tider/dagar och vill schemalägga din körjournal gör du så här. " key="3">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Jag vill ändra mina resor från tjänst till privat hur gör jag?" key="4">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Jag ska skicka in min körjournal, hur gör jag." key="5">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Kan min chef se mina resor? " key="6">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Jag vill flytta ett fordon från den här avdelningen till annan avdelning, hur gör jag det?" key="7">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Vi har flera poolbilar hur vet jag vem som kör vilken bil? " key="8">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Vi har köpt ett nytt företag och jag vill lägga till den i gruppen hur gör jag det? " key="9">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
                <Panel header="Jag vill lägga till en administratör, kan jag göra det? " key="10">
                    <p style={{ paddingLeft: 24 }}>Kontakta kundtjänst</p>
                </Panel>
            </Collapse>
        </>
    );
};

export default SupportController;

const styles = {
    headerContent: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",

    },
    ecoContent: {
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
        gap: 16
    },
    card: {
        padding: "2em 3em",
        minHeight: "100%",
        width: "33%",
        minWidth: 400,
        maxWidth: 500


    },
    dataDisplay: {
        fontSize: "42px",
    },
    dataSubDisplay: {
        fontSize: "24px",
    },
    dataDescription: {
        fontSize: "14px",
    },
};

export default styles;

import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {Select, Tooltip} from 'antd';
import {FolderOpenOutlined} from '@ant-design/icons';

const {Option, OptGroup} = Select;


export const VehiclesSelect = ({value, onChange, data, multiple, ...props}) => {
    const activeData = data?.filter((v) => !v?.isArchived)
    const archivedData = data?.filter((v) => v?.isArchived)
    return (
        <div>
            <Tooltip placement="topLeft" title={STATIC_TEXT.START_TYPING_TO_SEARCH}>
                <Select
                    mode={multiple}
                    allowClear={multiple}
                    defaultValue={value}
                    maxTagCount={1}
                    style={{minWidth: 200}}
                   // dropdownStyle={{minWidth: 400}}
                    dropdownMatchSelectWidth={false}
                    autoClearSearchValue={false}
                    placement={props.placement}
                    onChange={onChange}
                    showSearch
                    placeholder={props.placeholder || STATIC_TEXT.FILTER_BY_VEHICLE}
                    filterOption={(input, option) =>
                        JSON.stringify(option?.object)?.toLowerCase().includes(input.toLowerCase())
                    }
                    value={value}
                >
                    {multiple && (
                        <Option style={{color: "#286FBE", cursor: "pointer"}}
                          value={-1} key={-1}>

                            {value?.length > 0 ? (
                                <span
                                    style={{color: "#286FBE", cursor: "pointer"}}>
                                Avmarkera alla
                            </span>
                            ) : (
                                <span
                                    style={{color: "#286FBE", cursor: "pointer"}}>
                                Välj alla
                            </span>
                            )}

                        </Option>
                    )}
                    {activeData?.map((v, i) => {
                        return (
                            <Select.Option key={v.id} value={v.id} object={v}>
                                {v.registry} {v.displayName} {!v.registry && !v.displayName ? STATIC_TEXT.UNKNOWN + " " + v.id : ""}
                            </Select.Option>
                        );
                    })}
                    {archivedData?.length > 0 &&
                        <OptGroup label="Arkiverade">
                            {archivedData?.map((v, i) => {
                                return (
                                    <Select.Option key={v.id} value={v.id} object={v}>
                                        <FolderOpenOutlined style={{marginRight: 8}}/>
                                        {v.registry} {v.displayName} {!v.registry && !v.displayName ? STATIC_TEXT.UNKNOWN + " " + v.id : ""}
                                    </Select.Option>
                                );
                            })}
                        </OptGroup>}
                </Select>
            </Tooltip>
        </div>
    )

}


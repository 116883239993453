import React, {useContext, useEffect, useState} from "react";
import {Tooltip, Button, Menu, message, Table, Tabs, Typography,} from "antd";
import {DownloadOutlined, EyeInvisibleOutlined, EyeOutlined, HeatMapOutlined, ReloadOutlined} from "@ant-design/icons";
import {LastSeenLink} from "components/trackson-components/shared-components/DatumLink";
import {TableActionBtnType} from "components/trackson-components/Superdevices/Superdevices";
import {GeoContext} from "services/contexts/GeoContext";
import {ServicesContext} from "services/contexts/ServicesContext";
import {VehiclesContext} from "services/contexts/VehiclesContext";
import {
    benefitType,
    STATIC_TEXT as SUPER_DEVICES_STATIC_TEXT,
    STATIC_TEXT
} from "../../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../../constants/CustomConstant";
import {TabBadge} from "../../Superdevices/reusableComponents";
import {
    ButtonMenuDropdown, MenuItemInterval,
    registryPlateWithAlias,
    tableColumnsWithTooltip,
    tableProps,
    vehiclesIcon
} from "../../shared-components/sharedComponents";
import {dataApi} from "../../../../services/api/api";
import {AuthorizationContext} from "../../../../services/contexts/contexts";

const {Text} = Typography;
const {TabPane} = Tabs;

const OverViewList = ({
                          mapOverviewData,
                          selectedMarker,
                          onClickMarker,
                          openGeozone = () => null,
                          ...props
                      }) => {
    const {getGeozone} = useContext(GeoContext);
    const {getTrackersReport} = useContext(ServicesContext);
    const {updateVehicleFrequencyUpdate} = useContext(VehiclesContext);
    const currentUser = useContext(AuthorizationContext);

    const [activeData, setActiveData] = useState();
    const [recordFrequencyInterval, setRecordFrequencyInterval] = useState();
    const isAdmin = currentUser?.roles?.includes("ADMIN")
    const isRoot = currentUser?.roles?.includes("ROOT")
    useEffect(() => {
        setActiveData(mapOverviewData?.filter((item) => !item?.meta?.isArchived))
    }, [mapOverviewData])

    const showGeozoneModal = async (record) => {
        const res = await getGeozone(record.meta.vehicle_id, record.meta.organizationId);
        if (res) await openGeozone();
    };
    const exportTrackerReport = async (vehicleId, fileName) => {
        const res = await getTrackersReport(vehicleId, fileName);
        if (!res) console.log("Export Successful");
    };
    const handleUpdateFreq = async (vehicleId, freq) => {
        await updateVehicleFrequencyUpdate(vehicleId, freq).then(_ => {
            props.updateMapOverviewData()
            setRecordFrequencyInterval(null)
        })
    };

    function handleOnUpdateVehicle(value) {
        const vehicleId = value.meta.vehicle_id;
        dataApi.postVehicleTracking(vehicleId).then((_) => {
            message.success(SUPER_DEVICES_STATIC_TEXT.CURRENT_LOCATION_UPPDATED);
        });
    }

    const isTracker = (record) => {
        return record?.isTracker;
    };

    const columns = [
        {
            key: "registry",
            dataIndex: "registry",
            title: "Regnr/Alias",
            fixed: 'left',
            width: 150,
            render: (value, record) => {
                if (!record?.meta?.reg) {
                    return record?.meta?.vehicleDisplayName
                }

                const vehicle = {
                    displayName: record?.meta?.vehicleDisplayName,
                    registry: record?.meta?.reg
                }
                return registryPlateWithAlias(vehicle)
            }
        },
        {
            key: "style",
            dataIndex: "style",
            title: "Ikon",
            align: "center",
            render: (value, record) => vehiclesIcon(record?.meta),
        },
        {
            title: STATIC_TEXT.TYPE,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.meta,
            render: (value, record) => {
                const text = record?.isTracker ? "Utrustning" : benefitType[value?.benefitType]
                return <span>{text}</span>
            },
        },
        {
            title: STATIC_TEXT.LAST_SEEN,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.lastSeenMs,
            align: "center",
            render: (value, record) => {
                return value ? <LastSeenLink value={value} typ="map" isTracker={record?.isTracker}/> : null;
            },
        },
        {
            title: STATIC_TEXT.STATUS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.speed,
            render: (value, record) => {
                const isTracker = record?.isTracker || false;
                let textColor = "#b4bed2";
                if (value > 0) {
                    textColor = SD_BASE_COLORS.SWITCH_PRIVATE;
                }
                let status;
                if (value === null) {
                    status = STATIC_TEXT.NO_INFO;
                }
                if (value === 0) {
                    status = STATIC_TEXT.PARKED;
                }
                if (value > 0) {
                    status = STATIC_TEXT.IN_MOVING;
                }
                if (record?.position.longitude !== 0)
                    if (isTracker) {
                        return null;
                    }
                return <Text style={{color: textColor}}>{status}</Text>;
            },
        },
        {
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.meta,
            align: "right",
            render: (value, record) => {
                const isFollowed = value.vehicle_id === selectedMarker?.meta.vehicle_id;
                const filenameDescription = record?.meta?.reg ? record?.meta?.reg?.split(" ").join("_") : "";
                let disabledRecord = record?.position.longitude === 0;
                const buttonContent = (
                    <Button
                        disabled={disabledRecord}
                        icon={!isFollowed ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                        onClick={() => onClickMarker(record)}
                    >
                        {!record?.isTracker ? (!isFollowed ? STATIC_TEXT.FOLLOW : "Stäng") : null}
                        {record?.isTracker ? (!isFollowed ? "Visa på karta" : "Stäng") : null}
                    </Button>)

                return (
                    <Tooltip title={disabledRecord ? 'Fordonet befinner sig i privat läge och kan därför inte spåras' : null}>
                        {buttonContent}
                    </Tooltip>
                );
            },
        },
        {
            dataIndex: "operation",
            width: 80,
            render: (value, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(value, record)}/>
            },
        }
    ];

    function menuDropdown(value, record) {
        let disabledRecord = record?.position.longitude === 0;
        let updateIntervalText = `Ändra uppdateringsfrekvens (${record?.latestResponseFrequency || "24h"})`;
        const filenameDescription = record?.meta?.reg ? record?.meta?.reg?.split(" ").join("_") : "";
        const showMenuItem = isAdmin || isRoot
        return <Menu>
            {showMenuItem && <Menu.Item icon={<HeatMapOutlined/>}
                                        onClick={() => showGeozoneModal(record)}>{STATIC_TEXT.SHOW_GEO_ZONE}</Menu.Item>}
            {!isTracker(record) && !disabledRecord && <Menu.Item icon={<ReloadOutlined/>}
                                              onClick={() => handleOnUpdateVehicle(record)}>{STATIC_TEXT.UPDATE}</Menu.Item>
            }

            {isTracker(record) && <Menu.Item icon={<DownloadOutlined/>}
                                             onClick={() => exportTrackerReport(record?.meta?.vehicle_id, filenameDescription)}>{STATIC_TEXT.EXPORT_RAPPORT}</Menu.Item>}


            {isTracker(record) && showMenuItem &&
                <Menu.Item icon={<ReloadOutlined/>} onClick={() => setRecordFrequencyInterval(record)}>
                    {updateIntervalText}
                </Menu.Item>
            }


        </Menu>
    }


    let tabTitle = props.benefitType ?  STATIC_TEXT[props.benefitType] : "Fordon/Utrustning"
    return (
        <>
            {mapOverviewData && (
                <>
                    <Tabs defaultActiveKey={'1'}>
                        <TabPane
                            key={'1'}
                            tab={<TabBadge
                                text={tabTitle}
                                overflowCount={9999999}
                                badgeColor={'#3f3f3f'}
                                count={activeData?.length}/>}
                        >
                            <Table
                                dataSource={activeData}
                                columns={tableColumnsWithTooltip(columns)}
                                rowKey={(v, _) => v?.imei}
                                scroll={{x: 1000}}
                                {...tableProps}
                            />
                        </TabPane>
                    </Tabs>

                    <MenuItemInterval record={recordFrequencyInterval} setRecordFrequencyInterval={setRecordFrequencyInterval}
                                      handleUpdateFreq={handleUpdateFreq}/>
                </>
            )}
        </>
    );
};
export default OverViewList;

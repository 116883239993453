export const styles = {
    scheduleForm: {
        display: "flex",
        flexDirection: "row",
        gap: 40,
    },
    selectDay: {
        width: 80,
        margin: 0,
    },
    column: {
        width: "50%",
        minWidth: 400
    },
    input: {
        width: "50%",
    },
    button: {
        width: "50%",
        marginRight: 0,
        justifyContent: "flex-end",
    },
    saveButton: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "15px",
    },
    interval: {
        display: "flex",
        gap: 15,
        alignItems: "center",
    },
    paragraph: {
        margin: 0,
        fontSize: "18px",
    },
};
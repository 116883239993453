export const REQUEST_FILL_CONTROL_STATE = 'REQUEST_FILL_CONTROL_STATE'
export const RECEIVE_CONTROL_STATE = 'RECEIVE_CONTROL_STATE'

export const FETCH_OVERVIEW_AWAIT = 'DATA/FETCH_OVERVIEW_AWAIT'
export const FETCH_OVERVIEW_OK = 'DATA/FETCH_OVERVIEW_OK'
export const FETCH_OVERVIEW_FAIL = 'DATA/FETCH_OVERVIEW_FAIL'
export const FETCH_JOURNALS_AWAIT = 'DATA/FETCH_JOURNALS_AWAIT'
export const FETCH_JOURNALS_OK = 'DATA/FETCH_JOURNALS_OK'
export const FETCH_JOURNALS_FAIL = 'DATA/FETCH_JOURNALS_FAIL'
export const JOURNALS_RESET = 'DATA/JOURNALS_RESET'

export const PATCH_JOURNAL_ENTRY = "DATA/PATCH_JOURNAL_ENTRY"

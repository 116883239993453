import { Nest } from "../../utils/utilities";
import {
    AuthorizationProvider,
    OrganizationSimpleProvider, shouldHideUsersOrganizationsColumn,
    UserListSimpleProvider,
    VehicleProvider, VehiclesComplexListContext, VehiclesComplexListProvider
} from "../../services/contexts/contexts";
import AlarmsZonesController from "../../components/trackson-components/AlarmsZones";

const AlarmsZonesPage = () => (
    <Nest wrappers={[ AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, VehicleProvider, UserListSimpleProvider, VehiclesComplexListProvider, OrganizationSimpleProvider]}>
        <AlarmsZonesController />
    </Nest>
);

export default AlarmsZonesPage;

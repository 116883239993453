import React, { createContext, useState, useEffect } from "react";
import { dataApi } from "services/api/api";

const AlarmsContext = createContext({});
const AlarmsContextProvider = ({ children }) => {

    useEffect(() => {
        console.log('TEST context alarm')

    }, [])



    return <AlarmsContext.Provider value={{

    }}>{children}</AlarmsContext.Provider>;
};
export { AlarmsContext, AlarmsContextProvider };

import {
    SuperAdminDevicesContextProvider
} from "../../contexts/SuperAdminDevicesContext"
import {Nest} from "../../utils/utilities";
import {OrganizationSimpleProvider, VehicleProvider} from "../../services/contexts/contexts";
import OrganizationsController from "../../components/trackson-components/Organizations";
import {OrganizationContextProvider} from "../../services/contexts/OrganizationsContext";
import {RootOrganisationsProvider} from "../../services/contexts/views-context/root-organisations-context";
import {UsersContextProvider} from "../../services/contexts/UsersContext";
import {DevicesContextProvider} from "../../services/contexts/DevicesContext";

const SuperOrganizationPage = () => (
    <Nest
        wrappers={[
            VehicleProvider,
            UsersContextProvider,
            DevicesContextProvider,
            OrganizationContextProvider,
            OrganizationSimpleProvider,
            SuperAdminDevicesContextProvider,
            RootOrganisationsProvider,]}>
        <OrganizationsController />
    </Nest>
);

export default SuperOrganizationPage;

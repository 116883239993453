import React, {useEffect, useState} from 'react';
import {Button, Card, Collapse, Input, Typography} from 'antd';
import {PhoneOutlined} from '@ant-design/icons';
import "./styles.css";
import {userApi} from "../../../services/api/api";

const { Title } = Typography;
const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const GuidesController = () => {


    return (
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: "wrap"}}>
            <Title>Guider</Title>
            <div style={{display: 'flex', flexDirection: 'row', height: '100%', width: '100%',
                justifyContent: 'center', alignItems: 'top', gap: '3em', }}>
                <GuideComponent />
                <OtherQuestionsComponent />
            </div>

        </div>
    )

}


const GuideComponent = () => {

    const [user, setUser] = useState();
    const [showAdminGuides, setShowAdminGuides] = useState(false)

    useEffect(() => {

        userApi.getCurrentUser()
            .then(user => {
                console.log("userId", user)
                setUser(user.userId)
                if(user.roles?.includes("ROOT") || user.roles?.includes("ADMIN")) {
                    setShowAdminGuides(true)
                }
            }, [])

    }, [])

    const panelParagraphStyle = { paddingLeft: 24, paddingBottom: 0 }
    const videoStyle={ maxWidth: '100%', paddingLeft: 24 }
    return (


        <Collapse
            accordion={true}
            bordered={false}
            className="guides-custom-collapse"
        >

            <Panel header="Installation av OBD-enhet" key="1" className="guides-custom-panel">
                <p style={panelParagraphStyle}>
                    Beställt en enhet som installeras i bilens OBD-uttag? Ta med dig enheten och medföljande förlängningskabel och gör som vår säljare Pär i videon.
                    Du vet att den är korrekt installerad när de två gröna LED-lamporna på enhetens nederkant blinkar, och efter en stund blir enheten synlig i systemet.
                </p>

                <iframe
                    src="https://player.vimeo.com/video/824014510?h=7d00561c78&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                    style={videoStyle}
                    allowFullScreen title="MONTERA_OBD"></iframe>

            </Panel>
            <Panel header="Installation av batterienhet" key="2" className="guides-custom-panel">
                <p style={panelParagraphStyle}>
                    Beställt en enhet som ska kopplas direkt på bilbatteriet? Leta fram en passande hylsnyckel och gör som vår säljare Pär i videon.
                    Du vet att den är korrekt installerad när de två gröna LED-lamporna på enhetens nederkant blinkar, och efter en stund blir enheten synlig i systemet.
                </p>
                <iframe
                    src="https://player.vimeo.com/video/824014438?h=3498016c48&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                    style={videoStyle}
                    allowFullScreen title="MONTERA_BATTERI"></iframe>
            </Panel>
            { showAdminGuides ?
                <Panel header="Registrera ny användare" key="3" className="guides-custom-panel">
                    <p style={panelParagraphStyle}>
                        Har ni fått en ny kollega? Lägg till denne som användare i systemet genom att följa guiden i videon.
                    </p>
                    <iframe
                        src="https://player.vimeo.com/video/824014554?h=ea6ac7f2b0&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style={videoStyle}
                        allowFullScreen title="NY_ANV"></iframe>
                </Panel>
                : ''}
            <Panel header="Lägga upp ett arbetstidsschema" key="4" className="guides-custom-panel">

                { showAdminGuides ?
                    // Text better suited for admin
                    <p style={panelParagraphStyle}>
                        Vill ni automatisera klassificeringen av tjänste- respektive privata resor?
                        Genom att lägga in ett arbetstidsschema för varje förare ser ni till att alla resor som startas inom den angivna tidsperioden får samma klassificering.
                    </p>
                    : // Text better suited for driver
                    <p style={panelParagraphStyle}>
                        Vill du automatisera klassificeringen av tjänste- respektive privata resor?
                        Genom att lägga in ditt arbetstidsschema ser du till att alla resor som startas inom den angivna tidsperioden får samma klassificering.
                    </p>
                }
                <iframe
                    src="https://player.vimeo.com/video/824014368?h=ced156af2b&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                    style={videoStyle}
                    allowFullScreen title="ARBETSTIDSSCHEMA"></iframe>
            </Panel>

            { showAdminGuides ?
                <Panel header="Schemalägga rapporter" key="5" className="guides-custom-panel">
                    <p style={panelParagraphStyle}>
                        Vill ni slippa ta ut rapporter manuellt varje månad? Vår säljare Pär har lösningen.
                    </p>
                    <iframe
                        src="https://player.vimeo.com/video/824014647?h=2010bcb45f&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style={videoStyle}
                        allowFullScreen title="RAPPORTER"></iframe>
                </Panel>
                : ''}
            { showAdminGuides ?
                <Panel header="Registrera nytt fordon" key="6" className="guides-custom-panel">
                    <p style={panelParagraphStyle}>
                        När ni får era enheter levererade av oss är de redan kopplade till ett fordon i systemet - se angivet registreringsnummer eller fordonsnummer på enheten.
                        Har ni däremot köpt ett nytt fordon och vill flytta en enhet till detta fordon behöver ni registrera det nya fordonet i systemet.
                    </p>
                    <iframe
                        src="https://player.vimeo.com/video/824014601?h=8f53d4af84&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style={videoStyle}
                        allowFullScreen title="NYTT_FORDON"></iframe>
                </Panel>
                : ''}
            { showAdminGuides ?
                <Panel header="Flytta enhet till ett annat fordon" key="7" className="guides-custom-panel">
                    <p style={panelParagraphStyle}>
                        För att flytta enheten till ett nytt fordon, följ instruktionerna i videon.
                    </p>
                    <iframe
                        src="https://player.vimeo.com/video/824014401?h=4120b43c47&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        width="750" height="422" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                        style={videoStyle}
                        allowFullScreen title="FLYTTA_ENHET"></iframe>
                    <p style={{ paddingLeft: 24 , paddingTop: 44}}>
                        Har ni fler fordon än ni har enheter? Hör av er till er kontaktperson eller kontakta oss på kundtjänst så hjälper vi er vidare.
                    </p>
                </Panel>
                : ''}

        </Collapse>

    )
}

const OtherQuestionsComponent = () => {

    return(

        <>
            <Card bordered={true} className='otherQuestionsCard' style={{minWidth: 300}}
            >

                <Title level={2}>Saknar du någon guide?</Title>
                <Text style={{whiteSpace: 'normal'}} >
                    <br/>
                    Vi arbetar just nu på att ta fram mer informationsmaterial.
                    Under tiden kanske vi kan få bjuda på en personlig guidning i systemet?
                    <br/>
                    <br/>
                    Tveka inte att höra av dig till oss!</Text>
                <br/>
                <br/>
                <br/>
                <a href='tel: +460104240940'>
                    <Button size="large" type='primary' style={{minWidth: '200px', width: '80%' ,margin: 'auto'}}>
                        <PhoneOutlined style={{ marginBottom: '4.5em'}}/>
                        010 - 424 09 40
                    </Button>
                </a>

            </Card>

        </>

    )
}

export default GuidesController




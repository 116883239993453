import {Nest} from "../utils/utilities";
import {
    AuthorizationProvider,
    DeviceListProvider,
    OrganizationsComplexListProvider,
    shouldHideUsersOrganizationsColumn,
    UserListProvider,
    VehiclesListWithArchivedProvider
} from "../services/contexts/contexts";
import {TrackersListController} from "../components/trackson-components/trackersList";
import {ServicesContextProvider} from "../services/contexts/ServicesContext";
import {VehiclesContextProvider} from "../services/contexts/VehiclesContext";

const TrackersListView = () => {

    return <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, UserListProvider,
            VehiclesListWithArchivedProvider, DeviceListProvider,
            OrganizationsComplexListProvider, ServicesContextProvider, VehiclesContextProvider]}>
        < TrackersListController/>
    </Nest>

}

export default TrackersListView

import {dataApi} from "../../../services/api/api";
import moment from 'moment-timezone';
import {
    Modal,
} from 'antd';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
export const handleUpdateOdometer = (vehicleId, values, updateOdometer) => {

    let tz = dayjs.tz.guess();
    let odometer;
    let req = {}
    req = {
        valueKm: values.valueKm,
        recordedTimeMs: values.applyRetroactive ? dayjs.tz(values?.recordedTimeMs, tz).unix() * 1000 : null,
        fromTimeMs: null,
        applyRetroactive: values.applyRetroactive
    }


    dataApi.putUpdateOdometer(vehicleId, req)
        .then(r => {
            odometer = r?.odometer / 1000
            showOdometerPopup()


            if (updateOdometer && odometer) {
                updateOdometer(values, vehicleId, odometer)
                console.log(odometer)
            }


        })

}

export const showOdometerPopup = () => {
    Modal.success({
        content: (
            <div>
                <p>Mätarställning uppdaterad.</p>
                <p>Det kan dröja några minuter innan ditt fordon visar det uppdaterade värdet.</p>
            </div>
        ),
    });
}

export const getOdometerValue = (vehicle) => vehicle?.odometer?.valueKm ? vehicle?.odometer?.valueKm?.toLocaleString() + " km" : "Info saknas"


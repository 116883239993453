import React from "react";
import EcoScore from "components/trackson-components/EcoScore/EcoScore";
import {OrganizationContextProvider} from "services/contexts/OrganizationsContext";
import {VehiclesContextProvider} from "services/contexts/VehiclesContext";
import {UsersContextProvider} from "services/contexts/UsersContext";
import {EcoContextProvider} from "services/contexts/EcoContext";
import {AuthorizationProvider, SelectDefaultVehicle, VehicleContext, VehicleProvider} from "services/contexts/contexts";

const EcoScorePage = () => {
    return (
        <AuthorizationProvider>
                <OrganizationContextProvider>
                        <VehicleProvider>
                            <EcoContextProvider>
                                <SelectDefaultVehicle.provider>
                                    <EcoScore/>
                                </SelectDefaultVehicle.provider>
                            </EcoContextProvider>
                        </VehicleProvider>
                </OrganizationContextProvider>
        </AuthorizationProvider>
    );
};

export default EcoScorePage;

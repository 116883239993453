import React, {useEffect, useState} from "react";
import {dataApi} from "../api/api";

export const DeviceViewContext = React.createContext(null)
export const DeviceViewProvider = (props) => {
    const [dataSource, setDataSource] = useState();
    const [filteredList, setFilteredList] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dataApi.getDevices().then(e => {
            console.log(e)
            setDataSource(e)
            setFilteredList(e)
            setLoading(false)
        })
    }, [])

    const handleChange = event => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
    };


    useEffect(() => {
        let filteredList = dataSource

        if (searchTerm.length > 1)
            filteredList = filteredList?.filter(
                item => JSON.stringify(item).toLowerCase().includes(searchTerm)
            );

        if (selectedOrganization?.length > 0) {
            filteredList = filteredList?.filter(
                i => selectedOrganization.includes(i.organizationId)
            )
        }

        setFilteredList(filteredList)
    }, [searchTerm, dataSource, selectedOrganization])

    return <DeviceViewContext.Provider value={{
        filteredList,
        selectedOrganization,
        handleChange,
        organizationSelectOnChange,
        loading,
        setLoading,
        searchTerm,

    }}>{props.children}</DeviceViewContext.Provider>

}
import {
    Alert,
    AutoComplete,
    BackTop,
    Button,
    Card,
    Checkbox,
    ConfigProvider,
    DatePicker,
    Dropdown,
    Divider,
    Form,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Radio,
    Select,
    Skeleton,
    Switch,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";

import * as icons from "@ant-design/icons";
import {API_BASE_URL} from "configs/AppConfig";
import moment from "moment";
import React, {useContext, useEffect, useMemo, useReducer, useRef, useState} from "react";
import {Icon} from "react-icons-kit";
import {location} from "react-icons-kit/icomoon";
import "./JourneyPageStyles.css";
import {Resizable} from 'react-resizable';

import {dataApi} from "services/api/api";
import {
    changeTimeInTimestamp,
    convertTimestampToDateStr,
    extractedDate,
    formatFromMillis,
    formatMillis,
    formatSwedishNumber
} from "utilities";

import Sweden_road_sign from "../assets/svg/Sweden_road_sign_E25.svg";
import Sweden_road_sign_white from "../assets/svg/Sweden_road_sign_E25_white.svg";
import Flex from "../components/shared-components/Flex/Flex";
import Loading from "../components/shared-components/Loading/Loading";
import {CardSelectOrganizationForRoot} from "../components/trackson-components/shared-components/sharedComponents";
import {TripLogbookContext, TripLogbookProvider} from "../contexts/TripLogbookContext"
import {
    AuthorizationContext,
    AuthorizationProvider,
    InitialDateAttest,
    OrganizationSimpleContext,
    OrganizationSimpleProvider,
    SelectDefaultVehicle,
    TripContext,
    UserListSimpleContext,
    UserListSimpleProvider,
    VehicleContext,
    VehicleProvider,
} from "../services/contexts/contexts";
import {ORGANIZATION_ID} from "../services/redux/constants/authConstants";
import {jwt_xhrp, xhr_dejson} from "../trx";
import {OrganizationsTreeSelect} from "../components/trackson-components/shared-components/OrganizationsTreeSelect";
import ShowRouteModal from "../components/trackson-components/journey-routes-modal";
import {monthsLookUp} from "../constants/MonthsLookUp";
import {reportsTypesLookUp, STATIC_TEXT, tripTypes} from "../lang/translate/sv-SE/sv-SE";
import {CONSTANTS_TEXT, SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../constants/CustomConstant";
import {Nest} from "../utils/utilities";
import {VehiclesSelect} from "../components/trackson-components/shared-components/VehiclesSelect";
import {UsersSelect} from "../components/trackson-components/shared-components/UsersSelect";
import {customizeRenderEmpty} from "../components/trackson-components/shared-components/customizeRenderEmpty";

const {Title} = Typography;
const {Option} = Select;
const {RangePicker} = DatePicker;
const {Text} = Typography;

const TableJourneys = (sliceCount, currentUser,
                       userId, doLightAttest, doCorrect, turnOffDevCommentFlag, knownUsers, onSetDriver,
                       showModalWarningPurpose, patchJournalEntry, logbook, findNextTrip, checkMergeButton,
                       showMapWithRoutes, handleUnmerging, handleMergingClick, journalState,
                       setShowAlertAtestRefuse, rootEditMode, setRootEditMode, setShowJourneyHistory, selectedJourney,
                       columnWidths) => {
    const thisIsROOT = currentUser?.roles?.includes("ROOT");
    const currentUser2 = currentUser

    const handleShowLogs = (journal_id) => {
        setShowJourneyHistory(journal_id)
    }

    const handleDeleteClick = (journal_id) => {
        Modal.confirm({
            title: 'Är du säker?',
            content: 'Vill du ta bort den här resan? Det går inte att ångra.',
            okText: 'Ja, ta bort',
            okType: 'danger',
            cancelText: 'Avbryt',
            onOk() {
                logbook.deleteAdminTrip(journal_id)
            },
            onCancel() {
            },
        });
    };

    function renderStatus(value, o, idx) {
        value = o.data.status;
        let tripId = o.data.journal_id;
        const checked = o.data.lightAttested;
        let showTooltip = isNotCurrentUsersTrip(o);

        //show date as sticky panel
        if (o.sticky) {
            return <div style={{backgroundColor: "red", width: "100%"}}>{o.data.description}</div>
        }

        switch (value) {
            case "NONE":
                return (
                    <div
                        onClick={() => (showTooltip ? null : doLightAttest(!checked, tripId))}
                        id={idx}
                        style={{
                            fontSize: "1.4em",
                            opacity: "100%",
                            cursor: "pointer",
                        }}
                    >
                        <Tooltip
                            title={showTooltip ? "Du kan endast bekräfta dina egna resor" : "Ej bekräftad"}>
                            <icons.EyeOutlined
                                style={{
                                    color: SD_BASE_COLORS.BLUE,
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            case "CHECKED":
                return (
                    <div
                        onClick={() => (showTooltip ? null : doLightAttest(!checked, tripId))}
                        id={idx}
                        style={{fontSize: "1.4em", opacity: "100%", cursor: "pointer"}}
                    >
                        <Tooltip
                            title={showTooltip ? "Du kan endast avbekräfta dina egna resor" : "Bekräftad"}>
                            <icons.CheckOutlined style={{color: SD_BASE_COLORS.GREEN, marginRight: 5}}/>
                        </Tooltip>
                    </div>
                );
            case "LOCKED":
                return (
                    <div id={idx}
                         style={{opacity: "100%", cursor: "pointer"}}>
                        <Tooltip title={"Attesterad"}>
                            <icons.LockOutlined
                                style={{
                                    fontSize: "1.4em",
                                    opacity: "100%",
                                    color: SD_BASE_COLORS.YELLOW,
                                    marginRight: 5,
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            case "BAD":
                let adminComment = o?.data?.adminComment;
                return (
                    <div
                        onClick={() => (showTooltip ? null : doCorrect(true, tripId))} id={idx}
                        style={{opacity: "100%", cursor: "pointer"}}>
                        <Tooltip
                            title={showTooltip ? "Du kan endast korrigera dina egna resor" : adminComment}>
                            <icons.FlagFilled style={{
                                fontSize: "1.4em",
                                opacity: "100%",
                                color: "#bf0e0e",
                                marginRight: 5
                            }}/>
                        </Tooltip>
                    </div>
                );
            case "CORRECTED":
                return (
                    <div
                        onClick={() => (showTooltip ? null : doCorrect(false, tripId))} id={idx}
                        style={{opacity: "100%", cursor: "pointer"}}>
                        <Tooltip
                            title={showTooltip ? "Du kan endast avkorrigera dina egna resor" : "Korrigerad"}>
                            <icons.FlagFilled
                                style={{
                                    fontSize: "1.4em",
                                    opacity: "100%",
                                    color: SD_BASE_COLORS.GREEN,
                                    marginRight: 5,
                                }}
                            />
                        </Tooltip>
                    </div>
                );

            case "DONE":
                return (
                    <div id={idx}
                         style={{opacity: "100%", cursor: "pointer"}}>
                        <Tooltip title={"Godkänd"}>
                            <icons.FileDoneOutlined
                                style={{
                                    fontSize: "1.4em",
                                    opacity: "100%",
                                    color: SD_BASE_COLORS.GREEN,
                                    marginRight: 5,
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            default:
                return "foo";
        }
    }

    function renderDevComment(o, idx) {
        let devComment = o.data.devComment;
        let tripId = o.data.journal_id;
        const isMyTrip = o?.data?.driver?.id === userId;
        const isDisabled = o?.data?.status === "LOCKED" || o?.data?.status === "CORRECTED" || o?.data?.status === "DONE";

        const renderToolTip = (devComment) => {
            let translatedComments = [];
            if (devComment || devComment !== null) {
                devComment.map((comment) => {
                    switch (comment) {
                        case "INCOMPATIBLE_PURPOSES":
                            return translatedComments.push("Icke-kompatibla resetyper");
                    }
                });
                return (
                    <>
                        {translatedComments.map((comment) => {
                            return <p>{comment}</p>;
                        })}
                        {isMyTrip && !isDisabled ? <p style={{marginBottom: 0}}>Klicka för att släcka denna
                            flagga.</p> : null} {/* for test */}
                    </>
                );
            } else {

            }
        };

        return (
            devComment && (
                <div
                    onClick={() => (devComment && isMyTrip ? turnOffDevCommentFlag(null, tripId) : null)}
                    id={idx}
                    className={"active-div flex"}
                    style={{
                        fontSize: "1.1em",
                        opacity: "100%",
                        cursor: "pointer",
                    }}
                >
                    <Tooltip title={isMyTrip ? renderToolTip(devComment) : null}>
                        <icons.FlagFilled
                            style={{
                                fontSize: "1.1em",
                                opacity: "100%",
                                color: SD_BASE_COLORS.YELLOW,
                                marginRight: 5,
                            }}
                        />
                    </Tooltip>
                </div>
            )
        );
    }

    function isPrivateTrip(o) {
        return o.data.trip?.purpose === "PRIVATE";
    }

    function tripsColor(o) {
        return isPrivateTrip(o) ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS
    }

    function isNotCurrentUsersTrip(o, id) {
        return o?.data?.driver?.id !== userId;
    }

    function hasDriver(o) {
        return o.data.driver?.id !== null;
    }

    const DebugButton = (record) => {
        return (
            rootEditMode.active && rootEditMode.tripId === record?.data.journal_id &&
            <div style={{position: "absolute", top: 0, left: 0,}} className="hoverParent">
            <span className="hoverContent">
                #{record?.data?.journal_id}
            </span>
            </div>
        );
    };

    const renderisTripCorrected = (record) => {
        const editedTrip = record?.data?.overrideTsMs
        return (
            editedTrip && <div
                style={{
                    fontSize: "1.4em",
                    opacity: "100%",
                }}
            >
                <Tooltip
                    title={"Resan är korrigerad"}>
                    <icons.EditOutlined

                    />
                </Tooltip>
            </div>
        );
    };

    const columns = [
        /*      {
                  dataIndex: "sticky",
                  key: "sticky",
                  width: 0
              },*/
        {
            dataIndex: "status",
            key: "status",
            title: "Status",
            width: 75,
            show: true,
            fixedWidth: true,
            align: "center",
            render: (value, record, index) => {
                return (
                    <div style={{pointerEvents: "visible"}}>
                        {DebugButton(record, rootEditMode)}
                        {renderDevComment(record, index)}
                        {renderStatus(value, record, index)}
                        {renderisTripCorrected(record)}
                    </div>
                );
            },
        },
        {
            title: "Regnr",
            dataIndex: "vehicle",
            key: "vehicle",
            width: 100,
            render: (value, o, idx) => {
                return <Tag>{o.data.vehicle?.reg}</Tag>;
            },
        },
        {
            title: "Tid",
            dataIndex: "timestamp",
            width: 100,
            key: "timestamp_start_ms",
            render: (r, o, idx) => {
                const {data} = o;
                const disabled = !thisIsROOT ? true : isPrivateTrip(o)
                const startTime = convertTimestampToDateStr(data?.trip?.timestamp_start_ms, "HH:mm", false);
                const stopTime = convertTimestampToDateStr(data?.trip?.timestamp_stop_ms, "HH:mm", false);
                return isNotCurrentUsersTrip(o) && (isPrivateTrip(o)) ? null : (
                    <Flex flexDirection={"column"}>
                        <EditableText
                            disabled={!(rootEditMode.active && rootEditMode.tripId === o.data.journal_id)}
                            text={stopTime}
                            placeholder={"Ange när resan slutade (HH:mm)"}
                            onTextChange={(newValue) => {
                                const timestamp = changeTimeInTimestamp(data?.trip?.timestamp_stop_ms, newValue)
                                patchJournalEntry(o.data.journal_id, {timestamp_stop_ms: timestamp}, idx, idx);
                            }}
                        />
                        <EditableText
                            disabled={!(rootEditMode.active && rootEditMode.tripId === o.data.journal_id)}
                            text={startTime}
                            placeholder={"Ange när resan började (HH:mm)"}
                            onTextChange={(newValue) => {
                                const timestamp = changeTimeInTimestamp(data?.trip?.timestamp_start_ms, newValue)
                                patchJournalEntry(o.data.journal_id, {timestamp_start_ms: timestamp}, idx, idx);
                            }}
                        />
                    </Flex>

                );
            },
        },
        {
            title: "Adress",
            dataIndex: "address_start",
            key: "address",
            width: 200,
            ellipsis: true,

            render: (r, o, idx) => {
                const ifDisabled = (!hasDriver(o) || isNotCurrentUsersTrip(o, userId)) && (isPrivateTrip(o));
                const start = o.data?.geocode_start?.format
                const stop = o.data?.geocode_stop?.format
                let overrideStart = o.data.override_start
                let overrideStop = o.data.override_stop
                if (ifDisabled) {
                    overrideStop = "— Sekretess —"
                    overrideStart = "— Sekretess —"
                }
                return (
                    <Flex flexDirection={"column"}>
                        <Flex alignItems={"center"}>
                            {overrideStop && !ifDisabled &&
                                <Tooltip title={stop && "Ursprunglig adress: " + stop}>
                                    <icons.EditOutlined
                                        style={{marginLeft: "8.5px"}}
                                    />
                                </Tooltip>
                            }
                            <EditableText
                                disabled={ifDisabled}
                                placeholder={"Ange var resan avslutades"}
                                text={overrideStop || stop}
                                customStyle={{fontWeight: overrideStop && !ifDisabled && "bold"}}
                                onTextChange={(newValue) => {
                                    patchJournalEntry(o.data.journal_id, {override_stop: newValue || ""}, idx, idx);
                                }}
                            />
                        </Flex>

                        <Flex alignItems={"center"}>
                            {overrideStart && !ifDisabled &&
                                <Tooltip title={start && "Ursprunglig adress: " + start}>
                                    <icons.EditOutlined
                                        style={{marginLeft: "8.5px"}}
                                    />
                                </Tooltip>
                            }
                            <EditableText
                                disabled={ifDisabled}
                                placeholder={"Ange var resan börjades"}
                                customStyle={{fontWeight: overrideStart && !ifDisabled && "bold"}}
                                text={overrideStart || start}
                                onTextChange={(newValue) => {
                                    patchJournalEntry(o.data.journal_id, {override_start: newValue || ""}, idx, idx);
                                }}
                            />


                        </Flex>
                    </Flex>
                )
            },
        },
        {
            title: "Förare",
            dataIndex: "driver",
            width: 200,
            key: "driver",
            render: (r, o, idx) => {
                const {data} = o;
                const disabled = isNotCurrentUsersTrip(o) && hasDriver(o) && isPrivateTrip(o)
                const loggedInUser = knownUsers?.find(user => user.userId === userId);
                let selectedOptions = [];
                selectedOptions.push(<Option value={"-1"}>--Ingen förare--</Option>);
                if (loggedInUser && !loggedInUser.isArchived && loggedInUser.organizationId === data?.organizationId) {
                    selectedOptions.push(<Option value={loggedInUser.userId}>{loggedInUser.displayName}</Option>);
                }
                selectedOptions = selectedOptions.concat(knownUsers?.filter(user => user.userId !== userId && !user.isArchived && user?.organizationId === data?.organizationId)
                    ?.map((v) => {
                        return <Option label={"hhh"} value={v.userId}>{v.displayName}</Option>;
                    }))

                return (
                    <EditableText
                        disabled={disabled}
                        selectedOptions={selectedOptions}
                        entryType={"select"}
                        text={data.driver?.id}
                        customText={data.driver?.displayName}
                        customStyle={{whiteSpace: "normal", overflow: "visible", color: tripsColor(o)}}
                        onTextChange={(newValue) => {
                            let newDriver = knownUsers?.find(user => user.userId === newValue);
                            onSetDriver(newDriver, data.journal_id, data)
                        }}
                    />
                );
            },
        },
        {
            title: "Restid",
            dataIndex: "total_time",
            key: "total_time",
            width: 100,
            render: (r, o, idx) => {
                const {data} = o;
                // const travelLengthMinutes = ((data.trip?.timestamp_stop_ms - data.trip?.timestamp_start_ms) / 60000).toFixed(2)
                return isNotCurrentUsersTrip(o) && (isPrivateTrip(o)) ? null : (
                    <EditableText
                        disabled={true}
                        text={formatMillis(data.trip?.timestamp_stop_ms, data.trip?.timestamp_start_ms)}
                        customStyle={{color: tripsColor(o)}}
                        onTextChange={(newValue) => {
                        }}
                    />

                    /*   <div
                               style={{
                                   display: "flex",
                                   flexDirection: "row",
                                   flexWrap: "none",
                                   justifyContent: "space-between",
                                   alignItems: "center",
                               }}
                           >
                                   <span
                                       style={colorTripType(o.data.trip)}>{formatMillis(data.trip?.timestamp_stop_ms, data.trip?.timestamp_start_ms)}</span>
                           </div>*/
                );
            },
        },

        {
            title: "Avstånd",
            dataIndex: "odometer_total",
            key: "distance",
            width: 100,
            render: (r, o, idx) => {
                const {data} = o;
                const disabled = !thisIsROOT ? true : isPrivateTrip(o)
                const total = ((data.trip?.odometer_max) * 0.001).toFixed(2);
                return (
                    <EditableText
                        disabled={!(rootEditMode.active && rootEditMode.tripId === o.data.journal_id)}
                        text={total}
                        customEditableText={total + " km"}
                        suffix={"km"}
                        customStyle={{color: tripsColor(o)}}
                        placeholder={"Ange avstånd (km)"}
                        onTextChange={(newValue) => {
                            console.log("new value", newValue);

                            function kmToMeters(km) {
                                return km * 1000;
                            }

                            const meters = kmToMeters(newValue);

                            const showConfirm = () => {
                                Modal.confirm({
                                    title: 'Kort avstånd',
                                    icon: <icons.ExclamationCircleOutlined/>,
                                    content: 'Resor med avstånd under 200 m kommer att raderas från körjournalen',
                                    onOk() {
                                        console.log('OK');
                                        patchJournalEntry(o.data.journal_id, {odometer_max: meters}, idx, idx);

                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                });
                            };

                            if (meters < 200) {
                                showConfirm()
                            } else {
                                patchJournalEntry(o.data.journal_id, {odometer_max: meters}, idx, idx);
                            }

                        }}
                    />

                    /*<div className="d-table" style={colorTripType(o.data.trip)}>
                        <span className="d-table-row">{total} km</span>
                    </div>*/
                );
            },
        },

        {
            title: "Stopptid",
            dataIndex: "stopTimeBackwards",
            key: "stopTimeBackwards",
            width: 100,
            render: (value, o, idx) => {
                return isNotCurrentUsersTrip(o) && (isPrivateTrip(o)) ? null : (
                    <EditableText
                        disabled={true}
                        customStyle={{color: tripsColor(o)}}
                        text={o.data.stopTimeBackwards ? formatFromMillis(o.data.stopTimeBackwards) : null}
                        onTextChange={(newValue) => {
                        }}
                    />
                );
            },
        },
        {
            title: "Mätarställning",
            dataIndex: "odometer_start",
            key: "odometers",
            width: 150,
            render: (r, o, idx) => {
                const {data} = o;
                const editableText = formatSwedishNumber((data.trip?.odometer_stop / 1000).toFixed(0));
                const editableText2 = formatSwedishNumber((data.trip?.odometer_start / 1000).toFixed(0));
                return (
                    <Flex flexDirection={"column"}>
                        <EditableText
                            show={true}
                            disabled={true}
                            customEditableText={editableText + " km"}
                            suffix={"km"}
                            text={editableText}
                            onTextChange={(newValue) => {
                            }}
                        />
                        <EditableText
                            text={editableText2}
                            disabled={true}
                            customEditableText={editableText2 + " km"}
                            suffix={"km"}
                            onTextChange={(newValue) => {
                            }}
                        />
                    </Flex>
                );
            },
        },

        {
            title: "Syfte",
            dataIndex: "description",
            width: 200,
            render: (r, o, idx) => {
                const isUsersTrip = o.data.driver?.id === userId;
                const tripId = o.data.journal_id
                const options = currentUser?.purposes?.map((i) => {
                    return {value: i};
                });
                const enabledState = o.data.trip?.purpose.startsWith("BUSINESS");
                let checked = true;
                let adminsComment = o.data.adminComment || "";
                const showAdminComment = false;
                return (
                    <Flex flexDirection={"column"}>
                        <Switch
                            style={{
                                backgroundColor: enabledState ? SD_BASE_COLORS.SWITCH_BUSINESS : SD_BASE_COLORS.SWITCH_PRIVATE,
                                width: 80,
                                margin: "8.5px 11px"

                            }}
                            checkedChildren="Tjänst"
                            unCheckedChildren="Privat"
                            disabled={!thisIsROOT && o.data.driver?.id !== userId && o.data.driver?.id !== null && (o.data.trip?.purpose === "PRIVATE_AUTO" || o.data.trip?.purpose === "PRIVATE")}
                            onChange={(val) => {
                                if (val === false && !isUsersTrip && hasDriver(o)) {
                                    showModalWarningPurpose(o, idx, val, idx);
                                } else {
                                    patchJournalEntry(o.data.journal_id, {purpose: val ? "BUSINESS" : "PRIVATE"}, idx, idx);
                                    checked = !checked;
                                }
                            }}
                            checked={enabledState}
                        />

                        {/*<EditableText
                            disabled={!thisIsROOT && isNotCurrentUsersTrip(o) && o.data.driver?.id !== null && (isPrivateTrip(o))}
                            entryType={"tripType"}
                            customText={tripTypes[o.data.trip?.purpose]}
                            customStyle={{color: tripsColor(o), fontWeight: 500}}
                            text={o.data.trip?.purpose}
                            onTextChange={(newValue) => {
                                if (newValue === CONSTANTS_TEXT.PRIVATE && !isUsersTrip) {
                                    showModalWarningPurpose(o, idx, newValue, idx);
                                } else {
                                    patchJournalEntry(o.data.journal_id, {purpose: newValue}, idx, idx);
                                }
                            }}
                        />*/}

                        <EditableText
                            text={o.data.trip?.description}
                            disabled={isNotCurrentUsersTrip(o) && o.data.driver?.id !== null && (isPrivateTrip(o))}
                            entryType={"description"}
                            placeholder={"Ange syfte"}
                            customClass={"description-row"}
                            options={options}
                            onTextChange={(newValue) => {
                                patchJournalEntry(o.data.journal_id, {description: newValue}, idx, idx);
                            }}
                        />

                        {/*              <TimerInputSubmitter disabled={isUsersTrip} tripId={o.data.journal_id}
                                             value={o.data.trip.description} options={options} o={o}
                                             userId={userId}/>*/}
                    </Flex>
                );
            },
        },
        {
            title: "Rutt",
            dataIndex: "purpose",
            show: true,
            width: 175,
            minWidth: 175,
            fixedWidth: true,
            render: (r, o, idx) => {
                let isMerged = o?.data?.merged === true;
                let taxSek = o?.data?.congestionTaxSekOverride !== null ? o?.data?.congestionTaxSekOverride : o?.data?.congestionTaxSek || 0;
                let infraTaxSek = o?.data?.infraTaxSekOverride !== null ? o?.data?.infraTaxSekOverride : o?.data?.infraTaxSek || 0;
                const enabledState = o?.data?.congestionTaxCount || o?.data?.congestionTaxSekOverride || o?.data?.infraTaxSekOverride || o?.data?.infraTaxCount;
                let image = enabledState ? Sweden_road_sign : Sweden_road_sign_white;
                const showMergeButton = checkMergeButton(o?.data, idx);
                const modifiedTrip = o?.data?.overrideTsMs
                return (
                    <Flex
                        style={{maxWidth: "175px"}} flexDirection={"column"} gap={10}>
                        <Tooltip title={modifiedTrip ? "Karta inaktiverad pga korrigerad resa." : null}>
                            <Button
                                className={"active-div"}
                                style={{
                                    width: "100%",
                                    opacity: 0.9,
                                    cursor: "pointer",
                                }}
                                disabled={modifiedTrip || isNotCurrentUsersTrip(o) && (isPrivateTrip(o))}
                                onClick={(ev) => {
                                    showMapWithRoutes(ev, o);
                                }}
                                size="small"
                            >
                                <Flex gap={8}>
                                    <Icon icon={location}/>
                                    {selectedJourney?.data?.journal_id === o.data.journal_id ?
                                        "Dölj kartan" : "Visa kartan"}
                                </Flex>
                            </Button>
                        </Tooltip>

                        {isMerged && (
                            <Button
                                icon={<icons.ArrowsAltOutlined/>}
                                onClick={(e) => {
                                    handleUnmerging(o, idx);
                                }}
                                size="small"
                            >
                                Bryt isär resor
                            </Button>
                        )}

                        {!isMerged && showMergeButton && (
                            <Button
                                disabled={isNotCurrentUsersTrip(o) && !currentUser?.roles?.includes("ADMIN") && !currentUser?.roles?.includes("ROOT")}
                                // !currentUser?.roles?.includes('ADMIN') && !currentUser?.roles?.includes('ROOT')
                                icon={<icons.LinkOutlined/>}
                                onClick={(e) => {
                                    handleMergingClick(o.data.journal_id, idx, o);
                                }}
                                size="small"
                            >
                                Slå ihop med nästa
                            </Button>
                        )}

                        <Button
                            className={"active-div"}
                            style={{
                                width: "100%",
                                opacity: 0.9,
                                cursor: "pointer",
                            }}
                            disabled={isNotCurrentUsersTrip(o) && (isPrivateTrip(o))}
                            onClick={(ev) => {
                                showMapWithRoutes(ev, o);
                            }}
                            size="small"
                        >
                            <Flex gap={8}>
                                <img alt="Sweden road sign E25" width={23} src={image}/>
                                <Typography.Text
                                    style={{color: (enabledState) ? SD_BASE_COLORS.RED : null}}>{taxSek + infraTaxSek} kr</Typography.Text>
                            </Flex>
                        </Button>

                        {rootEditMode.tripId === o.data.journal_id && <Button
                            className={"active-div"}
                            style={{
                                width: "100%",
                                opacity: 0.9,
                                cursor: "pointer",
                            }}
                            onClick={(ev) => {
                                handleShowLogs(o.data.journal_id)
                            }}
                            size="small"
                        >
                            <Flex gap={8}>Journalhistorik</Flex>
                        </Button>}

                        {rootEditMode.tripId === o.data.journal_id && <Button
                            className={"active-div"}
                            type={"danger"}
                            style={{
                                width: "100%",
                                opacity: 0.9,
                                cursor: "pointer",
                            }}
                            onClick={(ev) => {
                                handleDeleteClick(o.data.journal_id)
                            }}
                            size="small"
                        >
                            <Flex gap={8}>Ta bort resa</Flex>
                        </Button>}


                        {thisIsROOT && <Button
                            className={"active-div"}
                            style={{
                                width: "100%",
                                opacity: 0.9,
                                cursor: "pointer",
                            }}
                            onClick={(ev) => {
                                if (rootEditMode.tripId === o.data.journal_id) {
                                    setRootEditMode({
                                        active: false,
                                        tripId: null
                                    })
                                } else {
                                    setRootEditMode({
                                        active: true,
                                        tripId: o.data.journal_id
                                    })
                                }
                            }}
                            size="small"
                        >
                            <Flex gap={8}>
                                {rootEditMode.active && rootEditMode.tripId === o.data.journal_id ? "Inaktivera Superadm." : "Superadminläge"}
                            </Flex>
                        </Button>}

                    </Flex>
                );
            },
        },
        //this extra col must be here for unbreakable table with resizable columns
        {
            dataIndex: "space",
            show: true
        }
    ].filter((item) => !item.hidden);


    const newColumns = columns?.map(col => {
        const tableElement = document.getElementsByClassName('ant-layout-content')[0];
        if (tableElement) {
            const tableWidth = tableElement?.clientWidth - 50
            let summaColumnsWidth = 0
            columns.map(item => summaColumnsWidth += item.width || 0)
            let addressWidth;
            if (tableWidth > summaColumnsWidth) {
                addressWidth = col.width + tableWidth - summaColumnsWidth
            }
            return {
                ...col,
                width: columnWidths[col.dataIndex] ? columnWidths[col.dataIndex] :
                    (addressWidth && col.dataIndex === "address_start" ? addressWidth : col.width),
                onCell: record => ({
                    record,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: record.sticky,
                    fixed: col.fixed,
                }),
            };
        }
    });
    return {newColumns};
}

function resizableColumns(mergedColumns, setMergedColumns) {


    const handleResize = (index, column) => (e, props) => {
        setMergedColumns((currentColumns) => {
            const nextColumns = [...currentColumns];
            const newWidth = props.size.width > (column.minWidth || 50) ? props.size.width : column.minWidth;
            nextColumns[index] = {
                ...nextColumns[index],
                width: newWidth,
            };
            return nextColumns;
        });
    };

    const mappedColumns = mergedColumns?.map((col, index) => ({
        show: true,
        ...col,
        ellipsis: true,
        // width: 100,
        onHeaderCell: (column) => ({
            width: col?.dataIndex === "sticky" ? 100 : column.width,
            onResize: handleResize(index, column),
            fixedWidth: col.fixedWidth,
            ellipsis: true,

        }),
    }));
    return mappedColumns;
}

function reloadOnScroll(setSliceCount, SLICE_COUNT, sliceCount, journalState) {
    useEffect(() => {
        if (sliceCount.stop >= journalState?.length) return

        const tableContents = document.getElementsByClassName('ant-table-body');
        if (tableContents.length > 0) {
            const tableContent = tableContents[0];

            const handleScroll = (event) => {
                let maxScroll = event.target.scrollHeight - event.target.clientHeight;
                let currentScroll = event.target.scrollTop;
                let scrollRemaining = maxScroll - currentScroll;
                if (scrollRemaining < 100) {
                    setSliceCount(prevState => ({
                        start: 0,
                        stop: prevState?.stop + SLICE_COUNT
                    }));
                }

            };
            tableContent.addEventListener('scroll', handleScroll);
            return () => tableContent.removeEventListener('scroll', handleScroll);
        }
    });
}

function showHideColumns(columns, showColumns, setShowColumns) {
    const menuColumns = (
        <Menu onClick={(v) => console.log(v)} style={{minWidth: 250}}>

            {columns?.map(item => {
                if (!item.title) return
                if (item.dataIndex === "space") return
                if (item.dataIndex === "status") return
                if (item.dataIndex === "purpose") return
                return <Menu.Item key={item.dataIndex} disabled>
                    <Checkbox value={item.dataIndex}
                              checked={showColumns?.[item.dataIndex]}
                              onChange={(v) =>
                                  setShowColumns({
                                      ...showColumns,
                                      [v.target.value]: v.target.checked
                                  })}
                    >{item.title}</Checkbox>
                </Menu.Item>
            })}
            <Flex justifyContent={"between"} style={{margin: 8}}>
                <Menu.Item disabled>
                    <a onClick={() => setShowColumns(Object.fromEntries(Object.entries(showColumns).map(([key, _]) => [key, false])))}>
                        Dölj alla
                    </a>
                </Menu.Item>
                <Menu.Item disabled>
                    <a onClick={() => setShowColumns(Object.fromEntries(Object.entries(showColumns).map(([key, _]) => [key, true])))}>
                        Visa alla
                    </a>
                </Menu.Item>
            </Flex>
        </Menu>
    );
    return menuColumns;
}

function drawerResize(dragHandleRef, setDrawerWidth) {
    const handleMouseDown = (e) => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (dragHandleRef.current) {
            const newWidth = window.innerWidth - e.clientX;
            const minWidth = 300;
            const maxWidth = window.innerWidth - 800;
            const clampedWidth = Math.max(minWidth, Math.min(newWidth, maxWidth));
            setDrawerWidth(clampedWidth);
        }
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };
    return handleMouseDown;
}

const Journey = () => {
    const logbook = useContext(TripLogbookContext)
    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)
    const knownUsers = useContext(UserListSimpleContext);
    const knownVehicles = useContext(VehicleContext).raw;
    const currentUser = useContext(AuthorizationContext);
    const defaultVehicle = useContext(SelectDefaultVehicle.context);
    const [selectedJourney, setSelectedJourney] = useState(null);
    const initMergeData = {
        // journalId: null,
        journal: null,
        showMergeModal: false,
        description1: null,
        description2: null,
        selectedDescription: null,
        id1: null,
        id2: null,
        preSelectedTripId: null,
    };
    const initialColumnsWidths = {
        custom: 100,
        sticky: 100,
        status: 25,
        vehicle: 60,
        timestamp: 60,
        address_start: 200,
        driver: 100,
        total_time: 60,
        odometer_total: 60,
        stopTimeBackwards: 60,
        odometer_start: 100,
        description: 100,
        purpose: 100,
    };
    let initialPickerValue = [
        moment().startOf('month'),
        moment().endOf('day')
    ];
    const journals = logbook.journalState
    const loadingJournals = logbook.loadingJournals;
    const patchJournalEntry = (journalId, lightAttest) => logbook.patchJournalEntry(journalId, lightAttest);
    const [selectMergeData, setSelectMergeData] = useState(initMergeData);
    const [journalState, setFilteredJournals] = useState(null);
    const [isShowRefusedTrips, setIsShowRefusedTrips] = useState(false);
    const [showAlertAtestRefuse, setShowAlertAtestRefuse] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [loading, setLoading] = useState(true);
    let SLICE_COUNT = 50;
    const initialSliceCount = {start: 0, stop: SLICE_COUNT};
    const [sliceCount, setSliceCount] = useState(initialSliceCount);
    const [selectedDriversToShowInJourney, setSelectedDriversToShowInJourney] = useState();
    const [selectedVehicleId, setSelectedVehicle] = useState();
    const [columnWidths, setColumnWidths] = useState([]);
    const [tripsDataRoutersModal, setTripsDataRoutersModal] = useState();
    const [showColumns, setShowColumns] = useState();
    const [mergedColumns, setMergedColumns] = useState()
    const [defaultPickerValue, setDefaultPickerValue] = useState(initialPickerValue);
    const [filteredVehicles, setFilteredVehicles] = useState(knownVehicles);
    const [filteredUsers, setFilteredUsers] = useState(knownUsers);
    const [type, setType] = useState('range');
    const [showingRoutes, setShowingRoutes] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [showJourneyHistoryJourneyId, setShowJourneyHistoryJourneyId] = useState(null);
    const [isCreateTripModalVisible, setCreateTripModalVisible] = useState(false);
    const userId = currentUser?.userId;
    const userDisplayName = currentUser?.displayName;
    const loadedUsers = !!knownUsers;
    const loadedVehicles = !!knownVehicles;
    const showAlert = !localStorage.getItem(ORGANIZATION_ID);

    useEffect(() => {
        let filtered = journals
        if (isShowRefusedTrips) {
            filtered = filtered?.flat().filter(item => item?.isBadTrip && !item?.attested)
        }
        setFilteredJournals(filtered)
    }, [journals, isShowRefusedTrips])

    useEffect(() => {
        if (type === "month") {
            const value = [
                moment().startOf('month'),
                moment().endOf('month')
            ];
            setDefaultPickerValue(value)
        }

        if (type === "year") {
            const value = [
                moment().startOf('year'),
                moment().endOf('year')
            ];
            setDefaultPickerValue(value)
        }

    }, [type])

    useEffect(() => {
        if (!currentUser || !defaultVehicle | !knownUsers) return;

        const urlParams = new URLSearchParams(window.location.search);
        const vehicleId = urlParams.get('vehicleId');
        const userId = urlParams.get('userId');

        if (vehicleId) {
            const id = parseInt(vehicleId);
            setSelectedVehicle([id]);
            setSelectedDriversToShowInJourney([]);
        } else if (userId) {
            const id = parseInt(userId);
            const isUserIdKnown = knownUsers?.some(item => item.userId === id);
            if (isUserIdKnown) {
                setSelectedDriversToShowInJourney([id]);
            } else {
                setSelectedDriversToShowInJourney([]);
            }
            setSelectedVehicle([]);
        } else {
            const isCurrentUserKnown = knownUsers?.some(item => item?.userId === currentUser?.userId);
            if (isCurrentUserKnown) {
                setSelectedDriversToShowInJourney([currentUser?.userId]);
            } else {
                setSelectedDriversToShowInJourney([]);
            }
            setSelectedVehicle([defaultVehicle]);
        }

    }, [currentUser, defaultVehicle, knownUsers]);

    useEffect(() => {
        if (!journalState) return
        journalState.flat().forEach((m) => {
            if (!showAlertAtestRefuse && m?.isBadTrip && !m?.attested) {
                setShowAlertAtestRefuse(true);
            }
        })
    }, [journalState])

    useEffect(() => {
        logbook.requestResetJournals()
    }, []);

    useEffect(() => {
        if (selectedOrganization?.length < 1) {
            setFilteredVehicles(knownVehicles);
            setFilteredUsers(knownUsers);
            return;
        }

        const nVehicles = knownVehicles?.filter((v) => selectedOrganization?.includes(v?.organizationId));
        const nUsers = knownUsers?.filter((v) => selectedOrganization?.includes(v?.organizationId));

        setFilteredVehicles(nVehicles);
        setFilteredUsers(nUsers);
    }, [selectedOrganization, knownVehicles, knownUsers]);

    function getMonthRange(dateString) {
        let startDate, endDate;

        if (dateString.match(/^\d{4}-\d{1,2}:e$/)) {
            // Format 'YYYY-M:e' (np. '2024-6:e')
            const [year, month] = dateString.split('-').map(Number);
            startDate = moment.utc([year, month - 1, 1]);
            endDate = moment.utc(startDate).endOf('month');
        } else if (dateString.match(/^\d{4}-Q\d$/)) {
            // Format 'YYYY-Q' (np. '2024-Q1')
            const [year, quarter] = dateString.split('-');
            startDate = moment.utc(`${year}-${(quarter - 1) * 3 + 1}-1`);
            endDate = moment.utc(startDate).endOf('quarter');
        } else if (dateString.match(/^\d{4}$/)) {
            // Format 'YYYY' (np. '2023')
            startDate = moment.utc(`${dateString}-01-01`);
            endDate = moment.utc(startDate).endOf('year');
        } else if (dateString.match(/^\d{4}-\d{2}$/)) {
            // Format 'YYYY-MM' (np. '2024-01')
            startDate = moment.utc(`${dateString}-01`);
            endDate = moment.utc(startDate).endOf('month');
        }

        return [startDate, endDate];
    }

    const onChangeDate = (dates, dateString) => {
        console.log("dates", dates)
        if (dates && dates.length > 1) {
            setDefaultPickerValue(dates);
        } else {
            const date = getMonthRange(dateString, dates)
            setDefaultPickerValue(date)
        }
    };

    useEffect(() => {
        if (defaultPickerValue[0].unix() && defaultPickerValue[1].unix() && selectedVehicleId && selectedDriversToShowInJourney && !showAlert)
            handleShowJourney()
    }, [defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney, showAlert])

    const handleShowJourney = () => {
        setLoading(false)
        setShowingRoutes(false)
        setShowAlertAtestRefuse(false);
        setIsShowRefusedTrips(false)
        setSliceCount(initialSliceCount)
        setRootEditMode(false)
        let fetchCoordinates = "0";
        logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney, fetchCoordinates, null, true)
    };

    useEffect(() => {
        const columnWidthsObj = {};
        mergedColumns?.forEach(item => {
            columnWidthsObj[item.dataIndex] = item.width;
        });
        setColumnWidths(columnWidthsObj);
    }, [journalState])

    const doLightAttest = (value, journalId) => {
        let lightAttest = {lightAttested: value};
        patchJournalEntry(journalId, lightAttest);
    };

    const doCorrect = (value, journalId) => {
        let correctedByDriver = {correctedByDriver: value};
        patchJournalEntry(journalId, correctedByDriver);
    };

    const turnOffDevCommentFlag = (value, journalId) => {
        let devComment = {devComment: value};
        dataApi.patchJournalDevCommentOff(journalId).then((res) => {
            logbook.patchJournals(journalId, devComment)
        });
    };
    const changeSelectedDescription = (radiovalue, textinput) => {
        if (radiovalue === "textarea") {
            setSelectMergeData({
                ...selectMergeData,
                selectedDescription: textinput,
            });
        } else {
            setSelectMergeData({
                ...selectMergeData,
                selectedDescription: radiovalue,
            });
        }
    };

    const onSetDriver = (selectDriverData, journalId, data) => {
        const user_id = selectDriverData?.userId || "-1";
        patchJournalEntry(journalId, {user_id: user_id});
    };

    const closeMergeModal = () => {
        setSelectMergeData(initMergeData);
        setInputValue(null);
        setRadioValue(null);
    };

    const handleMergingClick = (journalId, journalKey, o) => {
        let tripid1 = journalId;
        let trip1 = o.data;
        let trip2 = findNextTrip(tripid1);
        let tripid2 = trip2?.mergedTripsId ? trip2?.mergedTripsId?.at(-1) : trip2?.journal_id;
        if (trip1.trip.description === trip2.trip.description) {
            let selMergData = {
                journalId: journalId,
                journalKey: journalKey,
                journal: o,
                id1: tripid1,
                id2: tripid2,
                showMergeModal: false,
            };
            setSelectMergeData(selMergData);
            handleMargeTrip(selMergData, journalKey);
        } else {
            setSelectMergeData({
                journalId: journalId,
                journalKey: journalKey,
                journal: o,
                id1: tripid1,
                id2: tripid2,
                showMergeModal: true,
                description1: trip1.trip.description,
                description2: trip2.trip.description,
            });
        }
    };

    const handleUnmerging = (journey, journalKey) => {
        let journalIds = journey?.data?.mergedTripsId;
        dataApi.putJournalUnmerge(journalIds)
            .then((res) => {
                console.log(res)
                logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney)

            })
            .catch((err) => {
                console.log(err);
                message.error("Ett fel uppstod. Försök igen.");
            });
    };

    function showMapWithRoutes(ev, o) {
        if (showingRoutes && selectedJourney?.data?.journal_id === o.data.journal_id) {
            closeShowRoutesModal()
            return
        }

        ev.preventDefault();

        let fetchCoordinates = "1";
        let journeyId = o.data.journal_id?.toString();
        let coords = [];
        let passages = [];
        let congestionTaxSek = 0;
        let congestionTaxCount = 0;
        let congestionTaxSekOverride = null;
        let infraTaxSek = 0;
        let infraTaxCount = 0;
        let infraTaxSekOverride = null;
        setShowingRoutes(true);
        setLoadingModal(true)
        if (o?.data?.mergedTripsId) {
            dataApi.getJournalsByIds(o?.data?.mergedTripsId, true).then((res) => {
                res[0]?.map((trip) => {
                    if (trip?.trip?.coords) {
                        coords = [...trip?.trip?.coords, ...coords];
                    }
                    if (trip?.congestionData?.passages) {
                        passages = [...passages, ...trip?.congestionData?.passages];
                    }
                    congestionTaxSek = trip?.congestionTaxSek !== null ? parseInt(trip?.congestionTaxSek) + congestionTaxSek : congestionTaxSek;
                    congestionTaxCount = trip?.congestionTaxCount + congestionTaxCount;
                    if (trip?.congestionTaxSekOverride !== null) congestionTaxSekOverride = parseInt(trip?.congestionTaxSekOverride) + (congestionTaxSekOverride || 0);
                    infraTaxSek = trip?.infraTaxSek !== null ? parseInt(trip?.infraTaxSek) + infraTaxSek : infraTaxSek;
                    infraTaxCount = trip?.infraTaxCount + infraTaxCount;
                    if (trip?.infraTaxSekOverride !== null) infraTaxSekOverride = parseInt(trip?.infraTaxSekOverride) + (infraTaxSekOverride || 0);
                });
                // setClickedButton(clickedButton)
                setSelectedJourney({
                    data: {
                        ...o?.data,
                        trip: {
                            ...o?.data?.trip,
                            coords: coords,
                        },
                        congestionTaxSek: congestionTaxSek,
                        congestionTaxCount: congestionTaxCount,
                        congestionTaxSekOverride: congestionTaxSekOverride !== null ? congestionTaxSekOverride : null,
                        infraTaxSek: infraTaxSek,
                        infraTaxCount: infraTaxCount,
                        infraTaxSekOverride: infraTaxSekOverride !== null ? infraTaxSekOverride : null,
                        congestionData: {
                            passages: passages,
                        },
                    },
                });
            }).then(() => {
                setLoadingModal(false)
            });
        }

        if (!o?.data?.mergedTripsId) {
            dataApi.getJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney, fetchCoordinates, journeyId).then((res) => {
                res.stopTimeBackwards = o.data.stopTimeBackwards;
                if (res?.trip?.coords !== null) {
                    // setClickedButton(clickedButton)
                    setSelectedJourney({data: res});
                }
            }).then(() => {
                setLoadingModal(false)
            });
        }
    }

    function showModalWarningPurpose(o, idx, val, journalKey) {
        Modal.confirm({
            title: "Du är på väg att låsa denna resa",
            content:
                "Om du markerar denna resa som privat kommer endast föraren själv kunna se och ändra på resans detaljer.\n" +
                "Vill du verkligen ändra denna resa till privat? Du kan inte ångra detta.",
            okText: "Ja, ändra till privat",
            onOk() {
                patchJournalEntry(o.data.journal_id, {purpose: val ? "BUSINESS" : "PRIVATE"});
            },
            onCancel() {
            },
        });
    }

    const findNextTrip = (id1) => {
        if (!journalState || !id1) return;
        let idx = Object.entries(journalState)?.findIndex(([k, e]) => e[0]?.journal_id === id1);
        let trip2 = journalState[idx - 1];
        return trip2 ? trip2[0] : null;
    };

    function handleMargeTrip(selMergData, journalKey) {
        let data = selMergData;
        let description = data?.selectedDescription;
        let id1 = data?.id1;
        let id2 = data?.id2;

        dataApi.putJournalMerge(id1, id2, description).then((res) => {
            setSelectMergeData(initMergeData);
            setInputValue(null);
            setRadioValue(null);

            logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney)
        })
            .catch((e) => {
                console.error(e)
            });
    }

    function checkMergeButton(trip1) {
        let showMergeButton = true;
        const trip2 = findNextTrip(trip1?.journal_id);

        if (trip2?.driver?.id !== trip1?.driver?.id) {
            showMergeButton = false;
        }

        if (trip2?.vehicle?.vehicleId !== trip1?.vehicle?.vehicleId) {
            showMergeButton = false;
        }

        if (trip2?.trip?.purpose !== trip1?.trip?.purpose) {
            showMergeButton = false;
        }

        if ((trip2?.trip?.purpose === "PRIVATE" || trip1?.trip?.purpose === "PRIVATE") &&
            (trip2?.driver?.id !== currentUser?.userId || trip1?.driver?.id !== currentUser?.userId)
        ) {
            showMergeButton = false;
        }

        const difference = trip2?.trip?.timestamp_start_ms - trip1?.trip?.timestamp_stop_ms;
        const minutesDifference = Math.floor(difference / 1000 / 60);

        if (minutesDifference > 120) {
            showMergeButton = false;
        }

        if (trip2?.lightAttested) {
            showMergeButton = false
        }

        return showMergeButton;
    }

    const requestExport = (type, showPrivateTrips) => {
        console.log("report show private", showPrivateTrips)
        let tz = moment.tz.guess();
        const tsd = moment().tz(tz).format("YYYY-MM-DD");
        const userName = userDisplayName;
        let vehiclesToShow = "_";
        let zoneId = tz;

        message.loading(STATIC_TEXT.REPORT_IS_CREATED, 0);

        selectedVehicleId?.map((i) => {
            const vehicle = knownVehicles?.find((v) => v.id === i);
            const vehicleName = vehicle?.registry || vehicle?.displayName;
            vehiclesToShow = vehiclesToShow + vehicleName + "_";
        });

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_ALL_VEHICLES) {
            vehiclesToShow = "_" + STATIC_TEXT.ALL_VEHICLES.toUpperCase() + "_";
        }

        let filename = selectedVehicleId.length < 5 ? tsd + vehiclesToShow + "_" + userName + "_" : tsd + "_" + selectedVehicleId.length + "_fordon_" + userName + "_";

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.PDF) {
            filename = filename + reportsTypesLookUp.JOURNAL + ".pdf";
            dataApi.getJournalsPDF(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, filename, zoneId, selectedDriversToShowInJourney, selectedOrganization, showPrivateTrips).then((_) => {
                message.destroy();
            });
        }

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.CSV) {
            filename = filename + reportsTypesLookUp.JOURNAL + ".csv";
            // logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, filename, selectedDriversToShowInJourney, selectedOrganization)
            logbook.requestDownloadCSV(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, filename, selectedDriversToShowInJourney, selectedOrganization, showPrivateTrips)
        }

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_VEHICLE) {
            filename = filename + reportsTypesLookUp.SUMMARY_VEHICLE + ".pdf";
            dataApi.getSummaryVehicleReports(
                defaultPickerValue[0].unix(),
                defaultPickerValue[1].unix(),
                selectedVehicleId,
                filename,
                zoneId,
                false,
                selectedDriversToShowInJourney,
                selectedOrganization);
        }

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_DRIVER) {
            filename = filename + reportsTypesLookUp.SUMMARY_DRIVER + ".pdf";
            dataApi.getSummaryDriverReports(
                defaultPickerValue[0].unix(),
                defaultPickerValue[1].unix(),
                selectedVehicleId,
                filename,
                zoneId,
                false,
                selectedDriversToShowInJourney,
                selectedOrganization,
            );
        }

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.SALARY) {
            filename = filename + reportsTypesLookUp.SALARY + ".pdf";
            dataApi.getSalaryReports(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, filename, zoneId, false, selectedDriversToShowInJourney, selectedOrganization,);
        }

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.PURPOSE) {
            filename = filename + reportsTypesLookUp.PURPOSE + ".pdf";
            dataApi.getPurposeReports(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, filename, zoneId, false, selectedDriversToShowInJourney, selectedOrganization);
        }
        /////

        if (type === SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_ALL_VEHICLES) {
            const allVehicles = filteredVehicles?.map((i) => {
                return i.id;
            });
            filename = filename + reportsTypesLookUp.SUMMARY + ".pdf";
            dataApi.getSummaryReports(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), allVehicles, filename, zoneId, true, selectedDriversToShowInJourney);
        }
    };

    const changeSelectDriversToShowInJourney = (user) => {
        if (user.includes(-1)) {

            if (selectedDriversToShowInJourney?.length > 0) {
                setSelectedDriversToShowInJourney([])
                return;
            }

            const permissions = user
                ? filteredUsers.map((d) => {
                    return d?.userId;
                })
                : [];
            setSelectedDriversToShowInJourney(permissions);
            return;
        }

        const users = user.filter((user) => user !== null);
        setSelectedDriversToShowInJourney(users);
    };

    const changeFilterJournal = (v) => {

        if (v.includes(-1)) {

            if (selectedVehicleId?.length > 0) {
                setSelectedVehicle([])
                return;
            }

            const permissions = v
                ? filteredVehicles.map((d) => {
                    return d?.id;
                })
                : [];
            setSelectedVehicle(permissions);
            return;
        }

        const vehicles = v.filter((v) => v !== null);
        setSelectedVehicle(vehicles);
    };

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };

    const handleReportMenuClick = (e) => {
        if (selectedDriversToShowInJourney.length > 0 || selectedVehicleId.length > 0 || selectedOrganization.length > 0) {
            showConfirmForExport(e);
        } else {
            requestExport(e.key);
        }
    };

    const getAttest = (e) => {
        if (selectedVehicleId?.length < 1) {
            message.warning("Välj vilket fordon du vill attestera", 15);
            return;
        }

        const splitPeriod = e?.key?.split("/");
        const previousMonth = splitPeriod[0];
        const year = splitPeriod[1];
        let vehicleId = selectedVehicleId[0];

        dataApi.getAttestCkeckLite(previousMonth, year, vehicleId).then((resLite) => {
            dataApi.getAttestCheck(previousMonth, year, vehicleId).then((res) => {
                showConfirmForAttest(res, previousMonth, year, vehicleId, resLite);
            });
        });
    };

    const menu = (
        <Menu onClick={handleReportMenuClick}>
            <Menu.ItemGroup title="Körjournal">
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.PDF}>
                    Rapport som <b>PDF</b>
                </Menu.Item>
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.CSV}>
                    Rapport som <b>CSV</b>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Sammanställningar">
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_VEHICLE}>Fordonsrapport</Menu.Item>
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.SUMMARY_DRIVER}>Förarrapport</Menu.Item>
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.SALARY}>Lönerapport</Menu.Item>
                <Menu.Item key={SUPER_DEVICES_KEYS_AND_INDEXES.PURPOSE}>Syfterapport</Menu.Item>
                {/*<Menu.Item key="SUMMARY">{reportsTypes.SUMMARY}</Menu.Item>*/}
            </Menu.ItemGroup>
        </Menu>
    );

    function showConfirmForExport(e) {

        let showPrivateTrips = false
        Modal.confirm({
            title: "Export",
            content: (
                <div>
                    <p>Din rapport kommer att inkludera följande:</p>
                    {
                        selectedOrganization.length > 0 ? (
                            <>
                                <p>Organisation(er):</p>
                                <ul>
                                    {selectedOrganization?.map((i) => {
                                        const org = knownOrganizations?.find((o) => o.organizationId === i);
                                        const orgName = org?.fullName;
                                        return <li>{orgName}</li>;
                                    })}
                                </ul>
                            </>
                        ) : null
                    }
                    {
                        selectedVehicleId.length > 0 ? (
                            <>
                                <p>Fordon:</p>
                                <ul>
                                    {selectedVehicleId?.map((i) => {
                                        const vehicle = knownVehicles?.find((v) => v.id === i);
                                        const vehicleName = vehicle?.registry || vehicle?.displayName;
                                        return <li>{vehicleName}</li>;
                                    })}
                                </ul>
                            </>
                        ) : null
                    }
                    {
                        selectedDriversToShowInJourney.length > 0 ? (
                            <>
                                <p>Förare:</p>
                                <ul>
                                    {selectedDriversToShowInJourney?.map((i) => {
                                        const driver = knownUsers?.find((u) => u.userId === i);
                                        return <li>{driver.displayName}</li>;
                                    })}
                                </ul>
                            </>
                        ) : null
                    }
                    {(e.key === SUPER_DEVICES_KEYS_AND_INDEXES.PDF ||
                            e.key === SUPER_DEVICES_KEYS_AND_INDEXES.CSV) &&
                        selectedDriversToShowInJourney?.length === 1 &&
                        selectedDriversToShowInJourney[0] === userId &&
                        <div>
                            <Divider/>
                            <Flex
                                style={{marginTop: 10}}
                                justifyContent={"between"}>
                                Visa detaljer om privata resor:
                                <Switch
                                    defaultChecked={showPrivateTrips}
                                    onChange={(checked) => showPrivateTrips = checked}
                                />
                            </Flex>
                        </div>
                    }
                </div>
            ),
            okText: "OK",
            cancelText: "Avbryt",
            onOk() {
                requestExport(e.key, showPrivateTrips);
                console.log("OK");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    //Find 3 last months
    let monthToGetAttest = [];
    let year = moment().subtract(0, "year").startOf("year").format("YYYY").toLowerCase();
    let currentMonth = moment().subtract(0, "month").startOf("month").format("MMMM").toLowerCase();
    currentMonth = Object.entries(monthsLookUp)?.find(([k, m]) => m?.toLowerCase() === currentMonth);

    for (let i = 1; i < 4; i++) {
        let isPreviousYear = false;
        const month = moment().subtract(i, "month").startOf("month").format("MMMM").toLowerCase();
        const getValue = Object.entries(monthsLookUp)?.find(([k, m]) => m?.toLowerCase() === month);

        if (currentMonth[0] < 4 && getValue[0] > 9) {
            isPreviousYear = true;
        }

        monthToGetAttest.push({
            key: [getValue[0] + "/" + (isPreviousYear ? year - 1 : year)],
            value: getValue[1] + " " + (isPreviousYear ? year - 1 : year),
        });
    }

    const menuMonthToAttest = (
        <Menu onClick={getAttest}>
            {Object.entries(monthToGetAttest)?.map(([k, v]) => {
                return <Menu.Item key={v?.key}>{v?.value}</Menu.Item>;
            })}
        </Menu>
    );

    let dataTax = selectedJourney?.data;

    function showConfirmForAttest(data, previousMonth, year, vehicleId, unattested) {
        const business = data.countNoDescription;
        const disproved = data.countLightAttested;
        const total = data.countNotCertified;
        let getValueForMonth = Object.entries(monthsLookUp)?.find(([k, m]) => k === previousMonth);
        getValueForMonth = getValueForMonth[1]?.toLowerCase();
        const getValueForVehicle = knownVehicles?.find((v) => v?.id === vehicleId);

        const ifOldUnattested = Object.entries(unattested)?.map(([key, value]) => {
            const mo = key.split("-")[1];
            const month = Object.entries(monthsLookUp)?.find(([k, m]) => k.toString() === mo);
            return month[1]?.toLowerCase();
        });

        Modal.confirm({
            title: "Vill du attestera " + getValueForMonth + " för " + (getValueForVehicle?.registry || "") + "(" + (getValueForVehicle?.displayName || "-") + ")?",
            content: (
                <div>
                    <p>
                        Alla resor för <b>{getValueForMonth}</b> månad kommer nu att attesteras. Detta kan inte ångras.
                    </p>
                    <p>Vill du fortsätta?</p>

                    {ifOldUnattested?.length > 1 && (
                        <>
                            <Alert
                                message={" Du kommer att attestera alla månader inkl " + ifOldUnattested?.map((item) => " " + item) + "."}
                                type="warning" showIcon/>
                            <br/>
                        </>
                    )}

                    <Alert message={"Du har " + business + " resor av typen tjänst som saknar syfte."} type="warning"
                           showIcon/>
                    <br/>
                    <Alert message={disproved + "/" + total + " resor är markerade som bekräftade."} type="warning"
                           showIcon/>
                </div>
            ),
            okText: "Attestera",
            cancelText: "Avbryt",
            onOk() {
                // TODO move api req to provider
                dataApi.patchAttestUser(previousMonth, year, vehicleId).then((res) => {
                    logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney)
                });
                console.log("OK");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    let checkIfAttestDisabled = selectedVehicleId?.length !== 1 || selectedDriversToShowInJourney?.length !== 1;

    const handleRadioChange = (e, inputValue) => {
        setRadioValue(e.target.value);
        let textinput = inputValue;
        let radiovalue = e.target.value;

        changeSelectedDescription(radiovalue, textinput);
    };

    const [radioValue, setRadioValue] = useState();
    const [inputValue, setInputValue] = useState();

    const onInputChange = (e) => {
        setInputValue(e.target.value);
        let textinput = e.target.value;
        changeSelectedDescription("textarea", textinput);
    };
    const [rootEditMode, setRootEditMode] = useState(false)

    const {newColumns} = useMemo(() => TableJourneys(sliceCount, currentUser,
            userId, doLightAttest, doCorrect, turnOffDevCommentFlag, knownUsers, onSetDriver,
            showModalWarningPurpose, patchJournalEntry, logbook, findNextTrip, checkMergeButton,
            showMapWithRoutes, handleUnmerging, handleMergingClick, journalState,
            setShowAlertAtestRefuse, rootEditMode, setRootEditMode, setShowJourneyHistoryJourneyId, selectedJourney,
            columnWidths)
        , [rootEditMode, setRootEditMode, selectedJourney, columnWidths, tripsDataRoutersModal]);

    useEffect(() => {
        if (!newColumns || !userId) return

        setMergedColumns(newColumns)
    }, [newColumns])

    const columnsResizable = useMemo(() => resizableColumns(mergedColumns, setMergedColumns)
        , [mergedColumns]);

    reloadOnScroll(setSliceCount, SLICE_COUNT, sliceCount, journalState);
    const menuColumns = showHideColumns(columnsResizable, showColumns, setShowColumns);

    const [drawerWidth, setDrawerWidth] = useState(500);
    const drawerRef = useRef();
    const dragHandleRef = useRef();
    const handleMouseDown = drawerResize(dragHandleRef, setDrawerWidth);

    const divRef = useRef(null);
    const [divHeight, setDivHeight] = useState(0);

    useEffect(() => {
        if (!divRef?.current?.offsetHeight) {
            return;
        }

        const resizeObserver = new ResizeObserver(() => {
            if (divRef?.current?.offsetHeight !== divHeight) {
                const height = divRef?.current?.offsetHeight;
                setDivHeight(height);
            }
        });

        resizeObserver.observe(divRef.current);

        return function cleanup() {
            resizeObserver.disconnect();
        }
    }, [divRef.current])

    useEffect(() => {
        const minHeightValue = `calc(100vh - ${divHeight + 155}px)`;
        const styleSheet = document.createElement("style");
        styleSheet.type = "text/css";
        styleSheet.innerText = `#trips-table .ant-table-body { min-height: ${minHeightValue}; }`;
        document.head.appendChild(styleSheet);
    }, [divHeight])

    const PickerWithType = ({type, onChange, defaultValue}) => {
        const [currentRange, setCurrentRange] = useState(defaultValue);
        const handleDateChange = (dates, dateStrings) => {
            if (dates && dates.length === 2) {
                const start = dates[0];
                const end = dates[1].endOf('day');
                setCurrentRange([start, end]);
            }
        };
        if (type === 'range')
            return <RangePicker
                dropdownClassName={"one-month"}
                value={defaultPickerValue}
                onChange={handleDateChange}
                onOpenChange={(v) => !v && onChange(currentRange)}
                format="YYYY-MM-DD"
                allowClear={false}
                ranges={{
                    "Idag": [moment().startOf('day'), moment().endOf('day')],
                    'Den här månaden': [moment().startOf('month'), moment().endOf('month')],
                    'Förra månaden': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                }}
            />;

        return <DatePicker picker={type}
                           allowClear={false}
                           value={defaultPickerValue[1]}
                           onChange={onChange}/>;

    };

    function closeShowRoutesModal() {
        logbook.patchJournals(selectedJourney?.data?.journal_id, {field: null}, null, {object: tripsDataRoutersModal})
        setSelectedJourney(null)
        setShowingRoutes(false)
    }

    const transformList = (list) => {
        const formattedList = [];
        let currentDate = null;
        list?.flat().forEach(item => {
            const startDateFormatted = extractedDate(item.trip.timestamp_start_ms)
            if (currentDate !== startDateFormatted) {
                formattedList.push({
                    sticky: "sticky",
                    data: {
                        description: startDateFormatted,
                        key: moment(item.trip.timestamp_start_ms).startOf('day')
                    }
                });
                currentDate = startDateFormatted;
            }
            formattedList.push({
                data: {
                    ...item,
                    viewChecked: true,
                    viewSaved: false
                }
            });
        });
        return formattedList;
    };

    const data2 = transformList(journalState?.slice(sliceCount.start, sliceCount.stop));
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: record => ({
            // Tutaj możesz kontrolować, czy checkbox ma być wyłączony dla danego wiersza
            disabled: !record?.data?.journal_id, // Przykładowy warunek
            // Możesz także użyć innych właściwości
        }),
    };

    return !loadedUsers || !loadedVehicles || !knownOrganizations || showAlert ? (
        <div>
            {!showAlert ? (
                <Loading/>
            ) : (
                <CardSelectOrganizationForRoot
                    selectedOrganization={selectedOrganization}
                    organizationSelectOnChange={(value) => setSelectedOrganization([value])}
                    knownOrganizations={knownOrganizations}
                />
            )}
        </div>
    ) : (
        <Flex flexDirection={"column"} style={{height: `calc(100vh - 100px)`}}>
            <div ref={divRef}>
                <>
                    <div style={{display: "flex", flexDirection: "column", marginBottom: 0}}>

                        <Flex justifyContent={"between"} wrap={"wrap"}>
                            <Title>Resor</Title>

                            <Flex justifyContent={"start"} style={{gap: 15, marginBottom: 16}} wrap={"wrap"}>
                                {knownOrganizations?.length < 2 ? null : (
                                    <div>
                                        <OrganizationsTreeSelect data={knownOrganizations}
                                                                 onChange={organizationSelectOnChange}
                                                                 selectedOrganization={selectedOrganization}
                                                                 isMultiple={true}/>
                                    </div>
                                )}

                                <VehiclesSelect
                                    multiple={'multiple'}
                                    data={filteredVehicles}
                                    onChange={changeFilterJournal}
                                    value={selectedVehicleId}/>

                                <UsersSelect
                                    multiple={'multiple'}
                                    data={filteredUsers}
                                    value={selectedDriversToShowInJourney}
                                    onChange={changeSelectDriversToShowInJourney}
                                />
                                <PickerWithType type={type} onChange={onChangeDate} defaultValue={defaultPickerValue}/>

                            </Flex>
                        </Flex>
                    </div>

                    <Flex style={{gap: 15, marginBottom: 20}}>
                        {/*Show/hide columns*/}
                        {/*    <Dropdown overlay={menuColumns} trigger={['click']}>

                            <Button icon={<icons.ControlOutlined/>}>
                            </Button>
                        </Dropdown>*/}

                        <Dropdown overlay={menu}>
                            <Button icon={<icons.DownloadOutlined/>}>
                                Exportera rapport
                                <icons.DownOutlined/>
                            </Button>
                        </Dropdown>

                        <Dropdown disabled={checkIfAttestDisabled} overlay={menuMonthToAttest}>
                            <Tooltip
                                title={checkIfAttestDisabled ? "Välj en förare och ett fordon som du vill attestera" : null}>
                                <Button disabled={checkIfAttestDisabled}>
                                    Attestera resor
                                    <icons.DownOutlined/>
                                </Button>
                            </Tooltip>
                        </Dropdown>

                        {currentUser?.roles?.includes("ROOT") &&
                            <Button onClick={() => setCreateTripModalVisible(true)}>
                                <icons.PlusOutlined/>
                                Skapa ny resa (Superadmin)
                            </Button>
                        }
                    </Flex>
                </>

                {showAlertAtestRefuse && (
                    <Alert
                        message="Du har resor som behöver korrigeras. Kontrollera vilka resor som blivit flaggade av din arbetsgivare."
                        action={
                            <span>Visa bara flaggade resor  <Switch checked={isShowRefusedTrips}
                                                                    onChange={(v) => setIsShowRefusedTrips(v)}/></span>

                        }
                        type="warning"
                        showIcon
                        style={{marginBottom: 20}}
                    />
                )}

            </div>

            <BackTop style={{zIndex: "20"}}/>


            <div style={{height: "100%"}}>

                <Flex flexDirection={"row"} gap={10} style={{height: "100%"}}>
                    <Card
                        className={`left-panel ${showingRoutes ? 'left-panel-shrink' : ''}`}
                        ref={drawerRef}
                        style={{
                            height: "100%",
                            margin: 0,
                            width: showingRoutes ? `calc(100% - ${drawerWidth}px)` : "100%"
                        }}
                        bodyStyle={{padding: 0}}
                    >
                        {loadingJournals ?
                            <Skeleton active/>
                            :
                            <ConfigProvider
                                renderEmpty={() => customizeRenderEmpty(loading ? "Ange sökalternativ och tryck på Sök för att visa körjournal."
                                    :
                                    "Inga resor för vald filtrering.", `calc(100vh - ${divHeight + 240}px)`)}>
                                <Table
                                    id={"trips-table"}
                                    className="custom-bordered-header"
                                    rowClassName={(record) => {
                                        if (record.sticky === 'sticky') {
                                            return 'sticky-row';
                                        } else if (["DONE", "LOCKED", "CHECKED"].includes(record?.data?.status)) {
                                            return 'disabled-row';
                                        } else if (showingRoutes && selectedJourney && record?.data?.journal_id === selectedJourney?.data?.journal_id) {
                                            return ' showing-routes';
                                        } else if (selectedJourney && record?.data?.journal_id === selectedJourney?.data?.journal_id) {
                                            return 'selected-row';
                                        } else {
                                            return 'editable-row';
                                        }
                                    }}
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                            //row: CustomRow,

                                        },
                                        header: {
                                            cell: ResizableTitle,
                                        },
                                    }}
                                    columns={columnsResizable?.filter(item => item.show)}
                                    loading={loadingJournals}
                                    dataSource={data2}
                                    pagination={false}
                                    scroll={{y: `calc(100vh - ${divHeight + 155}px)`,}}
                                    rowKey={(record) => record?.data?.journal_id}
                                    //  rowSelection={rowSelection}
                                > </Table>
                            </ConfigProvider>
                        }
                    </Card>
                    <div
                        ref={dragHandleRef}
                        onMouseDown={handleMouseDown}
                        style={{
                            width: '20px',
                            height: `calc(100vh - ${divHeight + 90}px)`,
                            cursor: 'ew-resize',
                            position: 'fixed',
                            bottom: 0,
                            right: drawerWidth,
                            zIndex: 1000,
                            visibility: showingRoutes ? null : "hidden"
                        }}
                    />

                    <ShowRouteModal
                        selectedJourney={selectedJourney}
                        onOk={() => setShowingRoutes(false)}
                        onCancel={closeShowRoutesModal}
                        dataTax={dataTax}
                        visible={showingRoutes === true}
                        width={drawerWidth}
                        divHeight={divHeight}
                        getOverrideHistory={logbook.getOverrideHistory}
                        showLoggs={currentUser?.roles?.includes("ROOT")}
                        data={tripsDataRoutersModal}
                        setData={setTripsDataRoutersModal}
                        loading={loadingModal}
                        // clickedButton={clickedButton}
                    />

                </Flex>
            </div>

            <JourneyHistoryTable
                getOverrideHistory={logbook.getOverrideHistory}
                setShowJourneyHistoryJourneyId={setShowJourneyHistoryJourneyId}
                journalId={showJourneyHistoryJourneyId}/>
            <CreateTripModal
                onClose={() => {
                    logbook.requestFetchJournals(defaultPickerValue[0].unix(), defaultPickerValue[1].unix(), selectedVehicleId, selectedDriversToShowInJourney)
                    setCreateTripModalVisible(false)
                }}
                isVisible={isCreateTripModalVisible}
                filteredUsers={filteredUsers}
                filteredVehicles={filteredVehicles}
            />

            <Modal title="Syfte" style={{maxWidth: 500}} visible={selectMergeData.showMergeModal}
                   onOk={() => handleMargeTrip(selectMergeData, selectMergeData?.journalKey)}
                   onCancel={() => closeMergeModal()}>
                <Radio.Group onChange={(e) => handleRadioChange(e, inputValue)}
                             style={{display: "flex", flexDirection: "column"}} value={radioValue}>
                    {selectMergeData.description1 && selectMergeData.description1 != "–" && (
                        <Radio style={{marginBottom: "0.5em"}} value={selectMergeData.description1}>
                            {selectMergeData.description1}
                        </Radio>
                    )}
                    {selectMergeData.description2 && selectMergeData.description2 != "–" && (
                        <Radio style={{marginBottom: "0.5em"}} value={selectMergeData.description2}>
                            {selectMergeData.description2}
                        </Radio>
                    )}
                    <Radio value="textarea">
                        <span>Ange nytt syfte...</span>
                        <div>
                            {radioValue === "textarea" ? (
                                <Input
                                    style={{marginTop: "0.25em"}}
                                    value={inputValue}
                                    onChange={onInputChange}
                                    placeholder={STATIC_TEXT.SUPPLY_PURPOSE}
                                    // onChange={(e) => changeSelectedDescription(e.target.value)}
                                    // onBlur={changeSelectedDescription}
                                    allowClear
                                />
                            ) : null}
                        </div>
                    </Radio>
                </Radio.Group>
            </Modal>
        </Flex>
    );
};

const JourneyController = ({...props}) => {
    const vehicleState = useContext(VehicleContext);
    const handlers = {
        foo: (state, payload) => {
            return {...state, hello: "baz"};
        },
        receiveJournals: (state, payload) => {
            return {
                ...state,
                journalQuery: null,
                journalURL: payload.url,
                journals: {
                    ...payload.journals,
                },
            };
        },
    };

    const [state, dispatch] = useReducer(
        (state, action) => {
            return handlers[action.type](state, action.payload);
        },
        {},
        undefined
    );

    useEffect(() => {
        if (!state.journalQuery) return;

        if (!vehicleState.idByReg) {
            return;
        }

        const fromTimestamp = new Date(state.journalQuery.startDate).getTime() / 1000;
        const toTimestamp = new Date(state.journalQuery.endDate).getTime() / 1000;
        const filterVehicleIds = state.journalQuery.filters.registration.map((reg) => vehicleState.idByReg[reg]);

        let queryString = `?from_ts=${fromTimestamp}&to_ts=${toTimestamp}`;
        queryString += filterVehicleIds?.length > 0 ? "&vehicleIds=" + filterVehicleIds : "";
        queryString += "&excludeCoords=true";

        const url = API_BASE_URL + "/journal" + queryString;
        if (url === state.journalURL) return;

        jwt_xhrp("GET", url)
            .then(xhr_dejson)
            .then((journals) => {
                dispatch({
                    type: "receiveJournals",
                    payload: {
                        url: url,
                        journals: journals,
                    },
                });
            });
    }, [state.journalQuery, vehicleState.idByReg]);
    return (
        <TripContext.Provider value={{state, dispatch}}>
            <Journey {...props} />
        </TripContext.Provider>
    );
};

const JourneyPage = () => (
    <Nest
        wrappers={[
            InitialDateAttest.provider,
            AuthorizationProvider,
            OrganizationSimpleProvider,
            UserListSimpleProvider,
            VehicleProvider,
            SelectDefaultVehicle.provider,
            TripLogbookProvider
        ]}>
        <JourneyController/>
    </Nest>
)

export default JourneyPage;

const ResizableTitle = (props) => {
    const {onResize, width, ...restProps} = props;

    if (!width || restProps.fixedWidth) {
        return <th {...restProps} />;

    }

    return (
        <Resizable
            width={width}
            height={0}
            minConstraints={[100, 100]}
            onResize={onResize}
            draggableOpts={{enableUserSelectHack: true}}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const EditableCell = ({
                          editing,
                          dataIndex,
                          record,
                          children,
                          ...restProps
                      }) => {
    if (dataIndex === "status" && record.sticky) {
        return (
            <td
                {...restProps}

                style={{height: "100%",}}

            >
                {dataIndex === "status" ? (
                    <div style={{
                        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto',
                        marginLeft: "16px", height: 'fit-content', width: 'fit-content', textAlign: 'center'
                    }}>
                        <b>{record.data.description}</b>
                    </div>) : (
                    editing ? null : (
                        children
                    )

                )}
            </td>
        );

    }
    return (
        <td {...restProps} >
            {dataIndex === "stisscky" ? (
                <Text style={{
                    position: "absolute",
                    margin: "-16px",
                    backgroundColor: "red",
                    width: 600,
                    display: "block"
                }}>{record.data.description}</Text>
            ) : (
                editing ? null : (
                    children
                )

            )}
        </td>
    );
};

const EditableText = ({text, onTextChange, show, entryType, disabled, ...props}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(text);
    const inputRef = useRef(null);
    const [ignoreBlur, setIgnoreBlur] = useState(false);
    const blurTimeoutRef = useRef(null);

    useEffect(() => {
        setValue(text)
        setIgnoreBlur(false)
    }, [isEditing])

    function inputTextArea() {

        const buttonStyle = {
            width: '28px',
            height: '28px',
            padding: '0', // Remove padding to ensure the button does not exceed 10x10px due to padding
            minWidth: '0', // Override default min-width from Ant Design
            fontSize: 10,
            borderRadius: 0,
            marginLeft: 6,
            boxShadow: '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.58),0 9px 28px 8px rgba(0,0,0,.15)'
        };

        return <div style={{width: "100%"}}><Input
            ref={inputRef}
            value={value}
            suffix={props.suffix}
            onFocus={(event) => {
                event.target.select();
            }}
            placeholder={props.placeholder}
            onChange={(e) => setValue(e.target.value)}
            onPressEnter={(e) => {
                setIsEditing(false);
                if (value === text || props.customValidationRegel) return
                onTextChange(value);
            }}
            onBlur={(e) => {
                blurTimeoutRef.current = setTimeout(() => {
                    setIsEditing(false);
                    if (ignoreBlur) {
                        return;
                    }
                    if (value === text || props.customValidationRegel) return
                    onTextChange(value);
                }, 250);

            }}
            autoFocus
        />
            <div
                style={{position: "absolute", right: 0, zIndex: 100,}}
            >
                <Button style={buttonStyle}
                        onClick={(e) => {
                            setIsEditing(false);
                            if (value === text || props.customValidationRegel) return
                            onTextChange(value);
                        }}
                >OK</Button>
                <Button style={buttonStyle}
                        onClick={(e) => {
                            e.stopPropagation()
                            clearTimeout(blurTimeoutRef.current);
                            setIgnoreBlur(true)
                            setValue(text)
                            setIsEditing(false)
                            return
                        }}
                >X</Button>
            </div>
        </div>
    }

    function editorCustom() {
        return props.customEditableInput
    }

    function editorTripType() {
        return <Radio.Group
            size={"small"}
            value={value}
            onChange={(e) => {
                const newValue = e.target.value;
                onTextChange(newValue);
                setValue(newValue);
            }}
            onMouseLeave={() => {
                if (entryType === "tripType")
                    setIsEditing(false)
            }}
            buttonStyle="outline"
            style={{width: "100%"}}

        >
            <Radio.Button value={CONSTANTS_TEXT.BUSINESS}>{tripTypes.BUSINESS}</Radio.Button>
            <Radio.Button value={CONSTANTS_TEXT.PRIVATE}>{tripTypes.PRIVATE}</Radio.Button>
        </Radio.Group>

    }

    function editorTripType2() {
        return <Select
            value={value}
            open={isEditing}
            autoFocus
            onChange={(value) => {
                onTextChange(value)
                setValue(value)
                setIsEditing(false);
            }}
            onBlur={(e) => {
                setIsEditing(false);
            }}
            style={{minWidth: "100%"}}
            dropdownMatchSelectWidth={false}
            virtual={true}
            showSearch
            filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
            className="default-input-field"
            placeholder="Välj förare"
            optionFilterProp="children"

            onSearch={() => null}
        >
            <Select.Option value={CONSTANTS_TEXT.BUSINESS}>{tripTypes.BUSINESS}</Select.Option>
            <Select.Option value={CONSTANTS_TEXT.PRIVATE}>{tripTypes.PRIVATE}</Select.Option>
        </Select>

    }

    function editorDescription() {
        return <AutoComplete
            options={props.options}
            value={value || ""}
            filterOption={(inputValue, option) => option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            onChange={(e) => setValue(e)}
            onClear={(e) => {
                setIsEditing(false);
                onTextChange("");
            }
            }
            onSelect={(e) => setValue(e)}
            placeholder="Ange syfte..."
            onBlur={(e) => {
                setIsEditing(false);
                if (value === text || props.customValidationRegel) return
                onTextChange(value);
            }}
            dropdownMatchSelectWidth={false}
            autoFocus
            allowClear

        />

    }

    const EditorSelect = () => {
        return <Select
            showSearch
            filterOption={(input, option) => JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
            className="default-input-field"
            placeholder="Välj förare"
            optionFilterProp="children"
            virtual={true}
            dropdownMatchSelectWidth={false}
            open={isEditing}
            onChange={(value) => {
                setValue(value)
                onTextChange(value)
                setIsEditing(false);
            }}
            onBlur={(e) => {
                setIsEditing(false);
            }}
            onSearch={() => null}
            value={value}
            autoFocus
        >
            {props.selectedOptions}
        </Select>

    }

    const inputNode = (() => {
        switch (entryType) {
            case 'textArea':
                return (inputTextArea());
            case 'custom':
                return (editorCustom());
            case 'tripType':
                return (editorTripType());
            case 'description':
                return (editorDescription());
            case 'select':
                return <EditorSelect/>;
            default:
                return (inputTextArea());

        }
    })()

    return disabled ?
        <div className={"editable-text disabled"}>
            <div style={props.customStyle}>
                {props.customEditableText || props.customText || text}
            </div>
        </div>

        : isEditing || props.isEditing ? inputNode
            : (
                <div className={`editable-text ${props.customClass ? ` ${props.customClass}` : ''}`}
                     onClick={() => {
                         setIsEditing(true)
                     }}
                     onMouseEnter={() => {
                         if (entryType === "tripType")
                             setIsEditing(true)
                     }}
                >
                    <div
                        style={props.customStyle}
                    >
                        {props.customEditableText || props.customText || text}
                    </div>
                </div>
            );
};

const CreateTripModal = ({isVisible, onClose, ...props}) => {
    const [form] = Form.useForm();

    const onFormSubmit = async (values) => {
        console.log(values);
        const formattedValues = {
            ...values,
            timestampStartMs: values.timestampStartMs.valueOf(),
            timestampStopMs: values.timestampStopMs.valueOf(),
        };
        await dataApi.postAdminTrip(formattedValues);
        onCancel();
    }

    const handleSubmit = async (values) => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                onFormSubmit(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });

    };

    function onCancel() {
        form.resetFields();
        onClose()
    }

    return (
        <Modal
            title="Skapa en ny resa"
            visible={isVisible}
            onCancel={onCancel}
            bodyStyle={{maxHeight: `calc(100vh - 250px)`, overflow: "auto"}}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Avbryt
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Skapa resa
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="vehicleId"
                    label="Fordonets ID"
                    rules={[{required: true, message: 'Ange fordonets ID'}]}
                >
                    <VehiclesSelect
                        data={props.filteredVehicles}
                    />
                </Form.Item>

                <Form.Item
                    name="imei"
                    label="IMEI"
                    rules={[{required: true, message: 'Ange enhetens IMEI'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="userId"
                    label="Användarens ID"
                >
                    <UsersSelect
                        data={props.filteredUsers}
                    />

                </Form.Item>

                <Form.Item
                    name="timestampStartMs"
                    label="Starttid"
                    rules={[{required: true, message: 'Ange starttid i millisekunder'}]}
                >
                    <DatePicker
                        dropdownClassName={"display-footer"}
                        showTime format="YYYY-MM-DD HH:mm"/>
                </Form.Item>

                <Form.Item
                    name="timestampStopMs"
                    label="Stopptid"
                    rules={[{required: true, message: 'Ange stopptid i millisekunder'}]}
                >
                    <DatePicker
                        dropdownClassName={"display-footer"}
                        showTime format="YYYY-MM-DD HH:mm"/>
                </Form.Item>

                <Form.Item
                    name="tripOdometerMax"
                    label="Avstånd (m)"
                    rules={[{required: true, message: 'Ange maximal tripptodomätare'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="startLat"
                    label="Startlatitud"
                    rules={[{required: false, message: 'Ange startlatitud'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="startLon"
                    label="Startlongitude"
                    rules={[{required: false, message: 'Ange startlongitude'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="stopLat"
                    label="Stopplatitud"
                    rules={[{required: false, message: 'Ange stopplatitud'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>

                <Form.Item
                    name="stopLon"
                    label="Stopplongitude"
                    rules={[{required: false, message: 'Ange stopplongitude'}]}
                >
                    <InputNumber style={{width: '100%'}}/>
                </Form.Item>


                <Form.Item
                    name="startAddress"
                    label="Startadress"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="stopAddress"
                    label="Stoppadress"
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Syfte"
                    rules={[{required: false, message: 'Ange en beskrivning'}]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item
                    name="purpose"
                    label="Typ av resa"
                    rules={[{required: true, message: 'Vänligen välj typ av resa'}]}
                >
                    <Radio.Group>
                        <Radio value="BUSINESS">Tjänst</Radio>
                        <Radio value="PRIVATE">Privat</Radio>
                    </Radio.Group>
                </Form.Item>


            </Form>
        </Modal>
    );
};

const JourneyHistoryTable = (props) => {
    const [initialValues, setInitialValues] = useState({});
    const [data, setJourneyHistory] = useState();

    useEffect(() => {
        const saveInitialValues = (data) => {
            let initialValues = {};
            data?.forEach(item => {
                Object.keys(item).forEach(key => {
                    if (item[key] !== null && item[key] !== undefined && initialValues[key] === undefined) {
                        initialValues[key] = item[key];
                    }
                });
            });

            setInitialValues(initialValues);
        };

        saveInitialValues(data);
    }, [data]);

    useEffect(() => {
        if (props.journalId) {
            props.getOverrideHistory(props?.journalId)
                .then(res => setJourneyHistory(res))
        } else {
            setJourneyHistory(null)
        }
    }, [props.journalId])

    const translations = {
        timestampStartMs: "Tidsstämpel start",
        timestampStopMs: "Tidsstämpel stop",
        odometerStart: "Mätarställning start",
        odometerStop: "Mätarställning stop",
        tripOdometerMax: "Avstånd",
        overrideStartAddress: "Adress start",
        overrideStopAddress: "Adress stop",
        congestionTaxOverride: "Tskatt",
        infraTaxOverride: "Infra",
    };

    const columns = [
        {
            title: "Tid",
            dataIndex: "overrideTsMs",
            render: (text) => {
                return text && convertTimestampToDateStr(text)
            },
        },
        {
            title: "Fält",
            dataIndex: "overrideTsMs",
            render: (text, record) => {
                const nonNullEntries = Object.entries(record).reduce((acc, [key, value]) => {
                    if (!['overrideTsMs', 'overrideTuId', 'tripUserspaceId'].includes(key) && value !== null) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});

                const firstEntry = Object.entries(nonNullEntries)[0];
                if (!firstEntry) return '';
                const translatedKey = translations[firstEntry[0]] || firstEntry[0];
                return `${translatedKey} (${firstEntry[0]})`;
            },
        },
        {
            title: "Sparad ändring",
            dataIndex: "overrideStartAddress",
            render: (text, record) => {
                const nonNullEntries = Object.entries(record).reduce((acc, [key, value]) => {
                    if (!['overrideTsMs', 'overrideTuId', 'tripUserspaceId'].includes(key) && value !== null) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});

                const firstEntry = Object.entries(nonNullEntries)[0];
                if (!firstEntry) return '';
                const timestamp = ["timestampStartMs", "timestampStopMs"]
                const newValue = timestamp.includes(firstEntry[0]) ? convertTimestampToDateStr(firstEntry[1]) : firstEntry[1];
                return `${newValue}`;
            }
        },
        {
            title: "Första värde",
            dataIndex: " ",
            render: (text, record) => {
                const nonNullEntries = Object.entries(record).reduce((acc, [key, value]) => {
                    if (!['overrideTsMs', 'overrideTuId', 'tripUserspaceId'].includes(key) && value !== null) {
                        acc[key] = value;
                    }
                    return acc;
                }, {});

                const firstEntry = Object.entries(nonNullEntries)[0];
                if (!firstEntry) return '';
                const timestamp = ["timestampStartMs", "timestampStopMs"]
                const newValue = timestamp.includes(firstEntry[0]) ? convertTimestampToDateStr(initialValues[firstEntry[0]]) : initialValues[firstEntry[0]];
                return `${newValue}`;
            }
        },
    ]

    return <Modal
        style={{maxHeight: "calc(80vh)", top: 10}}
        footer={[
            <Button key="submit" type="primary" onClick={() => props.setShowJourneyHistoryJourneyId(null)}>
                Stäng
            </Button>,
        ]}
        onCancel={() => props.setShowJourneyHistoryJourneyId(null)}
        visible={props.journalId}>
        <Flex flexDirection={"column"} style={{marginTop: 20}}>
            <Typography.Title level={4}>Journalhistorik:</Typography.Title>
            <Table
                dataSource={data}
                columns={columns}
                scroll={{y: 200,}}
                pagination={false}
            />
        </Flex>
    </Modal>
}

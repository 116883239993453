import {VehicleController} from "../components/trackson-components/vehicles.js";
import {Nest} from "../utils/utilities";
import {
    AuthorizationProvider,
    DeviceListProvider,
    OrganizationsComplexListProvider,
    shouldHideUsersOrganizationsColumn,
    UserListProvider,
    VehiclesListWithArchivedProvider
} from "../services/contexts/contexts";

const VehicleListPage = () => {
    return <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, UserListProvider, VehiclesListWithArchivedProvider, DeviceListProvider, OrganizationsComplexListProvider]}>
        < VehicleController/>
    </Nest>};

export default VehicleListPage;

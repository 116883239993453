export const styles = {
    paragraph: {
        margin: 0,
        fontSize: '18px'
    },
    interval: {
        display: 'flex',
        gap: 15,
        alignItems: 'center',

    },
};
export default styles;

import React, {useState, useContext, useEffect, useRef} from "react"
import GoogleMapReact from 'google-map-react';
import { Icon }  from 'react-icons-kit'
import * as A from 'antd';
import { mapMarker } from 'react-icons-kit/fa/mapMarker'

import {GoogleMapContext} from "./map-context"
import {TaxContext, TripTaxContext} from "../../../services/contexts/tax-context"

const PayStation = (props) => {
    const slug = "[" + props.base.cp_id + "] "
        + props.base.number + ": " + props.base.name

    let translation
    switch (props.base.cp_id % 4) {
        case 0:
            translation = '-50%, -150%'
            break
        case 1:
            translation = '-25%, -150%'
            break
        case 2:
            translation = '-50%, -175%'
            break
        case 3:
            translation = '-25%, -175%'
            break
    }
    return <div
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <Bar angleClock={props.angleDeg}/>

        <A.Tooltip title={slug}>
            <Icon icon={mapMarker}
                  style={{fontSize: 12, color: props.color}}/>
        </A.Tooltip>
    </div>
}

const ReferencePoint = ({
                            back,
                            onClick,
                            latitude,
                            longitude,
                            angleDeg,
                            cpId,
                            ...props
                        }) => {
    return <div
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            opacity: '0.7'
        }}
    >
        <Bar angleClock={angleDeg} style={{opacity: 0.5}}/>
        <A.Tooltip title={"" + cpId} onClick={onClick}>
            <Icon icon={mapMarker} style={{
                fontSize: 4,
                color: back ? "#f66" : "#66f"
            }}/>
        </A.Tooltip>
    </div>
}

const AvlNode = ({display, key, style = {}, text = " ", ...props}) => {
    if (!display)
        return null
    return <div
        style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            height: '10px',
            borderColor: "#000",
            backgroundColor: "#fff",
            color: "#000",
            fontFamily: 'monospace',
            padding: '1px',
            fontSize: '8px',
            ...style
        }}
    >{text}</div>
}

const PlotCoords = ({coords, style = {}, display = true, ...props}) => {
    // NOTE cannot use state with unmounting hooks
    // const [plot, setPlot] = useState(null)
    const {map, maps} = useContext(GoogleMapContext)
    const plot = useRef()
    useEffect(() => {
        return () => {
            console.debug("Component is unmounting, clearing plot")
            if (plot.current) {
                plot.current.setMap(null)
            } else
                console.warn("There was no plot to remove")
        }
    }, [])

    useEffect(() => {
        // NOTE: The visible property apparently doesn't actually work. It's
        // settable, but reverts to false. Can't find a setter and the
        // documentation is completely barren. So probably don't use this
        // attribute.
        if (plot && plot.current) {
            console.log("Updating plot visibility", display, plot)
            plot.current.visible = display
            //plot.current.setMap(null)
            //plot.current = null
        }
    }, [display, plot])

    useEffect(() => {
        if (plot.current) {
            plot.current.setMap(null)
            // TODO don't leak
        }

        if(!map || !maps || !coords) return

        const newPlot = new maps.Polyline({
            path: coords,
            geodesic: false,
            strokeColor: "#0000ff",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            visible: display,
            ...style
        })
        newPlot.setMap(map)
        console.debug("Plot has been mapped, assigning to state")
        plot.current = newPlot
        // setPlot(newPlot)
    }, [map, coords])

    return null
}


export function GoogleTripPlotter({
                                     displayState,
                                 }) {
    const gmc = useContext(GoogleMapContext)
    const tc = useContext(TaxContext)
    const ttc = useContext(TripTaxContext).tracked

    // Fit bounds to new paths
    useEffect(() => {
        if(!ttc)
            return
        gmc.fitBoundsToLine(ttc.interpCoords)
        console.debug("EXPECTED BOUNDS TO REFIT")
    }, [ttc])

    const [hoverReference, setHoverReference] = useState({})
    const toggleHoverReference = (s) => {
        setHoverReference({
            ...hoverReference,
            [s.cpId]: !hoverReference[s.cpId]
        })
    }

    console.debug("Checking trip tax", ttc)

    return (!ttc || tc.loading) ? null : <GoogleMapReact {...gmc.mapProps}>
        { ttc.passages.map((p, i) => {
            let translation
            switch (p.cp_id % 4) {
                case 0:
                    translation = '-50%, -150%'
                    break
                case 1:
                    translation = '-25%, -150%'
                    break
                case 2:
                    translation = '-50%, -175%'
                    break
                case 3:
                    translation = '-25%, -175%'
                    break
            }
            const dateFmt = p.isotime.replace(/^\d\d\d\d-\d\d-\d\dT(\d\d:\d\d).*$/, '$1')
            const _ = props => props.children
            return <_ lat={p.intersection.lat}
                      lng={p.intersection.lng}
                      key={i}>
                <A.Tooltip title={'['+(p.cp_id%10000)+']'}>
                <div style={{
                    display: p.hit ? "block" : "none",
                    position: 'absolute',
                    transform: 'translate(' + translation + ')',
                    color: "#000",
                    fontSize: "1.2em",
                    fontFamily: "Arial",
                    opacity: 1,
                    border: "1px solid #990",
                    backgroundColor: "#fff",
                    zIndex:10000,
                }}>{dateFmt}{ p.price && '\n' + p.price + '\u00A0SEK'}</div>
                </A.Tooltip>
            </_>
        }) }

        { null && ttc.passages.map((p, i) => {
            return <AvlNode
                key={i}
                display={true}
                style={{
                    backgroundColor: p.hit && "#0f0" || "#933",
                    zIndex: p.hit && 1000 || 1
                }}
                lat={p.intersection.lat}
                lng={p.intersection.lng}
            />
        }) }
        {displayState.originalTrip &&
            <PlotCoords coords={ttc.coords} style={{strokeColor: "#333333"}}/>}
        {displayState.resampledTrip && ttc.resampledTrip && ttc.resampledTrip.map((s, i) => {
            return <AvlNode
                key={i}
                text={"" + i}
                display={displayState.showAllCoords}
                lat={s.lat}
                lng={s.lng}
            />
        })}
        {displayState.resampledTrip &&
            <PlotCoords
                coords={ttc.resampledTrip} style={{strokeColor: "#ff00ff"}}/>}
        {displayState.interpTrip &&
            <PlotCoords
                coords={ttc.interpCoords}
                style={
                    ttc.snapSource === 'google'
                        ? {strokeColor: "#0000ff"}
                        : {strokeColor: "#ff0000"}
                }/>}

        {displayState.tolls && Object.values(tc.stations).map((p, i) => {
            const props = {
                key: i,
                hit: ttc.hits.includes(p.base.cp_id),
                lat: p.lat,
                lng: p.lng,
                ...p
            }
            return (props.hit || displayState.allStations) && <PayStation {...props} />
        }).filter(x => x) || null}

        {displayState.references && tc.references.map((s, i) => {
            return <ReferencePoint
                key={i}
                onClick={() => toggleHoverReference(s)}
                lat={s.latitude} lng={s.longitude} {...s}
            />
        }) || null}


    </GoogleMapReact>
}

const Bar = ({angleClock, style}) => {
    return <img src={"/img/fig.png"}
                alt="Missing 'alt' attribute"
                style={{
                    position: 'absolute',
                    transform: 'rotate(' + angleClock + 'deg)',
                    ...style
                }}
    />
}

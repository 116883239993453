import React, {useState} from "react";
import {SD_BENEFIT_TYPES} from "../../../constants/CustomConstant";
import {lookupStyleMarker} from "./lookupStyleMarker";
import {Avatar, List, Modal, Tooltip} from "antd";
import {Icon} from "react-icons-kit";

export const ModalWithFullList = (title, vehicles, emails, users, knownVehicles, knownUsers, knownOrganizations) => {
    const [modalVisible, setModalVisible] = useState(false);

    const dataVehicles = vehicles?.map((i) => {
        const vehicle = knownVehicles?.find((v) => v?.id === i);
        const org = knownOrganizations?.find((org) => vehicle?.organizationId === org?.organizationId);
        const displayName = vehicle?.displayName ? vehicle?.displayName + " ": "";
        const registry = vehicle?.registry ? vehicle?.registry : "";

        let vehicleTitle = (displayName + registry) || vehicle?.id;
        return {
            title: vehicleTitle,
            org: org?.fullName,
            id: vehicle?.id,
            style: vehicle?.style,
            benefitType: vehicle?.benefitType,
            isTracker: vehicle?.isTracker
        };
    });
    const dataEmails = emails?.map((email) => {
        return {
            title: email,
            id: email?.id

        };
    });

    const dataUsers = users?.map((i) => {
        const user = knownUsers?.find((v) => v?.userId === i);
        const org = knownOrganizations?.find((org) => user?.organizationId === org?.organizationId);
        return {
            title: user?.displayName,
            org: org?.fullName,
            id: user?.id
        };
    });

    function generateTooltipContent(listData) {
        const maxToShow = 5;
        const totalItems = listData?.length || 0;

        if (totalItems > maxToShow) {
            const remainingItems = totalItems - maxToShow;
            const firstFifteenItems = listData.slice(0, maxToShow).map(item => item?.title || item?.id);

            return `${firstFifteenItems.join('\n')}\n(+${remainingItems} mer)`;
        } else {
            return listData.map(item => item?.title || item?.id).join('\n');
        }
    }


    const listData = dataVehicles || dataEmails || dataUsers;
    const tooltipContent = generateTooltipContent(listData);

    const modalContentDataVehicles = Object.entries(SD_BENEFIT_TYPES)
        .map(([key, title]) => {
            const data = listData?.filter(item => item.benefitType === title.TYPE && !item?.isTracker)
            if (data?.length < 1) return null;
            return (
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    header={title.TYPE_DISPLAY_TEXT}
                    renderItem={(item) => {
                            return (
                                <List.Item key={item?.id}>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar style={{ backgroundColor: item?.style?.color }}>
                                                {<Icon icon={lookupStyleMarker[item?.style?.marker]} />}
                                            </Avatar>
                                        }
                                        title={item?.title}
                                        description={item?.org}
                                    />
                                </List.Item>
                            );
                    }}
                />
            );
        });

    const utrustningData = listData?.filter(item => item.isTracker);
    if (utrustningData?.length > 0) {
        modalContentDataVehicles.push(
            <List
                itemLayout="horizontal"
                dataSource={utrustningData}
                header="Utrustning"
                renderItem={(item) => (
                    <List.Item key={item?.id}>
                        <List.Item.Meta
                            avatar={
                                <Avatar style={{ backgroundColor: item?.style?.color }}>
                                    {<Icon icon={lookupStyleMarker[item?.style?.marker]} />}
                                </Avatar>
                            }
                            title={item?.title}
                            description={item?.org}
                        />
                    </List.Item>
                )}
            />
        );
    }
    const modalContentDataUsers = <List
        itemLayout="horizontal"
        dataSource={listData}
        header={title.TYPE_DISPLAY_TEXT}
        renderItem={(item) => {
            return <List.Item key={item?.id}>
                <List.Item.Meta
                    title={item?.title} description={item?.org}/>
            </List.Item>
        }}
    />

    const modalContent = (content) => <div
        id="scrollableDiv"

    >
        {content}
    </div>;


    console.log(modalContentDataVehicles)
    const selectedListContent = dataVehicles ? modalContentDataVehicles : modalContentDataUsers;

    return <>
        <Modal
            visible={modalVisible}
            title={title}
            icon={false}
            onCancel={() => setModalVisible(false)}
            onOk={() => setModalVisible(false)}
            footer={null}
            bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}

        >
            {modalContent(selectedListContent)}
        </Modal>
            <a onClick={() => setModalVisible(true)}>{title}</a>
    </>

};
import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Table, Space, Typography, Divider, Checkbox } from "antd";
import { TRACKER_TYPES } from "services/redux/constants/deviceConstants";
import styles from "./styles";
import { ServicesContext } from "services/contexts/ServicesContext";
import { ORGANIZATION_ID } from "services/redux/constants/authConstants";
import { OrganizationContext } from "services/contexts/OrganizationsContext";
import {STATIC_TEXT} from "../../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../../constants/CustomConstant";
const { Text } = Typography;
const ExportTrackerReportModal = ({ visible, vehicles, onCancel = () => null, onOk = () => null }) => {
    const { getTrackersReport } = useContext(ServicesContext);
    const { organizations } = useContext(OrganizationContext);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const userOrganizations = JSON.parse(localStorage.getItem(ORGANIZATION_ID));
    useEffect(() => {
        if (vehicles) {
            const options = vehicles
                .filter((i) => TRACKER_TYPES.includes(i?.deviceType))
                .map((i) => {
                    return (
                        <Checkbox value={i?.meta.vehicle_id} style={{ ...styles.checkbox }} key={i?.meta.vehicle_id}>
                            {i.meta.reg}
                        </Checkbox>
                    );
                });
            setOptions(options);
        }
    }, [vehicles]);
    const onSelect = (value) => {
        setSelected(value);
    };
    const getTheReport = async () => {
        const orgName = organizations.find((i) => i.organizationId === userOrganizations);
        const res = await getTrackersReport(selected, orgName?.fullName);
        if (res !== false) {
            setTimeout(() => {
                onOk();
            }, 300);
        } else {
            onCancel();
        }
    };
    return (
        <>
            <Modal title="Export Tracker Report" visible={visible} onCancel={onCancel} onOk={getTheReport}>
                <Text>Välj vilka trackers som ska inkluderas i rapporten</Text>
                <Divider style={{ ...styles.divider }} />
                <Checkbox.Group onChange={(value) => onSelect(value)}>{options}</Checkbox.Group>
            </Modal>
        </>
    );
};

export default ExportTrackerReportModal;

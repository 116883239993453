import React, {useContext, useEffect, useState} from "react"
import {
    Alert,
    Button,
    Checkbox,
    ConfigProvider,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Select,
    Spin,
    Table,
    Tabs,
    Tooltip,
    Typography
} from 'antd';
import Loading from 'components/shared-components/Loading/Loading'
import * as icons from '@ant-design/icons';
import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    LockOutlined,
    MailOutlined,
    UpSquareOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import {dataApi, userApi} from "../../services/api/api";
import {OrganizationsTreeSelect} from "./shared-components/OrganizationsTreeSelect";
import {LastSeenLink} from "./shared-components/DatumLink";
import {FORM_MESSAGES, STATIC_TEXT, userRoles} from "../../lang/translate/sv-SE/sv-SE";
import {CONSTANTS_TEXT, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../constants/CustomConstant";
import {ButtonMenuDropdown, tableColumnsWithTooltip, tableProps} from "./shared-components/sharedComponents";
import {AlertArchived, TabsArchived} from "./shared-components/TabsArchived";
import {TabBadge} from "./Superdevices/reusableComponents";
import {customizeRenderEmpty} from "./shared-components/customizeRenderEmpty";
import {httpErrorMessages} from "../../constants/httpErrorMessages";
import {
    shouldHideUsersOrganizationsColumn,
    UserListWithArchivedContext,
    VehiclesComplexListContext
} from "../../services/contexts/contexts";
import {activateUser, archiveUser} from "./shared-components/archiveUserModals";
import {columnPermittedDrivers} from "./userroles";
import {SettingsForm} from "./shared-components/settingsForm";

const {Option} = Select;
const {TabPane} = Tabs;

export const UsersController = () => {
    const [organizationId, setChangeOrganisation] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [knownOrganizations, setKnownOrganizations] = useState([])

    useEffect(() => {
        dataApi.getOrganizationsSimple().then(r => {
            setKnownOrganizations(r)
        })
    }, [])

    const organizationSelectOnChange = value => {
        setChangeOrganisation(value)
    };

    const handleChangeSearchInput = event => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return <>
        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20, flexWrap: "wrap"}}>
            <Typography.Title>Superadmin - Användare</Typography.Title>
            <div style={{display: 'flex', gap: 15, flexWrap: "wrap"}}>
                <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                         selectedOrganization={organizationId} isMultiple={true}/>
                <Input.Search
                    type="text"
                    placeholder="Sök efter namn eller e-post"
                    label="Registreringsnummer"
                    defaultValue={searchTerm}
                    enterButton
                    onChange={handleChangeSearchInput}
                    style={{width: 300}}
                />
                <Button
                    type={'primary'}
                    onClick={() => setIsModalVisible(true)}
                    icon={<UserAddOutlined/>}>
                    Lägg till
                </Button>
            </div>
        </div>
        <EditableTable
            knownOrganizations={knownOrganizations}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            organizationId={organizationId}/>
    </>
}

const selectRoles = [
    <Option value={SUPER_DEVICES_KEYS_AND_INDEXES.ADMIN}>Företagsadmin</Option>,
    <Option value={SUPER_DEVICES_KEYS_AND_INDEXES.DRIVER}>Förare</Option>
]
const EditableTable = (props) => {
    const [form] = Form.useForm();
    const [showAlertArchived, setShowAlertArchived] = useState(false);
    const [archivedData, setArchivedData] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [loading, setLoading] = useState(true)
    const [isModalUsersPassword, setIsModalUsersPassword] = useState(false)
    const vehicles = useContext(VehiclesComplexListContext)
    const knownVehicles = vehicles?.filter(v => !v.isArchived);
    const users = useContext(UserListWithArchivedContext)
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);

    useEffect(() => {
        if (data && knownVehicles) setLoading(false)
    }, [data, knownVehicles])

    useEffect(() => {
        const sortData = data?.sort((a, b) => a.displayName?.localeCompare(b.displayName));
        setActiveData(sortData?.filter((item) => !item.isArchived))
        setArchivedData(sortData?.filter((item) => item.isArchived))
    }, [data])

    useEffect(() => {
        users?.map(item => {
            item.extraRoles = item?.roles?.map(role => userRoles[role])
        })
        setAllData(users)
    }, [users])

    useEffect(() => {
        let filteredList = allData?.filter(
            i => JSON.stringify(i).toLowerCase().includes(props.searchTerm))

        if (props?.organizationId?.length > 0) {
            filteredList = filteredList?.filter(
                i => props.organizationId.includes(i.organizationId)
            )
        }

        setData(filteredList)
    }, [props.organizationId, allData, props.searchTerm])

    function handleUsersPassword(userId, data) {
        userApi.patchNewPassword(userId, data).then(res => {
            setIsModalUsersPassword(false)
            setEditingKey('')
        })

    }

    function handleAdd(row, id) {
        const newData = [...allData];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];

        if (index > -1) {
            newData.splice(index, 1, {...item, ...row});
        } else {
            newData.push(row);
        }

        setData(newData)
        setAllData(newData)
        props.setIsModalVisible(false)
        setEditingKey(null)

    }

    const handleSelectChangeUsersOrganization = (record, id) => {
        const req = {organizationId: id}

        dataApi.patchChangeOrgUser(record.userId, req)
            .then(_ => {
                message.success("Användare flyttades till en annan organisation.")

                const newData = [...allData];
                const index = newData.findIndex(item => record.userId === item.userId);
                const item = newData[index];
                let newRow
                newRow = {
                    ...item,
                    permittedVehicles: [],
                    organizationId: id,
                    organizations: [{organizationId: id}]
                }
                newData.splice(index, 1, {...item, ...newRow});
                setData(newData)
                setAllData(newData)
            })
    }

    const sendInvitation = (record) => {
        const newData = [...allData];
        const index = newData.findIndex(item => record.userId === item.userId);
        const item = newData[index];

        const data = {
            email: record.displayEmail,
            fullName: record.displayName
        }

        const row = {
            inviteSentTsMs: Date.now()
        }

        dataApi.postInviteUser(data)
            .then(_ => {
                message.success("Inbjudan har skickats")
                if (index > -1) {
                    newData.splice(index, 1, {...item, ...row});
                    setData(newData);
                    setAllData(newData)
                }

            }).catch(e => {
            console.error(e)
            message.error('Något har gott fel. Försok igen')
        })


    }

    console.log(editingKey)

    function editUser(record) {
        setEditingKey(record);
        props.setIsModalVisible(true)
    }

    const columnsArchived = [
        {
            title: 'Namn',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'E-post',
            dataIndex: 'displayEmail',
            key: 'displayEmail',
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: "Arkiverad",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.archivedTsMs,
            width: 100,
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker} defaultText={true}/> : "-"),
        },
        {
            title: "",
            dataIndex: "operation",
            render: (text, record) => (
                <div style={{float: "right"}}>
                    <Button
                        icon={<UpSquareOutlined/>}
                        onClick={() => activateUser(record, allData, setAllData, editUser)}>
                        {STATIC_TEXT.ACTIVE}
                    </Button>
                </div>
            )
        },
    ]

    const editUsersPassword = (record) => {
        setEditingKey(record);
        setIsModalUsersPassword(true)
    };

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => editUser(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item icon={<LockOutlined/>}
                       onClick={() => editUsersPassword(record)}>Ändra lösenord</Menu.Item>
            <Menu.Item icon={<HomeOutlined/>}
                       onClick={() => ChangeUsersOrganization(record, props.knownOrganizations, handleSelectChangeUsersOrganization)}>{STATIC_TEXT.MOVE_TO_ORG} </Menu.Item>

            <Menu.Item icon={<FolderOpenOutlined/>}
                       onClick={() => archiveUser(record, allData, setAllData)}>
                {STATIC_TEXT.ARCHIVE}</Menu.Item>
        </Menu>
    }

    const columns = [
        {
            key: 'sendMail',
            width: 50,
            fixed: 'left',
            render: (value, record) => {
                return (
                    <Tooltip title="Skicka inbjudan till den användaren">
                        <a onClick={() => sendInvitation(record)}>
                            <MailOutlined style={{fontSize: '18px'}}/>
                        </a>
                    </Tooltip>
                )
            }
        },
        {
            title: 'Namn',
            dataIndex: 'displayName',
            key: 'displayName',
            fixed: 'left',
        },
        {
            title: 'E-post',
            dataIndex: 'displayEmail',
            key: 'displayEmail',
        },
        {
            title: 'Mobilnummer',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            editable: false,
            width: 150,
            hidden: hideUsersOrg,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: 'Roll',
            dataIndex: 'roles',
            key: 'roles',
            width: 150,
            render: (text) => (
                text?.map(e => {
                    return userRoles[e] + " "
                })
            ),
        },
        {
            title: 'GDPR',
            dataIndex: 'gdprApproved',
            key: 'gdprApproved',
            width: 80,
            render: (gdprApproved, record) => {
                return record.gdprApproved === true ? <CheckOutlined/> : <CloseOutlined/>
            }
        },
        {
            title: 'Senast aktiv',
            dataIndex: 'lastSeenApp',
            key: 'lastSeenApp',
            width: 120,
            render: (text) => (
                text ? <LastSeenLink value={text} typ={"map"} defaultText={true}/> : "-"
            )
        },
        {
            title: STATIC_TEXT.SENT_INVITE,
            dataIndex: 'inviteSentTsMs',
            key: 'inviteSentTsMs',
            width: 150,
            render: (text) => (
                text ? <LastSeenLink defaultText={true} value={text} typ={"map"}/> : "-"
            )
        },
        {
            title: "Behörig till",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.permittedVehicles,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.permittedVehicles,
            width: 120,
            render: (permittedVehicles, record) => {
                return columnPermittedDrivers(record, permittedVehicles, knownVehicles, null, props.knownOrganizations, false)
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    const onModalCancel = () => {
        setEditingKey(null)
        props.setIsModalVisible(false)
    };

    return (
        loading ? <Loading/> :
            <Form form={form} component={false}>
                <EditUserForm
                    onModalCancel={onModalCancel}
                    isModalVisible={props.isModalVisible}
                    knownOrganizations={props.knownOrganizations}
                    organizationId={props.organizationId}
                    vehicles={knownVehicles}
                    setData={handleAdd}
                    sendInvitation={sendInvitation}
                    isRoot={true}
                    record={editingKey}/>

                <ChangeUsersPassword record={editingKey} isModalOpen={isModalUsersPassword}
                                     handleOk={handleUsersPassword}
                                     handleCancel={() => {
                                         setEditingKey('')
                                         setIsModalUsersPassword(false)
                                     }}/>

                <TabsArchived
                    setShowAlertArchived={setShowAlertArchived}
                    showAlertArchived={showAlertArchived}
                    content={
                        <>
                            <TabPane
                                key={'1'}
                                tab={<TabBadge
                                    text={'Aktiva användare'}
                                    overflowCount={9999999}
                                    badgeColor={'#3f3f3f'}
                                    count={activeData?.length}/>}
                            >
                                {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                <Table
                                    dataSource={activeData}
                                    columns={tableColumnsWithTooltip(columns)}
                                    scroll={{x: 1500}}
                                    rowClassName="editable-row"
                                    {...tableProps}
                                    tableLayout={"fixed"}
                                />

                            </TabPane>
                            <TabPane
                                key={'2'}
                                tab={<TabBadge
                                    text={'Arkiverade användare'}
                                    overflowCount={9999999}
                                    badgeColor={'#3f3f3f'}
                                    count={archivedData?.length}/>}
                            >
                                {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                <ConfigProvider
                                    renderEmpty={() => customizeRenderEmpty("Du har inga arkiverade användare")}>
                                    <Table
                                        dataSource={archivedData}
                                        columns={tableColumnsWithTooltip(columnsArchived)}
                                        scroll={{x: 1000}}
                                        rowClassName="editable-row"
                                        {...tableProps}
                                    />
                                </ConfigProvider>

                            </TabPane>

                        </>
                    }
                />

            </Form>
    );
};

const layout = {
    display: 'grid',
    alignSelf: 'center',
    textAlign: 'right',
    backgroundColor: 'red',
    justifyContent: 'center',
    margin: 'auto',
    labelCol: {span: 8},
    wrapperCol: {span: 20},
};

export const EditUserForm = (props) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [handleAddForm] = Form.useForm();
    const [useOrganization, setUseOrganization] = useState(props.record?.organizationId);
    const [selectedPermittedVehicles, setSelectedPermittedVehicles] = useState();
    const [errorMessageUser, setErrorMessageUser] = useState(null);
    const [switchStates, setSwitchStates] = useState({});
    const [initialSwitchStates, setInitialSwitchStates] = useState({});
    const [record, setRecord] = useState()
    const [activeTabKey, setActiveTabKey] = useState('1');

    useEffect(() => {
        setRecord(props.record)
    }, [props.record])

    useEffect(() => {
        handleAddForm.resetFields()
        clearValues()
    }, [props.isModalVisible])

    useEffect(() => {
        if (!props.record) {
            handleAddForm.setFieldsValue({["vehicles"]: []})
        }
        clearValues()
    }, [useOrganization])

    useEffect(() => {
        if (props.isModalVisible === true)
            setUseOrganization(props.record?.organizationId)
        setSubmitButtonDisabled(false)
    }, [props.isModalVisible])

    const onFinish = values => {
        setSubmitButtonDisabled(true)
        handleAdd(values)
    };

    function clearValues() {
        handleAddForm.setFieldsValue({["organizationId"]: useOrganization})
        setSubmitButtonDisabled(false)
        setErrorMessageUser(null)
        setInitialSwitchStates({})
        setSwitchStates({})
        setActiveTabKey('1');
    }

    const onModalCancelNewForm = () => {
        setUseOrganization(null)
        props.onModalCancel()
    };

    function handlePostUser(values) {
        const row = {
            ...values,
            gdprApproved: false,
            organizationId: useOrganization,
            sendInvite: values.sendInvite,
            inviteSentTsMs: values.sendInvite ? Date.now() : null,
        };

        let id;
        dataApi.postNewUser(row, useOrganization)
            .then((res) => {
                id = res.userId;
                props.setData(res, res.userId);
                Object.entries(switchStates).map(([name, value]) => {
                    if (initialSwitchStates[name] === value) return Promise.resolve(null);

                    if (value) {
                        return dataApi.postAdminUserSettings(name, id).then(finalRes => {
                            props.setData(finalRes, id);
                        })
                    } else {
                        return dataApi.deleteAdminUserSettings(name, id).then(finalRes => {
                            props.setData(finalRes, id);
                        })
                    }
                });
            })
            .catch(e => {
                // Handle errors from any part of the process
                let translatedMessage = httpErrorMessages[e?.response?.data];
                setErrorMessageUser(translatedMessage);
                setSubmitButtonDisabled(false);
            });
    }

    function handlePatchUser(values) {
        let userId = props.record.userId;

        dataApi.patchUser(userId, values)
            .then((res) => {
                props.setData(res, userId);

                Object.entries(switchStates).map(([name, value]) => {
                    if (initialSwitchStates[name] === value) return Promise.resolve(null);

                    if (value) {
                        return dataApi.postAdminUserSettings(name, userId).then(finalRes => {
                            props.setData(finalRes, userId);
                        })
                    } else {
                        return dataApi.deleteAdminUserSettings(name, userId).then(finalRes => {
                            props.setData(finalRes, userId);
                        })
                    }
                });
            })
            .catch(e => {
                let translatedMessage = httpErrorMessages[e?.response?.data];
                setErrorMessageUser(translatedMessage);
                setSubmitButtonDisabled(false);
            });
    }

    const handleAdd = (values) => {
        let permissions = values.vehicles ? values.vehicles.map(d => {
            const index = props.vehicles.findIndex(u => u.id === d)
            const vehicle = props.vehicles[index]
            return {
                id: vehicle.id,
                displayName: vehicle.displayName,
                registry: vehicle.registry
            }
        }) : []

        values = {
            ...values,
            permittedVehicles: permissions
        }

        if (props.record) {
            handlePatchUser(values)
        } else {
            handlePostUser(values)
        }


    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const permittedVehiclesForOrg = props.vehicles?.filter(v => v.organizationId === useOrganization)?.filter(v => v.benefitType !== CONSTANTS_TEXT.POOL);
    const defaultPermittedVehicles = props.vehicles?.filter(vehicle =>
        props.record?.permittedVehicles?.some(permission => permission.id === vehicle.id)
    )?.filter(v => v.benefitType !== CONSTANTS_TEXT.POOL)

    function getForm() {
        return <Form
            {...layout}
            form={handleAddForm}
            name="basic"
            initialValues={props.record}
            layout='vertical'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete={"off"}
        >

            {errorMessageUser &&
                <>
                    <Alert type={"error"} message={errorMessageUser} showIcon/>
                    <br/>
                </>
            }

            <Form.Item
                label="Organisation"
                name="organizationId"
                rules={[{required: true, message: 'Välj organisation!'}]}
            >
                <OrganizationsTreeSelect disabled={props.record} data={props.knownOrganizations}
                                         selectedOrganization={useOrganization}
                                         onChange={(v) => setUseOrganization(v)}/>
            </Form.Item>
            <Form.Item
                label="Namn"
                name="displayName"
                rules={[{required: true, message: 'Ange namn!'}]}
            >
                <Input
                    prefix={<icons.UserOutlined style={{marginRight: 3}}/>}
                    placeholder={STATIC_TEXT.SUPPLY_A_FIRST_LAST_NAME}/>
            </Form.Item>
            <Form.Item
                label="E-post"
                name="displayEmail"
                rules={[
                    {
                        type: 'email',
                        message: FORM_MESSAGES.PERSON_WRONG_EMAIL,
                    },
                    {
                        required: true,
                        message: FORM_MESSAGES.PERSON_WRONG_EMAIL,
                    },
                ]}
            >
                <Input
                    disabled={props.record && !props.isRoot}
                    prefix={<icons.MailOutlined style={{marginRight: 3}}/>}
                    placeholder={STATIC_TEXT.SUPPLY_AN_EMAIL_ADDRESS}/>
            </Form.Item>
            <Form.Item
                label="Mobilnummer"
                name="phone"
                rules={[
                    {
                        required: false,
                        message: FORM_MESSAGES.PERSON_WRONG_PHONE,
                    },
                ]}
            >
                <Input
                    prefix={<icons.PhoneOutlined style={{marginRight: 3}}/>}
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_PHONE}/>
            </Form.Item>
            <Form.Item
                label="Roll"
                name="roles"
                rules={[{required: true, message: 'Välj roll!'}]}
                initialValue={[SUPER_DEVICES_KEYS_AND_INDEXES.DRIVER]}
            >
                <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                        JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())}
                    mode="multiple"
                    placeholder="Välj roll"
                >
                    {selectRoles}
                </Select>
            </Form.Item>
            <Form.Item
                label="Behörig till"
                name="vehicles"
                rules={[{required: false, message: 'Välj fordon!'}]}
                initialValue={defaultPermittedVehicles?.map(item => item.id)}
            >
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    defaultValue={defaultPermittedVehicles?.map(item => item.id)}
                    filterOption={(input, option) =>
                        JSON.stringify(option?.object)?.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{width: '100%'}}
                    placeholder="Välj fordon">

                    <Option disabled={true} value={"xxx"}>
                        {selectedPermittedVehicles?.length > 0 ? (
                            <span
                                onClick={() => {
                                    handleAddForm.setFieldsValue({["vehicles"]: []})
                                    setSelectedPermittedVehicles([])
                                }}
                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Avmarkera alla
                            </span>
                        ) : (
                            <span
                                onClick={() => {
                                    const m = permittedVehiclesForOrg?.map(({id}) => id)
                                    handleAddForm.setFieldsValue({["vehicles"]: m})
                                    setSelectedPermittedVehicles(permittedVehiclesForOrg)
                                }}
                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Välj alla
                            </span>
                        )}
                    </Option>

                    {permittedVehiclesForOrg?.map((d, _) => {
                        if ((d.registry !== "" && d.registry) || d.displayName) {
                            return <Option value={d.id}
                                           object={d}>{d.registry} {d.displayName}</Option>
                        } else {
                            return <Option value={d.id} object={d}>Okänd {d.id}</Option>
                        }
                    })}
                </Select>
            </Form.Item>
            {!props.record && <Form.Item
                name={SUPER_DEVICES_KEYS_AND_INDEXES.sendInvite}
                initialValue={true}
                valuePropName="checked">
                <Checkbox>Skicka inbjudan via e-post</Checkbox>
            </Form.Item>}
        </Form>;
    }

    return (
        <Modal centered title="Användare" onCancel={onModalCancelNewForm}
               onOk={() => handleAddForm.submit()} visible={props.isModalVisible}
               okText={props.record ? "Spara" : "Lägg till ny användare"}
               bodyStyle={{overflowY: 'auto', height: 'calc(100vh - 200px)'}}
        >

            <div style={{justifyContent: 'center',}}>
                <Spin spinning={submitButtonDisabled} tip="Vänta...">

                    {props.isRoot ?
                        <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey}>
                            <TabPane tab="Allmän" key="1">
                                {getForm()}

                            </TabPane>
                            {record && props.isRoot && <TabPane tab="Inställningar" key="2">
                                <SettingsForm record={record}
                                              initialSwitchStates={initialSwitchStates}
                                              setInitialSwitchStates={setInitialSwitchStates}
                                              switchStates={switchStates} setSwitchStates={setSwitchStates}/>

                            </TabPane>
                            }
                        </Tabs>
                        :
                        <>{getForm()}</>
                    }


                </Spin>
            </div>
        </Modal>
    );
};

export const ChangeUsersOrganization = (record, organizations, handleSelectChangeUsersOrganization) => {
    let organizationId;
    Modal.confirm({
        style: {minWidth: 500},
        title: `Flytta användare ${record.displayName} till en annan organisation`,
        content: (
            <OrganizationsTreeSelect
                width={"100%"}
                extra={{allowClear: false}}
                data={organizations}
                defaultValue={record.organizationId}
                onChange={(v) => organizationId = v}/>

        ),
        onOk() {
            handleSelectChangeUsersOrganization(record, organizationId)
        },
    });
}

export const ChangeUsersPassword = ({record, isModalOpen, handleOk, handleCancel}) => {
    const [form] = Form.useForm();

    const onFormSubmit = (values) => {
        const userId = record.id
        const data = {password: values.newPassword}
        handleOk(userId, data);
    };

    function onCancel() {
        form.resetFields();
        handleCancel()
    }

    let titleModal = "Ändra användarlösenord för " + record?.displayName || record?.displayEmail
    return (
        <Modal
            title={titleModal}
            visible={isModalOpen}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onFormSubmit(values);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                }}
            >
                <Form.Item
                    name="newPassword"
                    label="Nytt lösenord"
                    rules={[
                        {required: true, message: 'Vänligen ange det nya lösenordet!'},
                        {min: 8, message: 'Lösenordet måste vara minst 8 tecken långt.'}
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    label="Bekräfta lösenord"
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        {required: true, message: 'Vänligen bekräfta det nya lösenordet!'},
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('De angivna lösenorden matchar inte.'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    );
};


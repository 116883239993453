export const httpErrorMessages = {
    DEVICE_ALREADY_INSTALLED: "Enhet redan kopplad",
    DEVICE_FORBIDDEN: "Enhet förbjuden att utföra åtgärd",
    DEVICE_INCOMPLETE: "Enhet ej fullständig",
    DEVICE_MISSING: "Enhet saknas",
    DEVICE_MISSING_VEHICLE: "Enhet saknar fordon",
    DEVICE_NOT_INSTALLED: "Enhet är inte installerad",
    DEVICE_RETIRED : "Enheten är redan pensionerad",	//2021-08-02
    DIFFERENT_ORGANIZATIONS: "Enhet, fordon eller användare är inte inom samma organisation",
    DRIVER_NOT_PERMITTED_VEHICLE: "Förare har inte tillstånd att placeras i detta fordon",
    EMAIL_ALREADY_EXIST: "E-post finns redan",
    ICCID_ALREADY_EXIST: "ICCID-nummer finns redan",
    ICCID_LENGTH: "Fel längd på ICCID-nummer. Ska vara 18-22 siffror i längd.",
    ID_MISMATCH: "ID är felaktigt",
    IMEI_ALREADY_EXIST: "IMEI-nummer finns redan",
    IMEI_LENGTH: "Fel längd på IMEI-nummer. Ska vara 14-16 siffror i längd.",
    JOURNAL_MISSING: "Journal saknas",
    MISFORMATTED_FIELDS : "Ett eller flera fält är felformaterade, se över så att stavningen är korrekt",	//2021-08-02
    MISSING_CLEARANCE: "Du saknar rättigheter för att utföra denna åtgärd",
    MISSING_FIELDS: "Din förfrågan saknar obligatoriska värden",
    MISSING_TOKEN: "Felaktig länk",
    NO_HISTORICAL_ODOMETER_REFERENCE: "Ingen referens till historik mätarställning kunde hittas",
    NO_ODOMETER_REFERENCE: "Ingen referens till mätarställning kunde hittas",
    NO_USER_CHECKED_IN: "Ingen användare incheckad",
    OLD_PASSWORD_MISSING: "Gammalt lösenord saknas",
    ONLY_DIGITS: "Detta värde får endast innehålla nummer",
    ORGANIZATION_FORBIDDEN: "Organisation förbjuden att utföra denna åtgärd",
    ORGANIZATION_MISSING: "Organisation saknas",
    PASSWORD_MISMATCH: "Felaktigt lösenord",
    PASSWORD_SHORT: "Lösenordet är för kort",
    PHONE_ALREADY_EXIST: "Telefon-nummer finns redan",
    PHONE_LENGTH: "Fel längd på Telefon-nummer.Minst 14 siffror i längd.",
    PRIVATE_TRIP_DIFFERENT_USER: "Du har inte behörighet att ändra andras privata resor",
    REGISTRY_ALREADY_EXIST: "Registreringsnummer finns redan registrerat",
    REGISTRY_ALREADY_SET: "Registreringsnummer är redan registrerat",
    ROOT_OR_ADMIN_REQUIRED: "Root eller admin-åtkomst krävs",
    ROOT_REQUIRED: "Root-åtkomst krävs",
    SAME_PASSWORD: "Samma lösenord",
    TRIPS_NOT_CONNECTED: "Resor är inte kopplade",
    USER_FORBIDDEN: "Användare förbjuden att utföra åtgärd",
    USER_MISSING: "Användare saknas",
    USERNAME_MISSING: "Användarnamn saknas",
    VEHICLE_FORBIDDEN: "Fordon förbjuden att utföra åtgärd",
    VEHICLE_HAS_DEVICE: "Fordon har enhet kopplad",
    VEHICLE_MISSING: "Fordon saknas",
    VEHICLE_MISSING_DEVICE: "Fordon saknar enhet",
    WEEKDAYSELECTION_ALREADY_EXIST: "Veckodagsmarkering finns redan",
    WEEKDAYSELECTION_MISSING: "Veckodagsmarkering saknas",
    ZONE_MISSING: "Zon saknas",
    ZONES_MISSING: "Zoner saknas",
    SOMETHING_GONE_WRONG: 'Något gick fel',
    TOKEN_EXPIRED: 'Denna länk har upphört att gälla',
    TOKEN_USED: 'Denna länk har redan används en gång',
    TRY_AGAIN: 'Försök igen.',
    YES_CANCEL: "Ja, avbryt",
    NO_CONTINUE: "Nej, fortsätt",
    WE_CAN_NOT_FIND_DATA_FOR_THIS_PERIOD: "Vi kunde inte hitta data för denna period",
    DATE_TO: 'Till',
    ARE_YOU_SURE_TO_CANCEL: "Är du säker på att avbryta?",
    FORBIDDEN_DURING_TRIP: "Denna ändring är inte tillåten under resan",
    ODOMETER_TOO_LARGE: "Mätarställning är för stor. Ange i km",
    REPORT_SCHEDULE_MISSING: "Rapport schema saknas",
    MISSING_SUMMARIES: "Rapport för denna period saknas",
    MISSING_JOURNALS: "Rapport för denna period saknas",
    GEO_ZONE_ALARM_MISSING: "GeoFence zonen saknas",
    GEO_ZONE_ALARM_FORBIDDEN: "GeoFence zonen förbjuden",
    ACTIVE_IN_OUT_SAME: "Aktiv vid inresa och utresa får inte vara samma",
    NUMBERS_ONLY: "Endast ciffror tillåtna",
    BAD_ZONE_ID: "Kan inte hitta tidszon",
    ALARM_EVENTS_MISSING: "Det finns inga larm för valda parametrar",
    EMPTY_FORBIDDEN: "Ej tillåtet utan något val",
    PASSAGE_MISSING: "Passagen saknas",
    DIFFERENT_TRIP_TYPES: "Resor har olika typer",
    DIFFERENT_DRIVERS: "Resor har olika förare",
    DIFFERENT_VEHICLES: "Resor har olika fordon",
    ADMIN_ALREADY_APPROVED: "Journalen är redan godkänd",
    TRIP_ATTESTED: "Resan är redan attesterad",
    BAD_ROLES: "Felaktig roll",
    BAD_REG: "Registreringsskylt uppfyller inte Trafikverkets regler",
    ISO_COUNTRY_CODE_MISSING: "Fordonet saknar accepterad landsflagga",
    // INCOMPATIBLE_PURPOSES: "Se meddelande ovan" /////////////////////////////////////////////////// !!!!!!!!!!!!!!!!!!!!!!!
    INCOMPATIBLE_PURPOSES: "Vi har upptäckt att du försökt slå ihop resor av olika typ. För att värna om din integritet har vi klassat dessa resor som privat. Du kan enkelt ändra detta i körjournalen nedan om du vill klassa vissa ihopslagna resor som Tjänst.",
    IMEI_STATUS_MISSING: "Vi har inte än fått någon data från enheten/fordonet",
    DAYS_MISSING_TRIPS_ZERO: "Ogiltigt värde, minsta tillåtna värde är 1.",
    HOURS_MISSING_CONTACT_ZERO: "Ogiltigt värde, minsta tillåtna värde är 1.",
    VEHICLE_TRAVELLED_FARTHER: "Fordonets sammanlagd körsträcka är längre än angiven mätarställning.",
    ICCID_MISSING: "ICCID hittas inte hos våra leverantörer",
    TRUE_PHONE_UNAUTHORIZED: "Truephone credentials gäller inte"
}

export function getErrorMessage(HTTP_MESSAGE_RESPONSE) {

    console.log(" ~ file: httpErrorMessages.js ~ line 51 ~ getErrorMessage ~ HTTP_MESSAGE_RESPONSE", HTTP_MESSAGE_RESPONSE)
    let message = httpErrorMessages[HTTP_MESSAGE_RESPONSE] ? httpErrorMessages[HTTP_MESSAGE_RESPONSE] : 'No default message has been set/found.'
    console.log(" ~ file: httpErrorMessages.js ~ line 56 ~ getErrorMessage ~ message", message)
    return message

}

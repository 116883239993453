import React, { createContext, useState } from "react";
import { dataApi } from "services/api/api";

const GeoContext = createContext({});
const GeoContextProvider = ({ children }) => {
    const [geozone, setGeozone] = useState([]);
    const getGeozone = async (vehicleId, organizationId) => {
        const res = await dataApi
            .getGeoAlarm()
            .then((res) => {
                const geozone = res.filter((r) => r.targetVehicleIds?.includes(vehicleId) || r.targetTrackerIds?.includes(vehicleId)
                || r.targetOrganizationIds?.includes(organizationId) || r.targetTrackerOrganizationIds?.includes(organizationId))
                setGeozone(geozone);
                return true;
            })
            .catch((err) => console.error(err));
        return res;
    };

    return <GeoContext.Provider value={{ geozone, getGeozone }}>{children}</GeoContext.Provider>;
};
export { GeoContext, GeoContextProvider };

import antdSvSE from 'antd/es/locale/sv_SE';
import svMsg from "../locales/sv_SE.json";

const SvLang = {
  antd: antdSvSE,
  locale: 'sv-SE',
  messages: {
    ...svMsg
  },
};
export default SvLang;

import React, {useState} from "react";
import Map from "components/trackson-components/Map";
import {MapContextProvider} from "services/contexts/MapContext";
import "./MapPageStyles.css";
import {OrganizationContextProvider} from "services/contexts/OrganizationsContext";
import ExportTrackerReportModal from "components/trackson-components/Map/ExportTrackerReportModal";
import {ServicesContextProvider} from "services/contexts/ServicesContext";
import GeozoneModal from "components/trackson-components/Map/Modal/GeozoneModal/GeozoneModal";
import {GeoContextProvider} from "services/contexts/GeoContext";
import {VehiclesContextProvider} from "services/contexts/VehiclesContext";
import {AuthorizationProvider} from "../services/contexts/contexts";
import {RestrictedMapOrganizationsProvider} from "../services/contexts/contexts";

const MapPage = () => {
    const initialSettings = {show: false, vehicles: null};
    const initialGeozone = {show: false};
    const [exportTrackReport, setExportTrackReport] = useState(initialSettings);
    const [geozone, setGeozone] = useState(initialGeozone);
    const openExportTrackerReportModal = (vehicles) => {
        setExportTrackReport({show: true, vehicles});
    };
    const onClose = () => {
        setExportTrackReport(initialSettings);
    };
    const openGeozoneModal = () => {
        setGeozone({show: true});
    };
    const onCloseGeozoneModal = () => {
        setGeozone(initialGeozone);
    };
    return (
        <OrganizationContextProvider>
            <AuthorizationProvider>
                <VehiclesContextProvider>
                    <MapContextProvider>
                        <ServicesContextProvider>
                            <GeoContextProvider>
                                <RestrictedMapOrganizationsProvider>
                                    <Map
                                        openExportTrackerReportModal={(vehicles) => openExportTrackerReportModal(vehicles)}
                                        openGeozone={openGeozoneModal}/>
                                    <ExportTrackerReportModal visible={exportTrackReport.show}
                                                              vehicles={exportTrackReport.vehicles} onCancel={onClose}
                                                              onOk={onClose}/>
                                    {geozone.show && <GeozoneModal visible={geozone.show} geozone={geozone.geozone}
                                                                   onClose={onCloseGeozoneModal}/>}
                                </RestrictedMapOrganizationsProvider>
                            </GeoContextProvider>
                        </ServicesContextProvider>
                    </MapContextProvider>
                </VehiclesContextProvider>
            </AuthorizationProvider>
        </OrganizationContextProvider>
    );
};
export default MapPage;

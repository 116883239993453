export const STATIC_TEXT = {
    ALIAS: "Alias",
    BATTERY: "Batteri",
    BTN_ABORT: "Avbryt",
    BTN_ADD_UNIT: "Lägg till enhet",
    BTN_ADD_UNIT_TRACKER: "Lägg till T",
    BTN_AVL: "AVL",
    BTN_CALIBRATE: "Kalibrera",
    BTN_CLOSE: "Stäng",
    BTN_CONNECT_TO_CUSTOMER: "Koppla",
    BTN_CONNECT: "Koppla",
    BTN_CPU_RESET: "CPU åters.",
    BTN_WEB_CONNECT: "Web-connect.",
    BTN_RESET: "Återställ",
    BTN_DISPLAY_UNIT: "Enhet",
    BTN_DISPLAY_VEHICLE: "Fordon",
    BTN_DOWNLOAD_SEL: "Ladda ner valda",
    BTN_FETCH_VEHICLE_INFO: "Hämta uppgifter från biluppgifter.se",
    BTN_GET_INFO: "Hämta\ninfo",
    BTN_LOGG: "Logg",
    BTN_NEW_ORG: "Ny organisation",
    BTN_RETIRE: "Pensionera",
    BTN_SHOW: "Visa",
    BTN_SAVE: "Spara",
    BTN_TO_STORAGE: "Till lager",
    BTN_CHANGE_ORG: "Ändra org.",
    BTN_CHANGE: "Ändra",
    BTN_UPDATE: "Uppdatera",
    CAR_TYPE: "Biltyp",
    CHOOSE_A_DRIVER: "Välj en förare",
    CHOOSE_DRIVERS: "Välj förare",
    CHOOSE_A_VEHICLE: "Välj ett fordon",
    CHOOSE_VEHICLES: "Välj fordon",
    CHOOSE_AN_ORG: "Välj en organisation",
    CHOOSE_QUICK_COMMAND: "Välj kommando",
    CHOOSE: "Välj",
    CONFIG_SHORT: "CFG",
    CONFIG: "Config",
    CONNECTED: "Kopplad",
    CURRENT_DRIVER: "Incheckad förare",
    DATA_BLOCK: "Datamängd",
    DESCRIPTION: "Syfte",
    DISPLAY_UNIT: "Visa enhet",
    DISPLAY_VEHICLE: "Visa fordon",
    DRIVER: "Förare",
    DELETE: "Ta bort",
    EXISTING: "Befintlig",
    FIRMWARE_SHORT: "FW",
    FIRMWARE: "Firmware",
    FIRMWARE_AND_CONFIG: "FW & Config",
    FROM: "Från",
    GET_INFO: "Hämta info",
    HARDWARE: "Hårdvara",
    HEART_BEAT: "Sågs senast",
    ICCID: "ICCID",
    IMEI: "IMEI",
    KNOWN: "Känd",
    LIST_DRIVERS: "Lista förare",
    LIST_VEHICLES: "Lista fordon",
    LOG: "Kvitterade",
    MILE_AGE: "Mätars.",
    MILEAGE_FULL: "Mätarställning",
    Model: "Modell",
    MOVE_TO_ANOTHER_VEHICLE: "Flytta enhet till annat fordon",
    MOVE_DEVICE: "Flytta enhet",
    MOVE_VEHICLE: "Flytta fordon",
    NEW_DRIVER: "Ny förare",
    NEW_ORG_SHORT: "Ny org.",
    NEW_VEHICLE: "Nytt fordon",
    NO: "Nej",
    NO_DRIVER: "Ingen förare",
    OBD: "OBD",
    OPERATIONAL_AT: "Kopplad",
    ORG: "Organisation",
    OWNER: "Ägare",
    PHONE_NUMBER: "Telnr",
    START_OF_USE: "Enhet tas i bruk",
    PLACE_HOLDER_TEXT: "PLACE_HOLDER_TEXT",
    PLACE_HOLDER_TITLE: "PLACE_HOLDER_TITLE",
    PRIMARY_DRIVER: "Förvald förare",
    PERMITTED_DRIVERS: "Behöriga förare",
    REG_NUM_SHORT: "Regnr",
    REG_NUM: "Regnr",
    SAVE: "Spara",
    SEND_INVITE: "Skicka inbjudan",
    START: "Start",
    STOP: "Stop",
    SUPPLY: "Ange ",
    SUPPLY_A_FIRST_LAST_NAME: "Ange förnamn & efternamn",
    SUPPLY_A_FIRST_NAME: "Ange ett förnamn",
    SUPPLY_A_LAST_NAME: "Ange ett efternamn",
    SUPPLY_A_NEW_BUSINESS_NAME: "Ange nytt företagsnamn",
    SUPPLY_A_NEW_PASSWORD: "Ange nytt lösenord",
    SUPPLY_A_OLD_PASSWORD: "Ange ditt lösenord",
    SUPPLY_A_NEW_ORG_COUNTRY: "Ange ett land",
    SUPPLY_A_NEW_ORG_COUNTY: "Ange ett län",
    SUPPLY_A_NEW_ORG_EMAIL: "Ange en e-post",
    SUPPLY_A_NEW_ORG_PHONE: "Ange ett mobilnummer",
    SUPPLY_A_NEW_ORG_POSTAL_CODE: "Ange ett postnummer.",
    SUPPLY_A_NEW_ORG_STREET_ADDRESS: "Ange en gatuadress",
    SUPPLY_A_NEW_ORG_PASSWORD: "Ange ett lösenord",
    SUPPLY_A_NEW_ORG: "Ange nytt organisationsnr.",
    SUPPLY_A_REG_NUM: "Ange ett regnr",
    SUPPLY_AN_ALIAS: "Ange ett alias",
    SUPPLY_A_BENEFIT_TYPE: "Ange förmånstyp",
    SUPPLY_A_DEFAULT_TRIP_TYPE: "Ange restyp",
    SELECT_AN_EXISTING_ORGANISATION: "Välj en befintlig organisation",
    SELECT_AN_EXISTING_DRIVER: "Välj en befintlig förare",
    SELECT_AN_EXISTING_VEHICLE: "Välj ett befintligt fordon",
    SUPPLY_AN_EMAIL_ADDRESS: "Ange en e-post",
    SUPPLY_PURPOSE: "Ange syfte...",
    SYNC_FROM_CAR_INFO: "Synka från biluppgifter",
    SYNC_NOW: "Synka nu",
    TO: "Till",
    TRAVELS: "Resor",
    TYPE_ERROR: "Typ/Fel",
    TYPE: "Typ",
    BENEFIT_TYPE: "Fordons-typ",
    UNKNOWN: "Okänd",
    VEHICLE: "Fordon",
    TRACKER: "Utrustning",
    VOLTAGE_RANGE: "Voltage range",
    WARN_WARN_WARN: "Varning varning varning",
    YEAR: "Årtal",
    YES: "Ja",
    POP_CONFIRM_QUESTION_RETIRE_UNIT: "Vill du verkligen pensionera enheten?",
    POP_CONFIRM_QUESTION_CPU_RESET: "Vill du verkligen starta om enheten?",
    POP_CONFIRM_QUESTION_WEB_CONNECCT: "Vill du verkligen skicka web-connect?",
    BTN_UN_RETIRE: "Avpensionera",
    INPUT_SEARCH_IN_TABLE: "Söker efter IMEI, telnr, regnr, förare",
    BTN_SEARCH: "Sök",
    ICON: "Ikon",
    DEFAULT: "Förvald typ av resa",
    EDIT_DEVICE: "Ändra",
    GO_TO_JOURNEY: "Gå till Körjournalen",
    DBCLICK_ON_MAP_TO_CHOOSE_LOCATION: "Dubbelklicka för att välja en plats på kartan",
    CURRENT_LOCATION_UPPDATED: "Senaste position uppdaterad",
    STYLE: "Märkning",
    BTN_SHOW_ON_THE_MAP: "Visa på karta",
    SHOW_WHERE_ALARM_TRIGGERED: "Visa var larmet utlöstes",
    LAST_SEEN: "Sågs senast",
    SPEED: "Hastighet",
    NO_INFO: "Info saknas",
    PARKED: "Ej i rörelse",
    IN_MOVING: "I rörelse",
    UPDATE_LAST_POSITION: "Uppdatera aktuell position",
    DO_NOT_FOLLOW: "Sluta spåra fordonet",
    CAN_NOT_FOLLOW: "Fordonet kan inte spåras",
    FOLLOW_ON_THE_MAP: "Följ fordonet",
    FILTER_BY_VEHICLE: "Filtrera efter fordon",
    YOUR_CHANGED_SAVED: "Dina ändringar har sparats",
    NEW_ORG_CREATED: "Ny organisation skapades",
    COMPANY_ADMIN: "Företags Admin",
    COMPANY_DELETED: "Organisation har tagits bort",
    ORG_NAME: "Företagsnamn",
    PARENT: "Förälder",
    MAIL: "E-post",
    ORG_NUM: "Organisationsnr",
    POSTAL_ADDRESS: "Postadress",
    DELETE_ORG: "Ta bort organisation?",
    SEARCH_ORG: "Sök efter organisation",
    ADD_NEW_ORG: "Lägg till ny organisation",
    ADD: "Lägg till",
    SUPPLY_ADMINS_EMAIL: "Företagsadmins e-postadress (Fyll i om du vill skapa en ny användare)",
    ADMINS_PASSWORD: "Företagsadmins lösenord",
    INVOICE_ADDRESS: "Fakturaadress",
    USERS: "Användare",
    DEVICES: "Enheter",
    ORGANIZATION: "Organisation",
    ORGANIZATIONS: "Organisationer",
    LAST_SEEN_DRIVING: "Sågs senast körning",
    CHECKED: "Incheckad",
    INSTALL: "Installed",
    RETIRED: "Pensionerad",
    STREET_ADDRESS: "Gatuadress",
    POSTAL_CODE: "Postnummer",
    CITY: "Postort",
    COUNTY: "Län",
    COUNTRY: "Land",
    SUPERADMIN: "Superadmin",
    ADD_NEW_ZONE: "Lägg till ny plats",
    NAME: "Namn",
    INTERVAL: "Intervall",
    CURRENT_SCHEDULES: "Aktuella scheman",
    SCHEDULING: "Schemaläggning",
    CHOOSE_ALL: "Välj alla",
    THIS: "Den",
    EVERY_MONTH: "varje månad",
    TIMEPOINT: "Tidpunkt",
    MONTHLY_REPORT: "Månadsrapport",
    REPORTS_TYPE: "Typ av rapport",
    CREATE_NEW_SCHEDULE: "Spara",
    SEND_REPORT: "Rapport skickas",
    MAIL_RECIPIENTS: "Mottagare",
    REPORT: "rapport",
    REPORT_SCHEDULING: "rapportschemaläggning",
    REMOVE_REPORT: "Ta bort scheman?",
    REPORT_DELETED: "Scheman har tagits bort",
    REPORT_CREATED: "Ett nytt schema skapades",
    EXPORT_AS: "Exportera filen som...",
    ALL_VEHICLES: "Alla fordon",
    ALL_ORG: "Alla organisationer",
    ALL_DRIVERS: "Alla förare",
    LIGHT_MAP: "Ljus karta",
    LIGHT: "Ljus",
    DARK: "Mörk",
    START_TYPING_TO_SEARCH: "Börja skriva för att söka",
    UPDATE: "Uppdatera senaste position",
    UPDATE_PURPOSE: "Syften uppdaterades",
    DO_YOU_WANT_TO_DOWNLOAD_REPORTS_FOR_ALL_VEHICLES: "Vill du ladda ner körjournalrapport för alla valda fordon?",
    DESCRIBE_CASE: "Beskriv ditt ärende...",
    SUPPLY_TITLE: "Ange titel",
    TITLE: "Titel",
    SENT_INVITE: "Inbjudan skickad",
    BTN_CHANGE_ORG_FOR_VEHICLE: "Flytta fordonet till en annan organisation",
    MOVE_TO_ORG: "Flytta till annan organisation",
    JOURNEY: "Körjournal",
    FOLLOW: "Spåra fordon",
    UPDATE_ODOMETER: "Uppdatera mätarställning",
    CHOOSE_ORGS: "Välj organisation",
    FILTER_BY_ORG: "Filtrera efter organisation",
    FILTER_BY_BENEFIT_TYPE: "Filtrera efter typ",
    SCHEDULE_PRESELECTION: "Förval för schema:",
    TOO_LARGE_DATA_LIMIT_PLEASE_SELECT_ORGANIZATION: "För stort urval. Välj en organisation för att kunna se körjournalen.",
    REPORT_IS_CREATED: "Rapporten skapas...",
    ZONENAME: "titel",
    ADD_NEW_PURPOSE: "Lägg till ett nytt resesyfte",
    SAVED_PURPOSE: "Dina sparade resesyften",
    DESCRIPTIONS: "Resesyften",
    SEARCH_DRIVER: "Filtrera efter förare",

    ALARM_PLACES: "Geozon - larm",
    ALARM: "larm",
    PLACE: "Plats",
    ACKNOWLEDGED: "Kvitterat",
    SCHEDULE: "Schema",
    NEW_PLACE: "Ny plats",
    RADIUS: "Radie",
    ZONE_NAME: "Plats namn",
    NEW_ZONE: "Ny plats",
    RADIUS_MS: "Från",
    ZONES_DELETED: "Platsen har tagits bort",
    ADDRESS: "Adress",
    COORDINATE: "Koordinat",
    DESCRIPTION_GOAL: "Syfte",
    TRIP_TYPE: "Typ av resa",
    ENTRY_EXIT_TRIP: "Inresa/Utresa",
    DO_YOU_WANT_TO_DELETE_THIS_ZONE: '"Ta bort platsen?"',
    DATE_TO: "Till",
    ENTRY: "Inresa",
    EXIT: "Utresa",
    INSIDE: "Inom",
    OUTSIDE: "Utanför",
    DISTORTION: "Känslighet",
    WHEN: "När",
    WHERE: "Var",
    WHO: "Vem",
    WHICH: "Vilka",
    GEO_LARM_MESSAGE_INFO: "Dubbelklicka på kartan eller ange adress för att välja en punkt. Sträck sedan ut området för att markera det.",
    CHOOSE_MIN_3: "Du behöver välja minst 3 punkter på kartan",
    REPEAT_AFTER: "Upprepa larmet",
    NOTIFICATION: "Notiser",
    SHOW_LARM: "Visa larmets detaljer",
    ACTUALLY: "Ej kvitterade",
    ONGOING: "Pågående...",

    TAX: "Trängselskatt",

    DEVICE: "Enhet",
    EXPORT_RAPPORT: "Exportera rapport",
    ALARM_TYPE: "Typ",
    ALARM_NAME: "Namn",
    HIGH: "Hög",
    LOW: "Låg",
    TRIGGERED_LARM: "Utlösta larm",
    STATUS: "Status",
    RESTORED: "Återställt",
    ACTIVATE_ALARM_WHEN: "Aktivera larmet om fordon befinner sig ... valt område",
    GET_NOTIFICATION: "Få notifikation om fordon befinner sig",
    AREA: "area",
    ENDED: "Avslutad",
    SEARCH_VEHICLE: "Sök efter fordon, förare...",
    GEO_ZONE: "Geozon",
    ACTIVE: "Aktivera",
    ARCHIVE: "Arkivera",
    ARCHIVED: "Arkiverad",
    SHOW_ARCHIVED: 'Visa arkiverade fordon och användare.',
    CANCEL: "Avbryt",
    messageArchivedAlert: "Arkiverade fordon kommer inte att inkluderas i appen. Du kan ändra ditt val från Mitt konto under Inställningar",
    UNINSTALL_DEVICE: "Avinstallera enheten",
    RETIRE_DEVICE: "Pensionera enheten",
    MOVE_VEHICLE_TO_OTHER_ORGANIZATION: "Flytta fordonet till en annan organisation",
    REMOVE_VEHICLES_INFO: "Radera biluppgiftershistorik",
    REMOVE_VEHICLE: "Ta bort fordon",
    ROOT: "ROOT",
    SHOW_GEO_ZONE: "Visa geozon",
    CHECK_IN_DRIVER: "Incheckad förare",
    SUPPLY_ADMINS_NAME: "Företagsadmins namn",
};
export const FORM_MESSAGES = {
    VEHICLE_REGNR: "Ange fordonets reg.nr",
    VEHICLE_ALIAS: "Ange ett fordons-alias",
    PERSON_FIRST_NAME: "Ange personens förnamn",
    PERSON_LAST_NAME: "Ange personens efternamn",
    PERSON_EMAIL: "Ange personens e-post",
    PERSON_WRONG_EMAIL: "Ange en giltig e-postadress",
    PURPOSE: "Ange nytt syfte",
    PERSON_WRONG_PHONE: "Ange en giltig mobilnummer"

};
export const TAB_PANE_TILES = {
    WARNINGS: "Varningar",
    STOCK: "I Lager",
    ON_THE_WAY: "På väg",
    IN_DRIVE: "I drift",
    RETIRED: "Pensionerad",
};
export const SUPER_DEVICES_MODAL_TITLES = {
    ADD_AN_UNIT: "Lägg till enhet",
    CONNECT_TO_ORG: "Koppla till organisation",
    AVL: "AVL",
    DISPLAY_UNIT: "Enhet",
    LOGG: "Logg",
    C12SMS: "C12 / SMS-Kommunikation",
    VEHICLE: "Fordon",
    CHANGE_ORG: "Ändra organisation för enhet",
};
export const SD_MODAL_AVL_LOG_TITLES = {
    DATE: "Datum",
    DATE_FROM: "Från",
    DATE_TO: "Till",
    FIELD_ID: "ID",
};
export const SD_MODAL_SMS_LOG_TITLES = {
    DATE_TIME: "Datum - Tid",
    NEW_VALUE: "Nytt värde",
};
export const benefitType = {
    POOL: "Poolbil",
    PRIVATE: "Privat",
    BENEFIT: "Förmånsbil",
    WORK: "Arbetsfordon",
    TRACKER: "Tracker",
};
export const tripTypes = {
    PRIVATE: "Privat",
    BUSINESS: "Tjänst",
};
export const ZONES_STATIC_TEXT = {
    NEW_PLACE: "Ny plats",
    NAME: "Namn",
    RADIUS: "Radie",
    ZONE_NAME: "Plats namn",
    NEW_ZONE: "Ny plats",
    RADIUS_MS: "Från",
    ZONES_DELETED: "Platsen har tagits bort",
    ADDRESS: "Adress",
    COORDINATE: "Koordinat",
    DESCRIPTION_GOAL: "Syfte",
    TRIP_TYPE: "Typ av resa",
    ENTRY_EXIT_TRIP: "Inresa/Utresa",
    DO_YOU_WANT_TO_DELETE_THIS_ZONE: "Ta bort platsen?",
    DATE_TO: "Till",
};
export const ECOSCORE_STATIC_TEXT = {
    CHOOSE_VEHICLE: "Välj fordon",
    AVERAGE_AV_ECOSCORE_FOR_PERIOD: "Genomsnittlig EcoScore för perioden",
    TOTAL_AVERAGE: "Total: Genomsnitt",
    CURVE_TAKING: "Kurvtagning",
    BAD_CURVE_TAKING_REDUCES_YOUR_ECOSCORE: "Skarp kurvtagning minskar din EcoScore",
    LIMIT_CURVE_TAKING: " Gränsvärde 2,4 rad/s",
    RAPID_ACCELERATION_REDUCES_YOUR_ECOSCORE: "Hastig acceleration minskar din EcoScore",
    LIMIT_ACCELERATION: "Gränsvärde 2,5 m/s2",
    LIMIT_BRAKING: "Gränsvärde 2,7 m/s2",
    STARK_BRAKING_REDUCES_YOUR_ECOSCORE: "Kraftig inbromsning minskar din EcoScore",
    BRAKING: "Inbromsning",
    ACCELERATION: "Acceleration",
};
export const MESSAGE_NOTIFICATION = {
    SOMETHING_GONE_WRONG_TRY_AGAIN: "Något gick fel. Försök igen.",
    YES_CANCEL: "Ja, avbryt",
    NO_CONTINUE: "Nej, fortsätt",
    WE_CAN_NOT_FIND_DATA_FOR_THIS_PERIOD: "Vi kunde inte hitta data för vald period.",
    DATE_TO: "Till",
    ARE_YOU_SURE_TO_CANCEL: "Är du säker på att du vill avbryta?",
    YOU_HAVE_ACTIVATE_THE: "Du har aktiverat ",
    YOU_HAVE_ARCHIVED_THE: "Du har arkiverat "

};
export const applicationsNames = {
    ENTRY: "Inresa",
    EXIT: "Utresa",
    BOTH: "Inresa & utresa",
};
export const userRoles = {
    ROOT: "Superadmin",
    DRIVER: "Förare",
    ADMIN: "Företagsadmin",
};
export const reportsTypesLookUp = {
    JOURNAL: "Körjournalrapport",
    // "SUMMARY": 'Sammanställd körjournal',
    SUMMARY_VEHICLE: "Sammanställd fordonsrapport",
    SUMMARY_DRIVER: "Sammanställd förarrapport",
    SALARY: "Sammanställd lönerapport",
    DISCREPANCY: "Avvikelserapport",
    PURPOSE: "Syftesrapport",
};

export const settingsTranslate = {
    "FOTA_JASPER_24024_ORGS": "Fix-24024:",
    "NO_MAP_VIEW": "Stäng av kartan:",
    "USER_ALLOWED_MAP": "Karta alltid tillåten"
};
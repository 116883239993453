import {SuperVehicleController} from '../../components/trackson-components/superVehicles.js'
import {Nest} from "../../utils/utilities";
import {
    AuthorizationProvider, DeviceListProvider, shouldHideUsersOrganizationsColumn,
    OrganizationsComplexListProvider, UserListProvider,
    VehiclesComplexListProvider, VehiclesListWithArchivedProvider
} from "../../services/contexts/contexts";

const SuperVehicleView = () => {

    return <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, UserListProvider, VehiclesListWithArchivedProvider, DeviceListProvider, OrganizationsComplexListProvider]}>
        < SuperVehicleController/>
    </Nest>

}

export default SuperVehicleView

import React, {createContext, useCallback, useState} from "react";
import fetch from "../auth/FetchInterceptor"


// TODO maybe stop importing fetch here, instead make this the source of fetch

const initState = {
    auth: null,
    requestGetAuth: null
}

export const SessionContext = createContext(initState)
export const SessionContextProvider = ({children}) => {
    // TODO make some useCachedEndpoint hook to simplify these
    // there we could put loaded state, invalidation etc
    const [auth, setAuth] = useState()
    const [fetchingAuth, setFetchingAuth] = useState(false)
    const requestGetAuth = () => {
        if(fetchingAuth) return
        setFetchingAuth(true)
        fetch({url: "/auth"}).then((auth) => {
            console.log("SessionContext loaded auth:", auth)
            setAuth(auth)
            setFetchingAuth(false)
        }).catch((e) => {
            console.error(e)
            setFetchingAuth(false)
        })
    }
    return <SessionContext.Provider value={{
        auth,
        requestGetAuth,
    }}>{children}</SessionContext.Provider>
}

import React from 'react';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import reduxStore from "./services/redux/reduxStore";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import moment from "moment-timezone";
import Views from "./views";
import {VersionProvider} from "./configs/CacheBuster";

// Default timezone
moment.tz.setDefault("Europe/Stockholm");


function App() {

    // TODO is this the only place redux is injected?
    return (
        <VersionProvider>
            <div className="App">
                <Provider store={reduxStore.store}>
                    <PersistGate loading={null} persistor={reduxStore.persistor}>
                        <Router>
                            <Switch>
                                <Route path="/" component={Views} key={(i) => i}/>
                            </Switch>
                        </Router>
                    </PersistGate>
                </Provider>
            </div>
        </VersionProvider>

    );
}

export default App;

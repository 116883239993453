import React, {useContext, useEffect, useState} from "react"
import {Button, ConfigProvider, Form, Input, Menu, message, Modal, Table, Tabs, Tag, Tooltip, Typography} from 'antd';
import Loading from 'components/shared-components/Loading/Loading'
import {
    EditOutlined,
    ExclamationCircleOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    MailOutlined,
    DashboardOutlined,
    UpSquareOutlined,
    UserAddOutlined
} from '@ant-design/icons';
import {dataApi} from "../../services/api/api";
import {ChangeUsersOrganization, EditUserForm} from "./users";
import {OrganizationsTreeSelect} from "./shared-components/OrganizationsTreeSelect";
import {LastSeenLink} from "./shared-components/DatumLink";
import {TableActionBtnType} from "./Superdevices/Superdevices";
import {STATIC_TEXT, userRoles} from "../../lang/translate/sv-SE/sv-SE";
import {CONSTANTS_TEXT, SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../constants/CustomConstant";
import {TabBadge} from "./Superdevices/reusableComponents";
import {customizeRenderEmpty} from "./shared-components/customizeRenderEmpty";
import {
    ButtonMenuDropdown,
    tableColumnsWithTooltip,
    tableProps, vehicleTag
} from "./shared-components/sharedComponents";
import {AlertArchived, TabsArchived} from "./shared-components/TabsArchived";
import {
    OrganizationSimpleContext,
    shouldHideUsersOrganizationsColumn,
    UserListContext, UserListWithArchivedContext,
    VehiclesComplexListContext
} from "../../services/contexts/contexts";
import {activateUser, archiveUser} from "./shared-components/archiveUserModals";
import {ModalWithFullList} from "./shared-components/modalShowFullList";
import {activateVehiclesModals} from "./shared-components/archiveVehiclesModals";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {useHistory} from "react-router-dom";

const {TabPane} = Tabs;
const {Text} = Typography;

export const UsersController = () => {
    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)

    const [organizationId, setChangeOrganisation] = useState()
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const organizationSelectOnChange = value => {
        setChangeOrganisation(value)
    };

    const handleChangeSearchInput = event => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleAddUserButton = () => {
        setIsModalVisible(true)
    };

    return <>
        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20, flexWrap: "wrap"}}>
            <Typography.Title>Användare</Typography.Title>
            <div style={{display: 'flex', gap: 15, flexWrap: "wrap"}}>

                {knownOrganizations?.length < 2 ? null :
                    <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                             selectedOrganization={organizationId} isMultiple={true}/>
                }
                <Input.Search
                    type="text"
                    placeholder="Sök efter namn eller e-post"
                    label="Registreringsnummer"
                    defaultValue={searchTerm}
                    enterButton
                    onChange={handleChangeSearchInput}
                    style={{width: 300}}
                />
                <Button
                    type="primary"
                    onClick={handleAddUserButton}
                    icon={<UserAddOutlined/>}>
                    Lägg till
                </Button>

            </div>
        </div>
        <EditableTable
            knownOrganizations={knownOrganizations}
            organizationId={organizationId}
            searchTerm={searchTerm}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
        />
    </>
}

const EditableTable = (props) => {
    const vehicles = useContext(VehiclesComplexListContext)
    const knownVehicles = vehicles?.filter(v => !v.isArchived)
    const users = useContext(UserListWithArchivedContext)
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);

    const [form] = Form.useForm();
    const [allData, setAllData] = useState([]);
    const [filteredUsersData, setFilteredUsersData] = useState([]);
    const [archivedData, setArchivedData] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const [showAlertArchived, setShowAlertArchived] = useState(false);
    const [editingUser, setEditingUser] = useState();
    const [loading, setLoading] = useState(true)
    const [visibleModal, setVisibleModal] = useState(false)
    const {push} = useHistory();

    useEffect(() => {
        if (filteredUsersData && knownVehicles) setLoading(false)
    }, [filteredUsersData, knownVehicles])

    useEffect(() => {
        const sortData = filteredUsersData?.sort((a, b) => a.displayName.localeCompare(b.displayName));
        setActiveData(sortData?.filter((item) => !item.isArchived))
        setArchivedData(sortData?.filter((item) => item.isArchived))
    }, [filteredUsersData])

    useEffect(() => {
            users?.map(item => {
                item.extraRoles = item?.roles?.map(role => userRoles[role])
            })
            setAllData(users)
    }, [users])


    useEffect(() => {
        let filteredList = allData?.filter(
            item => JSON.stringify(item).toLowerCase().includes(props.searchTerm)
        )

        if (props?.organizationId?.length > 0) {
            filteredList = filteredList?.filter(
                i => props.organizationId.includes(i.organizationId)
            )
        }

        setFilteredUsersData(filteredList)
    }, [props.organizationId, allData, props.searchTerm])


    function handleAdd(row, id) {
        row.userId = id
        const newData = [...allData];
        const index = newData.findIndex(item => id === item.userId);
        const item = newData.findIndex(item => id === item.userId);

        if (index > -1) {
            newData.splice(index, 1, {...item, ...row});
        } else {
            newData.push(row);
        }

        setAllData(newData)
        props.setIsModalVisible(false)
        setEditingUser(null)
    }

    const handleMoveUserToOrganization = (record, organizationId) => {
        const req = {organizationId: organizationId}

        dataApi.patchUser(record.userId, req)
            .then(res => {
                message.success("Användare flyttades till en annan organisation.")
                const newData = [...allData];
                const index = newData.findIndex(item => record.userId === item.userId);
                const item = newData[index];
                newData.splice(index, 1, {...item, ...res});
                setAllData(newData)
            })
    }

    const sendInvitation = (record) => {
        const newData = [...allData];
        const index = newData.findIndex(item => record.userId === item.userId);
        const item = newData[index];

        const data = {
            email: record.displayEmail,
            fullName: record.displayName
        }

        const row = {
            inviteSentTsMs: Date.now()
        }

        dataApi.postInviteUser(data)
            .then(_ => {
                message.success("Inbjudan har skickats")
                if(index > -1) {
                    newData.splice(index, 1, {...item, ...row});
                    setAllData(newData)
                }

            }).catch(e => {
            console.error(e)
            message.error('Något har gott fel. Försok igen')
        })

    }

    function editUser(record) {
        setEditingUser(record);
        props.setIsModalVisible(true)
    }

    function redirectToView(record, view, showArchived) {
        const value = record?.id;
        let qs;
        qs = showArchived ? `&&showArchived=${showArchived}` : ""
        push(`${APP_PREFIX_PATH}/${view}?userId=${value}` + qs);
    }

    const columnsArchived = [
        {
            title: 'Namn',
            dataIndex: 'displayName',
            key: 'displayName',
        },
        {
            title: 'E-post',
            dataIndex: 'displayEmail',
            key: 'displayEmail',
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: "Arkiverad",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.archivedTsMs,
            width: 100,
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker} defaultText={true}/> : "-"),
        },
        {
            title: "",
            dataIndex: "operation",
            fixed: "right",
            render: (text, record) => (
                <div style={{float: "right"}}>
                    <Button
                            icon={<DashboardOutlined/>}
                            onClick={() => (record.isTracker ? null : redirectToView(record, "journey", true))}>

                        {STATIC_TEXT.JOURNEY}
                    </Button>
                    <Button
                            icon={<UpSquareOutlined/>}
                            onClick={() => activateUser(record, allData, setAllData, editUser)}>
                        {STATIC_TEXT.ACTIVE}
                    </Button>
                </div>
            )
        },
    ]

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => editUser(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item icon={<DashboardOutlined/>}
                       onClick={() => (record.isTracker ? null : redirectToView(record, "journey", false))}
            >{STATIC_TEXT.JOURNEY} </Menu.Item>
            <Menu.Item icon={<HomeOutlined/>}
                       onClick={() => ChangeUsersOrganization(record, props.knownOrganizations, handleMoveUserToOrganization)}>
                {STATIC_TEXT.MOVE_TO_ORG} </Menu.Item>

            <Menu.Item icon={<FolderOpenOutlined/>}
                       onClick={() => archiveUser(record, allData, setAllData)}>
                {STATIC_TEXT.ARCHIVE}</Menu.Item>
        </Menu>
    }

    const columns = [
        {
            key: 'sendMail',
            width: 50,
            fixed: 'left',
            render: (value, record) => {
                return (
                    <Tooltip title="Skicka inbjudan till den användaren">
                        <a onClick={() => sendInvitation(record)}>
                            <MailOutlined style={{fontSize: '18px'}}/>
                        </a>
                    </Tooltip>
                )
            }
        },
        {
            title: 'Namn',
            dataIndex: 'displayName',
            key: 'displayName',
            fixed: 'left',
        },
        {
            title: 'E-post',
            dataIndex: 'displayEmail',
            key: 'displayEmail',
        },
        {
            title: 'Mobilnummer',
            dataIndex: 'phone',
            key: 'phone',
            width: 150,
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            editable: false,
            width: 150,
            hidden: hideUsersOrg,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: 'Roll',
            dataIndex: 'roles',
            key: 'roles',
            width: 150,
            render: (text) => (
                text?.map(e => {
                    return userRoles[e] + " "
                })
            ),
        },
        {
            title: 'Senast aktiv',
            dataIndex: 'lastSeenApp',
            key: 'lastSeenApp',
            width: 120,
            render: (text) => (
                text ? <LastSeenLink value={text} typ={"map"} defaultText={true}/> : "-"
            )
        },
        {
            title: STATIC_TEXT.SENT_INVITE,
            dataIndex: 'inviteSentTsMs',
            width: 150,
            key: 'inviteSentTsMs',
            render: (text) => (
                text ? <LastSeenLink defaultText={true} value={text} typ={"map"}/> : "-"
            )
        },
        {
            title: "Behörig till",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.permittedVehicles,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.permittedVehicles,
            editable: false,
            render: (permittedVehicles, record) => {
                return columnPermittedDrivers(record, permittedVehicles, knownVehicles, null, props.knownOrganizations, false, visibleModal, setVisibleModal)
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    const onModalCancel = () => {
        setEditingUser(null)
        props.setIsModalVisible(false)
    };

    return (
        loading ? <Loading/> :
            <Form form={form} component={false}>
                <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px'}}>
                </div>
                <EditUserForm
                    onModalCancel={onModalCancel}
                    isModalVisible={props.isModalVisible}
                    knownOrganizations={props.knownOrganizations}
                    organizationId={props.organizationId}
                    vehicles={knownVehicles}
                    setData={handleAdd}
                    sendInvitation={sendInvitation}
                    record={editingUser}/>

                <TabsArchived
                    setShowAlertArchived={setShowAlertArchived}
                    showAlertArchived={showAlertArchived}
                    content={
                        <>
                            <TabPane
                                key={'1'}
                                tab={<TabBadge
                                    text={'Aktiva användare'}
                                    overflowCount={9999999}
                                    badgeColor={'#3f3f3f'}
                                    count={activeData?.length}/>}
                            >
                                {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                <Table
                                    dataSource={activeData}
                                    columns={tableColumnsWithTooltip(columns)}
                                    scroll={{x: 1500}}
                                    rowClassName="editable-row"
                                    {...tableProps}
                                    tableLayout={"fixed"}
                                />

                            </TabPane>
                            <TabPane
                                key={'2'}
                                tab={<TabBadge
                                    text={'Arkiverade användare'}
                                    overflowCount={9999999}
                                    badgeColor={'#3f3f3f'}
                                    count={archivedData?.length}/>}
                            >
                                {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                <ConfigProvider
                                    renderEmpty={() => customizeRenderEmpty("Du har inga arkiverade användare")}>
                                    <Table
                                        dataSource={archivedData}
                                        columns={tableColumnsWithTooltip(columnsArchived)}
                                        scroll={{x: 1000}}
                                        rowClassName="editable-row"
                                        {...tableProps}
                                    />
                                </ConfigProvider>
                            </TabPane>
                        </>
                    }
                />
            </Form>
    );
};

export const columnPermittedDrivers = (record, permittedVehicles, knownVehicles, name, knownOrganizations) => {
    permittedVehicles = permittedVehicles.filter(item => knownVehicles?.find(vehicle => vehicle.id === item.id))

    if (permittedVehicles?.length === 1) {
        const vehicle = knownVehicles?.find((v) => v.id === permittedVehicles[0].id);
        return (vehicleTag(vehicle));
    } else if (permittedVehicles?.length === 0) {
        return "-";
    } else {
        const recordVehicles = permittedVehicles?.map(item => item.id)
        let title = permittedVehicles?.length + " " + STATIC_TEXT.VEHICLE;
        return ModalWithFullList(title, recordVehicles, null, null, knownVehicles, null, knownOrganizations)
    }
}

import React, {useEffect, useState} from "react";
import {OrganizationsTreeSelect} from "./OrganizationsTreeSelect";
import ColorPicker from "../../shared-components/ColorPicker";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Row,
    Select,
    Slider,
    Switch,
    Typography,
} from "antd";
import {Icon} from "react-icons-kit";

import moment from "moment";
import {dataApi} from "../../../services/api/api";
import {httpErrorMessages} from "../../../constants/httpErrorMessages";
import {handleUpdateOdometer} from "./odometer";
import {generateRandomColor} from "./sharedComponents";
import {CONSTANTS_TEXT} from "../../../constants/CustomConstant";
import {lookupStyleMarker} from "./lookupStyleMarker";

const {Text} = Typography;
const {Option, OptGroup} = Select;

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

const selectIcon = [];

Object.entries(lookupStyleMarker).map(([i, k]) => {
    selectIcon.push(
        <Option value={i}>
            <Icon icon={k}/>
        </Option>
    );
});

export const NewTrackerForm = (props) => {
    const [useOrganization, setUseOrganization] = useState(props.record?.organizationId);
    const [errorMessageVehicle, setErrorMessageVehicle] = useState(null);
    const [selectedPermittedDrivers, setSelectedPermittedDrivers] = useState();
    const [previousDevice, setPreviousDevice] = useState();
    const [ifBenefitTypeIsPool, setIfBenefitTypeIsPool] = useState(false);
    const [handleAddForm] = Form.useForm();
    const [color, setColor] = useState();

    useEffect(() => {
        clearValues()
    }, [props.isModalVisible])

    useEffect(() => {
        clearValues()
    }, [useOrganization])

    useEffect(() => {
        if(!props.record?.state?.imei && props.record && !props.moveDevice){
            const vehicleId = props.record?.id;
            dataApi.getVehicleDevice(vehicleId)
                .then(res => {
                    const deviceId = Array.from(new Set(res?.map(item => item.deviceId)));
                    deviceId.reverse();
                    setPreviousDevice(deviceId);
                })
        }
    }, [props.record, props.moveDevice])

    useEffect(() => {
        if (!props.isModalVisible) return
        if (props?.knownOrganizations?.length < 2 && !props.record) {
            setUseOrganization(props?.knownOrganizations[0]?.organizationId)
        } else if (props.moveDevice) {
            setUseOrganization(props.moveDevice?.organizationId)
        }
        else {
            setUseOrganization(props.record?.organizationId)
        }

    }, [props.knownOrganizations, props.record, props.isModalVisible, props.moveDevice])

    const onFinish = (e) => {
    if (!e.state.imei && !props.record?.state?.imei) {
            showConfirmEmptyDevice(e);
        } else {
            handleAdd(e);
        }
    };

    const onModalCancelNewForm = () => {
        setUseOrganization(null)
        props.onModalCancel()
    };

    const clearValues = () => {
        handleAddForm.resetFields()
        handleAddForm.setFieldsValue({["organizationId"]: useOrganization})
        setColor(props.record?.style?.color || generateRandomColor())
        setErrorMessageVehicle(null)
    }

    const showConfirmEmptyDevice = (values) => {
        Modal.confirm({
            title: "Ingen enhet kopplad till tracker",
            content: "Du har inte valt någon enhet. Är detta korrekt? Tracker kommer inte visas på karta.",
            okText: "Ja, ingen enhet",
            onOk() {
                handleAdd(values);
            },
            onCancel() {
            },
        });
    };

    const onColorChange = (value) => {
        setColor(value.hex);
    };

    const handleAdd = (values) => {
        let permDrivers = values.permittedDrivers ? values.permittedDrivers.map((d) => {
                const index = props.permittedDrivers.findIndex((u) => u.userId === d);
                return {id: props.permittedDrivers[index]?.userId, displayName: props.permittedDrivers[index]?.displayName};
            })
            : [];

        values = {
            ...values,
            permittedDrivers: permDrivers,
            style: {...values.style, color: color},
            defaultTripType: "BUSINESS",
            benefitType: "TRACKER"
        }

        if (props.record) {
            handlePatchVehicle(values)
        } else {
            handlePostVehicle(values)
        }
    };

    async function handlePostVehicle(values) {
        try {
            const res = await dataApi.postSuperVehicle(values, useOrganization);
            const vehicleId = res.id;
            let vehicle = res;

            if (props.moveDevice) {
                await props.handleMoveDeviceToOtherVehicle(props.moveDevice, vehicleId);
                vehicle.state = props.moveDevice?.state;
            } else if (values.state?.imei && !props.record?.state?.imei ) {
                const devicesId = values.state.imei;
                const device = await dataApi.patchDevices(devicesId, vehicleId);
                vehicle.state = device;
                const findDevice = props.knownDevices?.find(item => item?.id === devicesId);
                if (findDevice) {
                    findDevice.installedVehicle = vehicleId;
                }
            }

            if (props.moveDevice) {
                props.setData([vehicle, { ...props.moveDevice, state: null }]);
            } else {
                props.setData(vehicle);
            }

        } catch (e) {
            let translatedMessage = httpErrorMessages[e?.response?.data];
            setErrorMessageVehicle(translatedMessage);
        }
    }

    function handlePatchVehicle(values) {
        const vehicleId = props.record?.id;
        let vehicle;

        return dataApi.patchVehicle(vehicleId, values)
            .then((res) => {
                vehicle = res;
            })
            .then(() => {
                if (props.moveDevice) {
                    return props.handleMoveDeviceToOtherVehicle(props.moveDevice, vehicleId, values);
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                if (values.state?.imei && !props.record?.state?.imei) {
                    const devicesId = values.state.imei;
                    return dataApi.patchDevices(devicesId, vehicleId)
                        .then((device) => {
                            vehicle.state = device;
                            const findDevice = props.knownDevices?.find(item => item?.id === devicesId);
                            if (findDevice) {
                                findDevice.installedVehicle = vehicleId;
                            }
                        });
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                props.setData(vehicle);
            })
            .catch((e) => {
                let translatedMessage = httpErrorMessages[e?.response?.data];
                setErrorMessageVehicle(translatedMessage);
            });
    }
    const defaultPermittedUsers = props.permittedDrivers?.filter(user =>
        props.record?.permittedDrivers?.some(permission => permission?.id === user?.id)
    )?.map(item => item?.id);

    const permittedDriversForOrg = props.permittedDrivers?.filter((i) => (i.organizationId === useOrganization));
    let devicesOptions = props.imeiList?.filter(item => item.organizationId === useOrganization && !item.installedVehicle && item.retired !== true);
    devicesOptions = devicesOptions?.filter(item => item.isTracker)
    return (
        <Modal centered title={"Tracker"} onCancel={onModalCancelNewForm}
               onOk={() => handleAddForm.submit()}
               okText={props.record ? "Spara" : "Lägg till nytt tracker"}
               visible={props.isModalVisible}
               bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 200px)'}}
        >
                    <Form form={handleAddForm} name="basic" layout="vertical"
                          style={{margin: "auto", justifyContent: "center"}} onFinish={onFinish}
                          initialValues={{
                              ...props.record,
                              permittedDrivers: defaultPermittedUsers,
                              style: props.record ? {...props.record.style} : {marker: "wrench", color: color},
                              state: {imei: props.moveDevice?.state?.imei || props.record?.state?.imei}

                          }}
                    >

                        {errorMessageVehicle &&
                            <>
                                <Alert type={"error"} message={errorMessageVehicle} showIcon/>
                                <br/>
                            </>
                        }

                        <Form.Item
                            label="Organisation"
                            name="organizationId"
                            rules={[{required: true, message: "Välj organisation!"}]}
                        >
                            <OrganizationsTreeSelect disabled={props.record || props.moveDevice} placeholder={"Välj organisation"}
                                                     data={props.knownOrganizations}
                                                     selectedOrganization={useOrganization}
                                                     onChange={(v) => setUseOrganization(v)}/>
                        </Form.Item>

                        <Form.Item
                            label="Enhet"
                            name={["state", "imei"]}
                            rules={[{ required: false, message: "Välj organisation!" }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                disabled={props.record?.state?.imei || props.moveDevice || !props.isROOT}
                                placeholder={"Välj enhet"}
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {devicesOptions?.filter((item) => previousDevice?.includes(item.id)).length > 0 && (
                                    <OptGroup label="Tidigare installerade enheter">
                                        {previousDevice?.map((deviceId, index) => {
                                            const item = devicesOptions.find((item) => item.id === deviceId);
                                            if (item) {
                                                return (
                                                    <Select.Option key={item.id} value={item.id}>
                                                        {index === previousDevice.length - 1 ? `${item.imei} (senaste)` : item.imei}
                                                    </Select.Option>
                                                );
                                            }
                                            return null;
                                        })?.reverse()}
                                    </OptGroup>
                                )}

                                {devicesOptions?.filter((item) => !previousDevice?.includes(item.id)).length > 0 && (
                                    <OptGroup label="Alla lediga enheter">
                                        {devicesOptions
                                            ?.filter((item) => !previousDevice?.includes(item.id))
                                            .map((item) => (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {item.imei}
                                                </Select.Option>
                                            ))}
                                    </OptGroup>
                                )}
                            </Select>
                        </Form.Item>


                        <Form.Item name={"displayName"} label="Alias"
                                   rules={[{required: true, message: "Ange alias"}]}>
                            <Input  placeholder="Ange alias"/>
                        </Form.Item>

                        <Form.Item label="Behöriga användare" name="permittedDrivers"
                                   rules={[{required: false, message: "Välj användare!"}]}>
                            {ifBenefitTypeIsPool ?
                                <p style={{marginTop: "0.5em", fontStyle: "italic"}}>Poolbilar är tillåtna för alla
                                    förare.</p>
                                :
                                <Select
                                    optionFilterProp="children"

                                    allowClear
                                    showSearch
                                    autoComplete={false}
                                    filterOption={(input, option) => JSON.stringify(option?.object)?.toLowerCase().includes(input.toLowerCase())}
                                    onSearch={() => null}

                                    onChange={(value, key) => setSelectedPermittedDrivers(key)}
                                    mode="multiple"
                                    style={{width: "100%"}}
                                    placeholder="Välj användare"
                                >

                                    <Option disabled={true} value={"xxx"}>
                                        {selectedPermittedDrivers?.length > 0 ? (
                                            <span
                                                onClick={() => {
                                                    handleAddForm.setFieldsValue({["permittedDrivers"]: []})
                                                    setSelectedPermittedDrivers([])
                                                }}
                                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Avmarkera alla
                            </span>
                                        ) : (
                                            <span
                                                onClick={() => {
                                                    const m = permittedDriversForOrg?.map(({userId}) => userId)
                                                    handleAddForm.setFieldsValue({["permittedDrivers"]: m})
                                                    setSelectedPermittedDrivers(permittedDriversForOrg)
                                                }}
                                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Välj alla
                            </span>
                                        )}
                                    </Option>

                                    {permittedDriversForOrg?.map((v, i) => {
                                        return (
                                            <Option value={v.userId} object={v}>{v.displayName}</Option>
                                        );
                                    })}
                                </Select>}
                        </Form.Item>

                        <Form.Item label="Ikon">
                            <Input.Group compact>
                                <Form.Item name={["style", "marker"]} noStyle
                                           rules={[{required: false, message: "Registration number is required"}]}>
                                    <Select placeholder="Välj ikon">
                                        {selectIcon}
                                    </Select>
                                </Form.Item>
                                <Form.Item name={["style", "color"]} style={{margin: "auto"}}
                                           rules={[{required: false, message: "Street is required"}]}>
                                    <ColorPicker color={props.record?.style?.color || color}
                                                 colorChange={onColorChange}/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Form>

        </Modal>
    );
};
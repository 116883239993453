import React, {createContext, useEffect, useState} from "react";
import {dataApi} from "services/api/api";

const OrganizationContext = createContext({});
const OrganizationContextProvider = ({ children }) => {
    const [organizations, setOrganizations] = useState([]);

    const fetchOrganizations = async () => {
        dataApi
            .getOrganizationsSimple()
            .then((res) => {
                setOrganizations(res);
            })
            .catch((err) => {
                console.error(err);
            });
    };
    const getOrganizationById = async (id) => {
        return await dataApi
            .getOrganization(id)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    };
    const patchOrganizationAccount = async (orgId, v) => {
        return await dataApi
            .patchOrganizationAccount(orgId, v)
            .then((_) => {
                return true;
            })
            .catch((err) => console.error(err));
    };

    const getAllOrganizationsForRoot = async () => {
        return await dataApi
            .getAllOrganizationsForSuperadmin()
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    useEffect(() => {
        fetchOrganizations();
    }, []);

    return <OrganizationContext.Provider value={{ organizations, getOrganizationById, patchOrganizationAccount, getAllOrganizationsForRoot: getAllOrganizationsForRoot }}>{children}</OrganizationContext.Provider>;
};

export { OrganizationContext, OrganizationContextProvider };

import React, {useState, useEffect, useRef} from 'react'
import { createSelector } from "reselect"
import fetch from 'auth/FetchInterceptor'
import {
    Button,
    Checkbox,
    DatePicker,
    Form,
    Input,
    Avatar,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Radio,
    Select,
    Table,
    Tag,
    Switch,
    TreeSelect,
    Typography
} from 'antd';
import * as icons from '@ant-design/icons'

import {SUPER_DEVICES_MODAL_TITLES} from "../../../lang/translate/sv-SE/sv-SE";

const MODAL_TITLE = SUPER_DEVICES_MODAL_TITLES.ADD_AN_UNIT
const MODAL_PRINT_NAME = "ModalFormDeviceIMEI"

const MODAL_STATIC_TEXT = {
    IMEI: 'TEMPLATE_IMEI',
    ORG: 'TEMPLATE_ORG',
}

// DONT USE - NOT USED -> AddUnit.jsx is the tool
const ModalFormDeviceAdd = ({ visible, handleAdd, handleClose }) => {

    const [form] = Form.useForm();
    //USE-PREV TO CLEAR?

    const onOk = (values) => {
        form.submit();
    };

    const addNewDevice = (values) => {
        //console.log("new device added", values)

        // const row = {
        //     imei: values.imei,
        //     deviceType: values.deviceType,
        //     installedVehicle: vehiclesNew[values.installedVehicle],
        //     iccid: vehiclesNew.iccid,
        //     phone: vehiclesNew.phone
        // }
        
        console.log("FIX DEVICE POST")
        /*
        DevicesAPI.post({row}).then( (u) => {
                handleAdd(u, row)
            }
        )
         */
    };

    const onFinish = (values) => {
        console.log(values)
        addNewDevice(values)
        handleClose()
    };

    //console.log(MODAL_PRINT_NAME, visible);

    return (
        
        <Modal title={MODAL_TITLE} visible={visible} onOk={onOk} onCancel={handleClose()}>
            
            <Form form={form} onFinish={onFinish} layout="vertical" name="userForm">
               
                <Form.Item
                    name="imei"
                    label="IMEI"
                    rules={[
                        {
                            required: true,
                            message: 'Ange IMEI nr!'
                        },
                    ]}
                >
                    <Input
                    placeholder="1234567898765432"
                    />
                    
                </Form.Item>
                
                <Form.Item
                    name="deviceType"
                    label="Typ av enhet"
                    rules={[
                        {
                            required: true,
                            message: 'Välj typ av enhet!'
                        },
                    ]}
                >
                    <Select>
                        {'selectDevices'}
                    </Select>
                </Form.Item>
                
                <Form.Item
                    name="iccid"
                    label="ICCID"
                    rules={[
                        {
                            required: true,
                            message: 'Ange ICCID nr!'
                        },
                    ]}
                >
                    <Input
                        placeholder="1234567898765432"
                    />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Telefonnummer"
                    rules={[
                        {
                            required: true,
                            message: 'Ange telefonnummer!'
                        },
                    ]}
                >
                    <Input
                        placeholder="1234567898765432"
                    />
                </Form.Item>

                <Form.Item
                    name="installedVehicle"
                    label="Ansluten till bilen"
                    rules={[
                        {
                            required: false,
                        },
                    ]}>

                    <Select>
                        {'selectVehicle'}
                    </Select>

                </Form.Item>

            </Form>

        </Modal>
    );

};


export default ModalFormDeviceAdd;
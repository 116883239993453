import {
    AuthorizationProvider,
    OrganizationSimpleProvider, shouldHideUsersOrganizationsColumn,
    UserListSimpleProvider,
    VehiclesComplexListProvider
} from "../services/contexts/contexts";
import {Nest} from "../utils/utilities";
import {SchedulingViewProvider} from "../services/contexts/views-context/scheduling-context";
import SchedulingController from "../components/trackson-components/Scheduling/SchedulingController";

const ReportSchedulePage = () => (
    <Nest wrappers={[ AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, OrganizationSimpleProvider, UserListSimpleProvider, VehiclesComplexListProvider, SchedulingViewProvider]}>
        <SchedulingController />
    </Nest>
)

export default ReportSchedulePage

import { Nest } from "../../utils/utilities";
import { OrganizationSimpleProvider, UserListSimpleProvider, VehicleProvider } from "../../services/contexts/contexts";
import AlarmView from "../../components/trackson-components/AlarmView/AlarmView";

const AlarmQualityController = () => {
    return (
        <>
            <AlarmView alarmType={"QUALITY"} />
        </>
    );
};
const AlarmsQualityPage = () => (
    <Nest wrappers={[VehicleProvider, UserListSimpleProvider, OrganizationSimpleProvider]}>
        <AlarmQualityController />
    </Nest>
);

export default AlarmsQualityPage

import React, {useContext, useEffect} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Grid, Menu} from "antd";
import {SessionContext} from "../../contexts/SessionContext"
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import {NAV_TYPE_SIDE, SIDE_NAV_LIGHT} from "constants/ThemeConstant";
import utils from "utils";
import {onMobileNavToggle} from "services/redux/actions/themeActions";
import {Icon as ExternalIcon} from "react-icons-kit";
import {mapO} from "react-icons-kit/fa/mapO";
import {road} from "react-icons-kit/fa/road";

// Don't edit this file!
// edit NavigationConfig.js
//
//
//
//
//
//
//
//
//
//
//
//
// Don't edit this file!
// edit NavigationConfig.js
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Don't edit this file!
// edit NavigationConfig.js
import {ORGANIZATION_ID} from "../../services/redux/constants/authConstants";
import {modulesByLimitedVisibility} from "../../constants/Modules";
import Loading from "../shared-components/Loading/Loading";
import {RestrictedMapOrganizationsContext} from "../../services/contexts/contexts";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const renderCustomIcon = (key) => {
    if (key === "journeys") {
        return <ExternalIcon icon={road} className="anticon navbar-icon" />;
    } else if (key === "map") {
        return <ExternalIcon icon={mapO} className="anticon navbar-icon" />;
    }

    return null;
};

const EmilusMenuItem = ({ key, icon, iconExternal, path, title, linkProps, ...props }) => {
    return (
        <Menu.Item key={key} {...props}>
            {icon ? <Icon type={icon} /> : null}
            {iconExternal ? <ExternalIcon className="navbar-icon anticon" icon={iconExternal} /> : null}
            <span>{title}</span>
            {path ? <Link to={path} {...linkProps} /> : null}
        </Menu.Item>
    );
};

const hasAnyElementFrom = (maybeSubset, universe) => {
    // Couldn't find any sane way to do this with set builtins
    if (!maybeSubset) return !universe;

    for (let elem of universe) {
        if (maybeSubset.includes(elem)) {
            return true;
        }
    }
    return false;
};


const SideNavContent = (props) => {
    // const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);
    const getOrganizationFromLS = localStorage.getItem(ORGANIZATION_ID);
    const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
    const showAlwaysInSidebar = [];
    const session = useContext(SessionContext)
    const excludedOrgs = useContext(RestrictedMapOrganizationsContext)

    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false);
        }
    };

    // This is a list for modules which are always available and has limited visibility
    Object.entries(modulesByLimitedVisibility).map(([l, v]) => showAlwaysInSidebar.push(l));


    const user = session.auth
    const loading = !user

    useEffect(() => {
        // replaces redux thingy
        if (!session.auth)
            session.requestGetAuth()
    }, [])

    const CheckIfModuleIsActive = (key) => {
        if (session.auth) {
            const organizationsActiveModules = session.auth?.organizations?.filter((org) => org.organizationId === (parseInt(getOrganizationFromLS) || session.auth.organizations[0].organizationId))[0];
            if (organizationsActiveModules.modules && organizationsActiveModules.modules[key]?.active === true) {
                return false;
            }
        }
        //TODO: Modules
        return false;
    };
    let localStorageGetOrganizationId = parseInt(localStorage.getItem(ORGANIZATION_ID));

    if (excludedOrgs?.includes(localStorageGetOrganizationId)) {
        navigationConfig.find((o) => o.key === "homeentries").submenu = navigationConfig
            .find((o) => o.key === "homeentries")
            .submenu.filter((o) => {
                return o.key !== "map";
            });
    }

    const maybeDisabled = { disabled: true };
    return loading ? (
        <Loading />
    ) : (
        <Menu
            theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            defaultSelectedKeys={[routeInfo?.key]}
            defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
            className={hideGroupTitle ? "hide-group-title" : ""}
        >
            {navigationConfig.map((menu) => {
                if (menu.limitVisibility && !hasAnyElementFrom(session.auth.roles, menu.limitVisibility)) {
                    return null;
                }

                if (!(menu.submenu?.length > 0)) {
                    return <EmilusMenuItem linkProps={{ onClick: () => closeMobileNav() }} {...menu} />;
                }

                return (
                    <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
                        {menu.submenu.map((subMenuFirst) => {
                            // Don't show submenu if module is not active for organization
                            if (!hasAnyElementFrom(subMenuFirst.key, showAlwaysInSidebar) && !hasAnyElementFrom("ROOT", session.auth.roles) && CheckIfModuleIsActive(subMenuFirst.key)) {
                                return null;
                            }

                            if (subMenuFirst.limitVisibility && !hasAnyElementFrom(session.auth.roles, subMenuFirst.limitVisibility)) {
                                return null;
                            }

                            if (!(subMenuFirst.submenu.length > 0)) {
                                return <EmilusMenuItem linkProps={{ onClick: () => closeMobileNav() }} {...subMenuFirst} />;
                            }

                            return (
                                <SubMenu icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null} key={subMenuFirst.key} title={setLocale(localization, subMenuFirst.title)}>
                                    {subMenuFirst.submenu.map((subMenuSecond) => {
                                        if (subMenuSecond.limitVisibility && !hasAnyElementFrom(session.auth.roles, subMenuSecond.limitVisibility)) {
                                            return null;
                                        }
                                        return <EmilusMenuItem linkProps={{ onClick: () => closeMobileNav() }} {...subMenuSecond} />;
                                    })}
                                </SubMenu>
                            );
                        })}
                    </Menu.ItemGroup>
                );
            })}
        </Menu>
    );
};

const TopNavContent = (props) => {
    const { topNavColor, localization } = props;
    return (
        <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
            {navigationConfig.map((menu) =>
                menu.submenu?.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                                {menu.icon ? <Icon type={menu?.icon} /> : null}
                                <span>{setLocale(localization, menu.title)}</span>
                            </span>
                        }
                    >
                        {menu.submenu?.map((subMenuFirst) =>
                            subMenuFirst.submenu.length > 0 ? (
                                <SubMenu key={subMenuFirst.key} icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null} title={setLocale(localization, subMenuFirst.title)}>
                                    {subMenuFirst.submenu.map((subMenuSecond) => (
                                        <Menu.Item key={subMenuSecond.key}>
                                            <span>{setLocale(localization, subMenuSecond.title)}</span>
                                            <Link to={subMenuSecond.path} />
                                        </Menu.Item>
                                    ))}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={subMenuFirst.key}>
                                    {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                                    <Link to={subMenuFirst.path} />
                                </Menu.Item>
                            )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path} /> : null}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
};

const mapStateToProps = ({ theme }) => {
    const { sideNavTheme, topNavColor } = theme;
    return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);

import React, { createContext, useState, useEffect } from "react";
import { dataApi } from "services/api/api";
const MapContext = createContext({});
const MapContextProvider = ({ children }) => {
    const [mapOverviewData, setMapOverViewData] = useState();

    const updateMapOverviewData = () => {
        dataApi
            .getOverview()
            .then((res) => {
                setMapOverViewData(res)
            })
            .catch((err) => console.error(err));
    };

    const updateSelectedVehicleOverviewData = (vehicleId) => {
        const selectedVehicleOverviewData = dataApi
            .getOverviewForVehicleId(vehicleId)

            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
        return selectedVehicleOverviewData;
    };
    return <MapContext.Provider value={{ mapOverviewData, updateMapOverviewData, updateSelectedVehicleOverviewData }}>{children}</MapContext.Provider>;
};
export { MapContext, MapContextProvider };

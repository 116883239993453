import React, { useState, useEffect, useRef, useContext } from "react";

import { createSelector } from "reselect";
// import fetch from "auth/FetchInterceptor";
import { Button, Checkbox, DatePicker, Form, Input, Avatar, InputNumber, message, Modal, Popconfirm, Radio, Select, Table, Tag, Typography, Progress, Divider, Row, Col } from "antd";

import { CloseSquareFilled, InfoCircleOutlined, CheckSquareFilled, WarningOutlined, PlayCircleOutlined, EditOutlined, WarningFilled, QuestionCircleFilled } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Text from "antd/es/typography/Text";
import { mockDevices } from "../components/trackson-components/Superdevices/mock-device-data";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../components/trackson-components/shared-components/Spinner";
import { Tooltip } from "chart.js";
import { filter } from "lodash";
import { Nest } from "../utils/utilities";
import {
    DeviceListContext,
    DeviceListProvider,
    OrganizationSimpleContext,
    OrganizationSimpleProvider,
    VehicleContext,
    VehicleProvider,
    VehiclesComplexListContext,
    VehiclesComplexListProvider,
} from "../services/contexts/contexts";
import Loading from "../components/shared-components/Loading/Loading";
import { OrganizationsTreeSelect } from "../components/trackson-components/shared-components/OrganizationsTreeSelect";
import Flex from "../components/shared-components/Flex/Flex";
import { dataApi, userApi } from "../services/api/api";
import { LastSeenLink } from "../components/trackson-components/shared-components/DatumLink";
import {AlarmsEventsListContext, AlarmsEventsListProvider} from "../services/contexts/alarmsEventsListContext";
import {DeviceViewContext, DeviceViewProvider} from "../services/contexts/deviceViewContext";

const DEVICE_TYPE_NAMES = {
    FMB001: "Plug-n-drive",
    FMT100: "Plug-n-drive",
    FMC001: "Plug-n-drive",
    TAT100: "GPS-tracker",
    FMB920: "Anläggningsmaskin",
    FMM001: "Bert Åkes Test FMM",
};

const { Search } = Input;
const progressBarSize = 40;
const progressBarStrokeSize = 10;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 24 },
};
const validateMessages = {
    types: {
        email: "Not a validate email!",
    },
};
const lorem =
    "tempus egestas sed sed risus pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui";

const vehicles = [
    {
        id: 45,
        category: "POOLCAR",
        registry: "abc123",
    },
    {
        id: 46,
        category: "POOLCAR",
        registry: "tdr344",
    },
    {
        id: 47,
        category: "POOLCAR",
        registry: "mkl432",
    },
];
const selectVehicle = [];

vehicles.map((d, k) => {
    selectVehicle.push(<Option value={k}>{d.registry?.toUpperCase()}</Option>);
});

const selectDevices = [];

Object.entries(DEVICE_TYPE_NAMES).map(([key, title]) => {
    selectDevices.push(
        <Option value={key}>
            {key} {title}
        </Option>
    );
});

// const mockVehicles = {
//    3: {
//       displayName: "RGX303",
//    },
//    4: {
//       displayName: "SCV909",
//    },
// };

// let _id = 4

/*
const DevicesAPI = {
    get: () => Promise.resolve(mockDevices),
    post: (obj) => {
        _id ++
        return Promise.resolve(_id)
    },
    put: (id, changeList) => Promise.resolve(null),
    putPermissions: (id, permissionList) => Promise.resolve(null),
}
 */

export const devicesSelector = createSelector(
    (s) => s.nav?.devices?.data,
    (deviceList) => {
        const r = {};

        if (!deviceList) return r;
        deviceList.forEach((d) => {
            r[d.id] = {
                ...d,
                iccid: d.iccid ? d.iccid : "iccid-todo",
                phone: d.phone ? d.phone : "-",
                displayName: DEVICE_TYPE_NAMES[d.deviceType],
                installedVehicle: d.installedVehicle
                    ? {
                          id: d.installedVehicle,
                          registry: "DEM000",
                          category: "POOLCAR",
                      }
                    : null,
            };
        });
        return r;
    }
);
//Devices table - nitipon
const DevicesView = ({ devices, vehicles, organizations, editDevice, createDevice }) => {
    devices = Object.entries(devices).map(([k, v]) => {
        const vehicle = vehicles?.find((e) => e.id === v.installedVehicle);
        const organization = organizations?.find((e) => e.organizationId === v.organizationId);
        return {
            ...v,
            installedVehicle: {
                id: vehicle?.id,
                registry: vehicle?.registry,
                displayName: vehicle?.displayName,
            },
            driver: {
                currentDriver: vehicle?.currentDriver,
                defaultDriver: vehicle?.defaultDriver,
            },
            organization: {
                organizationId: organization?.organizationId,
                fullName: organization?.fullName,
            },
        };
    });

    const [installationInfoModal, installationInfoModalState] = useState(false);
    const [errorReportModal, errorReportModalState] = useState(false);
    const [restartModal, restartModalState] = useState(false);
    const [editModal, editModalState] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState();

    const toggleInstallationInfoModal = () => {
        installationInfoModalState(!installationInfoModal);
    };
    const toggleErrorRepoptModal = () => {
        errorReportModalState(!errorReportModal);
    };
    const toggleRestartModal = () => {
        restartModalState(!restartModal);
    };
    const toggleEditModal = () => {
        editModalState(!editModal);
    };

    const trl = {
        imei: "IMEI",
        deviceType: "Typ av enhet",
        installedVehicle: "Fordon",
    };

    const columns = [
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Kopplad till",
            render: (value) => {
                const organization = organizations?.find((e) => e.organizationId === value);
                return (
                    <div>
                        <Text>{organization?.fullName}</Text>
                    </div>
                );
            },
        },
        {
            key: "identity",
            dataIndex: "identity",
            title: "Identitet",
            render: (text, value) => {
                return (
                    <div>
                        <Text>{value?.imei}</Text>
                        <br />
                        <Text type="secondary">{value?.iccid}</Text>
                    </div>
                );
            },
        },
        {
            key: "deviceType",
            dataIndex: "deviceType",
            title: trl["deviceType"],
            render: (value, record) => {
                return Object.entries(DEVICE_TYPE_NAMES).map(([key, title]) => {
                    if (key === value) {
                        return (
                            <div>
                                {title}
                                <br />
                                <Text type="secondary"> {value} </Text>
                            </div>
                        );
                    }
                });
            },
        },
        {
            key: "status",
            dataIndex: "status",
            title: "Batteri",
            render: (value) => {
                let strokeColor = "";
                if (!value.latestBatteryLevel) value.latestBatteryLevel = 0;

                if (value?.latestBatteryLevel <= 20) {
                    strokeColor = "#ff6b72";
                } else if (value?.latestBatteryLevel >= 20 && value?.latestBatteryLevel <= 50) {
                    strokeColor = "#ffc542";
                } else if (value?.latestBatteryLevel >= 50 && value?.latestBatteryLevel < 80) {
                    strokeColor = "#3e82f7";
                } else {
                    strokeColor = "#04d182";
                }
                return <Progress type="dashboard" percent={value?.latestBatteryLevel} strokeColor={strokeColor} width={progressBarSize} strokeWidth={progressBarStrokeSize} />;
            },
        },
        {
            key: "status",
            dataIndex: "status",
            title: "Sågs senast",
            render: (value) => {
                if (!value?.timestampMs) return "–";

                return (
                    <div>
                        <LastSeenLink value={value?.timestampMs} typ={"map"} />
                    </div>
                );
            },
        },
        {
            key: "installedVehicle",
            dataIndex: "installedVehicle",
            title: "Fordon",
            render: (vehicle, record) => {
                return vehicle ? (
                    <div>
                        <Text>{vehicle?.registry?.toUpperCase()}</Text>
                        <br />
                        <Text type="secondary">{vehicle?.displayName}</Text>
                    </div>
                ) : (
                    "-"
                );
            },
        },
        {
            key: "tools",
            title: "Verktyg",
            width: 540,
            render: (value, record) => {
                return (
                    <span>
                        <Button
                            type="link"
                            icon={<InfoCircleOutlined />}
                            size="small"
                            onClick={() => {
                                setDeviceInfo(record);
                                toggleInstallationInfoModal();
                            }}
                        >
                            Install. info
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            danger
                            type="link"
                            icon={<WarningOutlined />}
                            size="small"
                            onClick={() => {
                                setDeviceInfo(record);
                                toggleErrorRepoptModal();
                            }}
                        >
                            Felanmälan
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            type="link"
                            icon={<PlayCircleOutlined />}
                            style={{ color: "#04d182" }}
                            size="small"
                            onClick={() => {
                                setDeviceInfo(record);
                                toggleRestartModal();
                            }}
                        >
                            Omstart
                        </Button>
                        {/*<Divider type="vertical" />*/}
                        {/*<Button*/}
                        {/*   type="link"*/}
                        {/*   icon={<EditOutlined />}*/}
                        {/*   style={{ color: "#ededed" }}*/}
                        {/*   size="small"*/}
                        {/*   onClick={() => {*/}
                        {/*      toggleEditModal();*/}
                        {/*   }}*/}
                        {/*>*/}
                        {/*   Ändra*/}
                        {/*</Button>*/}
                    </span>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const confirmRestart = (device) => {
        console.log("-> sDeviceVehicleId", device);
        const sDeviceVehicleId = device?.installedVehicle?.id;
        const sDeviceOrgId = device?.organization?.organizationId; //NOTE: organizationId in this case/view is an object containing the whole organization-object
        console.log("-> sDeviceOrgId", sDeviceOrgId);

        // TODO test this after no redux here
        dataApi.vehicleCpuReset(sDeviceOrgId ? sDeviceOrgId : null, sDeviceVehicleId)
    };

    return (
        <div>
            <InstallModal visible={installationInfoModal} hidden={toggleInstallationInfoModal} deviceInfo={deviceInfo} />
            <ErrorReportModal visible={errorReportModal} hidden={toggleErrorRepoptModal} deviceInfo={deviceInfo} />
            <EditModal visible={editModal} hidden={toggleEditModal} />
            <ConfirmRestartModal
                visible={restartModal}
                hidden={toggleRestartModal}
                confirm={() => {
                    toggleRestartModal();
                    confirmRestart(deviceInfo);
                }}
            />
            <Table dataSource={devices} scroll={{ x: true }} columns={columns} tableLayout="fixed" />
        </div>
    );
};
const InstallModal = ({ visible, hidden, deviceInfo }) => {
    return (
        <Modal title="Installation information" visible={visible} onCancel={hidden} footer={[null]}>
            <Row justify="space-between">
                <Col>
                    <div>
                        <h5>Aktiverad</h5>
                        <LastSeenLink value={deviceInfo?.physicalInstallTimestamp} typ={"map"} />
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>Enhet typ</h5>
                        <Text type="secondary">
                            {Object.entries(DEVICE_TYPE_NAMES).map(([key, title]) => {
                                if (key === deviceInfo?.deviceType) {
                                    return title;
                                }
                            })}
                        </Text>
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>Typ av installation</h5>
                        <Text type="secondary">{deviceInfo?.currentInstallationLocation}</Text>
                    </div>
                </Col>
            </Row>
            <Row justify="start" style={{ marginTop: "24px" }} gutter={16}>
                <Col>
                    <div>
                        <h5>IMEI</h5>
                        <Text type="secondary">{deviceInfo?.imei}</Text>
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>ICCID</h5>
                        <Text type="secondary">{deviceInfo?.iccid}</Text>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

const ErrorReportModal = ({ visible, hidden, deviceInfo }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.body = "Enhet " + deviceInfo.imei + " Meddelande: " + values.body;
        userApi.postReportTrackson(values).then((r) => {
            message.success("Din felanmälan har skickats!");
            form.resetFields();
        });
        console.log("Success:", values);
    };

    return (
        <Modal title="Felanmälan" visible={visible} onCancel={hidden} footer={[null]}>
            <Row justify="space-between" align={"start"}>
                <Col>
                    <div>
                        <h5>Kopplat till</h5>
                        <Text type="secondary">{deviceInfo?.organization?.fullName}</Text>
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <Flex flexDirection={"column"}>
                        <h5>Fordon</h5>
                        <Text>{deviceInfo?.installedVehicle?.registry?.toUpperCase()}</Text>
                        <Text type="secondary">{deviceInfo?.installedVehicle?.displayName}</Text>
                    </Flex>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>Kategory</h5>
                        <Text type="secondary">{deviceInfo?.installedVehicle?.category}</Text>
                    </div>
                </Col>
            </Row>
            <Row justify="space-between" style={{ marginTop: "24px" }} gutter={16}>
                <Col>
                    <div>
                        <h5>Primär Förare</h5>
                        <Text type="secondary">{deviceInfo?.driver?.defaultDriver?.displayName}</Text>
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>Incheckad Förare</h5>
                        <Text type="secondary">{deviceInfo?.driver?.currentDriver?.displayName}</Text>
                    </div>
                </Col>
                <Divider type="vertical" className="modal-divider" />
                <Col>
                    <div>
                        <h5>Larm</h5>
                        {deviceInfo?.functional?.alarm ? (
                            <div>
                                <WarningFilled
                                    style={{
                                        color: deviceInfo?.functional?.alarm >= 6 ? "#ff6b72" : "#ffc542",
                                    }}
                                />
                                <Text
                                    type="secondary"
                                    style={{
                                        marginLeft: "8px",
                                        color: deviceInfo?.functional?.alarm >= 6 ? "#ff6b72" : "#ffc542",
                                    }}
                                >
                                    {deviceInfo?.functional?.alarm} larm
                                </Text>
                            </div>
                        ) : (
                            <div>
                                <CheckSquareFilled style={{ color: "#04d182" }} />
                                <Text type="secondary" style={{ marginLeft: "8px" }}>
                                    inga larm
                                </Text>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            <Divider />
            <h5>Meddelande</h5>
            <Form {...layout} name="nest-messages" validateMessages={validateMessages} layout="vertical" style={{ marginTop: "16px" }} onFinish={onFinish}>
                <Form.Item label="Titel" name="subject" rules={[{ required: true, message: "Ange titel!" }]}>
                    <Input placeholder="t.ex. ABC123 har låg batterinivå" />
                </Form.Item>

                <Form.Item
                    label="Beskrivning"
                    name="body"
                    rules={[
                        {
                            required: false,
                            message: "Please input description!",
                        },
                    ]}
                >
                    <Input.TextArea rows={8} placeholder="t.ex. Batterinivå var låg på morgonen" />
                </Form.Item>

                <Form.Item label="Din E-post" name="addrFrom" rules={[{ required: true, message: "Ange E-post" }]}>
                    <Input placeholder="min-epost@example.com" />
                </Form.Item>
                <Form.Item
                    label="Ditt Telefonnummer"
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: "Ange telefonnummer",
                        },
                    ]}
                >
                    <Input placeholder="0707..." />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol }} style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit" style={{ borderRadius: "4px" }}>
                        Skicka
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ConfirmRestartModal = ({ visible, hidden, confirm }) => {
    return (
        <Modal
            title="Omstart"
            visible={visible}
            onCancel={hidden}
            className="restart-modal"
            footer={[
                <Button key="cancel" onClick={hidden} className="cancel-btn">
                    Avbryt
                </Button>,
                <Button type="primary" key="next" className="confirm-btn" onClick={confirm}>
                    Starta om
                </Button>,
            ]}
        >
            <Flex flexDirection={"column"} alignItems={"center"}>
                <WarningFilled style={{ color: "#ffc542", fontSize: "40px" }} />
                <h1>Varning</h1>
                <p>Är du säker på att du vill starta om enheten?</p>
            </Flex>
        </Modal>
    );
};
const EditModal = ({ visible, hidden }) => {
    return (
        <Modal title="Ändra" visible={visible} onCancel={hidden} footer={[null]} width={640}>
            <div className="change-instruction">
                <h5>Flytta enhet till annat fordon</h5>
                <div className="description">
                    <ul>
                        <li>
                            <p>{lorem}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="change-instruction">
                <h5>Ersätta defekt enhet</h5>
                <div className="description">
                    <ul>
                        <li>
                            <p>{lorem}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="change-instruction">
                <h5>Byta enhet mellan två fordon</h5>
                <div className="description">
                    <ul>
                        <li>
                            <p>{lorem}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="change-instruction">
                <h5>Pensionera enhet</h5>
                <div className="description">
                    <ul>
                        <li>
                            <p>{lorem}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};
// --------------------------------------------------
const DevicesController = () => {
    let id = 0;
    const [visible, setVisible] = useState(false);

    const setDevices = () => console.log("TODO set devices");

    const vehicles = useContext(VehiclesComplexListContext);
    const {organizationList: organizations} = useContext(OrganizationSimpleContext)

    const { filteredList, selectedOrganization, handleChange, organizationSelectOnChange, loading, searchTerm } = useContext(DeviceViewContext);

    const showUserModal = () => {
        setVisible(true);
    };

    const hideUserModal = () => {
        setVisible(false);
    };

    function handleAdd(u, row) {}

    return loading ? (
        <Loading />
    ) : (
        <div>
            <ModalForm visible={visible} onCancel={hideUserModal} handleAdd={handleAdd} />
            <div className="table-container">
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
                    <Typography.Title>Enheter</Typography.Title>
                    <div style={{ display: "flex", gap: 15 }}>
                        {organizations?.length < 2 ? null : (
                            <OrganizationsTreeSelect data={organizations} onChange={organizationSelectOnChange} selectedOrganization={selectedOrganization} isMultiple={true} />
                        )}
                        <Input.Search type="text" placeholder="Sök enhet" defaultValue={searchTerm} enterButton onChange={handleChange} style={{ width: 300 }} />
                    </div>
                </div>
            </div>
            <DevicesView
                devices={filteredList}
                vehicles={vehicles}
                organizations={organizations}
                editDevice={(d) => {
                    console.log("device has been edited");

                    return true;
                }}
                createDevice={(d) => {}}
            />
        </div>
    );
};

const ModalForm = ({ visible, onCancel, handleAdd }) => {
    const [form] = Form.useForm();
    const prevVisibleRef = useRef();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;
    useEffect(() => {
        console.log(visible, prevVisible);

        if (!visible && prevVisible) {
            form.resetFields();
        }
    }, [visible]);

    const onOk = (values) => {
        form.submit();
    };

    const addNewDevice = (values) => {
        console.log("new device added", values);

        const row = {
            imei: values.imei,
            deviceType: values.deviceType,
            installedVehicle: vehicles[values?.installedVehicle],
            iccid: vehicles?.iccid,
            phone: vehicles?.phone,
        };

        console.log("FIX DEVICE POST");
        /*
        DevicesAPI.post({row}).then( (u) => {
                handleAdd(u, row)
            }
        )
         */
    };

    const onFinish = (values) => {
        console.log(values);
        addNewDevice(values);
        onCancel();
    };

    return (
        <Modal title="Lägg till ny enhet" visible={visible} onOk={onOk} onCancel={onCancel}>
            <Form form={form} onFinish={onFinish} layout="vertical" name="userForm">
                <Form.Item
                    name="imei"
                    label="IMEI"
                    rules={[
                        {
                            required: true,
                            message: "Ange IMEI nr!",
                        },
                    ]}
                >
                    <Input placeholder="1234567898765432" />
                </Form.Item>
                <Form.Item
                    name="deviceType"
                    label="Typ av enhet"
                    rules={[
                        {
                            required: true,
                            message: "Välj typ av enhet!",
                        },
                    ]}
                >
                    <Select>{selectDevices}</Select>
                </Form.Item>
                <Form.Item
                    name="iccid"
                    label="ICCID"
                    rules={[
                        {
                            required: true,
                            message: "Ange ICCID nr!",
                        },
                    ]}
                >
                    <Input placeholder="1234567898765432" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="Telefonnummer"
                    rules={[
                        {
                            required: true,
                            message: "Ange telefonnummer!",
                        },
                    ]}
                >
                    <Input placeholder="1234567898765432" />
                </Form.Item>

                <Form.Item
                    name="installedVehicle"
                    label="Ansluten till bilen"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select>{selectVehicle}</Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const OrganizationDevicesPage = () => {
    return <Nest wrappers={[VehiclesComplexListProvider, DeviceViewProvider, OrganizationSimpleProvider, DeviceListProvider]}>
        <DevicesController />
    </Nest>
}

export default OrganizationDevicesPage;

import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Dropdown,
    Switch,
    Select,
    Form,
    Input,
    InputNumber,
    Menu,
    message,
    Radio,
    Slider,
    Typography
} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router-dom";
import {dataApi} from "../../../services/api/api";
import {APP_PREFIX_PATH, BOOTSTRAP_URL_KEYS} from "../../../configs/AppConfig";
import {mapOptions, setLightMapType} from "../../../configs/mapStyle";
import GoogleMapReact from "google-map-react";
import Flex from "../../shared-components/Flex/Flex";
import {EmailRecipients, VehiclesTreeStructure} from "../shared-components/ItemsSelectors";
import {ORGANIZATION_ID, USER_ID} from "../../../services/redux/constants/authConstants";
import Loading from "../../shared-components/Loading/Loading";
import {createBoundsFromZonesData} from "../Zones/Zones";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {defaultScheduleAlarm} from "../../../constants/InitialConstant";

import {ScheduleTable2} from "../Schedule/ScheduleTable";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {ALARM_TEXT} from "../../../services/redux/constants/alarmConstants";
import {styles} from "../AlarmEditor/styles";

const {Title} = Typography;

export const stylesMap = {
    mapAndDetailsView: {
        width: "100%",
    },
    targetView: {
        width: "50%",
    },
    detailsView: {
        width: "40%",
    },
    mapView: {
        minHeight: 300,
        height: "100%"
    },
    schemaView: {
        marginTop: 25,
        marginBottom: 25,
    },
};

function MapView({setPolygons, polygons, setCenterCoords, centerCoords, bounds, ...props}) {
    const [centerMap, setCenterMap] = useState([59.334591, 18.06324]);
    const [mapsInstance, setMapsInstance] = useState();
    let activeInfoWindow;
    const searchBox = useRef(null);
    const input = document.getElementById("places-ref");

    function returnTriangle(results) {
        const triangle1 = mapsInstance.maps.geometry.spherical.computeOffset(results, 200, 0);
        const triangle2 = mapsInstance.maps.geometry.spherical.computeOffset(results, 200, 120);
        const triangle3 = mapsInstance.maps.geometry.spherical.computeOffset(results, 200, -120);
        return [triangle1, triangle2, triangle3];
    }

    const initPath = () => {
        const geocoder = new mapsInstance.maps.Geocoder();
        geocoder?.geocode(
            {
                address: input?.value,
            },
            function (results, status) {
                const ok = mapsInstance?.maps.GeocoderStatus.OK;

                if (!results) {
                    const latitude = parseFloat(centerCoords?.latitude);
                    const longitude = parseFloat(centerCoords?.longitude);
                    results = {lat: latitude, lng: longitude};
                    status = ok;
                    setCenterMap([latitude, longitude]);
                } else {
                    results = results[0].geometry.location;
                }

                if (status === ok) {
                    const triangleCoords = returnTriangle(results);
                    polygons?.setPath(triangleCoords);
                }
            }
        );
    };

    useEffect(() => {
        if (!centerCoords || !mapsInstance) return;

        initPath();
    }, [centerCoords, props.place]);

    function handleOnPlacesChanged(searchBox) {
        if (!searchBox?.current?.getPlaces()) {
            return;
        }

        const place = searchBox?.current?.getPlaces()[0];
        const bounds = place?.geometry?.viewport;
        mapsInstance?.map.fitBounds(bounds);

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        props.setAddress(place.formatted_address);
        setCenterCoords({latitude: lat, longitude: lng});

        // initPath()
    }

    useEffect(() => {
        const maps = mapsInstance?.maps;

        if (!searchBox?.current && maps?.places) {
            searchBox.current = new maps.places.SearchBox(input);
            searchBox.current.addListener("places_changed", () => handleOnPlacesChanged(searchBox));
        }

        return () => {
            if (maps) {
                searchBox.current = null;
                maps.event.clearInstanceListeners(searchBox);
            }
        };
    }, [mapsInstance]);

    const polygonChanged = (map, maps, polygons) => {
        var bounds = new maps.LatLngBounds();
        const path = polygons?.getPath().getArray();

        for (var i = 0; i < path?.length; i++) {
            bounds.extend(new maps.LatLng(path[i].lat(), path[i].lng()));
        }

        var center = bounds.getCenter();
        var latitude = center.lat();
        var longitude = center.lng();

        setCenterCoords({latitude: latitude, longitude: longitude});
        console.log(centerCoords);
        console.log(latitude, longitude);
    };

    function initPolygons(maps, map) {
        const pathDefault = [
            {
                lat: 59.57053037008066,
                lng: 16.391671447753904,
            },
            {
                lat: 60.05664027022537,
                lng: 17.885812072753904,
            },
            {
                lat: 59.180021795559256,
                lng: 17.836418296440627,
            },
        ];
        //noinspection JSUnresolvedFunction
        const newPolygons = new maps.Polygon({
            map,
            paths: bounds,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            draggable: false,
            geodesic: true,
            editable: true,
            onMouseOver: () => console.log("over"),
        });

        console.log(bounds, newPolygons);
        newPolygons.addListener("dragend", () => polygonChanged(map, maps, newPolygons));

        setPolygons(newPolygons);
        setMapsInstance({
            map: map,
            maps: maps,
        });

        const path = createBoundsFromZonesData(maps, bounds);
        //noinspection JSUnresolvedFunction
        map.fitBounds(path);
    }

    function setInfoWindow(maps, map, location) {
        if (activeInfoWindow) {
            activeInfoWindow.close();
        }

        //noinspection JSUnresolvedFunction
        const infowindow = new maps.InfoWindow({
            content: '<div id="content">' + '<div id="siteNotice">' + "</div>" + '<div id="bodyContent">' + "<p><b>Sträck ut området för att markera det.</b></p>" + "</div>" + "</div>",
            position: {lat: location.latitude, lng: location.longitude},
        });

        infowindow.open(map, polygons);
        activeInfoWindow = infowindow;
    }

    function onMapClick(r) {
        const location = {latitude: r.latLng.lat(), longitude: r.latLng.lng()};
        setCenterCoords(location);
        props.setAddress(null);
    }

    return (
        <div style={stylesMap.mapView}>
            <GoogleMapReact
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                center={centerMap}
                zoom={5}
                yesIWantToUseGoogleMapApiInternals={true}
                options={{...mapOptions, disableDoubleClickZoom: true}}
                onGoogleApiLoaded={({map, maps}) => {
                    setLightMapType(maps, map);
                    maps?.event.addListener(map, "dblclick", (r) => onMapClick(r, map, maps));
                    initPolygons(maps, map);
                }}
            ></GoogleMapReact>
        </div>
    );
}

function DetailsView({centerCoords, setCenterCoords, address, setAddress, alarm, zones, getZones, ...props}) {
    const options = [
        {label: STATIC_TEXT.INSIDE, value: SUPER_DEVICES_KEYS_AND_INDEXES.activeOnEnter},
        {label: STATIC_TEXT.OUTSIDE, value: SUPER_DEVICES_KEYS_AND_INDEXES.activeOnExit},
    ];
    const activeOn = alarm?.activeOnExit ? SUPER_DEVICES_KEYS_AND_INDEXES.activeOnExit : SUPER_DEVICES_KEYS_AND_INDEXES.activeOnEnter;

    const menu = (
        <Menu onClick={getZones}>
            {zones?.map((e) => {
                return <Menu.Item key={e.zoneId}>{e.zoneName}</Menu.Item>;
            })}
        </Menu>
    );

    let defaultDistortionValue = 50;
    let defaultRepeatValue = 0;
    const marks = {
        0: STATIC_TEXT.LOW,
        50: "Normal",
        100: STATIC_TEXT.HIGH,
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        maxWidth: "100%",
        whiteSpace: "wrap"
    };

    return (
        <Card style={stylesMap.detailsView}>
            <Form.Item
                name={SUPER_DEVICES_KEYS_AND_INDEXES.organizationId}
                label={STATIC_TEXT.ORGANIZATION}
                rules={[{required: true, message: STATIC_TEXT.SELECT_AN_EXISTING_ORGANISATION}]}
                initialValue={alarm?.organizationId}
            >
                <OrganizationsTreeSelect defaultValue={alarm?.organizationId} data={props.knownOrganizations}
                                         onChange={(r) => props.setSelectedOrganization(r)} isMultiple={false}/>
            </Form.Item>

            <Form.Item
                name="target"
                label={"Fordon/utrustning"}
                rules={[{required: true}]}
                initialValue={props.isTracker}
            >
                <Select
                    style={{width: "100%",}}
                    onChange={(value) => props.setIsTracker(value)}
                    value={props.isTracker}
                >
                    <Select.Option value={false}>Fordon</Select.Option>
                    <Select.Option value={true}>Utrustning</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.name} label={STATIC_TEXT.ALIAS}
                       rules={[{required: true, message: STATIC_TEXT.SUPPLY_AN_ALIAS}]}>
                <Input placeholder={STATIC_TEXT.SUPPLY_AN_ALIAS}/>
            </Form.Item>

            <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.activeOn} label={STATIC_TEXT.ACTIVATE_ALARM_WHEN}
                       initialValue={activeOn}>
                <Radio.Group defaultValue={activeOn} options={options}/>
            </Form.Item>

            <Form.Item label={STATIC_TEXT.DISTORTION} name={SUPER_DEVICES_KEYS_AND_INDEXES.distortion}
                       initialValue={alarm?.distortion || defaultDistortionValue}>
                <Slider marks={marks} step={50} tooltipVisible={false} defaultValue={defaultDistortionValue}/>
            </Form.Item>

            <Form.Item
                name={"repeatAfterHoursSelect"}
                label={ALARM_TEXT["repeatAfterHours"]}
                initialValue={0}
                style={{whiteSpace: "wrap"}}
            >
                <Radio.Group>
                    <Radio value={0} style={radioStyle}>
                        Meddela bara en gång
                    </Radio>
                    <Radio value={1} style={radioStyle}>
                        <span className="ant-form-text">Upprepa larmet varje </span>
                        <Form.Item noStyle name={SUPER_DEVICES_KEYS_AND_INDEXES.repeatAfterHours}
                                   initialValue={24}>
                            <InputNumber min={0}/>
                        </Form.Item>
                        <span> h tills larmperioden avslutats eller att larmet kvitterats</span>
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name={"notifyWhenAcknowledged"}
                label={ALARM_TEXT["notifyWhenAcknowledged"]}
                style={styles["notifyWhenAcknowledged"]}
                rules={[
                    {
                        required: false,
                        message: "Detta fält kan inte vara tomt",
                    },
                ]}
            >
                <span className="ant-form-text">Meddela när larmet kvitterats </span>
                <Form.Item noStyle name={"notifyWhenAcknowledged"}
                           valuePropName={alarm?.notifyWhenAcknowledged ? "checked" : null}>
                    <Switch/>
                </Form.Item>

            </Form.Item>

        </Card>
    );
}

function TargetVehiclesView(props) {
    return (
        <div style={stylesMap.targetView}>
            <Title level={3}>{STATIC_TEXT.WHICH}</Title>

            <VehiclesTreeStructure
                checkedKeys={props.checkedKeys}
                setCheckedKeys={props.setCheckedKeys}
                knownVehicles={props.knownVehicles}
                knownOrganizations={props.knownOrganizations}
                selectedOrganization={props.selectedOrganization}
                {...props}
            />
        </div>
    );
}

function EmailsView(props) {
    return (
        <div style={stylesMap.targetView}>
            <Title level={3}>{STATIC_TEXT.WHO}</Title>
            <EmailRecipients emailData={props.emailData} setEmailData={props.setEmailData}/>
        </div>
    );
}

function DetailsViewFormGeoAlarm(props) {
    const location = useLocation();
    const alarmId = parseInt(location?.state?.id);
    const [alarm, setAlarm] = useState(null);
    const [form] = Form.useForm();
    const [knownOrganizations, setKnownOrganizations] = useState();
    const [knownVehicles, setKnownVehicles] = useState();
    const [loading, setLoading] = useState(true);
    const [emailData, setEmailData] = useState([]);
    const [schedule, setSchedule] = useState();
    const history = useHistory();
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [polygons, setPolygons] = useState();
    const [centerCoords, setCenterCoords] = useState();
    const [place, setPlace] = useState("");
    const [knownZones, setKnownZones] = useState();
    const [formValues, setFormValues] = useState();
    const [bounds, setBounds] = useState(null);
    const [showRepeatAlarm, setShowRepeatAlarm] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState();
    const [isTracker, setIsTracker] = useState(false);

    useEffect(() => {
        if (alarmId > -1) {
            dataApi.getGeoAlarmById(alarmId).then((i) => {
                const targetVehicleIds = i?.targetVehicleIds || [];
                let targetOrganizationIds = i?.targetOrganizationIds || [];
                let targetTrackerOrganizationIds = i?.targetTrackerOrganizationIds || [];
                const targetTrackerIds = i?.targetTrackerIds || [];

                i.distortion = 100 - i.distortion;
                targetOrganizationIds = targetOrganizationIds?.map((org) => "org:" + org);
                targetTrackerOrganizationIds = targetTrackerOrganizationIds?.map((org) => "org:" + org);

                i.repeatAfterHoursSelect = i.repeatAfterHours === 0 ? 0 : (i.repeatAfterHours === -1 ? 0 : 1)

                setAlarm(i);
                setSchedule(i?.schedule);
                setCenterCoords(i?.centerCoords);
                setPlace(i?.place);
                setShowRepeatAlarm(i.repeatAfterHours !== -1);
                setSelectedOrganization(i.organizationId)
                setIsTracker(targetTrackerIds?.length > 0 || targetTrackerOrganizationIds?.length > 0)
                setCheckedKeys([...targetVehicleIds, ...targetOrganizationIds,
                    ...targetTrackerOrganizationIds, ...targetTrackerIds]);
                const email = i?.emails?.map((i) => {
                    return {email: i};
                });
                setEmailData(email);
                let path = i.zoneCoords?.map((latLng) => {
                    return {lat: latLng.latitude, lng: latLng.longitude};
                });
                setBounds(path);
            });
        } else {
            setSchedule([...defaultScheduleAlarm]);
            setCenterCoords({latitude: 0, longitude: 0});
        }
        if (!knownVehicles) {
            dataApi.getVehicles().then((res) => {
                setKnownVehicles(res);
            });
        }

        if (!knownZones) {
            const userId = localStorage.getItem(USER_ID);
            dataApi.getZones(userId).then((res) => {
                setKnownZones(res);
            });
        }

        if (!knownOrganizations) {
            dataApi.getOrganizationsSimple().then((r) => {
                const orgLS = parseInt(localStorage.getItem(ORGANIZATION_ID));
                if (orgLS) {
                    //  r = r.filter(i => i.organizationId === orgLS || i.parentOrganization === orgLS)
                }
                setKnownOrganizations(r);
            });
        }
    }, [alarmId]);

    useEffect(() => {
        if (knownVehicles && knownOrganizations && schedule) setLoading(false);
    }, [knownOrganizations, knownVehicles, schedule]);



    function historyPushAlarmsZones() {
        const url = APP_PREFIX_PATH + "/alarmsZones/";
        history.push({
            pathname: url,
        });
    }

    const onFinish = (values) => {
        const emails = [];

        Object.entries(emailData)?.map(([_, title]) => {
            return emails.push(title.email);
        });
        //noinspection JSUnresolvedFunction
        let path = polygons
            ?.getPath()
            .getArray()
            .map((latLng) => {
                return {latitude: latLng.lat(), longitude: latLng.lng()};
            });

        if (path?.length < 3) {
            message.info(STATIC_TEXT.CHOOSE_MIN_3);
            return;
        }

        const organizationId = localStorage.getItem(ORGANIZATION_ID);
        const activeOnEnter = values.activeOn.includes(SUPER_DEVICES_KEYS_AND_INDEXES.activeOnEnter);
        const activeOnExit = values.activeOn.includes(SUPER_DEVICES_KEYS_AND_INDEXES.activeOnExit);

        let targetOrganizationIds = checkedKeys?.filter((org) => org.toString().startsWith("org")).map((org) => parseInt(org.replace("org:", "")));
        const repeatAfterHours = showRepeatAlarm ? values.repeatAfterHours : -1;

        let selectedTarget = checkedKeys.map(keys => knownVehicles?.find(vehicle => vehicle?.id === keys))
        selectedTarget = selectedTarget.filter(item => !targetOrganizationIds.includes(item?.organizationId) && item)?.map(item => item?.id)

        const scheduleTracker = isTracker ? schedule?.map(item => item = {...item,     "times": [
                {
                    "start": '0000',
                    "stop": '2359'
                },
            ]}) : null

        const data = {
            organizationId: parseInt(organizationId),
            schedule: scheduleTracker || schedule,
            emails: emails,
            targetVehicleIds: !isTracker ? selectedTarget : [],
            targetOrganizationIds: !isTracker ? targetOrganizationIds : [],
            targetTrackerIds: isTracker ? selectedTarget : [],
            targetTrackerOrganizationIds: isTracker ? targetOrganizationIds : [],
            zoneCoords: [...path, path[0]],
            ...values,
            repeatAfterHours: values.repeatAfterHoursSelect === 0 ? 0 : repeatAfterHours,
            centerCoords: centerCoords,
            place: place,
            activeOnEnter: activeOnEnter,
            activeOnExit: activeOnExit,
            distortion: 100 - values?.distortion,
        };

        save(alarmId, data).then((_) => {
            historyPushAlarmsZones();
        });
    };

    const save = async (id, data) => {
        try {
            let row = data;

            if (id > -1) {
                dataApi.patchGeoAlarm(row, id);
            } else {
                dataApi.postGeoAlarm(row);
            }

            //noinspection JSUnresolvedFunction
            form?.resetFields();
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };
    console.log("schedule alarm view ",schedule);

    const changeScheduleParam = (dayOfWeek, fields) => {
        console.log(fields);
        console.log(schedule);
        const r = {};
        dayOfWeek = dayOfWeek - 1;
        r[dayOfWeek] = fields;
        // r = { sunday: {start: "13:00", stop: "17:00"}}
        // mock2[selectedId][dayOfWeek][param] = value

        let targetSchedule = schedule;

        console.log(targetSchedule[dayOfWeek]);
        console.log(targetSchedule);

        targetSchedule[dayOfWeek] = {
            ...targetSchedule[dayOfWeek],
            ...fields,
        };

        console.log(targetSchedule);
        setSchedule(targetSchedule);
    };

    function getZones(value) {
        const zone = knownZones?.find((e) => e.zoneId.toString() === value.key);
        const coords = {
            latitude: zone?.latitude,
            longitude: zone?.longitude,
        };
        setCenterCoords(coords);
        setPlace(zone?.address);
    }

    const enterOrExit = formValues?.activeOn?.includes(SUPER_DEVICES_KEYS_AND_INDEXES.activeOnExit) ? "utanför" : "inom"
    return loading ? (
        <Loading/>
    ) : (
        <>
            <Title>{STATIC_TEXT.ALARM_PLACES}</Title>
            <Form form={props.form}  layout={"vertical"} onFinish={onFinish} initialValues={alarm}
                  onValuesChange={(v, allValues) => setFormValues(allValues)}>
                <div style={stylesMap.mapAndDetailsView}>
                    <Title level={3}>{STATIC_TEXT.WHERE}</Title>
                    <Alert message={STATIC_TEXT.GEO_LARM_MESSAGE_INFO} type="success"/>
                    <br/>
                    <Flex justifyContent={"between"} gap={25}>


                        <Flex flexDirection={"column"} style={{width: "60%"}} gap={25}>
                            <Form.Item noStyle name={SUPER_DEVICES_KEYS_AND_INDEXES.place} label={STATIC_TEXT.ADDRESS}
                                       layout={"horizontal"}>
                                <div>
                                    <Input.Search
                                        id={"places-ref"}
                                        value={place}
                                        allowClear={true}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            // setTextAddress(e.target.value)
                                            setPlace(e.target.value);
                                        }}
                                        type={"search"}
                                        placeholder={"Sök efter adress"}
                                    />
                                </div>
                            </Form.Item>
                            <MapView
                                bounds={bounds}
                                alarm={alarm}
                                setAddress={setPlace}
                                address={place}
                                polygons={polygons}
                                setPolygons={setPolygons}
                                centerCoords={centerCoords}
                                setCenterCoords={setCenterCoords}
                            />

                        </Flex>
                        <DetailsView
                            knownOrganizations={knownOrganizations}
                            zones={knownZones}
                            getZones={getZones}
                            alarm={alarm}
                            setAddress={setPlace}
                            address={place}
                            form={form}
                            showRepeatAlarm={showRepeatAlarm}
                            setShowRepeatAlarm={setShowRepeatAlarm}
                            centerCoords={centerCoords}
                            setCenterCoords={setCenterCoords}
                            setSelectedOrganization={(r) => setSelectedOrganization(r)}
                            isTracker={isTracker}
                            setIsTracker={setIsTracker}
                        />
                    </Flex>
                </div>
                {isTracker && <Alert showIcon message={`Systemet kommer att meddela användaren om enheten befinner sig ${enterOrExit} angivet område vid rapporteringstillfället.`} type="info" style={stylesMap.schemaView}/>}
                {true && <SchemaView changeScheduleParam={changeScheduleParam} schedule={schedule} isTracker={isTracker}/>}
                <div style={stylesMap.mapAndDetailsView}>
                    <Flex justifyContent={"between"} gap={25}>
                        <TargetVehiclesView
                            checkedKeys={checkedKeys}
                            setCheckedKeys={setCheckedKeys}
                            knownVehicles={knownVehicles}
                            knownOrganizations={knownOrganizations}
                            isGeoAlarm={true}
                            selectedOrganization={selectedOrganization}
                            isTracker={isTracker}
                        />
                        <EmailsView emailData={emailData} setEmailData={setEmailData}/>
                    </Flex>
                </div>

                <Flex alignItems={"end"} justifyContent={"end"} gap={15}>
                    <Form.Item>
                        <Button onClick={historyPushAlarmsZones}>{STATIC_TEXT.BTN_ABORT}</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={checkedKeys?.length < 1} type="primary" htmlType="submit">
                            {STATIC_TEXT.SAVE}
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </>
    );
}

const GeoAlarmDetailsController = () => {
    return (
        <>
            <DetailsViewFormGeoAlarm/>
        </>
    );
};

export function SchemaView(props) {
    return (
        <div style={stylesMap.schemaView}>
            {props.title && <Title level={3}>{STATIC_TEXT.WHEN}</Title>}
            <ScheduleTable2 ifDefaultSchedule={false} schedule={props.schedule}
                           changeScheduleParam={props.changeScheduleParam} alarmSchedule={true} isTracker={props.isTracker}/>
        </div>
    );
}

export default GeoAlarmDetailsController;

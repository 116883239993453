import React, {useState} from 'react'
import {Button, Input, Typography} from 'antd';
import * as icons from '@ant-design/icons';
import {OrganizationsTable} from "./OrganizationsTable";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";

const OrganizationsController=()=>{
    const [searchTerm, setSearchTerm] = useState("");
    const [editingKey, setEditingKey] = useState('');
    const [visible, setVisible] = useState(false);
    const [isNewRecordForm, setIsNewRecordForm] = useState(false);

    const handleChangeSearchInput = event => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return(
        <>
        <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20}}>
        <Typography.Title>{STATIC_TEXT.SUPERADMIN} - {STATIC_TEXT.ORGANIZATIONS}</Typography.Title>
            <div style={{display: 'flex', gap: 15}}>
            <Input.Search
                type="text"
                placeholder={STATIC_TEXT.SEARCH_ORG}
                defaultValue={searchTerm}
                enterButton
                onChange={handleChangeSearchInput}
                style={{ width: 300 }}
            />
            <Button
                type={'primary'}
                icon={<icons.PlusOutlined />}
                onClick={() => {
                    setIsNewRecordForm(true)
                    setVisible(true);
                    setEditingKey('')
                }}
            >
                Lägg till ny organisation
            </Button>
        </div>
        </div>
            <OrganizationsTable
                searchTerm={searchTerm}
                visible={visible}
                editingKey={editingKey}
                setEditingKey={setEditingKey}
                setVisible={setVisible}
                isNewRecordForm={isNewRecordForm}
                setNewRecord={setIsNewRecordForm}
            />
        </>
    )
}

export default OrganizationsController

import React, {useContext, useEffect, useState} from "react";
import {Button, Checkbox, DatePicker, Input, Menu, Modal, Table, Tabs, Tooltip, TreeSelect, Typography} from "antd";
import {BellOutlined, CommentOutlined, DownloadOutlined, EnvironmentOutlined, EyeOutlined} from "@ant-design/icons";
import {convertTimestampToDateStr} from "../../../utilities";
import {SEARCH_TERM} from "../../../services/redux/constants/authConstants";
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import {useHistory} from "react-router-dom";

import {
    OrganizationSimpleContext,
    SelectDefaultVehicle,
    shouldHideUsersOrganizationsColumn,
    UserListSimpleContext,
} from "../../../services/contexts/contexts";
import Flex from "../../shared-components/Flex/Flex";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {dataApi} from "../../../services/api/api";
import moment from "moment";
import {TabBadge} from "../Superdevices/reusableComponents";
import {
    ALARM_NAMES_FUNCTION,
    ALARM_NAMES_GEO,
    ALARM_NAMES_QUALITY,
    ALARM_NAMES_SECURITY,
    ALARM_TYPES
} from "services/redux/constants/alarmConstants";
import {ModalWithAlarmsDetails} from "./ModalWithAlarmsDetails";
import {AlarmsEventsListContext} from "../../../services/contexts/alarmsEventsListContext";
import {useTranslation} from "react-i18next";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {VehiclesSelect} from "../shared-components/VehiclesSelect";
import {UsersSelect} from "../shared-components/UsersSelect";
import {ButtonMenuDropdown, tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";
import Loading from "../../shared-components/Loading";

const {Title} = Typography;
const {TabPane} = Tabs;

const AlarmsEventsController = () => {
    const [filteredAlarmData, setFilteredAlarmData] = useState();
    const [filteredUnacknowledgedAlarm, setFilteredUnacknowledgedAlarm] = useState();
    const [filteredAcknowledgedAlarm, setFilteredAcknowledgedAlarm] = useState();
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [showOnlyOngoingAlarms, setShowOnlyOngoingAlarms] = useState(false);
    const [showModalForAlarmGeo, setShowModalForAlarmGeo] = useState(false);
    const [showModalForAcknowledgingAlarms, setShowModalForAcknowledgingAlarms] = useState(false);
    const [record, setRecord] = useState();
    const [showAlarmCommentModal, setShowAlarmCommentModal] = useState(false);
    const {push} = useHistory();
    const {
        alarmData,
        selectedOrganization,
        selectedVehicles,
        setSelectedVehicles,
        selectedDrivers,
        selectedAlarmTypes,
        selectedAlarmSubTypes,
        selectedAlarmTypesAndSubTypes,
        knownVehicles,
        knownAlarmTypes,
        organizationSelectOnChange,
        vehiclesSelectOnChange,
        driversSelectOnChange,
        alarmTypesAndSubTypesSelectOnChange,
        onChangeDate,
        defaultPickerValue,
        loading,
        setLoading,
        fetchAlarms,
    } = useContext(AlarmsEventsListContext);
    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)
    const knownUsers = useContext(UserListSimpleContext);
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);
    const {t} = useTranslation();

    useEffect(() => {
        console.log("this is the selected vehicle", selectedVehicles);
    }, [selectedVehicles]);
    useEffect(() => {
        console.log("this is the selected vehicle id", selectedVehicleIds);
    }, [selectedVehicleIds]);

    useEffect(() => {
        filterAlarmData();
    }, [alarmData]);

    useEffect(() => {
        let filteredList = filteredAlarmData?.filter((e) => e?.acknowledged === false);
        setFilteredUnacknowledgedAlarm(filteredList);
        setLoading(false);
    }, [filteredAlarmData]);

    useEffect(() => {
        let filteredList = filteredAlarmData?.filter((e) => e?.acknowledged === true);
        setFilteredAcknowledgedAlarm(filteredList);
        setLoading(false);
    }, [filteredAlarmData]);

    useEffect(() => {
    }, [filteredAcknowledgedAlarm, filteredUnacknowledgedAlarm]);

    useEffect(() => {
        filterAlarmData()
    }, [showOnlyOngoingAlarms]);

    function filterAlarmData() {
        let filteredList = alarmData;

        if (selectedOrganization?.length > 0) {
            filteredList = filteredList?.filter((i) => selectedOrganization?.includes(i.organizationId));
        }
        if (selectedVehicles?.length > 0) {
            filteredList = filteredList?.filter((i) => selectedVehicles?.includes(i.vehicleId));
        }
        if (selectedDrivers?.length > 0) {
            filteredList = filteredList?.filter((i) => selectedDrivers?.includes(i.driverId));
        }
        if (selectedAlarmTypes?.length > 0 || selectedAlarmSubTypes?.length > 0) {
            filteredList = filteredList?.filter((i) => selectedAlarmTypes?.includes(i.alarmType) || selectedAlarmSubTypes?.includes(i.alarmName));
        }
        if (showOnlyOngoingAlarms) {
            filteredList = filteredList?.filter((i) => i.timestampStopMs === null);
        }
        setFilteredAlarmData(filteredList);
    }

    const ModalForAcknowledgingAlarms = ({showModalForAcknowledgingAlarms, record, handleAcknowledged}) => {
        const [alarmComment, setAlarmComment] = useState();

        function onChangeComment(e) {
            setAlarmComment(e.target.value);
        }

        function onAcknowledgingModalCancel() {
            setShowModalForAcknowledgingAlarms(false);
            setAlarmComment(false);
        }


        function onOkModal() {
            setAlarmComment(false);
            handleAcknowledged(record, alarmComment)
        }

        return (
            <Modal
                title="Kvittera larm"
                visible={showModalForAcknowledgingAlarms}
                onOk={() => onOkModal(record, alarmComment)}
                onCancel={() => onAcknowledgingModalCancel()}
                okText="Kvittera"
                cancelText="Avbryt"
                width={500}
            >
                <p>Här kan du välja att lägga till en egen notering om larmet (max 40 tecken).</p>
                <Input style={{marginTop: "0.25em"}} value={alarmComment ? alarmComment : null}
                       onChange={(e) => onChangeComment(e)} placeholder={"Egen kommentar"} allowClear maxLength={40}/>
            </Modal>
        );
    };

    const AlarmCommentModal = ({showAlarmCommentModal, record}) => {

        return (
            <Modal
                visible={showAlarmCommentModal}
                icon={null}
                title="Kvitteringskommentar"
                onOk={() => setShowAlarmCommentModal(false)}
                onCancel={() => setShowAlarmCommentModal(false)}
                footer={null}
                width={350}
            >
                <div>
                    <p>{record?.comment}</p>
                </div>
            </Modal>
        );
    };

    function redirectToView(record, url) {
        const value = record?.geoZoneAlarmId;
        const vehicle = knownVehicles.find((e) => e.id === record?.vehicleId);
        localStorage.setItem(SEARCH_TERM, vehicle.registry);
        push({
            pathname: `${APP_PREFIX_PATH}/` + url,
            state: {
                searchTerm: vehicle.registry,
                id: value,
            },
        });
    }

    function redirectToViewVehicleId(record, view, showArchived) {
        const value = record?.vehicleId;
        let qs;
        qs = showArchived ? `&&showArchived=${showArchived}` : ""
        push(`${APP_PREFIX_PATH}/${view}?vehicleId=${value}` + qs);
    }


    function switchShowModalForAlarmGeo(record) {
        setShowModalForAlarmGeo(!showModalForAlarmGeo);
        setRecord(record);
    }

    function handleAcknowledgeClick(record) {
        setRecord(record);
        setShowModalForAcknowledgingAlarms(!showModalForAcknowledgingAlarms);
    }

    function handleShowCommentModal(record) {
        setRecord(record);
        setShowAlarmCommentModal(!showAlarmCommentModal);
    }

    const geozoneNames = filteredAlarmData?.map((item) => {
        return {
            text: item.geoZoneAlarmName,
            value: item.geoZoneAlarmName,
        };
    });

    let filterDataAlias = geozoneNames?.filter((ele, ind) => ind === geozoneNames.findIndex((elem) => elem.value === ele.value));

    const handleAcknowledged = (item, comment) => {
        const data = {acknowledged: true, comment: comment};
        dataApi.patchAlarmPeriod(data, item.activeAlarmPeriodId).then((res) => {
            setShowModalForAcknowledgingAlarms(false);
            const newDataUnacknowledged = filteredUnacknowledgedAlarm?.filter((alarm) => alarm.activeAlarmPeriodId !== item.activeAlarmPeriodId);
            setFilteredUnacknowledgedAlarm(newDataUnacknowledged);

            const newDataAcknowledged = filteredAcknowledgedAlarm ? [...filteredAcknowledgedAlarm] : [];
            item = {
                ...item,
                acknowledged: res.acknowledged,
                comment: res.comment,
                acknowledgedBy: res.acknowledgedBy,
                acknowledgedTimestampMs: res.acknowledgedTimestampMs
            };
            newDataAcknowledged?.push(item);
            setFilteredAcknowledgedAlarm(newDataAcknowledged.sort((a, b) => b.activeAlarmPeriodId - a.activeAlarmPeriodId));
        });
    };


    let columns = [
        {
            title: STATIC_TEXT.ORGANIZATION,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.organizationId,
            hidden: hideUsersOrg,
            render: (text) => {
                const organization = knownOrganizations?.find((i) => i.organizationId === text);
                return organization?.fullName;
            },
        },
        {
            title: STATIC_TEXT.ALARM_TYPE,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.alarmType,
            render: (text) => {
                return ALARM_TYPES[text];
            },
        },
        {
            title: STATIC_TEXT.ALARM_NAME,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.alarmName,
            render: (text, record) => {
                switch (record.alarmType) {
                    case "SECURITY":
                        return ALARM_NAMES_SECURITY[text];
                    case "GEO":
                        return ALARM_NAMES_GEO[text];
                    case "FUNCTION":
                        return ALARM_NAMES_FUNCTION[text];
                    case "QUALITY":
                        return ALARM_NAMES_QUALITY[text];
                    default:
                        return "Okänd larmtyp";
                }
            },
        },
        {
            title: t(STATIC_TEXT.ALIAS),
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.geoZoneAlarmName,
        },
        {
            title: "Fordon/Utrustning",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.vehicle,
            render: (text) => {
                const vehicle = knownVehicles?.find((i) => i.id === text?.id);
                const registry = vehicle?.registry;
                const displayName = vehicle?.displayName ? ` (${vehicle.displayName})` : null;
                if (registry && displayName) {
                    return registry + displayName;
                } else if (!registry) {
                    return vehicle?.displayName
                } else if (!displayName) {
                    return registry
                } else {
                    return vehicle?.vehicleId
                }
            },
        },
        {
            title: STATIC_TEXT.TIMEPOINT,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.vehicle,
            render: (text, record) => {
                const start = convertTimestampToDateStr(record.timestampStartMs, "ddd, D MMM");
                const startTooltip = convertTimestampToDateStr(record.timestampStartMs, "ddd, D MMM YYYY HH:mm");
                const stop = record.timestampStopMs ? convertTimestampToDateStr(record.timestampStopMs, "ddd, D MMM ") : STATIC_TEXT.ONGOING;
                const stopTooltip = record.timestampStopMs ? convertTimestampToDateStr(record.timestampStopMs, "ddd, D MMM YYYY HH:mm") : STATIC_TEXT.ONGOING;

                if (record.timestampStartMs === record.timestampStopMs) return <span>{start}</span>;

                return (
                    <Tooltip title={startTooltip + " - " + (stopTooltip)}>
                        <span>{start} - </span>
                        {stop ? <span>{stop}</span> :
                            <span style={{color: SD_BASE_COLORS.YELLOW}}>{STATIC_TEXT.ONGOING}</span>}
                    </Tooltip>
                );
            },
        },
        {
            title: STATIC_TEXT.ACKNOWLEDGED,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.acknowledged,
            width: 180,
            render: (text, record) => {
                let user = knownUsers?.find((i) => i.userId === record?.acknowledgedBy);

                if (text) {
                    return (
                        <>
                            <p>
                                {convertTimestampToDateStr(record?.acknowledgedTimestampMs, "D MMM HH:mm")}
                                <br/>
                                {user?.displayName}
                            </p>
                            {record?.comment ? <CommentOutlined style={{fontSize: "1.5em", color: SD_BASE_COLORS.BLUE}}
                                                                onClick={() => handleShowCommentModal(record)}/> : null}
                        </>
                    );
                } else {
                    return (
                        <Flex justifyContent={"between"}>
                            <Button type={"primary"} onClick={() => handleAcknowledged(record, null)}>
                                Kvittera
                            </Button>
                            <Button icon={<CommentOutlined/>}
                                    onClick={() => handleAcknowledgeClick(record)}>
                            </Button>
                        </Flex>
                    );
                }
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    function menuDropdown(record) {
        const vehicle = knownVehicles?.find(item => item?.id === record?.vehicleId)
        return <Menu>
            <Menu.Item icon={<BellOutlined/>}
                       onClick={() => redirectToView(record, alarmDetailsUri(record))}>{STATIC_TEXT.SHOW_LARM} </Menu.Item>
            {!vehicle?.isArchived && <Menu.Item danger={false} icon={<EnvironmentOutlined/>}
                        onClick={() => redirectToViewVehicleId(record, "map")}>Visa på karta</Menu.Item>
            }            <Menu.Item danger={false} icon={<EyeOutlined/>}
                       onClick={() => switchShowModalForAlarmGeo(record)}>{STATIC_TEXT.SHOW_WHERE_ALARM_TRIGGERED} </Menu.Item>
        </Menu>
    }


    const alarmDetailsUri = (record) => {
        let uri = record.alarmType === "GEO" ? "geoLarmDetails/" : "alarmEditor/?alarmType=" + record.alarmType + "&id=";
        return uri + record.geoZoneAlarmId;
    };

    const exportReport = () => {
        let tz = moment.tz.guess();
        let vehiclesToShow = "_";
        let zoneId = tz;

        selectedVehicles?.map((i) => {
            const vehicle = knownVehicles?.find((v) => v.id === i);
            const vehicleName = vehicle?.registry || vehicle?.displayName;
            vehiclesToShow = vehiclesToShow + vehicleName + "_";
        });

        dataApi
            .getAlarmEventsReport(
                defaultPickerValue[0].unix(),
                defaultPickerValue[1].unix(),
                selectedDrivers,
                selectedVehicles,
                selectedOrganization,
                zoneId,
                selectedAlarmTypes,
                selectedAlarmSubTypes
            )
            .then((e) => {
                console.log(e);
            });
    };

    const handleShowAlarms = () => {
        fetchAlarms();
    };

    return loading ? null : (
        <>
            <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
                <Title>{STATIC_TEXT.TRIGGERED_LARM}</Title>
                <Button icon={<DownloadOutlined/>} onClick={exportReport}>
                    Exportera
                </Button>
            </div>

            <Flex flexDirection={"column"} style={{marginBottom: 10,}}>
                <Flex style={{gap: 10, flexWrap: "wrap"}}>

                    {knownOrganizations?.length < 2 ? null : (
                        <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                 selectedOrganization={selectedOrganization} isMultiple={true}/>
                    )}

                    <VehiclesSelect
                        multiple={'multiple'}
                        data={knownVehicles}
                        onChange={vehiclesSelectOnChange}
                        placeholder={"Filtrera efter fordon eller utrustning"}
                        value={selectedVehicles}/>

                    <UsersSelect
                        multiple={'multiple'}
                        data={knownUsers}
                        value={selectedDrivers}
                        onChange={driversSelectOnChange}
                    />


                    <div>
                        <TreeSelect
                            multiple
                            allowClear
                            showSearch
                            maxTagCount={1}
                            style={{minWidth: 200}}
                            dropdownStyle={{minWidth: 400}}
                            placeholder={"Filtrera efter larmtyp"}
                            treeData={knownAlarmTypes}
                            treeDefaultExpandAll
                            value={selectedAlarmTypesAndSubTypes}
                            onChange={alarmTypesAndSubTypesSelectOnChange}
                        ></TreeSelect>
                    </div>
                    <div>
                        <DatePicker.RangePicker
                            dropdownClassName={"one-month"}
                            onChange={(dates) => onChangeDate(dates)}
                            defaultValue={defaultPickerValue}
                            format="YYYY-MM-DD"
                            allowClear={false}
                            defaultPickerValue={defaultPickerValue}
                        />
                    </div>


                    <Button type="primary" onClick={handleShowAlarms}>
                        {STATIC_TEXT.BTN_SEARCH}
                    </Button>

                </Flex>
                <Checkbox style={{marginTop: "18px"}} checked={showOnlyOngoingAlarms}
                          onChange={(e) => setShowOnlyOngoingAlarms(e.target.checked)}>
                    Visa endast pågående larm för vald period
                </Checkbox>
            </Flex>

            <ModalWithAlarmsDetails setShowModalForAlarmGeo={setShowModalForAlarmGeo}
                                    showModalForAlarmGeo={showModalForAlarmGeo} record={record}/>

            <ModalForAcknowledgingAlarms setShowModalForAcknowledgingAlarms={setShowModalForAcknowledgingAlarms}
                                         showModalForAcknowledgingAlarms={showModalForAcknowledgingAlarms}
                                         record={record} handleAcknowledged={handleAcknowledged}/>

            <AlarmCommentModal setShowAlarmCommentModal={setShowAlarmCommentModal}
                               showAlarmCommentModal={showAlarmCommentModal} record={record}/>

            {!filteredUnacknowledgedAlarm ? <Loading/> :
                <Tabs defaultActiveKey="1">
                    <TabPane tab={<TabBadge text={STATIC_TEXT.ACTUALLY} overflowCount={999}
                                            count={filteredUnacknowledgedAlarm?.length}/>} key="1">
                        <Table dataSource={filteredUnacknowledgedAlarm} columns={tableColumnsWithTooltip(columns)}
                               scroll={{x: 1000}}
                               {...tableProps}/>
                    </TabPane>
                    <TabPane tab={<TabBadge text={STATIC_TEXT.LOG} overflowCount={999}
                                            count={filteredAcknowledgedAlarm?.length}/>} key="2">
                        <Table dataSource={filteredAcknowledgedAlarm} columns={tableColumnsWithTooltip(columns)}
                               scroll={{x: 1000}}
                               {...tableProps}/>
                    </TabPane>
                </Tabs>
            }        </>
    );
};
export default AlarmsEventsController;

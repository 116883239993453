import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Trackson';
export const GOOGLE_MAPS_API = 'AIzaSyCbkUCf4MxGQaKOo92M-0I7yQx9QKAuG_g';
export const BOOTSTRAP_URL_KEYS = {key: GOOGLE_MAPS_API, libraries: 'places' }
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const ENABLE_API_MOCK = false

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'dark',
	locale: 'sv',
	darkMapStyle: true
};

export const EXCLUDED_ORG_IDS = [41465,41186,30714,45768, 23033]
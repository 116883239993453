import {Nest} from "../utils/utilities";
import Guides from "../components/trackson-components/Guides";

const GuidesPage = () => {
    return (
        <Nest
            wrappers={[
            ]}>
            <Guides/>
        </Nest>
    );
};

export default GuidesPage;

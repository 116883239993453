import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import {AUTH_TOKEN, ORGANIZATION_ID, SHOW_ARCHIVED} from 'services/redux/constants/authConstants'
import { notification, message } from 'antd';
import {httpErrorMessages} from "../constants/httpErrorMessages";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'Authorization'
const PUBLIC_REQUEST_KEY = 'public-request'
const X_TRACKSON_ORGANIZATION = 'X-Trackson-Organization'

// API Request interceptor
service.interceptors.request.use(config => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN)
  const showArchived = localStorage.getItem(SHOW_ARCHIVED)
  let organizationId = localStorage.getItem(ORGANIZATION_ID)

  if(organizationId?.toString() === "undefined"){
    localStorage.removeItem(ORGANIZATION_ID)
    organizationId = null
  }

  if(organizationId && jwtToken){
    config.headers[X_TRACKSON_ORGANIZATION] =  organizationId
    config.headers[TOKEN_PAYLOAD_KEY] = 'JWT ' + jwtToken
  }

  if (showArchived) {
    config.headers[SHOW_ARCHIVED] = showArchived
  }

  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = 'JWT ' + jwtToken
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
    history.push(ENTRY_ROUTE)
    window.location.reload();
  }

  return config
}, error => {
  // Do something with request error here
  notification.error({
    message: 'Error'
  })
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
  return response.data
}, (error) => {

  let translatedMessage = httpErrorMessages[error?.response?.data]
  console.log('Failed:', translatedMessage);
  if(translatedMessage === "undefined" || !translatedMessage){
    translatedMessage = httpErrorMessages.SOMETHING_GONE_WRONG
  }

  let notificationParam = {
    message: ''
  }

  // Remove token and redirect
  if(error && error.response) {


    console.log(error.response.status, 401)

    if (error.response.status !== 500 && (error.response.status !== 401)){
    notificationParam.message = translatedMessage
    notification.error(notificationParam)
    }


    if (error.response.status === 400) {
      notificationParam.message = '400'
      notificationParam.description = 'Request failed with status code 400'
      console.log('Error 400', error)
    }

    // if (error.response.status === 401 || error.response.status === 403) {
    if (error.response.status === 401) {
      notificationParam.message = 'Autentisering misslyckades'
      notificationParam.description = 'Logga in igen'
      localStorage.removeItem(AUTH_TOKEN)
      history.push(ENTRY_ROUTE)
    }

    if (error.response.status === 403) {
      notificationParam.message = 'Forbidden'
      notificationParam.description = 'Requires clearance'
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found'
     // notification.error(notificationParam)
    }


    if (error.response.status === 415) {
      notificationParam.message = '415'
      notificationParam.description = 'Object is malformed'
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out'
      notification.error(notificationParam)
    }

    //notification.error(notificationParam)
  }

  return Promise.reject(error);
});

export default service

import React, {useState} from 'react';
import { Button, InputNumber, Form, Input, Modal, Row, Divider } from 'antd';
import { BatteryIcon } from './superDeviceIcons';
import {
  TwoRowsTitleText,
  TitleTop,
  TitleSpanned,
  TwoRowsIcon,
  ObdDeterIcon,
  CarNumberPlateIcon,
} from './reusableComponents';
import Col from 'antd/es/grid/col';
import { LastSeenLink } from '../shared-components/DatumLink';
import {SUPER_DEVICES_MODAL_TITLES, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {OdometerModal} from "../shared-components/sharedComponents";

// VISA ENHET
const ModalFormDeviceDisplay = ({ visible, handleAdd, handleClose, selectedDevice }) => {
  // const prevVisibleRef = useRef();

  const [odometerIsVisible, setOdometerIsVisible] = useState(false)
  // DEVICE
  const imei = selectedDevice.device.imei ? selectedDevice.device.imei : '-';
  const iccid = selectedDevice.device.iccid ? selectedDevice.device.iccid : '-';
  const organizationId = selectedDevice?.device?.organizationId?.fullName
    ? selectedDevice.device.organizationId.fullName
    : '-';

  const phone = selectedDevice.device.phone ? selectedDevice.device.phone : '-';
  let batteryLevel = '-';
  let heartBeat = '-';

  if (selectedDevice.device.status !== null) {
    batteryLevel = selectedDevice.device.status.latestBatteryLevel
      ? selectedDevice.device.status.latestBatteryLevel
      : '-';
    heartBeat = selectedDevice.device.status.timestampMs ? (
      <LastSeenLink value={selectedDevice.device.status.timestampMs} />
    ) : (
      '-'
    );
    //latestSeenPositiveSpeed
    //uplinkLoss
  }
  //physicalInstallTimestamp
  //physicalLocation

  //VEHICLE
  const odometer = selectedDevice?.device?.installedVehicle?.state
  let mileAge = selectedDevice?.device?.installedVehicle?.state?.odometer
    ? selectedDevice.device.installedVehicle.state.odometer + ' km'
    : '-';

  const carRegnr = selectedDevice?.device?.installedVehicle?.registry ? (
    selectedDevice.device.installedVehicle.registry.toUpperCase()
  ) : (
  '-'
  );

  const config = selectedDevice?.device?.configName ? selectedDevice.device.configName : '-';
  const firmware = selectedDevice?.device?.firmware ? selectedDevice.device.firmware : '-';
  const obd = selectedDevice?.device?.obd ? selectedDevice.device.obd : '-';
  const vehicle = selectedDevice.device.installedVehicle?.id ? selectedDevice.device?.installedVehicle : '-';
  const displayName = selectedDevice.device.installedVehicle?.displayName ? selectedDevice.device.installedVehicle?.displayName : '-';

  const driver = selectedDevice.device.installedVehicle
    ? selectedDevice.device.installedVehicle.displayName
    : '-';
  const dataBlock = selectedDevice.device.dataUsed ? selectedDevice.device.dataUsed : '-';

  const onOk = () => {
    handleClose();
  };

  const onFinish = (values) => {
    // console.log(values)
    //handleAdd(values);
  };

  const updateOdometer=(values, key, newOdometer)=>{
   mileAge = newOdometer
  }

    const handleOdometerVisible=()=>{
    setOdometerIsVisible(!odometerIsVisible)
  }

  console.log('Seldevice', selectedDevice);

  return (
    <Modal
      title={SUPER_DEVICES_MODAL_TITLES.DISPLAY_UNIT}
      visible={visible}
      onOk={onOk}
      onCancel={() => handleClose()}
    >
      <TitleTop level={3} title={vehicle.registry} />

      <Divider dashed />

      <Row gutter={[0, 20]}>
        <TwoRowsTitleText span={20} title={STATIC_TEXT.CONFIG} text={config} />
        <TwoRowsTitleText span={8} title={STATIC_TEXT.IMEI} text={imei} />
        <TwoRowsTitleText span={8} title={STATIC_TEXT.FIRMWARE} text={firmware} />
      </Row>

      <Row gutter={[0, 20]}>
        <TwoRowsTitleText span={8} title={STATIC_TEXT.ICCID} text={iccid} />
        <TwoRowsTitleText span={8} title={STATIC_TEXT.PHONE_NUMBER} text={phone} />
        <TwoRowsTitleText span={8} title={STATIC_TEXT.DATA_BLOCK} text={dataBlock} />
      </Row>

      <Row gutter={[0, 20]}>
        <TwoRowsTitleText span={8} title={STATIC_TEXT.ORG} text={organizationId} />
        <TwoRowsTitleText span={8} title={STATIC_TEXT.DRIVER} text={driver} />
        <TwoRowsTitleText
          span={8}
          title={STATIC_TEXT.REG_NUM_SHORT}
          text={carRegnr + " " + displayName}
        />
      </Row>

      <Row gutter={[0, 0]}>
        {/* IC = ICON NEEDED */}
        <TwoRowsIcon
          span={8}
          title={STATIC_TEXT.HEART_BEAT}
          icon={heartBeat ? heartBeat : null}
        />
        <TwoRowsIcon
          span={8}
          title={STATIC_TEXT.BATTERY}
          icon={
            batteryLevel ? (
              <BatteryIcon batteryLevel={batteryLevel} />
            ) : (
              <BatteryIcon batteryLevel={50} />
            )
          }
        />
        <TwoRowsIcon
          span={8}
          title={STATIC_TEXT.OBD}
          icon={obd ? <ObdDeterIcon obd={true} /> : <ObdDeterIcon obd={false} />}
        />
      </Row>

      <Divider dashed />

      <Row gutter={[0, 10]} align={'middle'}>
        <TitleSpanned level={4} span={8} title={STATIC_TEXT.MILEAGE_FULL} />
      </Row>

      <Row gutter={[15, 0]}>
        {/* add inputbox? number?  */}
        <Col span={12}>
          <Form.Item style={{ minWidth: 300 }} name={['setmilage']} rules={[{}]}>
            <Input disabled={true} placeholder={mileAge} value={mileAge} />
            <OdometerModal onCancel={handleOdometerVisible} record={vehicle} visible={odometerIsVisible} updateOdometer={updateOdometer} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Button disabled={!vehicle?.id} type='secondary' onClick={handleOdometerVisible}>
            {STATIC_TEXT.BTN_CALIBRATE}{' '}
          </Button>
        </Col>
      </Row>

      {/*<Divider dashed />*/}

      {/*<Row gutter={[0, 20]} align={'middle'} justify={'left'}>*/}
      {/*  <Col span={4}>*/}
      {/*    <Row gutter={[0, 0]} align={'middle'} justify={'center'}>*/}
      {/*      <icons.WarningFilled style={{ fontSize: '250%', color: 'lightcoral' }} />*/}
      {/*    </Row>*/}
      {/*  </Col>*/}

      {/*  <Col span={20}>*/}
      {/*    <Row gutter={[0, 0]} align={'middle'} justify={'left'}>*/}
      {/*      <Title level={3}>*/}
      {/*        {String(SUPER_DEVICES_STATIC_TEXT.MOVE_TO_ANOTHER_VEHICLE).toUpperCase()}{' '}*/}
      {/*      </Title>*/}
      {/*    </Row>*/}

      {/*    <Row gutter={[0, 0]} align={'middle'} justify={'left'}>*/}
      {/*      <Text>{String(SUPER_DEVICES_STATIC_TEXT.WARN_WARN_WARN).toUpperCase()} </Text>*/}
      {/*    </Row>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Modal>
  );
};

export default ModalFormDeviceDisplay;

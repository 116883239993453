import React, {useEffect, useContext} from 'react';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import {SessionContextProvider} from "../contexts/SessionContext"
import {errorService} from "../services/ErrorService";
import {AppLocale} from "../lang";
import {useVersion} from "../configs/CacheBuster";
import 'dayjs/locale/sv';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location } = props;
  const currentAppLocale = AppLocale[locale];
    const { loading, isLatestVersion, refreshCacheAndReload } = useVersion();

    useEffect(() => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
        }
    }, [loading, isLatestVersion, refreshCacheAndReload])

    window.onerror = function(msg, src, lineno, colno, error) {
       if (msg === "Uncaught TypeError: Cannot read property 'map' of undefined"/* || "Script error."*/){
        errorService.redirectToErrorSite()
       }
        console.log("Error msg", msg)
    }

  return (
      <SessionContextProvider>
          <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}>
              <ConfigProvider locale={currentAppLocale.antd}>
                  <Switch>
                      <Route exact path="/">
                          <Redirect to={APP_PREFIX_PATH} />
                      </Route>
                      <Route path={AUTH_PREFIX_PATH}>
                          <AuthLayout />
                      </Route>
                      <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                          <AppLayout location={location} />
                      </RouteInterceptor>
                  </Switch>
              </ConfigProvider>
          </IntlProvider>
      </SessionContextProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } =  theme;
  const { token } = auth;
  return { locale, token }
};

export default withRouter(connect(mapStateToProps)(Views));

import { Nest } from "../../utils/utilities";
import {
    AuthorizationProvider,
    OrganizationSimpleProvider, SelectDefaultVehicle, shouldHideUsersOrganizationsColumn,
    UserListSimpleProvider,
    VehiclesComplexListProvider,
} from "../../services/contexts/contexts";
import AlarmsEventsController from "../../components/trackson-components/AlarmsEvents";
import {AlarmsContextProvider} from "../../services/contexts/AlarmsContext";
import {AlarmsEventsListProvider} from "../../services/contexts/alarmsEventsListContext";

const AlarmsEventsPage = () => (
    <Nest wrappers={[
        AuthorizationProvider,
        shouldHideUsersOrganizationsColumn.provider,
        OrganizationSimpleProvider,
        UserListSimpleProvider,
        VehiclesComplexListProvider,
        SelectDefaultVehicle.provider,
        AlarmsEventsListProvider,
        AlarmsContextProvider
    ]}>
        <AlarmsEventsController />
    </Nest>
);

export default AlarmsEventsPage;

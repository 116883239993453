import {UsersController} from '../components/trackson-components/userroles.js'
import {
    AuthorizationProvider,
    OrganizationSimpleProvider,
    shouldHideUsersOrganizationsColumn,
    UserListWithArchivedProvider,
    VehiclesComplexListProvider
} from "../services/contexts/contexts";
import {SchedulingViewProvider} from "../services/contexts/views-context/scheduling-context";
import {Nest} from "../utils/utilities";
import {UsersContextProvider} from "../services/contexts/UsersContext";

const UserListPage = () => (
    <Nest
        wrappers={[AuthorizationProvider, shouldHideUsersOrganizationsColumn.provider, OrganizationSimpleProvider, UserListWithArchivedProvider, VehiclesComplexListProvider, UsersContextProvider, SchedulingViewProvider]}>
        <UsersController/>
    </Nest>
)
export default UserListPage

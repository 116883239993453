import React, { createContext, useState, useEffect } from "react";
import { dataApi, userApi } from "services/api/api";
const UsersContext = createContext({});
const UsersContextProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const fetchUsers = async () => {
        dataApi
            .getUsers()
            .then((res) => {
                setUsers(res);
            })
            .catch((err) => console.log(err));
    };
    const createNewUser = async (data) => {
        console.log(data);
        dataApi
            .postNewUser(data)
            .then((res) => {
                console.log(res);
                return true;
            })
            .then((err) => {
                console.error(err);
                return false;
            });
    };
    const updateUser = async (userId, data) => {
        console.log(userId, data);
        dataApi
            .patchUser(userId, data)
            .then((res) => {
                console.log(res);
                return true;
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    };
    const deleteUser = async (userId) => {
        const userIndex = users.findIndex((i) => i.userId === userId);
        const selectedUserIndex = selectedUsers.findIndex((i) => i.key === userId);
        dataApi
            .deleteUser(userId)
            .then((res) => {
                console.log(res);
                if (selectedUserIndex > -1 && selectedUserIndex > -1) {
                    users.splice(userIndex, 1);
                    selectedUsers.splice(selectedUserIndex, 1);
                    return true;
                }
            })
            .catch((err) => {
                console.error(err);
                return false;
            });
    };
    const changeUserOrg = async (record, organizationId) => {
        await dataApi
            .patchUser(record.key, { organizationId: organizationId })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.error(err));
    };
    const getCurrentUser = async () => {
        await userApi
            .getCurrentUser()
            .then((res) => setCurrentUser(res))
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        fetchUsers();
        getCurrentUser();
    }, []);
    return <UsersContext.Provider value={{ users, selectedUsers, currentUser, setSelectedUsers, createNewUser, updateUser, deleteUser, changeUserOrg }}>{children}</UsersContext.Provider>;
};

export { UsersContext, UsersContextProvider };

import AlarmReport  from "components/trackson-components/AlarmReport";
import { ServicesContextProvider } from "services/contexts/ServicesContext";
const AlarmReportPage = () => {
    return (
        <ServicesContextProvider>
            <AlarmReport />
        </ServicesContextProvider>
    );
};

export default AlarmReportPage;

import React, {useContext} from "react";
import moment from "moment";
import {Button, DatePicker, Input, message, Typography} from "antd";
import {
    BellOutlined,
    CloseCircleOutlined,
    DownloadOutlined,
    EyeOutlined,
    FileDoneOutlined,
    LockOutlined,
    MinusCircleOutlined,
    UnlockFilled
} from "@ant-design/icons";
import {dataApi} from "../../../services/api/api";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {AuthorizationContext, OrganizationSimpleContext,} from "../../../services/contexts/contexts";
import "./styles.css";
import {EconomyReportContext} from "../../../services/contexts/SalaryReportsContext";
import {ModalWithTrips} from "./ModalTrips";
import {TableForOrganization} from "./TableForOrganization";
import {reportsTypesLookUp} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import Loading from "../../shared-components/Loading/Loading";

const SalaryReportController = () => {
    const {
        filteredData,
        handleChange,
        organizationId,
        organizationSelectOnChange,
        selectedPeriod,
        timestamp,
        handleChangeShowModal,
        selectedDriverReport,
        showModal,
        updateObject,
        organizationData,
        onChangeDatePicker,
        dateFormat,
        loading
    } = useContext(EconomyReportContext);

    const {organizationList: knownOrganizations} = useContext(OrganizationSimpleContext)
    const currentUser = useContext(AuthorizationContext);

    function getDriverReport(rr, drivers) {
        let tz = moment.tz.guess();
        let zoneId = tz;
        const tsd = moment().tz(tz).format("YYYY-MM-DD");
        const userName = rr?.fullName || "Alla förare";
        let vehiclesToShow = "_";
        let filename = tsd + vehiclesToShow + userName + "_";
        filename = filename + reportsTypesLookUp.SUMMARY + ".pdf";

        if (drivers?.length > 0) {
            drivers = drivers.map((e) => {
                return e.personId;
            });
        }

        dataApi.getSalaryReports(timestamp[0], timestamp[1], rr?.vehicle?.vehicleId, filename, zoneId, false, drivers || rr.personId, true);
    }

    const handleReviewClick = (record) => {
        handleChangeShowModal(record);
    };

    const handleRemindClick = (record) => {
        const splitPeriod = selectedPeriod?.split("/");
        remindDriver(splitPeriod, record);
    };

    function remindDriver(splitPeriod, record) {
        dataApi.putAttestReminder(splitPeriod[1], record).then(() => {
            message.success(`Meddelande skickat till ${record.fullName}`);
        });
    }

    const handleRejectClick = (record) => {
        const splitPeriod = selectedPeriod?.split("/");
        dataApi
            .putUnapprove("", splitPeriod[1], splitPeriod[0], record?.personId, record?.vehicle?.vehicleId, [])
            // should look like this http://127.0.0.1:8089/api2/attest/refuse?month=2&year=2022&zoneId=Europe/Stockholm&driverId=2304&vehicleId=2309&badTrips=
            .then(() => {
                const row = {
                    adminApproved: false,
                };
                updateObject(record?.personId, record?.vehicle?.vehicleId, row);
            });
    };
    console.log("date", moment().endOf("day"));

    function disabledDate(current) {
        return current && current > moment().subtract(1, "months").endOf("month");
    }

    const columns = [
        {
            title: "Förare",
            dataIndex: "fullName",
            key: "fullName",
            fixed: "left",
        },
        {
            title: "Fordon",
            dataIndex: "vehicle",
            key: "vehicle",
            fixed: "left",
            render: (v) => {
                return (v.reg || "") + (v.full_name ? " (" + v.full_name + ")" : "");
            },
        },
        {
            title: "Resor",
            dataIndex: "tripCount",
            key: "tripCount",
            width: 100,
            align: "right",
            render: (v) => {
                return v + " st";
            },
        },
        {
            title: "Privat",
            dataIndex: "tripDistancePrivate",
            key: "tripDistancePrivate",
            align: "right",
            width: 150,
            render: (r) => {
                return <span style={{color: SD_BASE_COLORS.GREEN}}>{r?.toFixed(2) + " km"}</span>;
            },
        },
        {
            title: "Tjänst",
            dataIndex: "tripDistanceBusiness",
            key: "tripDistanceBusiness",
            align: "right",
            width: 150,
            render: (r) => {
                return <span style={{color: SD_BASE_COLORS.BLUE}}>{r?.toFixed(2) + " km"}</span>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 150,
            render: (value, r) => {
                if (r.adminApproved) {
                    return (
                        <>
                            <FileDoneOutlined style={{color: SD_BASE_COLORS.GREEN}}/> Godkänd
                        </>
                    );
                } else if (r.refused && !r.attested) {
                    return (
                        <>
                            <CloseCircleOutlined style={{color: SD_BASE_COLORS.RED}}/> Nekad
                        </>
                    );
                } else if (r.attested) {
                    return (
                        <>
                            <LockOutlined style={{color: SD_BASE_COLORS.YELLOW}}/> Attesterad
                        </>
                    );
                } else if (!r.attested) {
                    return (
                        <>
                            <MinusCircleOutlined/> Ej attesterad
                        </>
                    );
                }
            },
        },
        {
            title: "Verktyg",
            dataIndex: "reviewOrRemind",
            key: "reviewOrRemind",
            align: "right",
            width: 150,
            render: (value, record) => {
                let rolesArray = currentUser?.roles;
                let isUserRoot = !!rolesArray?.includes("ROOT");

                return (
                    <>
                        {record.adminApproved && record.attested && isUserRoot && (
                            <Button
                                icon={<UnlockFilled/>}
                                onClick={() => handleRejectClick(record)}
                            >
                                Lås upp
                            </Button>
                        )}
                        {!record.adminApproved && record.attested && (
                            <Button icon={<EyeOutlined/>} onClick={() => handleReviewClick(record)}>
                                Granska{" "}
                            </Button>
                        )}
                        {!record.adminApproved && !record.attested && (
                            <Button icon={<BellOutlined/>} onClick={() => handleRemindClick(record)}>
                                Påminn
                            </Button>
                        )}
                    </>
                );
            },
        },
        {
            title: "Exportera",
            dataIndex: "isLocked",
            key: "isLocked",
            align: "right",
            width: 200,
            render: (r, rr) => {
                return (
                    <Button  icon={<DownloadOutlined/>} onClick={() => getDriverReport(rr)}>
                        Lönerapport
                    </Button>
                );
            },
        },
    ];

    return !filteredData && selectedPeriod ? <Loading/> : (
        <>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, flexWrap: "wrap"}}>
                <Typography.Title>Lönerapporter</Typography.Title>
                <div style={{display: "flex", gap: 15, alignItems: "center", flexWrap: "wrap"}}>
                    <DatePicker.MonthPicker
                        value={moment(selectedPeriod, dateFormat)}
                        format={dateFormat}
                        onChange={onChangeDatePicker}
                        disabledDate={disabledDate}
                        allowClear={false}
                        placeholder="Select month"
                    />

                    {knownOrganizations?.length < 2 ? null : (
                        <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                 selectedOrganization={organizationId} isMultiple={true}/>
                    )}
                    <Input.Search type="text" placeholder="Sök efter regnr, IMEI eller förare"
                                  label="Registreringsnummer" enterButton onChange={handleChange} style={{width: 300}}/>
                </div>
            </div>

            <ModalWithTrips
                visible={showModal}
                handleChangeShowModal={handleChangeShowModal}
                updateObject={updateObject}
                driverReport={selectedDriverReport}
                timestemp={timestamp}
                selectedPeriod={selectedPeriod}
            />

            { loading ? <Loading/> : organizationData?.map((org) => {
                return (
                    <TableForOrganization
                        getDriverReport={getDriverReport}
                        columns={columns}
                        filteredData={filteredData}
                        organization={org}
                        knownOrganizations={knownOrganizations}
                        showButton={true}
                    />
                );
            })}
        </>
    );
};


export default SalaryReportController;

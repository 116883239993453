import {message} from "antd";
import fetch from "auth/FetchInterceptor";
import MockAdapter from "axios-mock-adapter"; // Remove in production
import {API_BASE_URL, ENABLE_API_MOCK} from "configs/AppConfig";
import {httpErrorMessages} from "../../constants/httpErrorMessages";
import {authMock, journalMock, overviewMock} from "../mockData";

if (ENABLE_API_MOCK) {
    const mock = new MockAdapter(fetch, {
        delayResponse: 0,
    });
    mock.onGet("/overview").reply(200, overviewMock);
    mock.onGet("/journal").reply(200, journalMock);
    mock.onPut("/journal/144").reply(200);
    mock.onPut("/journal/155").reply(200);
    mock.onPost("/auth").reply(200, authMock);
}

class UserAPI {
    constructor() {
        this.instance = fetch;
    }

    getCurrentUser() {
        let req;
        return this.instance.get("/auth", req);
    }

    patchNewPassword(userId, data = {}) {
        const req = {
            password: data.password,
            oldPassword: data.oldPassword,
        };

        return this.instance
            .patch(`/user/${userId}`, req)
            .then((r) => {
                message.success("Lösenord ändrades");
            })
            .catch((z) => {
                const translatedMessage = httpErrorMessages[z.response.data];
                console.log("Failed:", translatedMessage);
                message.error(translatedMessage + " Försök igen");
            });
    }

    postRegister(data = {}) {
        const req = {
            full_name: data.fullName,
            title: data.title,
            account: {
                username: data.username,
                password_raw: data.password,
            },
            organization: {
                organization_id: 1,
            },
        };

        return this.instance.post(`/register`, req);
    }

    postResetPassword(data = {}) {
        const args = {
            url: "/reset-password",
            method: "post",
            headers: {
                "public-request": "true",
            },
            data: data,
        };

        return this.instance(args);
    }

    patchResetPassword(data = {}) {
        const args = {
            url: "auth/reset-password",
            method: "patch",
            headers: {
                "public-request": "true",
            },
            data: data,
        };

        return this.instance(args);
    }

    postContactTrackson(data = {}) {
        const req = data;

        return this.instance.post("/contact-trackson", req);
    }

    postReportTrackson(data = {}) {
        return this.instance.post("/report-error", data);
    }

    postReportTrackson2OldFunction(data = {}) {
        const req = {
            subject: data.subject,
            body: data.body,
            addrFrom: data.addrFrom,
        };

        let header = "Felanmälan Trackson\n";
        header += "Telefonnummer: " + data.phone + "\n";
        header += "E-post: " + data.addrFrom + "\n";

        req.body = header + req.body;

        return this.instance.post("/contact-trackson", req);
    }

    postValidatePasswordToken(token, data) {
        let qr = "?token=" + token;

        const args = {
            url: "auth/validate-password-token" + qr,
            method: "post",
            headers: {
                "public-request": "true",
            },
            data: data,
        };

        return this.instance(args);

    }
}

class DataAPI {
    constructor() {
        this.instance = fetch;
    }

    getVehicleInfo(regNo) {
        return this.instance.get("/vehicleinfo/fetch?regNo=" + regNo);
    }
    patchTrackerUpdateFrequency(id, value) {
        let val = value.split("-");
        return this.instance.patch(`/vehicles/${id}/response-time?period=${val[0]}&unit=${val[1]}`);
    }

    getTrackersReport(trackers, filenameDescription = "") {
        return this.instance
            .get("/report/trackers?vehicleIds=" + trackers?.toString())
            .then((r) => {
                const a = document.createElement("a");
                a.href = "data:application/pdf;base64," + r;
                a.download = `trackerrapport_${filenameDescription}.pdf`; //'trackerrapport_<organisation_name>.pdf'
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    getOverview() {
        return this.instance.get("/overview");
    }

    getOverviewForVehicleId(vehicleId) {
        return this.instance.get(`/overview/vehicle/${vehicleId}`);
    }

    putJournalMerge(id1, id2, description) {
        const req = {
            journal_ids: [id1, id2],
            description: description,
        };

        return this.instance.put(`/journal/merge`, req);
    }

    putJournalUnmerge(journalIds) {
        const req = {
            journal_ids: journalIds,
        };

        return this.instance.put(`/journal/unmerge`, req);
    }

    getJournals(fromTimestamp, toTimestamp, vehicleId, drivers, fetchCoordinates, journeyId) {
        let params = {};
        let qs;

        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = journeyId ? "/" + journeyId : "";
        qs += "?from_ts=" + fromTimestamp + "&to_ts=" + toTimestamp;
        qs += "&size=99999999";
        qs += vehicleId?.length > 0 ? "&vehicleIds=" + vehicleId : "";
        qs += drivers?.length > 0 ? "&driverIds=" + drivers : "";
        qs += fetchCoordinates ? "&fetchCoordinates=" + fetchCoordinates : "";

        return this.instance.get("/journal" + qs, {
            params,
        });
    }

    getJournalsByIds(journalIds, coords) {
        return this.instance.get(`/journal/by-ids?journalIds=${journalIds}&coords=${coords}`);
    }

    getJournalsCSV(fromTimestamp, toTimestamp, vehicleId, filename, drivers, organizationIds, showPrivateTrips) {
        let params = {
            responseType: "blob",
        };
        let qs;

        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?from_ts=" + fromTimestamp + "&to_ts=" + toTimestamp;
        qs += "&size=99999999";
        qs += vehicleId?.length > 0 ? "&vehicleIds=" + vehicleId : "";
        qs += drivers?.length > 0 ? "&driverIds=" + drivers : "";
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";
        qs += "&showPrivate=" + showPrivateTrips;

        return this.instance
            .get("/journal-csv" + qs, {
                params,
            })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:text/csv;charset=iso-8859-1," + escape(r);
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "csv.csv";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    getJournalsPDF(fromTimestamp, toTimestamp, vehicleId, filename, zoneId, driverIds, organizationIds, showPrivateTrips) {
        let params = {
            responseType: "blob",
        };
        console.log("komigenorg", organizationIds);
        let qs;

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += vehicleId?.length > 0 ? "&vehicleIds=" + vehicleId : "";
        qs += driverIds?.length > 0 ? "&driverIds=" + driverIds : "";
        qs += zoneId ? "&zoneId=" + zoneId : "";
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";
        qs += "&showPrivate=" + showPrivateTrips;

        return this.instance
            .get(`/report/journal/pdf` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "pdf.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    // getSummaryReports(fromTimestamp, toTimestamp, vehiclesNew, filename, zoneId, allVehicles, driverIds, salaryReport) {
    //     let params = {
    //         responseType: 'blob'
    //     }

    //     let qs
    //     if (!fromTimestamp || !toTimestamp)
    //         return Promise.reject("Bad timestamp")

    //     qs = '?tsFrom=' + fromTimestamp + '&tsTo=' + toTimestamp;
    //     qs += '&zoneId=' + zoneId;
    //     qs += vehiclesNew ? '&vehicleIds=' + vehiclesNew : '';
    //     qs += allVehicles ? '&allVehicles=true' : '';
    //     qs += driverIds ? '&driverIds=' + driverIds : '';
    //     qs += salaryReport ? '&salaryReport=' + salaryReport : '';

    //     return this.instance.get(`/report/summary/pdf` + qs, {params})
    //         .then(r => {
    //             const a = document.createElement("a")
    //             // a.href = window.URL.createObjectURL(r)
    //             // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
    //             a.href = "data:application/pdf;base64," + r;
    //             if (filename) {
    //                 a.download = filename.replace(/\s+/g, '-');
    //             } else {
    //                 a.download = "pdf.pdf"
    //             }
    //             a.dispatchEvent(new MouseEvent("click"))
    //             message.destroy()
    //         })
    //         .catch(e => {
    //             message.destroy()

    //         })
    // }

    ///////////
    getSummaryVehicleReports(fromTimestamp, toTimestamp, vehicles, filename, zoneId, allVehicles, driverIds, organizationIds) {
        let params = {
            responseType: "blob",
        };

        let qs;
        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += "&zoneId=" + zoneId;
        qs += vehicles ? "&vehicleIds=" + vehicles : "";
        qs += allVehicles ? "&allVehicles=true" : "";
        qs += driverIds ? "&driverIds=" + driverIds : "";
        // qs += salaryReport ? '&salaryReport=' + salaryReport : '';
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";

        console.log("orgggggggggggggggg", organizationIds);

        return this.instance
            .get(`/report/summary-vehicle/pdf` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "pdf.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    getSummaryDriverReports(fromTimestamp, toTimestamp, vehicles, filename, zoneId, allVehicles, driverIds, organizationIds) {
        let params = {
            responseType: "blob",
        };

        let qs;
        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += "&zoneId=" + zoneId;
        qs += vehicles ? "&vehicleIds=" + vehicles : "";
        qs += allVehicles ? "&allVehicles=true" : "";
        qs += driverIds ? "&driverIds=" + driverIds : "";
        // qs += salaryReport ? '&salaryReport=' + salaryReport : '';
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";

        return this.instance
            .get(`/report/summary-driver/pdf` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "pdf.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }
    getSalaryReports(fromTimestamp, toTimestamp, vehicles, filename, zoneId, allVehicles, driverIds, organizationIds) {
        let params = {
            responseType: "blob",
        };

        let qs;
        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += "&zoneId=" + zoneId;
        qs += vehicles ? "&vehicleIds=" + vehicles : "";
        qs += allVehicles ? "&allVehicles=true" : "";
        qs += driverIds ? "&driverIds=" + driverIds : "";
        // qs += salaryReport ? '&salaryReport=' + salaryReport : '';
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";

        return this.instance
            .get(`/report/salary/pdf` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "pdf.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }
    getPurposeReports(fromTimestamp, toTimestamp, vehicles, filename, zoneId, allVehicles, driverIds, organizationIds) {
        let params = {
            responseType: "blob",
        };

        let qs;
        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += "&zoneId=" + zoneId;
        qs += vehicles ? "&vehicleIds=" + vehicles : "";
        qs += allVehicles ? "&allVehicles=true" : "";
        qs += driverIds ? "&driverIds=" + driverIds : "";
        // qs += salaryReport ? '&salaryReport=' + salaryReport : '';
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";

        return this.instance
            .get(`/report/purpose/pdf` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "pdf.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    /////////

    putJournal(journalId, data = {}) {
        const {
            purpose,
            user_id,
            description,
            driver,
            override_start,
            override_stop,
            passages,
            lightAttested,
            correctedByDriver,
            attested,
            congestionTaxSekOverride,
            adminComment,
            status,
            devComment,
            infraTaxSekOverride,
            odometer_max,
            timestamp_start_ms,
            timestamp_stop_ms
        } = data;
        const req = {};
        console.log("data", data)
        if (purpose) {
            req.purpose = purpose;
        }
        if (description !== null) {
            req.description = description;
        }
        if (adminComment !== null) {
            req.adminComment = adminComment;
        }
        if (odometer_max !== null) {
            req.odometerMax = odometer_max;
        }
        if (timestamp_start_ms !== null) {
            req.timestampStartMs = timestamp_start_ms;
        }
        if (timestamp_stop_ms !== null) {
            req.timestampStopMs = timestamp_stop_ms;
        }
        if (user_id) {
            req.user_id = user_id;
        }
        if (driver) {
            req.driver = driver;
        }
        if (override_start !== null) {
            req.override_start = override_start;
        }
        if (override_stop !== null) {
            req.override_stop = override_stop;
        }
        if (passages) {
            req.passages = passages;
        }
        if (lightAttested !== null) {
            req.lightAttested = lightAttested;
        }
        if (correctedByDriver !== null) {
            req.correctedByDriver = correctedByDriver;
        }
        // if (status !== null) {
        //     req.status = status
        // }
        if (attested !== null) {
            req.attested = attested;
        }
        if (congestionTaxSekOverride !== null) {
            req.congestionTaxSekOverride = congestionTaxSekOverride;
        }
        if (congestionTaxSekOverride !== null) {
            req.infraTaxSekOverride = infraTaxSekOverride;
        }
        if (devComment) {
            req.devComment = devComment;
        }

        return this.instance.put(`/journal/${journalId}`, req);
    }

    getZones(userId) {
        let params = {};
        let qs;

        return this.instance.get(`/user/${userId}/zone`, qs);
    }

    getZoneById(userId, zoneId) {
        let params = {};
        let qs;

        return this.instance.get(`/user/${userId}/zone/${zoneId}`, qs);
    }

    postZone(data = {}, userId) {
        const req = data;

        return this.instance.post(`/user/${userId}/zone`, req);
    }

    putZone(userId, zoneId, data = {}) {
        const req = data;

        return this.instance.patch(`/user/${userId}/zone/${zoneId}`, req);
    }

    deleteZone(userId, zoneId) {
        return this.instance.delete(`/user/${userId}/zone/${zoneId}`);
    }

    putCheckCurrentDriver(vehicleId, data = {}) {
        const req = data;
        console.log(req);

        return this.instance.put(`/vehicles/${vehicleId}/current-driver`, req);
    }

    getWeekdaySelection(userId) {
        return this.instance.get(`/user/${userId}/weekday`);
    }

    postWeekdaySelection(userId, data = {}) {
        const req = data;

        return this.instance.post(`/user/${userId}/weekday`, req);
    }

    patchWeekdaySelection(userId, data = {}) {
        const req = data;

        return this.instance.patch(`/user/${userId}/weekday`, req);
    }

    deleteWeekdaySelection(userId) {
        return this.instance.delete(`/user/${userId}/weekday`);
    }

    getEcoScore(fromTimestamp, toTimestamp, vehicleId) {
        let params = {
            responseType: "blob",
        };
        let qs;
        if (!fromTimestamp || !toTimestamp) return Promise.reject("Bad timestamp");

        qs = "?fromMs=" + fromTimestamp + "&toMs=" + toTimestamp;

        return this.instance.get(`/vehicles/${vehicleId}/ecoscore` + qs, {
            params,
        });
    }

    getEcoScoreWeek(vehicleId) {
        let params = {
            responseType: "blob",
        };

        return this.instance.get(`/vehicles/${vehicleId}/ecoscore`, {
            params,
        });
    }
    getEcoReport(tsFrom, tsTo, tz, orgIds) {
        let qs;
        qs = "?tsFrom=" + tsFrom + "&tsTo=" + tsTo;
        qs += "&zoneId=" + tz;
        qs += orgIds?.length > 0 ? "&organizationIds=" + orgIds : "";
        return this.instance.get("report/eco-score" + qs)
    }
    /*
        currentVehicle: (4) [2272, 2285, 2375, 2481]
        defaultVehicles: []
        displayEmail: ""
        displayName: ""
        lastSeenApp: "2021-05-31T11:49:03"
        lastSeenDriving: "2021-05-31T07:41:48"
        organizations: [2226]
        permittedVehicles: (5) [2272, 2481, 2375, 2285, 2383]
        roles: ["ADMIN"]
        userId: 2267
     */
    getOrganizations() {
        return this.instance.get("/auth").then((r) => {
            return r.organizations.map((k) => {
                return {
                    ...k,
                    organization_id: k.organizationId,
                    full_name: k.fullName,
                };
            });
        });
    }

    putUpdateOdometer(vehicleId, data = {}) {
        const req = data;

        return this.instance.put(`/vehicles/${vehicleId}/odometer`, req);
    }

    patchUser(userId, data = {}) {
        return this.instance.patch(`/user/${userId}`, data);
    }

    postInviteUser(data = {}) {
        const req = data;
        console.log("invite user", req);

        return this.instance.post("/invite-user", req);
    }

    getVehicles(showArchived) {
        let qs;
        qs = showArchived ? "?showArchived=" + showArchived : ""

        return this.instance.get(`/vehicles` + qs);
    }

    getVehiclesSimple() {
        let params = {};
        let qs;

        return this.instance.get(`/vehicles/simple`, qs);
    }

    getAllOrganizationsForSuperadmin() {
        let qs;

        return this.instance.get(`/admin/organization`, qs).then((r) => {
            return r?.map((k) => {
                return {
                    ...k,
                    organization_id: k.organizationId,
                    full_name: k.fullName,
                };
            });
        });
    }

    getOrganizationsSimple() {
        let qs;

        return this.instance.get(`/organization-simple`, qs).then((r) => {
            return r?.map((k) => {
                return {
                    ...k,
                    organization_id: k.organizationId,
                    full_name: k.fullName,
                };
            });
        });
    }

    patchVehicle(vehicleId, data = {}) {
        const req = data;

        return this.instance.patch(`/vehicles/${vehicleId}`, req);
    }

    postSuperVehicle(data = {}, organizationId) {
        const args = {
            url: "/vehicles",
            method: "post",
            // headers: {
            //     'X-Trackson-Organization': organizationId
            // },
            data: data,
        };
        console.log(args);

        return this.instance.post("/vehicles", data);
    }

    patchChangeOrgVehicle(vehiclesId, data = {}) {
        const args = {
            url: "/vehicles/" + vehiclesId,
            method: "patch",
            // headers: {
            //     'X-Trackson-Organization': data.organizationId
            // },
            data: data,
        };

        return this.instance(args);
    }

    patchChangeOrgUser(userId, data = {}) {
        const args = {
            url: "/user/" + userId,
            method: "patch",
            // headers: {
            //     'X-Trackson-Organization': data.organizationId
            // },
            data: data,
        };

        return this.instance(args);
    }

    postVehicle(data = {}) {
        const req = data;
        return this.instance.post(`/vehicles`, req);
    }

    postVehiclesResetFuelAndCO2(vehicleId) {
        return this.instance.post(`/vehicles/${vehicleId}/reset-fuel-co2`);
    }

    getEmailRecipients(organizationId) {
        const p = this.instance.get(`/organization/${organizationId}`);
        const p2 = p.then((r) => {
            return r.imeiFlagEmails;
        });
        return p2;
    }

    setEmailRecipients(organizationId, newEmailList) {
        return this.instance.patch(`/organization/${organizationId}`, {
            imeiFlagEmails: newEmailList,
        });
    }

    //--------------------------
    getOrganization(organizationId) {
        return this.instance.get(`/organization/${organizationId}`);
    }

    patchOrganizationAccount(organizationId, data = {}) {
        const req = data;

        return this.instance.patch(`/organization/${organizationId}`, req);
    }

    //--------------------------

    postOrganization(data = {}) {
        const req = data;

        return this.instance.post(`/admin/organization`, req);
    }

    patchOrganization(organizationId, data = {}) {
        const req = data;

        console.log(req);
        return this.instance.patch(`/admin/organization/${organizationId}`, req);
    }

    getAdminOrganization(organizationId) {
        return this.instance.get(`/admin/organization/${organizationId}`);
    }

    getPhoneFromJasper(iccid) {
        return this.instance.get(`/external/phone?iccid=${iccid}`);
    }

    getDeviceById(deviceId) {
        return this.instance.get(`/admin/devices/${deviceId}`);
    }

    patchAdminDevices(devicesId, data) {
        const req = data;
        return this.instance.patch(`/admin/devices/${devicesId}`, req);
    }

    getConfigFromFota(imei) {
        return this.instance.get(`/external/fota/device?imei=${imei}`);
    }

    patchDevices(devicesId, vehiclesId) {
        const req = {
            installedVehicle: vehiclesId,
        };

        console.log(req);
        return this.instance.patch(`/devices/${devicesId}`, req);
    }

    postVehicleTracking(vehicleId) {
        return this.instance.post(`/vehicles/${vehicleId}/tracking`);
    }

    deleteOrganization(organizationId) {
        return this.instance.delete(`/admin/organization/${organizationId}`);
    }

    deleteAdminDevicesInstalled(deviceId) {
        return this.instance.delete(`/admin/devices/${deviceId}/installed-vehicle`);
    }

    deleteDevicesInstalled(deviceId) {
        return this.instance.delete(`/devices/${deviceId}/installed-vehicle`);
    }

    postVehiclesWebConnect(vehicleId) {
        return this.instance.post(`/vehicles/${vehicleId}/web-connect`);
    }

    getUsers(showArchived) {
        let qs;
        qs = showArchived ? "?showArchived=" + showArchived : ""

        return this.instance.get(`/user` + qs);
    }

    getUserById(userId) {
        return this.instance.get(`/user/${userId}`);
    }

    getUsersSimple() {
        return this.instance.get(`/user/simple`);
    }

    getDevices() {
        return this.instance.get(`/devices`);
    }

    deleteUser(userId) {
        return this.instance.delete("/user/" + userId);
    }

    postNewUser(data = {}, organizationId) {
        return this.instance.post("/user", data);
    }

    getAVLLogFromTo(data, filename) {
        return this.instance.get(`/admin/avl/log?imei=${data.imei}&tsFromMs=${data.start}&tsToMs=${data.stop}`).then((r) => {
            console.log("get logg");

            const a = document.createElement("a");
            a.href = "data:text/csv;charset=iso-8859-1," + escape(r);
            if (filename) {
                a.download = filename;
            } else {
                a.download = "csv.csv";
            }
            a.dispatchEvent(new MouseEvent("click"));
        });
    }

    getReports(id) {
        return this.instance.get(`/report/schedule`);
    }

    getReportsOrganization(id) {
        return this.instance.get(`/report/schedule/organization/${id}`);
    }

    postReport(data) {
        return this.instance.post(`/report/schedule`, data);
    }

    deleteReport(id) {
        return this.instance.delete(`/report/schedule/${id}`);
    }

    deleteVehicle(id) {
        return this.instance.delete(`/vehicles/${id}`);
    }

    deleteGeoAlarm(id) {
        return this.instance.delete(`/geo-larm/${id}`);
    }

    postGeoAlarm(data) {
        return this.instance.post(`/geo-larm`, data);
    }

    getGeoAlarm(id) {
        return this.instance.get(`/geo-larm`);
    }

    getGeoAlarmById(id) {
        return this.instance.get(`/geo-larm/${id}`);
    }

    patchGeoAlarm(data, id) {
        return this.instance.patch(`/geo-larm/${id}`, data);
    }

    // getAlarmPeriod(current, id) {
    //     return this.instance.get(`/alarm-period`)
    // }
    getAlarmPeriod(fromTimestamp, toTimestamp) {
        let qs;

        qs = "?fromTs=" + fromTimestamp + "&toTs=" + toTimestamp;

        return this.instance.get(`/alarm-period` + qs);
    }

    getAlarmPeriodCurrent() {
        return this.instance.get(`/alarm-period/current`);
    }

    patchAlarmPeriod(data, id, comment) {
        let qs;

        qs = "?comment=" + comment;

        return this.instance.patch(`/alarm-period/${id}` + qs, data);
    }

    // getAlarmEventsReport(fromTimestamp, toTimestamp, vehicleId, filename, zoneId, driverIds, geoZoneAlarmIds) {
    //     let params = {
    //         responseType: 'blob'
    //     }

    //     let qs

    //     qs = '?tsFrom=' + fromTimestamp + '&tsTo=' + toTimestamp;
    //     qs += vehicleId?.length > 0 ? '&vehicleIds=' + vehicleId : '';
    //     qs += driverIds?.length > 0 ? '&driverIds=' + driverIds : '';
    //     qs += zoneId ? '&zoneId=' + zoneId : '';
    //     qs += geoZoneAlarmIds ? '&geoZoneAlarmIds=' + geoZoneAlarmIds : '';

    //     return this.instance
    //         .get(`/report/discrepancy` + qs, {params})
    //         .then(r => {
    //             const a = document.createElement("a")
    //             // a.href = window.URL.createObjectURL(r)
    //             // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
    //             a.href = "data:application/pdf;base64," + r;
    //             if (filename) {
    //                 a.download = filename.replace(/\s+/g, '-');

    //             } else {
    //                 a.download = "pdf.pdf"
    //             }
    //             a.dispatchEvent(new MouseEvent("click"))
    //             message.destroy()
    //         })
    //         .catch(e => {
    //             message.destroy()

    //         })
    // }

    getAlarmEventsReport(fromTimestamp, toTimestamp, driverIds, vehicleId, organizationIds, zoneId, alarmType, alarmName, filename) {
        let params = {
            responseType: "blob",
        };

        let qs;

        qs = "?tsFrom=" + fromTimestamp + "&tsTo=" + toTimestamp;
        qs += driverIds?.length > 0 ? "&driverIds=" + driverIds : "";
        qs += vehicleId?.length > 0 ? "&vehicleIds=" + vehicleId : "";
        qs += organizationIds?.length > 0 ? "&organizationIds=" + organizationIds : "";
        qs += zoneId ? "&zoneId=" + zoneId : "";
        qs += alarmType?.length > 0 ? "&alarmType=" + alarmType : "";
        qs += alarmName?.length > 0 ? "&alarmName=" + alarmName : "";

        return this.instance
            .get(`/report/discrepancy` + qs, { params })
            .then((r) => {
                const a = document.createElement("a");
                // a.href = window.URL.createObjectURL(r)
                // a.href = "data:text/csv;charset=iso-8859-1," + encodeURI(r);
                a.href = "data:application/pdf;base64," + r;
                if (filename) {
                    a.download = filename.replace(/\s+/g, "-");
                } else {
                    a.download = "Avvikelserapport.pdf";
                }
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            });
    }

    patchJournalPassage(id, data) {
        return this.instance.patch(`/journal/passage/${id}`, data);
    }

    putJournalRestorePassages(id, typeInfra, typeCongestion) {
        let qs = ""
        qs += typeInfra ? "?infra=true" : ""
        qs += typeCongestion ? "?congestion=true" : ""

        return this.instance.put(`/journal/${id}/restore-passages` + qs);
    }

    getAttestCheck(month, year, vehicleId) {
        return this.instance.get(`/attest/check?month=${month}&year=${year}&vehicleId=${vehicleId}`);
    }

    patchAttestUser(month, year, vehicleId) {
        return this.instance.patch(`/attest/user?month=${month}&year=${year}&vehicleId=${vehicleId}`);
    }

    patchAttestUserUnlock(userId, month, year) {
        return this.instance.patch(`/attest/user/${userId}/unlock?month=${month}&year=${year}`);
    }

    patchJournalUnattest(journalId) {
        return this.instance.patch(`/journal/${journalId}/unattest`);
    }

    patchJournalDevCommentOff(journalId) {
        return this.instance.put(`/journal/${journalId}/remove-dev-comment`);
    }

    getReportDriver(month, year) {
        return this.instance.get(`/report/driver?month=${month}&year=${year}`);
    }

    putAttestRefuse(message, month, year, zoneId, driverId, vehicleId, badTrips) {
        let params = {
            message: message,
        };
        return this.instance.put(`/attest/refuse?month=${month}&year=${year}&zoneId=${zoneId}&driverId=${driverId}&vehicleId=${vehicleId}&badTrips=${badTrips}`, params);
    }

    putUnapprove(message, month, year, driverId, vehicleId, badTrips) {
        let params = {
            message: message,
        };
        return this.instance.put(`/attest/unlock?month=${month}&year=${year}&driverId=${driverId}&vehicleId=${vehicleId}&badTrips=${badTrips}`, params);
    }

    putAttestConfirm(message, month, year, zoneId, driverId, vehicleId) {
        let params = {
            message: message,
        };
        return this.instance.put(`/attest/confirm?month=${month}&year=${year}&zoneId=${zoneId}&driverId=${driverId}&vehicleId=${vehicleId}`, params);
    }
    putAttestReminder(month, driverReport) {
        let params = [driverReport];
        return this.instance.put(`/attest/remind?month=${month}`, params);
    }

    getAlarmSettings() {
        return this.instance.get(`/alarm-settings`);
    }

    postAlarmSettings(data) {
        return this.instance.post(`/alarm-settings`, data);
    }

    getAlarmSettingsById(alarmId) {
        return this.instance.get(`/alarm-settings/${alarmId}`);
    }

    patchAlarmSettings(alarmId, data) {
        return this.instance.patch(`/alarm-settings/${alarmId}`, data);
    }

    deleteAlarmSettings(alarmId) {
        return this.instance.delete(`/alarm-settings/${alarmId}`);
    }

    getAttestCkeckLite(month, year, vehicleId) {
        return this.instance.get(`/attest/check-lite?month=${month}&year=${year}&vehicleId=${vehicleId}`);
    }

    postVehicleSetFlag(vehicleIds = [], isoCountryCode = null) {
        const data = {
            vehicleIds,
            isoCountryCode,
        };
        return this.instance.post(`/vehicles/set-flag`, data);
    }
    deleteVehicleHistory(vehicleId) {
        return this.instance.delete(`/vehicleinfo/history/${vehicleId}`);
    }

    patchArchiveVehicle(vehicleId) {
        return this.instance.patch(`/vehicles/${vehicleId}/archive`);
    }

    patchActivateVehicle(vehicleId) {
        return this.instance.patch(`/vehicles/${vehicleId}/activate`);
    }

    patchArchiveUser(userId) {
        return this.instance.patch(`/user/${userId}/archive`);
    }

    patchActivateUser(userId) {
        return this.instance.patch(`/user/${userId}/activate`);
    }

    // TODO use same wrapper for this fn
    // but it injects header in different way
    vehicleCpuReset(orgId, vehicleId) {
        return fetch({
            url: `${API_BASE_URL}/vehicles/${vehicleId}/cpureset`,
            method: "POST",
            headers: {
                "X-Trackson-Organization": orgId,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // data: payload,
        });
    }

    getVehicleDevice(vehicleId) {
        return this.instance.get(`/device-vehicle/vehicle/${vehicleId}`);
    }

    getVehicleById(vehicleId, ) {
            return this.instance.get(`/vehicles/${vehicleId}`);
        }

    separateStartConfig(deviceId, specifiedTimeOfDay) {
        let qs= specifiedTimeOfDay ? "&specifiedTimeOfDay=" + specifiedTimeOfDay : "";
        return this.instance.post(`/queclink/separate-start-config?deviceId=${deviceId}` + qs);
    }

    getOnomondoSMS(deviceId) {
        return this.instance.get(`/onomondo/sms/device/${deviceId}`);
    }

    getCheckQueclinkHistory(deviceId) {
        return this.instance.get(`/queclink/device/${deviceId}/history?groupByLatest=true`);
    }

    getOverrideHistory(journalId) {
        return this.instance.get(`/journal/${journalId}/override-history`);
    }

    postAdminOrgSettings(name, orgId) {
        return this.instance.post(`/admin/organization/settings?name=${name}&orgId=${orgId}`);
    }

    deleteAdminOrgSettings(name, orgId) {
        return this.instance.delete(`/admin/organization/settings?name=${name}&orgId=${orgId}`);
    }

    postAdminUserSettings(name, userId) {
        return this.instance.post(`/admin/user/settings?name=${name}&userId=${userId}`);

    }

    deleteAdminUserSettings(name, userId) {
        return this.instance.delete(`/admin/user/settings?name=${name}&userId=${userId}`);

    }

    postAdminTrip(values) {
        return this.instance.post(`/admin/trip`, values);

    }

    deleteAdminTrip(journalId) {
        return this.instance.delete(`/admin/trip/${journalId}`);
    }
}

const userApi = new UserAPI();
const dataApi = new DataAPI();

export { userApi, dataApi };

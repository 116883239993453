import { Nest } from "../../utils/utilities";
import {
    AuthorizationProvider,
    OrganizationSimpleProvider, shouldHideUsersOrganizationsColumn, UserListProvider,
    UserListSimpleProvider,
    VehicleProvider, VehiclesComplexListProvider
} from "../../services/contexts/contexts";
import AlarmSafetyController from "../../components/trackson-components/AlarmsSafety";

const AlarmSafetyPage = () => (
        <Nest wrappers={[AuthorizationProvider,
            shouldHideUsersOrganizationsColumn.provider,
            VehiclesComplexListProvider, VehicleProvider,
            UserListSimpleProvider, UserListProvider, OrganizationSimpleProvider]}>
        <AlarmSafetyController />
    </Nest>
);

export default AlarmSafetyPage;

import Flex from "../../shared-components/Flex";
import {settingsTranslate} from "../../../lang/translate/sv-SE/sv-SE";
import React, {useEffect} from 'react'
import {Form, Switch, Typography} from 'antd';

export const SettingsForm = ({record, setSwitchStates, setInitialSwitchStates}) => {

    useEffect(() => {
        if(!record) return
        setSwitchStates(record.settings);
        setInitialSwitchStates(record.settings); // Set initial states for comparison upon submission
    }, [record, setSwitchStates]);


    const onSwitchChange = (key, value) => {
        setSwitchStates(prev => ({...prev, [key]: value}));
    };

    return (
        <div>
            <Flex flexDirection={"column"}>
                <Typography.Title level={4}>Inställningar</Typography.Title>
                {record?.settings && Object.entries(record?.settings)?.map(([key, value]) => (
                    <Form.Item name={key} key={key} valuePropName="checked">
                        <Flex alignItems={"center"} justifyContent={"between"}
                              style={{width: "100%", padding: 8, borderBottom: "1px solid #4d5b75"}}>
                            {settingsTranslate[key] || key}
                            <Switch defaultChecked={value} onChange={(checked) => onSwitchChange(key, checked)}/>
                        </Flex>
                    </Form.Item>
                ))}
            </Flex>
        </div>
    );
};
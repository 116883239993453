import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {Select} from 'antd';
import {FolderOpenOutlined} from '@ant-design/icons';

const {Option, OptGroup} = Select;

export const UsersSelect = ({data, value, onChange, multiple, ...props}) => {
    const activeData = data?.filter((v) => !v?.isArchived)
    const archivedData = data?.filter((v) => v?.isArchived)
    return (
        <div>
            <Select
                showSearch
                mode={multiple}
                allowClear={multiple}
                filterOption={(input, option) => JSON.stringify(option?.object)?.toLowerCase().includes(input.toLowerCase())}
                maxTagCount={1}
                style={{minWidth: 200}}
                //dropdownStyle={{minWidth: 400}}
                autoClearSearchValue={false}
                dropdownMatchSelectWidth={false}
                placeholder={STATIC_TEXT.SEARCH_DRIVER}
                optionFilterProp="children"
                dropdownAlign={props.dropdownAlign}
                onChange={(value) => onChange(value)}
                onSearch={() => null}
                value={value}
            >
                {multiple && (
                    <Option style={{color: "#286FBE", cursor: "pointer"}}
                            value={-1} key={-1}>

                        {value?.length > 0 ? (
                            <span
                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Avmarkera alla
                            </span>
                        ) : (
                            <span
                                style={{color: "#286FBE", cursor: "pointer"}}>
                                Välj alla
                            </span>
                        )}

                    </Option>
                )}
                {activeData?.map((v, i) => {
                    return (
                        <Option value={v.userId}  object={v}>{v.displayName}
                        </Option>
                    );
                })}

                {archivedData?.length > 0 &&
                    <OptGroup label="Arkiverade">
                        {archivedData?.map((v, i) => {
                            return (
                                <Option value={v.userId}  object={v}>
                                        <FolderOpenOutlined style={{marginRight: 8}}/>
                                        {v.displayName}
                                </Option>

                            );
                        })}
                    </OptGroup>}
            </Select>
        </div>
    )

}


import fetch from 'auth/FetchInterceptor'

const AdminService = {}

AdminService.login = function (username, password) {
	const args = {
		url: '/auth',
		method: 'post',
		headers: {
		  'public-request': 'true'
		},
		data: {
			username: username,
			password: password
		}
	}
	console.log("login:", args)
	return fetch(args)
}

AdminService.logout = function (data) {
	return Promise.resolve()
	return fetch({
		url: '/session',
		method: 'delete',
		params: {}
	})
}

AdminService.session = function (data) {
	return fetch({
		url: '/session',
		method: 'get',
		params: {}
	})
}

AdminService.getControlState = function() {
    return fetch({
		url: '/api/control',
		method: 'get'
	})
}

AdminService.getDinState = function(ts) {
	// url: '/api/queries/din1-since/1616321068',
	return fetch({
		url: '/api/queries/din1-since/' + ts,
		method: 'get'
	})
}

export default AdminService

import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    ConfigProvider,
    Form,
    Input,
    Menu,
    message,
    Modal,
    Select,
    Spin,
    Table,
    Tabs,
    Tooltip,
    Typography
} from "antd";
import {
    CarOutlined,
    DashboardOutlined,
    EditOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    RetweetOutlined,
    UpSquareOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {Icon} from "react-icons-kit";
import {LastSeenLink} from "./shared-components/DatumLink";
import {dataApi} from "../../services/api/api";
import Loading from "../shared-components/Loading/Loading";
import {OrganizationsTreeSelect} from "./shared-components/OrganizationsTreeSelect";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {ORGANIZATION_ID} from "../../services/redux/constants/authConstants";
import {TabBadge} from "./Superdevices/reusableComponents";
import {
    ButtonMenuDropdown,
    ChangeVehiclesOrganization,
    CheckInDriver,
    registryPlateWithAlias,
    renderTripTypeTag,
    showDriversCell,
    showModalForMoveDevice,
    tableColumnsWithTooltip,
    tableProps,
    vehiclesIcon
} from "./shared-components/sharedComponents";
import {STATIC_TEXT} from "../../lang/translate/sv-SE/sv-SE";
import {SD_BENEFIT_TYPES, SUPER_DEVICES_KEYS_AND_INDEXES} from "../../constants/CustomConstant";
import {customizeRenderEmpty} from "./shared-components/customizeRenderEmpty";
import {AlertArchived, TabsArchived} from "./shared-components/TabsArchived";
import {NewVehicleForm} from "./shared-components/newVehicleForm";
import {
    DeviceListContext,
    OrganizationsComplexListContext,
    shouldHideUsersOrganizationsColumn,
    UserListContext,
    VehiclesListWithArchivedContext
} from "../../services/contexts/contexts";
import {activateVehiclesModals, archiveVehicle} from "./shared-components/archiveVehiclesModals";
import {getOdometerValue} from "./shared-components/odometer";
import {ModalWithFullList} from "./shared-components/modalShowFullList";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

const {Text} = Typography;
const {Option} = Select;
const {TabPane} = Tabs;

const countryNamesList = countries.getNames("sv", {select: "alias"});
const countryNamesAsArray = Object.entries(countryNamesList).map(([key, label]) => ({
    key,
    label,
}));
const countryNamesAsArraySorted = countryNamesAsArray.sort((a, b) => a.label.localeCompare(b.label));
const nordicCountries = countryNamesAsArraySorted.filter((country) => country.key === "SE" || country.key === "FI" || country.key === "NO" || country.key === "DK");
const sweden = countryNamesAsArraySorted.find((sweden) => sweden.key === "SE");
const findSweden = nordicCountries.findIndex((sweden) => sweden.key === "SE");
nordicCountries.splice(findSweden, 1);
nordicCountries.unshift(sweden);
const CHECK_OUT = "check_out";

export const VehicleController = () => {
    const [organizationId, setSelectedOrganization] = useState();
    const [benefitType, setBenefitType] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");

    const knownOrganizations = useContext(OrganizationsComplexListContext)
    const vehicles = useContext(VehiclesListWithArchivedContext)
    const knownDevices = useContext(DeviceListContext)
    const users = useContext(UserListContext);
    const knownUsers = users?.filter(v => !v.isArchived);

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };

    const onHandleBenefitTypeChange = (value) => {
        setBenefitType(value);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, flexWrap: "wrap"}}>

                <div>
                    <Typography.Title style={{marginBottom: 0}}>Fordon</Typography.Title>
                </div>
                <div style={{display: "flex", gap: 15, flexWrap: "wrap"}}>
                    {knownOrganizations?.length < 2 ? null : (
                        <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                 selectedOrganization={organizationId} isMultiple={true}/>
                    )}
                    <Input.Search
                        type="text"
                        placeholder="Sök efter regnr, IMEI eller förare"
                        label="Registreringsnummer"
                        defaultValue={searchTerm}
                        enterButton
                        onChange={handleChange}
                        style={{width: 300}}
                    />

                    <Button type={"primary"} onClick={() => setIsModalVisible(true)} icon={<CarOutlined/>}>
                        Lägg till
                    </Button>
                </div>
            </div>
            <VehicleTable
                permittedDrivers={knownUsers}
                devicesData={knownDevices}
                knownOrganizations={knownOrganizations}
                organizationId={organizationId}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                benefitType={benefitType}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                vehicles={vehicles}
            />
        </>
    );
};

export const VehicleMarker = ({marker}) => {
    return <Icon icon={marker}/>;
};

const VehicleTable = ({
                          vehicles,
                          permittedDrivers,
                          changeOdometerValue,
                          changeDefaultTripType,
                          changeBenefitType,
                          devicesData,
                          searchTerm,
                          setIsModalVisible,
                          ...props
                      }) => {
    const [form] = Form.useForm();
    const [filteredVehiclesData, setFilteredVehiclesData] = useState();
    const [showAlertArchived, setShowAlertArchived] = useState(false);
    const [archivedData, setArchivedData] = useState();
    const [activeData, setActiveData] = useState();
    const [loading, setLoading] = useState(true);
    const [editingKey, setEditingKey] = useState("");
    const [editingVehicle, setEditingVehicle] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [allData, setAllData] = useState();
    const [record, setRecord] = useState(null);

    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);

    const {push} = useHistory();

    useEffect(() => {
        setAllData(vehicles);
        setFilteredVehiclesData(vehicles);
        setLoading(false)
    }, [vehicles]);

    useEffect(() => {
        const sortData = filteredVehiclesData?.sort((a, b) => {
            // Sort by registry (if exists), put items without registry at the end
            if (a?.registry && b?.registry) {
                if (a.registry === b.registry) {
                    // If registry is the same, sort by alias
                    return a.displayName.localeCompare(b.displayName);
                } else {
                    return a.registry.localeCompare(b.registry);
                }
            } else if (!a?.registry && !b?.registry) {
                // If both items have no registry, sort by alias
                return a?.displayName?.localeCompare(b.displayName);
            } else {
                // One of the items has registry, put the one without registry at the end
                return a?.registry ? -1 : 1;
            }
        });
        setActiveData(sortData?.filter((item) => !item.isArchived))
        setArchivedData(sortData?.filter((item) => item.isArchived))
    }, [filteredVehiclesData])

    useEffect(() => {
        let filteredList = allData;

        if (searchTerm.length > 1) filteredList = filteredList?.filter((item) => JSON.stringify(item).toLowerCase().includes(searchTerm));

        if (props?.organizationId?.length > 0) {
            filteredList = filteredList?.filter((i) => props.organizationId.includes(i.organizationId));
        }
         filteredList = filteredList?.filter((i) => !i.isTracker);

        setFilteredVehiclesData(filteredList);
    }, [searchTerm, allData, props.organizationId, props.benefitType]);

    function handleAdd(rows) {
        const newData = [...allData];

        if (Array.isArray(rows)) {
            rows.forEach(row => {
                const index = newData.findIndex(item => row.id === item.id);
                if (index > -1) {
                    newData.splice(index, 1, { ...newData[index], ...row });
                } else {
                    newData.push(row);
                }
            });
        } else {
            const index = newData.findIndex(item => rows.id === item.id);
            if (index > -1) {
                newData.splice(index, 1, { ...newData[index], ...rows });
            } else {
                newData.push(rows);
            }
        }

        setFilteredVehiclesData(newData);
        setAllData(newData);
        setIsModalVisible(false);
        setEditingVehicle(null);
    }

     function handleMoveDeviceToOtherVehicle(record, vehicleId, knownVehicles) {
        setShowSpinner(true);
         knownVehicles = knownVehicles || allData

        const imei = record.state?.imei;
        const deviceIndex = devicesData.findIndex((device) => device.imei === imei);

        if (deviceIndex === -1) {
            setShowSpinner(false);
            message.error("Kunde inte hitta enheten.");
            return;
        }

        const device = devicesData[deviceIndex];

        if (record.id === vehicleId || !vehicleId) {
            setShowSpinner(false);
            return;
        }

        try {
             dataApi.patchDevices(device.id, vehicleId);

            const newData = [...knownVehicles];
            const recordIndex = newData.findIndex((item) => item.id === record.id);
            const vehicleIndex = newData.findIndex((item) => item.id === vehicleId);

            let newVehicle ;
            if (vehicleIndex !== -1) {
                newVehicle = { ...newData[vehicleIndex] };
                newVehicle.state = { ...device };
                newVehicle.odometer = record.odometer;
                newData.splice(vehicleIndex, 1, newVehicle);
            }

            const newRecord = { ...newData[recordIndex] };
            newRecord.state = null;
            newRecord.odometer = null;
            newData.splice(recordIndex, 1, newRecord);

            setFilteredVehiclesData(newData);
            setAllData(newData);
            setSelectedRecord(null);
            Modal.success({
                title: "Enheten flyttades",
                content: (
                    <div>
                        <p>Enhet {device.imei} flyttades till <strong>{newVehicle?.registry || newVehicle?.displayName}</strong>.</p>
                    </div>
                ),
            });
        } catch (error) {
            console.error(error);
            message.error("Något gick fel. Försök igen.");
        } finally {
            setShowSpinner(false);
        }
    }
    const handleSelectChangeVehicleOrganization = (record, selectedOrgId) => {
        setShowSpinner(true);
        const req = {organizationId: selectedOrgId}

        dataApi.patchChangeOrgVehicle(record.id, req)
            .then(i => {
                message.success("Fordon flyttades till en annan organisation.")
                const newData = [...allData];
                const index = newData.findIndex(item => record.id === item.id);
                const item = newData[index];
                let newRow = item
                newRow = {
                    ...item,
                    organizationId: selectedOrgId,
                    permittedDrivers: [],
                    defaultDriver: null,
                    currentDriver: null,
                }
                newData.splice(index, 1, {...item, ...newRow});
                setFilteredVehiclesData(newData)
                setAllData(newData)
                setShowSpinner(false);

            })
            .catch((e) => {
                message.error("Något gick fel. Försök igen.");
                console.error(e);
            });
    };

    function redirectToView(record, view, showArchived) {
        const value = record?.id;
        let qs;
        qs = showArchived ? `&&showArchived=${showArchived}` : ""
        push(`${APP_PREFIX_PATH}/${view}?vehicleId=${value}` + qs);
    }

    function handleAddNewVehicle(record) {
        setSelectedRecord(record);
        setIsModalVisible(true);
    }

    // EXLUDE ORGS HAX
    const EXCLUDED_ORG_IDS = [41465, 41186, 30714];
    const dontShowRedirectToMap = EXCLUDED_ORG_IDS.includes(parseInt(localStorage.getItem(ORGANIZATION_ID)));

    const columnsArchived = [
        {
            key: "registry",
            dataIndex: "registry",
            title: "Regnr",
            fixed: 'left',
            width: 150,
            render: (value, record) => registryPlateWithAlias(record)
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            width: 200,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: "Arkiverad",
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.archivedTsMs,
            key: "archived",
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker} defaultText={true}/> : "-"),
        },
        {
            title: "",
            dataIndex: "operation",
            render: (text, record) => (
                <div style={{float: "right"}}>
                    <Button
                        icon={<DashboardOutlined/>}
                        onClick={() => (record.isTracker ? null : redirectToView(record, "journey", true))}>

                        {STATIC_TEXT.JOURNEY}
                    </Button>
                    <Button
                        icon={<UpSquareOutlined/>}
                        onClick={() => activateVehiclesModals(record, allData, setAllData, editVehicle)}>
                        {STATIC_TEXT.ACTIVE}
                    </Button>
                </div>
            )
        },
    ].filter(item => !item.hidden);


    function editVehicle(record) {
        setEditingVehicle(record);
        setIsModalVisible(true)
    }


    function updateRecord(record) {
        const newData = [...allData];
        const index = newData?.findIndex((item) => record?.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...record});
        setAllData(newData);
        setRecord(null)
    }

    function menuDropdown(record) {
        const hasDevice = record?.state?.imei;
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => editVehicle(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item icon={<UserOutlined/>}
                       onClick={() => setRecord(record)}>{STATIC_TEXT.CHECK_IN_DRIVER} </Menu.Item>
            {dontShowRedirectToMap && record.isTracker ? null : (
                <Menu.Item icon={<DashboardOutlined/>}
                           onClick={() => (record.isTracker ? redirectToView(record, "map") : redirectToView(record, "journey"))}>
                    {record.isTracker ? "Visa på karta" : STATIC_TEXT.JOURNEY} </Menu.Item>
            )}
            <Menu.Item icon={<RetweetOutlined/>}
                       disabled={!hasDevice}
                       onClick={() => showModalForMoveDevice(record, allData, handleMoveDeviceToOtherVehicle, handleAddNewVehicle)}
            >{STATIC_TEXT.MOVE_DEVICE}</Menu.Item>
            {props.knownOrganizations?.length > 1 &&
                <Menu.Item icon={<HomeOutlined/>}
                       onClick={() => ChangeVehiclesOrganization(record, props.knownOrganizations, handleSelectChangeVehicleOrganization)}
            >
                {STATIC_TEXT.MOVE_VEHICLE}</Menu.Item>
            }
            <Menu.Item icon={<FolderOpenOutlined/>}
                       onClick={() =>
                           archiveVehicle(record, allData, setAllData, devicesData,
                               showModalForMoveDevice, handleMoveDeviceToOtherVehicle, handleAddNewVehicle)}>
                {STATIC_TEXT.ARCHIVE}</Menu.Item>
        </Menu>
    }

    const columns = [
        {
            key: "registry",
            dataIndex: "registry",
            title: "Regnr",
            fixed: 'left',
            width: 200,
            render: (value, record) => registryPlateWithAlias(record),
        },
        {
            key: "style",
            dataIndex: "style",
            title: "Ikon",
            align: "center",
            width: 80,
            render: (value) => vehiclesIcon(value)
        },

        {
            key: "benefitType",
            dataIndex: "benefitType",
            title: "Typ",
            width: 150,
            render: (value, record) => {
                const benefitType = Object.entries(SD_BENEFIT_TYPES)
                    .find(([key, title]) => title?.TYPE === value)
                return benefitType[1]?.TYPE_DISPLAY_TEXT
            },
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            editable: false,
            width: 150,
            hidden: hideUsersOrg,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            key: "defDriver",
            dataIndex: "defaultDriver",
            title: "Förvald förare",
            editable: true,
            render: (value, record) => {
                return showDriversCell(record, value)
            },
        },
        {
            key: "curDriver",
            dataIndex: "currentDriver",
            title: "Incheckad förare",
            editable: true,
            render: (value) => (value?.displayName || "Förare ej vald"),
        },
        {
            title: "Behöriga förare",
            key: "dPermitted",
            dataIndex: "permittedDrivers",
            width: 150,
            render: (value, record) => {
                value = value?.filter(item => permittedDrivers?.find(user => user.id === item.id))
                const ifPoolCar = record?.benefitType === "POOL";
                let text;
                if (ifPoolCar) {
                    text = "Tillåts för alla"
                }
                if (value?.length === 0) {
                    text = "Förare ej vald";
                }
                if (value?.length === 1) {
                    const driver = permittedDrivers?.find((v) => v.userId === value[0].id);
                    text = driver?.displayName

                }
                if (value?.length > 1) {
                    const recordUsers = value?.map(item => item.id)
                    let title = value?.length + " förare";
                    return ModalWithFullList(title, null, null, recordUsers, null, permittedDrivers, props.knownOrganizations)
                }
                return text
            },
        },
        {
            title: "Sågs senast",
            dataIndex: "lastSeen",
            key: "lastSeen",
            align: "center",
            width: 150,
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker}/> : "-"),
        },
        {
            key: "vehicleType",
            dataIndex: "hybrid",
            title: "Bränsletyp",
            width: 100,
            render: (text, record) => {
                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        {record.fuel1 ? <Text>{record.fuel1?.svName}</Text> : null}
                        {record.fuel2 ? <Text>{record.fuel2?.svName}</Text> : null}
                    </div>
                );
            },
        },
        {
            key: "odom",
            dataIndex: "odometer",
            title: "Mätarställning",
            align: "right",
            width: 150,
            render: (value, record) => getOdometerValue(record)
        },
        {
            key: "co2_fuel",
            dataIndex: "co2_fuel",
            title: "CO2",
            align: "right",
            width: 150,
            render: (value, record) => {
                const co21 = record?.co2GKm1 !== null ? `${record?.co2GKm1} g/km` : null;
                const co22 = record?.co2GKm2 !== null ? `${record?.co2GKm2} g/km` : null;

                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Text>{co21}</Text>
                        <Text>{co22}</Text>
                    </div>
                );
            },
        },
        {
            key: "consumption",
            dataIndex: "consumption",
            title: "Förbrukning",
            align: "right",
            width: 120,
            render: (value, record) => {
                const fuel1 = record?.fuel1 ? record?.consumption1 + " " + record?.fuel1.consumptionUnit : null;
                const fuel2 = record?.fuel2 ? record?.consumption2 + " " + record?.fuel2.consumptionUnit : null;

                return (
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <Text>{fuel1}</Text>
                        <Text>{fuel2}</Text>
                    </div>
                );
            },
        },

        {
            key: "isoCountryCode",
            dataIndex: "isoCountryCode",
            title: "Landskod",
            width: 100,
        },

        {
            key: "defaultTripType",
            dataIndex: "defaultTripType",
            title: "Förval",
            width: 80,
            render: (value, record) => {
                return renderTripTypeTag(value);
            },
        },
        {
            key: "imei",
            dataIndex: "state",
            title: "Enhet",
            width: 150,
            render: (value, record) => {
                if (!value?.imei) return

                let imei = value?.imei?.toString();
                const slicedImei = imei?.slice(0, 4) + "...." + imei?.toString().slice(11, 16);
                return <Tooltip title={imei}>
                    {slicedImei}
                </Tooltip>
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    let extraTitle = selectedRecord ? " med imei " + selectedRecord?.state?.imei : "";

    const onModalCancel = () => {
        setEditingVehicle(null)
        setSelectedRecord(null)
        setIsModalVisible(false)
    };


    return (
        <Form form={form} component={false}>

            <NewVehicleForm
                onModalCancel={onModalCancel}
                isModalVisible={props.isModalVisible}
                extraTitle={extraTitle}
                knownOrganizations={props.knownOrganizations}
                organizationId={props.organizationId}
                permittedDrivers={permittedDrivers}
                setData={handleAdd}
                knownDevices={devicesData}
                record={editingVehicle}
                moveDevice={selectedRecord}
                imeiList={devicesData}
                handleMoveDeviceToOtherVehicle={handleMoveDeviceToOtherVehicle}
            />

            <CheckInDriver record={record} updateRecord={updateRecord} setRecord={setRecord}/>

            {!activeData ? (
                <Loading/>
            ) : (
                <Spin spinning={editingKey !== "" && showSpinner}>

                    <TabsArchived
                        setShowAlertArchived={setShowAlertArchived}
                        showAlertArchived={showAlertArchived}
                        content={
                            <>
                                <TabPane
                                    key={'1'}
                                    tab={<TabBadge
                                        text={'Aktiva fordon'}
                                        overflowCount={9999999}
                                        badgeColor={'#3f3f3f'}
                                        count={activeData?.length}
                                    />}
                                >
                                    {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                    <Table
                                        dataSource={activeData}
                                        columns={tableColumnsWithTooltip(columns)}
                                        scroll={{x: 2200}}
                                        {...tableProps}
                                        tableLayout={"fixed"}
                                    />
                                </TabPane>
                                <TabPane
                                    key={'2'}
                                    tab={<TabBadge
                                        text={'Arkiverade fordon'}
                                        overflowCount={9999999}
                                        badgeColor={'#3f3f3f'}
                                        showTooltip={false}
                                        count={archivedData?.length}
                                    />}
                                >
                                    {showAlertArchived && <AlertArchived message={STATIC_TEXT.messageArchivedAlert}/>}
                                    <ConfigProvider
                                        renderEmpty={() => customizeRenderEmpty("Du har inga arkiverade fordon")}>
                                        <Table
                                            dataSource={archivedData}
                                            columns={tableColumnsWithTooltip(columnsArchived)}
                                            scroll={{x: 1000}}
                                            rowClassName="editable-row"
                                            {...tableProps}
                                        />
                                    </ConfigProvider>
                                </TabPane>

                            </>
                        }
                    />

                </Spin>
            )}
        </Form>
    );

};

import React, {useContext, useEffect, useState} from 'react'
import * as icons from '@ant-design/icons'
import Text from "antd/es/typography/Text";
import {SuperAdminDevicesContext, SuperAdminDevicesContextProvider} from "../../../contexts/SuperAdminDevicesContext"
import ModalFormDeviceConnect from './ModalFormDeviceConnect'
import ModalFormDeviceDisplay from './ModalFormDeviceDisplay';
import ModalFormDeviceAVL from './ModalFormDeviceAVL';
import ModalFormDeviceLogg from './ModalFormDeviceLogg';
import ModalFormDeviceVehicle from './ModalFormDeviceVehicle';
import ModalFormDeviceSMS from './ModalFormDeviceSMS';
import ModalFormDeviceAdd from './ModalFormDeviceAdd';
import AddUnit from './AddUnit';
import {dataApi} from 'services/api/api';
import {
    Tooltip,
    Button,
    Checkbox,
    Input,
    message,
    Modal,
    Pagination,
    Progress,
    Table,
    Tabs,
    Typography,
} from 'antd';
import {DatePicker} from 'antd-v5';

import {
    applySearchFilter,
    color_calculations,
    filterInDrive,
    filterInStock,
    filterOnTheWay,
    filterRetired,
    filterWarnings,
    findOrgNameById,
    findVehicleById,
    formatters,
} from './superDeviceHelpers'
import {SEARCH_TERM} from '../../../services/redux/constants/authConstants'
import {
    CarNumberPlateIcon,
    ObdDeterIcon,
    PopConfirmDangerButton,
    PopConfirmWarningButton,
    TabBadge
} from './reusableComponents';
import {LastSeenLink} from '../shared-components/DatumLink'
import ModalFormDeviceChangeOrganization from "./ModalFormDeviceChangeOrganization";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {convertTimestampToDateStr} from "../../../utilities";
import {STATIC_TEXT, TAB_PANE_TILES} from "../../../lang/translate/sv-SE/sv-SE";
import {
    SD_BASE_COLORS,
    SD_BENEFIT_TYPES,
    SD_DEVICE_TYPES,
    SD_TAB_COLORS,
    SUPER_DEVICES_KEYS_AND_INDEXES,
    SUPER_DEVICES_MODAL_TYPES
} from "../../../constants/CustomConstant";
import {tableProps} from "../shared-components/sharedComponents";
import Loading from "../../shared-components/Loading";
import Flex from "../../shared-components/Flex";
import moment from 'moment';

const TabPane = Tabs.TabPane;

export const TableActionBtnType = 'link'

const DevicesViewWarnings = ({
                                 devices,
                                 vehicles,
                                 orgData,
                                 getOnomondoSMS,
                                 getCheckQueclinkHistory,
                                 getNewConfig,
                                 openUserModal,
                                 setWarningsCount,
                                 onHandleFwConfigReq,
                                 showModalWithConfig,
                                 searchText,
                                 selectedOrganizations
                             }) => {

    const suContext = useContext(SuperAdminDevicesContext)

    const onCpuResetClick = (device) => {
        console.log('On CPU reset click')

        const sDeviceVehicleId = device.installedVehicle.id
        console.log("-> sDeviceVehicleId", sDeviceVehicleId);
        const sDeviceOrgId = device.organizationId.organizationId //NOTE: organizationId in this case/view is an object containing the whole organization-object
        console.log("-> sDeviceOrgId", sDeviceOrgId);

        suContext.requestVehicleCpuReset({
            organizationId: sDeviceOrgId ? sDeviceOrgId : null,
            selectedDeviceId: sDeviceVehicleId,
        })
    }

    const onWebConnectClick = (device) => {
        console.log('On CPU reset click')

        const sDeviceVehicleId = device.installedVehicle.id
        console.log("-> sDeviceVehicleId", sDeviceVehicleId);
        const sDeviceOrgId = device.organizationId.organizationId //NOTE: organizationId in this case/view is an object containing the whole organization-object
        console.log("-> sDeviceOrgId", sDeviceOrgId);

        dataApi.postVehiclesWebConnect(sDeviceVehicleId)
            .then(i => {
                console.log(i)
                message.success("Lyckad web-connection")
            })


    }

    let row = {}

    const list = devices ? filterWarnings(devices) : []

    const vehiclesList = vehicles ? vehicles : []

    const dataSource = [...list].map(device => {

        row = {
            deviceId: device.deviceId ? device.deviceId : null,
            imei: device.imei ? device.imei : null,
            organizationId: device.organizationId ? findOrgNameById(device.organizationId, orgData) : null,
            iccid: device.iccid ? device.iccid : null,
            phone: device.phone ? formatters.phoneNumber(device.phone) : null,
            deviceType: device.deviceType ? device.deviceType : null,
            firmware: device.firmware ? device.firmware : '-',
            configName: device.configName ? device.configName : '-',
            installedVehicle: device.installedVehicle ? findVehicleById(device.installedVehicle, vehiclesList) : null,
            physicalInstallTimestamp: device.physicalInstallTimestamp ? formatters.millisToDate(device.physicalInstallTimestamp) : null,
            physicalLocation: device.physicalLocation ? device.physicalLocation : null,
            retired: device.retired ? device.retired : null,
            isTracker: device.isTracker,
            smsControl: device.smsControl,
            status: device.status ? {
                timestampMs: device.status.timestampMs ? device.status.timestampMs : null,
                serverTimestampMs: device.status.serverTimestampMs ? device.status.serverTimestampMs : null,
                uplinkLoss: device.status.uplinkLoss ? device.status.uplinkLoss : null,
                latestBatteryLevel: device.status.latestBatteryLevel ? device.status.latestBatteryLevel : null,
                latestSeenPositiveSpeed: device.status.latestSeenPositiveSpeed ? device.status.latestSeenPositiveSpeed : null,
            } : null
        }
        return row

    })


    const filteredDataSource = applySearchFilter(dataSource, searchText, selectedOrganizations)

    const columnSizing = {
        typeError: 100,
        phone: 150,
        imei: 150,
        firmware: 100,
        config: 150,
        obd: 100,
        batteryLevel: 100,
        vehicle: 100,
        driver: 100,
        heartBeat: 180,
        btns: 120,
        installedVehicle: 100,
    }


    const columns = [
        //TYPE_ERROR
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.typeError,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.typeError,
            title: STATIC_TEXT.TYPE_ERROR,
            width: columnSizing.typeError,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //PHONE_NUMBER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            title: STATIC_TEXT.PHONE_NUMBER,
            width: columnSizing.phone,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //IMEI
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            title: STATIC_TEXT.IMEI,
            width: columnSizing.imei,
            fixed: "",
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        // //ORGANIZATION
        // {
        //     key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
        //     dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.org,
        //     title: SUPER_DEVICES_STATIC_TEXT.IMEI,
        //     width: columnSizing.imei,
        //     render: (value) => {
        //         return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
        //     }
        // },
        //FIRMWARE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            title: STATIC_TEXT.FIRMWARE_SHORT,
            width: columnSizing.firmware,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }

        },
        //CONFIG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            title: STATIC_TEXT.CONFIG,
            width: columnSizing.config,
            ellipsis: true,
            render: (value, record) => {
                return value ? <a onClick={() => showModalWithConfig(record)}>{value}</a> : <Text> {'-'} </Text>
            }
        },
        //OBD
        {

            key: SUPER_DEVICES_KEYS_AND_INDEXES.obd,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.obd,
            title: STATIC_TEXT.OBD,
            width: columnSizing.obd,
            render: (value) => {
                return value ? <ObdDeterIcon obd={true}/> : <ObdDeterIcon obd={false}/>
            }
        },

        //BATTERY -> status.latestBatteryLevel ?
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            title: STATIC_TEXT.BATTERY,
            width: columnSizing.batteryLevel,
            render: (value) => {
                return value?.latestBatteryLevel ? <BatteryIcon batteryLevel={value.latestBatteryLevel}/> :
                    <Text>{'-'}</Text>
            }
        },
        //VEHICLE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.VEHICLE,
            width: columnSizing.vehicle,
            filters: SD_BENEFIT_TYPES.map((item) => {
                return {
                    value: item.TYPE,
                    text: item.TYPE_DISPLAY_TEXT
                }
            }),
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle]?.benefitType?.indexOf(value) === 0
            },
            render: (value) => {
                if (value === null) {
                    return "-"
                }
                return (
                    <>
                        <CarNumberPlateIcon text={value?.registry}/>
                        <Text style={{textAlign: 'left',}} disabled>{value?.displayName}</Text>
                    </>
                )
            }
        },
        //DRIVER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.DRIVER,
            width: columnSizing.driver,
            render: (value) => {
                return value?.defaultDriver?.displayName ? <Text>{value?.defaultDriver?.displayName}</Text> :
                    <Text> {'-'} </Text>
            }


        },
        //HEARTBEAT -> status.timestampMs?
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            title: STATIC_TEXT.HEART_BEAT,
            width: columnSizing.heartBeat,
            align: "right",
            render: (value, record) => {
                const title = (
                    <div style={{textAlign: "right"}}>
                        {"ts: "}
                        {value?.timestampMs
                            ? <LastSeenLink
                                value={value.timestampMs}
                                typ={"map"}
                                isTracker={record.isTracker}
                                formatTimestampToFullDate={true}/>
                            : "-"}
                        <br/>
                        {"svTs: "}
                        {value?.serverTimestampMs
                            ? <LastSeenLink
                                value={value.serverTimestampMs}
                                typ={"map"}
                                isTracker={record.isTracker}
                                formatTimestampToFullDate={true}/>
                            : "-"}
                    </div>
                );
                return value?.timestampMs ?
                        <LastSeenLink value={value.timestampMs}
                                      customTooltip={title}
                                      typ={"map"} isTracker={record.isTracker}/> :
                        <Text> {'-'} </Text>

            }
        },
        // TODO lag was a mock-data
        // {
        //     key: 'openLog',
        //     dataIndex: 'openLog',
        //     // title: '',
        //     title: <div style={{ background: '#2a3041', padding: 0, margin: 0 }}><Text></Text></div>,
        //     width: columnSizing.btns,
        //     fixed: 'right',
        //     render: (text, record, index) => {
        //         return (
        //
        //             <Button
        //                 style={{ width: '100%' }}
        //                 type={TableActionBtnType}
        //                 icon={<icons.FileTextOutlined />}
        //                 onClick={
        //                     () => {
        //                         console.log('DATA, ', row)
        //                         openUserModal(SUPER_DEVICES_MODAL_TYPES.LOGG, record)
        //
        //                     }
        //                 } >
        //
        //                 {SUPER_DEVICES_STATIC_TEXT.BTN_LOGG}
        //             </Button>
        //
        //         )
        //     }
        // },
        {
            key: 'openAVL',
            dataIndex: 'openAVL',
            title: '',
            width: columnSizing.btns,
            sticky: true,
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.BarChartOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.AVL, record)
                        }
                    >
                        {STATIC_TEXT.BTN_AVL}
                    </Button>

                )
            }
        },
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: '',
            width: columnSizing.btns,
            ellipsis: true,
            render: (value, record, index) => {
                return value ? (

                    <PopConfirmDangerButton
                        title={STATIC_TEXT.POP_CONFIRM_QUESTION_CPU_RESET}
                        setOkText={STATIC_TEXT.BTN_RESET}
                        setCancelText={STATIC_TEXT.BTN_ABORT}
                        onConfirmFunc={() => onCpuResetClick(record)}
                        buttonProps={{
                            type: TableActionBtnType,
                            icon: <icons.WarningOutlined/>,
                            text: STATIC_TEXT.BTN_CPU_RESET
                        }}/>

                ) : null
            }
        },
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: '',
            width: columnSizing.btns,
            ellipsis: true,
            render: (value, record, index) => {
                return value ? (

                    <PopConfirmDangerButton
                        title={STATIC_TEXT.POP_CONFIRM_QUESTION_WEB_CONNECCT}
                        setOkText={STATIC_TEXT.BTN_RESET}
                        setCancelText={STATIC_TEXT.BTN_ABORT}
                        onConfirmFunc={() => onWebConnectClick(record)}
                        buttonProps={{
                            type: TableActionBtnType,
                            icon: <icons.WarningOutlined/>,
                            text: STATIC_TEXT.BTN_WEB_CONNECT
                        }}/>

                ) : null
            }
        },
        {
            key: 'openGetInfo',
            dataIndex: 'openGetInfo',
            title: '',
            width: columnSizing.btns,
            ellipsis: true,
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.InfoCircleOutlined/>}
                        onClick={
                            () => {
                                openUserModal(SUPER_DEVICES_MODAL_TYPES.C12SMS, record)
                            }

                        }
                    >
                        {STATIC_TEXT.BTN_GET_INFO}
                    </Button>


                )
            }
        },

        {
            key: 'openGetInfo',
            dataIndex: 'separateStartConfig',
            title: '',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => {
                let selectedTime = null;

                const handleTimeChange = (time) => {
                    const utcTime = time?.toISOString()
                    const getDate = new Date(utcTime);
                    const hours = getDate.getUTCHours().toString().padStart(2, '0');
                    const minutes = getDate.getUTCMinutes().toString().padStart(2, '0');
                    const timeHHMM = hours + minutes;

                    selectedTime = timeHHMM;
                };

                const handleGetNewConfig = () => {
                    Modal.confirm({
                        title: 'Hämta grundconfig för ' + record.imei,
                        content: (
                            <div>
                                <p>Är du säker att du vill hämta grundconfig på nytt för denna enhet?</p>
                                <p>Vill du ändra tidspunkt för uppdatering? Välj tid eller lämna den tom:</p>
                                <DatePicker.TimePicker changeOnBlur format={'HH:mm'}
                                            onChange={handleTimeChange}/>
                            </div>
                        ),
                        onOk: () => {
                            getNewConfig(record.deviceId, selectedTime);
                        },
                    });
                };

                return record?.isTracker && (
                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.ToolOutlined/>}
                        onClick={handleGetNewConfig}
                    >
                        Hämta grundconfig på nytt
                    </Button>
                );
            }
        },
        {
            key: 'smsControl',
            dataIndex: 'smsControl',
            title: '',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (!record?.isTracker) return

                let icon = record.smsControl ? <icons.CheckOutlined style={{color: SD_BASE_COLORS.GREEN}}/>
                    : <icons.CloseOutlined style={{color: SD_BASE_COLORS.RED,}}/>
                return (
                    <Flex alignItems={"center"}>
                        <Button
                            style={{}}
                            type={TableActionBtnType}
                            icon={<icons.ToolOutlined/>}
                            onClick={
                                () => {
                                    getCheckQueclinkHistory(record.deviceId)
                                    console.log(record)
                                }

                            }
                        >
                            Kontrollera SMS
                        </Button>
                        {record.smsControl !== undefined ? icon : record.smsControl}
                    </Flex>


                )
            }
        },
        {
            key: 'separateStartConfig',
            dataIndex: 'separateStartConfig',
            title: '',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    record?.isTracker && <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.ToolOutlined/>}
                        onClick={
                            () => {
                                getOnomondoSMS(record.deviceId)
                            }

                        }
                    >
                        Skicka SMS onomondo
                    </Button>


                )
            }
        },
        {
            title: '',
            width: columnSizing.btns,
            render: (record) => {
                return (
                    !record.isTracker && <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.DownloadOutlined/>}
                        onClick={() => onHandleFwConfigReq(record)}
                    >
                        {STATIC_TEXT.CONFIG}
                    </Button>

                )
            }
        },
    ]

    // TODO move up the filter one level
    useEffect(() => {
        setWarningsCount(filteredDataSource.length)
    }, [filteredDataSource.length])
    console.debug('Rendering warning device tab with data: ', filteredDataSource)
    return <BaseTable dataSource={filteredDataSource} columns={columns}/>
}
//row_key={device => device = [...dataSource].deviceId}

const DevicesViewInStock = ({
                                devices,
                                vehicles,
                                orgData,
                                openUserModal,
                                setInStockCount,
                                handleRetireClick,
                                onHandleFwConfigReq,
                                showModalWithConfig,
                                searchText,
                                selectedOrganizations
                            }) => {

    let row = {}

    const list = devices ? filterInStock(devices) : []

    const vehiclesList = vehicles ? vehicles : []

    let dataSource = [...list].map(device => {

        row = {
            deviceId: device.deviceId ? device.deviceId : null,
            imei: device.imei ? device.imei : '-',
            organizationId: device.organizationId ? findOrgNameById(device.organizationId, orgData) : '-',
            iccid: device.iccid ? device.iccid : '-',
            timestampStartOfUse: device.timestampStartOfUse ? device.timestampStartOfUse : null,
            phone: device.phone ? formatters.phoneNumber(device.phone) : '-',
            deviceType: device.deviceType ? device.deviceType : null,
            firmware: device.firmware ? device.firmware : '-',
            configName: device.configName ? device.configName : '-',
            installedVehicle: device.installedVehicle ? findVehicleById(device.installedVehicle, vehiclesList) : '-', // MATCHA VEHICLE HÄR
            physicalInstallTimestamp: device.physicalInstallTimestamp ? formatters.millisToDate(device.physicalInstallTimestamp) : '-',
            physicalLocation: device.physicalLocation ? device.physicalLocation : "-",
            retired: device.retired ? device.retired : null,
            isTracker: device.isTracker,
            status: device.status ? {
                timestampMs: device.status.timestampMs ? device.status.timestampMs : null,
                uplinkLoss: device.status.uplinkLoss ? device.status.uplinkLoss : null,
                latestBatteryLevel: device.status.latestBatteryLevel ? device.status.latestBatteryLevel : null,
                latestSeenPositiveSpeed: device.status.latestSeenPositiveSpeed ? device.status.latestSeenPositiveSpeed : null,
            } : null
        }

        return row

    })

    /*const DataApi2 = {
        put: (orgId, deviceId, payload) => {
            return new Promise((resolve, reject) => {
                fetch({
                    url: '/admin/devices/' + deviceId,
                    method: "PATCH",
                    headers: {
                        "X-Trackson-Organization": orgId,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                      },
                      data: payload,
                }).then(r => {
                    console.log('PUT device', r)
                    console.log('payload: ', payload)
                    console.log('orgId: ', orgId)
                    console.log('deviceId: ', deviceId)
                    return r
                }).catch(r => {
                    console.log(r)
                    resolve(r)
                    return r
                })
            })
        },

    }*/


    const [firmwareFill, setFirmwareFill] = useState()
    const [configFill, setConfigFill] = useState()
    //let imeiExample = '354017119827117'


    const filteredDataSource = applySearchFilter(dataSource, searchText, selectedOrganizations)

    const columnSizing = {
        selected: 50,
        type: 100,
        imei: 150,
        phone: 100,
        firmware: 50,
        config: 150,
        btns: 110,
    }


    const columns = [
        //CHOOSE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.selected,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.selected,
            title: STATIC_TEXT.CHOOSE,
            width: columnSizing.selected,
            //TODO CHECK TRUE/FALSE
            render: (value) => {
                return value ? <Text>{value}</Text> : <Checkbox/>
            }
        },
        //TYPE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            title: STATIC_TEXT.TYPE,
            width: columnSizing.type,
            filters: SD_DEVICE_TYPES,
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.deviceType]?.indexOf(value) === 0
            },
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }
        },

        //IMEI
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            title: STATIC_TEXT.IMEI,
            width: columnSizing.imei,
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }
        },
        //ICCID
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.iccid,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.iccid,
            title: STATIC_TEXT.ICCID,
            width: columnSizing.imei,
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }
        },
        //PHONE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            title: STATIC_TEXT.PHONE_NUMBER,
            width: columnSizing.phone,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //timestampStartOfUse
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.timestampStartOfUse,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.timestampStartOfUse,
            title: STATIC_TEXT.START_OF_USE,
            width: columnSizing.phone,
            render: (value) => {
                return value ? convertTimestampToDateStr(value, 'YYYY-MM-DD', true) : '-'
            }
        },
        //FIRMWARE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            title: STATIC_TEXT.FIRMWARE_SHORT,
            width: columnSizing.firmware,
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }

        },
        //CONFIG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            title: STATIC_TEXT.CONFIG,
            width: columnSizing.config,
            ellipsis: true,
            render: (value, record) => {
                return value ? <a onClick={() => showModalWithConfig(record)}>{value}</a> : <Text> {'-'} </Text>
            }
        },
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_connect_customer,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_connect_customer,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <Button
                        type={'link'}
                        style={{width: '100%', color: SD_BASE_COLORS.GREEN}}
                        icon={<icons.UserSwitchOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.CONNECT, record)
                        }
                    >
                        {STATIC_TEXT.BTN_CONNECT_TO_CUSTOMER}
                    </Button>
                )
            }
        },
        {
            //TODO: Retire here will fail atm since orgId === undefined on these
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <PopConfirmWarningButton
                        title={STATIC_TEXT.POP_CONFIRM_QUESTION_RETIRE_UNIT}
                        setOkText={STATIC_TEXT.BTN_RETIRE}
                        setCancelText={STATIC_TEXT.BTN_ABORT}
                        onConfirmFunc={() => handleRetireClick(record)}
                        buttonProps={{
                            type: TableActionBtnType,
                            icon: <icons.FileExcelOutlined/>,
                            text: STATIC_TEXT.BTN_RETIRE
                        }}/>

                )
            }
        },
        {
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (record) => {
                return (
                    !record.isTracker && <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.DownloadOutlined/>}
                        onClick={() => onHandleFwConfigReq(record)}
                    >
                        {STATIC_TEXT.CONFIG}
                    </Button>

                )
            }
        },
    ]
    useEffect(() => {
        setInStockCount(filteredDataSource.length)
    }, [filteredDataSource.length])
    return <BaseTable dataSource={filteredDataSource} columns={columns}/>
}

const DevicesViewOnTheWay = ({
                                 devices,
                                 vehicles,
                                 orgData,
                                 openUserModal,
                                 handleOrg,
                                 showModalWithConfig,
                                 onHandleFwConfigReq,
                                 setOnTheWayCount,
                                 handleRetireClick,
                                 searchText,
                                 selectedOrganizations
                             }) => {

    let row = {}

    const list = devices ? filterOnTheWay(devices) : []

    const vehiclesList = vehicles ? vehicles : []

    const dataSource = [...list].map(device => {

        row = {
            deviceId: device.deviceId ? device.deviceId : null,
            imei: device.imei ? device.imei : null,
            organizationId: device.organizationId ? findOrgNameById(device.organizationId, orgData) : null,
            iccid: device.iccid ? device.iccid : null,
            phone: device.phone ? formatters.phoneNumber(device.phone) : null,
            deviceType: device.deviceType ? device.deviceType : null,
            firmware: device.firmware ? device.firmware : '-',
            configName: device.configName ? device.configName : '-',
            installedVehicle: device.installedVehicle ? findVehicleById(device.installedVehicle, vehiclesList) : null,
            physicalInstallTimestamp: device.physicalInstallTimestamp ? formatters.millisToDate(device.physicalInstallTimestamp) : null,
            physicalLocation: device.physicalLocation ? device.physicalLocation : null,
            isTracker: device.isTracker,
            status: device.status ? {
                timestampMs: device.status.timestampMs ? device.status.timestampMs : null,
                uplinkLoss: device.status.uplinkLoss ? device.status.uplinkLoss : null,
                latestBatteryLevel: device.status.latestBatteryLevel ? device.status.latestBatteryLevel : null,
                latestSeenPositiveSpeed: device.status.latestSeenPositiveSpeed ? device.status.latestSeenPositiveSpeed : null,
            } : null
        }
        return row

    })

    const filteredDataSource = applySearchFilter(dataSource, searchText, selectedOrganizations)

    const columnSizing = {
        org: 100,
        type: 50,
        imei: 100,
        vehicle: 50,
        driver: 75,
        connected: 50,
        btns: 75,
        firmware: 100,
        config: 100,
    }


    const columns = [
        //ORG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            title: STATIC_TEXT.ORG,
            width: columnSizing.org,
            filters: orgData?.map((item) => {
                return {
                    value: item.fullName,
                    text: item.fullName
                }
            }),
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.org]?.fullName?.indexOf(value) === 0
            },
            render: (value) => {
                return value?.organizationId ? <Text>{value.fullName}</Text> : <Text> {'-'} </Text>
            }
        },
        //TYPE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            title: STATIC_TEXT.TYPE,
            width: columnSizing.type,
            filters: SD_DEVICE_TYPES,
            onFilter: (value, record) => {
                return record.deviceType?.indexOf(value) === 0
            },
            // sorter: (a, b) => a.deviceType?.length - b.deviceType?.length,
            render: (value) => {
                return value ? <Text>{value}</Text> : ''
            }
        },
        //IMEI
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            title: STATIC_TEXT.IMEI,
            width: columnSizing.imei,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //FIRMWARE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.firmware,
            title: STATIC_TEXT.FIRMWARE_SHORT,
            width: columnSizing.firmware,
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }

        },
        //CONFIG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.config,
            title: STATIC_TEXT.CONFIG,
            width: columnSizing.config,
            ellipsis: true,
            render: (value, record) => {
                return value ? <a onClick={() => showModalWithConfig(record)}>{value}</a> : <Text> {'-'} </Text>
            }
        },
        //VEHICLE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.VEHICLE,
            width: columnSizing.vehicle,
            render: (value) => {
                if (!value)
                    return <Text>{'-'}</Text>
                return value?.registry ?
                    <div>
                        <CarNumberPlateIcon text={value?.registry}/>
                        {value.displayName}
                    </div>
                    : <Text> {value?.displayName} </Text>
            }
        },
        //DRIVER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.DRIVER,
            width: columnSizing.driver,
            render: (value) => {
                return value?.defaultDriver?.displayName ? <Text>{value?.defaultDriver?.displayName}</Text> :
                    <Text> {'-'} </Text>
            }
        },
        //CONNECTED
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.connected,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.connected,
            title: STATIC_TEXT.CONNECTED,
            width: columnSizing.connected,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        // //BTN_TO_WARE_HOUSE
        // {
        //     key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_to_storage,
        //     dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_to_storage,
        //     title: '',
        //     width: columnSizing.btns,
        //     fixed: 'right',
        //     render: (value, record) => {
        //         return (
        //
        //             <Button
        //                 style={{ width: '100%' }}
        //                 type={TableActionBtnType}
        //                 onClick={
        //                     () => {
        //                         message.info('On the way - To Warehouse - onClick')
        //                         handleOrg(record, null)
        //                     }
        //                 }
        //             >
        //                 {SUPER_DEVICES_STATIC_TEXT.BTN_TO_STORAGE}
        //             </Button>
        //
        //         )
        //     }
        // },
        //BTN_CHANGE_ORG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (value, record, index) => {
                //console.log('INDEX::CHANGE_ORG::', value)

                return value ? (
                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.HomeOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.CHANGE_ORG, record)
                        }

                    >
                        {STATIC_TEXT.BTN_CHANGE_ORG}
                    </Button>
                ) : null

            }
        },
        //BTN_RETIRE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <PopConfirmWarningButton
                        title={STATIC_TEXT.POP_CONFIRM_QUESTION_RETIRE_UNIT}
                        setOkText={STATIC_TEXT.BTN_RETIRE}
                        setCancelText={STATIC_TEXT.BTN_ABORT}
                        onConfirmFunc={() => handleRetireClick(record)}
                        buttonProps={{
                            type: TableActionBtnType,
                            icon: <icons.FileExcelOutlined/>,
                            text: STATIC_TEXT.BTN_RETIRE
                        }}/>

                )
            }
        },
        {
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (record) => {
                return (
                    !record.isTracker && <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.DownloadOutlined/>}
                        onClick={() => onHandleFwConfigReq(record)}
                    >
                        {STATIC_TEXT.CONFIG}
                    </Button>

                )
            }
        },
    ]
    useEffect(() => {
        setOnTheWayCount(filteredDataSource.length)
    }, [filteredDataSource.length])
    return <BaseTable dataSource={filteredDataSource} columns={columns}/>
}

const DevicesViewInDrive = ({
                                devices,
                                vehicles,
                                orgData,
                                openUserModal,
                                setInDriveCount,
                                handleRetireClick,
                                onHandleFwConfigReq,
                                searchText,
                                selectedOrganizations
                            }) => {

    let row = {}

    const list = devices ? filterInDrive(devices) : []

    const vehiclesList = vehicles ? vehicles : []

    const dataSource = [...list].map(device => {
        row = {
            deviceId: device.deviceId ? device.deviceId : null,
            imei: device.imei ? device.imei : null,
            organizationId: device.organizationId ? findOrgNameById(device.organizationId, orgData) : null,
            iccid: device.iccid ? device.iccid : null,
            phone: device.phone ? formatters.phoneNumber(device.phone) : null,
            deviceType: device.deviceType ? device.deviceType : null,
            firmware: device.firmware ? device.firmware : '-',
            configName: device.configName ? device.configName : '-',
            dataUsed: device.dataUsed ? device.dataUsed : '-',
            installedVehicle: device.installedVehicle ? findVehicleById(device.installedVehicle, vehiclesList) : null,
            physicalInstallTimestamp: device.physicalInstallTimestamp ? formatters.millisToDate(device.physicalInstallTimestamp) : null,
            physicalLocation: device.physicalLocation ? device.physicalLocation : null,
            retired: device.retired ? device.retired : null,
            isTracker: device.isTracker,
            status: device.status ? {
                timestampMs: device.status.timestampMs ? device.status.timestampMs : null,
                uplinkLoss: device.status.uplinkLoss ? device.status.uplinkLoss : null,
                latestBatteryLevel: device.status.latestBatteryLevel ? device.status.latestBatteryLevel : null,
                latestSeenPositiveSpeed: device.status.latestSeenPositiveSpeed ? device.status.latestSeenPositiveSpeed : null,
            } : null
        }
        return row
    })

    const filteredDataSource = applySearchFilter(dataSource, searchText, selectedOrganizations)

    const columnSizing = {
        operationalAt: 75,
        org: 100,
        type: 100,
        imei: 150,
        heartBeat: 75,
        vehicle: 100,
        milleAge: 100,
        driver: 125,
        travels: 50,
        btns: 120,
    }

    console.debug("Superadmin devices in drive, orgData =", orgData)
    const columns = [
        //OPERATIONAL
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.operationalAt,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.operationalAt,
            title: STATIC_TEXT.OPERATIONAL_AT,
            width: columnSizing.operationalAt,
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }
        },
        //ORG
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.org,
            title: STATIC_TEXT.ORG,
            width: columnSizing.org,
            // TODO this filter causes a state update in an unmounted component????
            filters: orgData?.map((item) => {
                return {
                    value: item.fullName,
                    text: item.fullName
                }
            }),
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.org]?.fullName?.indexOf(value) === 0
            },
            render: (value) => {
                return value?.fullName ? <Text>{value.fullName}</Text> : <Text> {'-'} </Text>
            }
        },
        //TYPE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.deviceType,
            title: STATIC_TEXT.TYPE,
            width: columnSizing.type,
            filters: SD_DEVICE_TYPES,
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.deviceType]?.indexOf(value) === 0
            },
            render: (value) => {
                return value ? <Text>{value}</Text> : '-'
            }
        },
        //IMEI
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            title: STATIC_TEXT.IMEI,
            width: columnSizing.imei,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //HEARTBEAT -> status.timestampMs?
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            title: STATIC_TEXT.HEART_BEAT,
            width: columnSizing.heartBeat,
            render: (value, record) => {
                return value?.timestampMs ?
                    <LastSeenLink value={value.timestampMs} typ={"map"} isTracker={record.isTracker}/> :
                    <Text> {'-'} </Text>
            }
        },
        //VEHICLE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.VEHICLE,
            width: columnSizing.vehicle,
            render: (value) => {
                if (!value)
                    return <Text>{'-'}</Text>
                return value?.registry ?
                    <div>
                        <CarNumberPlateIcon text={value?.registry}/>
                        {value.displayName}
                    </div>
                    : <Text> {value?.displayName} </Text>
            }
        },
        //MILEAGE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.MILE_AGE,
            width: columnSizing.milleAge,
            render: (value) => {
                return value?.state?.odometer ? <Text>{`${value.state.odometer} km`}</Text> : <Text> {'-'} </Text>
            }
        },
        //DRIVER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.DRIVER,
            width: columnSizing.driver,
            render: (value) => {
                return value?.defaultDriver?.displayName ? <Text>{value?.defaultDriver?.displayName}</Text> :
                    <Text> {'-'} </Text>
            }
        },
        //TRAVELS
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.travels,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.travels,
            title: STATIC_TEXT.TRAVELS,
            width: columnSizing.travels,
            //TEMP RENDER
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        {
            key: 'openAVL',
            dataIndex: 'openAVL',
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            sticky: true,
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.BarChartOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.AVL, record)
                        }
                    >
                        {STATIC_TEXT.BTN_AVL}
                    </Button>

                )
            }
        },
        //BTN_DISPLAY_UNIT
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_unit,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_unit,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.AppstoreOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.DISPLAY_UNIT, record)
                        }>
                        {STATIC_TEXT.BTN_DISPLAY_UNIT}
                    </Button>

                )
            }
        },
        //BTN_DISPLAY_VEHICLE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_vehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_vehicle,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.CarOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.VEHICLE, record)
                        }>
                        {STATIC_TEXT.BTN_DISPLAY_VEHICLE}
                    </Button>

                )
            }
        },

        //BTN_RETIRE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_retire,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <PopConfirmWarningButton
                        title={STATIC_TEXT.POP_CONFIRM_QUESTION_RETIRE_UNIT}
                        setOkText={STATIC_TEXT.BTN_RETIRE}
                        setCancelText={STATIC_TEXT.BTN_ABORT}
                        onConfirmFunc={() => handleRetireClick(record)}
                        buttonProps={{
                            type: TableActionBtnType,
                            icon: <icons.FileExcelOutlined/>,
                            text: STATIC_TEXT.BTN_RETIRE
                        }}/>

                )
            }
        },
        //FW & CONFIG
        {
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (record) => {
                return (
                    !record.isTracker && <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.DownloadOutlined/>}
                        onClick={() => onHandleFwConfigReq(record)}
                    >
                        {STATIC_TEXT.CONFIG}
                    </Button>

                )
            }
        },
    ]

    useEffect(() => {
        setInDriveCount(filteredDataSource.length)
    }, [filteredDataSource.length]);
    return <BaseTable dataSource={filteredDataSource} columns={columns}/>
}

const DevicesViewRetired = ({
                                devices,
                                vehicles,
                                orgData,
                                openUserModal,
                                setRetiredCount,
                                searchText,
                                selectedOrganizations
                            }) => {
    const suContext = useContext(SuperAdminDevicesContext)
    let row = {}

    const list = devices ? filterRetired(devices) : []

    const vehiclesList = vehicles ? vehicles : []

    const dataSource = [...list].map(device => {

        row = {
            deviceId: device.deviceId ? device.deviceId : null,
            imei: device.imei ? device.imei : null,
            organizationId: device.organizationId ? findOrgNameById(device.organizationId, orgData) : null,
            iccid: device.iccid ? device.iccid : null,
            phone: device.phone ? formatters.phoneNumber(device.phone) : null,
            deviceType: device.deviceType ? device.deviceType : null,
            installedVehicle: device.installedVehicle ? findVehicleById(device.installedVehicle, vehiclesList) : null,
            physicalInstallTimestamp: device.physicalInstallTimestamp ? formatters.millisToDate(device.physicalInstallTimestamp) : null,
            physicalLocation: device.physicalLocation ? device.physicalLocation : null,
            retired: device.retired ? device.retired : null,
            status: device.status ? {
                timestampMs: device.status.timestampMs ? device.status.timestampMs : null,
                uplinkLoss: device.status.uplinkLoss ? device.status.uplinkLoss : null,
                latestBatteryLevel: device.status.latestBatteryLevel ? device.status.latestBatteryLevel : null,
                latestSeenPositiveSpeed: device.status.latestSeenPositiveSpeed ? device.status.latestSeenPositiveSpeed : null,
            } : null
        }
        return row

    })

    const filteredDataSource = applySearchFilter(dataSource, searchText, selectedOrganizations)

    const columnSizing = {
        typeError: 100,
        phone: '15%',
        imei: '15%',
        firmware: 100,
        config: 150,
        obd: 100,
        batteryLevel: 100,
        vehicle: '15%',
        driver: 100,
        heartBeat: 100,
        btns: 50,
        installedVehicle: 100,
    }


    const unRetireDevice = (device) => {
        const sDeviceId = device.deviceId
        const sDeviceOrgId = device.organizationId.organizationId //NOTE: organizationId in this case/view is an object containing the whole organization-object

        suContext.requestUpdateDeviceById({
            organizationId: sDeviceOrgId ? sDeviceOrgId : null,
            selectedDeviceId: sDeviceId,
            deviceUpdateData: {
                retired: false
            }
        })
    }


    const columns = [
        //PHONE_NUMBER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            title: STATIC_TEXT.PHONE_NUMBER,
            width: columnSizing.phone,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //IMEI
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            title: STATIC_TEXT.IMEI,
            width: columnSizing.imei,
            render: (value) => {
                return value ? <Text>{value}</Text> : <Text> {'-'} </Text>
            }
        },
        //VEHICLE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.VEHICLE,
            width: columnSizing.vehicle,
            filters: SD_BENEFIT_TYPES.map((item) => {
                return {
                    value: item.TYPE,
                    text: item.TYPE_DISPLAY_TEXT
                }
            }),
            onFilter: (value, record) => {
                return record[SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle]?.benefitType?.indexOf(value) === 0
            },
            render: (value) => {
                return value?.benefitType ? <Text>{value.benefitType}</Text> : <Text>{'-'}</Text>
            }
        },
        //DRIVER
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.installedVehicle,
            title: STATIC_TEXT.DRIVER,
            width: columnSizing.driver,
            render: (value) => {
                return value?.currentDriver?.displayName ? <Text>{value.currentDriver.displayName}</Text> :
                    <Text> {'-'} </Text>
            }
        },
        {
            key: 'openAVL',
            dataIndex: 'openAVL',
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            sticky: true,
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.BarChartOutlined/>}
                        onClick={
                            () => openUserModal(SUPER_DEVICES_MODAL_TYPES.AVL, record)
                        }
                    >
                        {STATIC_TEXT.BTN_AVL}
                    </Button>

                )
            }
        },
        //FOR TESTING RESPONSE
        {
            key: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_vehicle,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.btn_display_vehicle,
            title: '',
            width: columnSizing.btns,
            fixed: 'right',
            render: (text, record, index) => {
                return (

                    <Button
                        style={{width: '100%'}}
                        type={TableActionBtnType}
                        icon={<icons.ImportOutlined/>}
                        onClick={
                            () => unRetireDevice(record)
                        }>
                        {STATIC_TEXT.BTN_UN_RETIRE}
                    </Button>

                )
            }
        },
    ]
    useEffect(() => {
        setRetiredCount(filteredDataSource.length)
    }, [filteredDataSource.length]);
    return <BaseTable dataSource={filteredDataSource} columns={columns}/>
}


const BaseTable = ({dataSource, columns}) => {

    function onShowSizeChange(current, pageSize) {
        console.log('pageSize: ', current, pageSize);
    }

    const pagination = <Pagination pageSize={20} onChange={onShowSizeChange} defaultPageSize={20} defaultCurrent={20}/>
    // const pagination= { pageSize: 20,onChange={onShowSizeChange }

    const tableState = useState({
        bordered: false,
        loading: false,
        pagination,
        size: 'middle',
        title: false,
        showSizeChanger: false,
        showHeader: true,
        rowSelection: false,
        scroll: {x: 1300},
        // scroll: false,
        hasData: true,
        tableLayout: undefined,
        top: 'none',
        bottom: 'bottomRight',
    })

    return (
        <Table
            {...tableState[0]}
            sticky
            {...tableProps}
            dataSource={dataSource}
            columns={columns}
            // pagination={{ pageSize: pageSizeValue }}
        />
    );

};

const BatteryIcon = ({batteryLevel}) => {

    return <div><Progress type="dashboard" percent={batteryLevel} width={30}
                          strokeColor={color_calculations.determineBatteryColor(batteryLevel)}/></div>
}

const TabTable = () => {

    // let id = 0

    const [selectedDevice, setSelectedDevice] = useState([])
    const [vehicles, setVehicles] = useState({})
    const [visible, setVisible] = useState(false);
    const [useModal, setUseModal] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedOrganizations, setSelectedOrganization] = useState()

    const [warningsCount, setWarningsCount] = useState(0);
    const [inStockCount, setInStockCount] = useState(0);
    const [onTheWayCount, setOnTheWayCount] = useState(0)
    const [inDriveCount, setInDriveCount] = useState(0)
    const [retiredCount, setRetiredCount] = useState(0)

    // TODO get rid of this dispatch by refactoring remaining redux stuff
    const suContext = useContext(SuperAdminDevicesContext)

    // const orgLoaded = useSelector(store => store?.superOrgs?.LOADED)
    // const orgData = useSelector(store => store?.superOrgs?.RESULT)
    const orgLoaded = suContext.orgs.LOADED
    const orgData = suContext.orgs.RESULT

    const deviceLoaded = suContext.devices.LOADED
    const deviceData = suContext.devices.RESULT
    const deviceUpdateMessage = suContext.devices.MESSAGE_DEVICE_UPDATE

    console.debug("Superadmin context in tabtable: ", suContext)
    const vehiclesLoaded = suContext.vehicles.LOADED
    const vehiclesData = suContext.vehicles.RESULT

    // const usersLoaded = useSelector(store => store?.users?.LOADED)
    // const usersData = useSelector(store => store?.users?.RESULT)


    const retiredTabTitle = <Text style={{color: SD_TAB_COLORS.RETIRED}}>{TAB_PANE_TILES.RETIRED}</Text>;


    const updateSearchedText = (value) => {
        setSearchText(value || '')
    }

    useEffect(() => {
        //COUNTS
        if (deviceData?.length > 0) {
            const data = applySearchFilter(deviceData, searchText, selectedOrganizations)
            setWarningsCount(filterWarnings(data)?.length)
            setInStockCount(filterInStock(data)?.length)
            setOnTheWayCount(filterOnTheWay(data)?.length)
            setInDriveCount(filterInDrive(data)?.length)
            setRetiredCount(filterRetired(data)?.length)
        }
    }, [deviceData, searchText, selectedOrganizations])

    // TODO this is not how useEffect works, should be properly refactored
    useEffect(() => {
        if (orgLoaded) {
            console.log('ORGDATA', orgData)
            return
        }
        // suContext.requestFetchOrganizations()
    }, [])

    useEffect(() => {
        if (deviceLoaded) {
            // console.log('DEVICEDATA', deviceData)
            return
        }
        // suContext.requestFetchDevices()
    },)

    useEffect(() => {
        if (vehiclesLoaded) {
            console.log('VEHICLEDATA', vehiclesData)
            return
        }
        // suContext.requestFetchVehicles()
    }, [])

    // useEffect(() => {
    //     if (usersLoaded) {
    //         // console.log('USERSDATA', usersData)
    //         return
    //     }
    // }, [])

    useEffect(() => {
        // console.log('DEVICE::UPDATE:TYPE:::', deviceUpdateMessage)
        if (!deviceUpdateMessage) return;

        if (deviceUpdateMessage.type === 'SUCCESS') {
            message.success(deviceUpdateMessage.message);
        } else if (deviceUpdateMessage.type === 'ERROR') {
            message.error(deviceUpdateMessage.message);
        } else {

        }
    }, [deviceUpdateMessage])

    useEffect(() => {
        console.log('CURRENT::SEARCH::TEXT', searchText)
    }, [searchText])

    useEffect(() => {
        const search = localStorage.getItem(SEARCH_TERM)
        if (search) {
            setSearchText(search.toLowerCase())
            localStorage.removeItem(SEARCH_TERM)
        }

    }, [])

    const onHandleFwConfigReq = (device) => {

        dataApi.getConfigFromFota(device.imei).then(r => {
            console.log('row IMEI is: ', device.imei);
            console.log('row TEL is: ', device.phone);
            console.log("responce payload: ", r);
            console.log('Firmware: ', r.current_firmware);
            console.log('Configuration: ', r.current_configuration);

            let updateObj = {firmware: r.current_firmware, configName: r.current_configuration}

            suContext.requestFetchDevices()

        })

    }

    function showModalWithConfig(record) {
        console.log(record)
        Modal.info({
            title: 'Config för IMEI ' + record?.imei,
            content: (
                record?.configName
            ),
            onOk() {
            },
        });
    }

    const openUserModal = (modal_type, device) => {

        setSelectedDevice({
            ...selectedDevice, device
        })
        setUseModal(modal_type)
        setVisible(true)
    }

    const getNewConfig = (deviceId, specifiedTimeOfDay) => {
        dataApi.separateStartConfig(deviceId, specifiedTimeOfDay).then(_ =>
            message.success("Din förfrågan skickades")
        )
    }

    const getOnomondoSMS = (deviceId) => {
        dataApi.getOnomondoSMS(deviceId).then(_ =>
            message.success("Din SMS förfrågan skickades")
        )
    }

    const getCheckQueclinkHistory = (deviceId) => {
        dataApi.getCheckQueclinkHistory(deviceId).then(res => {
            const smsAckCount = res?.filter(item => item?.smsAck === true)?.length;
            const ackCount = res?.filter(item => item?.ack === true)?.length;
            const check = smsAckCount >= 4 && ackCount >= 2;

            suContext.requestUpdateDeviceById({
                selectedDeviceId: deviceId,
                deviceUpdateData: {smsControl: check}
            })
            message.success("Din SMS förfrågan skickades")
        }).catch(() => {
            suContext.requestUpdateDeviceById({
                selectedDeviceId: deviceId,
                deviceUpdateData: {smsControl: false}
            })
        })
    }

    const handleClose = () => {
        console.log('Trying to close modal...')
        setVisible(false)
        setUseModal(null)
    }

    const handleAdd = () => {
        throw new Error("use redux")
    }

    const handleRetireClick = (device) => {

        const sDeviceId = device.deviceId
        const sDeviceOrgId = device.organizationId.organizationId //NOTE: organizationId in this case/view is an object containing the whole organization-object
        // message.info(`On Click - Retire device - With deviceId: ${sDeviceId}`)
        console.log('Retire device with deviceId => ', sDeviceId)
        console.log('Retire device with deviceOrgId => ', sDeviceOrgId)

        suContext.requestUpdateDeviceById({
            organizationId: sDeviceOrgId ? sDeviceOrgId : null,
            selectedDeviceId: sDeviceId,
            deviceUpdateData: {
                retired: true
            }
        })
        /*
         */

    }

    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
        console.log(value)
        // handleSelectOrganization(value)
    };

    const handleOrg = (device, organizationId) => {
        console.debug("STATE MOVE DEVICE", device, suContext.devices)
        // handleChangeOrganization(device, organizationId)
        console.debug("STATE MOVE, CHANGED ORG", device, suContext.devices)

        const sDeviceId = device.deviceId
        const sDeviceOrgId = device.organizationId.organizationId //NOTE: organizationId in this case/view is an object containing the whole organization-object
        // message.info(`On Click - Retire device - With deviceId: ${sDeviceId}`)
        console.log('Retire device with deviceId => ', sDeviceId)
        console.log('Retire device with deviceOrgId => ', sDeviceOrgId)
        console.log('Retire device with orgId => ', organizationId)

        //TODO This one doesn't work?
        console.log(organizationId)
        console.debug("STATE MOVE, PRE REQ", device, suContext.devices)
        suContext.requestUpdateDeviceById({
            selectedDeviceId: sDeviceId,
            deviceUpdateData: {organizationId: organizationId}
            /*
            organizationId: sDeviceOrgId ? sDeviceOrgId : null,
            selectedDeviceId: sDeviceId,
            deviceUpdateData: {
                retired: true
            }
             */
        }).then(() => {
            message.success("Enhet flyttades till en annan organisation.")
        })
        console.debug("STATE MOVE, POST REQ", device, suContext.devices)
        /*
         */

        handleClose()

    }

    // console.log('Selected Device:', selectedDevice)
    // console.log('DEVICEDATA', deviceData)

    //TODO: Clear ModalForm forms if there are any forms on them (onOpen?)

    console.debug("Rendering Superadmin Tab Pane with data: ", deviceData)
    return (
        <React.Fragment>
            <div style={{display: "flex", justifyContent: 'space-between', marginBottom: 20, flexWrap: "wrap"}}>
                <Typography.Title>Enheter</Typography.Title>
                <div style={{display: 'flex', gap: 15, flexWrap: "wrap"}}>
                    <OrganizationsTreeSelect data={orgData?.length > 1 ? orgData : []}
                                             onChange={organizationSelectOnChange}
                                             selectedOrganization={selectedOrganizations} isMultiple={true}/>
                    <SearchContainerTop updateSearchedText={updateSearchedText}/>
                </div>
            </div>
            {!deviceData || !vehiclesData || !orgData ? <Loading/> :

                <Tabs>

                    <TabPane tab={<TabBadge text={TAB_PANE_TILES.WARNINGS} count={warningsCount}
                                            color={SD_TAB_COLORS.WARNINGS}/>} key="1">

                        <DevicesViewWarnings
                            devices={deviceData}
                            vehicles={vehiclesData}
                            openUserModal={openUserModal}
                            orgData={orgData}
                            selectedOrganizations={selectedOrganizations}
                            showModalWithConfig={showModalWithConfig}
                            onHandleFwConfigReq={onHandleFwConfigReq}
                            setWarningsCount={setWarningsCount}
                            searchText={searchText}
                            getNewConfig={getNewConfig}
                            getOnomondoSMS={getOnomondoSMS}
                            getCheckQueclinkHistory={getCheckQueclinkHistory}

                        />

                    </TabPane>

                    <TabPane
                        tab={<TabBadge text={TAB_PANE_TILES.STOCK} count={inStockCount} color={SD_TAB_COLORS.STOCK}/>}
                        key="2">

                        <div style={{paddingTop: 25, paddingBottom: 15, justifyContent: 'center'}}>
                            <AddUnit/>
                        </div>

                        <DevicesViewInStock
                            devices={deviceData}
                            vehicles={vehiclesData}
                            openUserModal={openUserModal}
                            orgData={orgData}
                            selectedOrganizations={selectedOrganizations}
                            onHandleFwConfigReq={onHandleFwConfigReq}
                            showModalWithConfig={showModalWithConfig}
                            setInStockCount={setInStockCount}
                            handleRetireClick={handleRetireClick}
                            searchText={searchText}
                        />


                    </TabPane>

                    <TabPane tab={<TabBadge text={TAB_PANE_TILES.ON_THE_WAY} count={onTheWayCount}
                                            color={SD_TAB_COLORS.ON_THE_WAY}/>} key="3">

                        <DevicesViewOnTheWay
                            devices={deviceData}
                            vehicles={vehiclesData}
                            openUserModal={openUserModal}
                            orgData={orgData}
                            selectedOrganizations={selectedOrganizations}
                            showModalWithConfig={showModalWithConfig}
                            setOnTheWayCount={setOnTheWayCount}
                            onHandleFwConfigReq={onHandleFwConfigReq}
                            handleRetireClick={handleRetireClick}
                            searchText={searchText}
                            handleOrg={handleOrg}
                        />

                    </TabPane>

                    <TabPane
                        tab={<TabBadge text={TAB_PANE_TILES.IN_DRIVE} count={inDriveCount}
                                       color={SD_TAB_COLORS.IN_DRIVE}/>}
                        key="4">


                        <DevicesViewInDrive
                            devices={deviceData}
                            vehicles={vehiclesData}
                            openUserModal={openUserModal}
                            orgData={orgData}
                            selectedOrganizations={selectedOrganizations}
                            onHandleFwConfigReq={onHandleFwConfigReq}
                            setInDriveCount={setInDriveCount}
                            handleRetireClick={handleRetireClick}
                            searchText={searchText}
                        />


                    </TabPane>

                    <TabPane tab={<TabBadge text={retiredTabTitle} count={retiredCount}
                                            color={SD_BASE_COLORS.NOT_IMPLEMENTED_YET}/>} key="5">
                        {/* DevicesView with props? */}
                        <DevicesViewRetired
                            devices={deviceData}
                            vehicles={vehiclesData}
                            selectedOrganizations={selectedOrganizations}
                            openUserModal={openUserModal}
                            orgData={orgData}
                            setRetiredCount={setRetiredCount}
                            searchText={searchText}

                        />

                    </TabPane>

                </Tabs>
            }
            <DisplayModal
                modalType={SUPER_DEVICES_MODAL_TYPES.AVL}
                visible={visible}
                handleAdd={() => handleAdd}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                handleClose={handleClose}
                useModal={useModal}
                orgData={orgData}
                vehicles={vehiclesData}
                handleOrg={handleOrg}
            />

        </React.Fragment>
    );
};


const DisplayModal = ({
                          visible,
                          handleAdd,
                          // TODO pretty sure this is wrong, its not a single device
                          selectedDevice,
                          handleOrg,
                          handleClose,
                          useModal,
                          orgData,
                          vehicles,
                          // TODO this also seems to update a whole list of devices, no?
                          setSelectedDevice
                      }) => {

    let deterModal = null

    switch (useModal) {
        case SUPER_DEVICES_MODAL_TYPES.AVL:

            deterModal = <ModalFormDeviceAVL
                visible={visible}
                handleAdd={() => handleAdd}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                handleClose={() => handleClose}
                vehicles={vehicles}
            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.LOGG:

            deterModal = <ModalFormDeviceLogg
                visible={visible}
                handleAdd={() => handleAdd}
                selectedDevice={selectedDevice}
                handleClose={() => handleClose}
            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.ADD:

            //Not used
            deterModal = <ModalFormDeviceAdd
                visible={visible}
                handleAdd={() => handleAdd()}
                handleClose={() => handleClose}

            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.DISPLAY_UNIT:

            deterModal = <ModalFormDeviceDisplay
                visible={visible}
                selectedDevice={selectedDevice}
                handleAdd={() => handleAdd()}
                handleClose={handleClose}
            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.CONNECT:

            deterModal = <ModalFormDeviceConnect
                visible={visible}
                handleAdd={() => handleAdd}
                selectedDevice={selectedDevice}
                handleClose={handleClose}
                orgData={orgData}
            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.VEHICLE:

            deterModal = <ModalFormDeviceVehicle
                visible={visible}
                selectedDevice={selectedDevice}
                handleClose={() => handleClose}
            />


            break;

        case SUPER_DEVICES_MODAL_TYPES.C12SMS:

            deterModal = <ModalFormDeviceSMS
                visible={visible}
                selectedDevice={selectedDevice}
                handleAdd={() => handleAdd()}
                handleClose={() => handleClose}
            />

            break;

        case SUPER_DEVICES_MODAL_TYPES.CHANGE_ORG:

            deterModal = <ModalFormDeviceChangeOrganization
                visible={visible}
                selectedDevice={selectedDevice}
                handleClose={() => handleClose}
                handleOrg={handleOrg}
                orgData={orgData}
            />

            break;

        default:

            deterModal = null
            break;
    }


    if (useModal != null) {

        return (
            <React.Fragment>
                {deterModal}
            </React.Fragment>
        );

    } else {
        return null
    }

}

const SearchContainerTop = ({updateSearchedText}) => {

    const {Search} = Input;

    const onSearch = (value) => {
        updateSearchedText(value)
    };

    const handleChangeSearchInput = event => {
        updateSearchedText(event.target.value)
    };

    return (
        <React.Fragment>
            <Search
                style={{width: 300}}
                placeholder={STATIC_TEXT.INPUT_SEARCH_IN_TABLE}
                allowClear
                enterButton
                onChange={handleChangeSearchInput}
            />
        </React.Fragment>
    );
}

function clearOrgHeader() {
    //  localStorage.removeItem(ORGANIZATION_ID);
}

export function handleChangeOrganization(record, organizationId) {
    console.log(record)
    const data = {organizationId: organizationId}
    dataApi.patchAdminDevices(record.deviceId, data)
        .then(_ => {
            message.success("Enhet flyttades till en annan organisation.")
        })


}

const SuperadminDevicesController = () => {
    const [loading, setLoading] = useState(true)

    const suContext = useContext(SuperAdminDevicesContext)

    // RESET REDUX STATES
    useEffect(() => {
        console.debug("STATE USEEFFECT SUPERADMIN DEVICES CONTROLLER")
        suContext.resetState()
        Promise.all([
            suContext.requestFetchVehicles(),
            suContext.requestFetchDevices(),
            suContext.requestFetchOrganizations()
        ]).then(() => {
            setLoading(false)
        })
        // dispatch({ type: SUPERDEVICE_POST_VEHICLE_RESET })
        // TODO make sure this comment doesnt break things
        // dispatch({ type: VEHICLE_REQUEST_VEHICLE_RESET })
        // setLoading(false)
    }, [])
    console.debug('Rendering superadmin controller, devices: ', suContext.devices, 'orgs: ', suContext.orgs)

    return <>
        {<TabTable/>}
    </>
}

export default function SuperadminDevices() {
    return <SuperAdminDevicesContextProvider>
        <SuperadminDevicesController/>
    </SuperAdminDevicesContextProvider>
}

import {Form, Card, Tree, Typography} from "antd";
import {useState} from "react";
import {ORGANIZATION_ID} from "../../../services/redux/constants/authConstants";
import {EditableEmailList} from "../EditableEmailList/editableEmailList";
import {benefitType, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";

const {Title} = Typography;

const text = (v) => {
    const benefit = !v?.isTracker ? `${benefitType[v.benefitType]} `  : ""
    const registry = v?.registry;
    const displayName = v?.displayName ? ` (${v.displayName})` : null;
    if (registry && displayName) {
        return benefit + registry + displayName;
    } else if (!registry) {
        return benefit + v?.displayName
    } else if (!displayName) {
        return benefit + registry
    } else {
        return benefit + v?.vehicleId
    }
}

export const displayVehicle = (v) => {


    return (
        <p>{text(v)}</p>
    );
};

export const displayDriver = (v) => {
    const displayName = v?.displayName;
    return <p>{displayName}</p>;
};

export const EmailRecipients = ({emailData, setEmailData, ...props}) => {
    const addEmailRecipient = (e) => {
        const newData = [...emailData, {email: e}];
        setEmailData(newData);
    };

    const deleteEmailRecipient = (e) => {
        const newData = emailData?.filter((item) => item !== e);
        setEmailData(newData);
    };

    return (
        <div style={props.style}>
            <EditableEmailList
                data={emailData}
                displayTitle={STATIC_TEXT.REPORT_SCHEDULING}
                displayPlaceHolder={STATIC_TEXT.SUPPLY_AN_EMAIL_ADDRESS}
                selectedMethod={STATIC_TEXT.MAIL}
                deleteEmail={deleteEmailRecipient}
                addEmail={addEmailRecipient}
            />
        </div>
    );
};

function addOrganizationAndSuborganizations(orgId, organizations, resultList) {
    const org = organizations.find(o => o.organizationId === orgId);
    if (!org) return;

    resultList.push(org);

    const subOrganizations = organizations.filter(o => o.parentOrganization === orgId);
    for (const subOrg of subOrganizations) {
        addOrganizationAndSuborganizations(subOrg.organizationId, organizations, resultList);
    }
}

export const VehiclesTreeStructure = ({checkedKeys, setCheckedKeys, knownOrganizations, ...props}) => {
    const [expandedKeys, setExpandedKeys] = useState(["-1"]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const org = props.selectedOrganization ? props.selectedOrganization : parseInt(localStorage.getItem(ORGANIZATION_ID));
    const data = [];

    if (org) {
        addOrganizationAndSuborganizations(org, knownOrganizations, data);
    }

    const treeDataVehicles = data.map((i) => {
        let children = props.knownVehicles
            ?.filter((m) => !m.isArchived)
            ?.filter((m) => props.isTracker ? m.isTracker : !m.isTracker)
            ?.filter((m) => m?.organizationId === i?.organizationId)
            ?.filter((m) => !checkedKeys?.includes("org:" + m?.organizationId))
            ?.map((v) => {
                return {
                    title: props.drivers ? displayDriver(v) : displayVehicle(v),
                    key: v?.id || v?.userId,
                };
            });

        return {
            title: i?.fullName,
            key: `org:${i?.organizationId}`,
            children: [...children],
        };
    });


    const treeData = [
        {
            title: STATIC_TEXT.CHOOSE_ALL,
            key: "-1",
            children: treeDataVehicles,
        },
    ];

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeys) => {
        setCheckedKeys(checkedKeys);
    };

    return (
        <Card bordered={false} style={{height: 350,}}>
            <Form.Item
                name={SUPER_DEVICES_KEYS_AND_INDEXES.reportTarget}
                rules={[
                    {
                        required: false,
                        message: STATIC_TEXT.CHOOSE_A_VEHICLE,
                    },
                ]}
            >{
                <Tree
                    checkable
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    onCheck={onCheck}
                    checkedKeys={checkedKeys}
                    treeData={treeData}
                    height={300}
                    defaultExpandAll
                />
            }</Form.Item>
        </Card>
    );
};



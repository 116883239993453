import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TripLogbookContext} from "../../../contexts/TripLogbookContext"
// import {selectLoadingJournals} from "../../../pages/JourneyPage";
import {dataApi} from "../../../services/api/api";
import moment from "moment";
import {FlagFilled, EditOutlined} from "@ant-design/icons";
import Loading from "../../shared-components/Loading/Loading";
import {Button, Input, Modal, Popconfirm, Switch, Table, Typography, Tooltip} from "antd";
import "./styles.css";
import {
    AuthorizationContext,
} from "../../../services/contexts/contexts";
import {convertTimestampToDateStr, formatFromMillis, formatMillis, formatSwedishNumber} from "../../../utilities";
import Flex from "../../shared-components/Flex/Flex";
import Sweden_road_sign from "../../../assets/svg/Sweden_road_sign_E25.svg";
import Sweden_road_sign_white from "../../../assets/svg/Sweden_road_sign_E25_white.svg";

import {TimerInputSubmitterComment} from "./TimerInputSubmitterComment";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import {tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";

// const selectLoadingJournals = createSelector(
//     (s) => s.data.loadingJournals,
//     (id) => id
// );

export function ModalWithTrips({visible, handleChangeShowModal, updateObject, driverReport, timestemp, ...props}) {
    // const dispatch = useDispatch();
    // let journalsData = useSelector(getJournalTableData);
    // let journalsBadTrips = useSelector(getJournalTableDataBadTrips);
    // const loadedJournal = !useSelector(selectLoadingJournals);
    const logbook = useContext(TripLogbookContext)
    let journalsData = logbook.journalTableData
    let journalsDataBadTrips = logbook.journalTableDataBadTrips
    const loadedJournal = logbook.loadedJournals
    const [message, setMessage] = useState("");
    const [journals, setJournals] = useState([]);
    const [filterBadTrips, setFilterBadTrips] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    let tz = moment.tz.guess();
    let zoneId = tz;

    const topColumns = [
        {
            title: "Datum",
            dataIndex: "journalKey",
            key: "journalKey",
            render: (r, o, idx) => {
                moment.locale();
                return moment(r).format("dddd, D MMMM YYYY");
            },
        },
    ];

    let topLevelData = [];

    Object.entries(journals).forEach((k, idx) => {
        topLevelData.push({
            journalKey: k[0],
            key: idx,
        });
    });

    useEffect(() => {
        if (!journals) return;

        //Set trips as selected if isBadTrip
        let prevSelected = [];

        Object.entries(journals).forEach((k, idx) => {
            k[1].map((item) => {
                if (item.isBadTrip) {
                    prevSelected.push(item.journal_id);
                }
            });

            setSelectedRowKeys(prevSelected);
        });
    }, [journals]);

    useEffect(() => {
        setJournals(journalsData);
    }, [journalsData]);

    useEffect(() => {
        if (filterBadTrips === true) {
            setJournals(logbook.journalTableDataBadTrips);
        } else {
            setJournals(journalsData);
        }
    }, [filterBadTrips]);

    useEffect(() => {
        if (!driverReport || !timestemp) return;

        setMessage("");
        setSelectedRowKeys([]);
        setFilterBadTrips(false);

        // dispatch(fetchJournals(timestemp[0], timestemp[1], [driverReport?.vehicle?.vehicleId], [driverReport?.personId]));
        logbook.requestFetchJournals(timestemp[0], timestemp[1], [driverReport?.vehicle?.vehicleId], [driverReport?.personId])
    }, [driverReport, timestemp]);

    const attestRefuse = (splitPeriod) => {
        let badTrips = [];
        Object.entries(journals).forEach((k, idx) => {
            k[1].map((trip) => {
                if (selectedRowKeys.includes(trip.journal_id)) {
                    if (!trip.mergedTripsId) {
                        badTrips.push(trip.journal_id);
                    } else {
                        badTrips.push(trip.mergedTripsId);
                    }
                }
            });
        });

        dataApi.putAttestRefuse(message, splitPeriod[1], splitPeriod[0], zoneId, driverReport?.personId, driverReport?.vehicle?.vehicleId, badTrips).then((res) => {
            const row = {
                attested: false,
            };
            updateObject(driverReport?.personId, driverReport?.vehicle?.vehicleId, row);
        });
    };

    const attestConfirm = (splitPeriod) => {
        dataApi.putAttestConfirm(message, splitPeriod[1], splitPeriod[0], zoneId, driverReport?.personId, driverReport?.vehicle?.vehicleId).then((res) => {
            const row = {
                adminApproved: true,
            };
            updateObject(driverReport?.personId, driverReport?.vehicle?.vehicleId, row);
        });
    };

    const handleRefuseClick = () => {
        const splitPeriod = props.selectedPeriod?.split("/");
        attestRefuse(splitPeriod);
        handleChangeShowModal();
    };

    const handleConfirmClick = () => {
        const splitPeriod = props.selectedPeriod?.split("/");
        attestConfirm(splitPeriod);
        handleChangeShowModal();
    };

    const disabledGreenButton =
        selectedRowKeys.length > 0
            ? {color: "rgb(158, 168, 187)", backgroundColor: "rgb(88, 99, 121)", borderColor: "rgb(77, 91, 117)"}
            : {color: "#fff", borderColor: SD_BASE_COLORS.GREEN, backgroundColor: SD_BASE_COLORS.GREEN};

    return !journalsData ? (
        <Loading/>
    ) : (
        <Modal
            bodyStyle={{overflowY: 'auto', maxHeight: 'calc(100vh - 250px)'}}

            style={{minWidth: "95%"}}
            title={`Granskning av attesterad körjournal`}
            visible={visible}
            footer={[
                <Button key="1" onClick={handleChangeShowModal}>
                    Avbryt
                </Button>,
                <>
                    {selectedRowKeys.length > 0 ? (
                        <Button key="2" onClick={handleRefuseClick} danger>
                            Avattestera
                        </Button>
                    ) : (
                        <Popconfirm
                            title="Du har inte flaggat några resor för korrigering - vill du avattestera hela månaden?"
                            onConfirm={handleRefuseClick} okText="OK" cancelText="Avbryt">
                            <Button key="2" danger>
                                Avattestera
                            </Button>
                        </Popconfirm>
                    )}
                </>,
                <Button key="3" onClick={handleConfirmClick} style={disabledGreenButton}
                        disabled={selectedRowKeys.length > 0}>
                    Godkänn
                </Button>,
            ]}
            onCancel={handleChangeShowModal}
        >
            <Typography.Title level={2} style={{marginBottom: "2rem"}}>
                Körjournal för {driverReport?.fullName} (fordon {driverReport?.vehicle?.reg})
            </Typography.Title>
            <div style={{maxWidth: "900px"}}>
                <p>
                    Nedanstående körjournal är bekräftad och attesterad av föraren. Efter att ha granskat journalen kan
                    du välja att godkänna eller avattestera journalen. Du kan också välja att skicka
                    med ett meddelande till föraren (ej obligatoriskt).
                </p>
            </div>

            <Input.TextArea style={{marginBottom: "30px"}} value={message} onChange={(e) => setMessage(e.target.value)}
                            placeholder={"Meddelande till förare..."} rows={4}/>

            <div style={{float: "right"}} className={"extraModalButtons"}>
                <Button key="1" style={{marginRight: "8px"}} onClick={handleChangeShowModal}>
                    Avbryt
                </Button>
                <>
                    {selectedRowKeys.length > 0 ? (
                        <Button key="2" style={{marginRight: "8px"}} onClick={handleRefuseClick} danger>
                            Avattestera
                        </Button>
                    ) : (
                        <Popconfirm
                            title="Du har inte flaggat några resor för korrigering - vill du avattestera hela månaden?"
                            onConfirm={handleRefuseClick} okText="OK" cancelText="Avbryt">
                            <Button key="2" style={{marginRight: "8px"}} danger>
                                Avattestera
                            </Button>
                        </Popconfirm>
                    )}
                </>
                <Button key="3" onClick={handleConfirmClick} style={disabledGreenButton}
                        disabled={selectedRowKeys.length > 0}>
                    Godkänn
                </Button>
            </div>
            <div>
                <div>
                    Visa bara flaggade resor
                    <Switch style={{marginLeft: 20}} checked={filterBadTrips} onChange={setFilterBadTrips}/>
                </div>

                {!loadedJournal ? (
                    <Loading/>
                ) : topLevelData.length > 0 ? (
                    <Table
                        columns={(topColumns)}
                        rowKey={(record) => record.key}
                        expandable={{
                            expandedRowRender: (record) => <ExpandedRowRender selectedRowKeys={selectedRowKeys}
                                                                              setSelectedRowKeys={setSelectedRowKeys}
                                                                              record={record} journals={journals}/>,
                            defaultExpandAllRows: true,
                            expandRowByClick: true,
                        }}
                        dataSource={topLevelData}
                        pagination={false}
                    ></Table>
                ) : null}
            </div>
        </Modal>
    );
}

const ExpandedRowRender = ({record, journals, selectedRowKeys, setSelectedRowKeys}) => {
    const currentUser = useContext(AuthorizationContext);
    const userId = currentUser?.userId;
    const journalKey = record?.journalKey;

    const renderisTripCorrected = (record) => {
        const editedTrip = record?.data?.overrideTsMs
        return (
            editedTrip && <div
                style={{
                    fontSize: "1.4em",
                    opacity: "100%",
                }}
            >
                <Tooltip
                    title={"Resan är korrigerad"}>
                    <EditOutlined

                    />
                </Tooltip>
            </div>
        );
    };

    function isPrivateTrip(o) {
        return o.data.trip?.purpose === "PRIVATE";
    }

    function isNotCurrentUsersTrip(o, id) {
        return o?.data?.driver?.id !== userId;
    }

    function hasDriver(o) {
        return o.data.driver?.id !== null;
    }

    const columns = [
        {
            title: "Flaggad",
            dataIndex: "badTrips",
            key: "badTrips",
            width: 90,
            render: (r, o) => {
                const journalId = o.data.journal_id;

                return (
                    <>
                        {selectedRowKeys.map((x) => (
                            <>{(journalId === x) > 0 && <FlagFilled style={{color: "#bf0e0e"}}/>}</>
                        ))}

                        {renderisTripCorrected(o)}

                    </>
                );
            },
        },
        {
            title: "Tid",
            dataIndex: "timestamp",
            key: "timestamp_start_ms",
            width: "8%",
            render: (r, o) => {
                const {data} = o;
                const startTime = convertTimestampToDateStr(data.trip.timestamp_start_ms, "HH:mm", false);
                const stopTime = convertTimestampToDateStr(data.trip.timestamp_stop_ms, "HH:mm", false);
                return o.data.driver.id !== userId && (o.data.trip.purpose === "PRIVATE_AUTO" || o.data.trip.purpose === "PRIVATE") ? null : (
                    <Flex flexDirection={"column"} justifyContent={"space-between"}>
                        <span title={stopTime}><div style={{height: "2rem"}}>{stopTime}</div> </span>
                        <br/>
                        <span title={startTime}><div style={{height: "2rem"}}>{startTime}</div> </span>

                    </Flex>
                );
            },
        },
        {
            title: "Adress",
            dataIndex: "address",
            key: "address",
            render: (r, o) => {
                const privateStyle = {
                    color: "rgb(158, 168, 187)",
                    backgroundColor: "rgb(88, 99, 121)",
                    border: "1px solid rgb(77, 91, 117)",
                    borderRadius: "0.625rem",
                    display: "block",
                    padding: "8.5px 11px",
                };
                const ifDisabled = (!hasDriver(o) || isNotCurrentUsersTrip(o, userId)) && (isPrivateTrip(o));
                const start = o.data?.geocode_start?.format
                const stop = o.data?.geocode_stop?.format
                let overrideStart = o.data.override_start
                let overrideStop = o.data.override_stop
                if (ifDisabled) {
                    overrideStop = "— Sekretess —"
                    overrideStart = "— Sekretess —"
                }
                return (
                    <Flex flexDirection={"column"}>
                        <Flex alignItems={"baseline"} gap={8}>
                            {overrideStop && !ifDisabled &&
                                <Tooltip title={stop}>
                                    <EditOutlined/>
                                </Tooltip>
                            }
                            {o.data.driver.id !== userId && (o.data.trip.purpose === "PRIVATE_AUTO" || o.data.trip.purpose === "PRIVATE") ? (
                                <div style={privateStyle}>Privat resa</div>
                            ) : (
                                <span title={stop}><div style={{height: "2rem", fontWeight: overrideStop && "bold"}}>{overrideStop || stop}</div> </span>

                            )}
                        </Flex>

                        <Flex alignItems={"baseline"} gap={8}>
                            {overrideStart && !ifDisabled &&
                                <Tooltip title={start}>
                                    <EditOutlined/>
                                </Tooltip>
                            }
                            {o.data.driver.id !== userId && (o.data.trip.purpose === "PRIVATE_AUTO" || o.data.trip.purpose === "PRIVATE") ? (
                                <div style={privateStyle}>Privat resa</div>
                            ) : (
                                <span title={start}><div style={{height: "2rem", fontWeight: overrideStart && "bold"}}>{overrideStart || start}</div> </span>
                            )}


                        </Flex>
                    </Flex>

                );
            },
        },
        {
            title: "Restid",
            dataIndex: "total_time",
            key: "total_time",
            width: "8%",
            render: (r, o) => {
                const startTime = o?.data?.trip?.timestamp_start_ms;
                const stopTime = o?.data?.trip?.timestamp_stop_ms;
                return <span title={formatMillis(stopTime, startTime)}>{formatMillis(stopTime, startTime)}</span>;
            },
        },
        {
            title: "Avstånd",
            dataIndex: "odometer_total",
            key: "distance",
            width: "8%",
            render: (r, o) => {
                const total = ((o?.data?.trip?.odometer_max) * 0.001).toFixed(2);
                return <span title={total + " km"}>{total} km</span>;
            },
        },
        {
            title: "Stopptid",
            dataIndex: "stopTimeBackwards",
            key: "stopTimeBackwards",
            width: "8%",
            render: (r, o) => {
                let text = o.data.stopTimeBackwards ? formatFromMillis(o.data.stopTimeBackwards) : null;
                return o?.data?.driver?.id !== userId && (o?.data?.trip?.purpose === "PRIVATE" || o?.data?.trip?.purpose === "PRIVATE") ? null : (
                    <span title={text}>{text}</span>
                );
            },
        },
        {
            title: "Mätarställning",
            dataIndex: "odometer_start",
            key: "odometers",
            width: "8%",
            render: (r, o) => {
                const text1 = formatSwedishNumber((o?.data?.trip?.odometer_stop / 1000).toFixed(0)) + " km"
                const text2 = formatSwedishNumber((o?.data?.trip?.odometer_start / 1000).toFixed(0)) + " km"
                return (
                    <Flex flexDirection={"column"}>
                        <span title={text1}>{text1}</span>
                        <span title={text2}>{text2}</span>
                    </Flex>
                );
            },
        },
        {
            title: "Syfte",
            dataIndex: "description",
            key: "description",
            width: "8%",
            render: (r, o) => {
                const description = o?.data?.trip?.description;
                const enabledState = o.data.trip.purpose.startsWith("BUSINESS");
                return (
                    <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"start"} gap={10}>
                        <Switch
                            style={{
                                backgroundColor: enabledState ? SD_BASE_COLORS.SWITCH_BUSINESS : SD_BASE_COLORS.SWITCH_PRIVATE,
                                marginRight: 10,
                            }}
                            checkedChildren="Tjänst"
                            unCheckedChildren="Privat"
                            disabled={true}
                            checked={enabledState}
                        />
                        <span>{description}</span>
                    </Flex>
                );
            },
        },
        {
            title: "Tskatt",
            dataIndex: "tax",
            key: "tax",
            width: "8%",
            render: (r, o) => {
                const enabledState = o?.data?.congestionTaxCount || o?.data?.congestionTaxSekOverride
                let taxSek = o?.data?.congestionTaxSekOverride !== null ? o?.data?.congestionTaxSekOverride : o?.data?.congestionTaxSek || 0;
                let image = enabledState ? Sweden_road_sign : Sweden_road_sign_white;
                const count = o?.data?.congestionTaxCount
                let text = count === 1 ? "passage" : "passager";
                let prevValue = (o?.data?.congestionTaxSek || "0") + " kr";
                return (
                    <Flex flexDirection={"column"}>

                        <Flex gap={8}>
                            <img alt="Sweden road sign E25" width={23} height={23} src={image}/>
                            {o?.data?.congestionTaxSekOverride !== null &&
                                <span title={prevValue}>
                                  <Typography.Text
                                      style={{
                                          textDecoration: "line-through",
                                      }}
                                  >
                                    {prevValue}
                                </Typography.Text>
                            </span>
                            }

                            <span title={taxSek + "kr"}>
                                <Typography.Text
                                    style={{color: taxSek ? SD_BASE_COLORS.RED : null}}>{taxSek} kr</Typography.Text>
                         </span>
                        </Flex>
                        <span title={count ? count + " " + text : null}>
                          {count ? count + " " + text : null}
                      </span>
                    </Flex>
                );
            },
        },
        {
            title: "Infra.avg.",
            dataIndex: "tax",
            key: "tax",
            width: "8%",
            render: (r, o) => {
                const enabledState = o?.data?.infraTaxSekOverride || o?.data?.infraTaxCount;
                let taxSek = o?.data?.infraTaxSekOverride !== null ? o?.data?.infraTaxSekOverride : o?.data?.infraTaxSek || 0;
                let image = enabledState ? Sweden_road_sign : Sweden_road_sign_white;
                const count = o?.data?.infraTaxCount
                let text = count === 1 ? "passage" : "passager";
                let prevValue = (o?.data?.infraTaxSek || "0") + " kr";

                return (
                    <Flex flexDirection={"column"}>
                        <Flex gap={8}>
                            <img alt="Sweden road sign E25" width={23} height={23} src={image}/>
                            {o?.data?.infraTaxSekOverride !== null &&
                                <span title={prevValue}>
                                <Typography.Text
                                    style={{
                                        textDecoration: "line-through",
                                    }}
                                >
                                    {prevValue}
                                </Typography.Text>
                                </span>
                            }

                            <span title={taxSek + "kr"}>
                                <Typography.Text
                                    style={{color: taxSek ? SD_BASE_COLORS.RED : null}}>{taxSek} kr</Typography.Text>
                         </span>
                        </Flex>
                        <span title={count ? count + " " + text : null}>
                          {count ? count + " " + text : null}
                      </span>
                    </Flex>
                );
            },
        },
        {
            title: "Kommentar",
            dataIndex: "adminComment",
            key: "adminComment",
            width: 160,
            render: (r, o) => {
                return <span title={o.data.adminComment}>
                    <TimerInputSubmitterComment tripId={o.data.journal_id} value={o.data.adminComment} o={o}/>
            </span>
            },
        }];

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    let data = [];
    if (journalKey && journals[journalKey] !== undefined && journals[journalKey].length > 0) {
        journals[journalKey].forEach((j, i) => {
            data.push({
                k: j.id,
                data: {
                    viewChecked: true,
                    viewSaved: j.journal_id === 144,
                    ...j,
                },
            });
        });
    }

    return (
        <Table
            rowKey={(record) => record?.data?.journal_id}
            columns={tableColumnsWithTooltip(columns)}
            dataSource={data}
            scroll={{x: 1200}}
            sticky={{offsetHeader: -30, offsetScroll: 0, }}
            pagination={false}
            rowSelection={{
                ...rowSelection,
            }}
        />
    );
};

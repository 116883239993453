export const payStations = {
    "3": {
        "number": 1,
        "name": "Danvikstull",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 11,
        "lane": 3,
        "sweref99tm_n": 6579134.0,
        "sweref99tm_e": 676636.2,
        "latitude": 59.31401341252156,
        "longitude": 18.103508544087155
    },
    "4": {
        "number": 1,
        "name": "Danvikstull",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 12,
        "lane": 3,
        "sweref99tm_n": 6579122.7,
        "sweref99tm_e": 676633.0,
        "latitude": 59.31391342300039,
        "longitude": 18.10344317397538
    },
    "5": {
        "number": 2,
        "name": "Skansbron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 21,
        "lane": 1,
        "sweref99tm_n": 6577958.9,
        "sweref99tm_e": 675321.3,
        "latitude": 59.30402374674799,
        "longitude": 18.07949093871554
    },
    "6": {
        "number": 2,
        "name": "Skansbron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 22,
        "lane": 1,
        "sweref99tm_n": 6577957.6,
        "sweref99tm_e": 675316.4,
        "latitude": 59.304014121992275,
        "longitude": 18.079403960590586
    },
    "7": {
        "number": 3,
        "name": "Skanstullsbron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 31,
        "lane": 3,
        "sweref99tm_n": 6578210.0,
        "sweref99tm_e": 675193.2,
        "latitude": 59.306328548144265,
        "longitude": 18.077448209616993
    },
    "8": {
        "number": 3,
        "name": "Skanstullsbron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 32,
        "lane": 2,
        "sweref99tm_n": 6578206.9,
        "sweref99tm_e": 675183.5,
        "latitude": 59.30630477085257,
        "longitude": 18.077275590977905
    },
    "9": {
        "number": 4,
        "name": "Johanneshovsbron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 41,
        "lane": 2,
        "sweref99tm_n": 6577929.8,
        "sweref99tm_e": 675193.7,
        "latitude": 59.30381571314429,
        "longitude": 18.07722982566504
    },
    "10": {
        "number": 4,
        "name": "Johanneshovsbron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 42,
        "lane": 2,
        "sweref99tm_n": 6577924.6,
        "sweref99tm_e": 675182.6,
        "latitude": 59.30377368448991,
        "longitude": 18.077030968315594
    },
    "11": {
        "number": 5,
        "name": "Liljeholmsbron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "NE",
        "cp_id": 51,
        "lane": 3,
        "sweref99tm_n": 6578652.2,
        "sweref99tm_e": 672400.1,
        "latitude": 59.311442788147914,
        "longitude": 18.0288175386571
    },
    "12": {
        "number": 5,
        "name": "Liljeholmsbron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "SW",
        "cp_id": 52,
        "lane": 3,
        "sweref99tm_n": 6578708.5,
        "sweref99tm_e": 672418.8,
        "latitude": 59.311940036421966,
        "longitude": 18.02919047708911
    },
    "13": {
        "number": 6,
        "name": "Stora Essingen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 61,
        "lane": 1,
        "sweref99tm_n": 6579845.2,
        "sweref99tm_e": 670514.7,
        "latitude": 59.32290665787442,
        "longitude": 17.996688748847856
    },
    "14": {
        "number": 6,
        "name": "Stora Essingen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 62,
        "lane": 1,
        "sweref99tm_n": 6579842.7,
        "sweref99tm_e": 670508.1,
        "latitude": 59.32288690292567,
        "longitude": 17.99657096702783
    },
    "15": {
        "number": 6,
        "name": "Stora Essingen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 63,
        "lane": 2,
        "sweref99tm_n": 6579752.4,
        "sweref99tm_e": 670498.5,
        "latitude": 59.32208097624048,
        "longitude": 17.996331191613262
    },
    "16": {
        "number": 7,
        "name": "Lilla Essingen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "NW",
        "cp_id": 72,
        "lane": 1,
        "sweref99tm_n": 6580109.5,
        "sweref99tm_e": 670918.9,
        "latitude": 59.32511346403307,
        "longitude": 18.003990311028065
    },
    "17": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 81,
        "lane": 2,
        "sweref99tm_n": 6580807.0,
        "sweref99tm_e": 671285.5,
        "latitude": 59.331219951991336,
        "longitude": 18.010976917316725
    },
    "18": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 82,
        "lane": 2,
        "sweref99tm_n": 6580839.5,
        "sweref99tm_e": 671259.2,
        "latitude": 59.3315220748301,
        "longitude": 18.010541140192213
    },
    "19": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 83,
        "lane": 1,
        "sweref99tm_n": 6580747.6,
        "sweref99tm_e": 671269.4,
        "latitude": 59.330693797501084,
        "longitude": 18.01064719292494
    },
    "20": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 84,
        "lane": 1,
        "sweref99tm_n": 6580822.0,
        "sweref99tm_e": 671266.4,
        "latitude": 59.33136221772379,
        "longitude": 18.010653611009538
    },
    "21": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": true,
        "is_essinge": true,
        "direction_s": "E",
        "cp_id": 85,
        "lane": 1,
        "sweref99tm_n": 6580804.6,
        "sweref99tm_e": 671169.1,
        "latitude": 59.33124563998646,
        "longitude": 18.00893212561712
    },
    "22": {
        "number": 8,
        "name": "Tpl Fredh\u00e4ll/Drottningholmsv\u00e4gen",
        "is_inbound": false,
        "is_essinge": true,
        // TODO wrong value!?
        // "direction_s": "N",
        "direction_s": "S",
        "cp_id": 86,
        "lane": 1,
        "sweref99tm_n": 6580855.6,
        "sweref99tm_e": 671258.8,
        "latitude": 59.33166661843193,
        "longitude": 18.010546902231145
    },
    "23": {
        "number": 8,
        "name": "Essingeleden",
        "is_inbound": true,
        "is_essinge": true,
        "direction_s": "S",
        "cp_id": 87,
        "lane": 3,
        "sweref99tm_n": 6581083.5,
        "sweref99tm_e": 671195.4,
        "latitude": 59.33373609296007,
        "longitude": 18.009615065773545
    },
    "24": {
        "number": 8,
        "name": "Essingeleden",
        "is_inbound": false,
        "is_essinge": true,
        "direction_s": "N",
        "cp_id": 88,
        "lane": 3,
        "sweref99tm_n": 6581083.0,
        "sweref99tm_e": 671212.7,
        "latitude": 59.33372459261556,
        "longitude": 18.009918316115783
    },
    "25": {
        "number": 9,
        "name": "Tpl Kristineberg",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 93,
        "lane": 1,
        "sweref99tm_n": 6581388.2,
        "sweref99tm_e": 671217.9,
        "latitude": 59.33645945093323,
        "longitude": 18.010251894882174
    },
    "26": {
        "number": 9,
        "name": "Tpl Kristineberg",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 94,
        "lane": 1,
        "sweref99tm_n": 6581360.6,
        "sweref99tm_e": 671252.0,
        "latitude": 59.336198106225126,
        "longitude": 18.010828542031394
    },
    "27": {
        "number": 9,
        "name": "Tpl Kristineberg",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 95,
        "lane": 1,
        "sweref99tm_n": 6581083.0,
        "sweref99tm_e": 671223.6,
        "latitude": 59.33372017148366,
        "longitude": 18.010109631341006
    },
    "28": {
        "number": 9,
        "name": "Tpl Kristineberg",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 96,
        "lane": 1,
        "sweref99tm_n": 6581083.5,
        "sweref99tm_e": 671187.2,
        "latitude": 59.33373941842988,
        "longitude": 18.009471140431007
    },
    "29": {
        "number": 10,
        "name": "Ekelundsbron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 111,
        "lane": 2,
        "sweref99tm_n": 6581859.5,
        "sweref99tm_e": 671341.9,
        "latitude": 59.340635636312655,
        "longitude": 18.012803037238495
    },
    "30": {
        "number": 10,
        "name": "Ekelundsbron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 112,
        "lane": 1,
        "sweref99tm_n": 6581857.3,
        "sweref99tm_e": 671353.0,
        "latitude": 59.34061140037375,
        "longitude": 18.012996153185693
    },
    "31": {
        "number": 11,
        "name": "Klarastrandsleden",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "SE",
        "cp_id": 101,
        "lane": 1,
        "sweref99tm_n": 6581692.0,
        "sweref99tm_e": 672321.5,
        "latitude": 59.338734697907974,
        "longitude": 18.029866165802733
    },
    "32": {
        "number": 11,
        "name": "Klarastrandsleden",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "NW",
        "cp_id": 102,
        "lane": 2,
        "sweref99tm_n": 6581700.7,
        "sweref99tm_e": 672325.5,
        "latitude": 59.33881108292742,
        "longitude": 18.029943335947202
    },
    "33": {
        "number": 12,
        "name": "Tomtebodav\u00e4gen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "SE",
        "cp_id": 123,
        "lane": 2,
        "sweref99tm_n": 6582234.9,
        "sweref99tm_e": 672082.2,
        "latitude": 59.343700871225984,
        "longitude": 18.026098838033512
    },
    "34": {
        "number": 12,
        "name": "Tomtebodav\u00e4gen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "NW",
        "cp_id": 124,
        "lane": 2,
        "sweref99tm_n": 6582240.4,
        "sweref99tm_e": 672089.4,
        "latitude": 59.34374725690518,
        "longitude": 18.026229638053604
    },
    "35": {
        "number": 13,
        "name": "Solnabron",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 131,
        "lane": 2,
        "sweref99tm_n": 6582570.9,
        "sweref99tm_e": 672416.1,
        "latitude": 59.346577679528444,
        "longitude": 18.03222982069787
    },
    "36": {
        "number": 13,
        "name": "Solnabron",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 132,
        "lane": 2,
        "sweref99tm_n": 6582597.1,
        "sweref99tm_e": 672411.8,
        "latitude": 59.34681438744275,
        "longitude": 18.032175283636985
    },
    "37": {
        "number": 14,
        "name": "Tpl Norrtull",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 145,
        "lane": 3,
        "sweref99tm_n": 6582950.2,
        "sweref99tm_e": 672989.4,
        "latitude": 59.34974438253721,
        "longitude": 18.04260034080288
    },
    "38": {
        "number": 14,
        "name": "Tpl Norrtull",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 146,
        "lane": 4,
        "sweref99tm_n": 6583015.4,
        "sweref99tm_e": 673131.4,
        "latitude": 59.35027080908717,
        "longitude": 18.045146194960964
    },
    "39": {
        "number": 14,
        "name": "Tpl Norrtull",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 147,
        "lane": 3,
        "sweref99tm_n": 6582944.2,
        "sweref99tm_e": 672981.5,
        "latitude": 59.34969381698423,
        "longitude": 18.04245680246301
    },
    "40": {
        "number": 14,
        "name": "Tpl Norrtull",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "NE",
        "cp_id": 148,
        "lane": 3,
        "sweref99tm_n": 6582959.2,
        "sweref99tm_e": 673063.7,
        "latitude": 59.3497946157293,
        "longitude": 18.043912247056717
    },
    "41": {
        "number": 21,
        "name": "Tpl Ekhagen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 211,
        "lane": 1,
        "sweref99tm_n": 6585350.7,
        "sweref99tm_e": 673317.0,
        "latitude": 59.37113621931609,
        "longitude": 18.0502854088732
    },
    "42": {
        "number": 21,
        "name": "Tpl Ekhagen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 212,
        "lane": 1,
        "sweref99tm_n": 6585381.0,
        "sweref99tm_e": 673323.0,
        "latitude": 59.37140546435272,
        "longitude": 18.050415244863785
    },
    "43": {
        "number": 21,
        "name": "Tpl Ekhagen",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 213,
        "lane": 1,
        "sweref99tm_n": 6585155.8,
        "sweref99tm_e": 673400.1,
        "latitude": 59.36935429718154,
        "longitude": 18.051588427034222
    },
    "44": {
        "number": 21,
        "name": "Tpl Ekhagen",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 214,
        "lane": 1,
        "sweref99tm_n": 6585169.4,
        "sweref99tm_e": 673349.1,
        "latitude": 59.369497229830614,
        "longitude": 18.050703335704327
    },
    "45": {
        "number": 22,
        "name": "Tpl Frescati",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 221,
        "lane": 1,
        "sweref99tm_n": 6584743.7,
        "sweref99tm_e": 673469.5,
        "latitude": 59.365630283744515,
        "longitude": 18.052475530118127
    },
    "46": {
        "number": 22,
        "name": "Tpl Frescati",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 222,
        "lane": 1,
        "sweref99tm_n": 6584741.5,
        "sweref99tm_e": 673469.4,
        "latitude": 59.36561059663265,
        "longitude": 18.052471999999266
    },
    "47": {
        "number": 23,
        "name": "Tpl Universitetet",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "S",
        "cp_id": 231,
        "lane": 2,
        "sweref99tm_n": 6584453.6,
        "sweref99tm_e": 673604.2,
        "latitude": 59.362973403938085,
        "longitude": 18.05460788577118
    },
    "48": {
        "number": 23,
        "name": "Tpl Universitetet",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "N",
        "cp_id": 232,
        "lane": 2,
        "sweref99tm_n": 6584471.4,
        "sweref99tm_e": 673639.0,
        "latitude": 59.3631186933352,
        "longitude": 18.055233549837624
    },
    "49": {
        "number": 24,
        "name": "Tpl Roslagstull",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "SW",
        "cp_id": 241,
        "lane": 1,
        "sweref99tm_n": 6583327.3,
        "sweref99tm_e": 673848.8,
        "latitude": 59.35277269583369,
        "longitude": 18.0579951998636
    },
    "50": {
        "number": 24,
        "name": "Tpl Roslagstull",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "NE",
        "cp_id": 242,
        "lane": 1,
        "sweref99tm_n": 6583296.8,
        "sweref99tm_e": 673892.3,
        "latitude": 59.352481260821165,
        "longitude": 18.05873447593609
    },
    "51": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 251,
        "lane": 1,
        "sweref99tm_n": 6583250.3,
        "sweref99tm_e": 675974.1,
        "latitude": 59.35120095883797,
        "longitude": 18.095253358338
    },
    "52": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 252,
        "lane": 1,
        "sweref99tm_n": 6583275.9,
        "sweref99tm_e": 675994.9,
        "latitude": 59.35142183783862,
        "longitude": 18.095639510401064
    },
    "53": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 253,
        "lane": 1,
        "sweref99tm_n": 6583417.6,
        "sweref99tm_e": 676603.4,
        "latitude": 59.352438145124836,
        "longitude": 18.106440597967524
    },
    "54": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 254,
        "lane": 1,
        "sweref99tm_n": 6583346.0,
        "sweref99tm_e": 676614.2,
        "latitude": 59.35179158954997,
        "longitude": 18.106571539683802
    },
    "55": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 255,
        "lane": 1,
        "sweref99tm_n": 6583212.7,
        "sweref99tm_e": 676214.4,
        "latitude": 59.35076348780592,
        "longitude": 18.09944213069989
    },
    "56": {
        "number": 25,
        "name": "V\u00e4rtan",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 256,
        "lane": 1,
        "sweref99tm_n": 6583403.8,
        "sweref99tm_e": 676600.5,
        "latitude": 59.3523156152903,
        "longitude": 18.10637836007064
    },
    "57": {
        "number": 26,
        "name": "Ropsten",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "E",
        "cp_id": 261,
        "lane": 1,
        "sweref99tm_n": 6583874.8,
        "sweref99tm_e": 676506.7,
        "latitude": 59.35657831511262,
        "longitude": 18.10511724726752
    },
    "58": {
        "number": 26,
        "name": "Ropsten",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "W",
        "cp_id": 262,
        "lane": 1,
        "sweref99tm_n": 6583883.3,
        "sweref99tm_e": 676498.6,
        "latitude": 59.35665792411922,
        "longitude": 18.1049819611326
    },
    "59": {
        "number": 26,
        "name": "Ropsten",
        "is_inbound": true,
        "is_essinge": false,
        "direction_s": "NW",
        "cp_id": 263,
        "lane": 2,
        "sweref99tm_n": 6583912.7,
        "sweref99tm_e": 676352.4,
        "latitude": 59.356982711524026,
        "longitude": 18.10243844870146
    },
    "60": {
        "number": 26,
        "name": "Ropsten",
        "is_inbound": false,
        "is_essinge": false,
        "direction_s": "SE",
        "cp_id": 264,
        "lane": 1,
        "sweref99tm_n": 6583902.4,
        "sweref99tm_e": 676348.8,
        "latitude": 59.3568918569359,
        "longitude": 18.102366789044993
    }
}


// https://stackoverflow.com/questions/17242144/javascript-convert-hsb-hsv-color-to-rgb-accurately#17243070
export function HSVtoRGB(h, s, v) {
    let r, g, b, i, f, p, q, t;

    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0:
            r = v
            g = t
            b = p
            break

        case 1:
            r = q
            g = v
            b = p
            break

        case 2:
            r = p
            g = v
            b = t
            break

        case 3:
            r = p
            g = q
            b = v
            break

        case 4:
            r = t
            g = p
            b = v
            break

        case 5:
            r = v
            g = p
            b = q
            break

    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
}

export const parseDirectionDeg = (ds) => {
    // CSS transforms has zero at [0, 1] and CW orientation!!
    return {
        "N": 0,
        "NW": 315,
        "W": 270,
        "SW": 225,
        "S": 180,
        "SE": 135,
        "E": 90,
        "NE": 45,
    }[ds]
}

import {TreeSelect} from 'antd';
import {getOrgTreeDataWithChildren} from "../Superdevices/superDeviceHelpers";

import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";

export const OrganizationsTreeSelect = ({
                                            data,
                                            selectedOrganization,
                                            onChange,
                                            defaultValue,
                                            isMultiple,
                                            width,
                                            thisIsSettingPanel,
                                            disabled,
                                            placeholder,
                                            extra
                                        }) => {
    const organizationId = selectedOrganization || defaultValue;
    const treeData = getOrgTreeDataWithChildren(data, organizationId, thisIsSettingPanel)
    const showSelect = true

    // autoComplete={false}
    return (
        !showSelect ? null :
            <div>
                <TreeSelect
                    multiple={isMultiple}
                    maxTagCount={1}
                    allowClear
                    showSearch
                    autoComplete="off"
                    autoClearSearchValue={false}
                    filterOption={(input, option) =>
                        option?.title?.toLowerCase().includes(input.toLowerCase())
                    }
                    style={{minWidth: width || 200}}
                    dropdownStyle={{minWidth: 400}}
                    treeData={treeData}
                    placeholder={placeholder || STATIC_TEXT.FILTER_BY_ORG}
                    treeDefaultExpandAll
                    value={selectedOrganization}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    disabled={disabled}
                    {...extra}
                />
            </div>
    )

}

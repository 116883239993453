import {dataApi} from "../../../services/api/api";
import React from "react";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal, message} from "antd";
import {MESSAGE_NOTIFICATION, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";

export function activateUser(record, data, setData, editUser) {
    const handleActive = async () => {
        try {
            const res = await dataApi.patchActivateUser(record?.id);
            const newData = [...data];
            const index = newData?.findIndex(item => record?.id === item.id);
            newData.splice(index, 1, {...newData[index], ...res});
            setData(newData);

            console.log(res)
            editUser(record)

        } catch (error) {
            console.error(error);
        }
    };


    Modal.success({
        title: 'Användaren är aktiverad',
        content: (
            <div>
                <p>Du har aktiverat {record.displayName}. Användaren kan nu logga in med sina tidigare inloggningsuppgifter.</p>
                <p>Du kan ändra användaruppgifter i nästa steg.</p>
            </div>
        ),
        okText: 'OK',
        cancelText: 'Ångra',
        onOk: handleActive,
    });

}

export function archiveUser(record, data, setData) {
    Modal.confirm({
        title: 'Arkivera användare',
        icon: <ExclamationCircleOutlined/>,
        okText: "Arkivera",
        content: (
            <div>
                <p>
                    Du kommer att arkivera {record?.displayName}.
                </p>
                <p><b> Användaren kommer inte längre att kunna logga in i systemet.</b></p>
                <p> All data som har skapats fram tills nu finns kvar.</p>
                <p>Vill du arkivera användaren?</p>
            </div>
        ),
        onOk() {
            dataApi.patchArchiveUser(record?.userId)
                .then((res) => {
                    const newData = [...data];
                    const index = newData?.findIndex((item) => record?.userId === item.userId);
                    const item = newData[index];
                    newData.splice(index, 1, {...item, ...res});
                    setData(newData);
                })
                .then(_ => {
                    message.open({
                        type: 'success',
                        content: MESSAGE_NOTIFICATION.YOU_HAVE_ARCHIVED_THE + STATIC_TEXT.USERS.toLowerCase() + " " + (record?.displayName || record?.id)
                    });
                });
        }
        ,
        onCancel() {
        }
        ,
    })
    ;
}
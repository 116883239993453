import React, { createContext, useState } from "react";
import { dataApi } from "services/api/api";

const EcoContext = createContext({});
const EcoContextProvider = ({ children }) => {
    const [ecoScore, setEcoScore] = useState([]);
    const getEcoScore = (dateStart, dateEnd, vehicleId) => {
        dataApi
            .getEcoScore(dateStart, dateEnd, vehicleId)
            .then((res) => setEcoScore(res))
            .catch((err) => console.error(err));
    };
    const downloadEcoScoreReport = (tsForm, tsTo, tz, orgIds) => {
        // TODO create job ID:s and poll-fetch them instead
        // because this query can take a very long time
        dataApi
            .getEcoReport(tsForm, tsTo, tz, orgIds)
            .then((r) => {
                const a = document.createElement("a");
                a.href = "data:application/pdf;base64," + r;
                a.download = "EcoScoreRapport.pdf";
                a.dispatchEvent(new MouseEvent("click"));
                message.destroy();
            })
            .catch((e) => {
                message.destroy();
            })
            .catch((err) => console.error(err));
    };
    return <EcoContext.Provider value={{ ecoScore, getEcoScore, downloadEcoScoreReport }}>{children}</EcoContext.Provider>;
};
export { EcoContext, EcoContextProvider };

import {MyVehicle} from "components/trackson-components/MyVehicles";
import {MapContextProvider} from "services/contexts/MapContext";
import {OrganizationContextProvider} from "services/contexts/OrganizationsContext";
import {
    AuthorizationProvider,
    RestrictedMapOrganizationsProvider,
    SelectDefaultVehicle,
    UserListProvider,
    VehicleProvider,
    VehiclesComplexListProvider
} from "services/contexts/contexts";

const MyVehiclePage = () => {
    return (
        <AuthorizationProvider>
            <OrganizationContextProvider>
                <VehiclesComplexListProvider>
                    <VehicleProvider>
                        <UserListProvider>
                            <MapContextProvider>
                                <SelectDefaultVehicle.provider>
                                    <RestrictedMapOrganizationsProvider>
                                        <MyVehicle/>
                                    </RestrictedMapOrganizationsProvider>
                                </SelectDefaultVehicle.provider>
                            </MapContextProvider>
                        </UserListProvider>
                    </VehicleProvider>
                </VehiclesComplexListProvider>
            </OrganizationContextProvider>
        </AuthorizationProvider>
    );
};

export default MyVehiclePage;

import {Menu, message, Table, Tag, Tooltip, Typography} from "antd";
import {useContext, useEffect, useState} from "react";
import {dataApi} from "../../../services/api/api";
import * as icons from "@ant-design/icons";
import {ButtonMenuDropdown, tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";
import {reportsTypesLookUp, STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SD_BASE_COLORS} from "../../../constants/CustomConstant";
import {shouldHideUsersOrganizationsColumn} from "../../../services/contexts/contexts";
import {ModalWithFullList} from "../shared-components/modalShowFullList";

const {Title} = Typography;

export const CurrentSchedules = (props) => {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState(props.knownReports);
    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);

    useEffect(() => {
        setAllData(props.knownReports);

        if (!props.selectedOrganization) {
            setData(props.knownReports);
        } else {
            const filteredData = props.knownReports?.filter((i) => i.organizationId === props.selectedOrganization);
            setData(filteredData);
        }
    }, [props.selectedOrganization, allData, props.knownReports]);

    const deleteReport = (record) => {
        dataApi.deleteReport(record.reportScheduleId).then((_) => {
            handleDelete(record);
        });
    };

    function handleDelete(row) {
        const newData = allData?.filter((item) => item?.reportScheduleId !== row?.reportScheduleId);
        setAllData(newData);
        props.setKnownReports(newData);
        message.success(STATIC_TEXT.REPORT_DELETED);
    }

    const columns = [
        {
            title: STATIC_TEXT.ORGANIZATION,
            dataIndex: "organizationId",
            key: "organizationId",
            hidden: hideUsersOrg,
            render: (text) => {
                const organization = props.knownOrganizations.find((i) => i.organizationId === text);
                return organization?.fullName;
            },
        },
        {
            title: STATIC_TEXT.REPORTS_TYPE,
            dataIndex: "reportType",
            key: "reportType",
            render: (text) => {
                return reportsTypesLookUp[text];
            },
        },
        {
            title: STATIC_TEXT.ALIAS,
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return text;
            },
        },
        {
            title: STATIC_TEXT.VEHICLE,
            dataIndex: "reportTarget",
            key: "reportTarget",
            width: 150,
            render: (text, record) => {
                if ((record?.reportTargetOrganizations === null || record?.reportTargetOrganizations.length === 0) && text?.length === 0) {
                    return "-";
                }
                if (text?.length === 1 && record.reportTargetOrganizations.length === 0) {
                    const vehicle = props.knownVehicles?.find((v) => v.id === text[0]);
                    let tooltip = `${vehicle?.displayName} ${vehicle?.defaultDriver === null ? "-" : vehicle?.defaultDriver?.displayName} / ${
                        vehicle?.currentDriver === null ? "-" : vehicle?.currentDriver?.displayName
                    }`;

                    return (
                        <Tooltip title={tooltip}>
                            <Tag color={SD_BASE_COLORS.SWITCH_BUSINESS} style={{margin: 5}}>
                                {vehicle?.registry}
                            </Tag>
                        </Tooltip>
                    );
                }
                if (record?.reportTargetOrganizations.length === 1 && text?.length === 0) {
                    const orgs = record?.reportTargetOrganizations?.map((e) => {
                        const org = props.knownOrganizations?.find((o) => o.organizationId === e);
                        return org?.fullName;
                    });

                    return STATIC_TEXT.ALL_VEHICLES + " inom " + orgs?.map((o) => o + " ");
                }
                if (text?.length > 0 && record.reportTargetOrganizations.length === 0) {
                    let title = text?.length + " " + STATIC_TEXT.VEHICLE;
                    return ModalWithFullList(title, text, null, null, props.knownVehicles, null, props.knownOrganizations)
                }
                if (text?.length >= 0 && record.reportTargetOrganizations.length > 0) {
                    const vehicles = [];
                    if (text?.length > 0) {
                        text?.forEach((r) => vehicles.push(r));
                    }
                    if (record?.reportTargetOrganizations.length > 0) {
                        record?.reportTargetOrganizations.forEach((v) => {
                            props?.knownVehicles.filter((r) => r.organizationId === v).forEach((vx) => vehicles.push(vx.id));
                        });
                    }

                    let title = vehicles?.length + " " + STATIC_TEXT.VEHICLE;
                    return ModalWithFullList(title, vehicles, null, null, props.knownVehicles, null, props.knownOrganizations)
                }
            },
        },
        {
            title: STATIC_TEXT.DRIVER,
            dataIndex: "reportTargetDrivers",
            key: "reportTargetDrivers",
            width: 150,
            render: (text, record) => {
                if (record?.reportTargetDriverOrganizations === null && text?.length === 0) {
                    return "-";
                }
                if (text?.length === 1 && record.reportTargetDriverOrganizations.length === 0) {
                    const driver = props.knownUsers?.find((v) => v.userId === text[0]);
                    return (
                        <a>{driver?.displayName}</a>
                    );
                }
                if (text?.length === 0 && record?.reportTargetDriverOrganizations.length === 0) {
                    return "-";
                }
                if (record?.reportTargetDriverOrganizations.length === 1 && text?.length === 0) {
                    const org = props?.knownOrganizations.find((i) => i.organizationId === record?.reportTargetDriverOrganizations[0]);
                    return `${STATIC_TEXT.ALL_DRIVERS} inom ${org?.fullName}`;
                }
                if (text?.length > 0 && record.reportTargetDriverOrganizations.length === 0) {
                    let title = text?.length + " " + STATIC_TEXT.DRIVER;
                    return ModalWithFullList(title, null, null, text, null, props.knownUsers, props.knownOrganizations)
                }
                if (text?.length >= 0 && record.reportTargetDriverOrganizations.length > 0) {
                    const drivers = [];
                    if (text?.length > 0) {
                        text?.forEach((r) => drivers.push(r));
                    }
                    if (record?.reportTargetDriverOrganizations.length > 0) {
                        record?.reportTargetDriverOrganizations.forEach((v) => {
                            props?.knownUsers.filter((r) => {
                                if (r.organizationId === v) drivers.push(r.userId);
                            });
                        });
                    }
                    let title = drivers?.length + " " + STATIC_TEXT.DRIVER;
                    return ModalWithFullList(title, null, null, drivers, null, props.knownUsers, props.knownOrganizations)
                }
            },
        },
        {
            title: STATIC_TEXT.MAIL_RECIPIENTS,
            dataIndex: "email",
            key: "email",
            render: (text) => {
                if (text?.length === 1) {
                    return <a>{text[0]}</a>;
                } else if (text?.length === 0) {
                    return "-";
                } else {
                    let title = text?.length + " " + STATIC_TEXT.MAIL_RECIPIENTS;
                    return ModalWithFullList(title, null, text)
                }
            },
        },
        {
            title: STATIC_TEXT.SEND_REPORT,
            dataIndex: "schedule",
            key: "schedule",
            render: (text) => {
                return STATIC_TEXT.THIS + " " + text + ":e " + STATIC_TEXT.EVERY_MONTH;
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    function menuDropdown(record) {
        return <Menu>
            <Menu.Item danger={true} icon={<icons.DeleteOutlined/>}
                       onClick={() => deleteReport(record)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }

    return (
        <div>
            <Title level={3}>{STATIC_TEXT.CURRENT_SCHEDULES}</Title>
            <Table columns={tableColumnsWithTooltip(columns)} scroll={{x: 1000}}
                   dataSource={data} rowClassName="editable-row"
                   {...tableProps}/>
        </div>
    );
};

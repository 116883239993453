import React, {useEffect, useState} from "react"
import {env} from "../../configs/EnvironmentConfig"
import {dataApi} from "../api/api"
import {HSVtoRGB} from "../../configs/PayStations"
import {jwt_xhrp2} from "../../trx"

const {DEBUG_ENDPOINT_URL }= env
const {API_ENDPOINT_URL} = env

export const TaxContext = React.createContext({
    loaded: false,
    payStations: []
})
export const TaxProvider = ({...props}) => {
    const [data, setData] = useState({
        loading: true,
        error: null,
        debug: true,
        stations: [],
        references: []
    })

    const cpSub = data.debug ? d => {
        //return d >= 100000 && d <= 100100
        //return d >= 100200 && d <= 100300
        //return d >= 100000 && ((d%10000) >= 200 && (d%10000) <= 300)
        //return d >= 100000 && ((d%10000) >= 300 && (d%10000) <= 400)
        //noinspection PointlessArithmeticExpressionJS
        return d >= 100000;
        const seg = 3 * 100
        return d >= 100000 && (((d % 10000) >= seg) && ((d % 10000) <= 100 + seg))
    } : () => true


    const getStations = () => {
        return jwt_xhrp2("GET", DEBUG_ENDPOINT_URL + "/stations")
            .then(({response}) => {
                return JSON.parse(response)
            })
            .then(data => {
                return data.map(v => {
                    let c = HSVtoRGB(parseFloat(v.number) / 26, 1, 0.8)
                    let color = '#' + ((c.r << 16) + (c.g << 8) + (c.b) + (1 << 17)).toString(16).substr(1)
                    //console.log("COLOR = " + color)
                    let colStr = 'rgb(' + c.r + ' ' + c.g + ' ' + c.b + ')'
                    //console.log("COLOR STR= " + colStr)
                    return {
                        color: colStr,
                        lat: v.geometry.coordinates[1],
                        lng: v.geometry.coordinates[0],
                        angleDeg: 90 - v.direction_deg,
                        slug: "" + v.number + ":" + v.name,
                        lineNumber: v._row,
                        base: v
                    }
                })
            })
    }

    const getReferences = () => {
        return jwt_xhrp2("GET", DEBUG_ENDPOINT_URL + "/references")
            .then(({response}) => JSON.parse(response))
            .then(data => {
                return data.map(v => {
                    return {
                        color: v.reference_back ? "#ff0000" : "#0000ff",
                        longitude: v.geometry.coordinates[0],
                        latitude: v.geometry.coordinates[1],
                        cpId: v.cp_id,
                        angleDeg: 90 - v.direction_deg,
                        back: v.reference_back
                    }
                })
            })
    }

    const api = {
        reload: () => {
            // setData({...data, loading: true})
            setData({...data})
            Promise.all([
                getStations(),
                getReferences()
            ])
                .then(([stations, references]) => {
                    //console.debug("Stations and refs:", stations, references)
                    setData({...data, loading: false, stations, references})
                })
        },
        putStationOverrides: (cpId, overrides) => {
            const newstr = JSON.stringify(overrides)
            console.debug("INCREMENT", newstr)
            return jwt_xhrp2("PUT", DEBUG_ENDPOINT_URL + "/station-override/" + cpId, false, newstr)
        }
    }

    useEffect(() => {
        api.reload()
    }, [])

    return <TaxContext.Provider value={{
        debug: data.debug,
        loading: data.loading,
        error: data.error,
        stations: data.stations.filter(x => cpSub(x.base.cp_id)),
        references: data.references.filter(x => cpSub(x.cpId)),
        ...api
    }}>{props.children}</TaxContext.Provider>
}
export const TripTaxContext = React.createContext({})
export const TripTaxProvider = ({track = null, ...props}) => {
    const [data, setData] = useState({})

    const getTripCoords = (tripId) => {
        console.log("getting trip coords for", tripId)
        return jwt_xhrp2("GET", DEBUG_ENDPOINT_URL + "/path-info/" + tripId)
            .then(({response}) => JSON.parse(response))
    }

    const checkCognestion2 = (tripId) => {
        // debounce because we're making multiple reqs for some reason
        // if (checkingCog) return Promise.resolve([])
        return jwt_xhrp2("GET", DEBUG_ENDPOINT_URL + "/check-path/" + tripId).then(
            ({response}) => {
                return JSON.parse(response)
            }
        )
    }

    useEffect(() => {
        if (!track) return
        api.updateCoords(track)
    }, [track])

    const api = {
        purgeTrip: (tripId) => {
            return jwt_xhrp2("POST", API_ENDPOINT_URL + "/journal/" + tripId + "/reset-toll").then(() => {
                setData({
                    ...data,
                    [tripId]: {loaded: false, error: null, data: null}
                })
            })
        },
        updateCoords: (tuid) => {
            if (!tuid) {
                console.debug("No trip selected, ", tuid)
                return
            }

            getTripCoords(tuid).then((trip) => {
                console.debug("Got trip coords", trip)
                return Promise.all([
                    trip,
                    checkCognestion2(tuid)
                ])
            }).then(([{
                original_timestamps,
                road_interpolation,
                resampled_path,
                snap_source,
                original_path,
            }, cog]) => {
                setData({
                    ...data,
                    [tuid]: {
                        loaded: new Date(),
                        error: false,
                        data: {
                            coords: original_path.coords,
                            interpCoords: road_interpolation.coords,
                            resampledTrip: resampled_path.coords,
                            snapSource: snap_source,
                            originalTimestamps: original_timestamps,
                            prices: cog.prices,
                            passages: cog.passages.map((p, i) => {
                                return {...p, price: cog.prices[i]}

                            }),
                            hits: cog.passages.filter(x => x.hit).map(x => x.cp_id)
                        }
                    }
                })
            })
        }
    }

    const tracked = track &&
        data[track] &&
        data[track].loaded &&
        data[track].data

    return <TripTaxContext.Provider value={{
        byId: data,
        ...api,
        tracked: tracked,
    }}>{props.children}</TripTaxContext.Provider>
}

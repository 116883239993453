import { useContext, useEffect, useState } from "react";
import { UsersContext } from "services/contexts/UsersContext";
import { Typography, Descriptions, Col, Row, Button } from "antd";
import Loading from "components/shared-components/Loading/Loading";
import { OrganizationContext } from "services/contexts/OrganizationsContext";
import { formatAnyAddress } from "constants/Modules";
const { Title } = Typography;
const Account = ({ onEditAccount = () => null }) => {
    const { currentUser } = useContext(UsersContext);
    const { getOrganizationById } = useContext(OrganizationContext);
    const [currentUserOrg, setCurrentUserOrg] = useState();
    const getOrganization = async (id) => {
        const org = await getOrganizationById(id);
        if (org !== undefined) setCurrentUserOrg(org);
    };
    useEffect(() => {
        if (currentUser !== undefined) getOrganization(currentUser.organizationId);
    }, [currentUser]);
    const editAccount = () => {
        onEditAccount(currentUser, currentUserOrg);
    };
    return currentUser === undefined || currentUserOrg === undefined ? (
        <Loading />
    ) : (
        <div>
            <Row justify="space-between">
                <Col>
                    <Title>Organisation - Konto</Title>
                </Col>
                <Col>
                    <Button type="primary" onClick={editAccount}>
                        Ändra
                    </Button>
                </Col>
            </Row>
            <div>
                <Descriptions bordered column={{ xs: 1, sm: 1, md: 1 }}>
                    <Descriptions.Item label="Företagsnamn">{currentUserOrg.fullName}</Descriptions.Item>
                    <Descriptions.Item label="Organisationsnummer">{currentUserOrg.organizationNumber}</Descriptions.Item>
                    <Descriptions.Item label="Postadress">{formatAnyAddress(currentUserOrg.postalAddress)}</Descriptions.Item>
                    <Descriptions.Item label="Fakturaadress">{formatAnyAddress(currentUserOrg.invoiceAddress)}</Descriptions.Item>
                    <Descriptions.Item label="E-post">{currentUserOrg.email}</Descriptions.Item>
                    <Descriptions.Item label="Telefonnummer">{currentUserOrg.phone}</Descriptions.Item>
                </Descriptions>
            </div>
        </div>
    );
};

export default Account;

import React, {useEffect, useRef, useState} from "react";
import {dataApi} from "../../../services/api/api";
import {Form, Input} from "antd";

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}
export const TimerInputSubmitterComment = ({tripId, value, timeout = 1000}) => {
    const [validateStatus, setValidateStatus] = useState("");
    const [inputValue, setInputValue, valueRef] = useStateAndRef(value);
    const [cache, setCache, cacheRef] = useStateAndRef(value);

    const maybeRequest = () => {
        if (validateStatus === "validating") return;

        if (valueRef.current === null) return;

        if (valueRef.current === cacheRef.current) return;

        dataApi
            .putJournal(tripId, {adminComment: valueRef.current || ""})
            .then(() => {
                setValidateStatus("success");
                setCache(valueRef.current);
            })
            .catch((e) => {
                setValidateStatus("error");
                setInputValue(value);
            });
    };

    useEffect(() => {
        const interval = setInterval(maybeRequest, timeout);
        return () => {
            clearInterval(interval); // cleanup
        };
    }, []);
    return (
        <Form style={{width: 140}}>
            <Form.Item style={{margin: "auto"}} hasFeedback validateStatus={validateStatus}>
                <Input
                    style={{
                    }}
                    value={inputValue}
                    onChange={(e) => {
                        setValidateStatus("validating");
                        setInputValue(e.target.value);
                    }}
                    placeholder={"Skriv kommentar..."}
                    allowClear
                ></Input>
            </Form.Item>
        </Form>
    );
};
import {createElement} from 'react';
import moment from 'moment-timezone';
import {SD_BASE_COLORS} from "../constants/CustomConstant";

//export const convertTimestampToDateStr = (unixTimestamp = 0, format = 'YYYY-MM-DD HH:mm:ss') => {
//    return moment.unix(unixTimestamp).format(format)
//}

export const reduceEntries = obj => obj.reduce((r, a) => {
        r[a[0]] = r[a[0]] || {};
        Object.assign(r[a[0]],  a[1])
        return r;
}, Object.create(null))

export const filterSpread = (lst, key, item) => {
    return [...lst.filter( e => e[key] !== item[key]), item]
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const getTimestampInSeconds = () => {
    return moment().unix()
}

export const convertTimestampToDateStr = (timestamp = 0, format = 'YYYY-MM-DD HH:mm:ss', unix = false) => {
    let tz = moment.tz.guess();

    if(unix) {
    return moment.unix(timestamp).tz(tz).format(format)
  } else {
    return moment(timestamp).tz(tz).format(format)
  }
}


/*
// https://stackoverflow.com/questions/55424790/how-i-draw-a-route-with-react-google-maps-component
// https://github.com/tomchentw/react-google-maps/blob/master/src/components/DirectionsRenderer.md
function MapDirectionsRenderer(props) {
  const [directions, setDirections] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { places, travelMode } = props;

    const waypoints = places.map(p => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true
    }));
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints
      },
      (result, status) => {
        console.log(result)
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          setError(result);
        }
      }
    );
  });

  if (error) {
    return <h1>{error}</h1>;
  }
  return (
    directions && (
      <DirectionsRenderer directions={directions} />
    )
  );
}
 */

export const range = n => Array.from(Array(n).keys())

export const formatMillis = (start_ms, stop_ms) => {
    let ms = Math.abs(moment(stop_ms).diff(moment(start_ms)));
    let d = moment.duration(ms);
    const hours = Math.floor(d.asHours())
    const minutes = d.minutes()
    if (hours === 0) {
        return `${minutes} m`
    }
    return `${hours} h ${minutes} m`
}

export const formatFromMillis = (milisec) => {
    let d = moment.duration(milisec);
    const hours = Math.floor(d.asHours())
    const minutes = d.minutes()
    if (hours === 0) {
        return `${minutes} m`
    }
    return `${hours} h ${minutes} m`
}

// Cthulhu R'lyeh UWU
export const formatSwedishNumber = (num) => {
    return (num).toString().split('').reverse().join('').match(/.{1,3}/g).reverse().map((x) => x.split('').reverse().join('')).join(' ')
}

export function eagerRange(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}


export const Nest = ({wrappers, ...props}) => {
    const recur = (rem) => {
        if (rem.length > 0) {
            const compo = rem.pop()
            return createElement(compo, null, recur(rem))
        }
        return props.children
    }

    const b = [...wrappers]
    b.reverse()
    return recur(b)
} //Capitalize first letter
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const colorTripType = (trip) => {
    return {
        color: trip?.purpose === "PRIVATE" || trip?.purpose === "PRIVATE_AUTO" ? SD_BASE_COLORS.SWITCH_PRIVATE : SD_BASE_COLORS.SWITCH_BUSINESS,
    };
};
const styles = {
    checkbox: {
        width: "100%",
        margin: "10px 0",
    },
    divider: {
        margin: "12px 0",
    },
};

export default styles;

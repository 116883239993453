import {GOOGLE_MAPS_API} from "../../../configs/AppConfig";
import {mapOptions, setLightMapType} from "../../../configs/mapStyle";
import React from "react";
import {Modal} from "antd";
import GoogleMapReact from "google-map-react";
import {Icon} from "react-icons-kit";
import * as icons from "react-icons-kit/fa";

export const ModalWithAlarmsDetails = ({showModalForAlarmGeo, setShowModalForAlarmGeo, record}) => {
    if (!record?.position) return null;

    return (
        <Modal title={record?.address} visible={showModalForAlarmGeo} onOk={() => setShowModalForAlarmGeo(false)}
               onCancel={() => setShowModalForAlarmGeo(false)} footer={null} width={1000}>
            <GoogleMapReact
                style={{height: 400, transform: "translate(0, 0px)"}}
                bootstrapURLKeys={{key: GOOGLE_MAPS_API}}
                center={[record?.position?.latitude, record?.position?.longitude]}
                zoom={15}
                options={mapOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({map, maps}) => {
                    setLightMapType(maps, map);
                }}
            >
                <Marker lat={record?.position?.latitude} lng={record?.position?.longitude}/>
            </GoogleMapReact>
        </Modal>
    );
};

const Marker = () => {
    return (
        <Icon
            icon={icons.warning}
            style={{
                fontSize: 16,
                borderRadius: "50%",
                boxShadow: "0 0 0 20px rgba(255,0,0,0.35)",
                backgroundColor: "0 0 0 20px rgba(255,0,0,0.35)",
                outlineStyle: "circle",
                color: "red",
            }}
        />
    );
};

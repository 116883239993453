import React, {useContext, useEffect, useState} from 'react'
import {Form, Input, Menu, message, Modal, Select, Table} from 'antd';
import * as icons from '@ant-design/icons';
import {DeleteOutlined, ToolOutlined, EditOutlined} from '@ant-design/icons';
import {Option} from "antd/es/mentions";
import {useDispatch} from "react-redux";
import {
    SuperAdminDevicesContext
} from "../../../contexts/SuperAdminDevicesContext"
import {dataApi} from "../../../services/api/api";
import {CollectionCreateForm, gData, generateData, generateList, z} from "./CollectionCreateForm";
import {RootOrganisationsContext} from "../../../services/contexts/views-context/root-organisations-context";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {ButtonMenuDropdown, tableColumnsWithTooltip, tableProps} from "../shared-components/sharedComponents";
import Loading from "../../shared-components/Loading";
import {OrganizationSimpleContext} from "../../../services/contexts/contexts";

export const OrganizationsTable = ({searchTerm, editingKey, visible, setVisible, setEditingKey, ...props}) => {

    const [filteredList, setFilteredList] = useState('');
    const [initialSwitchStates, setInitialSwitchStates] = useState({});
    const {fetchSimplyOrganizations} = useContext(OrganizationSimpleContext)

    const {data, setData, deviceLoaded, knownDevices, knownVehicles, knownUsers} = useContext(RootOrganisationsContext)
    // const suContext = useContext(SuperAdminDevicesContext)

    useEffect(() => {
        if (!visible || (knownVehicles && knownUsers)) {
            return
        }

        if (deviceLoaded) {
            return
        }
        // TODO this actually doesnt fetch anything that is used here...?
        // suContext.requestFetchDevices()

    }, [editingKey])

    useEffect(() => {
        let list = data.filter(i => JSON.stringify(i).toLowerCase().includes(searchTerm))
        setFilteredList(list)
        setEditingKey('')
    }, [searchTerm, data])

    useEffect(() => {
        props.setNewRecord(false)
    }, [visible])
    // const dispatch = useDispatch()

    useEffect(() => {
        if(data)
            fetchSimplyOrganizations()
    }, [data])

    const onModalFinish = (values, switchStates) => {
        setEditingKey('')
        setVisible(false)
        if (editingKey) {
            save(editingKey, values, switchStates)
        } else {
            onCreate(values)
        }
    }

    const onCreate = (values, switchStates) => {
        setVisible(false);
        setEditingKey('')
        let id;

        dataApi.postOrganization(values)
            .then((orgId, _) => {
                id = orgId.organizationId
                values.organizationId = orgId.organizationId
                handleAdd(values)
                message.success(STATIC_TEXT.NEW_ORG_CREATED)
                // dispatch({type: SUPER_ORG_REQUEST_VEHICLE_RESET})
            })
            .then(_ => {
                if(!switchStates)return
                Object.entries(switchStates).forEach(([name, value]) => {
                    if (initialSwitchStates[name] === value) return
                    if (value) {
                        dataApi.postAdminOrgSettings(name, id);
                    } else {
                        dataApi.deleteAdminOrgSettings(name, id);
                    }
                });

            })
    };

    const handleAdd = (row) => {
        const newData = [row, ...data];
        setData(newData)
    }

    const isEditing = record => record.organizationId === editingKey;
    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key, values, switchStates) => {
        try {
            const row = values;
            const newData = [...data];
            const index = newData.findIndex(item => key === item.organizationId);

            if (!values.parentOrganization) {
                row.parentOrganization = 0
            }

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {...item, ...row});
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }

            dataApi.patchOrganization(key, row)
                .then(_ => {
                    message.success(STATIC_TEXT.YOUR_CHANGED_SAVED)
                    setData(newData)
                    // dispatch({type: SUPER_ORG_REQUEST_VEHICLE_RESET})
                })
                .then(_ => {
                    Object.entries(switchStates).forEach(([name, value]) => {
                        if (initialSwitchStates[name] === value) return
                            if (value) {
                            dataApi.postAdminOrgSettings(name, key);
                        } else {
                            dataApi.deleteAdminOrgSettings(name, key);
                        }
                    });

                })

        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };


    const deleteOrganisation = record => {
        const confirmDelete = () => {
            dataApi.deleteOrganization(record.organizationId)
                .then(_ => {
                    message.success(STATIC_TEXT.COMPANY_DELETED);
                    handleDelete(record);
                    // dispatch({type: SUPER_ORG_REQUEST_VEHICLE_RESET});
                })
                .catch(error => {
                    message.error('Ett fel inträffade vid borttagning av organisationen.');
                    console.error(error);
                });
        };

        Modal.confirm({
            title: 'Bekräfta borttagning',
            content: 'Är du säker på att du vill ta bort denna organisation?',
            okText: 'Ta bort',
            okType: 'danger',
            cancelText: 'Avbryt',
            onOk: confirmDelete,
        });
    };

    function handleDelete(row) {
        const newData = data.filter(item => item.organizationId !== row.organizationId);
        setData(newData)
    }

    function showModalWithModules(record) {
        setVisible(true)
        setEditingKey(record.organizationId);
    }

    const columns = [
        {
            title: STATIC_TEXT.ORG_NAME,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.fullname,
            editable: false,
            fixed: "left",
        },
        {
            title: STATIC_TEXT.PARENT,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.parentOrganization,
            editable: false,
            render: (value) => {
                const parentOrganization = data.find(i => i.organizationId === value && value !== 0);
                return parentOrganization?.fullName
            }
        },
        {
            title: STATIC_TEXT.MAIL,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.email,
            editable: false,
        },
        {
            title: STATIC_TEXT.ORG_NUM,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.organizationNumber,
            editable: false,
        },
        {
            title: STATIC_TEXT.POSTAL_ADDRESS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.postalAddress,
            editable: false,
            render: (value) => {
                if (value?.streetAndNum)
                    return (value?.streetAndNum + ", " + value?.zip + ", " + value?.city + ", " + value?.country)
            }
        },
        {
            title: STATIC_TEXT.PHONE_NUMBER,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            editable: false
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            fixed: "right",
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ];



    function menuDropdown(record) {
        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => showModalWithModules(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => deleteOrganisation(record)}>{STATIC_TEXT.DELETE} </Menu.Item>
        </Menu>
    }


    return (
        <Form component={false}>

            <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px'}}>

                <CollectionCreateForm
                    visible={visible}
                    organizations={data}
                    onModalFinish={onModalFinish}
                    knownVehicles={knownVehicles}
                    knownUsers={knownUsers}
                    knownDevices={knownDevices}
                    initialSwitchStates={initialSwitchStates}
                    setInitialSwitchStates={setInitialSwitchStates}
                    record={data.find(i => i.organizationId === editingKey)}
                    isNewRecordForm={props.isNewRecordForm}
                    onCancel={() => {
                        setVisible(false)
                        setEditingKey('')
                    }}
                />
            </div>
            {!data ? <Loading/> :
                <Table
                dataSource={filteredList}
                columns={tableColumnsWithTooltip(columns)}
                rowKey={record => record?.organizationId}
                scroll={{x: 1000}}
                pagination={{
                    onChange: cancel,
                }}

                {...tableProps}
            />
            }        </Form>
    );
};

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          record,
                          index,
                          children,
                          ...restProps
                      }) => {

    const inputNode = (() => {
        switch (dataIndex) {
            case SUPER_DEVICES_KEYS_AND_INDEXES.postalAddress:
                return <AddressForm record={record?.postalAddress} typ="postalAdd"/>

            case "invoiceAddress":
                return <AddressForm record={record?.invoiceAddress} typ={"invoiceAddr"}/>

            case "parentOrganization":
                return (
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            JSON.stringify(option?.children)?.toLowerCase().includes(input.toLowerCase())
                        }
                        style={{minWidth: 150}}
                        defaultValue={record?.parentOrganization}>
                        <Option value={0}>Ingen</Option>
                        {restProps?.organizations.map(i => {
                            return <Option value={i.organizationId}>{i.fullName}</Option>
                        })}
                    </Select>
                )

            default :
                return <Input/>
        }
    })()

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: false,
                            message: `Ange ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

generateData(z);

generateList(gData);


export const AddressForm = ({record, typ}) => {
    return (
        <Form.Item style={{display: 'flex', justifyContent: 'center', margin: 'auto'}}>
            <Form.Item
                name={[typ, SUPER_DEVICES_KEYS_AND_INDEXES.streetAndNum]}
                initialValue={record?.streetAndNum}
                label={STATIC_TEXT.STREET_ADDRESS}
                rules={[{required: false, message: 'Province is required'}]}
            >
                <Input
                    prefix={<icons.EnvironmentOutlined style={{marginRight: 3}}/>}
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_STREET_ADDRESS}
                />
            </Form.Item>
            <Form.Item
                name={[typ, SUPER_DEVICES_KEYS_AND_INDEXES.zip]}
                label={STATIC_TEXT.POSTAL_CODE}
                initialValue={record?.zip}
                rules={[{required: false, message: 'Street is required'}]}
            >
                <Input
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_POSTAL_CODE}

                />
            </Form.Item>
            <Form.Item
                name={[typ, SUPER_DEVICES_KEYS_AND_INDEXES.city]}
                initialValue={record?.city}
                label={STATIC_TEXT.CITY}
                rules={[{required: false, message: 'Street is required'}]}
            >
                <Input
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTY}
                    defaultValue={record?.city}
                />
            </Form.Item>
            <Form.Item
                name={[typ, SUPER_DEVICES_KEYS_AND_INDEXES.state]}
                initialValue={record?.state}
                label={STATIC_TEXT.COUNTY}
                rules={[{required: false, message: 'Street is required'}]}
            >
                <Input
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTY}
                    defaultValue={record?.state}
                />
            </Form.Item>
            <Form.Item
                name={[typ, SUPER_DEVICES_KEYS_AND_INDEXES.country]}
                initialValue={record?.country}
                label={STATIC_TEXT.COUNTRY}
                rules={[{required: false, message: 'Street is required'}]}
            >
                <Input
                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_COUNTRY}
                    defaultValue={record?.country}
                />
            </Form.Item>
        </Form.Item>
    )
}

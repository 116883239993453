import React, {useEffect, useState} from "react"
import {Button, message, Select, Typography} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {dataApi, userApi} from "../../../services/api/api";
import Loading from "../../shared-components/Loading/Loading";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {LocationList} from "./LocationList";
import {useTranslation} from "react-i18next";
import {CONSTANTS_TEXT, customConstant} from "../../../constants/CustomConstant";
import {MESSAGE_NOTIFICATION, STATIC_TEXT, ZONES_STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {UsersSelect} from "../shared-components/UsersSelect";

export const SWEDEN_COORDS = [
    {latitude: 55.27, longitude: 14.09},
    {latitude: 68.01, longitude: 19.22},
]

let userId;

export const Zones = () => {
    const [locations, setLocations] = useState()
    const [loading, setLoading] = useState(true)
    const [knownOrganizations, setKnownOrganizations] = useState()
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const [knownUsers, setKnownUsers] = useState()
    const [filteredUsers, setFilteredUsers] = useState()
    const [showOrganizationSelector, setShowOrganizationSelector] = useState(false)
    const [showMap, setShowMap] = useState(false)
    const [getLocation, setGetLocation] = useState()
    const [editKey, setEditKey] = useState('')

    const { t } = useTranslation();

    navigator?.geolocation.getCurrentPosition(
        ({coords: {latitude: lat, longitude: lng}}) => {
            const pos = {lat, lng};
            setGetLocation(pos)
        }
    );

    useEffect(() => {
        dataApi.getOrganizationsSimple().then(r => {
            setKnownOrganizations(r)
        })

    }, [])

    useEffect(() => {
        userApi.getCurrentUser().then(user => {
            userId = user.userId
            setSelectedId(userId)
            getZonesFromAPI(userId)

            if (user.roles?.includes("ROOT")) {
                setShowOrganizationSelector(true)
            }
        })
        getUsers()
    }, [])

    useEffect(() => {
        let filteredList = knownUsers
        if (knownUsers?.length > 0 && selectedOrganization?.length > 0) {
            filteredList = filteredList?.filter(
                i => selectedOrganization.includes(i.organizationId))
        }
        setFilteredUsers(filteredList)
    }, [selectedOrganization])

    const getZonesFromAPI = (userId) => {
        setLoading(true)
        dataApi.getZones(userId).then(d => {
            setLocations(d)
            setLoading(false)
        })
    }

    const getUsers = () => {
        dataApi.getUsersSimple()
            .then(res => {
                setKnownUsers(res)
                setFilteredUsers(res)
            })
    }
    const organizationSelectOnChange = value => {
        setSelectedOrganization(value)
    };

    const handleSelectId = (e) => {
        setSelectedId(e)
        setLoading(true)
        getZonesFromAPI(e)
    }

    const handleAddNewLocation = () => {
        const row = {
            latitude: getLocation ? getLocation?.lat : 59.334591,
            longitude: getLocation ? getLocation?.lng : 18.06324,
            tripType: null,
            zoneName: ZONES_STATIC_TEXT.NEW_ZONE,
            radiusMs: 400,
            activeOnTripEnd: true,
            activeOnTripStart: true
        }

        dataApi.postZone(row, selectedId || userId)
            .then((id) => {
                row.zoneId = id
                setShowMap(true)

                let newData;
                if (locations) {
                    newData = [row, ...locations]
                } else {
                    newData = [row]
                }
                setLocations(newData)
                setEditKey(id)

            }).catch(_ => {
            message.error(MESSAGE_NOTIFICATION.SOMETHING_GONE_WRONG_TRY_AGAIN)
        })

        message.destroy()
        message.info(STATIC_TEXT.DBCLICK_ON_MAP_TO_CHOOSE_LOCATION, 15)
    }

    return loading ? <Loading/> : <>

        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: "wrap"}}>
            <Typography.Title>Platser</Typography.Title>
            <div style={{display: 'flex', gap: 15, flexWrap: "wrap"}}>
                {!showOrganizationSelector ? null :
                    <div style={{display: 'flex', gap: 15}}>
                        <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                 selectedOrganization={selectedOrganization} isMultiple={true}/>
                        <br/>

                        <UsersSelect
                            data={filteredUsers}
                            value={selectedId}
                            onChange={(e) => handleSelectId(e)}
                        />

                    </div>
                }
                <Button type={'primary'} disabled={showMap} onClick={handleAddNewLocation}
                        icon={<PlusOutlined/>}>{STATIC_TEXT.ADD_NEW_ZONE}</Button>

            </div>

        </div>
        <LocationList
            locations={locations}
            setLocations={setLocations}
            selectedId={selectedId}
            editKey={editKey}
            setEditKey={setEditKey}
            showMap={showMap}
            setShowMap={setShowMap}
            userId={userId}
        />
    </>
}

export const createBoundsFromZonesData = (maps, data) => {
    const bounds = new maps.LatLngBounds()
    if (!data) {
        data = SWEDEN_COORDS
    }

    data.forEach(d => {
        const lat = d?.latitude || d?.lat
        const lng = d?.longitude || d?.lng

        if (lat && lng) {
            // This is valid coordinate
            const newCoordinate = new maps.LatLng(lat, lng)
            bounds.extend(newCoordinate)
        }
    })

    return bounds
}

import React, {createContext, useState, useEffect} from 'react';

const VersionContext = createContext();

export const VersionProvider = ({children}) => {
    const [loading, setLoading] = useState(true);
    const [isLatestVersion, setIsLatestVersion] = useState(true);
    const [serverVersion, setServerVersion] = useState();

    useEffect(() => {
        fetch('/app-version')
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError('Received non-JSON response from server');



                }
                return response.json();
            })
            .then(data => {
                console.log('Date:', data);
                setServerVersion(data.date)
            })
            .catch(error => {
                console.error(error);
            });

    }, [])

    useEffect(() => {
        checkForNewVersion()
    }, [serverVersion])

    const refreshCacheAndReload = () => {
        console.log('Clearing cache and hard reloading...');
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
    };

    const checkForNewVersion = () => {
        const localVersion = localStorage.getItem('appVersion');

        if (serverVersion && serverVersion !== localVersion) {
            console.log(`We have a new version - ${localVersion}. Should force refresh`);
            localStorage.setItem('appVersion', serverVersion);
            setIsLatestVersion(false);
        } else {
            console.log(`You already have the latest version - ${localVersion}. No cache refresh needed.`);
            setIsLatestVersion(true);
        }
        setLoading(false)
    };

    return (
        <VersionContext.Provider value={{isLatestVersion, refreshCacheAndReload, loading}}>
            {children}
        </VersionContext.Provider>
    );
};
export const useVersion = () => React.useContext(VersionContext);

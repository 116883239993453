import {Nest} from "../../utils/utilities";
import {
    AuthorizationProvider,
    OrganizationSimpleProvider,
    shouldHideUsersOrganizationsColumn,
    UserListProvider, UserListSimpleProvider, VehicleProvider,
    VehiclesComplexListProvider
} from "../../services/contexts/contexts";
import AlarmFunctionsController from "../../components/trackson-components/AlarmsFunctions";

const AlarmsFunctionsPage = () => (
    <Nest
        wrappers={[AuthorizationProvider,
            shouldHideUsersOrganizationsColumn.provider,
            VehiclesComplexListProvider, VehicleProvider,
            UserListSimpleProvider, UserListProvider, OrganizationSimpleProvider]}>
        <AlarmFunctionsController/>
    </Nest>
);

export default AlarmsFunctionsPage;

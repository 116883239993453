import {AUTH_PREFIX_PATH} from 'configs/AppConfig'

class ErrorService {

redirectToSystemUpdate(){
    window.location = `${AUTH_PREFIX_PATH}/error-1`
}

redirectToErrorSite(){
    window.location = `error-2`
}
}

const errorService = new ErrorService()

export {errorService}

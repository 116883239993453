import React, {useContext, useEffect, useState, forwardRef } from "react";
import {connect, useDispatch} from "react-redux";
import * as icons from "@ant-design/icons";
import {SettingOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Drawer, Form, Input, Menu, message, Radio, Row, Switch, Typography} from "antd";

import {signOut} from "services/redux/actions/authActions";
import {dataApi, userApi} from "../../../services/api/api";
import {
    MAP_TYPE,
    ORGANIZATION_ID,
    SHOW_ARCHIVED,
    USER_ID,
    USER_ORG_ID
} from "../../../services/redux/constants/authConstants";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import Flex from "../../shared-components/Flex/Flex";
import {
    OrganizationSimpleContext,
    OrganizationSimpleProvider,
    VehicleContext,
    VehicleProvider
} from "../../../services/contexts/contexts";
import {Nest} from "../../../utils/utilities";
import Loading from "../../shared-components/Loading/Loading";

const {Title} = Typography;
const {Text} = Typography;

const SettingsPanel = ({...props}) => {
    const [loading, setLoading] = useState(true);
    const [showGDPR, setShowGDPR] = useState(false);
    const [currentUser, setCurrentUserData] = useState(null);
    const [windowReload, setWindowReload] = useState(false);
    const [showArchiveData, setShowArchiveData] = useState(false);
    const knownVehicles = useContext(VehicleContext).raw;
    const getOrganizationFromLS = localStorage.getItem(ORGANIZATION_ID);

    const {organizationList: knownOrganizations, fetchSimplyOrganizations} = useContext(OrganizationSimpleContext)

    let isChanged = true;
    const dispatch = useDispatch();

    // Local state
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
        fetchSimplyOrganizations()
    };

    const onClose = () => {
        setVisible(false);

        if (localStorage.getItem(SHOW_ARCHIVED) !== showArchiveData?.toString()) {
            localStorage.setItem(SHOW_ARCHIVED, showArchiveData?.toString())
            window.location.reload();
        }
    };

    const fetchData = () => {
        if(knownVehicles)
        userApi.getCurrentUser().then((user) => {
            const orgDisplayName = user?.organizations?.find(({organizationId}) => organizationId === user.organizationId)
            user.orgDisplayName = orgDisplayName?.fullName
            user.permittedVehicles = user?.permittedVehicles.map((vehicle) =>
                knownVehicles?.find((item) => item.id === vehicle.id)
            )
            setCurrentUserData(user);
            localStorage.setItem(MAP_TYPE, user?.mapType);
            localStorage.setItem(USER_ID, user?.userId);
            localStorage.setItem(USER_ORG_ID, user?.organizationId);

            let isRoot = JSON.stringify(user?.roles)?.includes("ROOT");

            if (!isRoot && !getOrganizationFromLS) {
                localStorage.setItem(ORGANIZATION_ID, user?.organizationId);
            }
        });
    };

    useEffect(() => {
        setLoading(false);

        if (currentUser?.hasApprovedGdpr === false) {
            setShowGDPR(true);
        }

    }, [currentUser]);

    console.log(        currentUser)
    useEffect(() => {
        if (knownVehicles) {
            fetchData()
        }
    }, [knownVehicles]);

    useEffect(() => {

        setShowArchiveData(JSON.parse(localStorage.getItem(SHOW_ARCHIVED) || false) || false)

        if (loading) {
            fetchData();
        }
    }, []);

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 24},
    };
    const tailLayout = {
        wrapperCol: {offset: 12, span: 24},
    };

    const changePassword = (values) => {
        let data = currentUser;
        data.oldPassword = values.old_password;
        data.password = values.new_password;

        userApi.patchNewPassword(currentUser?.userId, data);
    };

    const checkInDriver = (val, vehicleId) => {
        const data = {
            userId: val ? currentUser?.userId : null,
        };

        dataApi.putCheckCurrentDriver(vehicleId, data).then((r) => {
            message.success("Du är incheckad");
            isChanged = !isChanged;
            fetchData();
        });
    };

    function onChangeMapType(e) {
        const data = {mapType: e.target.value};
        dataApi.patchUser(currentUser?.userId, data).then((_) => {
            localStorage.setItem(MAP_TYPE, e.target.value);
            fetchData();
        });
    }

    const Demo = ({getOrganizationFromLS}) => {
        const [checked, setChecked] = useState();
        const organization = knownOrganizations.filter(
            (i) => i.organization_id === (getOrganizationFromLS ? parseInt(getOrganizationFromLS) : knownOrganizations[knownOrganizations.length - 1].organizationId)
        )[0]; // last org in array is users direct org
        const [form] = Form.useForm();

        const onFinish = (values) => {
            changePassword(values);
            form.resetFields();
        };

        const onFinishFailed = (errorInfo) => {
            form.resetFields();
        };

        const handleSelectOrganization = (e) => {
            if(e === undefined){
                localStorage.removeItem(ORGANIZATION_ID);
            }else {
                localStorage.setItem(ORGANIZATION_ID, e);
            }
            window.location.reload();
        };


        function onChangeArchivedData(value) {
            console.log('localStorage.getItem(SHOW_ARCHIVED)', localStorage.getItem(SHOW_ARCHIVED), value)
            setShowArchiveData(value)
        }

        return loading || !organization || !currentUser ? <Loading/> : (
            <Form {...layout} form={form} name="change" initialValues={{remember: false}} layout="vertical"
                  onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Row gutter={[0, 0]}>
                    <Col>
                        <Title level={3}>
                            {currentUser.displayName}
                            {getOrganizationFromLS ? <Text type="secondary"> ({organization?.full_name})</Text> : null}
                        </Title>
                    </Col>
                    <Divider/>
                </Row>

                {knownOrganizations.length < 2 ? null : (
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Title level={3}>Organisation</Title>
                            <OrganizationsTreeSelect
                                thisIsSettingPanel={true}
                                width={300}
                                data={knownOrganizations}
                                defaultValue={getOrganizationFromLS ? parseInt(getOrganizationFromLS) : null}
                                onChange={handleSelectOrganization}
                            />
                        </Col>
                        <Divider/>
                    </Row>
                )}

                <Row gutter={[0, 0]}>
                    <Col>
                        <Title level={3}>Mina fordon</Title>
                    </Col>
                </Row>

                <Row gutter={[0, 10]}>
                    <Col span={24}>
                        <Title level={4}>Förvald förare</Title>

                        {currentUser.defaultVehicles?.length > 0
                            ? currentUser.defaultVehicles?.map((v) => {
                                const vehicle = knownVehicles?.find((i) => i.id === v);
                                let enabledState = vehicle?.currentDriver?.id === currentUser.userId;

                                return !vehicle ? null : (
                                    <Flex justifyContent={"between"} alignItems={"center"}>
                                        <Flex flexDirection={"column"} justifyContent={"between"}  style={{ flexBasis: '60%', padding: "8px 0px"}}>
                                            <Text>{vehicle?.registry || "-"}</Text>
                                            {vehicle?.displayName}
                                        </Flex>
                                    </Flex>
                                );
                            })
                            : "Du har inte valt något fordon"}
                    </Col>
                </Row>

                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Title level={4}>Incheckad</Title>
                        {currentUser.permittedVehicles?.length < 1
                            ? "Du har inte valt något fordon"
                            : currentUser.permittedVehicles?.filter(vehicle => !vehicle?.isArchived)?.map((vehicle) => {
                                let enabledState = currentUser?.currentVehicle?.find((c) => c === vehicle?.id);


                                return !vehicle ? null : (
                                    <Flex justifyContent={"between"} alignItems={"center"}>
                                        <Flex flexDirection={"column"} justifyContent={"between"}  style={{ flexBasis: '60%', padding: "8px 0px"}}>
                                            <Text>{vehicle?.registry || "-"}</Text>
                                            {vehicle?.displayName}
                                        </Flex>
                                        <Switch
                                            checkedChildren="Incheckad"
                                            unCheckedChildren="Utcheckad"
                                            defaultChecked={enabledState}
                                            onChange={(val) => {
                                                checkInDriver(val, vehicle?.id);
                                                setChecked(val);
                                                enabledState = !enabledState;
                                            }}
                                        />

                                    </Flex>
                                );
                            })}
                    </Col>
                    <Divider/>
                </Row>

                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Title level={3}>Anpassa</Title>
                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <Flex flexDirection={"column"} justifyContent={"between"}  style={{ flexBasis: '60%', padding: "12px 0px"}}>
                                <Title level={4}>Karta</Title>
                                Standardtyp av karta.
                            </Flex>
                            <Radio.Group onChange={onChangeMapType} defaultValue={currentUser?.mapType}
                                         size="small">
                                <Radio.Button
                                    value={SUPER_DEVICES_KEYS_AND_INDEXES.LIGHT}>{STATIC_TEXT.LIGHT}</Radio.Button>
                                <Radio.Button
                                    value={SUPER_DEVICES_KEYS_AND_INDEXES.DARK}>{STATIC_TEXT.DARK}</Radio.Button>
                            </Radio.Group>

                        </Flex>

                        <Flex justifyContent={"between"} alignItems={"center"}>
                            <Flex flexDirection={"column"} justifyContent={"between"}  style={{ flexBasis: '60%', padding: "12px 0px"}}>
                                <Title level={4}>Arkiverade</Title>
                                {STATIC_TEXT.SHOW_ARCHIVED}
                            </Flex>
                            <Switch
                                defaultChecked={showArchiveData}
                                onChange={(value) => {
                                    onChangeArchivedData(value)
                                }}
                            />

                        </Flex>

                        {JSON.stringify(currentUser?.roles)?.includes("ROOT") &&
                            <Flex justifyContent={"between"} alignItems={"center"}>
                                <Flex flexDirection={"column"} justifyContent={"between"}  style={{ flexBasis: '60%', padding: "12px 0px"}}>
                                    <Title level={4}>Meny</Title>
                                    Ändra mellan två menytyp. Detta är en test för en ny meny.
                                </Flex>

                                <Switch defaultChecked={localStorage.getItem("MENU_TYPE" === "true")}
                                        onChange={(v) => localStorage.setItem("MENU_TYPE", v)}/>

                            </Flex>}


                        <Divider/>
                    </Col>
                </Row>

                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Title level={3}>Ändra lösenord</Title>
                        <Form.Item label="Nuvarande" name="old_password"
                                   rules={[{required: true, message: "Ange nuvarande lösenord"}]}>
                            <Input.Password prefix={<icons.LockOutlined style={{marginRight: 3}}/>}
                                            placeholder={STATIC_TEXT.SUPPLY_A_OLD_PASSWORD}
                                            autocomplete="new-password"/>
                        </Form.Item>

                        <Form.Item label="Nytt" name="new_password"
                                   rules={[{required: true, message: "Ange nytt lösenord"}]}>
                            <Input.Password prefix={<icons.LockOutlined style={{marginRight: 3}}/>}
                                            placeholder={STATIC_TEXT.SUPPLY_A_NEW_PASSWORD} autocomplete="off"/>
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Ändra lösenord
                            </Button>
                        </Form.Item>
                    </Col>
                    <Divider/>
                </Row>
            </Form>
        );
    };

    const getInitials = (name) => {
        const names = name?.split(' ');
        return names?.map(n => n[0]).join('').toUpperCase();
    };
    let initials = getInitials(currentUser?.displayName)

    return (
        <Nest
            wrappers={[VehicleProvider]}>
        <>
            <Menu mode="horizontal">
                <Menu.Item style={{lineHeight: 1.5, height: "100%"}} onClick={showDrawer}>
                    <Flex style={{height: "100%", textAlign: "right"}} flexDirection={"column"} className="pl-3"
                          justifyContent={"center"}>
                        <h4 className="mb-0">{currentUser?.displayName}</h4>
                        <span className="text-muted">{currentUser?.orgDisplayName}</span>
                    </Flex>
                </Menu.Item>
                <Menu.Item onClick={showDrawer}>
                    <SettingOutlined className="nav-icon mr-0" />
                </Menu.Item>
            </Menu>
            <Drawer title="Mitt konto" placement="right" width={350} onClose={onClose} visible={visible}>
                <Demo getOrganizationFromLS={getOrganizationFromLS}/>

                {/*TODO: Render if priv = superadmin*/}
                {/*
                <Button className="mt-3" type="secondary" block onClick={() => dispatch({type: 'RESET', payload: "reset-please"})}>
                    <span>DEBUG: Reset state</span>
                </Button>
                */}

                <Button
                    className="mt-3"
                    type="secondary"
                    block
                    onClick={() => {
                        props.signOut();
                    }}
                >
                    <span>Logga ut</span>
                </Button>
                <p style={{display: "none"}}>Version local storage: {localStorage.getItem('appVersion')}</p>
            </Drawer>
        </>
        </Nest>
    );
};

const SettingsPanelWrapped = forwardRef((props, ref) => {
    return (
        <Nest wrappers={[VehicleProvider, OrganizationSimpleProvider]}>
            <SettingsPanel ref={ref} {...props} />
        </Nest>
    );
});

export default connect(
    () => {
        return {};
    },
    {signOut}
)(SettingsPanelWrapped);

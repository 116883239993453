import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, Typography, Divider, Collapse, Tabs, Space } from "antd";
import styles from "./styles";
import { OrganizationContext } from "services/contexts/OrganizationsContext";
const { Title } = Typography;
const AccountEditModal = ({ show, org, onCancel = () => null }) => {
    const { patchOrganizationAccount } = useContext(OrganizationContext);
    const [form] = Form.useForm();
    const [isPostAddress, setIsPostAddress] = useState(false);
    const [userOrg, setUserOrg] = useState();
    useEffect(() => {
        if (org !== undefined) {
            form.setFieldsValue({
                postalStreet: org.postalAddress.streetAndNum,
                postalCity: org.postalAddress.city,
                postalCountry: org.postalAddress.country,
                postalState: org.postalAddress.state,
                postalZip: org.postalAddress.zip,
                invoiceStreet: org.invoiceAddress.streetAndNum,
                invoiceCity: org.invoiceAddress.city,
                invoiceCountry: org.invoiceAddress.country,
                invoiceState: org.invoiceAddress.state,
                invoiceZip: org.invoiceAddress.zip,
                phone: org.phone,
            });
            setUserOrg(org);
        } else {
            form.setFieldsValue({
                postalStreet: "",
                postalCity: "",
                postalCountry: "",
                postalState: "",
                postalZip: "",
                invoiceStreet: "",
                invoiceCity: "",
                invoiceCountry: "",
                invoiceState: "",
                invoiceZip: "",
                phone: "",
            });
        }
    });
    const onClickSave = async (v) => {
        const updateObj = {};
        if (isPostAddress === true) {
            updateObj["postalAddress"] = {
                streetAndNum: v.postalStreet,
                zip: v.postalZip,
                city: v.postalCity,
                country: v.postalCountry,
                state: v.postalState,
            };
            updateObj["phone"] = v.phone;
        } else {
            updateObj["invoiceAddress"] = {
                streetAndNum: v.invoiceStreet,
                zip: v.invoiceZip,
                city: v.invoiceCity,
                country: v.invoiceCountry,
                state: v.invoiceState,
            };
            updateObj["phone"] = v.phone;
        }
        const res = await patchOrganizationAccount(org.organizationId, updateObj);
        if (res === true) {
            window.location.reload();
        }
    };
    const editInvoiceForm = () => {
        return (
            <>
                <Form.Item
                    name="invoiceStreet"
                    label="Gata"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en post address",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoiceCity"
                    label="Stad"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en stad",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoiceState"
                    label="Stat"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en stat",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoiceCountry"
                    label="Land"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en land",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="invoiceZip"
                    label="Postkod"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en postkod",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </>
        );
    };
    const editPostForm = () => {
        return (
            <>
                <Form.Item
                    name="postalStreet"
                    label="Gata"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en post address",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="postalCity"
                    label="Stad"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en stad",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="postalState"
                    label="Stat"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en stat",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="postalCountry"
                    label="Land"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en land",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="postalZip"
                    label="Postkod"
                    rules={[
                        {
                            required: true,
                            message: "Lägg en postkod",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </>
        );
    };
    const tabChange = (e) => {
        if (Number(e) === 1) {
            setIsPostAddress(false);
        }
        if (Number(e) === 2) {
            setIsPostAddress(true);
        }
    };
    return (
        <Modal
            visible={show}
            title="Redigera Konto"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then((values) => {
                        onClickSave(values);
                    })
                    .catch((info) => console.log(`validate Failed:`, info));
            }}
            bodyStyle={styles.body}
        >
            <Tabs defaultActiveKey="1" onChange={(e) => tabChange(e)}>
                <Tabs.TabPane tab="Faktura Address" key={1}>
                    <Form form={form} layout="vertical" name="editUserOrg">
                        {editInvoiceForm()}
                    </Form>
                </Tabs.TabPane>
                <Tabs.TabPane tab="PostAdress" key={2}>
                    <Form form={form} layout="vertical" name="editUserOrg">
                        {editPostForm()}
                    </Form>
                </Tabs.TabPane>
            </Tabs>
            <Divider />
            <Form form={form} layout="vertical" name="editPhone">
                <Form.Item name="phone" label="Telefonnummer">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AccountEditModal;

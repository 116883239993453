// import/no-webpack-loader-syntax
import * as A from 'antd';
import {Button} from 'antd';
import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState
} from 'react'

import {useLocation} from 'react-router-dom';
import {GoogleMapProvider} from "./map-context"
import {
    TaxContext,
    TaxProvider,
    TripTaxContext,
    TripTaxProvider
} from "../../../services/contexts/tax-context"
import {KNOWN_LOCATION} from "../../../geometry.js"
import {AUTH_TOKEN} from "../../../services/redux/constants/authConstants"
import {ConfigureStations} from "./configure-stations"
import {GoogleTripPlotter} from "./triptax-map"


console.debug("KNOWN LOCATIONS", KNOWN_LOCATION)

const xhrp = (method, url, data = undefined) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    console.debug("Dispatching request", method, url, " with JWT = ", jwtToken)
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
            resolve(xhr)
        }
        xhr.onerror = () => {
            reject()
        }
        console.debug("Calling XHR open")
        xhr.open(method, url)
        if (method !== 'OPTIONS') {
            console.debug("Adding request header")
            xhr.setRequestHeader("Authorization", "JWT " + jwtToken)
        } else {
            console.debug("Ignoring request header")
        }
        console.debug("Calling XHR send")
        xhr.send(data)
    })
}


const PurgeTripButton = ({tripId}) => {
    const ttp = useContext(TripTaxContext)
    const [loading, setLoading] = useState(false)
    return <Button enabled={!!tripId} loading={ loading } onClick={ () => ttp.purgeTrip(tripId)}>Refresh</Button>
}

// const mapboxgl = require('mapbox-gl')

export const SuperTriptaxMainPage = ({debugMapBox = false}) => {
    // Zoom is a temporary thing to placate GoogleMapReact
    const _ = () => {
        const tc = useContext(TaxContext)

        const getSelectedTrip = (loc) => {
            const pathComponents = loc.pathname.split('/')
            const finalPath = pathComponents[pathComponents.length - 1]
            let selectedTrip = parseInt(finalPath)
            if(isNaN(selectedTrip))
                selectedTrip = null
            return selectedTrip
        }

        const setTripFromForm = (e) => {
            console.log(e.target)
            const newTrip = parseInt(e.target.getElementsByClassName("tripId")[0]?.value || 0)
            console.log('Picked new trip: ', newTrip)
            if(newTrip) {
                window.location.href = "/app/triptax-analyzer/" + newTrip
            }
        }

        const checkRef = useRef()

        let __version__ = 2
        let reducerState = null
        const reducerCache = localStorage.getItem('triptax-analyzer.displayState')
        try {
            reducerState = JSON.parse(reducerCache)
            if (!reducerState || reducerState.__version__ < __version__) {
                // same error as returned from JSON
                //noinspection ExceptionCaughtLocallyJS
                throw new SyntaxError("Breaking cache")
            }
        } catch {
            localStorage.removeItem('triptax-analyzer.displayState')
        }

        const [displayState, dd] = useReducer((s, a) => {
            return a(s)
        }, reducerState || {
            tolls: true,
            references: true,
            resampledTrip: true,
            interpTrip: true,
            originalTrip: true,
            allStations: true
        }, undefined)

        const toggleBox = () => {
            const newState = displayState.showAllCoords
            console.log(newState)
            dd(s => { return {...s, showAllCoords: newState} })
        }

        useEffect(() => {
            localStorage.setItem('triptax-analyzer.displayState', JSON.stringify({
                ...displayState,
                __version__
            }))
        }, [displayState])

        const DisplayToggle = ({text, attr}) => {
            return <A.Checkbox checked={displayState[attr]}
                               onChange={() => dd(s => {
                                   return {...s,
                                       [attr]: !s[attr]
                                   }
                               })}>
                {text}
            </A.Checkbox>
        }

        const selectedTrip = getSelectedTrip(useLocation())
        return <div>
            <div style={{height: '75vh', width: '100%'}}>
                <input type="checkbox"
                       name="show-coords"
                       id="show-coords"
                       tabIndex={2}
                       defaultChecked={displayState.showAllCoords}
                       ref={checkRef}
                       onChange={(e) => {
                           toggleBox()
                       }}/>
                <label htmlFor="show-coords">Show coords</label>

                <A.Checkbox
                    checked={displayState.tolls && displayState.references}
                    onChange={() => dd(s => {
                        return {
                            ...s,
                            tolls: !s.tolls,
                            references: !s.references
                        }
                    })}>
                    Show/hide stations
                </A.Checkbox>

                <DisplayToggle text={"Show/hide original path"}
                               attr={"originalTrip"}/>
                <DisplayToggle text={"Show/hide coordinate selection"}
                               attr={"resampledTrip"}/>
                <DisplayToggle text={"Show/hide smoothed path"}
                               attr={"interpTrip"}/>
                <DisplayToggle text={"Show/hide all stations"}
                               attr={"allStations"}/>

                <form onSubmit={(e) => {
                    e.preventDefault()
                    setTripFromForm(e);
                    return false;
                }}>
                    <input tabIndex={1} name="tripId" className="tripId"/>
                    <input tabIndex={3} type="submit" value="ok"/>
                </form>

                <Button onClick={() => tc.reload()}>Refresh meta</Button>
                <TripTaxProvider track={selectedTrip}>
                    <PurgeTripButton tripId={selectedTrip}/>
                    <GoogleMapProvider>
                        <GoogleTripPlotter displayState={ displayState } />
                    </GoogleMapProvider>
                </TripTaxProvider>
            </div>
        </div>
    }


    if (debugMapBox) {
        return <TaxProvider><ConfigureStations /></TaxProvider>
    } else {
        return <TaxProvider><_/></TaxProvider>
    }
}

export default SuperTriptaxMainPage

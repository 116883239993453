import React, {useEffect, useState} from 'react'
import {Checkbox, Form, Input, Modal, Skeleton, Table, Tabs, Tree, Typography} from 'antd';
import * as icons from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {LastSeenLink} from "../shared-components/DatumLink";
import {getOrgTreeDataWithChildren} from "../Superdevices/superDeviceHelpers";
import {OrganizationsTreeSelect} from "../shared-components/OrganizationsTreeSelect";
import {APP_PREFIX_PATH} from "../../../configs/AppConfig";
import {SEARCH_TERM} from "../../../services/redux/constants/authConstants";
import {AddressForm} from "./OrganizationsTable";
import {STATIC_TEXT} from "../../../lang/translate/sv-SE/sv-SE";
import {SUPER_DEVICES_KEYS_AND_INDEXES} from "../../../constants/CustomConstant";
import {dataApi} from "../../../services/api/api";
import {SettingsForm} from "../shared-components/settingsForm";

const {TabPane} = Tabs;
export const CollectionCreateForm = ({visible, onModalFinish, onCancel, organizations, ...props}) => {
    const [form] = Form.useForm();
    const [record, setRecord] = useState()
    const [loading, setLoading] = useState(true)
    const [showAdminsPassword, setShowAdminsPassword] = useState(true)
    const [tree, setTree] = useState([])
    const [switchStates, setSwitchStates] = useState({});

    useEffect(() => {
        if (tree?.length < 2) {
            let getTreeData = Object.assign(organizations)
            setTree(getTreeData)
        }
    }, [organizations])


    useEffect(() => {
        if (!visible || (!props?.record && !props.isNewRecordForm)) {
            setRecord(null)
            setShowAdminsPassword(true)
            setSwitchStates({})
            props.setInitialSwitchStates({})
            form.resetFields()
            setLoading(true)
            return;
        }

        if(props.isNewRecordForm){
            setLoading(false)
            return;
        }

        dataApi.getAdminOrganization(props.record?.organizationId)
                .then(res => {
                    setRecord(res)
                    setShowAdminsPassword(true)
                    form.resetFields()
                    form.setFieldsValue({...res});
                    setLoading(false)
                })

    }, [visible, props.record])

    return (
        <Modal
            visible={visible}
            style={{minWidth: 800}}
            title={record ? record.fullName : STATIC_TEXT.ADD_NEW_ORG}
            okText={record ? STATIC_TEXT.BTN_SAVE : STATIC_TEXT.ADD}
            cancelText={STATIC_TEXT.BTN_ABORT}
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        form.resetFields();
                        onModalFinish(values, switchStates);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Skeleton loading={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={record}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={STATIC_TEXT.ORG} key="1">
                            <Form.Item
                                name={SUPER_DEVICES_KEYS_AND_INDEXES.fullname}
                                label={STATIC_TEXT.ORG_NAME}
                                rules={[
                                    {
                                        required: true,
                                        message: STATIC_TEXT.SUPPLY_A_NEW_BUSINESS_NAME,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<icons.TeamOutlined style={{marginRight: 3}}/>}
                                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_BUSINESS_NAME}
                                />
                            </Form.Item>
                            <Form.Item
                                name={SUPER_DEVICES_KEYS_AND_INDEXES.parentOrganization}
                                label={STATIC_TEXT.PARENT}
                                rules={[
                                    {
                                        required: false,
                                        message: `Ange Företagsnamn`,
                                    },
                                ]}
                            >
                                <OrganizationsTreeSelect data={organizations}
                                                         defaultValue={record?.parentOrganization}/>
                            </Form.Item>
                            <Form.Item
                                name={SUPER_DEVICES_KEYS_AND_INDEXES.organizationNumber}
                                label={STATIC_TEXT.ORG_NUM}
                                rules={[
                                    {
                                        required: false,
                                        message: `Ange Organisationsnummer`,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<icons.NumberOutlined style={{marginRight: 3}}/>}
                                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG}
                                />
                            </Form.Item>
                            <Form.Item
                                name={SUPER_DEVICES_KEYS_AND_INDEXES.email}
                                label={STATIC_TEXT.MAIL}
                                rules={[
                                    {
                                        required: false,
                                        message: `Ange E-postadress`,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<icons.MailOutlined style={{marginRight: 3}}/>}
                                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_EMAIL}
                                />
                            </Form.Item>
                            <Form.Item
                                name={SUPER_DEVICES_KEYS_AND_INDEXES.phone}
                                label={STATIC_TEXT.PHONE_NUMBER}
                                rules={[
                                    {
                                        required: false,
                                        message: `Ange Telefonnummer`,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<icons.PhoneOutlined style={{marginRight: 3}}/>}
                                    placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_PHONE}
                                />
                            </Form.Item>
                            {record ? null :
                                <Form.Item
                                    name={SUPER_DEVICES_KEYS_AND_INDEXES.adminUserEmail}
                                    label={STATIC_TEXT.SUPPLY_ADMINS_EMAIL}
                                    rules={[
                                        {
                                            required: false,
                                            message: `Ange adminUserEmail`,
                                            autoComplete: false
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<icons.MailOutlined style={{marginRight: 3}}/>}
                                        placeholder={STATIC_TEXT.SUPPLY_AN_EMAIL_ADDRESS}

                                        autoComplete="new-password"/>
                                </Form.Item>}

                            {record ? null :
                                <Form.Item
                                    name={"adminUserFullName"}
                                    label={STATIC_TEXT.SUPPLY_ADMINS_NAME}
                                    rules={[
                                        {
                                            required: false,
                                            message: `Ange företagsadmins namn`,
                                            autoComplete: false
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<icons.UserOutlined style={{marginRight: 3}}/>}
                                        placeholder={STATIC_TEXT.SUPPLY_ADMINS_NAME}
                                        autoComplete="new-admins-name"/>
                                </Form.Item>}

                            {record ? null :
                                <Form.Item name={SUPER_DEVICES_KEYS_AND_INDEXES.sendInvite} valuePropName="checked">
                                    <Checkbox checked={!showAdminsPassword}
                                              onChange={() => setShowAdminsPassword(!showAdminsPassword)}>{STATIC_TEXT.SEND_INVITE}</Checkbox>
                                </Form.Item>}

                            {showAdminsPassword && !record ?
                                <Form.Item
                                    name={SUPER_DEVICES_KEYS_AND_INDEXES.adminUserPassword}
                                    label={STATIC_TEXT.ADMINS_PASSWORD}
                                    rules={[
                                        {
                                            required: false,
                                            message: `Ange adminUserPassword`,
                                            autoComplete: false
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<icons.LockOutlined style={{marginRight: 3}}/>}

                                        placeholder={STATIC_TEXT.SUPPLY_A_NEW_ORG_PASSWORD}
                                        autoComplete="new-password"/>
                                </Form.Item>
                                : null}
                        </TabPane>

                        <TabPane tab={STATIC_TEXT.POSTAL_ADDRESS} key="2">
                            <AddressForm typ={SUPER_DEVICES_KEYS_AND_INDEXES.postalAddress}/>
                        </TabPane>

                        <TabPane tab={STATIC_TEXT.INVOICE_ADDRESS} key="3">
                            <AddressForm typ={SUPER_DEVICES_KEYS_AND_INDEXES.invoiceAddress}/>
                        </TabPane>

                        {record && <TabPane tab="Inställningar" key="4">
                            <SettingsForm record={record}
                                          initialSwitchStates={props.initialSwitchStates}
                                          setInitialSwitchStates={props.setInitialSwitchStates}
                                          switchStates={switchStates} setSwitchStates={setSwitchStates}/>
                        </TabPane>
                        }
                        {!record ? null :
                            <TabPane tab={STATIC_TEXT.VEHICLE} key="5">
                                <VehiclesTabPane record={record} knownVehicles={props.knownVehicles}/>
                            </TabPane>}

                        {!record ? null :
                            <TabPane tab={STATIC_TEXT.USERS} key="6">
                                <UsersTabPane record={record} knownUsers={props.knownUsers}
                                              knownVehicles={props.knownVehicles}/>
                            </TabPane>}

                        {!record ? null :
                            <TabPane tab={STATIC_TEXT.DEVICES} key="7">
                                <DevicesTabPane record={record} knownDevices={props.knownDevices}/>
                            </TabPane>}

                        <TabPane tab={STATIC_TEXT.ORGANIZATIONS} key="8">
                            <OrganizationalHierarchyTabPane record={record} knownOrganizations={organizations}/>
                        </TabPane>
                    </Tabs>
                </Form>
            </Skeleton>
        </Modal>

    );
};
const VehiclesTabPane = ({knownVehicles, record}) => {
    const {push} = useHistory();
    const vehicles = knownVehicles?.filter(v => v?.organizationId === record?.organizationId)

    function handleShowVehicleClick(value) {
        localStorage.setItem(SEARCH_TERM, value)
        push({
            pathname: `${APP_PREFIX_PATH}/superVehicles`,
            state: {searchTerm: value}
        })
    }

    const columns = [
        {
            title: STATIC_TEXT.REG_NUM,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.registry,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.registry,
            render: (text, record) => <a
                onClick={() => handleShowVehicleClick(text ? text : record.displayName)}>{text} {record?.displayName}</a>,
        },
        {
            title: STATIC_TEXT.DRIVER,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.currentDriver,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.currentDriver,
            render: (text) => <text>{text?.displayName}</text>,
        },
        {
            title: STATIC_TEXT.TRAVELS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.address,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.address,
        },
        {
            title: STATIC_TEXT.MILEAGE_FULL,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.odometer,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.odometer,
            render: (text, record) => record?.odometer?.valueKm ? <text>{record?.odometer?.valueKm} km</text> : "-",
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>{record?.fullName} - {STATIC_TEXT.VEHICLE}</Typography.Title>
            <br/>
            <Table size="small" columns={columns} dataSource={vehicles}/>
        </div>
    )
}
const UsersTabPane = ({knownUsers, knownVehicles, record}) => {
    const {push} = useHistory();

    const users = knownUsers?.filter(v => v?.organizationId === record?.organizationId)

    function handleShowUserClick(value) {
        localStorage.setItem(SEARCH_TERM, value)
        push({
            pathname: `${APP_PREFIX_PATH}/users`,
            state: {searchTerm: value}
        })

    }

    const columns = [
        {
            title: STATIC_TEXT.USERS,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.displayName,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.displayName,
            render: (text, record) => <a onClick={() => handleShowUserClick(record.displayName)}>{text}</a>,
        },
        {
            title: STATIC_TEXT.MAIL,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.displayEmail,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.displayEmail,
            render: (text) => <text>{text}</text>,
        },
        {
            title: STATIC_TEXT.PHONE_NUMBER,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
        },
        {
            title: STATIC_TEXT.LAST_SEEN_DRIVING,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.lastSeenDriving,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.lastSeenDriving,
            render: (text) => text ? <LastSeenLink value={text} typ={"map"}/> : "-",
        },
        {
            title: STATIC_TEXT.LAST_SEEN,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.lastSeenApp,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.lastSeenApp,
            render: (text) => text ? <LastSeenLink value={text} typ={"map"}/> : "-",
        },
        {
            title: STATIC_TEXT.CHECKED,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.currentVehicle,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.currentVehicle,
            render: (text) => {
                const vehicle = knownVehicles?.find(v => v.id === text[0])
                const reg = vehicle?.registry || ''
                const name = vehicle?.displayName ? " (" + vehicle?.displayName + ")" : ''
                return reg + name
            },
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>{record?.fullName} - {STATIC_TEXT.USERS}</Typography.Title>
            <br/>
            <Table
                scroll={{x: 300}}
                size="small"
                columns={columns}
                dataSource={users}/>
        </div>
    )

}
const x = 3;
const y = 2;
export const z = 1;
export const gData = [];
export const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || gData;

    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({title: key, key});
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};
const dataList = [];
export const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const {key} = node;
        dataList.push({key, title: key});
        if (node.children) {
            generateList(node.children);
        }
    }
};
const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some(item => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};
const OrganizationalHierarchyTabPane = ({knownOrganizations, record}) => {

    const treeData = getOrgTreeDataWithChildren(knownOrganizations, record?.organizationId)
    const [expandedRow, setExpandedRow] = useState([record?.organizationId]);

    useEffect(() => {
        setExpandedRow([record?.organizationId])
    }, [record])

    return (
        <div>
            <Tree
                defaultExpandAll
                selectedKeys={expandedRow}
                onSelect={(value) => setExpandedRow(value)}
                treeData={treeData}
            />
        </div>
    );
};
const DevicesTabPane = ({knownDevices, record}) => {
    const {push} = useHistory();
    const devices = knownDevices?.filter(v => v?.organizationId === record?.organizationId)

    function handleShowUserClick(value) {
        localStorage.setItem(SEARCH_TERM, value)
        push({
            pathname: `${APP_PREFIX_PATH}/superdevices`,
            state: {searchTerm: value}
        })
    }

    const columns = [
        {
            title: STATIC_TEXT.IMEI,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.imei,
            render: (text, record) => <a onClick={() => handleShowUserClick(record.deviceId)}>{text}</a>,
        },
        {
            title: STATIC_TEXT.PHONE_NUMBER,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.phone,
        },
        {
            title: STATIC_TEXT.BATTERY,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.status,
            render: (text) => text?.latestBatteryLevel ? <text>{text?.latestBatteryLevel} %</text> : "-",
        },
        {
            title: STATIC_TEXT.INSTALL,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.physicalInstallTimestamp,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.physicalInstallTimestamp,
            render: (text) => text ? <LastSeenLink value={text} typ={"map"}/> : "-",
        },
        {
            title: STATIC_TEXT.RETIRED,
            dataIndex: SUPER_DEVICES_KEYS_AND_INDEXES.retired,
            key: SUPER_DEVICES_KEYS_AND_INDEXES.retired,
            render: (text) => <text>{text ? STATIC_TEXT.YES : STATIC_TEXT.NO}</text>,
        }
    ];

    return (
        <div>
            <Typography.Title level={2}>{record?.fullName} - {STATIC_TEXT.DEVICES}</Typography.Title>
            <br/>
            <Table
                scroll={{x: 300}}
                size="small"
                columns={columns}
                dataSource={devices}/>
        </div>
    )

}
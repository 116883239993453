const styles = {
    appContent: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap"
    },
    contentHeader: {
        display: "flex",
        marginBottom: 20,
        gap: 15,
        flexWrap: "wrap"
    },
    mapContent: {
        height: "50vh",
        width: "100%",
    },
    mapRow: {
        height: "100%",
    },
};

export default styles;

import React, { createContext, useState, useEffect } from "react";
import { dataApi } from "services/api/api";
const DevicesContext = createContext({});
const DevicesContextProvider = ({ children }) => {
    const [devices, setDevices] = useState([]);
    const [devicesLoaded, setDevicesLoaded] = useState(false);
    const fetchDevices = async () => {
        dataApi
            .getDevices()
            .then((res) => {
                setDevices(res);
                setDevicesLoaded(true)
            })
            .catch((err) => console.error(err));
    };
    useEffect(() => {
        fetchDevices();
    }, []);
    return <DevicesContext.Provider value={{ devices, devicesLoaded }}>{children}</DevicesContext.Provider>;
};

export { DevicesContext, DevicesContextProvider };

import React, {useContext, useEffect, useState, createContext} from 'react'
import {dataApi} from "../api/api"
import moment from 'moment'
import {InitialDateAttest, OrganizationsComplexListContext} from "./contexts";

 const EconomyReportContext = createContext(null)
 const EconomyReportProvider = (props) => {
    const [data, setData] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [organizationId, setOrganizationId] = useState([])
    const [searchTerm, setSearchTerm] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState()
    const [timestamp, setTimestamp] = useState()
    const [showModal, setShowModal] = useState(false)
    const [selectedDriverReport, setSelectedDriverReport] = useState(false)
    const [organizationData, setOrganizationData] = useState()
    const [loading, setLoading] = useState(true)
     let dateFormat = "YYYY/MM";

    const initialPeriod = useContext(InitialDateAttest.context)
    const organizations = useContext(OrganizationsComplexListContext)

    useEffect(() => {
        if (!initialPeriod) return

        setSelectedPeriod(initialPeriod)
        onChangeDatePicker(initialPeriod, initialPeriod)
    }, [initialPeriod])

    useEffect(() => {
        if (!organizations) return

        setOrganizationData(organizations.sort((a, b) => a.organizationId > b.organizationId ? 1 : -1))
    }, [organizations])


    useEffect(() => {
        if (!selectedPeriod) return

        setLoading(true)
        const splitPeriod = selectedPeriod.split('/')

        dataApi.getReportDriver(splitPeriod[1], splitPeriod[0])
            .then(res => {
                setData(res)
                setLoading(false)
            })
    }, [selectedPeriod])


    useEffect(() => {
        if (!data) return

        let newData = data
        let newDataOrganizations = organizations

        if (searchTerm.length > 1)
            newData = newData?.filter(
                item => JSON.stringify(item).toLowerCase().includes(searchTerm)
            );

        if (organizationId?.length > 0) {
            newDataOrganizations = newDataOrganizations?.filter(
                i => organizationId?.includes(i.organizationId))
        }

        setOrganizationData(newDataOrganizations)
        setFilteredData(newData)
    }, [searchTerm, organizationId, data])

    const organizationSelectOnChange = value => {
        setOrganizationId(value)
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleChangeShowModal = (driverReport) => {
        setShowModal(!showModal);
        setSelectedDriverReport(driverReport)
    };

    const updateObject = (personId, vehicleId, row) => {
        const newData = [...data];
        const index = newData.findIndex(item => personId === item.personId && vehicleId === item.vehicle.vehicleId);
        const item = newData[index];

        newData.splice(index, 1, {...item, ...row});
        setData(newData);
    }

    const handleUnattestDriver = (userId) => {
        const splitPeriod = selectedPeriod?.split('/')
        const previousMonth = splitPeriod[1]
        const year = splitPeriod[0]

        dataApi.patchAttestUserUnlock(userId, previousMonth, year)
            .then(_ => {
                const newData = [...data]
                let index = data?.findIndex(e => e.personId === userId);
                let item = data[index];
                item = {...item, attested: false}
                newData.splice(index, 1, {...item});
                setData(newData)
            })

    }

    const onChangeDatePicker = (date, dateString) => {
        const startOfMonth = moment(date, dateFormat).startOf('month').unix();
        const endOfMonth = moment(date, dateFormat).endOf('month').unix();

        setSelectedPeriod(dateString)
        setTimestamp([startOfMonth, endOfMonth])

    }

    return <EconomyReportContext.Provider value={{
        filteredData,
        handleChange,
        setOrganizationId,
        organizationId,
        setSearchTerm,
        timestamp,
        selectedDriverReport,
        organizationData,
        handleChangeShowModal,
        selectedPeriod,
        setSelectedPeriod,
        organizationSelectOnChange,
        handleUnattestDriver,
        showModal,
        onChangeDatePicker,
        updateObject,
        dateFormat,
        loading
    }}>{props.children}</EconomyReportContext.Provider>

}

export { EconomyReportContext, EconomyReportProvider };



import {range} from "./utilities"

export const makeCompassImage = ({width, height, alpha = 255}) => {
    // const width = 8
    // const height = 40
    const data = new Uint8Array(width * height * 4)

    // Normal for would probably be faster, but this is a small image
    range(height).forEach(i => {
        const color = i < 20 ? [255, 0, 0, alpha] : [240, 240, 240, alpha]
        range(width).forEach(j => {
            const offset = (i * width + j) * 4
            range(4).forEach(c => {
                data[offset + c] = color[c]
            })
        })
    })

    return data
}

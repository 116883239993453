import {
    SIGNIN,
    AUTH_TOKEN,
    AUTHENTICATED,
    SHOW_AUTH_MESSAGE,
    HIDE_AUTH_MESSAGE,
    SIGNOUT_SUCCESS,
    SIGNUP_SUCCESS,
    SHOW_LOADING,
    SIGNOUT,
    SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
} from "../constants/authConstants";

const initState = {
    loading: false,
    message: "",
    showMessage: false,
    redirect: "",
    token: localStorage.getItem(AUTH_TOKEN),
};

const auth = (state = initState, action) => {
    switch (action.type) {
        case SIGNIN:
            console.log("reducing signin", action);
            return {
                ...state,
            };
        case SIGNOUT:
            console.log("Reducing signout");
            return { ...state };
        case AUTHENTICATED:
            return {
                ...state,
                loading: false,
                redirect: "/",
                token: action.token,
            };
        case SHOW_AUTH_MESSAGE:
            return {
                ...state,
                message: action.message,
                showMessage: true,
                loading: false,
            };
        case HIDE_AUTH_MESSAGE:
            return {
                ...state,
                message: "",
                showMessage: false,
            };
        case SIGNOUT_SUCCESS: {
            return {
                ...state,
                token: null,
                redirect: "/",
                loading: false,
            };
        }
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                token: action.token,
            };
        }
        case SHOW_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token,
            };
        }
        case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
            return {
                ...state,
                loading: false,
                token: action.token,
            };
        }
        default:
            return state;
    }
};

export default auth;

import React, {useContext, useEffect, useState} from "react";
import {Button, Form, Input, Menu, message, Modal, Select, Spin, Table, Tabs, Tooltip, Typography} from "antd";
import {AimOutlined, ReloadOutlined, ExclamationCircleOutlined, DashboardOutlined, HistoryOutlined, EnvironmentOutlined, DownloadOutlined, EditOutlined, DeleteOutlined, ArrowsAltOutlined, HomeOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {Icon} from "react-icons-kit";
import {LastSeenLink} from "./shared-components/DatumLink";
import {dataApi} from "../../services/api/api";
import Loading from "../shared-components/Loading";
import {OrganizationsTreeSelect} from "./shared-components/OrganizationsTreeSelect";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import {ORGANIZATION_ID} from "../../services/redux/constants/authConstants";
import {
    ButtonMenuDropdown,
    ChangeVehiclesOrganization,
    CheckInDriver,
    MenuItemInterval,
    tableColumnsWithTooltip,
    tableProps,
    vehiclesIcon
} from "./shared-components/sharedComponents";
import {STATIC_TEXT} from "../../lang/translate/sv-SE/sv-SE";
import {
    AuthorizationContext,
    DeviceListContext,
    OrganizationsComplexListContext,
    shouldHideUsersOrganizationsColumn,
    UserListContext,
    VehiclesListWithArchivedContext
} from "../../services/contexts/contexts";
import {ModalWithFullList} from "./shared-components/modalShowFullList";
import {ServicesContext} from "../../services/contexts/ServicesContext";
import {NewTrackerForm} from "./shared-components/newTrackerForm";
import {VehiclesContext} from "../../services/contexts/VehiclesContext";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/sv.json"));

const {Text} = Typography;
const {Option} = Select;
const {TabPane} = Tabs;

const countryNamesList = countries.getNames("sv", {select: "alias"});
const countryNamesAsArray = Object.entries(countryNamesList).map(([key, label]) => ({
    key,
    label,
}));
const countryNamesAsArraySorted = countryNamesAsArray.sort((a, b) => a.label.localeCompare(b.label));
const nordicCountries = countryNamesAsArraySorted.filter((country) => country.key === "SE" || country.key === "FI" || country.key === "NO" || country.key === "DK");
const sweden = countryNamesAsArraySorted.find((sweden) => sweden.key === "SE");
const findSweden = nordicCountries.findIndex((sweden) => sweden.key === "SE");
nordicCountries.splice(findSweden, 1);
nordicCountries.unshift(sweden);
const CHECK_OUT = "check_out";

export const SuperTrackersListController = () => {
    const [organizationId, setSelectedOrganization] = useState();
    const [benefitType, setBenefitType] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");

    const knownOrganizations = useContext(OrganizationsComplexListContext)
    const vehicles = useContext(VehiclesListWithArchivedContext)
    const knownDevices = useContext(DeviceListContext)
    const users = useContext(UserListContext);
    const knownUsers = users?.filter(v => !v.isArchived);

    const organizationSelectOnChange = (value) => {
        setSelectedOrganization(value);
    };

    const onHandleBenefitTypeChange = (value) => {
        setBenefitType(value);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    return (
        <>
            <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20, flexWrap: "wrap"}}>

                <div>
                    <Typography.Title style={{marginBottom: 0}}>Superadmin - Utrustning</Typography.Title>
                </div>
                <div style={{display: "flex", gap: 15, flexWrap: "wrap" }}>
                    {knownOrganizations?.length < 2 ? null : (
                        <OrganizationsTreeSelect data={knownOrganizations} onChange={organizationSelectOnChange}
                                                 selectedOrganization={organizationId} isMultiple={true}/>
                    )}
                    <Input.Search
                        type="text"
                        placeholder="Sök efter utrustning..."
                        label="Registreringsnummer"
                        defaultValue={searchTerm}
                        enterButton
                        onChange={handleChange}
                        style={{width: 300}}
                    />

                    <Button type={"primary"} onClick={() => setIsModalVisible(true)} icon={<AimOutlined/>}>
                        Lägg till
                    </Button>
                </div>
            </div>
            <TrackersTable
                permittedDrivers={knownUsers}
                devicesData={knownDevices}
                knownOrganizations={knownOrganizations}
                organizationId={organizationId}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                benefitType={benefitType}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                vehicles={vehicles}
            />
        </>
    );
};

export const VehicleMarker = ({marker}) => {
    return <Icon icon={marker}/>;
};

const TrackersTable = ({
                          vehicles,
                          permittedDrivers,
                          changeOdometerValue,
                          changeDefaultTripType,
                          changeBenefitType,
                          devicesData,
                          searchTerm,
                          setIsModalVisible,
                          ...props
                      }) => {
    const [form] = Form.useForm();
    const [filteredVehiclesData, setFilteredVehiclesData] = useState();
    const [showAlertArchived, setShowAlertArchived] = useState(false);
    const [archivedData, setArchivedData] = useState();
    const [activeData, setActiveData] = useState();
    const [loading, setLoading] = useState(true);
    const [editingKey, setEditingKey] = useState("");
    const [editingVehicle, setEditingVehicle] = useState();
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState();
    const [allData, setAllData] = useState();
    const [record, setRecord] = useState(null);
    const [recordFrequencyInterval, setRecordFrequencyInterval] = useState();

    const hideUsersOrg = useContext(shouldHideUsersOrganizationsColumn.context);
    const {getTrackersReport} = useContext(ServicesContext);
    const {updateVehicleFrequencyUpdate} = useContext(VehiclesContext);
    const currentUser = useContext(AuthorizationContext);

    const isROOT = currentUser?.roles?.includes("ROOT")
    const {push} = useHistory();

    useEffect(() => {
        const data = vehicles?.filter(item => item.isTracker)
        setAllData(data);
        setLoading(false)
    }, [vehicles]);

    useEffect(() => {
        const sortData = filteredVehiclesData?.sort((a, b) => a.displayName?.localeCompare(b.displayName));
        setActiveData(sortData?.filter((item) => !item.isArchived))
        setArchivedData(sortData?.filter((item) => item.isArchived))
    }, [filteredVehiclesData])

    useEffect(() => {
        let filteredList = allData;

        if (searchTerm.length > 1) filteredList = filteredList?.filter((item) => JSON.stringify(item).toLowerCase().includes(searchTerm));

        if (props?.organizationId?.length > 0) {
            filteredList = filteredList?.filter((i) => props.organizationId.includes(i.organizationId));
        }

        setFilteredVehiclesData(filteredList);
    }, [searchTerm, allData, props.organizationId, props.benefitType]);

    function handleAdd(rows) {
        const newData = [...allData];

        if (Array.isArray(rows)) {
            rows.forEach(row => {
                const index = newData.findIndex(item => row.id === item.id);
                if (index > -1) {
                    newData.splice(index, 1, { ...newData[index], ...row });
                } else {
                    newData.push(row);
                }
            });
        } else {
            const index = newData.findIndex(item => rows.id === item.id);
            if (index > -1) {
                newData.splice(index, 1, { ...newData[index], ...rows });
            } else {
                newData.push(rows);
            }
        }

        setFilteredVehiclesData(newData);
        setAllData(newData);
        setIsModalVisible(false);
        setEditingVehicle(null);
    }

     function handleMoveDeviceToOtherVehicle(record, vehicleId, knownVehicles) {
        setShowSpinner(true);
         knownVehicles = knownVehicles || allData

        const imei = record.state?.imei;
        const deviceIndex = devicesData.findIndex((device) => device.imei === imei);

        if (deviceIndex === -1) {
            setShowSpinner(false);
            message.error("Kunde inte hitta enheten.");
            return;
        }

        const device = devicesData[deviceIndex];

        if (record.id === vehicleId || !vehicleId) {
            setShowSpinner(false);
            return;
        }

        try {
             dataApi.patchDevices(device.id, vehicleId);

            const newData = [...knownVehicles];
            const recordIndex = newData.findIndex((item) => item.id === record.id);
            const vehicleIndex = newData.findIndex((item) => item.id === vehicleId);

            let newVehicle ;
            if (vehicleIndex !== -1) {
                newVehicle = { ...newData[vehicleIndex] };
                newVehicle.state = { ...device };
                newVehicle.odometer = record.odometer;
                newData.splice(vehicleIndex, 1, newVehicle);
            }

            const newRecord = { ...newData[recordIndex] };
            newRecord.state = null;
            newRecord.odometer = null;
            newData.splice(recordIndex, 1, newRecord);

            setFilteredVehiclesData(newData);
            setAllData(newData);
            setSelectedRecord(null);
            Modal.success({
                title: "Enheten flyttades",
                content: (
                    <div>
                        <p>Enhet {device.imei} flyttades till <strong>{newVehicle?.registry || newVehicle?.displayName}</strong>.</p>
                        <p style={{ fontWeight: 'bold' }}>Glöm inte att ta ut enheten.</p>
                    </div>
                ),
            });
        } catch (error) {
            console.error(error);
            message.error("Något gick fel. Försök igen.");
        } finally {
            setShowSpinner(false);
        }
    }
    const handleSelectChangeVehicleOrganization = (record, selectedOrgId) => {
        setShowSpinner(true);
        const req = {organizationId: selectedOrgId}

        dataApi.patchChangeOrgVehicle(record.id, req)
            .then(i => {
                message.success("Tracker flyttades till en annan organisation.")
                const newData = [...allData];
                const index = newData.findIndex(item => record.id === item.id);
                const item = newData[index];
                let newRow = item
                newRow = {
                    ...item,
                    organizationId: selectedOrgId,
                    permittedDrivers: [],
                    defaultDriver: null,
                    currentDriver: null,
                }
                newData.splice(index, 1, {...item, ...newRow});
                setFilteredVehiclesData(newData)
                setAllData(newData)
                setShowSpinner(false);

            })
            .catch((e) => {
                message.error("Något gick fel. Försök igen.");
                console.error(e);
            });
    };

    function redirectToView(record, view, showArchived) {
        const value = record?.id;
        let qs;
        qs = showArchived ? `&&showArchived=${showArchived}` : ""
        push(`${APP_PREFIX_PATH}/${view}?vehicleId=${value}` + qs);
    }

    function handleAddNewVehicle(record) {
        setSelectedRecord(record);
        setIsModalVisible(true);
    }

    // EXLUDE ORGS HAX
    const EXCLUDED_ORG_IDS = [41465, 41186, 30714];
    const dontShowRedirectToMap = EXCLUDED_ORG_IDS.includes(parseInt(localStorage.getItem(ORGANIZATION_ID)));

    function editVehicle(record) {
        setEditingVehicle(record);
        setIsModalVisible(true)
    }


    function updateRecord(record) {
        const newData = [...allData];
        const index = newData?.findIndex((item) => record?.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {...item, ...record});
        setAllData(newData);
        setRecord(null)
    }

    const exportTrackerReport = async (vehicleId) => {
        const fileName = record?.displayName?.split(" ")?.join("_");
        const res = await getTrackersReport(vehicleId, fileName);
        if (!res) console.log("Export Successful");
    };

    const handleUpdateFreq = async (vehicleId, freq, record) => {
        await updateVehicleFrequencyUpdate(vehicleId, freq).then(_ => {
            const latestResponseFrequency = freq.replace("-", "");
            handleAdd({...record, selectedResponseFrequency: latestResponseFrequency})
        })
    };

    const handleDeleteInstalledDevice = (record) => {
        const imei = record.state?.imei;
        const device = devicesData?.find((i) => i.imei === imei);
        const itemIndex = allData.findIndex((i) => i.id === record.id);

        if (device) {
            Modal.confirm({
                title: `Bekräfta avinstallering för ${record?.registry || record.displayName}`,
                icon: <ExclamationCircleOutlined/>,
                okType: 'danger',
                content: 'Är du säker på att du vill avinstallera enhet?',
                onOk() {
                    let newData;
                    dataApi.deleteAdminDevicesInstalled(device.id).then((_) => {
                        message.success("Enheten har avinstallerats");
                        setShowSpinner(false);
                        device.installedVehicle = null;
                        device.retired = false;
                        if (itemIndex !== -1) {
                            const updatedItems = [...allData];
                            updatedItems[itemIndex].state = null;
                            setAllData(updatedItems);
                        }
                    });
                },
                onCancel() {
                },
                okText: "Avinstallera"
            })

        }
    };

    function handleDelete(row) {
        const newData = allData?.filter((item) => item?.id !== row?.id);
        setAllData(newData);
    }
    const deleteVehicle = (record) => {
        Modal.confirm({
            title: `Bekräfta borttagning av ${record?.registry || record.displayName}`,
            icon: <ExclamationCircleOutlined/>,
            okType: 'danger',
            content: 'Är du säker på att du vill ta bort detta fordon?',
            onOk() {
                let newData;
                dataApi.deleteVehicle(record.id).then((_) => {
                    message.success(record?.registry || record.displayName + " har tagits bort");
                    handleDelete(record)
                });
            },
            onCancel() {
            },
            okText: "Ta bort"
        })
    };

    function menuDropdown(record) {
        let updateIntervalText = `Ändra uppdateringsfrekvens (${record?.latestResponseFrequency || "24h"})`;
        const hasDevice = record?.state?.imei;

        return <Menu>
            <Menu.Item icon={<EditOutlined/>}
                       onClick={() => editVehicle(record)}>{STATIC_TEXT.BTN_CHANGE} </Menu.Item>
            <Menu.Item icon={<DownloadOutlined/>}
                       onClick={() => exportTrackerReport(record.id)}>{STATIC_TEXT.EXPORT_RAPPORT}</Menu.Item>
            {dontShowRedirectToMap ? null : (
                <Menu.Item icon={<EnvironmentOutlined/>}
                           onClick={() => redirectToView(record, "map")}>
                    Visa på karta </Menu.Item>
            )}

            <Menu.Item icon={<ReloadOutlined/>} onClick={() => setRecordFrequencyInterval(record)}>
                {updateIntervalText}
            </Menu.Item>

            {props.knownOrganizations?.length > 1 &&
                <Menu.Item icon={<HomeOutlined/>}
                        onClick={() => ChangeVehiclesOrganization(record, props.knownOrganizations, handleSelectChangeVehicleOrganization)}
            >
                Flytta utrustning till annan organisation</Menu.Item>
            }

            <Menu.Item disabled={!hasDevice} icon={<ArrowsAltOutlined/>}
                       onClick={() => handleDeleteInstalledDevice(record)}>{STATIC_TEXT.UNINSTALL_DEVICE} </Menu.Item>
            <Menu.Item danger={true} icon={<DeleteOutlined/>}
                       onClick={() => deleteVehicle(record)}>{STATIC_TEXT.REMOVE_VEHICLE} </Menu.Item>

        </Menu>
    }

    const columns = [
        {
            key: "style",
            dataIndex: "style",
            title: "Ikon",
            align: "center",
            width: 80,
            render: (value) => vehiclesIcon(value)
        },
        {
            key: "displayName",
            dataIndex: "displayName",
            title: "Alias",
            fixed: 'left',
        },
        {
            key: "organizationId",
            dataIndex: "organizationId",
            title: "Organisation",
            editable: false,
            hidden: hideUsersOrg,
            render: (value) => {
                const organization = props.knownOrganizations?.find((v) => v.organizationId === value);
                return organization ? organization.fullName : "-";
            },
        },
        {
            title: "Behöriga användare",
            key: "dPermitted",
            dataIndex: "permittedDrivers",
            render: (value, record) => {
                value = value?.filter(item => permittedDrivers?.find(user => user.id === item.id))
                const ifPoolCar = record?.benefitType === "POOL";
                let text;
                if (ifPoolCar) {
                    text = "Tillåts för alla"
                }
                if (value?.length === 0) {
                    text = "Användare ej vald";
                }
                if (value?.length === 1) {
                    const driver = permittedDrivers?.find((v) => v.userId === value[0].id);
                    text = driver?.displayName

                }
                if (value?.length > 1) {
                    const recordUsers = value?.map(item => item.id)
                    let title = value?.length + " användare";
                    return ModalWithFullList(title, null, null, recordUsers, null, permittedDrivers, props.knownOrganizations)
                }
                return text
            },
        },
        {
            title: "Uppdateringsfrekvens",
            dataIndex: "latestResponseFrequency",
            key: "latestResponseFrequency",
            align: "center",
            render: (text, record) => {
                if(record.latestResponseFrequency !== record.selectedResponseFrequency && record.selectedResponseFrequency){
                    return <Tooltip title={"Väntande ändring: " + record.selectedResponseFrequency}>
                        {record.latestResponseFrequency} <HistoryOutlined />
                    </Tooltip>

                } else {
                  return text
                }
            }
        },
        {
            title: "Batteri",
            dataIndex: "batteryPercentage",
            key: "batteryPercentage",
            align: "center",
            render: (value) => {
                return value ? value + "%" : "-"
            }
        },
        {
            title: "Sågs senast",
            dataIndex: "lastSeen",
            key: "lastSeen",
            align: "center",
            render: (text, record) => (text ?
                <LastSeenLink value={text} typ={"map"} isTracker={record.isTracker}/> : "-"),
        },
        {
            key: "imei",
            dataIndex: "state",
            title: "Enhet",
            render: (value, record) => {
                if (!value?.imei) return

                let imei = value?.imei?.toString();
                return <Tooltip title={imei}>
                    {imei}
                </Tooltip>
            },
        },
        {
            title: "",
            dataIndex: "operation",
            width: 80,
            fixed: "right",
            render: (text, record) => {
                return <ButtonMenuDropdown menu={() => menuDropdown(record)}/>
            },
        },
    ].filter(item => !item.hidden);

    let extraTitle = selectedRecord ? " med imei " + selectedRecord?.state?.imei : "";

    const onModalCancel = () => {
        setEditingVehicle(null)
        setSelectedRecord(null)
        setIsModalVisible(false)
    };


    return (
        <Form form={form} component={false}>

            <NewTrackerForm
                onModalCancel={onModalCancel}
                isModalVisible={props.isModalVisible}
                extraTitle={extraTitle}
                knownOrganizations={props.knownOrganizations}
                organizationId={props.organizationId}
                permittedDrivers={permittedDrivers}
                setData={handleAdd}
                knownDevices={devicesData}
                record={editingVehicle}
                moveDevice={selectedRecord}
                imeiList={devicesData}
                handleMoveDeviceToOtherVehicle={handleMoveDeviceToOtherVehicle}
                isROOT={isROOT}
            />

            <CheckInDriver record={record} updateRecord={updateRecord} setRecord={setRecord}/>

            {!activeData ? (
                <Loading/>
            ) : (
                <Spin spinning={editingKey !== "" && showSpinner}>

                    <Table
                        dataSource={activeData}
                        columns={tableColumnsWithTooltip(columns)}
                        {...tableProps}
                        scroll={{x: 1000}}
                        tableLayout={"fixed"}
                    />
                    <MenuItemInterval record={recordFrequencyInterval} setRecordFrequencyInterval={setRecordFrequencyInterval}
                                      handleUpdateFreq={handleUpdateFreq} />


                </Spin>
            )}
        </Form>
    );

};


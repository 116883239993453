import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { AUTH_TOKEN, ORGANIZATION_ID, SIGNIN, SIGNOUT } from "../constants/authConstants";

import { showAuthMessage, authenticated, signOutSuccess } from "../actions/authActions";

import AdminService from "services/AdminService";

export function* signInWithEmail() {
    yield takeEvery(SIGNIN, function* ({ payload }) {
        const { username, password } = payload;
        try {
            const user = yield call(AdminService.login, username, password);

            if (user.message) {
                yield put(showAuthMessage(user.message));
            } else {
                localStorage.setItem(AUTH_TOKEN, user.token);
                localStorage.removeItem(ORGANIZATION_ID);
                yield put(authenticated(user.token));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export function* signOut() {
    yield takeEvery(SIGNOUT, function* () {
        try {
            const signOutUser = yield call(AdminService.logout);
            // TODO clear all state here!
            if (signOutUser === undefined) {
                // ???? TODO: ????
                localStorage.removeItem(AUTH_TOKEN);
                yield put({ type: "RESET" });
                yield put(signOutSuccess(signOutUser));
            } else {
                yield put(showAuthMessage(signOutUser.message));
            }
        } catch (err) {
            yield put(showAuthMessage(err));
        }
    });
}

export default function* rootSaga() {
    yield all([fork(signInWithEmail), fork(signOut)]);
}

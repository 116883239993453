import React, {useState} from "react"
import {GOOGLE_MAPS_API} from "../../../configs/AppConfig"
import {KNOWN_LOCATION} from "../../../geometry"


// Provide a context for map-react internals
// NOTE: You can't mount props.children inside a GoogleMapReact, because it
// will not recurse into React.Fragment in order to check the lat, lng props
// on elements. Things will still render, but clumped up in the middle.
// So this provider just gives you a set of mapProps that you can pass into
// a GoogleMapReact, but you still need that component in your marker groups,
// the map does not compose like you'd expect React components to.
export const GoogleMapContext = React.createContext({map: null, maps: null})
export const GoogleMapProvider = ({...props}) => {
    const [state, setState] = useState({
        center: KNOWN_LOCATION.medis,
        zoom: 1,
        lastFittedBounds: null
    })

    const googleBounds = (maps, coords) => {
        const ret = new maps.LatLngBounds()
        coords.forEach(c => {
            ret.extend(new maps.LatLng(c[0], c[1]));
        })
        return ret
    }

    const onGoogleApiLoaded = ({map, maps}) => {
        if (!map || !maps) {
            throw new Error("Google API callback called, but no object received: Look for errors in GoogleMapReact")
        }
        setState({...state, center: null})
        map.fitBounds(googleBounds(maps, [
            KNOWN_LOCATION.medis,
            KNOWN_LOCATION.tensta,
            KNOWN_LOCATION.tyreso
        ]))
        setState({...state, zoom: null, map, maps})
    }

    const [boundsChanged, setBoundsChanged] = useState()
    const api = {
        fitBoundsToLine: (coords) => {
            setBoundsChanged(coords)
        }
    }

    React.useEffect(() => {
        if (!state.maps || !state.map || !boundsChanged) {
            console.debug("UNABLE TO FIT BOUNDS YET!")
            return
        }
        const bounds = new state.maps.LatLngBounds()
        let minlat = 90
        let minlng = 180
        let maxlat = -90
        let maxlng = -180
        let changed
        boundsChanged.forEach((coord) => {
            changed = false
            if ((coord.lat) < minlat) {
                minlat = coord.lat
            } else if ((coord.lng) < minlng) {
                minlng = coord.lng
            } else if ((coord.lat) > maxlat) {
                maxlat = coord.lat
            } else if ((coord.lng) > maxlng) {
                maxlng = coord.lng
            }
        })
        //bounds.extend(new window.google.maps.LatLng(minlat, minlng))
        //bounds.extend(new window.google.maps.LatLng(maxlat, maxlng))
        bounds.extend(new state.maps.LatLng(minlat, minlng))
        bounds.extend(new state.maps.LatLng(maxlat, maxlng))
        state.map.fitBounds(bounds, {
            top: 10,
            right: 10,
            left: 10,
            bottom: 10
        })
        setState({...state, lastFittedBounds: bounds})
    }, [state.map, state.maps, boundsChanged])

    return <GoogleMapContext.Provider value={{
        ...api,
        ...state,
        mapProps: {
            bootstrapURLKeys: { key: GOOGLE_MAPS_API },
            zoom: state.zoom,
            center: state.center,
            options: {        // styles: mapStyle,
                disableDoubleClickZoom: true
            },
            yesIWantToUseGoogleMapApiInternals: true,
            onGoogleApiLoaded: onGoogleApiLoaded
        }}}>{props.children}
    </GoogleMapContext.Provider>
}

export const authMock = {
    "token": "beepboop"
}
export const overviewMock = [
    {
        "id": 1,
        "meta": {
            "reg": "NS4957",
            "type": "CAR", //"CAR" | "TRUCK",
            "color": "#Df626B",
            "status": "STOPPED", //"MOVING" | "STOPPED" | "OFFLINE" | "BROKEN",
            "gps_quality": 1.0,
            "gsm_quality": 1.0
        },
        "last_seen_ms": 1618925929000,
        "speed_kph": 0,
        "speed_kmh": 0,
        "report_rate_s": 300,
        "battery_percentage": 100,
        "position": {
            "latitude": 59.84209056411892,
            "longitude": 17.625054561388687
        },
        "driver": {
            "user_id": 123,
            "display_name": "Lisa"
        }
    },
    {
        "id": 2,
        "meta": {
            "reg": "AEB557",
            "type": "CAR", //"CAR" | "TRUCK",
            "color": "#FA8C17",
            "status": "MOVING", //"MOVING" | "STOPPED" | "OFFLINE" | "BROKEN",
            "gps_quality": 1.0,
            "gsm_quality": 1.0
        },
        "last_seen_ms": 1618925929000,
        "speed_kph": 30,
        "speed_kmh": 30,
        "report_rate_s": 300,
        "battery_percentage": 100,
        "position": {
            "latitude": 59.69749597758973,
            "longitude": 18.11574738600702
        },
        "driver": {
            "user_id": 123,
            "display_name": "Lisa"
        }
    },
    {
        "id": 3,
        "meta": {
            "reg": "ABC256",
            "type": "CAR", //"CAR" | "TRUCK",
            "color": "#FA8C17",
            "status": "STOPPED", //"MOVING" | "STOPPED" | "OFFLINE" | "BROKEN",
            "gps_quality": 1.0,
            "gsm_quality": 1.0
        },
        "last_seen_ms": 1618832329000,
        "speed_kph": 30,
        "speed_kmh": 30,
        "report_rate_s": 300,
        "battery_percentage": 18,
        "position": {
            "latitude": 59.95653247721158,
            "longitude": 17.502039056502117
        },
        "driver": {
            "user_id": 123,
            "display_name": "Lisa"
        }
    }
]

export const journalMock = [
    {
        journal_id: 144,
        vehicle: {
            reg: "ABC123",
            type: "CAR", // CAR | TRUCK
        },
        driver: {
            user_id: 123,
            display_name: "Lisa"
        },
        trip: {
            timestamp_start_ms: 1618970798000,
            timestamp_stop_ms: 1619003198000,
            odometer_start: 10000,
            odometer_stop: 15000,
            odometer_max: 10000000,
            purpose: "WORK", // PRIVATE | WORK
            description: "Description",
            coords: [
                {
                    latitude: 59.84209056411892,
                    longitude: 17.625054561388687
                },
                {
                    latitude: 59.69749597758973,
                    longitude: 18.11574738600702
                }
            ]
        }
    },
    {
        journal_id: 155,
        vehicle: {
            reg: "DWD513",
            type: "CAR", // CAR | TRUCK
        },
        driver: {
            user_id: 124,
            display_name: "Bob"
        },
        trip: {
            timestamp_start_ms: 1616037238000,
            timestamp_stop_ms: 1616044438000,
            odometer_start: 30000,
            odometer_stop: 40000,
            odometer_max: 10000000,
            purpose: "PRIVATE", // PRIVATE | WORK
            description: "Description",
            coords: [
                {
                    latitude: 59.85057342114571,
                    longitude: 17.57739705761025
                },
                {
                    latitude: 59.86938106342094,
                    longitude: 17.597583382174143
                }
            ]
        }
    }
]
